import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Dropdown, Button, Form, Input, Divider, Menu, Icon, Row, Col, Tooltip, Alert, Select,
} from 'antd'
import helper from '@page/main/helper'
import clone from 'clone'

const FormItem = Form.Item

class GeneralPreference extends Component {
  state = {
    preferenceLoaded: false,
    preference: {
      language: '',
      measurement: '',
      email: '',
      password: '',
      passwordConfirm: '',
      firstName: '',
      lastName: '',
      mobilePhone: '',
      textAddress: '',
      timeZoneId: null,
      mapRefresh: null,
      country: '',
    },
    formUI: {
      disablePasswordChange: true,
      changingPassword: false,
      email: { validateStatus: '', help: '' },
      password: { validateStatus: '', help: '' },
      passwordConfirm: { validateStatus: '', help: '' },
      firstName: { validateStatus: '', help: '' },
      lastName: { validateStatus: '', help: '' },
      mobilePhone: { validateStatus: '', help: '' },
    },
    saveButtonLoading: false,
    generalPreferenceNotificationType: '',
  }

  static propTypes = {
    preferenceData: PropTypes.object,
    formUI: PropTypes.object,
    validateEmail: PropTypes.func.isRequired,
    validateName: PropTypes.func.isRequired,
    validatePassword: PropTypes.func.isRequired,
    validatePhone: PropTypes.func.isRequired,
    validateStatus: PropTypes.func,
    savePreference: PropTypes.func.isRequired,
    wMatrix: PropTypes.func.isRequired,
    getLanguageFullName: PropTypes.func.isRequired,
    getMeasurementFullName: PropTypes.func.isRequired,
    countryCodes: PropTypes.array.isRequired,
    timeZoneData: PropTypes.object,
    /** @helper */
    getPermission: PropTypes.func.isRequired,
  }

  static defaultProps = {
    preferenceData: null,
    formUI: null,
    validateStatus: null,
    timeZoneData: null,
  }

  componentDidMount = () => {
    this.loadPreference()
  }

  componentDidUpdate = () => {
    this.loadPreference()
  }

  loadPreference = () => {
    const { preferenceLoaded } = this.state
    const { preferenceData } = this.props
    const pref = preferenceData.data.preference

    if (pref && !preferenceLoaded) {
      this.setState({
        preferenceLoaded: true,
        preference: {
          language: localStorage.getItem('rft-language'),
          measurement: localStorage.getItem('rft-measurement'),
          email: pref.email,
          password: pref.password,
          passwordConfirm: '',
          firstName: pref.firstName,
          lastName: pref.lastName,
          mobilePhone: pref.mobilePhone,
          textAddress: pref.textAddress,
          timeZoneId: pref.timeZoneId,
          mapRefresh: pref.mapRefresh,
          country: pref.country,
        },
        formUI: {
          disablePasswordChange: true,
          changingPassword: false,
          email: { validateStatus: '', help: '' },
          password: { validateStatus: '', help: '' },
          passwordConfirm: { validateStatus: '', help: '' },
          firstName: { validateStatus: '', help: '' },
          lastName: { validateStatus: '', help: '' },
          mobilePhone: { validateStatus: '', help: '' },
        },
      })
    }
  }

  /* bonus point for Travis */
  changePreference = (key, e) => {
    const { preference } = this.state
    preference[key] = e.target.value
    this.setState({ preference })

    const { formUI } = this.state
    formUI[key].help = ''
    formUI[key].validateStatus = ''
    this.setState({ formUI })
  }

  togglePasswordChange = () => {
    const { formUI } = this.state
    const { preferenceData } = this.props
    if (formUI.changingPassword) {
      /* if current state is changing password, this function is called to cancel */
      this.changePreference('password', { target: { value: preferenceData.data.preference.password } })
      this.changePreference('passwordConfirm', { target: { value: '' } })
      formUI.disablePasswordChange = true
      formUI.changingPassword = false
      this.setState({ formUI })
    } else {
      this.changePreference('password', { target: { value: '' } })
      formUI.disablePasswordChange = false
      formUI.changingPassword = true
      this.setState({ formUI })
    }
  }

  switchLanguage = (e) => {
    const { key } = e
    const { preference } = this.state
    preference.language = key
    this.setState({ preference })
  }

  switchMeasurement = (e) => {
    const { key } = e
    const { preference } = this.state
    preference.measurement = key
    this.setState({ preference })
  }

  switchTimeZone = (e) => {
    const key = parseInt(e.key, 10)
    const { preference } = this.state
    preference.timeZoneId = key
    this.setState({ preference })
  }

  switchMapRefresh = (e) => {
    const key = parseInt(e.key, 10)
    const { preference } = this.state
    preference.mapRefresh = key
    this.setState({ preference })
  }

  savePreference = () => {
    const { formUI, preference } = this.state
    const {
      validateEmail, validatePassword, validateName, validatePhone, savePreference, wMatrix,
    } = this.props

    /* validate preference on the UI level first */
    let valid = true

    const tempFormUI = clone(formUI)

    tempFormUI.email = validateEmail(preference.email)
    valid = valid && tempFormUI.email.valid

    if (!tempFormUI.disablePasswordChange) {
      tempFormUI.password = validatePassword(preference.password, preference.passwordConfirm)
      valid = valid && tempFormUI.password.valid

      tempFormUI.passwordConfirm = validatePassword(preference.passwordConfirm, preference.password)
      valid = valid && tempFormUI.passwordConfirm.valid
    }

    tempFormUI.firstName = validateName(preference.firstName)
    valid = valid && tempFormUI.firstName.valid

    tempFormUI.lastName = validateName(preference.lastName)
    valid = valid && tempFormUI.lastName.valid

    if (preference.mobilePhone) {
      tempFormUI.mobilePhone = validatePhone(preference.mobilePhone)
      valid = valid && tempFormUI.mobilePhone.valid
    }

    this.setState({
      formUI: tempFormUI,
      saveButtonLoading: true,
    })

    if (valid) {
      savePreference(preference).then((res) => {
        if (res.code === 1000 && res.description === 'success') {
          if (res.ifNeedLogout) {
            localStorage.removeItem('rft-token')
            sessionStorage.removeItem('rft-token')
            window.location.replace('/login')
            return
          }

          this.setState({
            formUI: tempFormUI,
            saveButtonLoading: false,
            generalPreferenceNotificationType: 'success',
          })
          setTimeout(() => {
            this.setState({
              generalPreferenceNotificationType: '',
            })
          }, 5000)
        } else {
          switch (res.description) {
            case 'no permission':
              tempFormUI.email.help = wMatrix('noPermissionError')
              tempFormUI.email.validateStatus = 'error'
              break
            case 'email taken':
              tempFormUI.email.help = wMatrix('emailTakenError')
              tempFormUI.email.validateStatus = 'error'
              break
            default:
          }
          this.setState({
            formUI: tempFormUI,
            saveButtonLoading: false,
            generalPreferenceNotificationType: 'error',
          })
          setTimeout(() => {
            this.setState({
              generalPreferenceNotificationType: '',
            })
          }, 5000)
        }
      })
    } else {
      this.setState({
        formUI: tempFormUI,
        saveButtonLoading: false,
      })
    }
  }

  renderCountryCodeItems = () => {
    const { countryCodes } = this.props
    return countryCodes.map(country => (
      <Select.Option
        key={country.abv}
        value={country.abv}
        style={{ width: 350 }}
      >
        {`${country.country} +${country.phoneCode}`}
      </Select.Option>
    ))
  }

  switchCountry = (e) => {
    const { preference } = this.state
    preference.country = e
    this.setState({ preference })
  }

  renderTimeZoneItems = () => {
    const { timeZoneData } = this.props
    const timeZones = timeZoneData.data.getTimeZones
    if (timeZones) {
      return timeZones.map(zone => (
        <Menu.Item key={zone.id}>{zone.name}</Menu.Item>
      ))
    }
    return null
  }

  getTimeZoneName = (id) => {
    const { timeZoneData } = this.props
    const timeZones = timeZoneData.data.getTimeZones
    if (timeZones) {
      for (const zone of timeZones) {
        if (zone.id === id) {
          return zone.name
        }
      }
    }
    return 'Not Selected'
  }

  renderMapRefreshItems = () => {
    const mapRefreshRates = [30, 60, 120, 300, 600]
    return mapRefreshRates.map(rate => (
      <Menu.Item key={rate}>{this.getMapRefreshName(rate)}</Menu.Item>
    ))
  }

  getMapRefreshName = (seconds) => {
    const { wMatrix } = this.props
    switch (seconds) {
      case 30:
        return `${wMatrix('thirty')} ${wMatrix('seconds')}`
      case 60:
        return `${wMatrix('one')} ${wMatrix('minute')}`
      case 120:
        return `${wMatrix('two')} ${wMatrix('minutes')}`
      case 300:
        return `${wMatrix('five')} ${wMatrix('minutes')}`
      case 600:
        return `${wMatrix('ten')} ${wMatrix('minutes')}`
      default:
        return wMatrix('notSelected')
    }
  }

  render() {
    const {
      preference, formUI, generalPreferenceNotificationType, saveButtonLoading,
    } = this.state
    const {
      wMatrix, getLanguageFullName, getMeasurementFullName, getPermission,
    } = this.props

    const editable = getPermission('Preference', 'ynUpdate')

    const languageMenu = (
      <Menu onClick={this.switchLanguage}>
        <Menu.Item key="EN">{wMatrix('English')}</Menu.Item>
        <Menu.Item key="SP">{wMatrix('Spanish')}</Menu.Item>
      </Menu>
    )
    const measurementMenu = (
      <Menu onClick={this.switchMeasurement}>
        <Menu.Item key="m">{wMatrix('Miles')}</Menu.Item>
        <Menu.Item key="k">{wMatrix('Kilometers')}</Menu.Item>
      </Menu>
    )
    const timeZoneMenu = (
      <Menu onClick={this.switchTimeZone}>
        {this.renderTimeZoneItems()}
      </Menu>
    )
    const mapRefreshMenu = (
      <Menu onClick={this.switchMapRefresh}>
        {this.renderMapRefreshItems()}
      </Menu>
    )

    const formItemLayout = {
      labelCol: {
        sm: { span: 5 },
      },
      wrapperCol: {
        sm: { span: 14 },
      },
    }

    return (
      <div className="generalPreferencePanel">
        <div>
          <h2>{wMatrix('Application Settings')}</h2>
          <Divider style={{ width: '80%', marginTop: 0 }} />
          <Row>
            <Col xs={24}>
              <Row type="flex" justify="start">
                <Col xs={12} sm={6} md={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <label
                    style={{
                      display: 'inline-block', marginTop: 10, marginRight: 50, fontWeight: 600, color: '#333',
                    }}
                  >
                    {wMatrix('Language')}
                    :
                  </label>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <Dropdown overlay={languageMenu} trigger={['click']}>
                    <Button style={{ marginLeft: 8, fontWeight: 400 }}>
                      {getLanguageFullName(preference.language)}
                      {' '}
                      <Icon type="down" />
                    </Button>
                  </Dropdown>
                </Col>
                <Col xs={12} sm={6} md={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <label style={{
                    display: 'inline-block', marginTop: 10, marginRight: 50, fontWeight: 600, color: '#333',
                  }}
                  >
                    {wMatrix('Measurement')}
                    :
                  </label>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <Dropdown overlay={measurementMenu} trigger={['click']}>
                    <Button style={{ marginLeft: 8, fontWeight: 400 }}>
                      {getMeasurementFullName(preference.measurement)}
                      {' '}
                      <Icon type="down" />
                    </Button>
                  </Dropdown>
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle" style={{ marginTop: 25 }}>
                <Col xs={12} sm={6} md={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <label
                    style={{
                      display: 'flex', marginRight: 50, fontWeight: 600, color: '#333',
                    }}
                  >
                    {wMatrix('timeZone')}
                    :
                  </label>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <Dropdown overlay={timeZoneMenu} trigger={['click']}>
                    <Button style={{ marginLeft: 8, fontWeight: 400 }}>
                      {this.getTimeZoneName(preference.timeZoneId)}
                      {' '}
                      <Icon type="down" />
                    </Button>
                  </Dropdown>
                </Col>
                {/*
                <Col xs={12} sm={6} md={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <label
                    style={{
                      display: 'flex', marginRight: 50, fontWeight: 600, color: '#333',
                    }}
                  >
                    {wMatrix('mapRefreshRate')}
                    :
                  </label>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <Dropdown overlay={mapRefreshMenu} trigger={['click']}>
                    <Button style={{ marginLeft: 8, fontWeight: 400, width: '' }}>
                      {this.getMapRefreshName(preference.mapRefresh)}
                      {' '}
                      <Icon type="down" />
                    </Button>
                  </Dropdown>
                </Col>
                */
                }
              </Row>
            </Col>
          </Row>
          <h2 style={{ marginTop: 30 }}>{wMatrix('Profile Settings')}</h2>
          <Divider style={{ width: '80%', marginTop: 0 }} />
          <Form>
            <Row
              style={{ display: 'flex', alignItems: 'center', height: '64px' }}
            >
              <Col
                xs={5}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <label style={{ marginRight: 50, fontWeight: 600, color: '#333' }}>
                  {wMatrix('Email Address')}
                  :
                </label>
              </Col>
              <Col xs={18}>
                <FormItem
                  {...formItemLayout}
                  className="preferenceInput"
                  validateStatus={formUI.email.validateStatus}
                  help={formUI.email.help}
                  style={{ margin: 0 }}
                >
                  <Input
                    disabled={!editable}
                    onChange={(e) => { this.changePreference('email', e) }}
                    value={preference.email}
                    placeholder={wMatrix('preferenceEmailPlaceholder')}
                    type="email"
                    suffix={(
                      <Tooltip
                        placement="right"
                        title={wMatrix('preferenceEmailAddressInfo')}
                        arrowPointAtCenter
                      >
                        <Icon type="question-circle" theme="outlined" />
                      </Tooltip>
                    )}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row
              style={{ display: 'flex', alignItems: 'center', height: '64px' }}
            >
              <Col
                xs={5}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <label style={{ marginRight: 50, fontWeight: 600, color: '#333' }}>
                  {wMatrix('Password')}
                  :
                </label>
              </Col>
              <Col xs={19}>
                <FormItem
                  {...formItemLayout}
                  className="preferenceInput"
                  validateStatus={formUI.password.validateStatus}
                  help={formUI.password.help}
                  style={{ margin: 0 }}
                >
                  <Row>
                    <Col xs={19}>
                      <Input
                        onChange={(e) => { this.changePreference('password', e) }}
                        placeholder={wMatrix('preferencePasswordPlaceholder')}
                        value={preference.password}
                        disabled={!formUI.changingPassword || !editable}
                        type="password"
                        style={{ background: formUI.changingPassword ? '#fff' : '#ddd' }}
                      />
                    </Col>
                    <Col
                      xs={5}
                      onClick={this.togglePasswordChange}
                      style={{ color: '#4482ff', cursor: 'default', paddingLeft: 15 }}
                    >
                      {formUI.changingPassword ? wMatrix('cancel') : wMatrix('change')}
                    </Col>
                  </Row>
                </FormItem>
              </Col>
            </Row>

            <Row
              style={{
                display: (formUI.changingPassword ? 'flex' : 'none'),
                alignItems: 'center',
                height: '64px',
              }}
            >
              <Col
                xs={5}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <label style={{ marginRight: 50, fontWeight: 600, color: '#333' }}>
                  {wMatrix('Confirm Password')}
                  :
                </label>
              </Col>
              <Col xs={18}>
                <FormItem
                  {...formItemLayout}
                  className="preferenceInput"
                  // label={wMatrix('Confirm Password')}
                  validateStatus={formUI.passwordConfirm.validateStatus}
                  help={formUI.passwordConfirm.help}
                  style={{ display: (formUI.changingPassword ? 'block' : 'none') }}
                >
                  <Input
                    disabled={!editable}
                    placeholder={wMatrix('preferenceConfirmPasswordPlaceholder')}
                    type="password"
                    onChange={(e) => { this.changePreference('passwordConfirm', e) }}
                    value={preference.passwordConfirm}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row
              style={{ display: 'flex', alignItems: 'center', height: '64px' }}
            >
              <Col
                xs={5}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <label style={{ marginRight: 50, fontWeight: 600, color: '#333' }}>
                  {wMatrix('First Name')}
                  :
                </label>
              </Col>
              <Col xs={18}>
                <FormItem
                  {...formItemLayout}
                  className="preferenceInput"
                  validateStatus={formUI.firstName.validateStatus}
                  help={formUI.firstName.help}
                  style={{ margin: 0 }}
                >
                  <Input
                    disabled={!editable}
                    placeholder={wMatrix('preferenceFirstNamePlaceholder')}
                    onChange={(e) => { this.changePreference('firstName', e) }}
                    value={preference.firstName}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row
              style={{ display: 'flex', alignItems: 'center', height: '64px' }}
            >
              <Col
                xs={5}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <label style={{ marginRight: 50, fontWeight: 600, color: '#333' }}>
                  {wMatrix('Last Name')}
                  :
                </label>
              </Col>
              <Col xs={18}>
                <FormItem
                  {...formItemLayout}
                  className="preferenceInput"
                  validateStatus={formUI.lastName.validateStatus}
                  help={formUI.lastName.help}
                  style={{ margin: 0 }}
                >
                  <Input
                    disabled={!editable}
                    placeholder={wMatrix('preferenceLastNamePlaceholder')}
                    onChange={(e) => { this.changePreference('lastName', e) }}
                    value={preference.lastName}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row
              style={{ display: 'flex', alignItems: 'center', height: '64px' }}
            >
              <Col
                xs={5}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <label style={{ marginRight: 50, fontWeight: 600, color: '#333' }}>
                  {wMatrix('Mobile Phone')}
                  :
                </label>
              </Col>
              <Col xs={18}>
                <div style={{ display: 'flex', width: '90%' }}>
                  <FormItem
                    {...formItemLayout}
                    className="preferenceInput"
                    style={{ margin: 0 }}
                  >
                    <Select
                      onChange={this.switchCountry}
                      style={{ width: 100 }}
                      dropdownMatchSelectWidth={false}
                      value={preference.country}
                    >
                      {this.renderCountryCodeItems()}
                    </Select>
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    className="preferenceInput"
                    validateStatus={formUI.mobilePhone.validateStatus}
                    help={formUI.mobilePhone.help}
                    style={{ margin: 0, flex: 1 }}
                  >
                    <Input
                      placeholder="1234567890"
                      disabled={!editable}
                      onChange={(e) => { this.changePreference('mobilePhone', e) }}
                      value={preference.mobilePhone}
                    />
                  </FormItem>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
        <div>
          <Button
            onClick={this.savePreference}
            disabled={saveButtonLoading}
            style={{ float: 'right', fontWeight: 100 }}
            type="primary"
            loading={saveButtonLoading}
          >
            {saveButtonLoading ? wMatrix('Saving') : wMatrix('Save')}
          </Button>
          {generalPreferenceNotificationType !== ''
            ? (
              <Alert
                style={{ float: 'right', padding: '8px 48px 8px 16px', marginRight: 10 }}
                message={generalPreferenceNotificationType === 'success'
                  ? wMatrix('preferenceGeneralSaveSuccess')
                  : wMatrix('preferenceGeneralSaveFail')}
                type={generalPreferenceNotificationType}
              />
            )
            : null}
        </div>
      </div>
    )
  }
}

export default helper()(GeneralPreference)
