import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { List } from 'antd'
import LandmarksListItem from '@mol/landmarksListItem'

export default class LandmarksList extends Component {
  static propTypes = {
    landmarks: PropTypes.array,
    selectedLandmark: PropTypes.object,
    selectLandmark: PropTypes.func,
    listMode: PropTypes.string.isRequired,
    filterLandmarkStr: PropTypes.string,
  }

  static defaultProps = {
    landmarks: [],
    selectedLandmark: null,
    selectLandmark: () => { },
    filterLandmarkStr: null,
  }

  render() {
    const {
      landmarks, selectedLandmark, selectLandmark, listMode, filterLandmarkStr,
    } = this.props

    return (
      <List bordered>
        {landmarks.map(landmark => (
          <LandmarksListItem
            key={landmark.id}
            landmark={landmark}
            isSelected={selectedLandmark ? landmark.id === selectedLandmark.id : false}
            selectLandmark={selectLandmark}
            listMode={listMode}
            filterLandmarkStr={filterLandmarkStr}
          />
        ))}
      </List>
    )
  }
}
