import React, { Component } from 'react'
import PropTypes from 'prop-types'

/**
 * @description This is a wrapper used to wrap any item that you want to be
 * programatically scrolled to within a <ScrollView> or <SectionScrollView>
 *
 * @NOTE Editing/changing this file will effect both scrollView.js and sectionScrollView.js
 */

export default class ScrollElement extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    unregister: PropTypes.func.isRequired,
    show: PropTypes.bool,
    children: PropTypes.any,
  }

  static defaultProps = {
    show: true,
    children: null,
  }

  /** Create reference for this element */
  constructor(props) {
    super(props)
    this.element = React.createRef()
  }

  /** Register this reference with its parent scrollview */
  componentDidMount = () => {
    const { name, register } = this.props
    register(name, this.element)
  }

  /** Unregister this reference with its parent scrollview */
  componentWillUnmount = () => {
    const { name, unregister } = this.props
    unregister(name)
  }

  /** Simply wrap the children with the referencable element */
  render() {
    const { children, show } = this.props
    return (
      <div ref={this.element} className="scrollElement div" style={{ display: show ? 'block' : 'none' }}>
        {children}
      </div>
    )
  }
}
