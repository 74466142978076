import React from 'react'
import PropTypes from 'prop-types'
import { Divider, Row, Col } from 'antd'

const styles = {
  label: {
    marginRight: 50,
    fontWeight: 600,
    color: '#333',
  },
}

export default class ContactUs extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
  }

  render() {
    const { wMatrix } = this.props
    return (
      <div style={{ width: '80%' }}>
        <h2>{wMatrix('contactUs')}</h2>
        <Divider style={{ width: '80%', marginTop: 0 }} />
        <Row type="flex" justify="start" align="middle">
          <Col xs={2} />
          <Col xs={8} style={{ display: 'flex' }}>
            <label style={styles.label}>
              {wMatrix('call')}
              :
            </label>
            800-293-0420
          </Col>
          <Col xs={8} style={{ display: 'flex' }}>
            <label style={styles.label}>
              {wMatrix('email')}
              :
            </label>
            customerservice@rhinofleettracking.com
          </Col>
        </Row>
      </div>
    )
  }
}
