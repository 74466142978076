import React, { Component } from 'react'
import queryConnector from '@graphql/queryConnector'
import consumerConnector from '@graphql/consumerConnector'
import mutationConnector from '@graphql/mutationConnector'
import { getUserAlertSettings, moslQuery } from '@graphql/query'
import { updateAlertSettings, updateAlertMOSL } from '@graphql/mutation'
import { compose } from 'react-apollo'
import clone from 'clone'
import equal from 'deep-equal'
import PropTypes from 'prop-types'

/**
 * @todo
 *
 * - may be able to remove the get speed device list.. need to see what information
 * is queried differently
 * - IMPROVE/ remove orginized settings loop.. may be unecessary with new get alert settings query
 */

const notifSettingsHOC = () => (WrappedComponent) => {
  class NotifSettingsHOC extends Component {
    state = {
      alertSettings: {},
      alertSettingDevList: [],
      ifShowAlert: false,
      saveResponseAlertType: '',
    }

    static propTypes = {
      devices: PropTypes.array.isRequired,
      alertSettingsData: PropTypes.object.isRequired,
      moslInfo: PropTypes.object.isRequired,
      updateAlertSettingsMutation: PropTypes.func,
      updateAlertMOSLMutation: PropTypes.func,
    }

    static defaultProps = {
      updateAlertSettingsMutation: null,
      updateAlertMOSLMutation: null,
    }

    componentDidMount = () => {
      this.updateGeneralAlertSettings = this.updateGeneralAlertSettings.bind(this)
      this.getAlertSettings = this.getAlertSettings.bind(this)
    }

    componentDidUpdate = (prevProps) => {
      const { alertSettingsData } = this.props
      if (!equal(alertSettingsData, prevProps.alertSettingsData)) {
        if (alertSettingsData.data && !alertSettingsData.loading) {
          const alerts = alertSettingsData.data.alertSettings
          // organizes alert settings by eventTypeId
          this.organizeAlertSettingData(alerts)
        }
      }
      // console.log(this.props)
    }

    // takes alert setting data and organizes it for use in children
    organizeAlertSettingData = (alertSettings) => {
      // maybe organize into global and individual?
      const organizedSettings = {}
      alertSettings.forEach((setting) => {
        if (!organizedSettings[setting.numEventTypeId]) {
          organizedSettings[setting.numEventTypeId] = [setting]
        } else {
          organizedSettings[setting.numEventTypeId].push(setting)
        }
      })
      this.setState({ alertSettings: organizedSettings })
    }

    // pulls current user's alert settings from dbo.gps.v3_AlertSettings for selected eventType
    getAlertSettings = (eventType) => {
      const { alertSettingsData } = this.props
      // updates event type and forces refetch on query
      alertSettingsData.setVariables({ numEventTypeIds: eventType })
      // values are then updated in component update
    }

    getAlertSettingsDeviceList = (eventTypeIds) => {
      const devList = []
      const { devices } = this.props
      const { alertSettings } = this.state
      // loop through every device
      for (let i = 0; i < devices.length; i += 1) {
        // create standard object for device
        const deviceObj = {
          id: devices[i].id,
          alias: devices[i].alias,
          numSpeed: null, // speed threshold
          numMOSL: null, // miles over
          ynEmail: false,
          ynText: false,
          ynPush: false,
          ynApp: false,
          groups: devices[i].groups,
          labels: devices[i].labels,
          accel: false, // 14
          decel: false, // 15
          movementInitiated: false,
          movementConcluded: false,
          reconnect: false, // 21
          disconnect: false, // 3
          primaryOn: false, // 37
          primaryOff: false, // 38
          secondaryOn: false, // 39
          secondaryOff: false, // 40
          // hoo: {},
          landmarks: [],
        }

        eventTypeIds.forEach((id) => {
          // if they have settings for that particular event type
          if (alertSettings[id]) {
            for (let j = 0; j < alertSettings[id].length; j += 1) {
              if (alertSettings[id][j].numVehicleDeviceId === devices[i].id) {
                // yn values should be same as miles over, but double check
                if (deviceObj.ynEmail === false) {
                  deviceObj.ynEmail = alertSettings[id][j].ynEmail
                }
                if (deviceObj.ynText === false) {
                  deviceObj.ynText = alertSettings[id][j].ynText
                }
                if (deviceObj.ynPush === false) {
                  deviceObj.ynPush = alertSettings[id][j].ynPush
                }
                if (deviceObj.ynApp === false) {
                  deviceObj.ynApp = alertSettings[id][j].ynApp
                }
                if (id === 30) deviceObj.numMOSL = alertSettings[id][j].numMOSL
                if (id === 1) deviceObj.numSpeed = alertSettings[id][j].numSpeed
                // landmarks
                if (id === 6) deviceObj.landmarks = alertSettings[id][j].landmarks
                // hoo
                if (id === 5) deviceObj.hoo = alertSettings[id][j].hoo
                /**
                 * if they have email, text, or push applied, then
                 * they get these alerts. else, they don't
                 */
                if (alertSettings[id][j].ynEmail || alertSettings[id][j].ynText
                  || alertSettings[id][j].ynPush || alertSettings[id][j].ynApp) {
                  // accel
                  if (id === 14) deviceObj.accel = true
                  // decel
                  if (id === 15) deviceObj.decel = true
                  // movementInitiated
                  if (id === 20) deviceObj.movementInitiated = true
                  // movementConcluded
                  if (id === 27) deviceObj.movementConcluded = true
                  // reconn
                  if (id === 21) deviceObj.reconnect = true
                  // disconn
                  if (id === 3) deviceObj.disconnect = true
                  // primOn
                  if (id === 37) deviceObj.primaryOn = true
                  // primOff
                  if (id === 38) deviceObj.primaryOff = true
                  // secondaryOn
                  if (id === 39) deviceObj.secondaryOn = true
                  // primOn
                  if (id === 40) deviceObj.secondaryOff = true
                }
                break
              }
            }
          }
        })
        devList.push(deviceObj)
      }
      this.setState({ alertSettingDevList: devList })
      return devList
    }

    updateMOSL = (numMOSL) => {
      const { alertSettingsData, updateAlertMOSLMutation } = this.props
      // updateAlertMOSL
      if (numMOSL) {
        return updateAlertMOSLMutation({
          variables: {
            numMOSL: (numMOSL || undefined),
          },
        }).then((response) => {
          // update the alert settings (which will update the tables)
          if (response != null) {
            alertSettingsData.refetch()
          }
          return response
        })
      }
      return null
    }

    /**
     * @description Formats HOO object for saving. If there is a split but the first part ends where
     * the second part begins, the split is taken away.
     */
    formatHOOForSave = (HOOObj) => {
      if (!HOOObj) {
        return null
      }
      const formattedObj = {}
      const keys = Object.keys(HOOObj)
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i]
        const day = HOOObj[key]
        if (day.length === 2 && day[0].start !== null && day[0].end === day[1].start) {
          // combine split to one timeframe
          formattedObj[key] = [
            {
              start: day[0].start,
              end: day[1].end,
            },
            {
              start: null,
              end: null,
            },
          ]
        } else if (day.length === 1) {
          formattedObj[key] = [
            {
              start: day[0].start,
              end: day[0].end,
            },
            {
              start: null,
              end: null,
            },
          ]
        } else {
          formattedObj[key] = HOOObj[key]
        }
      }
      return formattedObj
    }

    // Creates/updates current user's alert settings
    updateGeneralAlertSettings = (devices, eventTypes, ynEmail, ynText, ynPush, ynApp,
      numSpeed, numMOSL, landmarkDetails, HOOObj) => {
      const { alertSettingsData, updateAlertSettingsMutation } = this.props
      const HOOVals = this.formatHOOForSave(HOOObj)
      return updateAlertSettingsMutation({
        variables: {
          numVehicleDeviceIds: devices,
          numEventTypeIds: eventTypes,
          ynEmail,
          ynText,
          ynPush,
          ynApp,
          numSpeed: (numSpeed || null),
          landmarkDetails: (landmarkDetails || null),
          HOO: HOOVals,
        },
      }).then((response) => {
        // update the alert settings (which will update the tables)
        if (response && devices != null) {
          if (response.data && response.data.updateAlertSettings.code === 1000) {
            // refetch
            alertSettingsData.refetch()
            return true
          }
          return false
        }
        return false
      }).catch(() => false)
    }

    // used to test search cases of partial group names
    inGroupList = (groupList, searchVal) => {
      // create an array of just the names
      const groupArray = groupList.map(group => group.name)
      // test each name in array to have reactive search work with partial group names
      for (let i = 0; i < groupArray.length; i += 1) {
        if (groupArray[i].toLowerCase().indexOf(searchVal.toLowerCase()) !== -1) {
          return true
        }
      }
      return false
    }

    filterDeviceByTag = (paraDeviceList, filterTags) => {
      if (paraDeviceList) {
        const validDeviceList = []

        const deviceList = clone(paraDeviceList)

        for (let i = 0; i < deviceList.length; i += 1) {
          const d = deviceList[i]
          if (filterTags.length === 0) {
            /* No need to check filter tags if no tag is selected */
            validDeviceList.push(d)
          } else {
            const singleDeviceTags = []
            if (d.groups) {
              for (let x = 0; x < d.groups.length; x += 1) {
                singleDeviceTags.push(d.groups[x].name)
              }
            }
            if (d.labels) {
              for (let y = 0; y < d.labels.length; y += 1) {
                singleDeviceTags.push(d.labels[y].name)
              }
            }
            let ifDeviceHasTag = 1
            for (let j = 0; j < filterTags.length; j += 1) {
              /* Check if this device has all the selected filter tags */
              ifDeviceHasTag *= singleDeviceTags.includes(filterTags[j])
            }
            if (ifDeviceHasTag) {
              validDeviceList.push(d)
            }
          }
        }
        return validDeviceList
      }
      return []
    }

    formatNullHOO = (day) => {
      if (day) {
        if (day[0].start === null
          && day[0].end === null
          && day[1].start === null
          && day[1].end === null) {
          return [
            {
              start: 0,
              end: 1440,
            },
            {
              start: null,
              end: null,
            },
          ]
        }
        return day
      }
      return null
    }

    setIfShowAlert = (ifShowAlert, type) => {
      this.setState({ ifShowAlert, saveResponseAlertType: type })
    }

    render = () => {
      // moslInfo.data.mosl
      const { moslInfo, alertSettingsData } = this.props
      const {
        alertSettings, alertSettingDevList, ifShowAlert, saveResponseAlertType,
      } = this.state
      let MOSLSetting = 10
      if (moslInfo && moslInfo.data && moslInfo.data.mosl) {
        MOSLSetting = moslInfo.data.mosl
      }

      return (
        <WrappedComponent
          refetchAlertSettings={alertSettingsData.refetch}
          {...this.props}
          getUserAlertSettings={this.getAlertSettings}
          alertSettings={alertSettings}
          updateGeneralAlertSettings={this.updateGeneralAlertSettings}
          updateUserMOSL={this.updateMOSL}
          alertSettingDevList={alertSettingDevList}
          inGroupList={this.inGroupList}
          filterListByTags={this.filterListByGroup}
          filterDeviceByTag={this.filterDeviceByTag}
          setSelectedEventTypes={this.setSelectedEventTypes}
          getAlertSettingsDeviceList={this.getAlertSettingsDeviceList}
          formatNullHOO={this.formatNullHOO}
          setIfShowAlert={this.setIfShowAlert}
          ifShowAlert={ifShowAlert}
          saveResponseAlertType={saveResponseAlertType}
          numMOSL={MOSLSetting}
        />
      )
    }
  }

  return compose(
    consumerConnector(),
    queryConnector(moslQuery, null, 'moslInfo', true),
    queryConnector(getUserAlertSettings, { numEventTypeIds: [17] }, 'alertSettingsData'),
    mutationConnector(updateAlertSettings, 'updateAlertSettingsMutation'),
    mutationConnector(updateAlertMOSL, 'updateAlertMOSLMutation'),
  )(NotifSettingsHOC)
}

export default notifSettingsHOC
