import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const BUBBLE_TYPES = {
  INBOUND: 'inbound',
  OUTBOUND: 'outbound',
}

const styles = {
  messageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: '10px 10px 10px 10px',
    position: 'relative',
  },
  bubbleWrapper: {
    borderRadius: 4,
    position: 'relative',
    minWidth: 125,
    maxWidth: '75%',
    minHeight: 35,
    padding: '8px 15px',
    wordWrap: 'break-word',
  },
  arrowOutbound: {
    height: 10,
    width: 20,
    backgroundColor: '#4382FF',
    clipPath: 'polygon(0 0, 100% 100%, 80% 0)',
    position: 'absolute',
    bottom: -9,
    right: 10,
    left: 'auto',
  },
  arrowInbound: {
    height: 10,
    width: 20,
    backgroundColor: '#EBE8DF',
    clipPath: 'polygon(20% 0, 0 100%, 100% 0)',
    position: 'absolute',
    bottom: -9,
    right: 'auto',
    left: 10,
  },
  bubbleTailInbound_Deprecated: {
    content: ' ',
    position: 'absolute',
    width: 0,
    height: 0,
    top: 'auto',
    bottom: '-10px',
    border: '6px solid',
    borderRadius: 2,
    borderColor: '#EBE8DF transparent transparent #EBE8DF',
    left: '8px',
    right: 'auto',
  },
  bubbleTailOutbound_Deprecated: {
    content: ' ',
    position: 'absolute',
    width: 0,
    height: 0,
    top: 'auto',
    bottom: '-10px',
    border: '6px solid',
    borderRadius: 2,
    borderColor: '#4382FF #4382FF transparent transparent',
    left: 'auto',
    right: '8px',
  },
  dateSent: {
    fontSize: 10,
    color: '#000000A6',
    paddingLeft: 10,
    paddingRight: 10,
  },
  content: {
    margin: 15,
  },
  unreadOverlay: {
    content: '',
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255,255,255,0.4)',
  },
}
/**
 * @todo
 * - add highlight color and animation for when message changes from unread to read
 */

export default class MessengerBubble extends PureComponent {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    type: PropTypes.string,
    content: PropTypes.string,
    deliveredDate: PropTypes.string,
    read: PropTypes.bool,
    // status: PropTypes.string,
  }

  static defaultProps = {
    type: BUBBLE_TYPES.OUTBOUND,
    content: null,
    deliveredDate: null,
    read: false,
    // status: 'pending',
  }

  render() {
    const {
      type, content, deliveredDate, wMatrix, read,
    } = this.props

    const dateText = deliveredDate ? moment(deliveredDate, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY hh:mm a') : wMatrix('pending')
    /**
     * Adjust styles based on message bubble type
     */
    const dynamicStyles = {
      bubbleWrapper: {
        ...styles.bubbleWrapper,
        backgroundColor: type === BUBBLE_TYPES.OUTBOUND ? '#4382FF' : '#EBE8DF',
        color: type === BUBBLE_TYPES.OUTBOUND ? 'white' : '#000000D9',
      },
      messageWrapper: {
        ...styles.messageWrapper,
        alignItems: type === BUBBLE_TYPES.OUTBOUND ? 'flex-end' : 'flex-start',
      },
    }

    return (
      <div style={dynamicStyles.messageWrapper}>
        <div style={styles.dateSent}>
          {dateText}
        </div>
        <div style={dynamicStyles.bubbleWrapper}>
          {content}
          <div
            style={type === BUBBLE_TYPES.OUTBOUND ? styles.arrowOutbound : styles.arrowInbound}
          />
        </div>
        {!read ? <div style={styles.unreadOverlay} /> : null}
      </div>
    )
  }
}
