import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  Menu,
  // Button,
  // Icon
} from 'antd'


const MenuItem = Menu.Item

export default class NotificationMenu extends Component {
  /**
     * NOTE: click handler is in parent (NotificationSettings.js)
     */

    static propTypes = {
      notifications: PropTypes.object.isRequired,
      currentSelection: PropTypes.string.isRequired,
      editMode: PropTypes.bool,
      collapseVal: PropTypes.bool,
      handleSelect: PropTypes.func.isRequired,
      getPermission: PropTypes.func.isRequired,
    }


    static defaultProps = {
      editMode: false,
      collapseVal: false,
    }

    render() {
      const {
        currentSelection, collapseVal, handleSelect, getPermission, editMode, notifications,
      } = this.props
      return (
        <div>
          <Menu
            onClick={handleSelect}
                // style={{width: 274}}
            defaultSelectedKeys={[currentSelection]}
            mode="inline"
            inlineCollapsed={collapseVal}
          >
            { /* Remove Stop Alert from Menu. 2020-06-26 Jay Li */}
            { [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13].map((menuItemKey) => {
              if (getPermission('Fuelcard', 'ynCreate') || menuItemKey !== 12) {
                return (
                  <MenuItem
                    key={menuItemKey}
                    disabled={!!(((currentSelection !== menuItemKey) && editMode))}
                  >
                    {notifications[menuItemKey].title}
                  </MenuItem>
                )
              }
              return null
            })}
          </Menu>
        </div>
      )
    }
}
