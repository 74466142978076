import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Tabs } from 'antd'
import GeneralPreference from '@mol/modal/generalPreference'
import PersonalLabels from '@org/modal/personalLabels'
import NotificationSettings from '@org/modal/notificationSettings'
import PreferenceHOC from '@hoc/preference.hoc'

class PreferenceModal extends Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    countryCodes: PropTypes.array.isRequired,
    saveAdmin: PropTypes.func,
    modalUI: PropTypes.object,
  }

  static defaultProps = {
    modalUI: null,
    saveAdmin: null,
  }

  render() {
    const { TabPane } = Tabs
    const {
      wMatrix, modalUI, saveAdmin, closeModal, countryCodes,
    } = this.props

    return (
      <Modal
        visible={modalUI.ifShowModal}
        onOk={saveAdmin}
        onCancel={closeModal}
        footer={null}
        width={1024}
        zIndex={10}
        maskClosable={false}
      >
        <h1>{wMatrix('Preferences')}</h1>
        <Tabs defaultActiveKey="1" onChange={this.switchAdminPanel}>
          <TabPane tab={wMatrix('General')} key="1">
            <GeneralPreference
              {...this.props}
              countryCodes={countryCodes}
            />
          </TabPane>

          <TabPane tab={wMatrix('Personal Labels')} key="2">
            <PersonalLabels
              {...this.props}
            />
          </TabPane>

          <TabPane tab={wMatrix('Notifications')} key="3">
            <NotificationSettings
              {...this.props}
            />
          </TabPane>
        </Tabs>
      </Modal>
    )
  }
}

export default PreferenceHOC()(PreferenceModal)
