/* eslint-disable object-curly-newline */
import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Divider, Row, Col, Form, Input, Button, Checkbox } from 'antd'
import helper from '@helper'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
}

class SubmitTicketForm extends React.Component {
  state = {
    liveMap: false,
    timeline: false,
    service: false,
    reports: false,
    admin: false,
    preference: false,
    other: false,
    alert: {
      display: 'none',
      icon: 'check-circle',
      message: '',
      type: 'success',
    },
  }

  baseState = { ...this.state }

  static propTypes = {
    /** @Helper */
    wMatrix: PropTypes.func.isRequired,
    validatePhone: PropTypes.func.isRequired,
    /** @Form */
    form: PropTypes.object.isRequired,
    /** @UserResourcesHOC */
    handleCreateTicket: PropTypes.func.isRequired,
  }

  handleChangeCheckbox = (event) => {
    this.setState({ [event.target.id]: event.target.checked })
  }

  /**
   * Handles validation/ error checking for phone number
   * @param {*} rule
   * @param {*} value
   * @param {Function} callback callback function for validator
   */
  validatePhoneNumber = (rule, value, callback) => {
    const { validatePhone } = this.props
    try {
      if (value) {
        const validResponse = validatePhone(value)
        if (!validResponse.valid) {
          throw new Error(validResponse.help)
        }
      }
      callback()
    } catch (err) {
      callback(err)
    }
  }

  handleSubmit = () => {
    const { form, handleCreateTicket } = this.props
    const relatedFeatures = { ...this.state }
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        handleCreateTicket(
          values.subject,
          values.email,
          values.phoneNumber,
          relatedFeatures,
          values.details,
        ).then(() => {
          form.resetFields()
          const success = {
            display: 'block',
            icon: 'check-circle',
            message: 'Success',
            type: 'success',
          }
          this.setState({ alert: success })
          setTimeout(() => {
            this.setState(this.baseState)
          }, 5000)
        }).catch(() => {
          const error = {
            display: 'block',
            icon: 'exclamation-circle',
            message: 'Something went wrong',
            type: 'error',
          }
          this.setState({ alert: error })
        })
      }
    })
  }

  render() {
    const { form, wMatrix } = this.props
    const { getFieldDecorator } = form
    const { liveMap, timeline, service, reports,
      admin, preference, other, alert } = this.state

    return (
      <div style={{ marginTop: 50 }}>
        <h2>{wMatrix('submitTicket')}</h2>
        <div style={{ width: '80%' }}>
          <Divider style={{ marginTop: 0 }} />
        </div>
        <Form onSubmit={null}>
          <Form.Item label={wMatrix('subject')} {...formItemLayout}>
            {getFieldDecorator('subject', {
              rules: [
                { required: true, message: wMatrix('This field is required') },
              ],
            })(
              <Input placeholder={wMatrix('ticketSubject')} />,
            )}
          </Form.Item>

          <Form.Item label={wMatrix('email')} {...formItemLayout}>
            {getFieldDecorator('email', {
              rules: [
                { type: 'email', message: wMatrix('Invalid email') },
                { required: true, message: wMatrix('This field is required') },
              ],
            })(
              <Input placeholder={wMatrix('email')} />,
            )}
          </Form.Item>

          <Form.Item label={wMatrix('phoneNumber')} {...formItemLayout}>
            {getFieldDecorator('phoneNumber', {
              rules: [
                { required: false, message: wMatrix('') },
                { validator: this.validatePhoneNumber },
              ],
            })(
              <Input placeholder={wMatrix('phoneNumberToReach')} />,
            )}
          </Form.Item>

          <Row type="flex" justify="start" align="middle" style={{ marginBottom: 20 }}>
            <Col xs={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <label style={{ marginRight: 8 }}>
                {wMatrix('relatedFeatures')}
                :
              </label>
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={6}>
                  <Checkbox
                    id="liveMap"
                    checked={liveMap}
                    onChange={e => this.handleChangeCheckbox(e)}
                  >
                    {wMatrix('liveMap')}
                  </Checkbox>
                </Col>
                <Col xs={6}>
                  <Checkbox
                    id="timeline"
                    checked={timeline}
                    onChange={e => this.handleChangeCheckbox(e)}
                  >
                    {wMatrix('timeline')}
                  </Checkbox>
                </Col>
                <Col xs={6}>
                  <Checkbox
                    id="service"
                    checked={service}
                    onChange={e => this.handleChangeCheckbox(e)}
                  >
                    {wMatrix('service')}
                  </Checkbox>
                </Col>
                <Col xs={6}>
                  <Checkbox
                    id="reports"
                    checked={reports}
                    onChange={e => this.handleChangeCheckbox(e)}
                  >
                    {wMatrix('reports')}
                  </Checkbox>
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Col xs={6}>
                  <Checkbox
                    id="admin"
                    checked={admin}
                    onChange={e => this.handleChangeCheckbox(e)}
                  >
                    {wMatrix('Admin')}
                  </Checkbox>
                </Col>
                <Col xs={6}>
                  <Checkbox
                    id="preference"
                    checked={preference}
                    onChange={e => this.handleChangeCheckbox(e)}
                  >
                    {wMatrix('preference')}
                  </Checkbox>
                </Col>
                <Col xs={6}>
                  <Checkbox
                    id="other"
                    checked={other}
                    onChange={e => this.handleChangeCheckbox(e)}
                  >
                    {wMatrix('other')}
                  </Checkbox>
                </Col>
              </Row>
            </Col>
          </Row>

          <Form.Item label={wMatrix('details')} {...formItemLayout}>
            {getFieldDecorator('details', {
              rules: [
                { required: true, message: wMatrix('This field is required') },
              ],
            })(
              <Input.TextArea rows={4} placeholder={wMatrix('pleaseDescribe')} />,
            )}
          </Form.Item>
        </Form>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div>
            <Alert
              style={{
                display: alert.display,
                width: '275px',
                margin: '0px 10px',
              }}
              closable
              showIcon
              iconType={alert.icon}
              message={alert.message}
              type={alert.type}
            />
          </div>
          <div>
            <Button onClick={this.handleSubmit} type="primary">
              {wMatrix('submit')}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

const SubmitTicket = Form.create({})(SubmitTicketForm)

export default helper()(SubmitTicket)
