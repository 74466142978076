/**
 * RULE 1: 1 level ONLY
 * RULE 2: Keys can be MAX 4 words long
 * RULE 3: Unique keys
 * RULE 4: Leave a comment JSDOC / markup for functions
 * RULE 5: DON'T TALK ABOUT LOCALIZATION
 */
class EN {
  constructor() {
    this.speedMeasure = 'mph'
    this.distanceMeasure = 'mile'
    this.dictionary = {
      'Enter': 'Enter',
      'Exit': 'Exit',
      'Trigger on Enter': 'Trigger on Enter',
      'Trigger on Exit': 'Trigger on Exit',
      'Trigger': 'Trigger',
      'Landmark Name': 'Landmark Name',
      'Enter and Exit': 'Enter and Exit',
      'app' : 'App',
      'driver_confirmed' : 'Driver Confirmed',
      'driver_unconfirmed' : 'Driver Unconfirmed',
      'unread': 'Unread',
      'Unassigned': 'Unassigned',
      'powerOn': 'Power On',
      'powerOff': 'Power Off',
      'Total Devices': 'Total Devices',
      'serviceTech': 'Service Tech',
      'Driver Updated Successfully': 'Driver Updated Successfully',
      'Latitude': 'Latitude',
      'Longitude': 'Longitude',
      'Cleaned': 'Cleaned',
      'Service Status': 'Service Status',
      'speedLimit': 'Speed Threshold',
      'speedLimitDescription': 'If you choose to enable speed limit alerts, you will be notified when this speed limit is exceeded',
      'defaultTimeRange': 'Default Timeline Range',
      'defaultTimeRangeDescription': 'The default timeline range in the timeline section',
      'thirtyHistory': 'Last 30 days',
      'dailyHistory': 'Day-by-day',
      'Speeding at': 'Speeding at',
      'Latitude': 'Latitude',
      'Longitude': 'Longitude',
      'lowBattery': 'Low Battery',
      'over the road speed': 'over the road speed',
      'Exited Landmark': 'Exited Landmark',
      'Entered Landmark': 'Entered Landmark',
      'Driving at': 'Driving at',
      'Diagnose Trouble Code': 'Diagnose Trouble Code',
      'Speed Threshold': 'Speed Threshold',
      'First Move Time': 'First Move Time',
      'Last Move Time': 'Last Move Time',
      'reportTooLarge': 'Requested Report Too Large',
      'reportTooLargeDescription': 'You can choose fewer vehicles or a smaller time frame and try again, or request a download below',
      'reportDownload': 'Report Download',
      'reportDownloadDescription': 'We will send the report to the emails when it is ready',
      'saveReportDownloadSuccess': 'Request submited successfully, we will email the report when it is ready',
      'saveReportDownloadError': 'Failed to submit the request, please try again or contact us',
      'request': 'Request',
      'download': 'Download',
      /** @Header (using nav keys) */
      map: 'Map',
      reports: 'Reports',
      service: 'Service',
      'driver-scorecard': 'Driver Scorecard',
      'video-clips': 'Video Clips',
      'Coming Soon': 'Coming Soon',
      landmarks: 'Landmarks',
      landmark: 'Landmark',
      distance: 'Distance',
      eld: 'ELD',
      'field-service': 'Field Service',
      videoClips: 'Video Clips',
      /* end of header */

      'Reports': 'Reports',
      'Driver Scorecard': 'Driver Scorecard',

      'activeTickets': 'Active Tickets',
      'ticketStatus': 'Ticket Status',
      'serviceType': 'Service Type',
      'Upcoming': 'Upcoming',
      'serviceCost': 'Service Cost',
      'topCostingVehicles': 'Top Expensive Vehicles',
      'highestCostingVehicles': 'Highest Costing Vehicles',
      'createService': 'Create Service',

      /* event type */
      'Idle': 'Idle',
      "Landmark GeoFence Entered and Exited": "Landmark GeoFence Entered and Exited",
      "Low Battery": "Low Battery",
      "Stop Begin": "Stop Begin",
      "Device Off Route": "Device Off Route",
      "Diagnostic Trouble Code": "Diagnostic Trouble Code",
      "Disconnect": "Disconnect",
      "Dynamic GeoFence": "Dynamic GeoFence",
      "Engine RPM": "Engine RPM",
      "Excessive Speeding": "Excessive Speeding",
      "Fast Start": "Fast Start",
      "Fuel Card Misuse": "Fuel Card Misuse",
      "Stop End": "Stop End",
      "Hard Braking": "Hard Braking",
      "Harsh Turn": "Harsh Turn",
      "High Temperature": "High Temperature",
      "Idle Begin": "Idle Begin",
      "Landmark Entered": "Landmark Entered",
      "Landmark Exited": "Landmark Exited",
      "Low Battery": "Low Battery",
      "Low Fuel": "Low Fuel",
      "Low Temperature": "Low Temperature",
      "Malfunction Indicator": "Malfunction Indicator",
      "Movement Concluded": "Movement Concluded",
      "Movement Initiated": "Movement Initiated",
      "Panic Button Activated": "Panic Button Activated",
      "Power Loss": "Power Loss",
      "PTO Primary On": "PTO Primary On",
      "PTO Primary Off": "PTO Primary Off",
      "PTO Secondary On": "PTO Secondary On",
      "PTO Secondary Off": "PTO Secondary Off",
      "PTO Tertiary On": "PTO Tertiary Off",
      "PTO Tertiary Off": "PTO Tertiary Off",
      "ptoPrimaryOn":"PTO Primary On",
      "ptoPrimaryOff":"PTO Primary Off",
      "ptoSecondaryOn":"PTO Secondary On",
      "ptoSecondaryOff":"PTO Secondary Off",
      "ptoTertiaryOn":"PTO Tertiary On",
      "ptoTertiaryOff":"PTO Tertiary Off",
      "Reconnect": "Reconnect",
      "Speed Limit": "Speed Limit",
      "Speeding End Inside Boundary": "Speeding End Inside Boundary",
      "Speeding Start Inside Boundary": "Speeding Start Inside Boundary",
      "Stop": "Stop",
      "Unauthorized Ignition On": "Unauthorized Ignition On",
      "Unauthorized Movement": "Unauthorized Movement",
      "Idle End": "Idle End",
      "Ignition On": "Ignition On",
      "Ignition Off": "Ignition Off",
      "Trip End": "Trip End",
      "Trip Begin": "Trip Begin",
      "Landmark Entered": "Landmark Entered",
      "Landmark Exited": "Landmark Exited",
      "Service Due": "Service Due",
      "idleBegin": "Idle Begin",
      "idleEnd": "Idle End",
      /* end of event type */
      /* service types */
      "Oil Change": "Oil Change",
      "Maintenance": "Maintenance",
      "Tire Rotation": "Tire Rotation",
      "Equipment Service": "Equipment Service",
      "Brake Replacement": "Brake Replacement",
      "Fuel Filter Change": "Fuel Filter Change",
      "Tire Replacement": "Tire Replacement",
      "Air Filter Change": "Air Filter Change",
      "Hydraulic Fluid Change": "Hydraulic Fluid Change",
      "Transmission Filter Change": "Transmission Filter Change",
      "Transmission Fluid Change": "Transmission Fluid Change",
      "Battery Replacement": "Battery Replacement",
      "Belt Replacement": "Belt Replacement",
      "Inspection": "Inspection",
      "Registration": "Registration",
      "Differential Service": "Differential Service",
      "Fan Gear Box Service": "Fan Gear Box Service",
      "DEF Pump Flter": "DEF Pump Flter",
      "DPF Clean": "DPF Clean",
      "DEF Filter Change": "DEF Filter Change",
      "DOT Inspection": "DOT Inspection",
      "Alignment": "Alignment",
      "Vehicle Rotation": "Vehicle Rotation",
      "Coolant Flush": "Coolant Flush",
      "Lubricate Tow Body": "Lubricate Tow Body",
      "Lubricate Chasis": "Lubricate Chasis",
      "Engine Tune Up": "Engine Tune Up",
      "Shock Replacement": "Shock Replacement",
      "LF Steer Tire": "LF Steer Tire",
      "RF Steer Tire": "RF Steer Tire",
      "LF Drive Tire Set": "LF Drive Tire Set",
      "RF Drive Tire Set": "RF Drive Tire Set",
      "LR Drive Tire Set": "LR Drive Tire Set",
      "RR Drive Tire Set": "RR Drive Tire Set",
      "Lift Axle Set": "Lift Axle Set",
      "Cabin Air Filter": "Cabin Air Filter",
      "Sticker renewal": "Sticker renewal",
      "License plate sticker renewal": "License plate sticker renewal",
      "Exterior Detail": "Exterior Detail",
      "Interior Detail": "Interior Detail",
      "Full Detail": "Full Detail",
      "Service Performed": "Service Performed",
      "Medical Card": "Medical Card",
      "Drivers license": "Drivers license",
      "Trailer Inspections": "Trailer Inspections",
      "Toll Renewal": "Toll Renewal",
      "Replacement Windshiled": "Replacement Windshiled",
      "Record mileage": "Record mileage",
      "Windshield Replacement": "Windshield Replacement",
      "Factory Recalls": "Factory Recalls",
      "Brake Inspection": "Brake Inspection",
      "Device Usage": "Device Usage",
      "Alternator Replacement": "Alternator Replacement",
      "Radiator": "Radiator",
      "Fuel System Clean and Flush": "Fuel System Clean and Flush",
      "Oil Analysis": "Oil Analysis",
      "Oil Filter": "Oil Filter",
      "Other": "Other",
      "Wheel Chair Lift Inspection": "Wheel Chair Lift Inspection",
      "Blower Motor Oil Change": "Blower Motor Oil Change",
      "Truck Mount Oil Change": "Truck Mount Oil Change",
      "CAT Pump Oil Change": "CAT Pump Oil Change",
      "CAT Pump Service": "CAT Pump Service",
      "Blower Motor Service": "Blower Motor Service",
      "Truck Mount Service": "Truck Mount Service",
      "PTO Service": "PTO Service",
      "Drive Shaft Lubrication": "Drive Shaft Lubrication",
      "Transmission Service": "Transmission Service",
      "Oil Change - Diesel Engine": "Oil Change - Diesel Engine",
      "Reoccurring Maintenance": "Reoccurring Maintenance",
      "Recall": "Recall",
      "DOT Mileage": "DOT Mileage",
      "DOT Biennial Update": "DOT Biennial Update",
      "Test": "Test",
      "AC Repairs": "AC Repairs",
      "Mileage": "Mileage",
      "Oil and Filter Change": "Oil and Filter Change",
      "Suspension and Steering System": "Suspension and Steering System",
      "Belt Inspection": "Belt Inspection",
      "Timing Belt": "Timing Belt",
      "Spark Plugs": "Spark Plugs",
      "Software": "Software",
      "Engine Hours": "Engine Hours",
      "Lift Gate": "Lift Gate",
      "Thermostat and Gasket Assembly": "Thermostat and Gasket Assembly",
      "Suspension and Steering System": "Suspension and Steering System",
      "BIT Inspection": "BIT Inspection",
      "Bulb Change": "Bulb Change", // had space after
      "Cleaned": "Cleaned",
      "DOT Inspection - Tractor": "DOT Inspection - Tractor", // had space after
      "DOT Inspection - Trailer": "DOT Inspection - Trailer",
      "Suspension and Steering System": "Suspension and Steering System", // had space after
      "Tire Pressure Check": "Tire Pressure Check", // had space after
      /* end of service types */
      'Email': 'Email',
      'Phone': 'Phone',
      'Role': 'Role',
      'Labels': 'Labels',
      'Start Date': 'Start Date',
      'End Date': 'End Date',
      'Speed Threshold': 'Speed Threshold',
      'Events': 'Events',
      'Service Types': 'Service Types',
      'Date Range': 'Date Range',
      'Start of the day': 'Start of the day',
      'End of the day': 'End of the day',
      'Daily': 'Daily',
      'Weekly': 'Weekly',
      'Monthly': 'Monthly',
      'Annually': 'Annually',
      'Quarterly': 'Quarterly',
      'Current Day': 'Current Day',
      'Yesterday': 'Yesterday',
      'Current Week': 'Current Week',
      'Last Week': 'Last Week',
      'Current Month': 'Current Month',
      'Last Month': 'Last Month',
      'Current Quarter': 'Current Quarter',
      'Last Quarter': 'Last Quarter',
      'Year To Date': 'Year To Date',
      'All': 'All',
      'serviceDue': 'Service Due',
      'serviceAlertDescription': 'Receive an alert by email for upcoming vehicle service due',
      'theAliasCannotBeEmpty': 'The alias cannot be empty',
      'okToDelete': 'OK to delete',
      /** 11/19/2019 */
      'Phone Carrier': 'Phone Carrier',
      'Please select a phone carrier': 'Please select a phone carrier',
      'Not Selected': 'Not Selected',
      /** 10/16/2019 */
      'fuelcardMisuse': 'Fuel Card Misuse',
      'fuelcardAlertDescription': 'Receive an alert for possible fuelcard misuse',
      /** 10/15/2019 */
      'Saving': 'Saving',
      'saveAlertSuccessMsg': 'Notification settings saved successfully',
      'saveAlertErrorMsg': 'Failed to save notification settings, please try again or contact us',
      /** 10/10/2019 */
      'Panic Button': 'Panic Button',
      'panicButtonDescription': 'The Panic Button Alert is for the driver to notify dispatches that there may be an emergency. Only available on with certain devices and have to been wired in correctly. ',
      'Stop': 'Stop',
      'stopDescription': 'The stop alert is to monitor when a device stops for a specified period of time',
      'MovementInitiatedConcluded': 'Movement Initiated/Concluded',
      'movementAlertDescription': 'Motion alert is to monitor if a vehicle starts or stops',
      'Movement Initiated': 'Movement Initiated',
      'Movement Concluded': 'Movement Concluded',
      'preferenceGeneralSaveSuccess': 'Preference settings saved successfully',
      'preferenceGeneralSaveFail': 'Failed to save the settings, please make sure the inputs are valid. If you still experience the same issue please contact us.',
      /** 10/08/2019 */
      'Email already exists': 'Email already exists',
      'User Updated Successfully': 'User Updated Successfully',
      Distance: 'Distance',
      sortBy: 'Sort By',
      searchBy: 'Search By',
      'Sort by: Alias': 'Sort by: Alias',
      'Sort by: Distance': 'Sort by: Distance',
      'Search by: Address': 'Search by: Address',
      'Search by: Landmark': 'Search by: Landmark',
      'Type address to calculate': 'Type address to calculate',
      /* 09/04/2019 */
      'Short Trip, less than a mile': 'Short Trip, less than a mile',
      /* 12/18/2018 */
      'Map View': 'Map View',
      'Time Between Trips': 'Time Between Trips',
      Date: 'Date',
      dateAndTime: 'Date And Time',
      Sort: 'Sort',
      Filter: 'Filter',
      Start: 'Start',
      End: 'End',
      ASC: 'ASC',
      DESC: 'DESC',
      Trip: 'Trip',
      'Current Trip': 'Current Trip',
      'Short Trip, Less than a mile': 'Short Trip, less than a mile',
      miles: 'miles',
      List: 'List',
      Map: 'Map',
      View: 'View',
      Satellite: 'Satellite',
      Traffic: 'Traffic',
      Animation: 'Animation',
      Clustering: 'Clustering',
      'Night Mode': 'Night Mode',
      /* 7-17-20 */
      showLandmarks: 'Show Landmarks',
      /* 11/13/2018 */
      Timeline: 'Timeline',
      timeline: 'Timeline',
      Performance: 'Performance',
      /* 10/23/2018 */
      Devices: 'Devices',
      'Search for Device': 'Search for Device',
      'Filter By Tag': 'Filter By Group or Label',
      'driven by': 'driven by',
      'Last Locate': 'Last Locate',
      on: 'on',
      'Current State': 'Current State',
      Speed: 'Speed',
      'Live Map': 'Live Map',
      Service: 'Service',
      Jobs: 'Jobs',
      Job: 'Job',
      Shipments: 'Shipments',
      Shipment: 'Shipment',
      workOrders: 'Work Orders',
      workOrder: 'Work Order',
      Destination: 'Destination',
      Destinations: 'Destinations',
      jobName: 'Job Name',
      dateCreated: 'Date Created',
      dueDate: 'Due Date',
      createJob: 'Create Job',
      addDestination: 'Add Destination',
      addDestinations: 'Add Destinations',
      Order: 'Order',
      completionDate: 'Completion Date',
      additionalItems: 'Additional Items',
      package: 'Package',
      packages: 'Packages',
      work_order: 'Work Order',

      Stopped: 'Stopped',
      Parking: 'Parking',
      Moving: 'Moving',
      'Service Type': 'Service Type',
      'Last Service Date': 'Last Service Date',
      'Service Interval': 'Service Interval',
      'Distance Traveled': 'Distance Traveled',
      'Time Traveled': 'Time Traveled',
      'Service Notes': 'Service Notes',
      'New Label': 'New Label',
      Name: 'Name',
      'Current Labels': 'Current Labels',
      'OK to cancel': 'OK to cancel',
      /* 10/22/2018 */
      Groups: 'Groups',
      Users: 'Users',
      Drivers: 'Drivers',
      'New Group': 'New Group',
      groupPanelDescription1: 'Create a group to help organize and filter your devices. Start by creating a name and selecting a color. Then click the “Next” button to start selecting which devices you want in your group.',
      groupPanelDescription2: 'Choose the devices you would like in your group. When you search your group later, these are the devices that will be filtered into view. When you are finished, click the next button to choose who can view this group.',
      groupPanelDescription3: 'Now choose who will be able to view this group. Once you have finished, click “Save in the lower right.',
      Delete: 'Delete',
      Next: 'Next',
      Cancel: 'Cancel',
      Previous: 'Previous',
      Example: 'Example',
      Preview: 'Preview',
      Comfortable: 'Comfortable',
      Cozy: 'Cozy',
      Compact: 'Compact',
      'Alias Name': 'Alias Name',
      Driver: 'Driver',
      'Current Groups': 'Current Groups',
      Device: 'Device',
      Group: 'Group',
      User: 'User',
      'Permissions/Role': 'Permissions/Role',
      'Viewable Groups': 'Viewable Groups',
      'Search for User': 'Search for User',
      groupHasBeenUpdated: 'Your group has been updated.',
      labelHasBeenUpdated: 'Your label has been updated.',
      'Add User': 'Add User',
      /* 10/21/2018 */
      Save: 'Save',
      Success: 'Success',
      emailTakenError: 'Email is taken. If you own this email, please contact us',
      noPermissionError: 'Your account does not have the permission to do this, if you have a question, please contact us',
      /** Validation */
      'Invalid email': 'Invalid email',
      'This field is required': 'This field is required',
      'Invalid name': 'Invalid name',
      'Invalid phone number': 'Invalid phone number',
      'Fields must match': 'Fields must match',
      'Completion requires a completion date': 'Completion requires a completion date',

      Language: 'Language',
      Measurement: 'Measurement',
      'Profile Settings': 'Profile Settings',
      'Email Address': 'Email Address',
      Password: 'Password',
      'Confirm Password': 'Confirm Password',
      'First Name': 'First Name',
      'Last Name': 'Last Name',
      'Mobile Phone': 'Mobile Phone',
      change: 'change',
      cancel: 'cancel',
      English: 'English',
      Spanish: 'Spanish',
      Miles: 'Miles',
      Kilometers: 'Kilometers',
      preferenceEmailAddressInfo: 'We need to log you out for security reasons if this field is changed',
      preferenceEmailPlaceholder: 'Enter your login email username',
      preferencePasswordPlaceholder: 'Enter the new password',
      preferenceConfirmPasswordPlaceholder: 'Enter the new password again',
      preferenceFirstNamePlaceholder: 'Enter your first name',
      preferenceLastNamePlaceholder: 'Enter your last name',
      'Application Settings': 'Application Settings',
      basicInformation: 'Basic Information',

      basicInfo: 'Basic Info',
      deviceInfo: 'Device Info',
      email: 'Email',
      admin: 'admin',
      Admin: 'Admin',
      General: 'General',
      Preference: 'Preference',
      preference: 'Preference',
      Preferences: 'Preferences',
      'Personal Labels': 'Personal Labels',
      'Log out': 'Log out',
      lastLocate: 'Last Locate',
      vehicleInfo: 'Vehicle Info',
      Notifications: 'Notifications',
      password: 'Password',
      userResources: 'User Resources',
      support: 'Support',
      guides: 'Guides',
      contactUs: 'Contact Us',
      call: 'Call',
      submitTicket: 'Submit a Ticket',
      subject: 'Subject',
      phoneNumber: 'Phone Number',
      relatedFeatures: 'Related Features',
      details: 'Details',
      Details: 'Details',
      ticketSubject: 'Ticket Subject',
      phoneNumberToReach: 'Phone number to reach you',
      pleaseDescribe: 'Please describe the issue you are having',
      liveMap: 'Live Map',

      other: 'Other',
      deviceInstallationVideos: 'Device Installation Videos',
      userGuides: 'User Guides',
      submit: 'Submit',
      year: 'Year',
      upcoming: 'Upcoming',
      tickets: 'Tickets',
      nextDue: 'Next Due',
      in: 'in',

      createLabelStep1: 'Create personal lable to help organize and filter your devices. Start by creating a name and selecting a color. Then click the “Next” button to start selecting which devices you want in your label.',
      createLabelStep2: 'Choose the devices you would like in your label. When you search your label later, these are the devices that will be filtered into view. Once you have finished, click “Save” in the lower right.',

      lastName: 'Last Name',
      firstName: 'First Name',
      name: 'Name',
      phone: 'Phone',
      role: 'Role',
      nameRoleGroups: 'Name, Role, Groups',
      selectOptional: 'Select (Optional)',
      globalAdminViewAll: 'Global and Admin roles can view all groups',

      global: 'Global',
      supervisor: 'Supervisor',
      readOnly: 'Read-Only',
      pleaseSelectPermissionLevel: 'Please select permission level',

      DeviceBattery: 'Device Battery',
      Speeding: 'Speeding',
      DTC: 'DTC',
      HoursOfOperations: 'Hours of Operations',
      Landmarks: 'Landmarks',
      ReconnectDisconnect: 'Reconnect/Disconnect',
      AccelerationDeceleration: 'Acceleration/Deceleration',
      Acceleration: 'Acceleration',
      Deceleration: 'Deceleration',
      Accel: 'Accel',
      Decel: 'Decel',
      Reconnect: 'Reconnect',
      Disconnect: 'Disconnect',
      adjustHours: 'Adjust Hours',

      PTO: 'PTO',
      powerTakeOffSwitches: 'Power Take-Off Switches (PTO)',
      Primary: 'Primary',
      Secondary: 'Secondary',
      On: 'On',
      Off: 'Off',
      noLandmarksApplied: 'No Landmarks Applied.',
      noDetails: 'No Details.',
      notifSwitchOn: 'Notify me when this switch turns on?',
      notifSwitchOff: 'Notify me when this switch turns off?',
      PrimaryPtoSwitch: 'Primary PTO Switch',
      SecondaryPtoSwitch: 'Secondary PTO Switch',
      LandmarkName: 'Landmark Name',
      Type: 'Type',

      Enable: 'Enable',
      Split: 'Split',

      deviceGroupLabel: 'Device, Group, Label',

      deviceBatteryDescription: 'Receive an alert when your device battery is low and needs replacement. Only available for devices using battery as their power supply.',
      speedingDescription: 'The Speed Alert can be used in two ways. Receive alerts if a vehicle ever goes over a certain speed or receive alerts if a vehicle goes a designated amount over the posted speed limit.',
      dtcDescription: 'The DTC alert notifies you when your device receives a DTC (diagnostics code) from your vehicle. This helps you stay on top of vehicle maintenance by immediately addressing the problem.',
      hooDescription: 'The Hours of Operation alert allows you to set hours of operation for a specific device and receive alerts when that device is used outside of those hours.',
      landmarksDescription: 'The Landmark Alert creates an alert for select devices when they are near or enter a specific area.',
      reconDisconDescription: 'Receive alerts every time a select device is disconnected from its port. Receive another alert when that device is reconnected.',
      accelerationDescription: 'The Acceleration/Deceleration Alert notifies you when a vehicle accelerates too quickly and for hard braking. Great for monitoring poor driver behavior.',
      ptoDescription: 'The PTO Alert notifies you when switches on your vehicle are being used, like a crane. Only available with certain devices and must be wired in correctly.',
      stopLengthDescription: 'The amount of minutes that a vehicle needs to idle for a Stop to be created. Can be between 3 and 60 minutes.',

      text: 'Text',
      push: 'Push',
      speedThreshold: 'Speed Threshold',
      milesOver: 'Miles Over',
      milesOverPostedSpeed: 'Miles Over Posted Speed Limit',
      notifyMeMO1: 'Notify me when a device is going ',
      notifyMeMO2: ' mph over the posted speed limit.',
      setYourNewValue: 'Set your new Value',
      edit: 'Edit',
      deviceSpeedThreshold: 'Device Speed Threshold',
      editingDevices: 'Editing Devices',
      selectNotificationType: 'Select Notification Type',
      MOCurrentlySet1: 'Currently set to ',
      MOCurrentlySet2: ' mph over the speed limit.',
      applyToSelectedVehicles: 'Apply to selected vehicles?',
      leaveBlankOrZero: 'Leave blank or zero if you would not like to apply this alert.',
      notifyMeSpeedThresh1: 'Notify me if the selected devices travel at or above ',
      notifyMeSpeedThresh2: ' mph.',

      Icon: 'Icon',
      'Device Manager': 'Device Manager',
      'Device Information': 'Device Information',
      'Vehicle Information': 'Vehicle Information',

      addDriver: 'Add Driver',
      dotNumber: 'DOT Number',
      assignToDevice: 'Assign To Device',
      unassigned: 'Unassigned',
      nameDotDevice: 'Name, DOT, Device...',
      manageELDDrivers: 'Manage ELD Drivers',
      fobId: 'FOB ID',
      areYouSure: 'Are you sure?',
      noTripData: 'No Trip Data',
      endedAt: 'Ended At:',
      timeZone: 'Time Zone',
      mapRefreshRate: 'Map Refresh Rate',

      showOnMap: 'Show on Map',
      onMap: 'On Map',

      orderBy: 'Order By',
      locates: 'Locates',
      events: 'Events',
      showLocates: 'Show Locates',
      showEvents: 'Show Events',
      filterEvents: 'Filter Events',
      ascending: 'Ascending',
      descending: 'Descending',
      apply: 'Apply',
      checkAll: 'Check all',
      expandDrawerToShow: 'expand the drawer to show more',
      enableShowEvents: 'Enable Show Events to use this section',

      circleDescription: 'A circle landmark consists of a single point with a specified radius. You can edit this radius directly on the map or below.',
      rectDescription: 'A rectangle landmark consists of two diagonal points. You can edit these points directly on the map or below.',
      polyDescription: 'A polygon landmark consists of three or more points. You can edit these points directly on the map or below. You can adjust the number of points below.',
      Landmark: 'Landmark',
      nameLandmark: 'Name Landmark',
      chooseLandmarkType: 'Choose Landmark Type',
      radius: 'Circle',
      rectangle: 'Rectangle',
      polygon: 'Polygon',
      detailsAndAdjustments: 'Details and Adjustments',
      addressSearch: 'Address Search',
      searchMap: 'Search Map',
      searchForLandmark: 'Search for Landmark',
      update: 'Update',

      topSpeed: 'Top Speed',
      current: 'current',

      errorUpdating: 'Error Updating',
      updatedSuccessfully: 'Updated Successfully',
      updating: 'Updating',
      lastThirtyDays: 'Last 30 Days',

      groupsAndLabels: 'Groups and Labels',

      driverScorecard: 'Driver Scorecard',
      true: 'True',
      totalDrivers: 'Total Drivers',
      thisWeek: 'This Week',
      good: 'Good',
      fair: 'Fair',
      poor: 'Poor',
      exceptions: 'Exceptions',
      speedingExceptions: 'Speeding Exceptions',
      speeding: 'Speeding',
      hardBrakes: 'Hard Brakes',
      fastStarts: 'Fast Starts',
      timeRange: 'Time Range',
      week: 'week',
      Week: 'Week',
      weeks: 'weeks',
      month: 'month',
      months: 'months',
      dashboard: 'Dashboard',
      driver: 'Driver',
      count: 'Count',
      ofTotal: 'of Total',
      performance: 'Performance',
      standings: 'Standings',
      rank: 'Rank',
      'Rank': 'Rank',
      overallRank: 'Overall Rank',
      billable: 'Billable',
      searchDriverName: 'Search Driver Name',
      selectReport: 'Select a report from the side menu',
      selectLandmark: 'Select Landmark',
      reportSearch: 'Search Reports',
      modifyReportFilters: 'Modify Report Filters',
      generate: 'Generate',
      create: 'Create',
      afterHours: 'After-Hours',
      batteryStatus: 'Battery Status',
      exceptions: 'Exceptions',
      idle: 'Idle',
      ignitionOnOff: 'Ignition On/Off',
      locations: 'Locations',
      postedSpeedLimit: 'Posted Speed Limit',
      speed: 'Speed',
      stop: 'Stop',
      trips: 'Trips',
      fuelcardTransactions: 'Fuelcard Transactions',
      transactions: 'Transactions',
      fuelcardMileage: 'Fuelcard Mileage',
      selected: 'selected',
      vehiclesSelected: 'Vehicles Selected',
      noData: 'No Data',
      selectAll: 'Select All',
      clearAll: 'Clear All',
      endedAt: 'Ended At',
      overTheLimit: 'over the limit',
      trip: 'trip',
      scheduledReport: 'Scheduled Report',
      createScheduledReport: 'Create Scheduled Report',
      editScheduledReport: 'Edit Scheduled Report',
      devices: 'Devices',
      all: 'All',
      filters: 'Filters',
      deliveryFrequency: 'Delivery Frequency',
      schedule: 'Schedule',
      dateRange: 'Date Range',
      startDate: 'Start Date',
      deliveryTime: 'Delivery Time',
      recipients: 'Recipients',
      save: 'Save',
      areYouSure: 'Are you sure?',
      Vehicle: 'Vehicle',
      Vehicles: 'Vehicles',
      sample: 'Sample',
      menu: 'Menu',
      top: 'Top',
      searchDeviceAliasOrVIN: 'Search device alias or VIN',
      filterGroupsAndLabels: 'Filter Groups and Labels',
      alias: 'Alias',
      Alias: 'Alias',
      labels: 'Labels',
      created: 'Created',
      due: 'Due',
      recurring: 'Recurring',
      estimatedCost: 'Estimated Cost',
      cost: 'Cost',
      status: 'Status',
      notes: 'Notes',
      history: 'History',
      moreFromThisVehicle: 'More From This Vehicle',
      date: 'Date',
      mileage: 'Mileage',
      engineHours: 'Engine Hours',
      ticketNumber: 'Ticket Number',
      type: 'Type',
      actualCost: 'Actual Cost',
      Completed: 'Completed',
      completedBy: 'Completed By',
      completed: 'Completed',
      overdue: 'Overdue',
      Overdue: 'Overdue',
      Current: 'Current',
      active: 'Active',
      Active: 'Active',
      groupBy: 'Group By',
      assignedDevice: 'Assigned Device',
      addItem: 'Add Item',
      read: 'Read',
      locationType: 'Location Type',
      Latitude: 'Latitude',
      Longitude: 'Longitude',
      Coordinates: 'Coordinates',
      sent_unconfirmed: 'Sent Unconfirmed',
      deleteJob: 'Delete Job',

      complete: 'Complete',
      quickCreate: 'Quick Create',
      iconUpdated: 'Icon Updated',
      somethingWentWrong: 'Something Went Wrong',
      iconManager: 'Icon Manager',
      color: 'Color',
      label: 'Label',
      icon: 'Icon',
      locateNow: 'Locate Now',
      iconTextLimit: 'Limited to 4 characters and only available on specific icons',
      Advanced: 'Advanced',
      stopLength: 'Stop Length',
      advancedOptions: 'Advanced Options',
      min: 'min',
      pleaseInput: 'Please input',
      notSelected: 'Not Selected',
      Scheduled: 'Scheduled',
      Standard: 'Standard',
      Professional: 'Professional',
      Custom: 'Custom',
      battery: 'Battery',
      ignition: 'Ignition',
      movement: 'Movement',
      event: 'Event',
      Event: 'Event',
      odometer: 'Odometer',
      pto: 'PTO',
      hoursOfOperation: 'Hours of Operation',
      fuelCardTransaction: 'Fuel Card Transaction',
      fuelCardMileage: 'Fuel Card Mileage',
      driveTime: 'Drive Time',
      'Drive Time': 'Drive Time',
      time: 'time',
      Time: 'Time',
      city: 'city',
      City: 'City',
      state: 'state',
      State: 'State',
      zip: 'zip',
      Zip: 'Zip',
      voltage: 'voltage',
      Voltage: 'Voltage',
      Heading: 'Heading',
      'Road Speed': 'Road Speed',
      'Speed Over': 'Speed Over',
      Duration: 'Duration',
      'Violation Count': 'Violation Count',
      'Max Speed': 'Max Speed',
      'First Occurrence': 'First Occurrence',
      'Last Occurrence': 'Last Occurrence',
      VIN: 'VIN',
      'Service Start Date': 'Service Start Date',
      Interval: 'Interval',
      'Since Previous Service': 'Since Previous Service',
      Status: 'Status',
      Notes: 'Notes',
      Card: 'Card',
      'Unit Cost': 'Unit Cost',
      'Quantity(Gallons)': 'Quantity(Gallons)',
      'Fuel Purchase': 'Fuel Purchase',
      Rebate: 'Rebate',
      'Misc.': 'Misc.',
      'Total Possible Misuse': 'Total Possible Misuse',
      'Transaction Date': 'Transaction Date',
      'Previous Transaction Date': 'Previous Transaction Date',
      'Estimated MPG': 'Estimated MPG',

      second: 'Second',
      seconds: 'Seconds',
      minute: 'Minute',
      minutes: 'Minutes',

      one: 'One',
      two: 'Two',
      five: 'Five',
      ten: 'Ten',
      thirty: 'Thirty',

      mon: 'Mon',
      tue: 'Tue',
      wed: 'Wed',
      thu: 'Thu',
      fri: 'Fri',
      sat: 'Sat',
      sun: 'Sun',

      viewAll: 'View All',
      close: 'Close',

      tripBegin: 'Trip Begin',
      tripEnd: 'Trip End',
      locate: 'Locate',
      ignitionOn: 'Ignition On',
      ignitionOff: 'Ignition Off',
      fastStart: 'Fast Start',
      mosl: 'Miles Over The Speed Limit',
      stopEnd: 'Stop End',
      stopBegin: 'Stop Begin',
      idleEnd: 'Idle End',
      idleBegin: 'Idle Begin',
      hardBrake: 'Hard Braking',
      harshTurn: 'Harsh Turn',
      start: 'Start',
      movementInitiated: 'Movement Initiated',
      movementConcluded: 'Movement Concluded',
      landmarkEnter: 'Landmark Enter',
      landmarkExit: 'Landmark Exit',
      ptoOn: 'PTO On',
      ptoOff: 'PTO Off',
      reconnect: 'Reconnect',
      disconnect: 'Disconnect',
      panic: 'Panic',
      fuelcardTransaction: 'Fuelcard Transaction',

      disableStarter: 'Disable Starter',
      starterInterruptToolTip: 'Prevent vehicle from starting.',

      day: 'day',
      days: 'days',
      hours: 'hours',
      years: 'years',
      Hours: 'Hours',
      Days: 'Days',
      Months: 'Months',
      Years: 'Years',

      pending: 'Pending',
      sortBy: 'Sort By',
      allDrivers: 'All Drivers',
      allLandmarks: 'All Landmarks',
      more: 'more',
      recursAfterCompletion: 'Recurs after completion',

      tempDescriptionFiller1: 'Lorem ipsum dolor amet veniam pickled paleo fam photo booth ugh lorem man bun artisan everyday carry sartorial sustainable.',
      tempDescriptionFiller2: '2 Lorem ipsum dolor amet veniam pickled paleo fam photo booth ugh lorem man bun artisan everyday carry sartorial sustainable.',
      listView: 'List View',
      network: 'Network',
      location: 'Location',
      Location: 'Location',
      address: 'Address',
      Address: 'Address',
      datetime: 'Date & Time',
      status: 'Status',
      productKey: 'Product Key',
      serial: 'Serial Number',
      companyPreferences: 'Company Preferences',
      moslDescription: 'A vehicle will generate a Miles Over the Speed Limit event (MOSL) when there are two or more consecutive locates where the vehicle\'s speed is greater than or equal to the posted speed limit plus the miles per hour threshold set by the user. This setting will apply to all devices on your account.',
      moslError: 'Looks like your account does not have miles over the speed limit enabled. Contact customer support for assistance.',
      v3VideoGuides: 'V3 Video Guides',
      v3ManualGuides: 'V3 Manual Guides',
      selectAServiceTicket: 'Select a service ticket from the side menu or Create New',
      clear: 'Clear',
      afterHoursDescription: 'The After-Hours Report will give you the locations of the device(s) that moved outside of a time span that you designate.',
      batteryDescription: 'The Battery Status Report shows the last voltage reading for each device.',
      tripsDescription: 'The Trips report will show trips and exceptions in the trips.',
      ignitionDescription: 'The Ignition Report will show the time when the ignition is on and when it is off.',
      locationDescription: 'The Locations Report shows the locations of the vehicle when it was moving.',
      movementDescription: 'The Movement Report shows when a device has a movement and has come to rest.',
      moslDescription: 'The Miles Over Speed Limit Report shows the locations of the vehicle when it exceeded the posted speed limit.',
      stopDescription: 'The Stops Report will display when and where a stop was logged.',
      idleDescription: 'The Idle Minutes Report shows the daily idle time for a vehicle.',
      eventDescription: 'The Event Report will show all exceptions for the device(s) during a given date and time range. This includes: speed limits, geofences, and landmark areas.',
      speedThresholdDescription: 'The Speed Threshold Report allows you to set a speed limit and see when a device exceeds that speed and how many times.',
      serviceDescription: 'The Services Report will show each vehicle and the type of services that have been setup.',
      mileageDescription: 'The Mileage Report shows the daily miles traveled for a vehicle.',
      odometerDescription: 'The Odometer Report shows the daily miles traveled for a vehicle.',
      ptoDescription: 'The PTO Report will display when and where a switch was triggered (eg. PTO, door opening/closing, etc.).',
      hoursOfOperationDescription: 'The Hours of Operation Report will show the time when the device started moving and when it last moved.',
      fuelCardTransactionDescription: 'Fuelcard Transaction Report for fuelcard users.',
      fuelCardMileageDescription: 'Fuelcard Mileage Report for fuelcard users.',
      engineHoursDescription: `The Engine Hours Report will show each vehicle's time between an ignition on and an ignition off.`,
      driverScorecardDescription: `The Driver Scorecard Report will show each driver's rank based on the exception during the specified timeframe.`,
      geofenceEnter: "Landmark Entered",
      geofenceExit: "Landmark Exited",
      liveVideo: 'Live Video',
      eventVideo: 'Event Video',
      viewRelatedClips: 'View Related Clips',
      availableClips: 'Available Clips',
      camera: 'Camera',
      clipUploadWarning: 'Uploading a clip uses data and will take away from your data cap.',
      upload: 'Upload',
      clipStart: 'Clip Start',
      clipEnd: 'Clip End',
      timeRemaining: 'Time Remaining',
      dtc: 'DTC',
      exportDeviceList: 'Export Device List',
      eventDuration: 'Event Duration',
      requestClip: 'Request Clip',
      wexCard: 'Wex Card',
      fuelcard: 'Fuel Card',
      invalidWexCardLength: 'Invalid Wex Card length',
      failedToUpdateWexCard: 'Failed To Update Wex Card',
      fobEnabled: 'Fob Enabled',
      fobEnabledDescription: 'Enabling FOB will override driver assignment using the fob scanner',
      Clustering: 'Clustering',
      'Driver Authorization': 'Driver Authorization',
      driverIn: 'Driver Authorization',

      iconSize: 'Icon Size',
      small: 'Small',
      medium: 'Medium',
      large: 'Large',

      centerOnAddress: 'Center on Address',
      typeMessage: 'Type Message',
      send: 'Send',
      items: 'Items',
      actions: 'Actions',

      importLandmarks: 'Import Landmarks',
      downloadTemplateCSV: 'Download Template .CSV File',
      uploadFile: 'Upload File',
      deleteAndImport: 'Delete and Import',
      import: 'Import',
      validating: 'Validating',
      uploadCSV: 'Click this area or drag a valid .csv file to this area to upload',
      landmarkImportDetails: 'Click this area or drag a valid .csv file to this area to upload file. Selected files will be validated and then uploaded. You will recieve an email when your landmarks have been successfully uploaded.',
      landmarkImportDetailsReplace: 'Click this area or drag a valid .csv file to this area to replace upload file. Selected files will be validated and then uploaded. You will recieve an email when your landmarks have been successfully uploaded.',
      deleteAndImportLandmarksConfirm: 'Are you sure you want to delete all existing landmarks before importing your new landmarks?',
      fileWasInvalid: 'Your .csv file was invalid. Please correct the following issues and re-upload your file.',
      xValidLandmarksClickBelow: 'valid landmarks. Click the button below to import.',
      issueImportingLandmarks: 'There was an issue importing your landmarks. Please try again later.',
      importSuccessfullyRequested: 'Import successfully requested. You will receive an email when importing has finished.',

      /** Popconfirm messages */
      Yes: 'Yes',
      No: 'No',
      deleteJobConfirm: 'Are you sure you want to delete this job?',

      /** Alert Messages */
      errorRefetchingDeviceList: 'Error refetching device list',
      notAValidYear: 'Not a valid year',
      duplicateVin: 'VIN is assigned to another vehicle or there was an issue validating this VIN',
      driverUpdatedSuccessfully: 'Driver updated successfully',
      driverCreatedSuccessfully: 'Driver created successfully',
      driverDeletedSuccessfully: 'Driver Deleted Successfully',
      failedToUpdateDriver: 'Failed to update driver',
      failedToCreateDriver: 'Failed to create driver',
      failedToDeleteDriver: 'Failed to delete driver',
      errorRequestingClipUpload: 'There was an error requesting the clip upload.',
      errorClipUploadDataLimit: 'This vehicle\'s camera has reached its data limit for this month. Please contact support if you have any questions.',
      clipUploadRequested: 'Clip upload requested. Check back later to view the clip.',
      clipUploadRequestedWarning: 'Clip has been requested. This may take up to two minutes. Please do not refresh the page.',
      errorDeleting: 'Error deleting.',
      noLongerCenteredAddress: 'Your landmark is no longer centered at the above address.',
      noAssociatedAddress: 'This landmark does not have an associated address. Please add this in the landmark editor.',
      issueCreatingDestination: 'There was an issue creating the destination.',
      destinationCreatedSuccessfully: 'Destination created successfully.',

      // speed: (speed, test) => speed + ' mph',
      moving: (speed, date, time, fullAddress) => {
        return 'Moving ' + speed + ' ' + this.speed + ' at ' + date + ' ' + time + ' on ' + fullAddress
      },
      stopped: (stoppedMinutes, fullAddress) => {
        return 'Stopped for ' + stoppedMinutes + ' at ' + fullAddress
      }
    }
    this.init()
  }

  init = () => { }

}

export default new EN();
