import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, InputNumber } from 'antd'

export default class numberInputCard extends Component {
    state = {
    //   inputNum: 0,
    }

    static propTypes = {
    //   toggleVal: PropTypes.bool,
      maxVal: PropTypes.number,
      minVal: PropTypes.number,
      titleVal: PropTypes.string,
      titleVal2: PropTypes.string,
      onChangeVal: PropTypes.func.isRequired,
      defaultVal: PropTypes.number,
      disable: PropTypes.bool,
      value: PropTypes.number,
    }


    static defaultProps = {
    //   toggleVal: false,
      minVal: 0,
      maxVal: 100,
      titleVal: 'Notify me if the selected devices travel at or above ',
      titleVal2: ' mph.',
      defaultVal: null,
      disable: false,
      value: null,
    }


    onChangeVal = (value) => {
      const { onChangeVal } = this.props
      //   this.setState({ inputNum: value })
      if (onChangeVal) {
        onChangeVal(value)
      }
    }

    render() {
      const {
        titleVal, minVal, maxVal, defaultVal, disable, titleVal2, value,
      } = this.props
      return (
        <Card size="small" style={{ maxWidth: '530px', margin: '10px 0px 10px 0px' }}>
          <span>
            {titleVal}
            <InputNumber
              size="small"
              style={{ margin: '0px 10px' }}
              min={minVal}
              max={maxVal}
              onChange={this.onChangeVal}
              defaultValue={defaultVal || undefined}
              disabled={disable || undefined}
              value={value || undefined}
            />
            {titleVal2}
          </span>
        </Card>
      )
    }
}
