import React, { Component } from 'react'
import PropTypes from 'prop-types'
import tvUpload from '@assets/img/icons/tv-upload.svg'
import tvLoading from '@assets/img/icons/tv-loading.svg'
import tvPlay from '@assets/img/icons/tv-play.svg'

const colors = {
  red: '#D0021B',
  green: '#2CD227',
  textGray: '#000000A6',
  highlightBlue: '#4482FF',
}

const cameraIcon = (fill) => {
  let fillColor = '#000000'
  if (fill) {
    fillColor = colors[fill] || fill
  }
  return (
    <svg
      style={{
        width: '100%',
        height: 'auto',
      }}
      viewBox="0 0 13 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icons/Video Camera/Black</title>
      <g
        id="Icons/Video-Camera/Black"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M10.08,4.74 L10.08,2.22 C10.08,1.824 9.756,1.5 9.36,1.5 L0.72,1.5 C0.324,1.5 0,1.824 0,2.22 L0,9.42 C0,9.816 0.324,10.14 0.72,10.14 L9.36,10.14 C9.756,10.14 10.08,9.816 10.08,9.42 L10.08,6.9 L12.96,9.78 L12.96,1.86 L10.08,4.74 Z"
          id="Path"
          fill={fillColor}
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

const liveCameraIcon = (fill) => {
  let fillColor = '#000000'
  if (fill) {
    fillColor = colors[fill] || fill
  }
  return (
    <svg
      style={{
        width: '100%',
        height: 'auto',
      }}
      viewBox="0 0 16 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icons/Video Camera/Live</title>
      <g
        id="Icons/Video Camera/Live"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M11.52,1.875 C12.0073846,1.875 12.4061538,2.28 12.4061538,2.775 L12.4061538,2.775 L12.4061538,5.925 L15.9507692,2.325 L15.9507692,12.225 L12.4061538,8.625 L12.4061538,11.775 C12.4061538,12.27 12.0073846,12.675 11.52,12.675 L11.52,12.675 L0.886153846,12.675 C0.398769231,12.675 -8.37463432e-12,12.27 -8.37463432e-12,11.775 L-8.37463432e-12,11.775 L-8.37463432e-12,2.775 C-8.37463432e-12,2.28 0.398769231,1.875 0.886153846,1.875 L0.886153846,1.875 Z M6,4.99192033 C4.66991717,4.99192033 3.36879579,5.90994689 2.09663587,7.746 C3.3869692,9.27407128 4.69344656,10.0381069 6.01606793,10.0381069 C7.33868931,10.0381069 8.64516667,9.27407128 9.9355,7.746 C8.64191617,5.90994689 7.33008283,4.99192033 6,4.99192033 Z"
          id="Combined-Shape"
          fill={fillColor}
          fillRule="nonzero"
        />
        <circle
          id="Oval"
          fill={fillColor}
          cx="6"
          cy="7.5"
          r="2"
        />
      </g>
    </svg>
  )
}

const locationPinIcon = (fill) => {
  let fillColor = '#000000'
  if (fill) {
    fillColor = colors[fill] || fill
  }
  return (
    <svg
      style={{
        width: '100%',
        height: 'auto',
      }}
      viewBox="0 0 16 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icons/Location</title>
      <g
        id="Icons/Location"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          fill={fillColor}
          fillRule="nonzero"
          d="M7.86956522,0 C11.7355704,0 14.8695652,3.18995821 14.8695652,7.12499826 C14.8695652,9.99774561 13.886284,10.7999759 8.58896105,18.6166556 C8.24133084,19.1278 7.49776313,19.1277629 7.15016938,18.6166556 C1.85284647,10.7999759 0.869565217,9.99774561 0.869565217,7.12499826 C0.869565217,3.18995821 4.00356001,0 7.86956522,0 Z M7.86956522,3.8 C6.06543503,3.8 4.60289855,5.28865321 4.60289855,7.125 C4.60289855,8.96134679 6.06543503,10.45 7.86956522,10.45 C9.6736954,10.45 11.1362319,8.96134679 11.1362319,7.125 C11.1362319,5.28865321 9.6736954,3.8 7.86956522,3.8 Z"
          id="Combined-Shape"
        />
      </g>
    </svg>
  )
}

const chatIcon = (fill) => {
  let fillColor = '#000000'
  if (fill) {
    fillColor = colors[fill] || fill
  }
  return (
    <svg
      style={{
        width: '100%',
        height: 'auto',
      }}
      // width="18px"
      // height="16px"
      viewBox="0 0 18 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icons/chat">
          <g>
            <path
              d="M15,2 C15.542,2 16,2.458 16,3 L16,10 C16,10.542 15.542,11 15,11 L6.171,11 L6,11.171 L6,11 L3,11 C2.458,11 2,10.542 2,10 L2,3 C2,2.458 2.458,2 3,2 L15,2 M15,0 L3,0 C1.35,0 0,1.35 0,3 L0,10 C0,11.65 1.35,13 3,13 L4,13 L4,16 L7,13 L15,13 C16.65,13 18,11.65 18,10 L18,3 C18,1.35 16.65,0 15,0 Z"
              id="Shape"
              fill={fillColor}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

const badgeIcon = (type) => {
  switch (type) {
    case 'error':
      return (
        <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="icons/errorCircle" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <circle id="Oval" fill="#F5222E" cx="6" cy="6" r="6" />
            <path d="M3.12115982,8.87884018 C3.28270625,9.04038661 3.54532275,9.04038661 3.70686918,8.87884018 L6,6.58570935 L8.29313082,8.87884018 C8.45467725,9.04038661 8.71729375,9.04038661 8.87884018,8.87884018 C9.04038661,8.71729375 9.04038661,8.45467725 8.87884018,8.29313082 L6.58570935,6 L8.87884018,3.70686918 C9.04038661,3.54532275 9.04038661,3.28270625 8.87884018,3.12115982 C8.71729375,2.95961339 8.45467725,2.95961339 8.29313082,3.12115982 L6,5.41429065 L3.70686918,3.12115982 C3.54532275,2.95961339 3.28270625,2.95961339 3.12115982,3.12115982 C2.95961339,3.28270625 2.95961339,3.54532275 3.12115982,3.70686918 L5.41429065,6 L3.12115982,8.29313082 C2.95961339,8.45467725 2.95961339,8.71729375 3.12115982,8.87884018 Z" id="Shape" fill="#FFFFFF" fillRule="nonzero" />
          </g>
        </svg>
      )
    case 'success':
      return (
        <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="icons/successCircle/green" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <circle id="Oval" fill="#56E06E" cx="6" cy="6" r="6" />
            <g id="check" transform="translate(3.000000, 4.000000)" fill="#FFFFFF" fillRule="nonzero">
              <path d="M2.13314038,4.85969388 C2.06367583,4.93622449 1.96526773,5 1.87843705,5 C1.79160637,5 1.69319826,4.93303571 1.62083936,4.8565051 L0,3.07079082 L0.515195369,2.50318878 L1.8813314,4.00829082 L5.4934877,0 L6,0.577168367 L2.13314038,4.85969388 L2.13314038,4.85969388 Z" id="Path" />
            </g>
          </g>
        </svg>
      )
    default:
      return (
        <svg width="12px" height="12px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="icons/pendingCircle" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <circle id="Oval" fill="#5E5E5E" cx="7" cy="7" r="7" />
            <polyline id="Path-3" stroke="#FFFFFF" points="2.7400768 8.51749841 5.35361844 5.62988957 8.4512687 8.51749841 11.1841102 5.62988957" />
          </g>
        </svg>
      )
  }
}


export default class Icon extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    width: PropTypes.number, // number in pixels
    color: PropTypes.string,
  }

  static defaultProps = {
    width: 20,
    color: '#000000',
  }

  render() {
    const {
      width, type, color,
    } = this.props

    if (type.includes('badge')) {
      const badgeType = type.split('-')[1]
      if (!badgeType) {
        return null
      }
      return (
        <div
          style={{
            width,
            height: width,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all 0.05s ease-out',
          }}
        >
          {badgeIcon(badgeType)}
        </div>
      )
    }

    switch (type) {
      case 'location-pin':
        return (
          <div
            style={{
              width,
              height: width,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'all 0.05s ease-out',
            }}
          >
            {locationPinIcon(color)}
          </div>
        )
      case 'camera':
        return (
          <div
            style={{
              width,
              height: width,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'all 0.05s ease-out',
            }}
          >
            {cameraIcon(color)}
          </div>
        )
      case 'live-camera':
        return (
          <div
            style={{
              width,
              height: width,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'all 0.05s ease-out',
            }}
          >
            {liveCameraIcon(color)}
          </div>
        )
      case 'tv-upload':
        return (
          <img
            style={{
              width,
              height: width,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            src={tvUpload}
            alt="upload"
          />
        )
      case 'tv-loading':
        return (
          <img
            style={{
              width,
              height: width,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            src={tvLoading}
            alt="loading"
          />
        )
      case 'tv-play':
        return (
          <img
            style={{
              width,
              height: width,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            src={tvPlay}
            alt="play"
          />
        )
      case 'chat-bubble':
        return (
          <div
            style={{
              width,
              height: width,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'all 0.05s ease-out',
            }}
          >
            {chatIcon(color)}
          </div>
        )
      default:
        return null
    }
  }
}
