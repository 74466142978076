import React, { Component } from 'react'
import queryConnector from '@graphql/queryConnector'
import mutationConnector from '@graphql/mutationConnector'
import { companyGroupQuery, contactToAssignGroupQuery } from '@graphql/query'
import {
  createCompanyGroup,
  assignCompanyGroupToDevice,
  assignCompanyGroupToUser,
  deleteCompanyGroupMutation,
} from '@graphql/mutation'
import { compose } from 'react-apollo'
import PropTypes from 'prop-types'

const groupPanelHOC = () => (WrappedComponent) => {
  class GroupPanelHOC extends Component {
    static propTypes = {
      wMatrix: PropTypes.func.isRequired,
      devices: PropTypes.array,
      companyGroupQueryData: PropTypes.object,
      /** @mutationConnector */
      deleteCompanyGroup: PropTypes.func,
      usersQuery: PropTypes.object,
      createGroup: PropTypes.func,
      assignGroupToDevice: PropTypes.func,
      assignGroupToUser: PropTypes.func,
      refetchDevices: PropTypes.func,
      filterDeviceByTag: PropTypes.func,
    }

    static defaultProps = {
      devices: [],
      companyGroupQueryData: null,
      /** @mutationConnector */
      deleteCompanyGroup: null,
      usersQuery: null,
      createGroup: null,
      assignGroupToDevice: null,
      assignGroupToUser: null,
      refetchDevices: null,
      filterDeviceByTag: null,
    }


    state = {
      groupsLoading: true,
      usersLoading: true,
      message: {},
    }

    componentDidUpdate = (prevProps) => {
      const { companyGroupQueryData, usersQuery } = this.props

      if (prevProps.companyGroupQueryData.loading
          && !companyGroupQueryData.loading) this.setState({ groupsLoading: false })
      if (prevProps.usersQuery.loading
        && !usersQuery.loading) this.setState({ usersLoading: false })
    }

    /**
     * Static function to get role name from role Id
     */
    getRoleName = (number) => {
      const { wMatrix } = this.props
      switch (number) {
        case 1: return wMatrix('global')
        case 2: return wMatrix('Admin')
        case 3: return wMatrix('supervisor')
        case 4: return wMatrix('readOnly')
        case 6: return wMatrix('serviceTech')
        default: return wMatrix('readOnly')
      }
    }

    save = (tag, checkedItems) => {
      const {
        createGroup, assignGroupToDevice, assignGroupToUser,
        companyGroupQueryData, usersQuery, refetchDevices,
        filterDeviceByTag,
      } = this.props

      this.setState({
        groupsLoading: true,
        usersLoading: true,
        message: {},
      })

      createGroup({ variables: tag }).then((response) => {
        if (response) {
          return assignGroupToDevice({
            variables: {
              groupIds: [response.data.createCompanyGroup.id],
              deviceIds: checkedItems.devices,
              ifEditingTag: true,
            },
          }).then((response1) => {
            if (response1.data.assignCompanyGroupToDevice.code === 1000) {
              /* Only have the property of ifEditingTag here, other places
              calling assignGroupToUser should not have this */
              return assignGroupToUser({
                variables: {
                  groupIds: [response.data.createCompanyGroup.id],
                  contactIds: checkedItems.users,
                  ifEditingTag: true,
                },
              }).then((response2) => {
                if (response2.data.assignCompanyGroupToUser.code === 1000) {
                  /* reset filter tags */
                  filterDeviceByTag([])
                  this.setState({
                    message: {
                      success: 'groupHasBeenUpdated',
                    },
                  })
                } else {
                  this.setState({
                    message: {
                      error: 'Could not add or remove users to group. Did not save changes.',
                    },
                  })
                }

                // workaround for .finally in Edge browser
              })
            }
            this.setState({
              message: {
                error: 'Could not add or remove devices to group. Did not save changes.',
              },
            })

            // workaround for .finally in Edge browser
          })
        }
        this.setState({
          message: {
            error: 'Could not save group',
          },
        })

        // workaround for .finally in Edge browser
      }).then(() => { // workaround for .finally in Edge browser
        companyGroupQueryData.refetch()
        usersQuery.refetch()
        refetchDevices()
      })
    }

    delete = (numTagID) => {
      const {
        deleteCompanyGroup, companyGroupQueryData, usersQuery, refetchDevices,
      } = this.props

      this.setState({
        groupsLoading: true,
        usersLoading: true,
        message: {},
      })

      deleteCompanyGroup({ variables: { id: numTagID } }).then((response) => {
        if (response.data.deleteCompanyGroup.code === 1000) {
          companyGroupQueryData.refetch()
          usersQuery.refetch()
          refetchDevices()
        } else {
          this.setState({
            message: {
              error: 'Could not delete group',
            },
          })
        }
      })
    }

    render = () => {
      const { companyGroupQueryData, usersQuery, devices } = this.props
      const { groupsLoading, usersLoading, message } = this.state

      let users = []
      if (usersQuery.data && usersQuery.data.contactToAssignGroup) {
        users = JSON.parse(JSON.stringify(usersQuery.data.contactToAssignGroup))
        users = users.map((user) => {
          const userWithName = user
          userWithName.role.name = this.getRoleName(user.role.id)
          return userWithName
        })
      }
      return (
        <WrappedComponent
          groups={companyGroupQueryData.data.group}
          groupsLoading={groupsLoading}
          devices={devices}
          users={users}
          usersLoading={usersLoading}
          save={this.save}
          deleteTag={this.delete}
          message={message}
          contactToAssignGroupQueryRefetch={usersQuery.refetch}
          {...this.props}
        />
      )
    }
  }
  return compose(
    queryConnector(companyGroupQuery, { type: 'contact', input: '0' }, 'companyGroupQueryData', true),
    queryConnector(contactToAssignGroupQuery, { }, 'usersQuery', true),
    mutationConnector(createCompanyGroup, 'createGroup'),
    mutationConnector(assignCompanyGroupToDevice, 'assignGroupToDevice'),
    mutationConnector(assignCompanyGroupToUser, 'assignGroupToUser'),
    mutationConnector(deleteCompanyGroupMutation, 'deleteCompanyGroup'),
  )(GroupPanelHOC)
}

export default groupPanelHOC
