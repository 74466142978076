/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

const styles = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  verticalRectangleMap: {
    maxWidth: '1ch',
    wordWrap: 'break-word',
    whiteSpace: 'break-spaces',
    margin: '0px 0px 0px 0px',
    lineHeight: '8px',
    fontSize: '9px',
    textAlign: 'center',
  },
  verticalRectangleDeviceList: {
    maxWidth: '1.1ch',
    wordWrap: 'break-word',
    whiteSpace: 'break-spaces',
    margin: '1px 0px 1px 0px',
    lineHeight: '8px',
    fontSize: '9px',
  },
  verticalRectangleMgr: {
    maxWidth: '1ch',
    wordWrap: 'break-word',
    whiteSpace: 'break-spaces',
    margin: '0px 2px 0px 2px',
    lineHeight: '14px',
    fontSize: '14px',
  },
  horizontalRectangleMap: {
    margin: '0px 0px 0px 0px',
    fontSize: '12px',
  },
  horizontalRectangleDeviceList: {
    margin: '0 2px 0 2px',
    fontSize: '11px',
  },
  horizontalRectangleMgr: {
    margin: '0 2px 0 2px',
    fontSize: '18px',
  },
  circleMap: {
    margin: '0 0 0 0',
    fontSize: '12px',
  },
  circleDeviceList: {
    margin: '0 2px 0 2px',
    fontSize: '11px',
  },
  circleMgr: {
    margin: '0 2px 0 2px',
    fontSize: '18px',
  },
}

export default class DeviceIcon extends Component {
  static propTypes = {
    icon: PropTypes.object.isRequired,
    inIconMgr: PropTypes.bool,
    inDeviceList: PropTypes.bool,
    // callback used in Virtual List
    measure: PropTypes.func,
  }

  static defaultProps = {
    inIconMgr: false,
    inDeviceList: false,
    measure: null,
  }

  getTextStyle() {
    const { icon, inIconMgr, inDeviceList } = this.props
    const getShapeStyle = () => {
      switch (icon.type) {
        case 'verticalRectangle':
          if (inDeviceList) return { ...styles.verticalRectangleDeviceList }
          if (inIconMgr) return { ...styles.verticalRectangleMgr }
          return { ...styles.verticalRectangleMap }
        case 'horizontalRectangle':
          if (inDeviceList) return { ...styles.horizontalRectangleDeviceList }
          if (inIconMgr) return { ...styles.horizontalRectangleMgr }
          return { ...styles.horizontalRectangleMap }
        case 'circle':
          if (inDeviceList) return { ...styles.circleDeviceList }
          if (inIconMgr) return { ...styles.circleMgr }
          return { ...styles.circleMap }
        default:
          return {}
      }
    }
    const style = getShapeStyle()
    if (icon.color === 'white') {
      style.color = 'black'
    } else {
      style.color = 'white'
    }
    return style
  }

  render() {
    const {
      icon, inDeviceList, inIconMgr, measure,
    } = this.props
    return (
      <div
        style={
          inDeviceList || inIconMgr
            ? { position: 'relative' }
            : {
              display: 'flex',
              justifyContent: 'center',
            }
        }
      >
        <img
          onLoad={measure}
          src={require(`@assets/img/device_icons/${icon.type}_${icon.color}.svg`)}
          alt="car icon"
          width={inDeviceList || inIconMgr ? '100%' : '80%'}
        />
        { icon.text && ['circle', 'horizontalRectangle', 'verticalRectangle'].includes(icon.type)
          ? (
            <div style={styles.container}>
              <p className={icon.type} style={this.getTextStyle()}>{icon.text}</p>
            </div>
          ) : (<span />)
        }
      </div>
    )
  }
}
