import React, { Component } from "react";
import queryConnector from '@graphql/queryConnector';
import consumerConnector from '@graphql/consumerConnector'
import mutationConnector from '@graphql/mutationConnector'
import { companyGroupQuery, contactQuery } from "@graphql/query";
import { assignCompanyGroupToUser, createContact_mutation, updateContacts, updateFeatures } from '@graphql/mutation'
import { compose } from 'react-apollo'

const userPanelHOC = () => WrappedComponent => {
  class UserPanelHOC extends Component {
    state = {
      newTag: null,
      contacts: [],
      notificationMessage: '',
      notificationType: '',
      notificationFlag: false,
      showForm: false,
    }

    //refetch contacts and update the contacts state
    userRefetch = () => {
      this.props['contactQueryConn'].refetch().then((response) => {
        this.setState({ contacts: response.data.contacts })
      })
    }

    notificationFromUserPanel = (message, type) => {
      this.setState({
        notificationMessage: message,
        notificationType: type,
        notificationFlag: true
      })
      setTimeout(() => {
        this.setState({
          notificationMessage: '',
          notificationType: 'success',
          notificationFlag: false
        })
      }, 5000)
    }

    showFormToggle = () => {
      const { showForm } = this.state
      this.setState({ showForm: !showForm })
    }

    createNewUser = (fname, lname, emailVal, pass, mobilePhone, role, groupIds) => {
      //create user using createUser_mutation
      // use this response to dictate what contact to assign possible groups to...
      return this.props.createContact({
        variables: {
          contact: {
            firstName: fname,
            lastName: lname,
            email: emailVal,
            password: pass,
            mobilePhone: mobilePhone,
            roleId: role
          }
        }
      }).then((response) => {
        //if contact was created and there are groups selected
        if (response) {
          if (response.data && response.data.createContactBcrypt) {
            if (response.data.createContactBcrypt.id === -1) {
              this.notificationFromUserPanel('Email already exists', 'error')
              return false
            } else {
              this.showFormToggle(true)
              if (groupIds != null) {
                this.props.assignGroupToUser({ variables: { groupIds: groupIds, contactIds: [response.data.createContactBcrypt.id] } }).then((response2) => {
                  this.userRefetch()
                  this.notificationFromUserPanel('User Created Successfully', 'success')
                })
                return true
              }
              this.userRefetch()
              this.notificationFromUserPanel('User Created Successfully', 'success')
              return true
            }
          }
        }
      })

    }

    updateUser = (contact) => {
      let { groups, ...contactData } = contact
      return this.props.updateContacts({ variables: { contacts: [contactData] } }).then((response) => {
        if (response && groups != null) {
          if (response.data.updateContacts[0].id === -1) {
            this.notificationFromUserPanel('Email already exists', 'error')
            return false
          } else {
            if (groups != null) {
              let groupNums = []
              for (let i = 0; i < groups.length; i++) {
                groupNums.push(Number(groups[i]))
              }
              this.props.assignGroupToUser({ variables: { groupIds: groupNums, contactIds: [response.data.updateContacts[0].id] } }).then((response2 => {
                if (response2.data.assignCompanyGroupToUser.code === 1000) {
                  this.userRefetch();
                  this.notificationFromUserPanel('User Updated Successfully', 'success')
                  return true
                } else {
                  this.notificationFromUserPanel('Failed to update user, please contact us', 'error')
                  return false
                }
              }))
            } else {
              this.userRefetch();
              this.notificationFromUserPanel('User Updated Successfully', 'success')
              return true
            }
          }
        }
      })
    }

    deleteUser = (contact) => {
      let { groups, ...contactData } = contact
      contactData.isActive = false
      this.props.updateContacts({ variables: { contacts: [contactData] } }).then((response) => {
        if (response.data.updateContacts[0].id) {
          this.userRefetch();
          this.notificationFromUserPanel('User Deleted Successfully', 'success')
        } else {
          this.notificationFromUserPanel('Failed to delete user, please contact us', 'error')
        }
      })
    }

    render = ({ loading, error, data, refetch } = this.props) => {
      const { notificationMessage, notificationType, notificationFlag, showForm } = this.state
      const { wMatrix, getPermission, groupQueryConn, contactQueryConn, activeKey } = this.props
      return (
        <WrappedComponent
          groups={groupQueryConn.data.group}
          contacts={contactQueryConn.data.contacts}
          contactsRefetch={contactQueryConn.refetch}
          arrangeToPanelList={this.arrangeToPanelList}
          createNewContact={this.createNewUser}
          updateUser={this.updateUser}
          deleteUser={this.deleteUser}
          showFormToggle={this.showFormToggle}
          showForm={showForm}
          notificationMessage={notificationMessage}
          notificationType={notificationType}
          notificationFlag={notificationFlag}
          wMatrix={wMatrix}
          getPermission={getPermission}
          activeKey={activeKey}
        />
      )
    }
  }
  return compose(
    //admin ID is always '0' (cient ID and contactID)
    queryConnector(companyGroupQuery, { type: 'contact', input: '0' }, 'groupQueryConn'),
    queryConnector(contactQuery, { type: 'contact' }, 'contactQueryConn'),
    mutationConnector(assignCompanyGroupToUser, 'assignGroupToUser'),
    mutationConnector(updateContacts, 'updateContacts'),
    mutationConnector(createContact_mutation, 'createContact'),
    consumerConnector()
  )(UserPanelHOC)
}

export default userPanelHOC;
