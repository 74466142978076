import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FilterSection from '@atom/timeline/filterSection'
import TimelineHeader from '@mol/timelineHeader'
import CheckboxOption from '@atom/checkboxOption'
import SwitchOption from '@atom/switchOption'
import { Radio } from 'antd'

const RadioGroup = Radio.Group
const RadioButton = Radio.Button

const styles = {
  container: {
    display: 'flex',
    flexGrow: '1',
    // flexShrink: '0',
    flexDirection: 'column',
    alignContent: 'stretch',
    overflow: 'auto',
  },
  verticalText: {
    writingMode: 'vertical-lr',
    textOrientation: 'mixed',
    transform: 'rotate(180deg)',
    textAlign: 'center',
  },
}

export default class FilterPage extends Component {
  static propTypes = {
    eventToString: PropTypes.func.isRequired,
    availableEventFilters: PropTypes.array.isRequired,
    selectedEventTypes: PropTypes.array.isRequired,
    sortOrder: PropTypes.string.isRequired,
    showEvents: PropTypes.bool.isRequired,
    showLocates: PropTypes.bool.isRequired,
    expanded: PropTypes.bool,
    wMatrix: PropTypes.func.isRequired,
    toggleLocates: PropTypes.func.isRequired,
    toggleEvents: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    date: PropTypes.string.isRequired,
    changeTripDate: PropTypes.func.isRequired,
    timelineRange: PropTypes.string.isRequired,
  }

  static defaultProps = {
    expanded: true,
  }

  constructor(props) {
    super(props)
    const {
      /** Uncomment below to show "Check All" button */
      // availableEventFilters,
      selectedEventTypes,
      sortOrder,
    } = props
    this.state = {
      selectedEventTypes,
      /** Uncomment below to show "Check All" button */
      // checkAll: selectedEventTypes.length === availableEventFilters.length,
      sortOrder,
    }
  }

  onCheckboxChange = (eventType, show) => {
    const { selectedEventTypes } = this.state
    /** Uncomment below to show "Check All" button */
    // const { availableEventFilters } = this.props
    const selectedEventTypesClone = selectedEventTypes.slice(0)
    let newEventTypes = []
    if (show) {
      newEventTypes = selectedEventTypesClone
      newEventTypes.push(eventType)
      this.setState({ selectedEventTypes: newEventTypes })
    } else {
      newEventTypes = selectedEventTypesClone.filter(event => event !== eventType)
      this.setState({ selectedEventTypes: newEventTypes })
    }
    /** Uncomment below to show "Check All" button */
    // this.setState({
    //   checkAll: newEventTypes.length === availableEventFilters.length,
    // })
  }

  /** Function used to toggle Check all */
  onCheckAllChange = (eventType, show) => {
    const { availableEventFilters } = this.props
    if (eventType === 'checkAll') {
      this.setState({
        selectedEventTypes: show ? availableEventFilters : [],
        // indeterminate: false,
        /** Uncomment below to show "Check All" button */
        // checkAll: show,
      })
    }
  }

  returnFilterOptions = () => {
    const { selectedEventTypes } = this.state
    const { availableEventFilters, eventToString } = this.props
    // non-deep clone
    let eventsClone = availableEventFilters.slice(0)
    eventsClone = eventsClone.sort()
    return (
      eventsClone.map(event => (
        event !== 'locate'
          ? (
            <CheckboxOption
              label={eventToString(event)}
              value={event}
              key={event}
              // defaultChecked={selectedEventTypes.indexOf(event) >= 0}
              onChange={this.onCheckboxChange}
              checked={selectedEventTypes.indexOf(event) >= 0}
            />
          ) : null
      )))
  }

  renderFilterSection = () => {
    // const { checkAll } = this.state
    const { wMatrix, showEvents } = this.props
    if (showEvents) {
      /** Uncomment below to show "Check All" button */
      return (
        <div style={{ overflow: 'scroll' }}>
          {/* <CheckboxOption
            label={wMatrix('checkAll')}
            key="checkAll"
            value="checkAll"
            // defaultChecked={selectedEventTypes === availableEventFilters}
            onChange={this.onCheckAllChange}
            checked={checkAll}
          /> */}
          {this.returnFilterOptions()}
        </div>
      )
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <h5>{wMatrix('enableShowEvents')}</h5>
      </div>
    )
  }

  renderExpandText = () => {
    const { wMatrix } = this.props
    return (
      <div style={{ display: 'flex', flexGrow: '1' }}>
        <h4 style={styles.verticalText}>
          {wMatrix('expandDrawerToShow')}
        </h4>
      </div>
    )
  }

  renderFilterPage = () => {
    const {
      wMatrix, sortOrder, toggleLocates, toggleEvents, timelineRange,
    } = this.props
    let {
      showLocates, showEvents,
    } = this.props

    const showLocatesLS = JSON.parse(localStorage.getItem('rft-showLocates'))
    if (showLocatesLS !== null && showLocates !== showLocatesLS) {
      showLocates = showLocatesLS
    }

    const showEventsLS = JSON.parse(localStorage.getItem('rft-showEvents'))
    if (showEventsLS !== null && showEvents !== showEventsLS) {
      showEvents = showEventsLS
    }

    return (
      <div>
        <FilterSection title={wMatrix('orderBy')}>
          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'center',
          }}
          >
            <RadioGroup onChange={this.orderChange} defaultValue={sortOrder}>
              <RadioButton size="small" value="ascending">{wMatrix('ascending')}</RadioButton>
              <RadioButton size="small" value="descending">{wMatrix('descending')}</RadioButton>
            </RadioGroup>
          </div>
        </FilterSection>
        { timelineRange === 'thirty-history' ? null : (
          <div>
            <FilterSection title={wMatrix('locates')}>
              <SwitchOption label={wMatrix('showLocates')} defaultChecked={showLocates} onChange={toggleLocates} />
            </FilterSection>
            <FilterSection title={wMatrix('events')}>
              <SwitchOption label={wMatrix('showEvents')} defaultChecked={showEvents} onChange={toggleEvents} />
            </FilterSection>
            <FilterSection title={wMatrix('filterEvents')}>
              {this.renderFilterSection()}
            </FilterSection>
          </div>
        )
        }

      </div>
    )
  }

  orderChange = (e) => {
    // console.log(`radio checked: ${e.target.value}`)
    this.setState({ sortOrder: e.target.value })
  }

  onSave =() => {
    const { selectedEventTypes, sortOrder } = this.state
    const { onSave } = this.props
    onSave(sortOrder, selectedEventTypes)
  }

  render() {
    const {
      wMatrix, date, expanded, changeTripDate, timelineRange,
    } = this.props
    return (
      <div style={styles.container}>
        <div style={{ position: 'sticky', top: '0px', zIndex: 5 }}>
          <TimelineHeader
            date={date}
            lastThirty={timelineRange === 'thirty-history'}
            expanded={expanded}
            changeTripDate={changeTripDate}
            filterPage
            toggleFilterPage={this.onSave}
            wMatrix={wMatrix}
          />
        </div>
        {!expanded ? this.renderExpandText() : this.renderFilterPage()}
      </div>
    )
  }
}
