import React, { Component } from 'react'
import { compose } from 'react-apollo'
import queryConnector from '@graphql/queryConnector'
import consumerConnector from '@graphql/consumerConnector'
import mutationConnector from '@graphql/mutationConnector'
import {
  updateVehicleInfo, updateVehicleIcon, assignStopLength, updateSpeedThreshold, updateFobStatus,
  updateTimelineRange,
} from '@graphql/mutation'
import {
  getVehicleTypes, iconColors, iconTypes, vinExists,
} from '@graphql/query'
import PropTypes from 'prop-types'

/**
 * @todo
 * - optimize/ make more dynamic the get id functions.
 */
const deviceMgrHOC = () => (WrappedComponent) => {
  class DeviceMgrHOC extends Component {
    static propTypes = {
      vehicleTypeData: PropTypes.object.isRequired,
      iconColorsQuery: PropTypes.object.isRequired,
      iconTypesQuery: PropTypes.object.isRequired,
      updateVehicleIconMutation: PropTypes.func.isRequired,
      updateVehicle: PropTypes.func.isRequired,
      assignStopMinutes: PropTypes.func.isRequired,
      updateSpeedThresholdValue: PropTypes.func.isRequired,
      updateFobStatusValue: PropTypes.func.isRequired,
      updateTimelineRangeValue: PropTypes.func.isRequired,
      refetchDevices: PropTypes.func.isRequired,
      wMatrix: PropTypes.func.isRequired,
      apolloClient: PropTypes.object.isRequired,
    }

    state = {
      showAlert: {
        show: false,
        type: 'error',
        message: '',
      },
      deviceMgrUpdating: false,
    }

    /**
     * Unmount and clear any timers
     */
    componentWillUnmount = () => {
      if (this.alertTimeout) {
        clearTimeout(this.alertTimeout)
        this.alertTimeout = 0
      }
    }

    /**
     * Return Sorted Makes (Unspecified First)
     */
    getMakes = () => {
      const { vehicleTypeData } = this.props
      if (vehicleTypeData && vehicleTypeData.data) {
        const { data } = vehicleTypeData
        if (Object.entries(data) === 0) {
          return null
        }
        const makes = []
        let unspecified = null
        // make an array to not change original data
        for (const make in data.makes) {
          if (Object.prototype.hasOwnProperty.call(data.makes, make)) {
            const makeOption = {
              id: data.makes[make].id,
              name: data.makes[make].name,
            }
            if (makeOption.id === 0) {
              unspecified = makeOption
            } else {
              makes.push(makeOption)
            }
          }
        }
        // alphabetize
        makes.sort((a, b) => {
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
        // Place unspecified first
        makes.unshift(unspecified)
        return makes
      }
      return null
    }

    /**
     * checks makes to return id, given the name of make
     */
    getMakeID = (name) => {
      const { vehicleTypeData } = this.props
      const { makes } = vehicleTypeData.data
      for (const make in makes) {
        if (makes[make].name === name) {
          return makes[make].id
        }
      }
      return 0
    }

    /**
     * return model id given make name and model name
     */
    getModelID = (make, model) => {
      const { vehicleTypeData } = this.props
      const { makes } = vehicleTypeData.data
      for (const m in makes) {
        if (makes[m].name === make) {
          const { models } = makes[m]
          for (const n in models) {
            if (models[n].name === model) {
              return models[n].id
            }
          }
        }
      }
      return 0
    }

    /**
     * return models (object of name and id) given make name
     */
    getModelsFromMakeName = (name) => {
      const { vehicleTypeData } = this.props
      const { makes } = vehicleTypeData.data
      for (const make in makes) {
        if (makes[make].name === name) {
          const modelsArray = []
          modelsArray.push({ id: 0, name: 'Unspecified' })
          for (const model in makes[make].models) {
            if (Object.prototype.hasOwnProperty.call(makes[make].models, model)) {
              const modelObj = {
                id: makes[make].models[model].id,
                name: makes[make].models[model].name,
              }
              modelsArray.push(modelObj)
            }
          }
          return modelsArray
        }
      }
      return [0]
    }

    /**
     * return models (object of name and id) given make id
     */
    getModelsFromMakeID = (id) => {
      const { vehicleTypeData } = this.props
      const { makes } = vehicleTypeData.data
      const unspecified = { id: 0, name: 'Unspecified' }
      for (const make in makes) {
        if (makes[make].id === id) {
          const modelsArray = []
          for (const model in makes[make].models) {
            if (Object.prototype.hasOwnProperty.call(makes[make].models, model)) {
              const modelObj = {
                id: makes[make].models[model].id,
                name: makes[make].models[model].name,
              }
              modelsArray.push(modelObj)
            }
          }
          // alphabetize
          modelsArray.sort((a, b) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
          })
          // Place unspecified first
          modelsArray.unshift(unspecified)
          return modelsArray
        }
      }
      return [unspecified]
    }

    /**
     * Handle alert close
     */
    alertOnClose = () => {
      this.setState({ showAlert: { show: false, type: 'error', message: null } })
    }

    /**
     * Sets alert state and sets timeout for closing
     * @param {String} type type of alert message ('success','error')
     * @param {String} message Optional alert message
     */
    setAlertDeviceMgr = (type, message) => {
      this.setState({
        showAlert: { show: true, type, message: message || null },
        deviceMgrUpdating: false,
      })
      this.alertTimeout = setTimeout(() => {
        this.alertOnClose()
      }, 5000)
    }

    /**
     * Updates the vehicle information then refetches device list
     */
    updateVehicleInfo = async (data) => {
      // deviceList.hoc
      const { refetchDevices, updateVehicle, wMatrix } = this.props
      // reset message
      this.alertOnClose()
      // set updating state
      this.setState({ deviceMgrUpdating: true })
      try {
        const updateRes = await updateVehicle({
          variables: {
            numVehicleDeviceID: data.id,
            alias: data.alias,
            make: data.make,
            model: data.model,
            year: parseInt(data.year, 10),
            color: data.color,
            license: data.license,
            vin: data.vin,
          },
        })
        // success
        if (updateRes.data.updateVehicleInfo.code === 1000) {
          // refetch deviceList
          const refetchRes = await refetchDevices()
          this.setAlertDeviceMgr(
            refetchRes ? 'success' : 'error',
            refetchRes ? wMatrix('updatedSuccessfully') : wMatrix('errorRefetchingDeviceList'),
          )
        } else {
          /** @todo Possibly add spanish for gql responses? */
          this.setAlertDeviceMgr('error', updateRes.data.updateVehicleInfo.description)
        }
      } catch (err) {
        // console.log(err)
        this.setAlertDeviceMgr('error', wMatrix('errorUpdating'))
      } finally {
        this.setState({ deviceMgrUpdating: false })
      }
    }

    /**
     * @public
     * @description Update vehicle icon
     */
    updateIcon = async (deviceId, type, color, label) => {
      const { updateVehicleIconMutation, refetchDevices } = this.props
      // set to loading
      try {
        const response = await updateVehicleIconMutation({
          variables: {
            deviceId,
            type,
            color,
            label,
          },
        })

        // success
        if (response.data && response.data.updateVehicleIcon) {
          // refetch devices
          await refetchDevices()
          // return success after devices are refetched (so entire app has correct icon)
          return response.data.updateVehicleIcon
        }
        // no data
        return null
      } catch (err) {
        // error
        throw err
      }
    }

    assignStopLength = async (deviceId, stopLength) => {
      const { assignStopMinutes } = this.props

      try {
        const response = await assignStopMinutes({
          variables: {
            deviceId,
            stopLength,
          },
        })

        // success
        if (response.data && response.data.assignStopLength) {
          // return success after devices are refetched (so entire app has correct icon)
          return response.data.assignStopLength
        }
        // no data
        return null
      } catch (err) {
        throw err
      }
    }

    assignSpeedThreshold = async (deviceId, speedThreshold) => {
      const { updateSpeedThresholdValue } = this.props

      try {
        const response = await updateSpeedThresholdValue({
          variables: {
            deviceId,
            speedThreshold,
          },
        })

        // success
        if (response.data && response.data.assignSpeedThreshold) {
          // return success after devices are refetched (so entire app has correct icon)
          return response.data.assignSpeedThreshold
        }
        // no data
        return null
      } catch (err) {
        throw err
      }
    }

    assignFobStatus = async (deviceId, enable) => {
      const { updateFobStatusValue } = this.props

      try {
        const response = await updateFobStatusValue({
          variables: {
            deviceId,
            enable,
          },
        })

        // success
        if (response.data && response.data.updateFobStatus) {
          // return success after devices are refetched (so entire app has correct icon)
          return response.data.updateFobStatus
        }
        // no data
        return null
      } catch (err) {
        throw err
      }
    }

    assignTimelineRange = async (deviceId, timelineRange) => {
      const { updateTimelineRangeValue } = this.props
      let timeRange = ''
      switch (timelineRange) {
        case 'thirty-history':
          timeRange = 'THIRTY_HISTORY'
          break
        case 'daily-history':
          timeRange = 'DAILY_HISTORY'
          break
        default:
          timeRange = timelineRange
      }

      try {
        const response = await updateTimelineRangeValue({
          variables: {
            deviceId,
            timeRange,
          },
        })

        // success
        if (response.data && response.data.updateTimelineRangeValue) {
          // return success after devices are refetched (so entire app has correct icon)
          return response.data.updateTimelineRangeValue
        }
        // no data
        return null
      } catch (err) {
        throw err
      }
    }

    /**
     * Checks to see if the given VIN exists in the DB
     * @param {String} vinValue desired VIN to check
     * @returns {Boolean} true if VIN already exists or if there was an issue
     * validating. Otherwise, false
     */
    checkIfVinExists = async (vinValue) => {
      const { apolloClient } = this.props
      if (!vinValue || vinValue.length === 0 || vinValue === '-') {
        return false
      }
      try {
        const res = await apolloClient.query({
          query: vinExists,
          fetchPolicy: 'network-only',
          variables: {
            vin: vinValue,
          },
        })
        // if we obtain a valid value, return the boolean (true or false)
        if (res && res.data && res.data.vinExists !== undefined) {
          return res.data.vinExists
        }
        // Otherwise, there was an issue, return true
        return true
      } catch {
        return true
      }
    }

    render = ({ refetch } = this.props) => {
      this.getMakes()
      const {
        showAlert, deviceMgrUpdating, selected,
      } = this.state
      const {
        iconColorsQuery, iconTypesQuery, vehicleTypeData, refetchDevices,
      } = this.props
      let iconColorsArray = []
      let iconTypesArray = []
      if (iconColorsQuery.data && iconColorsQuery.data.iconColors) {
        iconColorsArray = iconColorsQuery.data.iconColors
      }
      if (iconTypesQuery.data && iconTypesQuery.data.iconTypes) {
        iconTypesArray = iconTypesQuery.data.iconTypes
      }
      return (
        <WrappedComponent
          refetch={refetch}
          updateVehicleInfo={this.updateVehicleInfo}
          getMakeID={this.getMakeID}
          getModelID={this.getModelID}
          getModelsFromMakeID={this.getModelsFromMakeID}
          getModelsFromMakeName={this.getModelsFromMakeName}
          vehicleTypeData={vehicleTypeData}
          vehicleMakes={this.getMakes()}
          selected={selected}
          showAlert={showAlert}
          alertOnClose={this.alertOnClose}
          deviceMgrUpdating={deviceMgrUpdating}
          iconColors={iconColorsArray}
          iconTypes={iconTypesArray}
          updateIcon={this.updateIcon}
          assignStopLength={this.assignStopLength}
          assignSpeedThreshold={this.assignSpeedThreshold}
          assignFobStatus={this.assignFobStatus}
          assignTimelineRange={this.assignTimelineRange}
          refetchDevices={refetchDevices}
          checkIfVinExists={this.checkIfVinExists}
          {...this.props}
        />
      )
    }
  }
  return compose(
    queryConnector(getVehicleTypes, {}, 'vehicleTypeData'),
    queryConnector(iconColors, {}, 'iconColorsQuery'),
    queryConnector(iconTypes, {}, 'iconTypesQuery'),
    mutationConnector(updateVehicleInfo, 'updateVehicle'),
    mutationConnector(updateVehicleIcon, 'updateVehicleIconMutation'),
    mutationConnector(assignStopLength, 'assignStopMinutes'),
    mutationConnector(updateSpeedThreshold, 'updateSpeedThresholdValue'),
    mutationConnector(updateFobStatus, 'updateFobStatusValue'),
    mutationConnector(updateTimelineRange, 'updateTimelineRangeValue'),
    consumerConnector(),
  )(DeviceMgrHOC)
}

export default deviceMgrHOC
