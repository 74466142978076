import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { List } from 'antd'
import GroupOfGroups from '@mol/groupOfGroups'
import Skeleton from '@atom/skeleton'
import moment from 'moment'
import movingIcon from '@assets/img/icons/moving_icon.svg'
import stopIcon from '@assets/img/icons/stop_icon.svg'
import Icon from '@atom/icon'
// import parkingIcon from '@assets/img/icons/parking_icon.svg'
import DeviceIcon from '@atom/deviceIcon'

import Analytics from '@analytics'
import helper from '@helper'

const ListItem = List.Item

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '2px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: '5px',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'flex-start',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%',
  },
  textBox: {
    // width: '115px',
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flexGrow: '0',
    margin: '0px 4px',
    alignItems: 'flex-start',
  },
  statusIconsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    marginTop: 6,
  },
  icon: {
    alignSelf: 'center',
    // minHeight: '50px',
    width: '45px',
    // margin: '-10px 5px 0px 5px',
  },
  detailText: {
    fontSize: '0.8em',
  },
  driverText: {
    textAlign: 'center',
    fontSize: '0.7em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  miniText: {
    textAlign: 'center',
    fontSize: '0.7em',
  },
  distanceTextContainer: {
    alignSelf: 'center',
    textAlign: 'center',
    minHeight: '50px',
    width: '75px',
    margin: '0px 0px 0px 5px',
  },
  distanceText: {
    fontSize: '1.7em',
  },
  lastLocate: {
    fontSize: '0.7em',
  },
  miniTextRed: {
    fontSize: '0.7em',
    color: 'red',
  },
}

class DeviceListItem extends Component {
  /**
   * Props that should be passed:
   *  d:
   *  index:
   *  selectedDevice:
   *  selectDevice:
   *  deviceListMode:
   *
   */

    static propTypes = {
      device: PropTypes.object,
      index: PropTypes.number,
      selectedDevice: PropTypes.object,
      selectDevice: PropTypes.func,
      hoverDevice: PropTypes.func,
      deviceListMode: PropTypes.string,
      ifShowDistance: PropTypes.bool,
      checkIfMobileDevice: PropTypes.func.isRequired,
      skeleton: PropTypes.bool,
      forceUpdate: PropTypes.func,
      // callback used in Virtual List that procs reMeasure of element height
      measure: PropTypes.func,
      /** @helper */
      locatedXAgo: PropTypes.func.isRequired,
      minutesToString: PropTypes.func.isRequired,
      getPermission: PropTypes.func.isRequired,
      /** @Main */
      mainNav: PropTypes.string,
      /** @DistanceHOC */
      setOriginFlag: PropTypes.bool,
    }

    static defaultProps = {
      device: null,
      index: 0,
      selectedDevice: null,
      selectDevice: null,
      hoverDevice: null,
      deviceListMode: 'comfortable',
      ifShowDistance: false,
      skeleton: false,
      mainNav: 'map',
      measure: null,
      setOriginFlag: null,
      forceUpdate: null,
    }

    componentDidMount = () => {
      const { measure } = this.props
      // if measure function exists, measure the element on mount
      if (measure) {
        measure()
      }
    }

    /**
     * Implement didUpdate for use with virtual list.
     * @param {Object} prevProps previous props
     */
    componentDidUpdate = (prevProps) => {
      const {
        measure, deviceListMode, setOriginFlag, forceUpdate, device,
      } = this.props
      // If compoenent is part of virtualList, proc measure on layout change
      if (measure && (
        (prevProps.deviceListMode !== deviceListMode) || (prevProps.setOriginFlag !== setOriginFlag)
      )) {
        measure()
      }
      // New locates and status updates should re-render the list
      const newStatus = device?.status && prevProps.device?.status
        && prevProps.device.status !== device.status
      const newLocate = device?.locate && prevProps.device?.locate
        && prevProps.device.locate.address.full !== device.locate.address.full
      if (forceUpdate && (newStatus || newLocate)) {
        forceUpdate()
        measure()
      }
    }

    /* get the Icon on the left side of a device block,
    depends on if vehicle is moving, parking or stopped */
    getIconByStatus = (device) => {
      switch (device.status.state) {
        /* This is returned from stored procedure directly gps.dbo.[v3_DeviceStatus_get] */
        case 'Stopped':
          return stopIcon
        case 'Moving':
          return movingIcon
        // case 'Parking':
        //   return stopIcon
        default:
          return stopIcon
      }
    }

    returnTags = () => {
      const { device, deviceListMode } = this.props
      const wrap = deviceListMode !== 'compact'
      if ((deviceListMode !== 'cozy') && (device.groups || device.labels)) {
        return (
          <div style={{
            marginLeft: '8px', display: 'flex', flexWrap: 'nowrap', alignSelf: deviceListMode === 'compact' ? 'center' : 'flex-start',
          }}
          >
            <GroupOfGroups
              groups={device.groups}
              size={deviceListMode === 'compact' ? 'small' : 'large'}
              labels={device.labels}
              maxCharacters={25} // make this a feature of group of groups
              wrap={wrap}
            />
          </div>
        )
      }
      return null
    }

    /**
     * Compares the last locate with the current time.
     *
     * Used to show if there is a problem with the device. If the device is still
     * in moving state and there has been some time, there's a problem.
     *
     * @param {string} datetimeUTC of last locate
     * @param {string} status of the device (moving, stopped, etc)
     */
    returnTimeSinceLastLocate = (datetimeUTC, status) => {
      const { locatedXAgo, minutesToString } = this.props
      // grab utc datetime to compare to current utc datetime
      const lastLocateUTC = moment(datetimeUTC)
      const momentNowUTC = moment.utc()
      const minSinceLastLocate = momentNowUTC.diff(lastLocateUTC, 'minutes')
      if (minSinceLastLocate > 10 && status.state === 'Moving') {
        return (
          <div style={minSinceLastLocate > (30 * 24 * 60) ? styles.miniTextRed : styles.miniText}>
            {locatedXAgo(minutesToString(minSinceLastLocate, 30))}
          </div>
        )
      }
      return null
    }

    /**
     * Returns component with landmark if available, address if not
     */
    returnLocation = () => {
      const { device } = this.props
      const { locate } = device
      const { landmarks, address } = locate
      // console.log('locate: ', locate)
      if (landmarks.length > 0) {
        const landmarksClone = JSON.parse(JSON.stringify(landmarks))
        const landmarksText = landmarksClone.map(landmark => landmark.name).join(', ')
        return (
          <div style={{
            ...styles.detailText,
            display: 'flex',
            flexWrap: 'no-wrap',
          }}
          >
            <div style={{ margin: 4 }}>
              <Icon type="location-pin" color="textGray" width={10} />
            </div>
            {landmarksText}
          </div>
        )
      }
      return (
        <>
          <div style={styles.detailText}>
            {address && address.street_address ? address.street_address : ''}
          </div>
          <div style={styles.detailText}>
            {address && address.city ? `${address.city}, ` : ''}
            {address && address.state ? address.state : ''}
          </div>
        </>
      )
    }

    // h2 = 1.5em
    returnTextBox = () => {
      const { deviceListMode, device } = this.props
      const { locate, status } = device
      switch (deviceListMode) {
        case 'comfortable':
        case 'cozy':
          return (
            <div style={styles.textBox}>
              <h3
                style={{
                  marginBottom: 0, marginRight: 2,
                }}
                title={device.alias}
              >
                {device.alias}
              </h3>
              {this.returnLocation()}
              {locate ? this.returnTimeSinceLastLocate(locate.datetimeUTC, status) : null}
            </div>
          )
        default:
          return (
            <div style={styles.textBox}>
              <h3 style={{ marginBottom: 0, maxWidth: 175 }} title={device.alias}>
                {device.alias}
              </h3>
            </div>
          )
      }
    }

    /**
    * Returns Device icon with drivername below
    */
    returnDriverIcon = () => {
      const {
        deviceListMode, ifShowDistance, device, measure,
      } = this.props
      if (deviceListMode === 'compact') {
        return null
      }
      if (ifShowDistance) {
        return (
          <div style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
          >
            <div style={styles.distanceTextContainer}>
              <span style={styles.distanceText}>{device.distance}</span>
              <span> mi</span>
            </div>
            <div style={styles.detailText}>{device.currentDriver ? (`${device.currentDriver.firstName.substring(0, 1)}. ${device.currentDriver.lastName}`) : ''}</div>
          </div>
        )
      }
      return (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: 45,
        }}
        >
          <div style={styles.icon}>
            <DeviceIcon
              measure={measure}
              icon={device.icon}
              inDeviceList
            />
          </div>
          <div style={styles.driverText}>
            {device.currentDriver
              ? (`${device.currentDriver.firstName.substring(0, 1)}. ${device.currentDriver.lastName}`)
              : ''
          }
          </div>
        </div>
      )
    }

    returnHeader = () => {
      const { deviceListMode, device } = this.props
      return (
        <div style={styles.header}>
          <h4 style={{
            marginBottom: '0px', display: 'inline',
          }}
          >
            {device.alias}

          </h4>
          {deviceListMode === 'compact' ? this.returnTags() : <img style={{ transform: 'scale(0.6,0.6)' }} src={this.getIconByStatus(device)} alt="device status" />}
        </div>
      )
    }

    /**
    * @description Returns Icon for device moving status
    * 25wx30h
    * @returns {Element}
    */
    returnDeviceStatus = () => {
      const { device, minutesToString } = this.props
      return (
        <div style={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 4, maxWidth: 40,
        }}
        >
          <img style={{ maxWidth: '20px' }} src={this.getIconByStatus(device)} alt="device status" />
          <div style={styles.miniText}>
            {
            device.status.stoppedMinutes
              ? minutesToString(device.status.stoppedMinutes, 30) : null
            }
          </div>
        </div>
      )
    }

    /**
     * @description Returns camera status icon
     * @returns {Element}
     */
    returnCameraStatus = () => {
      const { device, getPermission } = this.props
      const { type, camera } = device
      if (type.includes('IMD') && getPermission('Camera', 'ynRead')) {
        const color = camera?.status === 'online' ? 'green' : 'red'
        return (
          <div style={{ margin: '5px 4px 4px 4px' }}>
            <Icon
              type="camera"
              width={15}
              color={color}
            />
          </div>
        )
      }
      return null
    }

    /**
     * @description Groups camera status and device status into a flex and returns it
     * @returns {Element}
     */
    returnStatusIcons = () => (
      <div style={styles.statusIconsContainer}>
        {this.returnCameraStatus()}
        {this.returnDeviceStatus()}
      </div>
    )

    /** returns skeleton version of card */
    returnSkeletonCard = () => (
      <div style={{ ...styles.card, borderBottom: '1px solid #e9e9e9', marginBottom: '5px' }}>
        <div style={styles.header}>
          <Skeleton wrapperStyle={{
            width: '36px', height: '14px', marginBottom: '14px',
          }}
          />
          <Skeleton wrapperStyle={{
            height: '20px', width: '20px', borderRadius: '20px', margin: '5px', alignSelf: 'center',
          }}
          />
        </div>
        <div style={styles.content}>
          <Skeleton wrapperStyle={{ height: '38px', width: '38px', margin: '5px' }} />
          <div>
            <Skeleton wrapperStyle={{ height: '14px', width: '152px', margin: '5px' }} />
            <Skeleton wrapperStyle={{ height: '14px', width: '152px', margin: '5px' }} />
          </div>
        </div>
      </div>
    )

    /**
     * return the content on the right side for compact mode
     * it could be tags, statusIcon or distance
     * */
    returnRightSideContent = () => {
      const {
        deviceListMode,
        ifShowDistance,
        device,
      } = this.props

      if (deviceListMode === 'compact') {
        if (ifShowDistance) {
          return `${device.distance} mi`
        }
        return this.returnTags()
      }
      return this.returnStatusIcons()
    }

    returnCard = () => {
      const {
        device,
        index,
        selectedDevice,
        selectDevice,
        deviceListMode,
        hoverDevice,
        checkIfMobileDevice,
        mainNav,
      } = this.props

      return (
        <ListItem
          key={device.id}
          className={`deviceListItem ${(selectedDevice && selectedDevice.id === device.id) ? 'selected' : ''}`}
          onClick={() => {
            Analytics.record({
              feature: 'device_list',
              page: `${mainNav}`,
              event: 'select_device',
            })

            selectDevice(device, index)
          }}
          onMouseEnter={
            () => ((hoverDevice && !checkIfMobileDevice()) ? hoverDevice(device, index) : null)
          }
          onMouseLeave={
            () => ((hoverDevice && !checkIfMobileDevice()) ? hoverDevice(null, -1) : null)
          }
        >
          <div style={styles.card}>
            {/* {this.returnHeader()} */}
            <div style={styles.content}>
              <div style={{ display: 'flex' }}>
                {this.returnDriverIcon()}
                {this.returnTextBox()}
              </div>
              {this.returnRightSideContent()}
            </div>
            {deviceListMode !== 'compact' ? this.returnTags() : null}
          </div>
        </ListItem>
      )
    }

    render() {
      const { skeleton } = this.props
      return (
        skeleton ? this.returnSkeletonCard() : this.returnCard()
      )
    }
}

export default helper()(DeviceListItem)
