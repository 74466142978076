import React, { Component } from 'react'
import socket from '@graphql/socket'
import { notification, Icon } from 'antd'
import EventIcon from '@atom/eventIcon'
import PropTypes from 'prop-types'
import ButtonWrapper from '@atom/textButton'

const notificationsHOC = () => (WrappedComponent) => {
  class NotificationsHOC extends Component {
    static propTypes = {
      /** @helper */
      eventToString: PropTypes.func.isRequired,
      addressToString: PropTypes.func.isRequired,
      speedToString: PropTypes.func.isRequired,
    }

    constructor(props) {
      super(props)
      this.state = {
        notifications: [],
        deliverQuietly: localStorage.getItem('rft-deliverNotifsQuietly')
          ? JSON.parse(localStorage.getItem('rft-deliverNotifsQuietly')) : false,
        // This is managed at the hoc level to also manage weather to fire off the pop up
        dropDownVisible: false,
      }
    }


    componentDidMount() {
      const contactId = parseInt(sessionStorage.getItem('contactId'), 10)
      // on recieving contactId check
      socket.on('checkingAlertContactId', (resContactId) => {
        if (contactId === resContactId) {
          socket.emit('alertContactId', contactId)
        }
      })
      // on recieving new alert
      socket.on('alert', (newAlert) => {
        this.pushNewAlert(newAlert)
        this.openNotification(newAlert)
      })
      // temp
      // this.interval = setInterval(() => this.pushNewAlert({ eventName: 'blah' }), 10000)
    }

    updateDeliverQuietlyPref = (checked) => {
      localStorage.setItem('rft-deliverNotifsQuietly', checked)
      this.setState({ deliverQuietly: checked })
    }

    /**
     * Clears specified alert from list. If no ID is passed, clear all alerts.
     * @param {String} alertId The unique alert id. If none is passed, clear all alerts
     */
    clearAlerts = (alertId) => {
      const { notifications } = this.state
      let notifsClone = JSON.parse(JSON.stringify(notifications))
      if (!alertId) {
        this.setState({ notifications: [] })
      } else {
        notifsClone = notifsClone.filter(notif => notif.id !== alertId)
        this.setState({ notifications: notifsClone })
      }
    }

    pushNewAlert = (newAlert) => {
      const { notifications } = this.state
      const notifsClone = JSON.parse(JSON.stringify(notifications))
      notifsClone.unshift(newAlert)
      if (notifsClone.length > 20) {
        const deleteCount = notifsClone.length - 20
        notifsClone.splice(20, deleteCount)
      }
      this.setState({ notifications: notifsClone })
    }

    /**
     * Fires off the notification pop up
     */
    openNotification = (newAlert) => {
      const { deliverQuietly, dropDownVisible } = this.state
      const { eventToString } = this.props
      const { raw: { event: { type } } } = newAlert
      if (deliverQuietly || dropDownVisible) {
        return
      }
      notification.open({
        key: newAlert.id,
        message: eventToString(type),
        description: this.returnAlertContentElement(newAlert),
        duration: 15,
        icon: (
          <EventIcon
            event={newAlert.raw.event.type}
            speed={newAlert.raw.speed.mph}
            imgStyle={{ marginTop: 5, marginBottom: 5 }}
          />
        ),
        onClick: () => {
          // Show alert in notif menu on click
          this.highlightNotification(newAlert.id)
          this.setState({ dropDownVisible: true })
          this.closeAllNotifications()
        },
        // On close, remove from notifications array
        closeIcon: (
          <ButtonWrapper
            useAsWrapper
            onClick={() => this.clearAlerts(newAlert.id)}
          >
            <Icon style={{ color: '#000000A6' }} type="close" />
          </ButtonWrapper>
        ),
      })
    }

    /**
     * This closes all notification pop ups. Used when opening the notifications history menu.
     */
    closeAllNotifications = () => {
      const { notifications } = this.state
      for (let i = 0; i < notifications.length; i += 1) {
        notification.close(notifications[i].id)
      }
    }

    /**
     * Sets the highlightedNotif State to the provided alert Id and then sets it back to
     * null after 5 seconds. This is to highlight the selected notification from
     * the menu when a notification pop up was clicked.
     * @param {String} alertId Identifier for alert
     */
    highlightNotification = (alertId) => {
      this.setState({ highlightedNotif: alertId })
      setTimeout(() => {
        this.setState({ highlightedNotif: null })
      }, 5000)
    }

    returnAlertContentElement = alert => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>
          <strong>{alert.alert.alias}</strong>
          {alert.alert.driver ? ` (${alert.alert.driver})` : ''}
        </span>
        <span>
          {this.buildAlertDetails(alert)}
        </span>
      </div>
    )

    /**
     * Toggles the visibility state of the dropdown
     */
    toggleDropDownVisible = () => {
      const { dropDownVisible } = this.state
      this.setState({ dropDownVisible: !dropDownVisible })
    }

    /**
     * This is a callback function passed by ant designs dropdown.
     * @param {Boolean} flag Visible state passed by ant designs dropdown
     */
    handleVisibleChange = (flag) => {
      this.setState({ dropDownVisible: flag })
    }

    /**
     * The following section contains the notification formatting functions
     */

    /**
     * Returns user readable string for DTC codes. This was originally
     * copied from v3-consumer-alerts
     * @param {String} code DTC code from alert data
     * @returns {String}
     */
      formatDTC = (code) => {
        if (code === '-1') {
          return 'No Code Sent'
        }
        return code.replace(/,\s*$/, '')
      }

    /**
      * Converts raw alert payload data into a readable description. This function has been copied
      * from the alerts consumer and modified.
      * @param {Object} payload Alert payload object
      * @returns {String} Details of an alert in readable string format.
      */
    buildAlertDetails = (payload) => {
      const { addressToString, speedToString } = this.props
      switch (payload.raw.event.type) {
        case 'after_hours':
          return `Unauthorized Movement at ${payload.alert.time} near ${addressToString(payload.address)}`
        case 'disconnect':
          return `Power Disconnect at ${payload.alert.time} near ${addressToString(payload.address)}`
        case 'dtc':
          return `Diagnostic Trouble Code ${this.formatDTC(payload.raw.dtc.code)} at ${payload.alert.time} near ${addressToString(payload.address)}`
        case 'fast_start':
          return `Fast Start at ${payload.alert.time} near ${addressToString(payload.address)}`
        case 'hard_brake':
          return `Hard Break ${payload.alert.time} near ${addressToString(payload.address)}`
        case 'landmark_enter':
          return `Landmark Entered at ${payload.alert.time} near ${addressToString(payload.address)}.${payload.landmarkEvent ? (` Has entered ${payload.landmarkEvent.name}`) : ''}`
        case 'landmark_exit':
          return `Landmark Exited at ${payload.alert.time} near ${addressToString(payload.address)}.${payload.landmarkEvent ? (` Has exited ${payload.landmarkEvent.name}`) : ''}`
        case 'low_battery':
          return `Battery low for ${payload.alert.alias}`
        case 'mosl':
          return `Excessive speeding at ${payload.alert.time} near ${addressToString(payload.address)}. ${speedToString(payload.raw.speed.mph)} in a ${speedToString(payload.raw.location.speedLimit.mph)} zone`
        case 'panic':
          return `Panic Button Alert ${payload.alert.time} near ${addressToString(payload.address)}`
        case 'pto_primary_off':
          return `PTO Primary Off occured at ${payload.alert.time} near ${addressToString(payload.address)}`
        case 'pto_primary_on':
          return `PTO Primary On occured at ${payload.alert.time} near ${addressToString(payload.address)}`
        case 'pto_secondary_off':
          return `PTO Secondary Off occured at ${payload.alert.time} near ${addressToString(payload.address)}`
        case 'pto_secondary_on':
          return `PTO Secondary On occured at ${payload.alert.time} near ${addressToString(payload.address)}`
        case 'reconnect':
          return `Power Reconnect at ${payload.alert.time} near ${addressToString(payload.address)}`
        case 'speeding':
          return `Speeding at ${payload.alert.time} near ${addressToString(payload.address)}. Moving at ${speedToString(payload.raw.speed.mph)}`
        case 'start':
          return `Motion Initiated at ${payload.alert.time} near ${addressToString(payload.address)}`
        case 'stop':
          return `Motion Concluded at ${payload.alert.time} near ${addressToString(payload.address)}`
        case 'fuelcard_misuse':
          return `Possible Fuelcard Misuse at ${payload.alert.time} near ${addressToString(payload.address)}`
        default:
          return null
      }
    }

    render() {
      const {
        notifications, deliverQuietly, dropDownVisible, highlightedNotif,
      } = this.state
      return (
        <WrappedComponent
          pushNotifs={notifications}
          updateDeliverQuietlyPref={this.updateDeliverQuietlyPref}
          deliverQuietly={deliverQuietly}
          clearAlerts={this.clearAlerts}
          buildAlertDetails={this.buildAlertDetails}
          dropDownVisible={dropDownVisible}
          toggleDropDownVisible={this.toggleDropDownVisible}
          handleVisibleChange={this.handleVisibleChange}
          highlightedNotif={highlightedNotif}
          {...this.props}
        />
      )
    }
  }
  // return compose(

  // )(NotificationsHOC)
  return NotificationsHOC
}

export default notificationsHOC
