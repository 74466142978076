import PropTypes from 'prop-types'
import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import TripMarker from '@mol/tripMarker'
import Marker from '@atom/marker'
import equal from 'deep-equal'
import helper from '@helper'

import greenCarKey from '@assets/img/mapIcons/CarKey/Green.svg'
import redCarKey from '@assets/img/mapIcons/CarKey/Red.svg'
import landmarkIcon from '@assets/img/mapIcons/Landmark.svg'
import exceptionIcon from '@assets/img/mapIcons/Exception.svg'
import Speedometer from '@assets/img/mapIcons/Speedometer.svg'
import locateIcon from '@assets/img/mapIcons/Locate.svg'
import pedal from '@assets/img/mapIcons/Pedal.svg'
import turn from '@assets/img/mapIcons/Harsh Turn.svg'
import movementConclude from '@assets/img/mapIcons/Movement Concluded.svg'
import movementInitiate from '@assets/img/mapIcons/Movement Initiated.svg'
import stop from '@assets/img/mapIcons/Stop.svg'
import tripStart from '@assets/img/mapIcons/Trip Start.svg'
import tripEnd from '@assets/img/mapIcons/Trip End.svg'
import afterHours from '@assets/img/mapIcons/AfterHours.svg'
import disconnect from '@assets/img/mapIcons/Disconnect.svg'
import reconnect from '@assets/img/mapIcons/Reconnect.svg'
import idle from '@assets/img/mapIcons/Idle.svg'
import panicButton from '@assets/img/mapIcons/Panic Button.svg'
import ptoOn from '@assets/img/mapIcons/PTO On.svg'
import ptoOff from '@assets/img/mapIcons/PTO Off.svg'
import locateArrow from '@assets/img/mapIcons/locateArrow.svg'
import locateDot from '@assets/img/mapIcons/dot.png'
import fuelCardIcon from '@assets/img/mapIcons/FuelcardEvent.svg'

/**
 *
 * Procedure:
 * 1. Initialize the map and save its reference.
 * 2. When events are given, make the map bounds fit all or most of the markers
 *
 * @link https://github.com/google-map-react/google-map-react/blob/master/API.md
 * @link https://developers.google.com/maps/documentation/javascript/reference/map
 *
 *
 * @TODO Update streetView markers (this.addGoogleMarkers())
 *  - make icons larger (especially arrow)
 *  - add heading to arrow markers
 *  - make markers flat (parallel to ground)
 */

const styles = {
  landmarkShape: {
    strokeColor: '#4482FF',
    strokeOpacity: 0.83,
    strokeWeight: 2,
    fillColor: '#4482FF',
    fillOpacity: 0.38,
  },
}

class TimelineMap extends Component {
  map = null

  maps = null

  landmarkShapes = {}

  static propTypes = {
    initMap: PropTypes.func.isRequired,
    // selectedDevice: PropTypes.object,
    settings: PropTypes.object.isRequired,
    fitBounds: PropTypes.func,
    center: PropTypes.object,
    zoom: PropTypes.number,
    events: PropTypes.array.isRequired,
    selectedTrip: PropTypes.number.isRequired,
    selectedEventID: PropTypes.number,
    selectEvent: PropTypes.func.isRequired,
    createLandmark: PropTypes.func.isRequired,
    landmarks: PropTypes.array,
    findLandmarkCenter: PropTypes.func.isRequired,
  }

  static defaultProps = {
    center: {
      lat: 30.116936,
      lng: -97.129397,
    },
    zoom: 1,
    // selectedDevice: null,
    fitBounds: null,
    selectedEventID: null,
    landmarks: null,
  }

  state = {
    map: null,
    maps: null,
    googleMarkers: [],
    boundsChanged: false,
  }

  componentDidUpdate(prevProps, prevState) {
    const { events, selectedEventID } = this.props
    const { maps } = this.state
    /**
     * Whenever events have loaded, we need to update
     * the view and zoom to fit the marker(s)
     * We also need to update if an individual event was selected.
     * Also, there is a case when the maps object is not yet saved. In this case, we check if
     * that state has changed and if it was null. (that way we dont keep updating
     * everytime the maps changes)
     */
    if ((prevProps.events?.length === 0 && events.length > 0)
      || prevProps.selectedEventID !== selectedEventID
      || (!equal(prevState.maps, maps) && prevState.maps === null)) {
      this.fitToCoordinates(events.length > 0)
    }
    /**
     * This is to create the necessary google.markers for use in streetView
     * (Currently, we do not have menu/clickable icons in streetView)
     */
    if (!equal(prevProps.events, events) && maps) {
      this.addGoogleMarkers()
    }

    this.handleBoundChange()
  }

  /**
   * @description Cleanly clears timer. Removes event listener for resizing
   */
  componentWillUnmount = () => {
    this.clearBoundsTimer()
  }

  onBoundsChange = (/* center, zoom, bounds, marginBounds */) => {
    this.handleBoundChange()
  }

  /**
  * @private
  * @description handles map bounds change
  */
  handleBoundChange = () => {
    if (this.boundsTimer) {
      return
    }
    this.boundsTimer = setTimeout(() => {
      this.toggleLandmarkShapes()
      this.boundsTimer = 0

      this.setState({ boundsChanged: true })
    }, 1200)
  }

  /**
  * @private
  * @description Used to cleanly clear bounds timer
  */
  clearBoundsTimer = () => {
    // If timer is running, clear
    if (this.boundsTimer) {
      clearTimeout(this.boundsTimer)
      this.boundsTimer = 0
    }
  }

  // Sets the map on all markers in the array.
  setMapOnAllMarkers = (map) => {
    const { googleMarkers } = this.state
    for (let i = 0; i < googleMarkers.length; i += 1) {
      googleMarkers[i].setMap(map)
    }
  }

  // Hides Markers, but keeps in array
  hideGoogleMarkers = () => {
    this.setMapOnAllMarkers(null)
  }

  // Deletes all google markers
  deleteGoogleMarkers = () => {
    this.hideGoogleMarkers()
    this.setState({ googleMarkers: [] })
  }

  // If map is initiated, add all markers to map
  showGoogleMarkers = () => {
    const { map } = this.state
    if (map) {
      this.setMapOnAllMarkers(map)
    }
  }

  /**
   * @description currently used to provide icon to streetView markers
   */
  getEventIcon = (event, heading, speed) => {
    switch (event) {
      case 'locate':
        if (heading && speed) return locateArrow
        return locateIcon
      case 'locateArrow':
        return locateArrow
      case 'locateDot':
        return locateDot
      case 'ignition_off':
        return redCarKey
      case 'ignition_on':
        return greenCarKey
      case 'speeding':
      case 'mosl':
        return Speedometer
      case 'landmark_enter':
      case 'landmark_exit':
        return landmarkIcon
      case 'fast_start':
        return pedal
      case 'hard_brake':
        return pedal
      case 'harsh_turn':
        return turn
      case 'stop':
        return movementConclude
      case 'stop_begin':
      case 'stop_end':
        return stop
      case 'start':
        return movementInitiate
      case 'trip_end':
        return tripEnd
      case 'trip_begin':
        return tripStart
      case 'after_hours':
        return afterHours
      case 'disconnect':
        return disconnect
      case 'reconnect':
        return reconnect
      case 'idling':
      case 'idle_begin':
      case 'idle_end':
        return idle
      case 'panic_Button':
        return panicButton
      case 'pto_primary_on':
      case 'pto_secondary_on':
      case 'pto_tertiary_on':
        return ptoOn
      case 'pto_primary_off':
      case 'pto_secondary_off':
      case 'pto_tertiary_off':
        return ptoOff
      case 'fuelcard_transaction':
        return fuelCardIcon
      default:
        return exceptionIcon
    }
  }

  /**
   * @private
   * @description Creates the google markers needed for street view
   */
  addGoogleMarkers = () => {
    const { googleMarkers } = this.state
    const { events } = this.props
    // first clear
    if (googleMarkers.length > 0) {
      this.deleteGoogleMarkers()
    }
    // create necessary markers
    if (events.length > 0) {
      const newMarkers = []
      events.forEach((event) => {
        if (event.lat && event.lng) {
          const eventIcon = {
            url: this.getEventIcon(event.event, event.heading, event.speed),
            scaledSize: new this.maps.Size(40, 40),
            origin: new this.maps.Point(0, 0),
            anchor: new this.maps.Point(20, 20),
          }
          const googleMarker = new this.maps.Marker({
            position: { lat: event.lat, lng: event.lng },
            icon: eventIcon,
            map: null,
          })
          newMarkers.push(googleMarker)
        }
      })
      // set state of markers
      this.setState({ googleMarkers: newMarkers })
    }
  }

  /**
   * @private
   * @description Creates the event markers for normal map view
   */
  markers = () => {
    const {
      events, selectedTrip, selectEvent, selectedEventID, createLandmark,
    } = this.props
    if (events.length > 0) {
      // clone events to map
      const eventsClone = JSON.parse(JSON.stringify(events))
      return eventsClone.map((event) => {
        if (event.lat && event.lng) {
          return (
            <TripMarker
              id={event.id}
              tripID={event.tripId} // may be undefined
              selected={(selectedEventID === event.id)
                && (!event.tripId || event.tripId === selectedTrip)}
              clickable
              lat={event.lat}
              lng={event.lng}
              heading={event.heading}
              speed={event.speed}
              key={event.tripId ? `${event.tripId}-${event.id}` : `${selectedTrip}-${event.id}`}
              type={event.event}
              selectEvent={selectEvent}
              createLandmark={createLandmark}
              onItemOpen={() => { this.disableDoubleClickToZoom(true) }}
              onItemClose={() => { this.disableDoubleClickToZoom(false) }}
            />
          )
        } return null
      })
    }
    return (
      null
    )
  }

  /**
   * @public
   * Disable or enable doubleClickToZoom
   * @param {boolean} disable to set specific state
   */
  disableDoubleClickToZoom = (disable) => {
    const { map } = this.state
    map.disableDoubleClickZoom = disable
    this.setState({ map })
  }


  /**
   * @description Creates a Google shape based on the landmark's definition
   * @param {Object} landmark
   * @returns Google Circle, Polygon, or Rectangle object
   */
  getLandmarkShape = (landmark) => {
    let shape
    if (landmark.type === 'circle') {
      shape = new this.maps.Circle({
        ...styles.landmarkShape,
        map: this.map,
        center: landmark.center[0],
        radius: landmark.radius,
        editable: false,
        draggable: false,
        zIndex: 1,
      })
    } else if (landmark.type === 'polygon') {
      shape = new this.maps.Polygon({
        ...styles.landmarkShape,
        map: this.map,
        paths: landmark.points,
        editable: false,
        draggable: false,
        zIndex: 1,
      })
    } else {
      const south = landmark.points[0].lat
      const north = landmark.points[1].lat
      const east = landmark.points[2].lng
      const west = landmark.points[0].lng
      shape = new this.maps.Rectangle({
        ...styles.landmarkShape,
        map: this.map,
        bounds: {
          north,
          south,
          east,
          west,
        },
        editable: false,
        draggable: false,
        zIndex: 1,
      })
    }

    return shape
  }

  /**
   * @description Creates or changes a landmark's shape based on the zoom level
   */
  toggleLandmarkShapes = () => {
    const { landmarks, settings } = this.props

    // Check to see if landmark shape was already created
    if (this.map && landmarks) {
      for (let i = 0; i < landmarks.length; i += 1) {
        const landmark = landmarks[i]
        if (this.landmarkShapes[i] === undefined && settings.showLandmarks) {
          this.landmarkShapes[i] = this.getLandmarkShape(landmark)
        } else if (this.landmarkShapes[i] !== undefined) {
          if (this.map.zoom > 10 && settings.showLandmarks) {
            this.landmarkShapes[i].setMap(this.map)
          } else {
            this.landmarkShapes[i].setMap(null)
          }
        }
      }
    }
  }

  /**
     * @private
     * @description Creates landmark markers for map
     * @returns {[Marker]}  Returns array of marker components
     */
  landmarkMarkers = () => {
    const { landmarks, settings, findLandmarkCenter } = this.props
    const { boundsChanged } = this.state

    if (boundsChanged && settings.showLandmarks && landmarks && this.maps) {
      const landmarkMarkers = []
      for (let i = 0; i < landmarks.length; i += 1) {
        const landmark = landmarks[i]
        const position = findLandmarkCenter(landmark)
        if (this.map.getBounds().contains(position)) {
          landmarkMarkers.push(
            <Marker
              lat={position.lat}
              lng={position.lng}
              alias={landmark.name}
              key={landmark.id}
              type="landmark"
            />,
          )
        }
      }
      return landmarkMarkers
    }
    return null
  }

  /**
   * @private
   *
   * Fits the map to the events or a selected device's last locate
   *
   * @param {boolean} isSelectedDevice
   */
  fitToCoordinates = (eventsExist) => {
    const { maps, map } = this.state
    // Make sure map/maps has been loaded
    if (maps) {
      const bounds = new maps.LatLngBounds()
      const {
        events, selectedEventID,
        fitBounds, center,
      } = this.props
      // set bounds
      // If there are events
      if (eventsExist) {
        // If there is a selected event
        if (selectedEventID) {
          for (let i = 0; i < events.length; i += 1) {
            if (events[i].id === selectedEventID) {
              bounds.extend(new maps.LatLng(
                events[i].lat, events[i].lng,
              ))
            }
          }
        } else {
          for (let i = 0; i < events.length; i += 1) {
            if (events[i].lat && events[i].lng) {
              bounds.extend(new maps.LatLng(
                events[i].lat, events[i].lng,
              ))
            }
          }
        }
      } else {
        // this is usually run on first load/render (no events)
        bounds.extend(new maps.LatLng(
          center.lat, center.lng,
        ))
      }
      // zoom/fitbounds
      if (selectedEventID && fitBounds) {
        fitBounds(bounds, 14)
      } else {
        // When we zoom without selected device, drawer is open, so
        // use map's fitBounds function to add padding to bounds
        map.fitBounds(bounds, {
          top: 20, left: 20, bottom: 20, right: 330,
        })
      }
    }
  }

  /**
   * @public
   * @description Callback for our custom initialization. This also sets a listener for the
   * streetView visibility state (to hide or show streetView markers)
   */
  initMap = (map, maps) => {
    this.setState({
      map,
      maps,
    })
    this.map = map
    this.maps = maps
    // set up streetView listener
    const thePanorama = map.getStreetView()
    maps.event.addListener(thePanorama, 'visible_changed', () => {
      if (thePanorama.getVisible()) {
        // Show streetView markers
        this.showGoogleMarkers(map)
      } else {
        // Hide streetView markers
        this.hideGoogleMarkers()
      }
    })
  }


  /**
   * @private
   *
   * Customize the map on init
   */
  createMapOptions = maps => ({
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: maps.ControlPosition.LEFT_BOTTOM,
    },
    streetViewControl: true,
    streetViewControlOptions: {
      position: maps.ControlPosition.LEFT_BOTTOM,
    },
    zoomControlOptions: {
      position: maps.ControlPosition.LEFT_BOTTOM,
    },
    disableDoubleClickZoom: false,
  })

  render() {
    const {
      center, zoom, initMap,
    } = this.props
    return (
      <GoogleMapReact
        bootstrapURLKeys={{
          client: 'gme-twmatters',
        }}
        onGoogleApiLoaded={({ map, maps }) => initMap(map, maps, this.initMap)}
        // onChange={this.boundsChange} // will eventually govern the select event.
        defaultCenter={center}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        options={this.createMapOptions}
        onBoundsChange={this.onBoundsChange}
      >
        {this.markers()}
        {this.landmarkMarkers()}
      </GoogleMapReact>
    )
  }
}

export default helper()(TimelineMap)
