import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'react-apollo'
import queryConnector from '@graphql/queryConnector'
import mutationConnector from '@graphql/mutationConnector'
import { contactMetaQuery, customBrandingQuery } from '@graphql/query'
import { updateContactMetaMutation } from '@graphql/mutation'
import equal from 'deep-equal'
import helper from '@helper'

const densityEnum = {
  COMFY: 'comfortable',
  COZY: 'cozy',
  COMPACT: 'compact',
}

const iconSizeEnum = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
}

const contactMetaHOC = () => (WrappedComponent) => {
  class ContactMetaHOC extends React.Component {
    static propTypes = {
      contactMeta: PropTypes.object.isRequired,
      customBranding: PropTypes.object.isRequired,
      updateContactMeta: PropTypes.func.isRequired,
    }

    state = {
      listDensity: localStorage.getItem('rft-listDensity') || densityEnum.COMFY,
      mapIconDensity: localStorage.getItem('rft-mapIconDensity') || densityEnum.COMFY,
      mapIconSize: localStorage.getItem('rft-mapIconSize') || iconSizeEnum.LARGE,
      clearStorage: localStorage.getItem('rft-listDensity'),
    }

    componentDidUpdate(prevProps) {
      const { contactMeta: cm } = this.props
      const {
        listDensity, mapIconDensity, mapIconSize,
      } = this.state

      if (!equal(cm, prevProps.contactMeta)) {
        if (cm?.data?.contactMeta?.clientId) {
          sessionStorage.setItem('clientId', cm.data.contactMeta.clientId)
        }
        if (cm?.data?.contactMeta?.contactId) {
          sessionStorage.setItem('contactId', cm.data.contactMeta.contactId)
        }

        let listDensityVal = listDensity
        let mapIconDensityVal = mapIconDensity
        let mapIconSizeVal = mapIconSize
        let hasValue = 0

        if (cm?.data?.contactMeta?.listDensity) {
          listDensityVal = cm.data.contactMeta.listDensity
          hasValue += 1
        }

        if (cm?.data?.contactMeta?.mapIconDensity) {
          mapIconDensityVal = cm.data.contactMeta.mapIconDensity
          hasValue += 1
        }

        if (cm?.data?.contactMeta?.mapIconSize) {
          mapIconSizeVal = cm.data.contactMeta.mapIconSize
          hasValue += 1
        }
        if (!cm.loading && hasValue !== 3) {
          this.writeContactMeta(listDensityVal, mapIconDensityVal, mapIconSizeVal)
        }
      }
    }

    writeContactMeta = async (listDensity, mapIconDensity, mapIconSize) => {
      const {
        updateContactMeta,
      } = this.props

      const {
        clearStorage,
      } = this.state

      this.setState({ listDensity, mapIconDensity, mapIconSize })

      // set to loading
      try {
        await updateContactMeta({
          variables: {
            listDensity: listDensity.toUpperCase(),
            mapIconDensity: mapIconDensity.toUpperCase(),
            mapIconSize: mapIconSize.toUpperCase(),
          },
        })
      } catch (err) {
        // error
        throw err
      }

      // Check for localStorage
      if (clearStorage !== null) {
        localStorage.removeItem('rft-listDensity')
        localStorage.removeItem('rft-mapIconDensity')
        localStorage.removeItem('rft-mapIconSize')
        this.setState({ clearStorage: null })
      }

      return null
    }

    render() {
      const { contactMeta: cm, customBranding: cb } = this.props
      const { listDensity, mapIconSize, mapIconDensity } = this.state
      const logo = {
        src: cb?.data?.customBranding?.logo,
        loading: cb?.loading,
      }

      return (
        <WrappedComponent
          {...this.props}
          clientId={cm?.data?.contactMeta?.clientId}
          contactId={cm?.data?.contactMeta?.contactId}
          minuteTZOffset={cm?.data?.contactMeta?.minuteTZOffset}
          logo={logo}
          writeContactMeta={this.writeContactMeta}
          mapIconSize={mapIconSize}
          mapIconDensity={mapIconDensity}
          listDensity={listDensity}
          contactMetaLoading={cm.loading}
        />
      )
    }
  }

  return compose(
    queryConnector(contactMetaQuery, null, 'contactMeta', true),
    queryConnector(customBrandingQuery, null, 'customBranding', true),
    mutationConnector(updateContactMetaMutation, 'updateContactMeta'),
    helper(),
  )(ContactMetaHOC)
}

export default contactMetaHOC
