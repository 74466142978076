import React, { Component } from 'react'

/**
 * Reads local storage for map settings
 *
 * @todo set defaults in localStorage when first logged in
 * @todo probably change this to read database
 */
const mapHOC = () => (WrappedComponent) => {
  class MapHOC extends Component {
    // Added eslint disable for state properties since they are used outside of this file
    /* eslint-disable react/no-unused-state */
    state = {
      satellite: localStorage.getItem('rft-map-satellite') === 'true', // a hack to switch it from a string to a boolean
      traffic: localStorage.getItem('rft-map-traffic') === 'true',
      animation: localStorage.getItem('rft-map-animation') === 'true',
      nightMode: localStorage.getItem('rft-map-nightMode') === 'true',
      showLandmarks: localStorage.getItem('rft-map-showLandmarks') === 'true', // show landmarks in device map
      clustering: localStorage.getItem('rft-map-clustering')
        ? localStorage.getItem('rft-map-clustering') === 'true'
        : true, // default clustering to True if localStorage not set
    }

    updateMapSettings = (type, isOn) => {
      localStorage.setItem(`rft-map-${type}`, isOn.toString())

      const object = {}
      object[type] = isOn
      this.setState(object)
    }

    render = () => (
      <WrappedComponent
        mapSettings={this.state}
        updateMapSettings={this.updateMapSettings}
        {... this.props}
      />
    )
  }

  return MapHOC
}

export default mapHOC
