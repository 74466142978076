import React, { Component } from 'react'
import { message } from 'antd'
import { withRouter } from 'react-router-dom'
import { compose } from 'react-apollo'
import moment from 'moment'
import consumerConnector from '@graphql/consumerConnector.js'
import PropTypes from 'prop-types'
import Analytics from '@analytics'
import { allEventsLocates, timelineTrips } from '@graphql/query'
import { requestClipUpload } from '@graphql/mutation'
import equal from 'deep-equal'

/**
 * @todo
 * [x] enable linting!
 * [x] condense loading variables if possible
 * [x] add proper loading
 * [x] add time between trips
 * [x] fix all day
 * [_] update last 30 days
 * [_] add hour events efficiently
 * [_] no need to filter and sort separately anymore?
 */

const tripHOC = () => (WrappedComponent) => {
  class TripHOC extends Component {
    static propTypes = {
      /** @apollo */
      apolloClient: PropTypes.object.isRequired,
      /** @parent */
      selectedDevice: PropTypes.object,
      wMatrix: PropTypes.func.isRequired,
      formatAddress: PropTypes.func.isRequired,
      minuteTZOffset: PropTypes.number.isRequired,
      selectedDeviceSocketEvent: PropTypes.object,
    }

    static defaultProps = {
      selectedDevice: null,
      selectedDeviceSocketEvent: null,
    }

    state = {
      date: moment().format('YYYY-MM-DD'),
      redTripsLoading: false,
      tripPathLoading: false,
      historyTrips: [],
      hoursOfOperation: {
        start: null,
        end: null,
      },
      ifCurrentTrip: false,
      availableEventFilters: [],
      selectedEventTypes: [],
      eventCount: [],
      selectedTrip: 1,
      selectedEventID: null,
      allDay: true,
    }

    /**
     * Only need to check for local storage once on mount
     */
    componentDidMount = () => {
      this.initLocalStorage()
    }

    componentDidUpdate = (prevProps) => {
      const {
        minuteTZOffset, selectedDevice, formatAddress, selectedDeviceSocketEvent,
      } = this.props
      const {
        hoursOfOperation, historyTrips, allDay, date, availableEventFilters, selectedEventTypes,
      } = this.state
      if (!allDay) return
      if (selectedDeviceSocketEvent
        && !equal(selectedDeviceSocketEvent, prevProps.selectedDeviceSocketEvent)) {
        /**
         * we only consider to update the timeline view if the socket event is for
         * the selected device also we check if the new event timestamp to
         * see if it is an old event
         */
        const socketEvent = selectedDeviceSocketEvent
        if (prevProps.selectedDeviceSocketEvent !== null
          && !moment(socketEvent.raw.location.datetime)
            .isSameOrAfter(prevProps.selectedDeviceSocketEvent.raw.location.datetime)
        ) {
          return
        }

        // Ignore the event happens on a different day than the selected date
        if (moment(socketEvent.raw.location.datetime).format('YYYY-MM-DD') !== date) return

        const historyTripsClone = JSON.parse(JSON.stringify(historyTrips))
        if (historyTripsClone?.length < 1) return
        const updateDT = moment.utc(socketEvent.raw.location.datetime, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').add(minuteTZOffset, 'minutes')
        // format new event
        const formattedEvent = {
          actualOdometer: null,
          address: formatAddress(socketEvent.address),
          alias: selectedDevice.alias,
          date: updateDT.format('YYYY-MM-DD'),
          datetime: updateDT.format('YYYY/MM/DD HH:mm:ss'),
          details: {
            speed: null,
            roadspeed: null,
            mosl: null,
            triggeredlandmark: null,
            endtime: null,
            dtc: null,
            duration: null,
          },
          deviceId: selectedDevice.id,
          event: socketEvent.raw.event.type,
          heading: socketEvent.raw.heading.degree ? socketEvent.raw.heading.degree : 0,
          id: historyTripsClone[0].events.length + 1,
          landmarks: socketEvent.landmarks,
          lat: socketEvent.raw.location.latitude,
          lng: socketEvent.raw.location.longitude,
          speed: socketEvent.raw.speed.mph ? socketEvent.raw.speed.mph : 0,
          time: updateDT.format('hh:mm:ss a'),
          tripId: null,
          videoClip: {
            id: null,
            replayURL: null,
            favorite: null,
            viewed: null,
            status: null,
          },
          virtualOdometer: socketEvent.raw.odometer.virtual.miles
            ? socketEvent.raw.odometer.virtual.miles
            : null,
        }

        // Conditionally push the new event type to the filter
        if (availableEventFilters.indexOf(formattedEvent.event) < 0) {
          const availableEventFiltersClone = JSON.parse(JSON.stringify(availableEventFilters))
          availableEventFiltersClone.push(formattedEvent.event)
          const selectedEventTypesClone = JSON.parse(JSON.stringify(selectedEventTypes))
          selectedEventTypesClone.push(formattedEvent.event)
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ availableEventFilters: availableEventFiltersClone })
          this.applyFilters(selectedEventTypesClone)
        }

        historyTripsClone[0].events.push(formattedEvent)
        const updatedHoursOfOperation = {
          start: hoursOfOperation.start,
          end: formattedEvent.time,
        }
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          historyTrips: historyTripsClone,
          hoursOfOperation: updatedHoursOfOperation,
        })
      }
    }

    /**
     * @private
     * @description Checks if local storage has been set for sort order, show locates,
     * and show events. Sets them to default if not.
     */
    initLocalStorage = () => {
      if (localStorage.getItem('rft-sortOrder') === null) {
        localStorage.setItem('rft-sortOrder', 'descending')
      }
      if (localStorage.getItem('rft-showLocates') === null) {
        localStorage.setItem('rft-showLocates', true)
      }
      if (localStorage.getItem('rft-showEvents') === null) {
        localStorage.setItem('rft-showEvents', true)
      }
    }

    // pass in format 'YYYY-MM-DD'
    changeTripDate = (date) => {
      this.setState({ date })
      /** @analytics Record timeline date change */
      Analytics.record({
        feature: 'timeline',
        page: 'map',
        event: 'changed_date',
      })
    }

    toggleLocates = (showLocates) => {
      localStorage.setItem('rft-showLocates', showLocates)
      this.setState({ selectedEventID: null })
    }

    toggleEvents = (showEvents) => {
      localStorage.setItem('rft-showEvents', showEvents)
      this.setState({ selectedEventID: null })
    }

    /**
     * Updates the filter state and local storage
     * @param {[String]} filtersArray Array of filter/event names
     * (database format. i.e: "hard_brake")
     */
    applyFilters = (filtersArray) => {
      const { availableEventFilters } = this.state

      const localEvents = JSON.parse(localStorage.getItem('rft-timelineEventFilters')) || {}
      const updatedFilterList = localEvents // object
      for (let i = 0; i < availableEventFilters.length; i += 1) {
        /**
         * if filters array contains this, then it is true, else it is false..
         * (only adjusting the available event types and leave remaining eventTypes
         * as is in localStorage)
         */
        if (filtersArray.includes(availableEventFilters[i])) {
          updatedFilterList[availableEventFilters[i]] = true
        } else {
          updatedFilterList[availableEventFilters[i]] = false
        }
      }
      localStorage.setItem('rft-timelineEventFilters', JSON.stringify(updatedFilterList))
      this.setState({ selectedEventTypes: filtersArray })
    }

    /**
     * @private
     * @description - Adds event types to filter options and sets default selected values
     * @NOTE Ideally, we would pull the full event types list from db, but we
     * apparently use some that are marked as inactive in the gps.dbo.v3_EventType table
     */
    pushFilterOptions = (historyTrip) => {
      const { availableEventFilters } = this.state
      // let availableEventFilters = availableEventFilters
      for (let x = 0; x < historyTrip.events.length; x += 1) {
        if ((availableEventFilters.indexOf(historyTrip.events[x].event) < 0)
          && historyTrip.events[x].event !== 'locate') {
          availableEventFilters.push(historyTrip.events[x].event)
        }
      }
      this.setState({ availableEventFilters })
      // select Default filters based on localStorage
      const localEventTypes = JSON.parse(localStorage.getItem('rft-timelineEventFilters'))
      /**
       * If localStorage hasn't been set, set all available filters to true
       */
      if (localEventTypes === null) {
        this.setState({ selectedEventTypes: availableEventFilters })
        const newLocalStorageEvents = {}
        for (let i = 0; i < availableEventFilters.length; i += 1) {
          newLocalStorageEvents[availableEventFilters[i]] = true
        }
        localStorage.setItem('rft-timelineEventFilters', JSON.stringify(newLocalStorageEvents))
      } else {
        const newLocalStorageEvents = JSON.parse(JSON.stringify(localEventTypes))
        let updateLocalStorageFlag = false
        const selectedEventTypes = []
        for (let i = 0; i < availableEventFilters.length; i += 1) {
          // if key does not exist in local storage, add it and default to true
          if (!Object.keys(localEventTypes).includes(availableEventFilters[i])) {
            newLocalStorageEvents[availableEventFilters[i]] = true
            selectedEventTypes.push(availableEventFilters[i])
            // if at least one new eventType was added, update local storage
            updateLocalStorageFlag = true
          } else if (localEventTypes[availableEventFilters[i]] === true) {
            selectedEventTypes.push(availableEventFilters[i])
          }
        }
        if (updateLocalStorageFlag) {
          localStorage.setItem('rft-timelineEventFilters', JSON.stringify(newLocalStorageEvents))
        }
        this.setState({ selectedEventTypes })
      }
    }

    stringifyForDatabase = (value) => {
      if (!value) return null
      try {
        const stringifiedValue = JSON.stringify(value)
        return stringifiedValue
      } catch {
        // if there was an issue stringifying, simply return null value
        return null
      }
    }

    /**
     * Requests camera clip for a given event
     * @param {Number} deviceId device id
     * @param {String} datetime datetime of event
     * @param {String} event event type
     * @param {Float} lat latitude
     * @param {Float} lng longitude
     */
    requestCameraClipUpload = async (
      deviceId, datetime, event, lat, lng, address, landmarks, drivers, eventDetails,
    ) => {
      const { apolloClient, wMatrix } = this.props
      const addressString = this.stringifyForDatabase(address)
      const landmarksString = this.stringifyForDatabase(landmarks)
      const driversString = this.stringifyForDatabase(drivers)
      const eventDetailsString = this.stringifyForDatabase(eventDetails)
      const messageKey = Date.now().toString()
      try {
        message.info({ content: wMatrix('clipUploadRequestedWarning'), messageKey, duration: 15 })
        const uploadReqRes = await apolloClient.mutate({
          mutation: requestClipUpload,
          variables: {
            deviceId,
            datetime,
            event,
            lat,
            lng,
            address: addressString,
            landmarks: landmarksString,
            drivers: driversString,
            eventDetails: eventDetailsString,
          },
        })
        if (uploadReqRes.data && uploadReqRes.data.uploadClip
          && uploadReqRes.data.uploadClip.code === 1000) {
          message.success({ content: wMatrix('clipUploadRequested'), messageKey, duration: 2 })
        } else if (uploadReqRes.data && uploadReqRes.data.uploadClip && uploadReqRes.data.uploadClip.code === 4004) {
          message.error({ content: wMatrix('errorClipUploadDataLimit'), messageKey, duration: 2 })
        }
      } catch (err) {
        message.error({ content: wMatrix('errorRequestingClipUpload'), messageKey, duration: 2 })
      }
    }

    /**
     * @public
     * @description Sets sort order and reorders historyTrips accordingly
     */
    setSortOrder = (value) => {
      const sortOrder = localStorage.getItem('rft-sortOrder')
      if (sortOrder !== value) {
        localStorage.setItem('rft-sortOrder', value)
      }
    }

    /**
     * This reverses the sorting when necessary.
     * @NOTE Trips are returned from gql sorted by ascending initially
     * @param {String} sortValue ascending or descending
     */
    returnSortedTrips = (sortValue) => {
      const { historyTrips } = this.state
      // clone history trips (ascending)
      if (historyTrips && historyTrips.length > 0) {
        let trips = JSON.parse(JSON.stringify(historyTrips))
        // a - b
        if (sortValue === 'ascending') {
          trips = trips.sort((a, b) => a.tripOrder - b.tripOrder) // sort trips using trip Order
          // events are already sorted in ascending
        } else {
          // if descending
          trips = trips.sort((a, b) => b.tripOrder - a.tripOrder)
          for (let i = 0; i < trips.length; i += 1) {
            trips[i].events = trips[i].events.reverse()
          }
        }
        // update time between trips
        trips = this.getTimeBetweenTrips(trips, sortValue)
        return trips
      }
      return []
    }

    /**
     * @private
     * @description Add time to next trip. (called in sorting function)
     */
    getTimeBetweenTrips = (trips, sortOrder) => {
      const tripsClone = JSON.parse(JSON.stringify(trips))
      // Always check time between current trip and next trip (no matter desc or asc)
      for (let i = 0; i < tripsClone.length; i += 1) {
        /** @note not sure about this block */
        if (tripsClone[i].ynCurrentTrip) {
          this.setState({ ifCurrentTrip: true })
        }
        // format recieved `hh:mm a`
        if (i === tripsClone.length - 1) {
          tripsClone[i].timeToNextTrip = 0
        } else if (sortOrder === 'descending') {
          tripsClone[i].timeToNextTrip = moment(tripsClone[i].startTime, 'hh:mm a').diff(moment(tripsClone[i + 1].endTime, 'hh:mm a'), 'minutes')
        } else {
          tripsClone[i].timeToNextTrip = moment(tripsClone[i + 1].startTime, 'hh:mm a').diff(moment(tripsClone[i].endTime, 'hh:mm a'), 'minutes')
        }
      }
      return tripsClone
    }

    /**
     * @private
     * @param {Array} trips
     * @param {Boolean} allEvents
     * @description Sets state to have the chosen day/time span's HOO.
     * @Note Assumes the trips are in ascending order. This should be called on the initial return
     * of a set of trips
     */
    setHoursOfOperation = (trips) => {
      /* the trips are order by dtStartTime desc from stored procedure */
      let hoursOfOperation = {
        start: '',
        end: '',
      }
      // If start and end span greater than a day, display date instead of time
      if (trips.length > 0 && trips[0].startDate !== trips[trips.length - 1].endDate) {
        hoursOfOperation = {
          start: trips[0].startDate,
          end: trips[trips.length - 1].endDate,
        }
      } else if (trips.length > 0) {
        // "2020-03-31T13:40:27-05:00"
        hoursOfOperation = {
          start: trips[0].startTime,
          end: trips[trips.length - 1].endTime,
        }
      }
      this.setState({ hoursOfOperation })
    }

    /**
     * @public
     * @description Makes GQL call to return the given dates trips or the
     * last thirty days' locates/events
     * @param {number} numVehicleDeviceID device id
     * @param {string} date date value (not used for ATs)
     * @param {boolean} allEvents all Events and Locates (single trip for everything)
     */
    getRedshiftTrips = async (numVehicleDeviceID, date, allEvents) => {
      const { apolloClient } = this.props
      // Stop any client processes (concurrent gql calls)
      apolloClient.stop()
      this.setState({
        redTripsLoading: true,
        historyTrips: [],
        hoursOfOperation: {
          start: null,
          end: null,
        },
      })
      try {
        let redTrips = null
        if (allEvents) {
          // If AT, set date to today, else, use date
          redTrips = await apolloClient.query({
            query: allEventsLocates,
            variables: {
              deviceId: numVehicleDeviceID,
              endDateTime: date ? moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD 23:59:59') : moment().format('YYYY-MM-DD 23:59:59'),
            },
            fetchPolicy: 'network-only',
          })
        } else {
          // If normal device
          redTrips = await apolloClient.query({
            query: timelineTrips,
            variables: {
              reportId: null,
              deviceIds: [numVehicleDeviceID],
              startDateTime: moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD 00:00:00'),
              endDateTime: moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD 23:59:59'),
              withLocates: 1,
            },
            fetchPolicy: 'network-only',
          })
          // call all day trips as well
        }
        if (redTrips.data) {
          let trips = null
          // if normal device, else if AT, else
          if (redTrips.data && redTrips.data.tripsReport && redTrips.data.tripsReport.rows
            && redTrips.data.tripsReport.rows.length > 0
            && redTrips.data.tripsReport.rows[0].trips) {
            trips = JSON.parse(JSON.stringify(redTrips.data.tripsReport.rows[0].trips))
          } else if (redTrips.data && redTrips.data.allEventsLocates
            && redTrips.data.allEventsLocates.length > 0
            && redTrips.data.allEventsLocates[0].trips) {
            trips = JSON.parse(JSON.stringify(redTrips.data.allEventsLocates[0].trips))
          } else trips = []

          /** Create necessary event filters */
          for (let i = 0; i < trips.length; i += 1) {
            /** @TODO need to update filteroptions to show idle instead of begin and end */
            this.pushFilterOptions(trips[i])
          }
          /* set hours of operation */
          this.setHoursOfOperation(trips)
          /** Store the original in state */
          this.setState({ historyTrips: trips })
          /** automatically set selected trip as first trip after getting history trips */
          if (trips.length > 0) {
            const sortOrder = localStorage.getItem('rft-sortOrder')
            if (sortOrder === 'ascending') {
              this.setSelectedTrip(trips[0].tripOrder)
            } else this.setSelectedTrip(trips[trips.length - 1].tripOrder)
          }
        } else {
          // if no data recieved, set trips as empty array
          this.setState({ historyTrips: [] })
        }
      } catch (err) {
        throw err
      } finally {
        this.setState({
          redTripsLoading: false,
        })
      }
    }

    /**
     * @public
     * @description Sets selected trip and resets selected event to null
     */
    setSelectedTrip = (tripOrder) => {
      // reset selected event each time a new trip is selected
      this.setState({ selectedEventID: null })
      this.setState({ selectedTrip: tripOrder })
    }

    /**
     * @public
     * @description Selects event as well as trip id if necessary.
     */
    selectEvent = (eventID, tripID) => {
      const { selectedTrip } = this.state
      // if you pass trip ID and it is not currently selected, select it
      if (tripID && tripID !== selectedTrip) {
        this.setState({ selectedTrip: tripID })
      }
      if (eventID) {
        this.setState({ selectedEventID: eventID })
      } else {
        this.setState({ selectedEventID: null })
      }
    }

    /**
     * @private
     * @description - filters events based on selected events state
     */
    filterEvents = (event) => {
      const { selectedEventTypes } = this.state
      // create copy
      let filterArray = []
      if (JSON.parse(localStorage.getItem('rft-showEvents')) === true) filterArray = [...selectedEventTypes]
      if (JSON.parse(localStorage.getItem('rft-showLocates')) === true) filterArray.push('locate')
      return filterArray.indexOf(event.event) >= 0
    }

    /** @TODO add hour events  and start and end of trip if there is an hour event between */
    addTripHourEvents = (events) => {
      const sortOrder = localStorage.getItem('rft-sortOrder')
      const eventsClone = []
      for (let event = 0; event < (events.length); event += 1) {
        // push event
        eventsClone.push(events[event])
        // time in form: 7:46:07 AM
        if (event + 1 !== events.length) {
          const currentTime = moment(events[event].time, 'h:mm:ss A')
          const nextTime = moment(events[event + 1].time, 'h:mm:ss A')
          if (currentTime.hours() !== nextTime.hours()) {
            // insert time event
            const hourItem = {
              type: 'hour',
              event: 'hour',
              id: `h-${sortOrder === 'descending' ? currentTime.hours() : nextTime.hours()}`,
              time: sortOrder === 'descending' ? currentTime.format('h:00 a') : nextTime.format('h:00 a'),
            }
            eventsClone.push(hourItem)
          }
        }
      }
      return eventsClone
    }

    /**
     * @private
     * @description Filters ALL trips.
     * and adds appropriate hour events?
     */
    returnFilteredTrips = (trips) => {
      const tripsClone = JSON.parse(JSON.stringify(trips))


      for (let i = 0; i < tripsClone.length; i += 1) {
        tripsClone[i].events = tripsClone[i].events.filter(this.filterEvents)
      }
      return tripsClone
    }

    /**
     * @private
     * @description Returns selected trip given the trips and the trip id
     */
    returnSelectedTrip = (trips, selectedTrip) => {
      for (let i = 0; i < trips.length; i += 1) {
        if (trips[i].id === selectedTrip) {
          return trips[i]
        }
      }
      return null
    }

    /**
     * @private
     * @description Returns events from all trips concatinated.
     * @param {[Object]} trips
     * @returns {Array} Array of events
     */
    returnAllEvents = (trips) => {
      const { selectedDevice } = this.props
      let allDayEvents = []
      for (let i = 0; i < trips.length; i += 1) {
        if (trips[i].events) {
          const eventsClone = JSON.parse(JSON.stringify(trips[i].events))
          allDayEvents = allDayEvents.concat(eventsClone)
        }
      }
      // If this is not an asset tracker, add hour events
      if (selectedDevice && selectedDevice.timelineRange !== 'thirty-history') {
        allDayEvents = this.addTripHourEvents(allDayEvents)
      }
      return allDayEvents
    }

    toggleAllDay = (allDay) => {
      this.setState({ allDay })
    }

    render = () => {
      const {
        selectedEventID, selectedEventTypes, selectedTrip, redTripsLoading,
        tripPathLoading, availableEventFilters,
        date, eventCount, hoursOfOperation, ifCurrentTrip, selectedFilters,
        allDay,
      } = this.state

      const sortOrder = localStorage.getItem('rft-sortOrder')
      const showLocates = JSON.parse(localStorage.getItem('rft-showLocates'))
      const showEvents = JSON.parse(localStorage.getItem('rft-showEvents'))
      // sorted Trips
      const sortedTrips = this.returnSortedTrips(sortOrder)
      // filteredTripHistory
      const filteredTrips = this.returnFilteredTrips(sortedTrips)
      // filteredTripEvents
      const selectedFilteredTrip = this.returnSelectedTrip(filteredTrips, selectedTrip)
        || { events: [] }
      // all events
      /** @note this works fine, but this is not what's used for the drawer,
       * it is used for the map only
       * so the drawer doesnt have the hour events
       * @todo: update the addHourEvents to handle hours between trips or update
       * gql to create hour events?
      */
      const allEvents = this.returnAllEvents(filteredTrips)

      return (
        <WrappedComponent
          // allDayEvents={allDayEvents}
          allDayEvents={allEvents}
          applyFilters={this.applyFilters}
          availableEventFilters={availableEventFilters}
          changeTripDate={this.changeTripDate}
          date={date}
          eventCount={eventCount}
          filteredTripEvents={selectedFilteredTrip.events}
          getTrips={this.getRedshiftTrips}
          historyTrips={filteredTrips}
          hoursOfOperation={hoursOfOperation}
          ifCurrentTrip={ifCurrentTrip}
          selectEvent={this.selectEvent}
          selectedEventID={selectedEventID}
          selectedEventTypes={selectedEventTypes}
          selectedFilters={selectedFilters}
          selectedTrip={selectedTrip}
          setSelectedTrip={this.setSelectedTrip}
          setSortOrder={this.setSortOrder}
          showEvents={showEvents}
          showLocates={showLocates}
          sortOrder={sortOrder}
          toggleEvents={this.toggleEvents}
          toggleLocates={this.toggleLocates}
          tripPathLoading={tripPathLoading}
          redTripsLoading={redTripsLoading}
          requestCameraClipUpload={this.requestCameraClipUpload}
          allDay={allDay}
          toggleAllDay={this.toggleAllDay}
          {...this.props}
        />
      )
    }
  }

  return compose(
    consumerConnector(),
    withRouter,
  )(TripHOC)
}

export default (tripHOC)
