import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Button, Checkbox, DatePicker, Form, Input, Select, Popconfirm,
} from 'antd'
import CustomIcon from '@atom/icon'
import ModalHeader from '@mol/modalHeader'

const styles = {
  leftItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 25px 10px 0',
    height: '40px',
    alignItems: 'center',
  },
  rightItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '0 0 10px 0',
    height: '40px',
    alignItems: 'center',
  },
  formItem: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: 0,
  },
}

const dateFormat = 'MMM DD, YYYY h:mm a' // 'YYYY-MM-DD hh:mm a'
const dbDateFormat = 'YYYY-MM-DD HH:mm'

class BasicInfo extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    job: PropTypes.object,
    devices: PropTypes.array.isRequired,
    form: PropTypes.object.isRequired,
    navDashboard: PropTypes.func.isRequired,
    openMessenger: PropTypes.func,
    createJob: PropTypes.func.isRequired,
    updateJob: PropTypes.func.isRequired,
    deleteJob: PropTypes.func.isRequired,
    createJobLoading: PropTypes.bool,
    deleteJobLoading: PropTypes.bool,
    updateJobLoading: PropTypes.bool,
  }

  static defaultProps = {
    job: null,
    openMessenger: null,
    createJobLoading: false,
    deleteJobLoading: false,
    updateJobLoading: false,
  }

  state = {
    colorState: 'textGray',
  }

  componentDidUpdate(prevProps) {
    const { job, form } = this.props
    const prevJob = JSON.stringify(prevProps.job)
    const currJob = JSON.stringify(job)
    if (prevJob !== currJob) {
      form.resetFields()
    }
  }

  handleSave = async () => {
    const {
      form, job, updateJob, createJob,
    } = this.props
    const formFields = form.getFieldsValue()
    if (job && job.id) {
      const newData = { ...job, ...formFields }
      await updateJob(newData)
    } else {
      await createJob(formFields)
    }
  }

  handleDisable = () => {
    const { form, job } = this.props
    const formFields = form.getFieldsValue()
    const dueDate = moment(formFields.dueDate).format(dbDateFormat)

    if (job && job.id) {
      if (formFields.alias !== job.alias) return false
      if (formFields.notes !== job.notes) return false
      if (formFields.deviceId !== job.deviceId) return false
      if (formFields.dueDate !== null && dueDate !== job.dueDate) return false
      if (formFields.completed) return false

      return true
    }

    return false
  }

  handleDelete = async () => {
    const {
      form, navDashboard, deleteJob, job,
    } = this.props
    form.resetFields()
    await deleteJob(job.id)
    navDashboard()
  }

  handleCancel = () => {
    const { form, navDashboard } = this.props
    form.resetFields()
    navDashboard()
  }

  renderDeviceSelect = () => {
    const { devices, form, job } = this.props
    const options = []

    for (const device of devices) {
      options.push(
        <Select.Option
          key={device.id}
          value={device.id}
          disabled={!!device.assignedJob}
        >
          {device.alias}
        </Select.Option>,
      )
    }

    return (
      <Form.Item style={styles.formItem}>
        {form.getFieldDecorator('deviceId', {
          initialValue: job && job.deviceId ? job.deviceId : '',
        })(
          <Select
            showSearch
            style={{ width: '250px' }}
            disabled={job && job.status === 'completed'}
            filterOption={(input, option) => option.props.children.toLowerCase()
              .indexOf(input.toLowerCase()) >= 0}
          >
            {options}
          </Select>,
        )}
      </Form.Item>
    )
  }

  /**
   * Currently only garmin devices have messenger feature. This returns the messenger button if the
   * device has a garmin attached
   * @returns Element
   */
  messengerButton = () => {
    const {
      openMessenger, devices, form, job,
    } = this.props
    const { colorState } = this.state
    // get selected device id
    const selectedDeviceId = form.getFieldValue('deviceId')
    // get selected device info
    const copyDevices = JSON.parse(JSON.stringify(devices))
    const selectedDevice = copyDevices.filter(d => d.id === selectedDeviceId)[0]

    // render button. enable if garmin. disable if not.
    if (
      !selectedDevice
      || !selectedDevice.garmin
      || !job
      || !job.id
    ) {
      return null
    }
    return (
      <div style={{ marginLeft: 4, marginRight: 4 }}>
        <Button
          type="default"
          onClick={() => { openMessenger(selectedDevice) }}
          onMouseEnter={() => { this.setState({ colorState: 'highlightBlue' }) }}
          onMouseLeave={() => { this.setState({ colorState: 'textGray' }) }}
          style={{
            width: 36,
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0px 5px',
          }}
        >
          <CustomIcon
            type="chat-bubble"
            width={16}
            color={colorState}
          />
        </Button>
      </div>
    )
  }

  renderCompletion = () => {
    const { job, form } = this.props
    if (job) {
      if (job.status === 'completed') {
        return (
          <div style={styles.rightItem}>
            {moment(job.completionDate).format(dateFormat)}
          </div>
        )
      }
      return (
        <div style={styles.rightItem}>
          <Form.Item style={{ marginBottom: 0 }}>
            {form.getFieldDecorator('completed', {
              initialValue: false,
              valuePropName: 'checked',
            })(
              <Checkbox />,
            )}
          </Form.Item>
        </div>
      )
    }
    return null
  }

  render() {
    const {
      job, form, wMatrix, createJobLoading, deleteJobLoading, updateJobLoading,
    } = this.props

    return (
      <div>
        <ModalHeader headerName={wMatrix('basicInfo')} description="" />
        <Form
          name="jobForm"
          style={{ width: '100%' }}
        >
          <div style={{ display: 'flex', marginLeft: '50px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={styles.leftItem}>
                <h3 style={{ margin: 0 }}>{`${wMatrix('jobName')}: `}</h3>
              </div>
              <div style={styles.leftItem}>
                <h3 style={{ margin: 0 }}>{`${wMatrix('assignedDevice')}: `}</h3>
              </div>
              {job && job.id
                ? (
                  <div style={styles.leftItem}>
                    <h3 style={{ margin: 0 }}>{`${wMatrix('dateCreated')}: `}</h3>
                  </div>
                ) : null
              }
              <div style={styles.leftItem}>
                <h3 style={{ margin: 0 }}>{`${wMatrix('dueDate')}: `}</h3>
              </div>
              {job && job.id
                ? (
                  <div style={styles.leftItem}>
                    <h3 style={{ margin: 0 }}>{`${wMatrix('Completed')}: `}</h3>
                  </div>
                ) : null
              }
              <div style={styles.leftItem}>
                <h3 style={{ margin: 0 }}>{`${wMatrix('Notes')}: `}</h3>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={styles.rightItem}>
                <Form.Item style={styles.formItem}>
                  {form.getFieldDecorator('alias', {
                    initialValue: job && job.alias ? job.alias : '',
                  })(
                    <Input style={{ maxWidth: '250px' }} />,
                  )}
                </Form.Item>
              </div>
              <div style={styles.rightItem}>
                {this.renderDeviceSelect()}
                {this.messengerButton()}
              </div>
              {job && job.createdDate
                ? (
                  <div style={styles.rightItem}>
                    {moment(job.createdDate).format(dateFormat)}
                  </div>
                ) : null
              }
              <div style={styles.rightItem}>
                <Form.Item style={{ marginBottom: 0 }}>
                  {form.getFieldDecorator('dueDate', {
                    initialValue: job && job.dueDate
                      ? moment(job.dueDate)
                      : null,
                  })(
                    <DatePicker
                      allowClear
                      style={{ width: '100%', marginBottom: 0 }}
                      size="default"
                      format={dateFormat}
                      showTime={{ format: 'HH:mm', minuteStep: 15, use12Hours: true }}
                    />,
                  )}
                </Form.Item>
              </div>
              {this.renderCompletion()}
              <div style={{ ...styles.rightItem, height: '120px' }}>
                <Form.Item style={styles.formItem}>
                  {form.getFieldDecorator('notes', {
                    initialValue: job && job.notes ? job.notes : '',
                  })(
                    <Input.TextArea
                      style={{ maxWidth: '250px' }}
                      rows={5}
                    />,
                  )}
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
        <div style={{ display: 'flex' }}>
          <div>
            {job && job.id ? (
              <Popconfirm
                title={wMatrix('deleteJobConfirm')}
                onConfirm={this.handleDelete}
                okText={wMatrix('Yes')}
                cancelText={wMatrix('No')}
              >
                <Button
                  // onClick={this.handleDelete}
                  type="danger"
                  style={{ margin: '0 10px 0 0' }}
                  loading={deleteJobLoading}
                >
                  {wMatrix('deleteJob')}
                </Button>
              </Popconfirm>
            ) : null
          }
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button
              onClick={this.handleCancel}
              type="info"
              style={{ margin: '0 10px 0 0' }}
            >
              {wMatrix('Cancel')}
            </Button>
            <Button
              onClick={this.handleSave}
              type="primary"
              disabled={this.handleDisable()}
              loading={createJobLoading || updateJobLoading}
            >
              {job && job.id ? wMatrix('update') : wMatrix('Save')}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default Form.create({ name: 'jobForm' })(BasicInfo)
