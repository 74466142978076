/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import darkLogo from '@assets/img/logo_white.svg'

const styles = {
  container: {
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: 120,
  },
  image: {
    height: '100%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    objectFit: 'scale-down',
  },
}

export default class Logo extends React.PureComponent {
  static propTypes = {
    onClick: PropTypes.func,
    logo: PropTypes.object,
  }

  static defaultProps = {
    onClick: null,
    logo: {
      src: null,
      loading: true,
    },
  }

  render() {
    const { onClick, logo } = this.props

    return (
      <div style={styles.container} onClick={onClick}>
        { logo.loading ? null : <img style={styles.image} src={logo.src || darkLogo} alt="logo" />}
      </div>
    )
  }
}
