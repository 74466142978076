import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Tabs } from 'antd'
import DeviceMgrPanel from '@mol/modal/deviceMgrPanel'
import IconMgrPanel from '@mol/modal/iconMgrPanel'
import AdvancedMgrPanel from '@mol/modal/advancedMgrPanel'
import DeviceMgrHOC from '@hoc/deviceMgr.hoc'

class DeviceMgrModal extends Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    selectedDevice: PropTypes.object,
    modalUI: PropTypes.object,
    getMakeID: PropTypes.func.isRequired,
    getModelID: PropTypes.func.isRequired,
    vehicleTypeData: PropTypes.object,
    vehicleMakes: PropTypes.array,
    getModelsFromMakeID: PropTypes.func.isRequired,
    updateVehicleInfo: PropTypes.func.isRequired,
    assignStopLength: PropTypes.func.isRequired,
    assignSpeedThreshold: PropTypes.func.isRequired,
    assignFobStatus: PropTypes.func.isRequired,
    assignTimelineRange: PropTypes.func.isRequired,
    refetchDevices: PropTypes.func.isRequired,
    checkIfVinExists: PropTypes.func.isRequired,
    showAlert: PropTypes.object,
    alertOnClose: PropTypes.func,
    deviceMgrUpdating: PropTypes.bool,
    /** used in @iconMgr */
    updateIcon: PropTypes.func.isRequired,
    iconTypes: PropTypes.array,
    iconColors: PropTypes.array,
    /** helper */
    getPermission: PropTypes.func.isRequired,
  }

  static defaultProps = {
    modalUI: null,
    selectedDevice: null,
    vehicleTypeData: null,
    vehicleMakes: null,
    showAlert: null,
    alertOnClose: null,
    deviceMgrUpdating: false,
    iconTypes: [],
    iconColors: [],
  }

  /**
   * @todo
   * - update modal Title (alias) when alias is updated
   */
  render() {
    const { TabPane } = Tabs
    const {
      wMatrix, modalUI, closeModal, selectedDevice, assignStopLength, assignSpeedThreshold,
      vehicleTypeData, getMakeID, getModelID, getModelsFromMakeID, updateVehicleInfo,
      showAlert, alertOnClose, deviceMgrUpdating, iconTypes, iconColors, updateIcon,
      vehicleMakes, refetchDevices, getPermission, assignFobStatus, assignTimelineRange,
      checkIfVinExists,
    } = this.props

    if (selectedDevice) {
      return (
        <Modal
          visible={modalUI.ifShowModal}
        // onOk={this.props.saveAdmin}
          onCancel={closeModal}
          footer={null}
        // width={1024}
          width="70%"
          zIndex={10}
          maskClosable={false}
        >
          <h1>
            {`${wMatrix('Device Manager')} - ${selectedDevice.alias}`}
          </h1>
          <Tabs defaultActiveKey="1" onChange={this.switchAdminPanel}>
            <TabPane tab={wMatrix('Device')} key="1">
              <DeviceMgrPanel
                selectedDevice={selectedDevice}
                vehicleTypeData={vehicleTypeData}
                vehicleMakes={vehicleMakes}
                getMakeID={getMakeID}
                getModelID={getModelID}
                getModelsFromMakeID={getModelsFromMakeID}
                updateVehicleInfo={updateVehicleInfo}
                showAlert={showAlert}
                alertOnClose={alertOnClose}
                deviceMgrUpdating={deviceMgrUpdating}
                checkIfVinExists={checkIfVinExists}
              />
            </TabPane>
            <TabPane tab={wMatrix('Icon')} key="2">
              <IconMgrPanel
                wMatrix={wMatrix}
                selectedDevice={selectedDevice}
                iconTypes={iconTypes}
                iconColors={iconColors}
                updateIcon={updateIcon}
              />
            </TabPane>
            {
              getPermission('DeviceSettings', 'ynUpdate')
                ? (
                  <TabPane tab={wMatrix('Advanced')} key="3">
                    <AdvancedMgrPanel
                      wMatrix={wMatrix}
                      selectedDevice={selectedDevice}
                      assignStopLength={assignStopLength}
                      assignSpeedThreshold={assignSpeedThreshold}
                      refetchDevices={refetchDevices}
                      assignFobStatus={assignFobStatus}
                      assignTimelineRange={assignTimelineRange}
                    />
                  </TabPane>
                ) : null
            }

          </Tabs>
        </Modal>
      )
    }
    return null
  }
}

export default DeviceMgrHOC()(DeviceMgrModal)
