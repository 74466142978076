import React from 'react'
import PropTypes from 'prop-types'
import { Breadcrumb, Tabs } from 'antd'
import Card from '@atom/card'
import BasicInfo from '@mol/logistics/basicInfo'
import DestinationsInfo from '@mol/logistics/destinationsInfo'

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  contentContainer: {
    display: 'flex',
    alignSelf: 'stretch',
    flexGrow: 1,
    // height: '100%',
  },
  dot: {
    height: '5px',
    width: '5px',
    backgroundColor: 'grey',
    flexShrink: 0,
    borderRadius: '50%',
    margin: '0 5px 0 5px',
  },
  greyText: {
    color: 'grey',
    margin: '0 5px 0 5px',
  },
  leftPanel: {
    flex: 1,
    borderRight: 'solid 5px #E6E6E6',
    alignSelf: 'stretch',
    paddingRight: 10,
    marginRight: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  vehicleContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 60,
  },
  vehicleRow: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  rightPanel: {
    flex: 2,
    alignSelf: 'stretch',
  },
  modalRow: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '10px',
  },
  progress: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

export default class LogisticsJob extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    nav: PropTypes.string.isRequired,
    job: PropTypes.object,
    devices: PropTypes.array.isRequired,
    navDashboard: PropTypes.func.isRequired,
    openMessenger: PropTypes.func,
    createJob: PropTypes.func.isRequired,
    updateJob: PropTypes.func.isRequired,
    deleteJob: PropTypes.func.isRequired,
    createDestination: PropTypes.func.isRequired,
    updateDestination: PropTypes.func.isRequired,
    deleteDestination: PropTypes.func.isRequired,
    createTask: PropTypes.func.isRequired,
    updateTask: PropTypes.func.isRequired,
    deleteTask: PropTypes.func.isRequired,
    reorderDestinations: PropTypes.func.isRequired,
    landmarks: PropTypes.array,
    newDestinationLoading: PropTypes.bool,
    destinationsTableLoading: PropTypes.bool,
    createJobLoading: PropTypes.bool,
    deleteJobLoading: PropTypes.bool,
    updateJobLoading: PropTypes.bool,
  }

  static defaultProps = {
    job: null,
    openMessenger: null,
    landmarks: [],
    newDestinationLoading: false,
    destinationsTableLoading: false,
    createJobLoading: false,
    deleteJobLoading: false,
    updateJobLoading: false,
  }

  state = {
    tabKey: '1',
  }

  componentDidUpdate = (prevProps) => {
    const { nav, job } = this.props
    // If we switch to create from selected Job
    if (nav === 'details' && !job && prevProps.job) {
      this.changeTab('1')
    }
  }

  changeTab = (tabKey) => {
    this.setState({ tabKey })
  }

  renderCounts = (destinations) => {
    const { wMatrix } = this.props
    let shipments = 0
    let workOrders = 0
    for (const dest of destinations) {
      for (const task of dest.tasks) {
        if (task.type === 'package') shipments += 1
        if (task.type === 'work_order') workOrders += 1
      }
    }
    return (
      <>
        <h3 style={{ margin: '0 10px' }}>
          {`${destinations.length} ${wMatrix('Destinations')}`}
        </h3>
        <h3 style={{ margin: '0 10px' }}>
          {`${shipments} ${wMatrix('packages')}`}
        </h3>
        <h3 style={{ margin: '0 10px' }}>
          {`${workOrders} ${wMatrix('workOrders')}`}
        </h3>
      </>
    )
  }

  /**
   * Calls createJob from hoc and then switches to destinations tab
   * @param {Object} job Job object to be saved
   */
  createJobAndSwitchTab = async (job) => {
    const { createJob } = this.props
    await createJob(job)
    this.changeTab('2')
  }

  renderContent = () => {
    const {
      wMatrix, job, devices, openMessenger, navDashboard,
      updateJob, deleteJob,
      createDestination, updateDestination, deleteDestination,
      createTask, updateTask, deleteTask,
      reorderDestinations, landmarks,
      newDestinationLoading, destinationsTableLoading, createJobLoading, deleteJobLoading,
      updateJobLoading,
    } = this.props
    const { tabKey } = this.state
    return (
      // fullHeightTabs: custom class to make antd Tabs utilize full height of parent
      <div className="fullHeightTabs" style={{ width: '100%', height: '100%' }}>
        <Tabs defaultActiveKey={tabKey} activeKey={tabKey} onChange={this.changeTab} style={{ height: '100%' }}>
          <Tabs.TabPane tab={wMatrix('basicInformation')} key="1" style={{ height: '100%' }}>
            <BasicInfo
              job={job}
              wMatrix={wMatrix}
              devices={devices}
              navDashboard={navDashboard}
              openMessenger={openMessenger}
              createJob={this.createJobAndSwitchTab}
              updateJob={updateJob}
              deleteJob={deleteJob}
              createJobLoading={createJobLoading}
              deleteJobLoading={deleteJobLoading}
              updateJobLoading={updateJobLoading}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={wMatrix('Destinations')} key="2" style={{ height: '100%' }} disabled={!job}>
            <DestinationsInfo
              job={job}
              wMatrix={wMatrix}
              navDashboard={navDashboard}
              createDestination={createDestination}
              updateDestination={updateDestination}
              deleteDestination={deleteDestination}
              createTask={createTask}
              updateTask={updateTask}
              deleteTask={deleteTask}
              reorderDestinations={reorderDestinations}
              landmarks={landmarks}
              newDestinationLoading={newDestinationLoading}
              destinationsTableLoading={destinationsTableLoading}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    )
  }

  render() {
    const { wMatrix, job } = this.props
    const id = job && job.id ? `#${job.id}` : 'New Job'
    const dests = job && job.destinations ? job.destinations : []

    return (
      <Card>
        <div style={styles.wrapper}>
          <div style={{ display: 'flex' }}>
            <Breadcrumb style={{ alignSelf: 'flex-start' }}>
              <Breadcrumb.Item>{wMatrix('field-service')}</Breadcrumb.Item>
              <Breadcrumb.Item>{id}</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ display: 'flex', marginLeft: 'auto' }}>
              {this.renderCounts(dests)}
            </div>
          </div>
          <div style={styles.contentContainer}>
            {this.renderContent()}
          </div>
        </div>
      </Card>
    )
  }
}
