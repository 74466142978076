import React, { Component } from 'react'
import equal from 'deep-equal'
import NotificationHeader from '@atom/notificationHeader'
import NotificationDeviceTable from '@mol/notificationSettings/notificationTable'
import NotificationEditPanel from '@mol/notificationSettings/notificationEditPanel'
import MilesOverDropDown from '@atom/milesOverDropDown'
import GlobalAlertSwitch from '@mol/notificationSettings/globalAlertSwitch'
import PropTypes from 'prop-types'
// hold Title/description, Notif Freq, SelectionTable
// should hold the steps?....

export default class NotificationRightContainer extends Component {
  static propTypes = {
    alertSettings: PropTypes.object,
    checkedDevices: PropTypes.array,
    notifications: PropTypes.object,
    currentSelection: PropTypes.string.isRequired,
    milesOverValue: PropTypes.number,
    handleCancelClick: PropTypes.func.isRequired,
    eventTypeIds: PropTypes.array,
    updateGeneralAlertSettings: PropTypes.func.isRequired,
    handleSaveClick: PropTypes.func.isRequired,
    getPermission: PropTypes.func.isRequired,
    wMatrix: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    alertSettingsData: PropTypes.object,
    setIfShowAlert: PropTypes.func,
    landmarks: PropTypes.array,
    numMOSL: PropTypes.number.isRequired,
  }

  static defaultProps = {
    alertSettings: null,
    checkedDevices: [],
    notifications: null,
    editMode: false,
    alertSettingsData: {},
    setIfShowAlert: null,
    milesOverValue: 20,
    eventTypeIds: [],
    landmarks: [],
  }

  constructor(props) {
    super(props)
    this.childGeneralTable = React.createRef()
  }

  state = {
    generalSetting: false,
  }

  componentDidUpdate = (prevProps) => {
    if (!equal(prevProps, this.props)) {
      this.setIfGeneral()
    }
  }

  setIfGeneral = () => {
    const { checkedDevices } = this.props
    if (checkedDevices.length === 0) {
      this.setState({ generalSetting: true })// general = true
    } else {
      this.setState({ generalSetting: false })
    }
  }

  returnWindow = () => {
    const {
      notifications, currentSelection, alertSettings, checkedDevices,
      milesOverValue, handleCancelClick, updateGeneralAlertSettings, handleSaveClick,
      getPermission, wMatrix, editMode, alertSettingsData, setIfShowAlert, landmarks, numMOSL,
    } = this.props
    const { generalSetting } = this.state
    const selectedTypeIds = notifications[currentSelection].eventTypeIds
    return (
      <div style={{ width: 740, marginLeft: 28 }}>
        <NotificationHeader {...this.props} />
        <MilesOverDropDown
          eventTypeIds={selectedTypeIds}
          milesOverValue={!alertSettings[30] || alertSettings[30][0].numMOSL === null
            ? undefined
            : alertSettings[30][0].numMOSL}
          {...this.props}
        />
        {
          (selectedTypeIds.includes(52))
            ? (
              <GlobalAlertSwitch
                {...this.props}
              />
            )
            : (
              <>
                <NotificationDeviceTable
                  ref={this.childGeneralTable}
                  generalSetting={generalSetting}
                  eventTypeId={selectedTypeIds[0]}
                  eventTypeTableIds={selectedTypeIds}
                  {...this.props}
                />
                <NotificationEditPanel
                  generalSetting={generalSetting}
                  eventTypeIds={selectedTypeIds}
                  checkedDevices={checkedDevices}
                  milesOverValue={milesOverValue}
                  handleCancelClick={handleCancelClick}
                  currentSelection={currentSelection}
                  notifications={notifications}
                  updateGeneralAlertSettings={updateGeneralAlertSettings}
                  handleSaveClick={handleSaveClick}
                  alertSettings={alertSettings}
                  getPermission={getPermission}
                  wMatrix={wMatrix}
                  editMode={editMode}
                  alertSettingsData={alertSettingsData}
                  setIfShowAlert={setIfShowAlert}
                  landmarks={landmarks}
                  numMOSL={numMOSL}
                  // {...this.props}
                />
              </>
            )
        }
      </div>
    )
  }

  render() {
    return (
      this.returnWindow()
    )
  }
}
