import React, { Component } from 'react';
import { Query } from "react-apollo";

const queryConnector = (queryGQL, variables, name, refetchShowLoading) => WrappedComponent => {
  class QueryConnector extends Component {

    state = {
      params: variables
    }

    setVariables = (variablesVal) => {
      this.setState({params: variablesVal})
    }

    render = () => {
      let setVariables = this.setVariables

      return (
        <Query query={queryGQL} variables={this.state.params} fetchPolicy='network-only' notifyOnNetworkStatusChange={refetchShowLoading ? refetchShowLoading : false}>
          {({ loading, error, data, refetch }) => {
            const dynamicProps ={
              [name]: {
                loading,
                error,
                data,
                refetch,
                setVariables,
                params: this.state.params,
                // add update func
              }
            }
            return (
              <WrappedComponent
                runQuery={queryGQL}
                loading={loading}
                error={error}
                data={data}
                refetch={refetch}
                {...this.props}
                {...dynamicProps}
              />
            )
          }}
        </Query>
      )
    }
  }
  return QueryConnector
}
export default queryConnector


/*

function zoo(foo, bar)

zoo(1)

params: gql!, variables?, name? = devices

this.props.devices.refetch()

returns:
  if name
  this.prop[name].loading ... refetch this.props.name.data

*/