import React, { Component } from 'react'
import TimelineMap from '@mol/timelineMap'
import Map from '@mol/map'
import { compose } from 'react-apollo'
import TripHOC from '@hoc/trip.hoc'
import PropTypes from 'prop-types'
import equal from 'deep-equal'
import helper from '@helper'
import Analytics from '@analytics'
import TimelineDrawer from '../containers/rightDrawer/timelineDrawer'

class TimelineView extends Component {
  static propTypes = {
    // from @helper
    wMatrix: PropTypes.func.isRequired,
    eventToString: PropTypes.func.isRequired,
    // from devices.container
    createLandmark: PropTypes.func.isRequired,
    updateMapSettings: PropTypes.func,
    mapSettings: PropTypes.object,
    selectedDevice: PropTypes.object,
    landmarks: PropTypes.array,
    findLandmarkCenter: PropTypes.func.isRequired,
    // from trip.hoc
    getTrips: PropTypes.func.isRequired,
    changeTripDate: PropTypes.func.isRequired,
    filteredTripEvents: PropTypes.array.isRequired,
    setSelectedTrip: PropTypes.func.isRequired,
    selectEvent: PropTypes.func.isRequired,
    setSortOrder: PropTypes.func.isRequired,
    toggleEvents: PropTypes.func.isRequired,
    toggleLocates: PropTypes.func.isRequired,
    applyFilters: PropTypes.func.isRequired,
    requestCameraClipUpload: PropTypes.func.isRequired,
    historyTrips: PropTypes.array,
    selectedEventTypes: PropTypes.array.isRequired,
    availableEventFilters: PropTypes.array.isRequired,
    hoursOfOperation: PropTypes.object,
    date: PropTypes.string.isRequired,
    sortOrder: PropTypes.string.isRequired,
    selectedTrip: PropTypes.number,
    selectedEventID: PropTypes.number,
    redTripsLoading: PropTypes.bool,
    showLocates: PropTypes.bool.isRequired,
    showEvents: PropTypes.bool.isRequired,
    allDayEvents: PropTypes.array,
    selectModal: PropTypes.func.isRequired,
    allDay: PropTypes.bool.isRequired,
    toggleAllDay: PropTypes.func.isRequired,
    /** @Main */
    mainNav: PropTypes.string,
  }

  static defaultProps = {
    selectedDevice: null,
    mapSettings: {},
    updateMapSettings: () => {},
    historyTrips: null,
    hoursOfOperation: null,
    redTripsLoading: false,
    selectedTrip: 1,
    selectedEventID: null,
    allDayEvents: [],
    mainNav: 'map',
    landmarks: null,
  }

  componentDidMount() {
    const {
      selectedDevice, getTrips, date, allDay,
    } = this.props
    /** @NOTE good test date: '2019-08-23' */
    /** This if statement is here because this component soft mounts when the application starts,
     * then mounts correctly when the timeline tab is opened
    */
    if (selectedDevice) {
      if (selectedDevice.timelineRange === 'thirty-history') {
        getTrips(selectedDevice.id, null, true)
      } else {
        getTrips(selectedDevice.id, date, allDay)
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      selectedDevice, getTrips, date, allDay,
    } = this.props
    // Call GetTrips from trip.hoc if selectedDevice or selected date has changed
    // or if tab has changed to all day
    if (selectedDevice && selectedDevice.id) {
      if (!equal(prevProps.selectedDevice.id, selectedDevice.id)
      || (!equal(prevProps.date, date))) {
        if (selectedDevice.timelineRange === 'thirty-history') {
          getTrips(selectedDevice.id, null, true)
        } else {
          getTrips(selectedDevice.id, date, allDay)
        }
      }
      // If tab changed to all day
      if (prevProps.allDay !== allDay) {
        getTrips(selectedDevice.id, date, allDay)
      }
    }
  }

  renderTimelineMap = (initMap, fitBounds) => {
    const { allDay } = this.props
    const {
      selectedDevice, selectedTrip, selectedEventID,
      selectEvent, filteredTripEvents, allDayEvents, createLandmark,
      landmarks, mapSettings, findLandmarkCenter,
    } = this.props
    return (
      <TimelineMap
        initMap={initMap}
        fitBounds={fitBounds}
        selectedDevice={selectedDevice}
        selectedTrip={selectedTrip}
        events={allDay ? allDayEvents : filteredTripEvents}
        selectedEventID={selectedEventID}
        settings={mapSettings}
        selectEvent={selectEvent}
        createLandmark={createLandmark}
        landmarks={landmarks}
        findLandmarkCenter={findLandmarkCenter}
      />
    )
  }

  selectTab = (id, name) => {
    const { selectEvent, mainNav, toggleAllDay } = this.props
    if (id === '1') {
      toggleAllDay(true)
      selectEvent(null)
    } else toggleAllDay(false)
    if (name === 'Trips') {
      /** @analytics Record Navigation to Trips Tab */
      Analytics.record({
        feature: 'timeline',
        page: `${mainNav}`,
        event: 'select_trips_view',
      })
    }
  }

  render() {
    const {
      mainNav, wMatrix, eventToString,
      mapSettings, updateMapSettings,
      selectedDevice, historyTrips, getTrips, date,
      hoursOfOperation, changeTripDate, setSelectedTrip,
      selectedEventID, selectEvent, selectedTrip, availableEventFilters, applyFilters,
      showLocates, showEvents, sortOrder, toggleLocates, toggleEvents, selectedEventTypes,
      setSortOrder, redTripsLoading, selectModal, requestCameraClipUpload,
    } = this.props

    return (
      <div style={{ flexGrow: 1, position: 'relative' }}>
        <div style={{ display: 'flex', height: '100%', background: 'none' }}>
          <Map
            settings={mapSettings}
            updateSettings={updateMapSettings}
            renderMap={this.renderTimelineMap}
            selectModal={selectModal}
            landmarkToggle
          />
        </div>
        <TimelineDrawer
          mainNav={mainNav}
          wMatrix={wMatrix}
          eventToString={eventToString}
          redTripsLoading={redTripsLoading}
          selectTab={this.selectTab}
          show={!!selectedDevice}
          selectedDevice={selectedDevice}
          historyTrips={historyTrips}
          getTrips={getTrips}
          date={date}
          hoursOfOperation={hoursOfOperation}
          changeTripDate={changeTripDate}
          setSelectedTrip={setSelectedTrip}
          selectedTrip={selectedTrip}
          selectedEventID={selectedEventID}
          selectEvent={selectEvent}
          availableEventFilters={availableEventFilters}
          applyFilters={applyFilters}
          selectedEventTypes={selectedEventTypes}
          showLocates={showLocates}
          showEvents={showEvents}
          sortOrder={sortOrder}
          toggleLocates={toggleLocates}
          toggleEvents={toggleEvents}
          setSortOrder={setSortOrder}
          selectModal={selectModal}
          requestCameraClipUpload={requestCameraClipUpload}
        />
      </div>
    )
  }
}

export default compose(
  helper(),
  TripHOC(),
)(TimelineView)
