import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Alert } from 'antd'
import { HuePicker } from 'react-color'
import MakeGroup from '@mol/makeGroup'
import DevicesTable from '@mol/devicesTable'
import { InputSearch } from '@atom/input'
import UsersTable from '@mol/usersTable'
import EditTags from '@mol/modal/editTags'

import GroupModalHOC from '@hoc/groupPanel.hoc'
import helper from '@helper'

class GroupPanel extends Component {
  static propTypes = {
    groups: PropTypes.array,
    groupsLoading: PropTypes.bool,
    devices: PropTypes.array,
    users: PropTypes.array,
    usersLoading: PropTypes.bool,
    save: PropTypes.func.isRequired,
    deleteTag: PropTypes.func.isRequired,
    message: PropTypes.object,
    activeKey: PropTypes.string.isRequired,
    wMatrix: PropTypes.func.isRequired,
    contactToAssignGroupQueryRefetch: PropTypes.func.isRequired,
  }

  static defaultProps = {
    groups: [],
    groupsLoading: false,
    devices: [],
    users: [],
    usersLoading: false,
    message: {},
  }

  state = {
    showColorPicker: false,
    userSearchVal: '',
  }

  componentDidUpdate(prevProps) {
    const { activeKey, contactToAssignGroupQueryRefetch } = this.props
    if (prevProps.activeKey !== '1' && activeKey === '1') {
      contactToAssignGroupQueryRefetch()
    }
  }

  toggleColorPicker = () => {
    const { showColorPicker } = this.state
    this.setState({ showColorPicker: !showColorPicker })
  }

  /**
   * @public
   * Handles on change for user search input
   */
  updateUserSearch = (event) => {
    this.setState({ userSearchVal: event.target.value })
  }

  /**
   * @private
   * Filters users list based on search state. Checks name, email, and groups.
   */
  filterUsers = () => {
    const { users } = this.props
    const { userSearchVal } = this.state
    // make clean copy
    if (users) {
      let filteredUsers = JSON.parse(JSON.stringify(users))
      if (userSearchVal && userSearchVal.length > 0) {
        filteredUsers = filteredUsers.filter((user) => {
          const fullName = `${user.firstName} ${user.lastName}`
          if (fullName.toLowerCase().indexOf(userSearchVal.toLowerCase()) !== -1
            || user.role.name.toLowerCase().indexOf(userSearchVal.toLowerCase()) !== -1
            || user.email.toLowerCase().indexOf(userSearchVal.toLowerCase()) !== -1) {
            return true
          }
          /** @TODO LEFT OF HERE. results of group check are not returning correctly */
          for (let i = 0; i < user.groups.length; i += 1) {
            if (user.groups[i].name.toLowerCase().indexOf(userSearchVal.toLowerCase()) !== -1) {
              return true
            }
          }
          return false
        })
      }
      return filteredUsers
    }
    return []
  }

  /**
   *
   * stepNum
   * common.showColorPicker
   *
   * tag.selected
   * tag.create
   * tag.cancel
   * tag.save
   * tag.delete
   * tag.changeColor
   * tag.changeName
   *
   * devices.items
   * devices.isChecked
   * devices.update
   *
   * landmarks.items
   * landmarks.isChecked
   * landmarks.update
   *
   * users.items
   * users.isChecked
   * users.update
   */
  renderSlide = (items) => {
    const {
      wMatrix, message, devices, usersLoading,
    } = this.props
    const { showColorPicker } = this.state
    const filteredUsers = this.filterUsers()
    switch (items.stepNum) {
      case 1:
        return (
          <div>
            <div>
              <MakeGroup
                name={items.tag.selected.name}
                changeGroupName={items.tag.changeName}
                toggleColorPicker={this.toggleColorPicker}
                changeColor={items.tag.changeColor}
                show={(items.stepNum === 1)}
              />
            </div>
            <div style={{ position: 'relative' }}>
              <div className="huePicker" style={{ display: showColorPicker ? 'block' : 'none', marginLeft: '400px', marginBottom: '10px' }}>
                <HuePicker color={items.tag.selected.color} onChange={items.tag.changeColor} />
              </div>
            </div>
          </div>
        )
      case 2:
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <DevicesTable
              style={{ marginTop: 5 }}
              devices={devices}
              updateSelectedRows={items.devices.update}
              checkedDevices={items.devices.checked}
              wMatrix={wMatrix}
              ref={this.deviceTable}
            />
          </div>
        )
      case 3:
        return (
          <div style={{ display: items.stepNum === 3 ? 'flex' : 'none', flexDirection: 'column' }}>
            <InputSearch
              style={{ alignSelf: 'flex-end' }}
              className="groupPanelSearch"
              placeholder={wMatrix('Search for User')}
              onChange={this.updateUserSearch}
            />
            <UsersTable
              style={{ marginTop: 5 }}
              isDetailed
              loading={usersLoading}
              users={filteredUsers}
              updateSelectedRows={items.users.update}
              checkedUsers={items.users.checked}
              ref={this.contactTable}
            />
          </div>
        )
      default:
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
            <Button style={{ display: items.stepNum === 0 ? 'block' : 'none' }} type="primary" onClick={items.tag.create}>{wMatrix('New Group')}</Button>
            <Alert style={{ display: message.error || message.success ? 'block' : 'none', width: '275px', marginLeft: '10px' }} closable showIcon message={wMatrix(message.error || message.success)} type={message.error ? 'error' : 'success'} />
          </div>
        )
    }
  }

  render() {
    const {
      groups, groupsLoading, save, deleteTag, devices, users, wMatrix,
    } = this.props
    const items = [{
      name: 'devices',
      items: devices,
    },
    {
      name: 'users',
      items: users,
    }]
    const descriptions = ['', wMatrix('groupPanelDescription1'), wMatrix('groupPanelDescription2'), wMatrix('groupPanelDescription3'), '', '']

    return (
      <div>
        <EditTags
          type="groups"
          tags={groups}
          loading={groupsLoading}
          items={items}
          render={this.renderSlide}
          saveTag={save}
          deleteTag={deleteTag}
          descriptions={descriptions}
        />
      </div>
    )
  }
}

export default GroupModalHOC(helper())(GroupPanel)
