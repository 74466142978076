/**
 * RULE 1: 1 level ONLY
 * RULE 2: Keys can be MAX 4 words long
 * RULE 3: Unique keys
 * RULE 4: Leave a comment JSDOC / markup for functions
 * RULE 5: DON'T TALK ABOUT LOCALIZATION
 */
class SP {
  constructor() {
    this.speedMeasure = 'mph'
    this.distanceMeasure = 'mile'
    this.dictionary = {
      'Enter': 'Ingresar',
      'Exit': 'Salida',
      'Trigger on Enter': 'Activar al entrar',
      'Trigger on Exit': 'Activar al salir',
      'Trigger': 'Desencadenar',
      'Landmark Name': 'Nombre del punto de referencia',
      'Enter and Exit': 'Entrar y salir',
      'app' : 'App',
      'driver_confirmed' : 'Conductor confirmado',
      'driver_unconfirmed' : 'Conductor no confirmado',
      'unread': 'No leído',
      'Unassigned': 'No asignado',
      'powerOn': 'Encendido',
      'powerOff': 'Apagado',
      'Total Devices': 'Dispositivos totales',
      'serviceTech': 'Técnico de servicio',
      'Driver Updated Successfully': 'El controlador se actualizó correctamente',
      'Latitude': 'Latitud',
      'Longitude': 'Longitud',
      'Cleaned': 'Limpiado',
      'Service Status': 'Estado del servicio',
      'speedLimit': 'Umbral de velocidad',
      'speedLimitDescription': 'Si elige habilitar las alertas de límite de velocidad, se le notificará cuando se exceda este límite de velocidad',
      'defaultTimeRange': 'Intervalo de tiempo predeterminado',
      'defaultTimeRangeDescription': 'El intervalo de tiempo predeterminado en la sección de escala de tiempo',
      'thirtyHistory': 'Últimos 30 días',
      'dailyHistory': 'Día a día',
      'Speeding at': 'Acelerando en',
      'Latitude': 'Latitud',
      'Longitude': 'Longitud',
      'lowBattery': 'Batería baja',
      'over the road speed': 'sobre la velocidad de la carretera',
      'Exited Landmark': 'Punto de referencia salido',
      'Entered Landmark': 'Punto de referencia introducido',
      'Driving at': 'Conduciendo a',
      'Diagnose Trouble Code': 'Diagnosticar código de problema',
      'Speed Threshold': 'Umbral de velocidad',
      'First Move Time': 'Tiempo Del Primer Movimiento',
      'Last Move Time': 'Hora del último movimiento',
      'reportTooLarge': 'Informe solicitado demasiado grande',
      'reportTooLargeDescription': 'Puede elegir menos vehículos o un período de tiempo menor e intentarlo de nuevo, o solicitar una descarga a continuación',
      'reportDownload': 'Informe de descarga',
      'reportDownloadDescription': 'Enviaremos el informe a los correos electrónicos cuando esté listo',
      'saveReportDownloadSuccess': 'Solicitud enviada con éxito, le enviaremos el informe por correo electrónico cuando esté listo',
      'saveReportDownloadError': 'No se pudo enviar la solicitud, intente nuevamente o contáctenos',
      'request': 'Solicitud',
      'download': 'Descargar',
      /** @Header (using nav keys) */
      map: 'Map',
      reports: 'Informes',
      service: 'Servicio',
      'driver-scorecard': 'Cuadro De Mando',
      'video-clips': 'Videoclips',
      'Coming Soon': 'Próximamente',
      landmarks: 'Puntos De Referencia',
      landmark: 'Punto De Referencia',
      distance: 'Distancia',
      eld: 'ELD',
      'field-service': 'Servicio De Campo',
      videoClips: 'Clips De Video',
      /* end of header */

      'Reports': 'Informes',
      'Driver Scorecard': 'Cuadro de mando',

      'activeTickets': 'Entradas activas',
      'ticketStatus': 'Estado del boleto',
      'serviceType': 'Tipo de servicio',
      'Upcoming': 'Próxima',
      'serviceCost': 'Costo del servicio',
      'topCostingVehicles': 'Vehículos de mayor costo',
      'highestCostingVehicles': 'Vehículos de Mayor Coste',
      'createService': 'Crear servicio',
      /* event type */
      "Idle": "Ociosa",
      "Landmark GeoFence Entered and Exited": "Punto de referencia GeoFence ingresado y salido",
      "Low Battery": "Batería baja",
      "Stop Begin": "Stop Begin",
      "Device Off Route": "Dispositivo fuera de ruta",
      "Diagnostic Trouble Code": "Código de diagnóstico de problemas",
      "Disconnect": "Desconectar",
      "Dynamic GeoFence": "GeoFence dinámico",
      "Engine RPM": "RPM del motor",
      "Excessive Speeding": "Exceso de velocidad",
      "Fast Start": "Comienzo rápido",
      "Fuel Card Misuse": "Uso indebido de la tarjeta de combustible",
      "Stop End": "Stop End",
      "Hard Braking": "Frenada dura",
      "Harsh Turn": "Vuelta dura",
      "High Temperature": "Alta temperatura",
      "Idle Begin": "Inactivo comenzar",
      "Landmark Entered": "Punto de referencia ingresado",
      "Landmark Exited": "Punto de referencia salido",
      "Low Battery": "Batería baja",
      "Low Fuel": "Bajo combustible",
      "Low Temperature": "Baja temperatura",
      "Malfunction Indicator": "Indicador de mal funcionamiento",
      "Movement Concluded": "Movimiento concluido",
      "Movement Initiated": "Movimiento iniciado",
      "Panic Button Activated": "Botón de pánico activado",
      "Power Loss": "Pérdida de potencia",
      "PTO Primary On": "PTO primario encendido",
      "PTO Primary Off": "PTO primario apagado",
      "PTO Secondary On": "PTO secundaria encendido",
      "PTO Secondary Off": "PTO secundaria apagado",
      "PTO Tertiary On": "PTO terciario activado",
      "PTO Tertiary Off": "PTO terciario apagado",
      "ptoPrimaryOn": "PTO primario encendido",
      "ptoPrimaryOff": "PTO primario apagado",
      "ptoSecondaryOn": "PTO secundaria encendido",
      "ptoSecondaryOff": "PTO secundaria apagado",
      "ptoTertiaryOn": "PTO terciario activado",
      "ptoTertiaryOff": "PTO terciario apagado",
      "Reconnect": "Volver a conectar",
      "Speed Limit": "Límite de velocidad",
      "Speeding End Inside Boundary": "Exceso de velocidad dentro del límite",
      "Speeding Start Inside Boundary": "Acelerar Comenzar dentro del límite",
      "Stop": "Detener",
      "Unauthorized Ignition On": "Encendido no autorizado en",
      "Unauthorized Movement": "Movimiento no autorizado",
      "Idle End": "Idle End",
      "Ignition On": "Encendido conectado",
      "Ignition Off": "Encendido apagado",
      "Trip End": "Fin del viaje",
      "Trip Begin": "Viaje comenzar",
      "Landmark Entered": "Punto de referencia ingresado",
      "Landmark Exited": "Punto de referencia salido",
      "Service Due": "Servicio debido",
      "idleBegin": "Idle Begin",
      "idleEnd": "Idle End",
      /* end of event type */
      /* service types */
      "Oil Change": "Cambio de aceite",
      "Maintenance": "Mantenimiento",
      "Tire Rotation": "Rotación de llanta",
      "Equipment Service": "Servicio de equipamiento",
      "Brake Replacement": "Reemplazo de freno",
      "Fuel Filter Change": "Cambio de filtro de combustible",
      "Tire Replacement": "Reemplazo de llanta",
      "Air Filter Change": "Cambio de filtro de aire",
      "Hydraulic Fluid Change": "Cambio de fluido hidráulico",
      "Transmission Filter Change": "Cambio de filtro de transmisión",
      "Transmission Fluid Change": "Cambio de fluido de transmisión",
      "Battery Replacement": "Cambio de batería",
      "Belt Replacement": "Reemplazo de la correa",
      "Inspection": "Inspección",
      "Registration": "Registro",
      "Differential Service": "Servicio diferencial",
      "Fan Gear Box Service": "Servicio de caja de engranajes de ventilador",
      "DEF Pump Flter": "Filtro de bomba DEF",
      "DPF Clean": "DPF limpio",
      "DEF Filter Change": "Cambio de filtro de DEF",
      "DOT Inspection": "Inspección DOT",
      "Alignment": "Alineación",
      "Vehicle Rotation": "Rotación de vehículos",
      "Coolant Flush": "Descarga de refrigerante",
      "Lubricate Tow Body": "Lubricar el cuerpo de remolque",
      "Lubricate Chasis": "Lubricar Chasis",
      "Engine Tune Up": "Ajuste del motor",
      "Shock Replacement": "Reemplazo de choque",
      "LF Steer Tire": "Neumático de dirección LF",
      "RF Steer Tire": "Neumático de dirección RF",
      "LF Drive Tire Set": "Juego de neumáticos LF Drive",
      "RF Drive Tire Set": "Juego de neumáticos RF Drive",
      "LR Drive Tire Set": "Juego de neumáticos LR Drive",
      "RR Drive Tire Set": "Juego de neumáticos RR Drive",
      "Lift Axle Set": "Conjunto de eje elevador",
      "Cabin Air Filter": "Filtro de aire de cabina",
      "Sticker renewal": "Renovación de etiqueta",
      "License plate sticker renewal": "Renovación de la etiqueta de la matrícula",
      "Exterior Detail": "Detalle exterior",
      "Interior Detail": "Detalle interior",
      "Full Detail": "Detalle completo",
      "Service Performed": "Servicio realizado",
      "Medical Card": "Tarjeta médica",
      "Drivers license": "Licencia de conducir",
      "Trailer Inspections": "Inspecciones de remolques",
      "Toll Renewal": "Renovación de peaje",
      "Replacement Windshiled": "Reemplazo Windshiled",
      "Record mileage": "Registro de kilometraje",
      "Windshield Replacement": "Reemplazo de parabrisas",
      "Factory Recalls": "Recuerdos de fábrica",
      "Brake Inspection": "Inspección de frenos",
      "Device Usage": "Uso del dispositivo",
      "Alternator Replacement": "Reemplazo del alternador",
      "Radiator": "Radiator",
      "Fuel System Clean and Flush": "Sistema de combustible limpio y al ras",
      "Oil Analysis": "Análisis de aceite",
      "Oil Filter": "Filtro de aceite",
      "Other": "Otra",
      "Wheel Chair Lift Inspection": "Inspección de elevador de silla de ruedas",
      "Blower Motor Oil Change": "Cambio de aceite del motor del soplador",
      "Truck Mount Oil Change": "Cambio de aceite de montaje en camión",
      "CAT Pump Oil Change": "Cambio de aceite de la bomba CAT",
      "CAT Pump Service": "Servicio de bomba CAT",
      "Blower Motor Service": "Servicio de motor soplador",
      "Truck Mount Service": "Servicio de montaje de camiones",
      "PTO Service": "Servicio de toma de fuerza",
      "Drive Shaft Lubrication": "Lubricación del eje impulsor",
      "Transmission Service": "Servicio de transmisión",
      "Oil Change - Diesel Engine": "Cambio de aceite - motor diese",
      "Reoccurring Maintenance": "Mantenimiento recurrente",
      "Recall": "Recordar",
      "DOT Mileage": "DOT Kilometraje",
      "DOT Biennial Update": "Actualización bienal del DOT",
      "Test": "Prueba",
      "AC Repairs": "Reparaciones de aire acondicionado",
      "Mileage": "Kilometraje",
      "Oil and Filter Change": "Cambio de aceite y filtro",
      "Suspension and Steering System": "Sistema de suspensión y dirección",
      "Belt Inspection": "Inspección de la correa",
      "Timing Belt": "Correa dentada",
      "Spark Plugs": "Bujías",
      "Software": "Software",
      "Engine Hours": "Horas de motor",
      "Lift Gate": "Puerta de ascensor",
      "Thermostat and Gasket Assembly": "Conjunto de termostato y junta",
      "Suspension and Steering System": "Sistema de Suspensión y Dirección",
      "BIT Inspection": "Inspección BIT",
      "Bulb Change": "Cambio de Bombilla", // had space after
      "Cleaned": "Limpiado",
      "DOT Inspection - Tractor": "Inspección DOT - Tractor", // had space after
      "DOT Inspection - Trailer": "Inspección DOT - Remolque",
      "Suspension and Steering System": "Sistema de Suspensión y Dirección", // had space after
      "Tire Pressure Check": "Comprobación de la Presión de los Neumáticos", // had space after
      /* end of service types */
      'Email': 'Correo electrónico',
      'Phone': 'Teléfono',
      'Role': 'Papel',
      'Labels': 'Etiquetas',
      'Start Date': 'Fecha de inicio',
      'End Date': 'Fecha final',
      'Speed Threshold': 'Umbral de velocidad',
      'Events': 'Eventos',
      'Service Types': 'Tipos de servicio',
      'Date Range': 'Rango de fechas',
      'Start of the day': 'Comienzo del día',
      'End of the day': 'Fin del día',
      'Daily': 'Daily',
      'Weekly': 'Semanal',
      'Monthly': 'Mensual',
      'Annually': 'Anualmente',
      'Quarterly': 'Trimestral',
      'Current Day': 'Current Day',
      'Yesterday': 'Ayer',
      'Current Week': 'Semana actual',
      'Last Week': 'La semana pasada',
      'Current Month': 'Mes actual',
      'Last Month': 'El mes pasado',
      'Current Quarter': 'Trimestre actual',
      'Last Quarter': 'Último cuarto',
      'Year To Date': 'El año hasta la fecha',
      'All': 'Todas',
      'serviceDue': 'Servicio Debido',
      'serviceAlertDescription': 'Reciba una alerta por correo electrónico para el próximo servicio del vehículo debido',
      'theAliasCannotBeEmpty': 'El alias no puede estar vacío',
      'okToDelete': 'OK la ceremonia',
      /** 11/19/2019 */
      'Phone Carrier': 'Operador de telefonía',
      'Please select a phone carrier': 'Por favor seleccione un operador de telefonía',
      'Not Selected': 'No seleccionado',
      /** 10/16/2019 */
      'fuelcardMisuse': 'Uso indebido de la tarjeta de combustible',
      'fuelcardAlertDescription': 'Reciba una alerta por posible mal uso de la tarjeta de combustible',
      /** 10/15/2019 */
      'Saving': 'Ahorro',
      'saveAlertSuccessMsg': 'La configuración de notificaciones se guardó correctamente',
      'saveAlertErrorMsg': 'No se pudo guardar la configuración de las notificaciones. Vuelve a intentarlo o contáctanos.',
      /** 10/10/2019 */
      'Panic Button': 'Botón de pánico',
      'panicButtonDescription': 'La alerta de botón de pánico es para que el conductor notifique a los despachos que puede haber una emergencia. Solo está disponible con ciertos dispositivos y debe conectarse correctamente.',
      'Stop': 'Detener',
      'stopDescription': 'La alerta de detención es para monitorear cuando un dispositivo se detiene por un período de tiempo específico',
      'MovementInitiatedConcluded': 'Movimiento iniciado / concluido',
      'movementAlertDescription': 'La alerta de movimiento es para monitorear si un vehículo arranca o se detiene',
      'Movement Initiated': 'Movimiento iniciado',
      'Movement Concluded': 'Movimiento concluido',
      'preferenceGeneralSaveSuccess': 'Configuraciones de preferencias guardadas correctamente',
      'preferenceGeneralSaveFail': 'No se pudo guardar la configuración, asegúrese de que las entradas sean válidas. Si aún experimenta el mismo problema, contáctenos.',
      /** 10/08/2019 */
      'Email already exists': 'el Email ya existe',
      'User Updated Successfully': 'Usuario actualizado con éxito',
      Distance: 'Distancia',
      sortBy: 'Ordenar Por',
      searchBy: 'Busca Por',
      'Sort by: Alias': 'Ordenar Por: Alias',
      'Sort by: Distance': 'Ordenar Por: Distancia',
      'Search by: Address': 'Busca Por: Dirección',
      'Search by: Landmark': 'Busca Por: Landmark',
      'Type address to calculate': 'Escriba dirección para calcular',
      /* 09/04/2019 */
      'Short Trip, less than a mile': 'Viaje corto, menos de una milla',
      /* 12/18/2018 */
      'Map View': 'Vista del Mapa',
      'Time Between Trips': 'Tiempo Entre Viajes',
      Date: 'Fecha',
      dateAndTime: 'Fecha y hora',
      Sort: 'Ordenar',
      Filter: 'Filtrar',
      Start: 'Comienzo',
      End: 'Fin',
      ASC: 'ASC',
      DESC: 'DESC',
      Trip: 'Viaje',
      'Current Trip': 'Viaje Actual',
      'Short Trip, Less than a mile': 'Viaje corto, menos de una milla',
      miles: 'millas',
      List: 'Lista',
      Map: 'Mapa',
      View: 'Vista',
      Satellite: 'Satélite',
      Traffic: 'Tráfico',
      Animation: 'Animación',
      Clustering: 'Agrupación',
      'Night Mode': 'Modo Nocturno',
      /* 7-17-20 */
      showLandmarks: 'Mostrar Hitos',
      /* 11/13/2018 */
      Timeline: 'Línea de Tiempo',
      timeline: 'Línea de Tiempo',
      Performance: 'Actuación',
      /* 10/23/2018 */
      Devices: 'Dispositivos',
      'Search for Device': 'Buscar dispositivo',
      'Filter By Tag': 'Filtrar por grupo o etiqueta',
      'driven by': 'conducido por',
      'Last Locate': 'Último lugar',
      on: 'en',
      'Current State': 'Estado Actual',
      Speed: 'Velocidad',
      'Live Map': 'Mapa en vivo',
      Service: 'Servicio',
      Jobs: 'Trabajos',
      Job: 'Trabajo',
      Shipments: 'Envíos',
      Shipment: 'Envío',
      workOrders: 'Órdenes de trabajo',
      workOrder: 'Orden de trabajo',
      Destination: 'Destino',
      Destinations: 'Destinos',
      jobName: 'Nombre del trabajo',
      dateCreated: 'Fecha de creacion',
      dueDate: 'Fecha de vencimiento',
      createJob: 'Crear trabajo',
      addDestination: 'Agregar destino',
      addDestinations: 'Agregar destinos',
      Order: 'Pedido',
      completionDate: 'Fecha de Terminación',
      additionalItems: 'Artículos adicionales',
      package: 'Paquete',
      packages: 'Paquetes',
      work_order: 'Orden de trabajo',

      Stopped: 'Detenido',
      Parking: 'Estacionamiento',
      Moving: 'Emocionante',
      'Service Type': 'Tipo de servicio',
      'Last Service Date': 'Fecha del último servicio',
      'Service Interval': 'Fecha del último servicio',
      'Distance Traveled': 'Distancia viajada',
      'Time Traveled': 'El tiempo viajó',
      'Service Notes': 'Notas de servicio',
      'New Label': 'Nueva etiqueta',
      'Name': 'Nombre',
      'Current Labels': 'Etiquetas actuales',
      'OK to cancel': 'OK para cancelar',
      /* 10/22/2018 */
      'Groups': 'Grupos',
      'Users': 'Usuarios',
      'Drivers': 'Conductores',
      'New Group': 'Nuevo grupo',
      'groupPanelDescription1': 'Cree etiquetas de grupo para ayudar a organizar y filtrar sus dispositivos. Comience creando un nombre de etiqueta y seleccionando un color de etiqueta. Luego haga clic en el botón "Siguiente" para comenzar a seleccionar qué dispositivos usarán su etiqueta.',
      'groupPanelDescription2': 'Elija los dispositivos a los que le gustaría aplicar su etiqueta. Cuando busque su etiqueta más tarde, estos son los dispositivos que se filtrarán para ver. Cuando haya terminado, haga clic en el siguiente botón para elegir quién puede ver sus etiquetas.',
      'groupPanelDescription3': 'Ahora elige quién podrá ver esta etiqueta. Una vez que haya terminado, haga clic en "Guardar etiqueta" en la esquina inferior derecha.',
      'Delete': 'Borrar',
      'Next': 'Siguiente',
      'Cancel': 'Cancelar',
      'Previous': 'Anterior',
      'Example': 'Ejemplo',
      'Preview': 'Avance',
      'Comfortable': 'Cómodo',
      'Cozy': 'Acogedor',
      'Compact': 'Compacto',
      'Alias Name': 'Apodo',
      'Driver': 'Conductor',
      'Current Groups': 'Grupos Actuales',
      'Device': 'Dispositivo',
      'Group': 'Grupo',
      'User': 'Usuario',
      'Permissions/Role': 'Permisos/Rol',
      'Viewable Groups': 'Grupos visibles',
      'Search for User': 'Buscar usuario',
      groupHasBeenUpdated: 'Tu grupo ha sido actualizado.',
      labelHasBeenUpdated: 'Su etiqueta ha sido actualizada.',
      'Add User': 'Agregar Usuario',
      /* 10/21/2018 */
      'Save': 'Salvar',
      'Success': 'Éxito',
      emailTakenError: 'Se toma el correo electrónico. Si posee este correo electrónico, por favor contáctenos',
      noPermissionError: 'Su cuenta no tiene permiso para hacer esto; si tiene alguna pregunta, comuníquese con nosotros.',
      /** Validation */
      'Invalid email': 'Email inválido',
      'This field is required': 'este campo es requerido',
      'Invalid name': 'Nombre inválido',
      'Invalid phone number': 'Numero de telefono invalido',
      'Fields must match': 'Los campos deben coincidir',
      'Completion requires a completion date': 'La finalización requiere una fecha de finalización',

      'Language': 'Idioma',
      'Measurement': 'Medición',
      'Profile Settings': 'Configuración de perfil',
      'Email Address': 'Correo electrónico',
      'Password': 'Contraseña',
      'Confirm Password': 'Confirmar Contraseña',
      'First Name': 'Nombre de pila',
      'Last Name': 'Apellido',
      'Mobile Phone': 'Teléfono móvil',
      'change': 'cambio',
      'cancel': 'cancelar',
      'English': 'Inglés',
      'Spanish': 'Español',
      'Miles': 'Millas',
      'Kilometers': 'Kilómetros',
      preferenceEmailAddressInfo: 'Si se cambia el correo electrónico, debemos cerrar sesión por razones de seguridad',
      preferenceEmailPlaceholder: 'Necesitamos cerrar sesión después de que se cambie este campo',
      preferencePasswordPlaceholder: 'Ingrese la nueva contraseña',
      preferenceConfirmPasswordPlaceholder: 'Ingrese la nueva contraseña nuevamente',
      preferenceFirstNamePlaceholder: 'Ponga su primer nombre',
      preferenceLastNamePlaceholder: 'Ingresa tu apellido',
      'Application Settings': 'Solicitud Ajustes',
      basicInformation: 'Información básica',

      basicInfo: 'Información básica',
      deviceInfo: 'Información del dispositivo',
      email: 'Correo electrónico',
      admin: 'admin',
      Admin: 'Admin',
      General: 'General',
      Preference: 'Preferencia',
      preference: 'Preferencia',
      Preferences: 'Preferencias',
      "Personal Labels": 'Personal Etiquetas',
      "Log out": 'Cerrar sesión',
      lastLocate: 'Última localización',
      vehicleInfo: 'Información del vehículo',
      Notifications: 'Notificaciones',
      password: 'Contraseña',
      userResources: 'Recursos De Usuario',
      support: 'Apoyo',
      guides: 'Guías',
      contactUs: 'Contáctenos',
      call: 'Llamada',
      submitTicket: 'Enviar un Ticket',
      subject: 'Tema',
      phoneNumber: 'Número de Teléfono',
      relatedFeatures: 'Características Relacionadas',
      details: 'Detalles',
      Details: 'Detalles',
      ticketSubject: 'Asunto del Boleto',
      phoneNumberToReach: 'Número de teléfono para contactarlo',
      pleaseDescribe: 'Describa el problema que tiene',
      liveMap: 'Mapa en Vivo',
      other: 'Otra',
      deviceInstallationVideos: 'Videos de Instalación del Dispositivo',
      userGuides: 'Guías de Usuario',
      submit: 'Enviar',
      year: 'Año',
      upcoming: 'Próxima',
      tickets: 'Entradas',
      nextDue: 'Lo Próximo Por Hacer',
      in: 'en',

      createLabelStep1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis molestie eros rhoncus interdum. Integer id aliquet lorem. Nulla facilisi. Pellentesque in tortor nisl. Etiam.',
      createLabelStep2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec ultricies nulla. Nulla pharetra facilisis nibh eget dictum. Sed nec orci sollicitudin purus suscipit lobortis.',

      lastName: 'Apellido',
      firstName: 'Nombre',
      name: 'Nombre',
      phone: 'Teléfono',
      role: 'Permisos',
      nameRoleGroups: 'Nombre, Permisos, Grupos',
      selectOptional: 'Seleccionar (Opcional)',
      globalAdminViewAll: 'Los roles globales y administrativos pueden ver todos los grupos',

      global: 'Global',
      supervisor: 'Supervisor',
      readOnly: 'Solo lectura',
      pleaseSelectPermissionLevel: 'Por favor seleccione el nivel de permiso',

      DeviceBattery: 'Batería Del Dispositivo',
      Speeding: 'Exceso De Velocidad',
      DTC: 'DTC',
      HoursOfOperations: 'Horas De Operación',
      Landmarks: 'Puntos De Referencia',
      ReconnectDisconnect: 'Reconectar/Desconectar',
      AccelerationDeceleration: 'Aceleración / Desaceleración',
      Acceleration: 'Aceleración',
      Deceleration: 'Desaceleración',
      Accel: 'Acel',
      Decel: 'Desacel',
      Reconnect: 'Reconectar',
      Disconnect: 'Desconectar',
      adjustHours: 'Ajustar Horas',

      PTO: 'PTO',
      powerTakeOffSwitches: 'Interruptores De Toma De Fuerza (PTO)',
      Primary: 'Primario',
      Secondary: 'Secundario',
      On: 'On',
      Off: 'Off',
      noLandmarksApplied: 'No se aplican puntos de referencia.',
      noDetails: 'No Hay Detalles.',
      notifSwitchOn: '\u00BF Notificarme cuando este interruptor se encienda?',
      notifSwitchOff: '\u00BF Notificarme cuando este interruptor se apaga?',
      PrimaryPtoSwitch: 'Interruptor PTO Primario',
      SecondaryPtoSwitch: 'Interruptor PTO Secundario',
      LandmarkName: 'Nombre De Referencia',
      Type: 'Tipo',

      Enable: 'Habilitar',
      Split: 'División',

      deviceGroupLabel: 'Device, Group, Label', //Not in use?

      deviceBatteryDescription: 'Reciba una alerta cuando la batería de su dispositivo esté baja y necesite reemplazo. Solo disponible para dispositivos que usan batería como fuente de alimentación.',
      speedingDescription: 'La alerta de velocidad se puede utilizar de dos maneras. Reciba alertas si un vehículo supera una cierta velocidad o reciba alertas si un vehículo supera una cantidad designada por encima del límite de velocidad establecido.',
      dtcDescription: 'La alerta de DTC le notifica cuando su dispositivo recibe un DTC (código de diagnóstico) de su vehículo. Esto le ayuda a mantenerse al tanto del mantenimiento del vehículo al solucionar el problema de inmediato.',
      hooDescription: 'La alerta de Horas de Operación le permite establecer las horas de operación para un dispositivo específico y recibir alertas cuando ese dispositivo se usa fuera de esas horas.',
      landmarksDescription: 'La alerta de Landmark crea una alerta para dispositivos seleccionados cuando están cerca o ingresan a un área específica.The Landmark alert creates an alert for select devices when they are near or enter a specific area.',
      reconDisconDescription: 'Recibe alertas cada vez que un dispositivo seleccionado se desconecta de su puerto. Reciba otra alerta cuando el dispositivo se vuelva a conectar.',
      accelerationDescription: 'La alerta de aceleración/desaceleración le notifica cuando un vehículo acelera demasiado rápido y para frenar bruscamente. Excelente para monitorear el mal comportamiento del conductor.',
      ptoDescription: 'La alerta PTO le avisa cuando se usan interruptores en su vehículo, como una grúa. Solo disponible con ciertos dispositivos y debe estar conectado correctamente.',
      stopLengthDescription: 'La cantidad de minutos que un vehículo necesita estar inactivo para crear una parada. Puede ser entre 3 y 60 minutos.',

      text: 'Texto',
      push: 'Notificación De Inserción',
      speedThreshold: 'Umbral De Velocidad',
      milesOver: 'Millas Encima',
      milesOverPostedSpeed: 'Millas Sobre el Límite de Velocidad Publicado',
      notifyMeMO1: 'Notificarme cuando un dispositivo vaya a ',
      notifyMeMO2: ' mph por encima del límite de velocidad publicado.',
      setYourNewValue: 'Establece tu nuevo valor',
      edit: 'Editar',
      deviceSpeedThreshold: 'Umbral de Velocidad del Dispositivo',
      editingDevices: 'Editando Estos Dispositivos',
      selectNotificationType: 'Seleccione el Tipo de Notificación',
      MOCurrentlySet1: 'Actualmente configurado a ',
      MOCurrentlySet2: ' mph sobre el límite de velocidad.',
      applyToSelectedVehicles: '¿Aplicar a los vehículos seleccionados?',
      leaveBlankOrZero: 'Deje en blanco o cero si no desea aplicar esta alerta.',
      notifyMeSpeedThresh1: 'Notificarme si los dispositivos seleccionados viajan a ',
      notifyMeSpeedThresh2: ' mph o más.',

      Icon: 'Icono',
      'Device Manager': 'Administrador De Dispositivos',
      'Device Information': 'Información Del Dispositivo',
      'Vehicle Information': 'Información Del Vehículo',

      addDriver: 'agregar controlador',
      dotNumber: 'Número DOT',
      assignToDevice: 'Asignar A Dispositivo',
      unassigned: 'Sin Asignar',
      nameDotDevice: 'Nombre, DOT, Dispositivo...',
      manageELDDrivers: 'Administrar Los Controladores De ELD',
      fobId: 'FOB ID',
      areYouSure: '¿Estás seguro?',
      noTripData: 'No Hay Datos De Viaje',
      endedAt: 'Terminó En:',
      timeZone: 'Zona Horaria',
      mapRefreshRate: 'Frecuencia De Actualización Del Mapa',

      showOnMap: 'Mostrar en el Mapa',
      onMap: 'En el Mapa',

      orderBy: 'Ordenar Por',
      locates: 'Localiza',
      events: 'Eventos',
      showLocates: 'Mostrar Localizas',
      showEvents: 'Mostrar Eventos',
      filterEvents: 'Filtrar Eventos',
      ascending: 'Ascendente',
      descending: 'Descendiendo',
      apply: 'Aplicar',
      checkAll: 'Seleccionar todo',
      expandDrawerToShow: 'expandir el cajón para mostrar más',
      enableShowEvents: 'Habilite Mostrar eventos para usar esta sección',

      circleDescription: 'Un hito de radio consiste en un solo punto con un radio especificado. Puede editar este radio directamente en el mapa o debajo.',
      rectDescription: 'Un hito rectangular consta de dos puntos diagonales. Puede editar estos puntos directamente en el mapa o debajo.',
      polyDescription: 'Un hito de polígono consta de tres o más puntos. Puede editar estos puntos directamente en el mapa o debajo. Puede ajustar el número de puntos a continuación.',
      Landmark: 'Punto de referencia',
      nameLandmark: 'Nombre Punto de referencia',
      chooseLandmarkType: 'Elija tipo de hito',
      radius: 'Circulo',
      rectangle: 'Rectángulo',
      polygon: 'Polígono',
      detailsAndAdjustments: 'Detalles y Ajustes',
      addressSearch: 'Búsqueda de Dirección',
      searchMap: 'Buscar en el Mapa',
      searchForLandmark: 'Buscar Landmark',
      update: 'Actualizar',

      topSpeed: 'Velocidad Máxima',
      current: 'actual',

      errorUpdating: 'Error Al Actualizar',
      updatedSuccessfully: 'Actualizado Exitosamente',
      updating: 'Actualización',
      lastThirtyDays: 'Últimos 30 Días',

      groupsAndLabels: 'Grupos y Etiquetas',

      driverScorecard: 'Cuadro de mando',
      true: 'Cierto',
      totalDrivers: 'Conductores Totales',
      thisWeek: 'Esta Semana',
      good: 'Buena',
      fair: 'Justa',
      poor: 'Mala',
      exceptions: 'Excepciones',
      speedingExceptions: 'Excepciones de Exceso de Velocidad',
      speeding: 'Exceso de velocidad',
      hardBrakes: 'Roturas Duras',
      fastStarts: 'Comienzos Rápidos',
      timeRange: 'Intervalo de Tiempo',
      week: 'semana',
      Week: 'Semana',
      weeks: 'semanas',
      month: 'mes',
      months: 'meses',
      dashboard: 'Tablero',
      driver: 'Conductor',
      count: 'Contar',
      ofTotal: 'del Total',
      performance: 'Actuación',
      standings: 'Clasificaciones',
      rank: 'Rango',
      'Rank': 'Rango',
      overallRank: 'Rango general',
      billable: 'Facturable',
      searchDriverName: 'Buscar Nombre del Conductor',
      selectReport: 'Seleccione un informe del menú lateral',
      selectLandmark: 'Seleccionar Punto de Referencia',
      reportSearch: 'Buscar Informes',
      modifyReportFilters: 'Modificar Filtros de Informes',
      generate: 'Generar',
      create: 'Crear',
      afterHours: 'Fuera de Horario',
      batteryStatus: 'Estado de la Batería',
      exceptions: 'Excepciones',
      idle: 'Ocioso',
      ignitionOnOff: 'Encendido Encendido/Apagado',
      locations: 'Localizaciones',
      postedSpeedLimit: 'Límite de Velocidad Publicado',
      speed: 'Velocidad',
      stop: 'Detener',
      trips: 'Excursiones',
      fuelcardTransactions: 'Transacciones de Fuelcard',
      fuelcardMileage: 'Kilometraje de Fuelcard',
      transactions: 'Transacciones',
      selected: 'Seleccionado',
      vehiclesSelected: 'Vehículos Seleccionados',
      noData: 'Sin Datos',
      selectAll: 'Seleccionar Todo',
      clearAll: 'Limpiar Todo',
      endedAt: 'Terminó En',
      overTheLimit: 'sobre el límite',
      trip: 'viaje',
      scheduledReport: 'Informe programado',
      createScheduledReport: 'Crear informe programado',
      editScheduledReport: 'Editar informe programado',
      devices: 'Dispositivos',
      all: 'Todos',
      filters: 'Filtros',
      deliveryFrequency: 'Frecuencia de entrega',
      schedule: 'Horario',
      dateRange: 'Rango de fechas',
      startDate: 'Fecha de inicio',
      deliveryTime: 'El tiempo de entrega',
      recipients: 'Destinatarios',
      save: 'Salvar',
      areYouSure: 'Estas segura?',
      Vehicle: 'Vehículo',
      Vehicles: 'Vehículos',
      sample: 'Muestra',
      menu: 'Menú',
      top: 'Tapa',
      searchDeviceAliasOrVIN: 'Busque para un alias de dispositivo o VIN',
      filterGroupsAndLabels: 'Filtra los grupos y etiquetas',
      alias: 'Alias',
      Alias: 'Alias',
      labels: 'Etiquetas',
      created: 'Creado',
      due: 'Debido',
      recurring: 'Periódico',
      estimatedCost: 'Costo estimado',
      cost: 'Costo',
      status: 'Estado',
      notes: 'Notas',
      history: 'Historia',
      moreFromThisVehicle: 'Más De Este Vehículo',
      date: 'Fecha',
      mileage: 'Kilometraje',
      engineHours: 'Horas de motor',
      ticketNumber: 'Numero de Ticket',
      type: 'Tipo',
      actualCost: 'Costo Real',
      Completed: 'Terminado',
      completedBy: 'Completado Por',
      completed: 'Terminado',
      overdue: 'Atrasado',
      Overdue: 'Atrasado',
      Current: 'Actual',
      active: 'Activo',
      Active: 'Activo',
      groupBy: 'Agrupar Por',
      assignedDevice: 'Dispositivo Asignado',
      addItem: 'Añadir Artículo',
      read: 'Leer',
      locationType: 'Tipo de ubicacion',
      Latitude: 'Latitud',
      Longitude: 'Longitud',
      Coordinates: 'Coordenadas',
      sent_unconfirmed: 'Enviado sin confirmar',
      deleteJob: 'Eliminar trabajo',

      complete: 'Completa',
      quickCreate: 'Creación rápida',
      iconUpdated: 'Icono Actualizado',
      somethingWentWrong: 'Algo Salió Mal',
      iconManager: 'Administrador de Iconos',
      color: 'Color',
      label: 'Etiqueta',
      icon: 'Icono',
      locateNow: 'Ubicar Ahora',
      iconTextLimit: 'Limitado a 4 caracteres y solo disponible en iconos específicos',
      Advanced: 'Avanzada',
      stopLength: 'Longitud de parada',
      advancedOptions: 'Opciones avanzadas',
      min: 'min',
      pleaseInput: 'Por favor ingrese',
      notSelected: 'No seleccionado',
      Scheduled: 'Programado',
      Standard: 'Estándar',
      Professional: 'Profesional',
      Custom: 'Personalizado',
      battery: 'Batería',
      ignition: 'Encendido',
      movement: 'Movimiento',
      event: 'Evento',
      Event: 'Evento',
      odometer: 'Cuentakilómetros',
      pto: 'PTO',
      hoursOfOperation: 'Horas de operación',
      fuelCardTransaction: 'Transacción de tarjeta de combustible',
      fuelCardMileage: 'Tarjeta de combustible Kilometraje',
      driveTime: 'Hora de manejar',
      'Drive Time': 'Hora de manejar',
      time: 'hora',
      Time: 'Hora',
      city: 'ciudad',
      City: 'Ciudad',
      state: 'estado',
      State: 'Estado',
      zip: 'Código Postal',
      Zip: 'Código Postal',
      voltage: 'voltaje',
      Voltage: 'Voltaje',
      Heading: 'Bóveda',
      'Road Speed': 'Velocidad del camino',
      'Speed Over': 'Velocidad más',
      Duration: 'Duración',
      'Violation Count': 'Conteo de violaciones',
      'Max Speed': 'Máxima velocidad',
      'First Occurrence': 'Primera ocurrencia',
      'Last Occurrence': 'Última ocurrencia',
      VIN: 'VIN',
      'Service Start Date': 'Fecha de inicio del servicio',
      Interval: 'Intervalo',
      'Since Previous Service': 'Desde servicio anterior',
      Status: 'Estado',
      Notes: 'Notas',
      Card: 'Tarjeta',
      'Unit Cost': 'Costo unitario',
      'Quantity(Gallons)': 'Cantidad (galones)',
      'Fuel Purchase': 'Compra de combustible',
      Rebate: 'Reembolso',
      'Misc.': 'Misceláneas.',
      'Total Possible Misuse': 'Uso indebido total posible',
      'Transaction Date': 'Fecha de la última transacción',
      'Previous Transaction Date': 'La segunda fecha de transacción más reciente',
      'Estimated MPG': 'MPG estimado',

      second: 'Segundo',
      seconds: 'Segundos',
      minute: 'Minuto',
      minutes: 'Minutos',

      one: 'Uno',
      two: 'Dos',
      five: 'Cinco',
      ten: 'Diez',
      thirty: 'Treinta',

      mon: 'Lu',
      tue: 'Ma',
      wed: 'Mi',
      thu: 'Ju',
      fri: 'Vi',
      sat: 'Sa',
      sun: 'Do',

      viewAll: 'Ver Todo',
      close: 'Cierra',

      tripBegin: 'Viaje Comienza',
      tripEnd: 'Final del Viaje',
      locate: 'Localizar',
      ignitionOn: 'Encendido Conectado',
      ignitionOff: 'Encendido Apagado',
      fastStart: 'Comienzo Rápido',
      mosl: 'Millas Por Encima Del Límite De Velocidad',
      stopEnd: 'Parada Final',
      stopBegin: 'Deja De Empezar',
      idleEnd: 'Final Inactivo',
      idleBegin: 'Comienzo Inactivo',
      hardBrake: 'Frenada Dura',
      harshTurn: 'Giro Duro',
      start: 'Comienzo',
      movementInitiated: 'Movimiento iniciado',
      movementConcluded: 'Movimiento concluido',
      landmarkEnter: 'Hito Entrar',
      landmarkExit: 'Salida de Hito',
      ptoOn: 'PTO Encender',
      ptoOff: 'PTO Apagar',
      reconnect: 'Reconectar',
      disconnect: 'Desconectar',
      panic: 'Pánico',
      fuelcardTransaction: 'Transacción de Tarjeta de Combustible',

      disableStarter: 'Desactivar Arranque',
      starterInterruptToolTip: 'Evitar que el vehículo arranque.',

      day: 'día',
      days: 'días',
      hours: 'horas',
      years: 'años',
      Hours: 'Horas',
      Days: 'Días',
      Months: 'Meses',
      Years: 'Años',

      pending: 'Pendiente',
      sortBy: 'Ordenar Por',
      allDrivers: 'Todas Las Conductoras',
      allLandmarks: 'Todas las señales',
      more: 'más',
      recursAfterCompletion: 'Se repite después de la finalización',

      tempDescriptionFiller1: 'Lorem ipsum dolor amet veniam pickled paleo fam photo booth ugh lorem man bun artisan everyday carry sartorial sustainable.',
      tempDescriptionFiller2: '2 Lorem ipsum dolor amet veniam pickled paleo fam photo booth ugh lorem man bun artisan everyday carry sartorial sustainable.',
      listView: 'Vista de la lista',
      network: 'Red',
      location: 'Posición',
      Location: 'Posición',
      address: 'Posición',
      Address: 'Posición',
      datetime: 'Fecha y Hora',
      status: 'Estado',
      productKey: 'Clave de producto',
      serial: 'Número de Serie',
      companyPreferences: 'Preferencias de empresa',
      moslDescription: 'Un vehículo generará un evento Millas por encima del límite de velocidad (MOSL) cuando haya dos o más ubicaciones consecutivas donde la velocidad del vehículo sea mayor o igual al límite de velocidad publicado más el umbral de millas por hora establecido por el usuario. Esta configuración se aplicará a todos los dispositivos en su cuenta.',
      moslError: 'Parece que su cuenta no tiene millas por encima del límite de velocidad habilitado. Póngase en contacto con atención al cliente para obtener ayuda.',
      v3VideoGuides: 'V3 Guías de video',
      v3ManualGuides: 'V3 Guías manuales',
      selectAServiceTicket: 'Seleccione un ticket de servicio del menú lateral o Crear Nuevo',
      clear: 'Neto',
      afterHoursDescription: 'El Informe de ubicaciones fuera del horario de atención le dará las ubicaciones de los dispositivos que se movieron fuera del período de tiempo que usted designe.',
      batteryDescription: 'El Informe de estado de la batería muestra la última lectura de voltaje para cada dispositivo.',
      tripsDescription: 'El informe de viajes mostrará viajes y excepciones en los viajes.',
      ignitionDescription: 'El Informe de encendido mostrará la hora en que el encendido está encendido y cuando está apagado.',
      locationDescription: 'El Informe de ubicaciones muestra las ubicaciones del vehículo cuando estaba en movimiento.',
      movementDescription: 'El Informe de movimiento muestra cuando un dispositivo tiene un movimiento y se ha detenido.',
      moslDescription: 'El Informe de millas por encima del límite de velocidad muestra las ubicaciones del vehículo cuando excedió el límite de velocidad indicado.',
      stopDescription: 'El Informe de paradas mostrará cuándo y dónde se registró una parada.',
      idleDescription: 'El Informe de minutos de inactividad muestra el tiempo de inactividad diario de un vehículo.',
      eventDescription: 'El informe de eventos mostrará todas las excepciones para los dispositivos durante una fecha y un intervalo de tiempo determinados. Esto incluye: límites de velocidad, geocercas y áreas emblemáticas.',
      speedThresholdDescription: 'El Informe de umbral de velocidad le permite establecer un límite de velocidad y ver cuándo un dispositivo excede esa velocidad y cuántas veces.',
      serviceDescription: 'El Informe de servicios mostrará cada vehículo y el tipo de servicios que se han configurado.',
      mileageDescription: 'El Informe de kilometraje muestra las millas diarias recorridas por un vehículo.',
      odometerDescription: 'El informe del odómetro muestra las millas diarias recorridas por un vehículo.',
      ptoDescription: 'El Informe de PTO mostrará cuándo y dónde se activó un interruptor (por ejemplo, PTO, apertura / cierre de puertas, etc.).',
      hoursOfOperationDescription: 'El Informe de horas de funcionamiento mostrará la hora en que el dispositivo comenzó a moverse y la última vez que se movió.',
      fuelCardTransactionDescription: 'Informe de transacciones de tarjetas de combustible para usuarios de tarjetas de combustible',
      fuelCardMileageDescription: 'Informe de kilometraje de la tarjeta de combustible para usuarios de tarjetas de combustible',
      engineHoursDescription: `El Informe de horas del motor mostrará el tiempo de cada vehículo entre el encendido y el apagado.`,
      driverScorecardDescription: `El Informe del Cuadro de Mando del Conductor mostrará la clasificación de cada conductor según la excepción durante el período de tiempo especificado.`,
      geofenceEnter: "Punto de referencia ingresado",
      geofenceExit: "Punto de referencia salido",
      liveVideo: 'Video en Vivo',
      eventVideo: 'Video del Evento',
      viewRelatedClips: 'Ver Clips Relacionados',
      availableClips: 'Clips Disponibles',
      camera: 'Cámara',
      clipUploadWarning: 'Cargar un clip usa datos y le quitará el límite de datos.',
      upload: 'Subir',
      clipStart: 'Inicio del Clip',
      clipEnd: 'Extremo del Clip',
      timeRemaining: 'Tiempo Restante',
      dtc: 'DTC',
      exportDeviceList: 'Exportar lista de dispositivos',
      eventDuration: 'Duración del Evento',
      requestClip: 'Solicitar Clip',
      wexCard: 'Wex Tarjeta',
      fuelcard: 'Tarjeta de combustible',
      invalidWexCardLength: 'Longitud de la tarjeta Wex no válida',
      failedToUpdateWexCard: 'No se pudo actualizar la tarjeta Wex',
      fobEnabled: 'Fob habilitada',
      fobEnabledDescription: 'Habilitar FOB anulará la asignación del controlador mediante el escáner de fob',
      Clustering: 'Agrupación',
      'Driver Authorization': 'Autorización del conductor',
      driverIn: 'Autorización del conductor',

      iconSize: 'Tamaño de ícono',
      small: 'Pequeño',
      medium: 'Medio',
      large: 'Grande',

      centerOnAddress: 'Centrarse en la Dirección',
      typeMessage: 'Escribir Mensaje',
      send: 'Enviar',
      items: 'Artículos',
      actions: 'Comportamiento',

      importLandmarks: 'Importar Puntos de Referencia',
      downloadTemplateCSV: 'Descargar Archivo .CSV de Plantilla',
      uploadFile: 'Subir Archivo',
      deleteAndImport: 'Eliminar e Importar',
      import: 'Importar',
      validating: 'Validando',
      uploadCSV: 'Haga clic en esta área o arrastre un archivo .csv válido a esta área para cargar',
      landmarkImportDetails: 'Haga clic en esta área o arrastre un archivo .csv válido a esta área para cargar el archivo. Los archivos seleccionados se validarán y luego se cargarán. Recibirás un correo electrónico cuando tus puntos de referencia se hayan cargado correctamente.',
      landmarkImportDetailsReplace: 'Haga clic en esta área o arrastre un archivo .csv válido a esta área para reemplazar el archivo de carga. Los archivos seleccionados se validarán y luego se cargarán. Recibirás un correo electrónico cuando tus puntos de referencia se hayan cargado correctamente.',
      deleteAndImportLandmarksConfirm: '¿Está seguro de que desea eliminar todos los puntos de referencia existentes antes de importar sus nuevos puntos de referencia?',
      fileWasInvalid: 'Su archivo .csv no es válido. Corrija los siguientes problemas y vuelva a cargar su archivo.',
      xValidLandmarksClickBelow: 'puntos de referencia válidos. Haga clic en el botón de abajo para importar.',
      issueImportingLandmarks: 'Hubo un problema al importar tus puntos de referencia. Por favor, inténtelo de nuevo más tarde.',
      importSuccessfullyRequested: 'Importación solicitada correctamente. Recibirás un correo electrónico cuando la importación haya finalizado.',

      /** Popconfirm messages */
      Yes: 'Sí',
      No: 'No',
      deleteJobConfirm: '¿Estás seguro de que quieres eliminar este trabajo?',

      /** Alert Messages */
      errorRefetchingDeviceList: 'Error al volver a buscar la lista de dispositivos',
      notAValidYear: 'No es un año válido',
      duplicateVin: 'El VIN está asignado a otro vehículo o hubo un problema al validar este VIN',
      driverUpdatedSuccessfully: 'El controlador se actualizó correctamente',
      driverCreatedSuccessfully: 'Controlador creado con éxito',
      driverDeletedSuccessfully: 'El controlador se eliminó correctamente',
      failedToUpdateDriver: 'No se pudo actualizar el controlador',
      failedToCreateDriver: 'No se pudo crear el controlador',
      failedToDeleteDriver: 'No se pudo eliminar el controlador',
      errorRequestingClipUpload: 'Hubo un error al solicitar la carga del clip.',
      errorClipUploadDataLimit: 'La cámara de este vehículo ha alcanzado su límite de datos para este mes. Póngase en contacto con el soporte si tiene alguna pregunta.',
      clipUploadRequested: 'Se solicitó la carga del clip. Vuelva a consultar más tarde para ver el clip.',
      clipUploadRequestedWarning: 'Se ha solicitado el clip. Esto puede tardar hasta dos minutos. Por favor, no actualice la página.',
      errorDeleting: 'Error al borrar.',
      noLongerCenteredAddress: 'Su punto de referencia ya no está centrado en la dirección anterior.',
      noAssociatedAddress: 'Este hito no tiene una dirección asociada. Agregue esto en el editor de puntos de referencia.',
      issueCreatingDestination: 'Hubo un problema al crear el destino.',
      destinationCreatedSuccessfully: 'Destino creado con éxito.',

      // speed: (speed, test) => speed + ' mph',
      moving: (speed, date, time, fullAddress) => {
        return 'Moving ' + speed + ' ' + this.speed + ' at ' + date + ' ' + time + ' on ' + fullAddress
      },
      stopped: (stoppedMinutes, fullAddress) => {
        return 'Stopped for ' + stoppedMinutes + ' at ' + fullAddress
      }
    }
    this.init()
  }

  init = () => { }

}

export default new SP();
