import React, { Component } from 'react'
import PropTypes from 'prop-types'
import helper from '@helper'
import Reports from '@page/main/organisms/reportsView'
import FullScreenContainer from './fullScreen.container'

class ReportsContainer extends Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    selectModal: PropTypes.func,
    devices: PropTypes.array,
    landmarks: PropTypes.array,
    landmarksLoading: PropTypes.bool,
    /** @Main */
    mainNav: PropTypes.string,
  }

  static defaultProps = {
    devices: null,
    landmarks: null,
    landmarksLoading: false,
    selectModal: null,
    mainNav: 'reports',
  }

  render() {
    const {
      wMatrix, devices, landmarks, landmarksLoading, selectModal, mainNav,
    } = this.props

    return (
      <FullScreenContainer
        heading={wMatrix('reports')}
        devices={devices}
      >
        <Reports
          mainNav={mainNav}
          devices={devices}
          landmarks={landmarks}
          landmarksLoading={landmarksLoading}
          selectModal={selectModal}
        />
      </FullScreenContainer>
    )
  }
}

export default helper()(ReportsContainer)
