import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Menu, Input } from 'antd'
import helper from '@helper'

const { SubMenu } = Menu

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    flexShrink: '0',
  },
  searchCreateContainer: {
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#F9F9F9',
    width: '100%',
    padding: '10px 5px 5px 5px',
  },
  menuContainer: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}

class FullScreenMenu extends Component {
  static propTypes = {
    menuItems: PropTypes.array.isRequired,
    wMatrix: PropTypes.func.isRequired,
    onInputChange: PropTypes.func,
    selectItem: PropTypes.func,
    renderItem: PropTypes.func,
    handleCreate: PropTypes.func,
    noSubIndent: PropTypes.bool,
    selectedKey: PropTypes.string,
    postHeader: PropTypes.object,
    searchPlaceholder: PropTypes.string,
  }

  static defaultProps = {
    selectItem: null,
    onInputChange: null,
    renderItem: null,
    handleCreate: null,
    noSubIndent: null,
    selectedKey: null,
    postHeader: null,
    searchPlaceholder: 'Search',
  }

  state = {
    selectedKeyState: [],
  }

  componentDidUpdate = (prevProps) => {
    const { selectedKey } = this.props
    // handle deselection if key is passed by props
    if (prevProps.selectedKey && !selectedKey) {
      this.deselectItem()
    }
  }

  deselectItem = () => {
    this.setState({ selectedKeyState: [] })
  }

  /**
   * Handles menu item click
   */
  handleClick = (e) => {
    const { selectItem } = this.props
    const { key } = e
    if (selectItem) {
      selectItem(key)
    }
    // Keep internal state in case selectedKey is not passed by props
    this.setState({ selectedKeyState: [key] })
  }

  /**
   * Creates the header based on title and icon
   * @argument {string} title - Title of menu group
   * @argument {object} icon - icon information of form
   * {
   *  type: ('antd' || 'file'),
   *  name: ('antd icon type' || null),
   *  src: (null || img src)
   * }
   */
  // icon object should be in form
  // { type: ('antd' || 'file'), name: ('antd icon type' || filename)}
  returnSubMenuHeader = title => (
    <span>
      <h3 style={{ margin: 0 }}>{title}</h3>
    </span>
  )


  renderMenu = () => {
    const {
      menuItems, renderItem, noSubIndent, selectedKey,
    } = this.props
    const { selectedKeyState } = this.state
    // clone reportMenu so we dont alter it
    const menuItemsClone = []
    const defaultOpenKeys = []
    for (let i = 0; i < menuItems.length; i += 1) {
      menuItemsClone.push(menuItems[i])
      defaultOpenKeys.push(menuItems[i].id)
    }
    // create menu
    return (
      <Menu
        selectedKeys={selectedKey ? [selectedKey] : selectedKeyState}
        onClick={this.handleClick}
        defaultSelectedKeys={[]}
        defaultOpenKeys={defaultOpenKeys}
        mode="inline"
        style={{ minWidth: 200 }}
      >
        {menuItemsClone.map(group => (
          <SubMenu
            key={group.id}
            title={
              this.returnSubMenuHeader(group.title)
            }
          >
            {group.items ? group.items.map(item => (
              <Menu.Item
                className={noSubIndent ? 'noPaddingMenuItem' : undefined}
                key={item.id}
                style={{ height: '100%' }}
              >
                {item.title || renderItem(item)}
              </Menu.Item>
            )) : null}
          </SubMenu>
        ))}
      </Menu>

    )
  }

  onInputChange = (e) => {
    const { onInputChange } = this.props
    if (onInputChange) {
      onInputChange(e.target.value)
    }
  }

  render() {
    const {
      wMatrix, handleCreate, postHeader, searchPlaceholder,
    } = this.props
    return (
      <div style={styles.container}>
        <div style={styles.searchCreateContainer}>
          {!handleCreate ? null : (
            <Button type="primary" onClick={handleCreate} style={{ marginBottom: '10px' }}>
              {wMatrix('create')}
            </Button>
          )}
          <Input onChange={this.onInputChange} placeholder={searchPlaceholder} />
          {postHeader || null}
        </div>
        <div style={styles.menuContainer} className="fullScreenMenu">
          {this.renderMenu()}
        </div>

      </div>
    )
  }
}

export default helper()(FullScreenMenu)
