import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Divider } from 'antd'
import StepCircles from '@mol/stepCircles'


export default class ModalHeader extends Component {
    static propTypes = {
      headerName: PropTypes.string,
      totalSteps: PropTypes.number,
      showSteps: PropTypes.bool,
      stepNum: PropTypes.number,
      description: PropTypes.string,
      width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }

    static defaultProps = {
      totalSteps: 3,
      showSteps: false,
      stepNum: 0,
      headerName: null,
      description: null,
      width: '575px',
    }

    render() {
      const {
        headerName, stepNum, showSteps, totalSteps, description, width,
      } = this.props
      return (
        <div style={{ width }}>
          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            <h2 style={{ float: 'left', fontWeight: '400' }}>{headerName}</h2>
            {showSteps
              ? (
                <StepCircles
                  stepNum={stepNum}
                  show={showSteps}
                  totalSteps={totalSteps}
                />
              ) : null
              }
          </div>
          <Divider style={{ top: '-25px' }} />
          <p style={{ marginTop: '-35px' }}>{description}</p>
        </div>
      )
    }
}
