import PropTypes from 'prop-types'
import React, { Component } from 'react'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    height: '100%',
    color: '#2b303b',
    backgroundColor: '#FFF',
    padding: 10,
    overflow: 'hidden',
    position: 'relative',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  contentContainer: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    borderColor: '#97979740',
    borderRadius: '2px',
    borderWidth: '1px',
    borderStyle: 'solid',
    backgroundColor: '#FFF',
  },
}


/**
 * Reusable FullScreen Container
 *
 * Simply consists of a header and content container.
 *
 * Props:
 * @property {string} heading - The title of the page.
 * @property {object || array} children - The children or contents
 * @property {string} backgroundColor - Optional backgroundColor override
 * Reusable Right Container
 *
 * Simply consists of a header and content container.
 *
 * Props:
 * @property {string} heading - The title of the page.
 * @property {object || array} children - The children or contents
 *
 */
class FullScreenContainer extends Component {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    filled: PropTypes.bool,
  }

  static defaultProps = {
    children: null,
    filled: false,
  }

  /**
   * Renders the header
   */
  renderHeader = () => {
    const { heading } = this.props
    return (
      <h1 style={{ marginBottom: 10, fontWeight: 'normal', fontSize: '24px' }}>
        {heading}
      </h1>
    )
  }

  render() {
    const { children, filled } = this.props
    return (
      <div style={styles.container}>
        {this.renderHeader()}
        <div style={{ ...styles.contentContainer, backgroundColor: filled ? '#E6E6E6' : '#FFF' }}>
          {children}
        </div>
      </div>
    )
  }
}

export default FullScreenContainer
