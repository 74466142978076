import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Icon, Input, Button, Checkbox,
} from 'antd'
import ButtonWrapper from '@atom/textButton'
import addLandmark from '@assets/img/icons/addLandmark.svg'
import cancelAddLandmark from '@assets/img/icons/cancelAddLandmark.svg'
import helper from '@helper'

const styles = {
  animation: {
    transition: 'all 0.5s ease-out',
  },
  menuItemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  failIcon: {
    color: '#F5222E',
    borderRadius: '50%',
    marginLeft: '2px',
  },
  successIcon: {
    color: '#52C41B',
    borderRadius: '50%',
    marginLeft: '2px',
  },
}

/**
 * This is an early version of a marker menu item and can only handle
 * the landmark quick-add item. This could potentially handle many types,
 * or we can make individual one-off menu items. tbd
 */

class MarkerMenuItem extends Component {
  static propTypes = {
    clickable: PropTypes.bool,
    type: PropTypes.string,
    iconDiameter: PropTypes.number,
    wMatrix: PropTypes.func,
    // specific to landmark button
    lat: PropTypes.number,
    lng: PropTypes.number,
    createLandmark: PropTypes.func,
    onItemOpen: PropTypes.func,
    onItemClose: PropTypes.func,
  }

  static defaultProps = {
    clickable: false,
    type: 'quickLandmark',
    iconDiameter: 30,
    wMatrix: null,
    // specific to landmark button
    lat: null,
    lng: null,
    createLandmark: null,
    onItemOpen: null,
    onItemClose: null,
  }

  state = {
    loading: false,
    open: false,
    inputValue: '',
    feedbackMessage: null,
    billable: true,
  }

  /**
   * Toggles the quick add landmark item in marker menu
   */
  openToggle = () => {
    const { onItemOpen, onItemClose } = this.props
    const { open } = this.state
    if (open) {
      this.setState({ inputValue: '' })
      this.setState({ open: !open })
      if (onItemClose) { onItemClose() }
    } else {
      this.setState({ open: !open })
      if (onItemOpen) { onItemOpen() }
    }
  }

  /**
   * Calls landmark create and defaults to circle with radius 600m.
   * This also handles the promise's response by showing loading
   * followed by a success or fail icon when promise is resolved. Then timeouts after 2 seconds
   * and resets the menu.
   */
  saveLandmark = () => {
    const { inputValue, billable } = this.state
    const {
      lat, lng, createLandmark, onItemClose,
    } = this.props
    // prop check
    if (lat && lng && createLandmark) {
      // set save button to loading state
      this.setState({ loading: true })

      // default to 600m radius (.372mi)
      createLandmark('circle', inputValue, [{ lat, lng }], 600, billable).then((response) => {
        if (response === 1000) {
          // success
          this.setState({
            feedbackMessage: 'success',
          })
        } else {
          // fail
          this.setState({
            feedbackMessage: 'fail',
          })
        }
      }).finally(() => {
        // wait two seconds then close and reset menu
        setTimeout(() => {
          this.setState({
            loading: false,
            open: false,
            inputValue: '',
            feedbackMessage: null,
          })

          if (onItemClose) { onItemClose() }
        }, 2000)
      })
    }
  }

  /**
   * Update state of landmark name input
   */
  onNameChange = (e) => {
    this.setState({ inputValue: e.target.value })
  }

  /**
   * Update state of landmark billable
   */
  onBillableChange = (e) => {
    this.setState({ billable: e.target.checked })
  }

  /**
   * Returns feedback icon ( fail x or success check )
   */
  returnFeedbackMessage = () => {
    const { feedbackMessage } = this.state
    const { iconDiameter } = this.props
    if (feedbackMessage === 'success') {
      return (
        <Icon
          type="check-circle"
          theme="filled"
          style={{
            ...styles.successIcon,
            fontSize: iconDiameter - 5,
            marginRight: (iconDiameter / 2) + 2,
            display: feedbackMessage === 'success' ? 'block' : 'none',
          }}
        />
      )
    }
    if (feedbackMessage === 'fail') {
      return (
        <Icon
          type="close-circle"
          theme="filled"
          style={{
            ...styles.failIcon,
            fontSize: iconDiameter - 5,
            marginRight: (iconDiameter / 2) + 2,
            display: feedbackMessage === 'success' ? 'block' : 'none',
          }}
        />
      )
    }
    return null
  }

  /**
   * returns quick add landmark menu item
   */
  landmarkMenuItem = () => {
    const {
      inputValue, loading, open,
    } = this.state
    const { clickable, wMatrix, iconDiameter } = this.props

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: open ? '#FFFFFFC0' : undefined,
          borderRadius: iconDiameter / 2,
          minWidth: open ? 300 : iconDiameter,
          padding: 3,
          ...styles.animation,
        }}
      >
        <div style={styles.menuItemWrapper}>
          <ButtonWrapper
            useAsWrapper
            onClick={clickable ? () => this.openToggle() : () => null}
            style={{
              margin: '0px 0.5px',
            }}
          >
            <img src={open ? cancelAddLandmark : addLandmark} alt="Add Landmark" style={{ ...styles.animation }} />
          </ButtonWrapper>
          <div style={{
            ...styles.animation,
            display: open ? 'flex' : 'none',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexGrow: 1,
          }}
          >
            <Input
              size="small"
              style={{ marginLeft: '4px', marginRight: '4px', width: 190 }}
              onChange={e => this.onNameChange(e)}
              value={inputValue}
              placeholder={wMatrix('LandmarkName')}
            />
            <Checkbox
              style={{ flexShrink: 0, marginLeft: '4px', marginRight: '4px' }}
              onChange={this.onBillableChange}
              defaultChecked
            >
              {wMatrix('billable')}
            </Checkbox>
            <Button
              onClick={() => this.saveLandmark()}
              type="primary"
              size="small"
              loading={loading}
              style={{ marginLeft: '4px', marginRight: iconDiameter / 2 }}
              disabled={!inputValue || inputValue.length === 0 || loading}
            >
              {wMatrix('Save')}
            </Button>
            {this.returnFeedbackMessage()}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { type } = this.props
    if (type === 'quickLandmark') {
      return this.landmarkMenuItem()
    }
    return null
  }
}


export default helper()(MarkerMenuItem)
