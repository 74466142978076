import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Tabs } from 'antd'
import ContactUs from '@mol/modal/contactUs'
import SubmitTicket from '@mol/modal/submitTicket'
import Guides from '@mol/modal/guides'
import UserResourcesHOC from '@hoc/userResources.hoc'

class UserResources extends React.Component {
  static propTypes = {
    ifShowModal: PropTypes.bool.isRequired,
    wMatrix: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    handleCreateTicket: PropTypes.func.isRequired,
  }

  render() {
    const { TabPane } = Tabs
    const {
      ifShowModal, closeModal, handleCreateTicket, wMatrix,
    } = this.props

    return (
      <>
        <Modal
          visible={ifShowModal}
          onCancel={closeModal}
          footer={null}
          width={1024}
          zIndex={10}
          maskClosable={false}
        >
          <h1>{wMatrix('userResources')}</h1>
          <Tabs defaultActiveKey="1" onChange={this.switchAdminPanel}>
            <TabPane tab={wMatrix('support')} key="1">
              <ContactUs wMatrix={wMatrix} />
              {
                /*
              <SubmitTicket
                wMatrix={wMatrix}
                handleCreateTicket={handleCreateTicket}
              /> */
              }
            </TabPane>
            <TabPane tab={wMatrix('guides')} key="2">
              <Guides wMatrix={wMatrix} />
            </TabPane>
          </Tabs>
        </Modal>
      </>
    )
  }
}

export default UserResourcesHOC()(UserResources)
