import React, { Component } from 'react'
import { Table } from 'antd'
import Card from '@atom/card'
import ServiceTicket from '@mol/service/ticket'
import moment from 'moment'
import PropTypes from 'prop-types'

const styles = {
  detailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  upperArea: {
    flex: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    marginTop: -5,
  },
  lowerArea: {
    flex: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: -5,
  },
  lowerHalf: {
    flex: 1,
    flexShrink: 0,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}

export default class ServiceDetails extends Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    nav: PropTypes.string.isRequired,
    selectedTicket: PropTypes.object.isRequired,
    handleSelectTicket: PropTypes.func.isRequired,
    historyTickets: PropTypes.array.isRequired,
    relatedTickets: PropTypes.array.isRequired,
    types: PropTypes.array.isRequired,
    valueTypes: PropTypes.array.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleDashboard: PropTypes.func.isRequired,
    showAdminEdit: PropTypes.bool.isRequired,
    getMeasurement: PropTypes.func.isRequired,
  }

  renderHistory = () => {
    const { wMatrix, historyTickets, handleSelectTicket } = this.props
    const columns = [
      {
        title: `${wMatrix('ticketNumber')}`,
        dataIndex: 'id',
      },
      {
        title: `${wMatrix('Completed')}`,
        dataIndex: 'completion',
        render: (item) => {
          if (item) {
            const date = item.date ? moment(item.date).format('YYYY-MM-DD') : null
            return (
              <div>
                <div>{date}</div>
              </div>
            )
          }
          return null
        },
      },
      {
        title: `${wMatrix('completedBy')}`,
        dataIndex: 'completion.contact.name',
      },
      {
        title: `${wMatrix('actualCost')}`,
        dataIndex: 'cost.actual',
        render: cost => (<div>{cost ? `$${cost}` : ''}</div>),
      },
    ]
    return (
      <Card
        title={wMatrix('history')}
        contentWrapper={{
          overflow: 'auto',
        }}
      >
        <Table
          rowKey="id"
          onRow={record => ({
            onClick: () => { handleSelectTicket(record.id) },
          })}
          dataSource={historyTickets}
          columns={columns}
        />
      </Card>
    )
  }

  renderRelated = () => {
    const { wMatrix, relatedTickets, handleSelectTicket } = this.props
    const columns = [
      {
        title: `${wMatrix('ticketNumber')}`,
        dataIndex: 'id',
      },
      {
        title: `${wMatrix('status')}`,
        dataIndex: 'details.status',
        render: status => (<div>{wMatrix(status)}</div>),
      },
      {
        title: `${wMatrix('type')}`,
        dataIndex: 'type.name',
      },
      {
        title: `${wMatrix('actualCost')}`,
        dataIndex: 'cost.actual',
        render: cost => (<div>{cost ? `$${cost}` : ''}</div>),
      },
    ]

    return (
      <Card
        title={wMatrix('moreFromThisVehicle')}
        contentWrapper={{
          overflow: 'auto',
        }}
        style={{ marginLeft: -5 }}
      >
        <Table
          rowKey="id"
          onRow={record => ({
            onClick: () => { handleSelectTicket(record.id) },
          })}
          dataSource={relatedTickets}
          columns={columns}
        />
      </Card>
    )
  }

  render() {
    const {
      wMatrix, nav, selectedTicket, types, valueTypes, handleEdit, handleDelete, handleDashboard,
      showAdminEdit, getMeasurement,
    } = this.props
    return (
      <div style={styles.detailsWrapper}>
        <div style={styles.upperArea}>
          <ServiceTicket
            wMatrix={wMatrix}
            nav={nav}
            ticket={selectedTicket}
            alias={selectedTicket.alias}
            vehicle={selectedTicket.vehicle}
            types={types}
            valueTypes={valueTypes}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleDashboard={handleDashboard}
            showAdminEdit={showAdminEdit}
            getMeasurement={getMeasurement}
          />
        </div>
        <div style={styles.lowerArea}>
          <div style={styles.lowerHalf}>{this.renderHistory()}</div>
          <div style={{ ...styles.lowerHalf, marginLeft: -5 }}>
            {this.renderRelated()}
          </div>
        </div>
      </div>
    )
  }
}
