import React from 'react'
import PropTypes from 'prop-types'
import { Select, Table } from 'antd'
import SkeletonComponent from '@atom/skeletonComponents'
import helper from '../../helper'

class EngineHoursReport extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    minutesToString: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    generatedData: PropTypes.array.isRequired,
    reportLoading: PropTypes.bool.isRequired,
  }

  state = { groupBy: 'vehicle' }

  renderFooter = (durationMinutes) => {
    const { minutesToString } = this.props
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold' }}>
        {`Subtotal: ${minutesToString(durationMinutes, null, true, 'hours')}`}
        <div style={{ width: '15%' }} />
      </div>
    )
  }

  groupByDate = (data) => {
    const { columns, minutesToString } = this.props
    data.sort((a, b) => {
      if (a.date > b.date) return 1
      if (a.date < b.date) return -1
      return 0
    })
    const dates = new Map()
    for (let i = 0; i < data.length; i += 1) {
      if (dates.has(data[i].date)) {
        dates.get(data[i].date).push(data[i])
      } else {
        dates.set(data[i].date, [data[i]])
      }
    }

    const groupedData = []
    let rangeTotal = 0
    for (const [k, v] of dates) {
      let dateTotal = 0
      for (const j of v) {
        dateTotal += j.duration
        rangeTotal += j.duration
      }
      groupedData.push(
        <div key={k} style={{ margin: '0 0 20px 0' }}>
          <h4>{k}</h4>
          <Table
            columns={columns}
            dataSource={v}
            pagination={false}
            rowKey="key"
            footer={() => this.renderFooter(dateTotal)}
          />
        </div>,
      )
    }

    return (
      <div>
        {groupedData}
        <div style={{
          display: 'flex',
          alignItems: 'middle',
          fontWeight: 'bold',
          fontSize: '16px',
          margin: '10px 0 10px 0',
        }}
        >
          <div style={{ width: '75%' }} />
          {`Total: ${minutesToString(rangeTotal, null, true, 'hours')}`}
        </div>
      </div>
    )
  }

  groupByVehicle = (data) => {
    const { columns, minutesToString } = this.props
    const vehicles = new Map()
    for (let i = 0; i < data.length; i += 1) {
      if (vehicles.has(data[i].deviceId)) {
        vehicles.get(data[i].deviceId).push(data[i])
      } else {
        vehicles.set(data[i].deviceId, [data[i]])
      }
    }

    const groupedData = []
    let rangeTotal = 0

    for (const [k, v] of vehicles) {
      let vehicleTotal = 0
      for (const j of v) {
        vehicleTotal += j.duration
        rangeTotal += j.duration
      }
      groupedData.push(
        {
          deviceId: k,
          alias: v[0].alias,
          date: `${v[0].date} - ${v[v.length - 1].date}`,
          duration: vehicleTotal,
        },
      )
    }

    return (
      <div>
        <div style={{ margin: '0 0 20px 0' }}>
          <Table
            columns={columns}
            dataSource={groupedData}
            pagination={false}
            rowKey="deviceId"
          />
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'middle',
          fontWeight: 'bold',
          fontSize: '16px',
          margin: '10px 0 10px 0',
        }}
        >
          <div style={{ width: '75%' }} />
          {`Total: ${minutesToString(rangeTotal, null, true, 'hours')}`}
        </div>
      </div>
    )
  }

  handleGroupSelect = (value) => {
    const { groupBy } = this.state
    if (groupBy !== value) {
      this.setState({ groupBy: value })
    }
  }

  renderGroupSelect() {
    const { wMatrix, reportLoading } = this.props
    return (
      <div style={{ margin: '15px 0 15px 0' }}>
        <h4>{wMatrix('groupBy')}</h4>
        <Select
          style={{ width: '100px' }}
          defaultValue="vehicle"
          onChange={this.handleGroupSelect}
          disabled={reportLoading}
        >
          <Select.Option
            key="vehicle"
            value="vehicle"
          >
            {wMatrix('Vehicle')}
          </Select.Option>
          <Select.Option
            key="date"
            value="date"
          >
            {wMatrix('Date')}
          </Select.Option>
        </Select>
      </div>
    )
  }

  render() {
    const { generatedData, reportLoading } = this.props
    const { groupBy } = this.state
    let engineHoursTable = this.groupByDate(generatedData)
    if (groupBy === 'vehicle') engineHoursTable = this.groupByVehicle(generatedData)
    return generatedData.length > 0 ? (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {this.renderGroupSelect()}
        { reportLoading
          ? <SkeletonComponent type="table" />
          : engineHoursTable
        }
      </div>
    ) : null
  }
}

export default helper()(EngineHoursReport)
