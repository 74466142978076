import React, { Component } from 'react'
import { Menu, Dropdown, Icon } from 'antd'
import Tag from '@atom/tag'
import PropTypes from 'prop-types'
import GroupOfGroups from '@mol/groupOfGroups'

export default class GroupDropDown extends Component {
    static propTypes = {
      groups: PropTypes.array,
      cozy: PropTypes.bool,
      isEditable: PropTypes.bool,
      allGroups: PropTypes.array,
      currentlyEditingRole: PropTypes.number,
      // show: PropTypes.bool,
    }

    static defaultProps = {
      groups: [],
      cozy: true,
      isEditable: false,
      allGroups: [],
      currentlyEditingRole: null,
      // show: true,
    }

    returnMenuItems = groups => groups.map(group => (
      <Menu.Item key={group.id}>
        <div style={{ display: 'flex' }}>
          <Tag
            id={group.id}
            name={group.name}
            color={group.color}
          />
        </div>
      </Menu.Item>
    ))

    returnMenu = groups => (
      <Menu>
        {this.returnMenuItems(groups)}
      </Menu>
    )

    render() {
      const {
        groups, isEditable, cozy, allGroups, currentlyEditingRole,
      } = this.props
      let groupsToShow = []
      if (!!currentlyEditingRole && currentlyEditingRole < 3) {
        groupsToShow = allGroups
      } else {
        groupsToShow = groups
      }
      const groupMenu = this.returnMenu(groupsToShow)
      return (
        <Dropdown overlay={groupMenu} trigger={['click']} placement="bottomRight">
          <div style={{
            display: 'flex', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center',
          }}
          >
            <GroupOfGroups
              groups={groupsToShow}
              isEditable={isEditable}
              size={cozy ? 'small' : 'large'}
            />
            <Icon type="down" style={{ marginLeft: '5px' }} />
          </div>
        </Dropdown>
      )
    }
}
