import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Alert, Breadcrumb, Button, Modal, Progress,
} from 'antd'
import TechnicianForm from '@atom/service/technicianForm'
import AdminForm from '@atom/service/form'
import Card from '@atom/card'

const { Item } = Breadcrumb

const colors = ['#4482FF', '#FF5500', '#FFBA44', '#87D068']

const styles = {
  ticketWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  contentContainer: {
    display: 'flex',
    alignSelf: 'stretch',
    flexGrow: '1',
  },
  dot: {
    height: '5px',
    width: '5px',
    backgroundColor: 'grey',
    flexShrink: 0,
    borderRadius: '50%',
    margin: '0 5px 0 5px',
  },
  greyText: {
    color: 'grey',
    margin: '0 5px 0 5px',
  },
  leftPanel: {
    flex: 1,
    borderRight: 'solid 5px #E6E6E6',
    alignSelf: 'stretch',
    paddingRight: 10,
    marginRight: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  vehicleContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 60,
  },
  vehicleRow: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  rightPanel: {
    flex: 2,
    alignSelf: 'stretch',
  },
  modalRow: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '10px',
  },
  progress: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

export default class ServiceTicket extends Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    nav: PropTypes.string.isRequired,
    types: PropTypes.array.isRequired,
    valueTypes: PropTypes.array.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleDashboard: PropTypes.func.isRequired,
    showAdminEdit: PropTypes.bool.isRequired,
    ticket: PropTypes.object,
    getMeasurement: PropTypes.func.isRequired,
  }

  static defaultProps = {
    ticket: null,
  }

  state = {
    adminForm: false,
    deleteModal: false,
    deleteAlert: {
      display: 'none',
      message: '',
      type: 'success',
    },
  }

  handleDeleteModal = () => {
    const { deleteModal } = this.state
    this.setState({ deleteModal: !deleteModal })
  }

  executeDelete = async () => {
    const { handleDelete, handleDashboard, ticket } = this.props
    try {
      await handleDelete(ticket.id)
      handleDashboard()
    } catch (e) {
      const error = {
        display: 'block',
        message: 'Something went wrong',
        type: 'error',
      }
      this.setState({ deleteAlert: error })
    }
  }

  /**
   * @private
   * @returns {Component}
   * @description renders the left panel of the ticket. This should flex based on its contents
   */
  renderLeftPanel = () => {
    const { ticket } = this.props
    const { alias, vehicle } = ticket
    if (vehicle && alias) {
      return (
        <div style={styles.vehicleContainer}>
          <h1 style={{ margin: 0 }}>{alias}</h1>
          <div style={styles.vehicleRow}>
            {vehicle.vin ? <h4 style={styles.greyText}>{vehicle.vin}</h4> : null}
            {vehicle.vin && vehicle.license ? <div style={styles.dot} /> : null}
            {vehicle.license ? <h4 style={styles.greyText}>{vehicle.license}</h4> : null}
          </div>
          <div style={styles.vehicleRow}>
            {vehicle.make || vehicle.model
              ? (
                <h4 style={styles.greyText}>
                  {`${vehicle.make && vehicle.make !== 'Unspecified' ? vehicle.make : ''} ${vehicle.model ? vehicle.model : ''}`}
                </h4>
              ) : null}
            {(vehicle.make || vehicle.model) && vehicle.color ? <div style={styles.dot} /> : null}
            {vehicle.color ? <h4 style={styles.greyText}>{vehicle.color}</h4> : null}
          </div>
        </div>
      )
    }
    /**
     * @NOTE - This is where you would put the code for the device selection on create
     */
    return 'create'
  }

  /**
   * @private
   * @returns {Component}
   * @description renders the right panel of the ticket. This should flex based on its contents
   */
  renderRightPanel = () => {
    const {
      wMatrix, nav, ticket, types, valueTypes, handleEdit, showAdminEdit, getMeasurement,
    } = this.props
    const { adminForm } = this.state
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', minWidth: '500px',
      }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {showAdminEdit && !adminForm ? (
            <div style={{ display: 'flex' }}>
              <Button
                style={{ width: '100px' }}
                onClick={() => this.setState({ adminForm: !adminForm })}
                type="primary"
              >
                {wMatrix('edit')}
              </Button>
            </div>
          ) : null}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <div style={{ display: 'flex' }}>
            {adminForm ? (
              <AdminForm
                wMatrix={wMatrix}
                nav={nav}
                selectedDevices={[]}
                types={types}
                valueTypes={valueTypes}
                handleCancel={() => this.setState({ adminForm: false })}
                handleEdit={handleEdit}
                existingTicket={ticket}
                getMeasurement={getMeasurement}
              />
            ) : (
              <TechnicianForm
                wMatrix={wMatrix}
                handleEdit={handleEdit}
                existingTicket={ticket}
                getMeasurement={getMeasurement}
              />
            )}
          </div>
          <div style={{ display: 'flex' }}>
            {this.renderDueProgress()}
          </div>
        </div>
        {adminForm ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button
              style={{ width: '100px', margin: '0 10px 0 0' }}
              onClick={this.handleDeleteModal}
              type="danger"
            >
              {wMatrix('Delete')}
            </Button>
          </div>
        ) : null}
      </div>
    )
  }

  renderDeleteModal = () => {
    const { wMatrix } = this.props
    const { deleteModal, deleteAlert } = this.state
    return (
      <>
        <Modal
          visible={deleteModal}
          onCancel={this.handleDeleteModal}
          footer={null}
          width={500}
          zIndex={4}
          closable={false}
        >
          <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            <div style={styles.modalRow}>
              <h2>{wMatrix('areYouSure')}</h2>
            </div>
            <div style={styles.modalRow}>
              <Button onClick={this.handleDeleteModal} type="default">
                {wMatrix('Cancel')}
              </Button>
              <div style={{ width: '30%' }} />
              <Button onClick={this.executeDelete} type="danger">
                {wMatrix('Delete')}
              </Button>
            </div>
            <div style={styles.modalRow}>
              <Alert
                style={{
                  display: deleteAlert.display,
                  width: '275px',
                  margin: '0px 10px',
                }}
                closable
                showIcon
                message={deleteAlert.message}
                type={deleteAlert.type}
              />
            </div>
          </div>
        </Modal>
      </>
    )
  }

  renderDueProgress = () => {
    const { wMatrix, ticket, getMeasurement } = this.props
    const dueLabel = () => {
      if (ticket.due.type !== 'Miles') {
        return ticket.due.type
      }

      return getMeasurement() === 'k' ? 'KM' : 'Miles'
    }
    if (ticket.details.status === 'completed') {
      return (
        <div style={styles.progress}>
          <Progress
            type="dashboard"
            percent={100}
            strokeColor={colors[3]}
            strokeWidth={6}
            width={180}
            gapDegree={90}
          />
          <h2>{wMatrix('complete')}</h2>
        </div>
      )
    }

    if (ticket.details.status === 'overdue') {
      let dueToValue = ticket.due.value - ticket.details.currentValue
      if (dueToValue % 1 !== 0) {
        dueToValue = dueToValue.toFixed(2)
      }
      return (
        <div style={styles.progress}>
          <Progress
            type="dashboard"
            percent={100 * ((ticket.details.currentValue - ticket.due.value) / ticket.due.value)}
            strokeColor={colors[1]}
            strokeWidth={6}
            width={180}
            gapDegree={90}
            format={() => (
              <div style={{
                fontSize: '25px', color: colors[1], paddingLeft: 22, paddingRight: 22,
              }}
              >
                {`${dueToValue} ${dueLabel()}`}
              </div>
            )}
          />
          <h2>{wMatrix('Overdue')}</h2>
        </div>
      )
    }

    if (ticket.details.status === 'upcoming') {
      return (
        <div style={styles.progress}>
          <Progress
            type="dashboard"
            percent={100 * ticket.details.currentValue / ticket.due.value}
            strokeColor={colors[2]}
            strokeWidth={6}
            width={180}
            gapDegree={90}
            format={() => (
              <div style={{
                fontSize: '25px', color: colors[2], paddingLeft: 22, paddingRight: 22,
              }}
              >
                {`${(ticket.details.currentValue)} ${dueLabel()}`}
              </div>
            )}
          />
          <h2>{wMatrix('Upcoming')}</h2>
        </div>
      )
    }

    return (
      <div style={styles.progress}>
        <Progress
          type="dashboard"
          percent={100 * ticket.details.currentValue / ticket.due.value}
          strokeColor={colors[0]}
          strokeWidth={6}
          width={180}
          gapDegree={90}
          format={() => (
            <div style={{
              fontSize: '25px', color: colors[0], paddingLeft: 22, paddingRight: 22,
            }}
            >
              {`${(ticket.details.currentValue)} ${dueLabel()}`}
            </div>
          )}
        />
        <h2>{wMatrix('Active')}</h2>
      </div>
    )
  }

  render() {
    const { wMatrix, ticket } = this.props
    return (
      <Card>
        <div style={styles.ticketWrapper}>
          <Breadcrumb style={{ alignSelf: 'flex-start' }}>
            <Item>{wMatrix('service')}</Item>
            <Item>{`#${ticket.id}`}</Item>
          </Breadcrumb>
          <div style={styles.contentContainer}>
            <div style={styles.leftPanel}>
              {this.renderLeftPanel()}
            </div>
            <div style={styles.rightPanel}>
              {this.renderRightPanel()}
            </div>
          </div>
          {this.renderDeleteModal()}
        </div>
      </Card>
    )
  }
}
