import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button, Icon } from 'antd'
import TextButton from '@atom/textButton'


const Google = window.google

const styles = {
  container: {
    display: 'flex',
    width: '100%',
  },
  searchBtn: {
    height: 35,
    borderTopRight: '4px',
    borderBottomRight: '4px',
    borderTopLeft: '0px',
    borderBottomLeft: '0px',
  },
}

class addressAutoComplete extends Component {
  autoCompleteInput = React.createRef()

  autoComplete = null

  autoCompleteService = null

  geoCoder = null

  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    style: PropTypes.object,
    onSelect: PropTypes.func,
    onButtonClick: PropTypes.func,
  }

  static defaultProps = {
    style: {},
    onSelect: null,
    onButtonClick: null,
  }

  state = {
    address: '',
    ifUsePrediction: true,
    coordinate: null,
  }


  /**
   * Attaches Google api to input element, as well as instantiates the other Google APIs to be used.
   */
  componentDidMount() {
    this.autoComplete = new Google.maps.places.Autocomplete(
      this.autoCompleteInput.current,
      { types: ['geocode'] },
    )
    this.autoCompleteService = new Google.maps.places.AutocompleteService()
    this.autoComplete.addListener('place_changed', this.selectAddress)
    this.geoCoder = new Google.maps.Geocoder()
  }

  /**
   * Update state of address value. Runs on input change of input box.
   * @param {Event} e event of input change
   */
  changeAddress = (e) => {
    this.setState({ address: e.target.value, ifUsePrediction: true })
  }

  /**
   * Runs select of address from google's dropdown on press of enter key.
   * @param {Event} e Keypress event
   */
  pressEnterOnAddressBox = async (e) => {
    const { onSelect } = this.props
    if (e.key === 'Enter') {
      this.setState({ ifUsePrediction: true })
      const confirmedLocation = await this.confirmAddress()
      if (onSelect) {
        onSelect(confirmedLocation.coordinate, confirmedLocation.address)
      }
    }
  }

  /**
   * onSelect Callback for Google API Autocomplete select trigger. This occurs when
   * the place is updated, but we only use it when the place updates due to selection from the
   * google api menu.
   */
  selectAddress = () => {
    const { onSelect } = this.props
    const place = this.autoComplete.getPlace()
    /**
     * if the user selects an address from the dropdown then no need
     * to use the prediction
     */
    let latlng = null
    /**
     * we try to get the lat lng from the select,
     * if we can't, then we just grab the address,
     * we will get the lat&lng from the address later
     */
    if (place.geometry) {
      const lat = place.geometry.location.lat()
      const lng = place.geometry.location.lng()
      latlng = {
        lat,
        lng,
      }
      this.setState({ coordinate: latlng })
      this.setState({ address: place.formatted_address })
      this.setState({ ifUsePrediction: false })
      if (onSelect) {
        onSelect(latlng, place.formatted_address)
      }
    } else {
      // console.log('no nvalid place, if use prediction set to true')
      this.setState({ ifUsePrediction: true })
    }
  }

  /**
   * to confirm address to calculate the distance
   * if the user selects an address, then use the selected addres,
   * else use the first prediction
   * the function getPlacePredictions gives us the correct results based on the browser location
   * the function getQueryPredictions gives us the incorrect results,
   * because the results are not based on browser location
   */
  confirmAddress = async () => {
    const { ifUsePrediction, address, coordinate } = this.state
    if (ifUsePrediction) {
      const predictedAddress = await this.getPredictedAddressPromise(address)
      // Set state before geoCoord call to update input value without delay
      this.setState({ address: predictedAddress })
      const geoCoords = await this.getGeoCodePromise(predictedAddress)
      this.setState({ coordinate: geoCoords })
      return {
        address: predictedAddress,
        coordinate: geoCoords,
      }
    }
    return {
      address,
      coordinate,
    }
  }

  /**
   * Onclick for search button. Runs onButtonClick callback function if provided.
   */
  onButtonClick = async () => {
    const { onButtonClick } = this.props
    const confirmedLocation = await this.confirmAddress()
    // if callback function was passed, send the aquired location data
    if (onButtonClick) {
      onButtonClick(confirmedLocation.coordinate, confirmedLocation.address)
    }
  }

  /**
   * Uses array of google places predictions to then grab the first result and obtain it's lat/lng
   * @param {[Object]} predictions Array of places via Google API
   *
   * @deprecated since version 3.0.29
   */
  usePredictedAddress = async (predictions) => {
    /**
     * the predicted address doesn't have lat&lng from google results,
     * I have to geocode it
     */
    const { onButtonClick } = this.props
    if (predictions === null || predictions.length === 0) {
      // alert('Not a valid address')
    } else {
      /**
       * description is what the dropdown addresses look like
       */
      const address = predictions[0].description
      /**
       * the geocode function is an async function,
       * I have to call the hoc function here
       */
      this.setState({ address })
      this.geoCoder.geocode({ address }, (results) => {
        const latlng = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        }
        onButtonClick(latlng)
      })
    }
  }

  /**
   * Returns a promise that when resolved returns a validated google address string.
   * @param {String} address incomplete address to be validated and autocompleted
   * @returns Promise <String>
   */
  getPredictedAddressPromise = address => new Promise((resolve, reject) => {
    this.autoCompleteService.getPlacePredictions({ input: address },
      (predictions) => {
        if (predictions === null || predictions.length === 0) {
          reject()
        }
        resolve(predictions[0].description)
      })
  })

  /**
   * Returns a promise that when resolved, returns an object containing lat and lng.
   * @param {String} address Address to geocode for lat lng
   * @returns Promise <Object>
   */
  getGeoCodePromise = address => new Promise((resolve, reject) => {
    this.geoCoder.geocode({ address }, (results) => {
      if (results.length === 0) {
        reject()
      }
      const latlng = {
        lat: results[0].geometry.location.lat(),
        lng: results[0].geometry.location.lng(),
      }
      resolve(latlng)
    })
  })

  clearAddress = () => {
    this.setState({
      address: '',
      coordinate: null,
    })
  }

  /**
   * @private
   *
   * Shows clear button if there is an address in component state
   */
  renderClearButton = () => {
    const { wMatrix } = this.props
    const { address } = this.state

    if (address) {
      return (
        <TextButton
          style={{
            color: '#4482FF', fontStyle: 'normal', textDecoration: 'underline', textAlign: 'right', marginBottom: 0, alignSelf: 'flex-end',
          }}
          onClick={this.clearAddress}
        >
          {wMatrix('clear')}
        </TextButton>
      )
    }

    return null
  }

  render() {
    const { wMatrix, style } = this.props
    const { address } = this.state

    return (
      <span style={style} className="ant-input-search ant-input-search-enter-button ant-input-group-wrapperr">
        <span className="ant-input-wrapper ant-input-group">
          <input
            className="ant-input"
            ref={this.autoCompleteInput}
            placeholder={wMatrix('Type address to calculate')}
            onChange={(e) => { this.changeAddress(e) }}
            onKeyPress={(e) => { this.pressEnterOnAddressBox(e) }}
            value={address}
          />
          <span className="ant-input-group-addon">
            <Button
              className="ant-input-search-button"
              type="primary"
              style={styles.searchBtn}
              onClick={this.onButtonClick}
            >
              <Icon type="search" />
            </Button>
          </span>
        </span>
        {this.renderClearButton()}
      </span>
    )
  }
}

export default addressAutoComplete
