import React, { Component } from 'react'
import { Table } from 'antd'
import PropTypes from 'prop-types'
import GroupsDropDown from '@mol/groupsDropDown'
import helper from '@helper'

class UsersTable extends Component {
    static propTypes = {
      users: PropTypes.array,
      checkedUsers: PropTypes.array,
      loading: PropTypes.bool,
      updateSelectedRows: PropTypes.func.isRequired,
      isSelectable: PropTypes.bool,
      isDetailed: PropTypes.bool,
      wMatrix: PropTypes.func.isRequired,
    }

    static defaultProps = {
      users: [],
      checkedUsers: [],
      loading: false,
      isDetailed: false,
      isSelectable: true,
    }

    // rowSelection object indicates the need for row selection

    onSelectChange = (selectedRowKeys) => {
      const { updateSelectedRows } = this.props

      updateSelectedRows('users', selectedRowKeys)
    }

    renderTable = (devColumns, rowSelection) => {
      const { isSelectable, users, loading } = this.props
      if (isSelectable) {
        return (
          <div>
            <Table loading={loading} dataSource={users} columns={devColumns} rowSelection={rowSelection} rowKey="id" size="middle" />
          </div>
        )
      }

      return (
        <div>
          <Table loading={loading} dataSource={users} columns={devColumns} rowKey="id" size="middle" />
        </div>
      )
    }

    render() {
      const { isDetailed, wMatrix, checkedUsers } = this.props
      let devColumns = null
      const rowSelection = {
        selectedRowKeys: checkedUsers,
        onChange: this.onSelectChange,
      }

      if (isDetailed === false) {
        devColumns = [{
          title: wMatrix('Users'),
          dataIndex: 'firstName',
          key: 'contactId',
        }]
      } else {
        devColumns = [{
          title: wMatrix('First Name'),
          dataIndex: 'firstName',
          key: 'firstName',
        },
        {
          title: wMatrix('Last Name'),
          dataIndex: 'lastName',
          key: 'lastName',
        },
        {
          title: wMatrix('email'),
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: wMatrix('Permissions/Role'),
          dataIndex: 'role.name',
          key: 'role',
        },
        {
          title: wMatrix('Viewable Groups'),
          dataIndex: 'groups',
          render: tags => (
            <GroupsDropDown
              groups={tags}
              isEditable={false}
              cozy
            />
          ),
        }]
      }

      return (
        this.renderTable(devColumns, rowSelection)
      )
    }
}

export default helper()(UsersTable)
