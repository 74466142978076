import React, { Component } from 'react'
import FilterSection from '@atom/timeline/filterSection'
import {
  Radio, Input, Button, Popconfirm, Checkbox,
} from 'antd'
import PropTypes from 'prop-types'
import equal from 'deep-equal'
import AddressAutoComplete from '@atom/addressAutoComplete'
import Analytics from '@analytics'

const { TextArea } = Input

const styles = {
  blueHeader: {
    minHeight: '50px',
    flexShrink: '0',
    backgroundColor: '#102A96',
  },
  scrollableContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'auto',
    paddingBottom: 10,
    alignItems: 'stretch',
  },
  addressSearch: {
    width: '230px',
    marginBottom: '15px',
  },
}

export default class LandmarkEditor extends Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    landmark: PropTypes.object,
    setDrawType: PropTypes.func.isRequired,
    saveLandmark: PropTypes.func.isRequired,
    deleteLandmark: PropTypes.func.isRequired,
    zoomToPoint: PropTypes.func,
    cancelEdit: PropTypes.func,
    ifNewLandmark: PropTypes.bool,
    savingLandmark: PropTypes.bool,
    deletingLandmark: PropTypes.bool,
    selectedLandmarkCenter: PropTypes.object,
    expanded: PropTypes.bool,
    /** @Main */
    mainNav: PropTypes.string.isRequired,
  }

  static defaultProps = {
    landmark: {
      name: 'My new landmark',
      type: 'circle',
      id: null,
      center: null,
      radius: 1000,
      points: null,
      billable: true,
    },
    zoomToPoint: null,
    cancelEdit: null,
    ifNewLandmark: false,
    savingLandmark: false,
    deletingLandmark: false,
    selectedLandmarkCenter: null,
    expanded: true,
  }

  constructor(props) {
    super(props)
    const { landmark, selectedLandmarkCenter } = props
    this.state = {
      type: landmark.type,
      name: landmark.name,
      // center: landmark.center,
      id: landmark.id,
      billable: landmark.billable,
      // points: landmark.points,
      // radius: landmark.radius,
      address: landmark.destinationAddress || '',
      addressCoordinates: selectedLandmarkCenter || null,
      notes: landmark.notes,
    }
    this.setDrawType(landmark.type)
  }

  componentDidUpdate = (prevProps) => {
    const { landmark, selectedLandmarkCenter } = this.props
    if (!equal(prevProps.landmark, landmark)) {
      this.updateLandmarkState(landmark, selectedLandmarkCenter)
    }
  }

  updateLandmarkState = (landmark, landmarkCenter) => {
    this.setState({
      type: landmark.type,
      name: landmark.name,
      id: landmark.id,
      billable: landmark.billable,
      address: landmark.destinationAddress || '',
      addressCoordinates: landmarkCenter || null,
      notes: landmark.notes,
    })
    this.setDrawType(landmark.type)
  }

  saveLandmark = () => {
    const {
      type, name, id, billable, address, notes,
    } = this.state
    const { saveLandmark } = this.props
    saveLandmark(type, name, id, billable, address, notes)
  }

  deleteLandmark = () => {
    const { deleteLandmark, landmark } = this.props
    deleteLandmark(landmark.id)
  }

  typeChange = (e) => {
    this.setState({ type: e.target.value })
    this.setDrawType(e.target.value)
  }

  setDrawType = (type) => {
    const { setDrawType } = this.props
    if (type === 'circle') setDrawType('CIRCLE')
    else if (type === 'rectangle') setDrawType('RECTANGLE')
    else setDrawType('POLYGON')
  }

  nameChange = (e) => {
    this.setState({ name: e.target.value })
  }

  returnDescription = () => {
    const { type } = this.state
    const { wMatrix } = this.props
    switch (type) {
      case 'circle':
        return (
          <div style={{ marginTop: '4px' }}>{wMatrix('circleDescription')}</div>
        )
      case 'rectangle':
        return (
          <div style={{ marginTop: '4px' }}>{wMatrix('rectDescription')}</div>
        )
      case 'polygon':
        return (
          <div style={{ marginTop: '4px' }}>{wMatrix('polyDescription')}</div>
        )
      default:
        return (
          <div style={{ marginTop: '4px' }}>{wMatrix('circleDescription')}</div>
        )
    }
  }

  /**
   * Update state of landmark billable
   */
  onBillableChange = (e) => {
    this.setState({ billable: e.target.checked })
  }

  /**
   * Callback function passed to AddressAutoComplete's onSelect. Updates address
   * and coordinates state.
   * @param {Object} coordinates Coordinates object containing 'lat' and 'lng keys
   * @param {String} address Address associated with the coordinates
   */
  addressOnSelect = (coordinates, address) => {
    const { mainNav } = this.props
    // only log analytics when select was actually performed
    if (address && address !== '') {
      /** @analytics Record address search */
      Analytics.record({
        feature: 'edit_landmark',
        page: `${mainNav}`,
        event: 'clicked_address_search',
      })
    }
    this.setState({ address: address || '', addressCoordinates: coordinates || null })
  }

  /**
   * Function that calls prop function "zoomToPoint"
   */
  centerAtAddress = () => {
    const { address, addressCoordinates } = this.state
    const { zoomToPoint } = this.props
    // if valid address and coordinate object, zoom to point
    if (address && address.length > 0 && Object.keys(addressCoordinates).includes('lat')) {
      zoomToPoint(addressCoordinates)
    }
  }

  /**
   * Javascript implimentation of the haversine formula used to measure distance between two
   * lat/lng coordinates.
   * reference - https://www.movable-type.co.uk/scripts/latlong.html
   * @param {Object} coords1 Coordinate object containing keys 'lat' and 'lng'
   * @param {Object} coords2 Coordinate object containing keys 'lat' and 'lng'
   * @returns {Number} Distance in meters
   */
  haversineDistance = (coords1, coords2) => {
    // get distance betwen the two points
    const R = 6371e3 // meters
    const theta1 = coords1.lat * Math.PI / 180
    const theta2 = coords2.lat * Math.PI / 180
    const deltaTheta = (coords2.lat - coords1.lat) * Math.PI / 180
    const deltaGamma = (coords2.lng - coords1.lng) * Math.PI / 180

    // square of half the chord length between points
    const a = Math.sin(deltaTheta / 2) * Math.sin(deltaTheta / 2)
      + Math.cos(theta1) * Math.cos(theta2)
      * Math.sin(deltaGamma / 2) * Math.sin(deltaGamma / 2)

    // angular distance in radians
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    const distance = R * c

    return distance
  }

  /**
   * Compares landmark center with address coordinates. Returns true if they
   * are within 30ft of eachother.
   * @returns {Boolean}
   */
  sameCenter = () => {
    const { selectedLandmarkCenter } = this.props
    const { addressCoordinates } = this.state
    if (selectedLandmarkCenter && addressCoordinates) {
      const delta = this.haversineDistance(selectedLandmarkCenter, addressCoordinates)
      // 50ft ~ 15.24 meters
      if (delta > 15.24) {
        return false
      }
      return true
    }
    return false
  }

  onNotesChange = ({ target: { value } }) => {
    this.setState({ notes: value })
  };

  /**
   * Returns warning text if the landmark center and address center are more than 30ft apart.
   * @returns Element
   */
  returnNotCenterWarning = () => {
    const { address } = this.state
    const { wMatrix } = this.props
    const same = this.sameCenter()
    if (same || !address || address.length === 0) return null
    return (
      <div style={{
        alignSelf: 'flex-end', width: '230px', marginBottom: 8, color: 'red', fontSize: 12, marginTop: 2, paddingLeft: 4, paddingRight: 4,
      }}
      >
        {wMatrix('noLongerCenteredAddress')}
      </div>
    )
  }

  /**
   * Renders details/adjustment fields. This includes address search, Billable, and notes
   */
  renderAdjustments = () => {
    const { wMatrix } = this.props
    const { billable, notes, address } = this.state

    return (
      <div style={{ display: 'flex', flexDirection: 'column', overflowX: 'hidden' }}>
        <div style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexGrow: 0, padding: 2,
        }}
        >
          <div style={{ flexGrow: 0 }}>{wMatrix('address')}</div>
          <div style={{ width: '230px', flexGrow: 0 }}>
            <AddressAutoComplete
              onSelect={this.addressOnSelect}
              // style={styles.addressSearch}
              wMatrix={wMatrix}
              defaultAddress={address}
            />
          </div>
        </div>
        {this.returnNotCenterWarning()}
        <Button
          type="primary"
          onClick={this.centerAtAddress}
          style={{ alignSelf: 'flex-end', width: '230px', marginBottom: 8 }}
        >
          {wMatrix('centerOnAddress')}
        </Button>
        <div style={{ display: 'flex' }}>
          <span>{wMatrix('billable')}</span>
          <Checkbox
            style={{ marginLeft: 'auto', marginRight: '4px' }}
            onChange={this.onBillableChange}
            checked={billable}
          />
        </div>
        <div style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', flexGrow: 0, padding: 2,
        }}
        >
          <div style={{ marginTop: 12 }}>{wMatrix('notes')}</div>
          <TextArea
            style={{ width: '230px' }}
            value={notes}
            onChange={this.onNotesChange}
            autoSize={{ minRows: 4, maxRows: 10 }}
          />
        </div>
      </div>
    )
  }

  renderMainContent = () => {
    const { type, name } = this.state
    const { wMatrix, expanded } = this.props
    if (!expanded) return null
    return (
      <div style={styles.scrollableContent}>
        <FilterSection title={wMatrix('nameLandmark')}>
          <Input size="small" defaultValue={name} onChange={this.nameChange} value={name} />
        </FilterSection>
        <FilterSection title={wMatrix('chooseLandmarkType')}>
          <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center',
          }}
          >
            <Radio.Group onChange={this.typeChange} defaultValue={type} value={type}>
              <Radio.Button size="small" value="circle">{wMatrix('radius')}</Radio.Button>
              <Radio.Button size="small" value="rectangle">{wMatrix('rectangle')}</Radio.Button>
              <Radio.Button size="small" value="polygon">{wMatrix('polygon')}</Radio.Button>
            </Radio.Group>
            {this.returnDescription()}
          </div>
        </FilterSection>
        <FilterSection title={wMatrix('detailsAndAdjustments')}>
          {this.renderAdjustments()}
        </FilterSection>
      </div>
    )
  }

  /**
   * Renders the footer buttons (cancel, save, delete)
   * @returns Element
   */
  renderFooter = () => {
    const {
      wMatrix, cancelEdit, ifNewLandmark,
      savingLandmark, deletingLandmark, expanded,
    } = this.props
    if (!expanded) return null
    return (
      <div style={{
        display: 'flex',
        flexShrink: 0,
        flexDirection: 'row',
        justifyContent: 'center',
        padding: '4px',
        backgroundColor: 'white',
        boxShadow: '0px -5px 10px 2px #88888880',
      }}
      >
        <Popconfirm
          title="Are you sure to delete this landmark?"
          onConfirm={() => this.deleteLandmark()}
          onTest="Yes"
          cancelText="No"
        >
          <Button
            disabled={savingLandmark || deletingLandmark}
            style={{ margin: '2px', display: !ifNewLandmark ? 'block' : 'none' }}
            type="danger"
          >
            {deletingLandmark ? 'Deleting' : 'Delete'}
          </Button>
        </Popconfirm>

        <Button
          style={{ margin: '2px 2px 2px 10px', marginLeft: !ifNewLandmark ? 35 : 2 }}
          onClick={() => cancelEdit()}
        >
          {wMatrix('Cancel')}
        </Button>

        <Button
          disabled={savingLandmark || deletingLandmark}
          style={{ margin: '2px' }}
          type="primary"
          onClick={() => this.saveLandmark()}
        >
          {savingLandmark ? 'Saving' : 'Save'}
        </Button>
      </div>
    )
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          height: '100%',
        }}
      >
        <div style={styles.blueHeader} />
        {this.renderMainContent()}
        {this.renderFooter()}
      </div>
    )
  }
}
