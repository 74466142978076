import React, { Component } from 'react'
import PropTypes from 'prop-types'

const styles = {
  container: {
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
  },
  headerContainer: {
    height: '56px',
    backgroundColor: '#EFEFEF',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    padding: '0px 0px 10px 12px',
  },
  title: {
    color: '#595959',
  },
}

export default class FilterSection extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any,
  }

  static defaultProps = {
    children: null,
  }

  render() {
    const { title, children } = this.props
    return (
      <div style={styles.container}>
        <div style={styles.headerContainer}>
          {title}
        </div>
        <div style={{ margin: '8px' }}>
          {children}
        </div>

      </div>
    )
  }
}
