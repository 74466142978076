import React, { Component } from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import TextButton from '@atom/textButton'
import FaIcon from '@atom/fontawesomeIcon'

/**
 * @description This component uses ant designs datePicker but uses a textButton instead of an input
 * to activate the calandar popup
 * @prop { bool } small - True will render small stacked date instead of verbose date
 * @prop { string } selectedDate - this is the selected Date controlled by its parent (or hoc)
 * @prop { func } setDate - (Required) function to change the date - setDate(string)
 */

export default class CustomDatePicker extends Component {
  state = {
    open: false,
  }

  static propTypes = {
    small: PropTypes.bool,
    selectedDate: PropTypes.string,
    setDate: PropTypes.func.isRequired,
  }

  static defaultProps = {
    small: false,
    selectedDate: null,
  }

  open = () => {
    // const { open } = this.state
    this.setState({ open: true })
  }

  returnTextDate = () => {
    const { small, selectedDate } = this.props
    const momentDate = moment(selectedDate)
    if (!small) {
      return momentDate.format('MMMM DD, YYYY')
    }
    const month = momentDate.format('MMM')
    const day = momentDate.format('DD')
    const year = momentDate.format('YYYY')

    return (
      <div style={{
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <div style={{ textDecoration: 'underline' }}>{month}</div>
        <div>{day}</div>
        <div>{year}</div>
      </div>
    )
  }

  openChange = (status) => {
    this.setState({ open: status })
  }

  setDate = (momentDate) => {
    const { setDate } = this.props
    // console.log(momentDate.format('YYYY-MM-DD'))
    setDate(momentDate.format('YYYY-MM-DD'))
  }

  /** Disable all future dates */
  disableOldAndFutureDates = (dateValue) => {
    const today = moment()
    const lastYear = moment().subtract(1, 'years')
    if (!dateValue) {
      return false
    }
    return dateValue.valueOf() > today.valueOf() || dateValue.valueOf() < lastYear
  }


  render() {
    const { open } = this.state
    const { selectedDate, small } = this.props
    return (
      <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
        <div style={{
          display: 'flex', flexDirection: 'row', position: 'relative', alignItems: 'center',
        }}
        >
          <FaIcon color="white" icon="calendar" fontSize={12} />
          <TextButton
            onClick={() => { this.open() }}
            disabled={small}
            style={{
              color: '#FFFFFF',
              fontWeight: 'normal',
              margin: '0px 5px 0px 5px',
              textDecoration: 'underline',
            }}
          >
            {this.returnTextDate()}
          </TextButton>
        </div>
        <DatePicker
          style={{ position: 'absolute', visibility: 'hidden' }}
          disabledDate={this.disableOldAndFutureDates}
          size="small"
          popupStyle={{ position: 'absolute', display: 'flex' }}
          value={moment(selectedDate)}
          onChange={date => this.setDate(date)}
          format="MMMM DD, YYYY"
          open={open}
          onOpenChange={status => this.openChange(status)}
        />
      </div>
    )
  }
}
