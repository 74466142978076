import React, { Component } from 'react'
import clone from 'clone'
import { compose } from 'react-apollo'
import moment from 'moment'
import queryConnector from '@graphql/queryConnector.js'
import consumerConnector from '@graphql/consumerConnector'
import mutationConnector from '@graphql/mutationConnector'
import PropTypes from 'prop-types'
import socket from '@graphql/socket'

import {
  deviceQuery,
  locateNowQuery,
  locateNowStatus,
  driversQuery,
  deviceLocatesQuery,
} from '@graphql/query'
import {
  assignDeviceToDriver,
  sendSMSMessage,
  updateStarterInterrupted,
} from '@graphql/mutation'
import equal from 'deep-equal'

/**
 * NOTE
 * - maybe should update so we use a single function for both device list calls and socket updates?
 */

const deviceListHOC = () => (WrappedComponent) => {
  class DeviceListHOC extends Component {
    socketEvents = {
      lastUpdate: moment(),
    }

    static propTypes = {
      deviceListData: PropTypes.object.isRequired,
      deviceListLocateData: PropTypes.object,
      mapRefresh: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      apolloClient: PropTypes.object.isRequired,
      assignDeviceToDriverQuery: PropTypes.func,
      sendSMSMessageMutation: PropTypes.func,
      updateStarterInterruptedMutation: PropTypes.func,
      driversData: PropTypes.object,
      newEvent: PropTypes.object,
      newAlert: PropTypes.object,
      navPath: PropTypes.string,
      noLocateNowTypeList: PropTypes.array,
      minuteTZOffset: PropTypes.number,
      formatAddress: PropTypes.func.isRequired,
    }

    static defaultProps = {
      mapRefresh: null,
      assignDeviceToDriverQuery: null,
      sendSMSMessageMutation: null,
      updateStarterInterruptedMutation: null,
      driversData: null,
      newEvent: null,
      newAlert: null,
      navPath: 'map',
      deviceListLocateData: null,
      noLocateNowTypeList: ['B1-MIOT-GA', 'NXLocate 3G'], // TODO: add yn flag to DeviceType GQL?
      minuteTZOffset: 0,
    }

    state = {
      selectedDevice: null,
      // hoverDevice: null,
      filterStr: '',
      filterTags: [],
      // newTag: null,
      // assignedDevice: [],
      canLocateNow: false,
      locateNowLoading: false,
      deviceList: [],
      hasGarmin: false,
    };

    componentDidMount() {
      clearInterval(this.interval)
      // this is to check if we need to repull the locates for every minute
      this.interval = setInterval(async () => {
        this.refetchLocates()
      }, 60000)

      clearInterval(this.stopStatusInterval)
      this.stopStatusInterval = setInterval(() => {
        this.updateStopStatus()
      }, 60000)
      // update locates from socket events every 12.5 seconds
      this.socketInterval = setInterval(async () => {
        this.updateSocketEvents()
      }, 12500)

      socket.on('checkingDeviceId', (deviceInfo) => {
        try {
          const { deviceListData } = this.props
          // ToDo: replace below if statement to check if this deviceId is in this.state.deviceList
          let ifFound = false
          for (const d of deviceListData.data.devices_v3) {
            if (d.id === deviceInfo.deviceId) {
              ifFound = true
              break
            }
          }
          if (ifFound) {
            socket.emit('deviceMatched', deviceInfo)
          }
        } catch (err) {
          // console.log(err)
        }
      })

      socket.on('event', (newEvent) => {
        this.socketEvents[newEvent.deviceId] = newEvent
        this.socketEvents.lastUpdate = moment()
      })
    }


    componentDidUpdate(prevProps, prevState) {
      const { deviceListData } = this.props
      const { selectedDevice } = this.state

      // If client has a garmin enabled device, add state flag (later used by header.container)
      if (!equal(deviceListData, prevProps.deviceListData)) {
        this.checkForGarmin()
        /** This needs to be here for the initial setting of the device list */
        this.updateDeviceList(deviceListData.data.devices_v3)
      }

      if (selectedDevice && (
        (prevState.selectedDevice && prevState.selectedDevice.id !== selectedDevice.id)
        || !prevState.selectedDevice)) {
        clearInterval(this.locateNowInterval)
        this.locateNowInterval = setInterval(() => {
          this.locateNowStatus(selectedDevice.id)
        }, 30000)
      }
    }


    componentWillUnmount() {
      clearInterval(this.interval)
      clearInterval(this.locateNowInterval)
      clearInterval(this.stopStatusInterval)
      clearInterval(this.socketInterval)
    }

    /**
     * Updates deviceList with new socket events
     */
    updateSocketEvents = () => {
      const { minuteTZOffset, formatAddress } = this.props
      const { deviceList, selectedDevice } = this.state
      if (!deviceList) return
      const cloneDL = JSON.parse(JSON.stringify(deviceList))
      const cloneSD = JSON.parse(JSON.stringify(selectedDevice))
      for (const d of cloneDL) {
        const socketEvent = this.socketEvents[d.id]
        if (socketEvent) {
          /**
           * Socket event exists for a device in the device list
           * Obtain deviceList and socket locate utc timestamp.
           * Only update with socket values if socket is latest locate.
          */
          const socketMomentUTC = moment.utc(socketEvent.raw.location.datetime, 'YYYY-MM-DDTHH:mm:ss.SSSSZ')
          const deviceListItemMomentUTC = moment.utc(d.locate.datetimeUTC, 'YYYY-MM-DDTHH:mm:ss.SSSSZ')
          if (socketMomentUTC.isAfter(deviceListItemMomentUTC)) {
            const status = {
              state: 'Parking',
              stoppedMinutes: 0,
            }
            if (socketEvent.raw.event.type === 'locate' && socketEvent.raw.speed.mph > 0) {
              status.state = 'Moving'
            } else if (moment.utc().diff(socketMomentUTC, 'minutes') > d.stopMinutes) {
              status.state = 'Stopped'
              status.stoppedMinutes = moment.utc().diff(socketMomentUTC, 'minutes')
            }
            /** The following takes the raw event's datetime and places it
             * in the user's timezone to be displayed
             */
            const updateDT = moment.utc(socketEvent.raw.location.datetime, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').add(minuteTZOffset, 'minutes')
            const newLocate = {
              address: formatAddress(socketEvent.address),
              latitude: socketEvent.raw.location?.latitude,
              longitude: socketEvent.raw.location?.longitude,
              speed: socketEvent.raw.speed?.mph,
              heading: socketEvent.raw.heading.degree,
              datetimeUTC: socketEvent.raw.location.datetime,
              time: updateDT.format('hh:mm:ss a'),
              date: updateDT.format('MM/DD/YYYY'),
              landmarks: socketEvent.landmarks,
            }
            d.locate = newLocate
            d.status = status // ?? This logic is currently in the database v3_DeviceList
            if (cloneSD && cloneSD.id === d.id) {
              cloneSD.locate = newLocate
              cloneSD.status = status
            }
          }
        }
      }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ deviceList: cloneDL, selectedDevice: cloneSD })
    }

    updateDeviceList = (deviceList) => {
      this.setState({ deviceList })
    }

    /**
     * Set on interval in componentDidMount. Refetches every minute if no socket
     * events have come in within the last minute
     */
    refetchLocates = async () => {
      // only refetch locates if the socket is not connected, this is a backup plan for socket.io
      // also check if lastUpdate happened in 60 seconds. If not then refetch!
      if (socket.connected && sessionStorage.getItem('clientId') && moment().diff(this.socketEvents.lastUpdate, 'second') < 60) return
      const { selectedDevice } = this.state
      const { navPath, deviceListLocateData } = this.props
      // only refetch the locates/address/stop status on the map page
      if (!['map', 'distance'].includes(navPath)) {
        return
      }
      try {
        await deviceListLocateData.refetch()
        // the following function compares current locates with refetched locate
        // for latest
        const updatedDevices = this.devicesWithNewLocates()
        this.setState({ deviceList: updatedDevices })
        if (selectedDevice) {
          // find selected device and update state
          for (let i = 0; i < updatedDevices.length; i += 1) {
            if (updatedDevices[i].id === selectedDevice.id) {
              this.setState({ selectedDevice: updatedDevices[i] })
            }
          }
        }
      } catch (err) {
        // console.log(err)
      }
    }

    updateStopStatus = () => {
      const { deviceList } = this.state
      const cloneDL = JSON.parse(JSON.stringify(deviceList))
      for (const di in cloneDL) {
        if (cloneDL[di]) {
          const status = {
            state: 'Parking',
            stoppedMinutes: 0,
          }
          if (cloneDL[di].locate.speed > 0) {
            status.state = 'Moving'
          } else if (moment.utc().diff(moment.utc(cloneDL[di].locate.datetimeUTC), 'minutes', true) > cloneDL[di].stopMinutes) {
            status.state = 'Stopped'
            status.stoppedMinutes = parseInt(moment.utc().diff(moment.utc(cloneDL[di].locate.datetimeUTC), 'minutes', true), 10)
          }
          cloneDL[di].status = status
        }
      }

      this.setState({ deviceList: cloneDL })
    }

    /**
     * Toggle Starter Interrupt
     * @argument {Object} device
     * @argument {Boolean} state - true = on, false = off
     */
    setStarterInterrupt = async (device, state) => {
      const { sendSMSMessageMutation, updateStarterInterruptedMutation } = this.props
      const {
        id, type, networkProvider, phoneNumber, simCardNumber,
      } = device

      const isATT = networkProvider === 'AT&T'
      let msg = 'SETRELAYDRIVE'
      if (type.includes('GenX 3')) {
        // use relay 2
        msg += '2'
      } else {
        // use relay 4
        msg += '4'
      }
      if (state) {
        msg += 'ON'
      } else {
        msg += 'OFF'
      }

      try {
        const sendSMSResult = await sendSMSMessageMutation({
          variables: {
            number: isATT ? simCardNumber : phoneNumber,
            body: msg,
            isATT,
          },
        })
        if (sendSMSResult.data.sendSMSMessage.code === 1000) {
          // if success, then update table
          const updateStarterInterupprtResult = await updateStarterInterruptedMutation({
            variables: {
              deviceId: id,
              enable: state ? 1 : 0,
            },
          })
          if (updateStarterInterupprtResult
            && updateStarterInterupprtResult.data
            && updateStarterInterupprtResult.data.updateStarterInterrupted
            && updateStarterInterupprtResult.data.updateStarterInterrupted.code === 1000) {
            await this.refetchAndCheckSelected()
          }
        }
      } catch (err) {
        /** @todo add code to handle errors? maybe display message or revert toggle? */
        // eslint-disable-next-line no-console
        console.log(err)
      }
    }

    /**
     *
     */
    locateNow = (deviceId) => {
      const { apolloClient } = this.props
      this.setState({
        locateNowLoading: true,
      })
      apolloClient.query({
        query: locateNowQuery,
        fetchPolicy: 'network-only',
        variables: {
          id: deviceId,
        },
      }).then(() => {
        //
      }).catch(() => {
        // @todo error notification?
      }).finally(() => {
        // can locate should be false right after a call
        this.setState({
          locateNowLoading: false,
          canLocateNow: false,
        })
      })
    }

    /**
     * Sets state of canLocateNow status
     */
    locateNowStatus = (deviceId) => {
      const { apolloClient } = this.props

      apolloClient.query({
        query: locateNowStatus,
        fetchPolicy: 'network-only',
        variables: {
          id: deviceId,
        },
      }).then((results) => {
        this.setState({
          canLocateNow: !results.data.locateNowStatus,
        })
      }).catch(() => {
        // @todo error notification?
      })
    }

    selectDevice = (d) => {
      this.setState({
        selectedDevice: d,
      })
    }

    hoverDevice = (d) => {
      this.setState({
        hoveredDevice: d,
      })
    }

    unselectDevice = () => {
      this.setState({
        selectedDevice: null,
      })
    }

    /**
     * @private
     * @description This is the final filtering of the deviceList and returns the filtered list.
     * Handles alias filtering as well as tag filtering
     * @returns {Array} Array of filtered devices
     */
    devices = () => {
      const { filterStr, deviceList } = this.state
      // If deviceList has been updated, use it. Else use the original deviceListData
      // Clone the arrays to not alter the state or original prop by filtering
      if (!deviceList) {
        return []
      }
      const devicesForFiltering = JSON.parse(JSON.stringify(deviceList))
      devicesForFiltering.sort((a, b) => (a.alias.toLowerCase() > b.alias.toLowerCase() ? 1 : -1))
      return devicesForFiltering.filter((device) => {
        if (device.locate) {
          // filter by alias
          if (device.locate.latitude !== 0
            && device.alias.toLowerCase().indexOf(filterStr.toLowerCase()) >= 0) {
            // Filter by tags
            const tags = []
            if (device.groups) {
              for (const group of device.groups) tags.push(group.id)
            }

            if (device.labels) {
              for (const label of device.labels) tags.push(label.id)
            }

            const { filterTags } = this.state

            if (filterTags.length > 0) {
              for (const filterTag of filterTags) {
                if (tags.includes(filterTag)) {
                  return device
                }
              }
            } else {
              return device
            }
          }
        }
        return false
      })
    }

    filterDevice = (filterStr) => {
      this.setState({ filterStr })
    }

    filterDeviceByTag = (filterTags) => {
      this.setState({ filterTags })
    }

    /**
     * @public
     * @description This function refetches the devicelist with all fields, but also checks to see
     * if the selected device was modified and updates the state if necessary. This function is used
     * by other components when device settings have been modified.
     * @returns {Boolean} True if refetch was successful. False if not
     */
    refetchAndCheckSelected = async () => {
      const { deviceListData } = this.props
      const { selectedDevice } = this.state
      try {
        const response = await deviceListData.refetch()
        // eslint-disable-next-line camelcase
        const { devices_v3 } = response.data
        // update state device list
        this.setState({ deviceList: devices_v3 })
        if (selectedDevice) {
          // find selected device and update state if changed
          for (let i = 0; i < devices_v3.length; i += 1) {
            if (devices_v3[i].id === selectedDevice.id) {
              this.setState({ selectedDevice: devices_v3[i] })
            }
          }
        }
        return true
      } catch (err) {
        return false
        // console.log(err)
      }
    }


    getExistingTagList = () => {
      const existingTagList = []
      const { deviceListData } = this.props
      if (deviceListData && deviceListData.data && deviceListData.data.devices_v3) {
        const devicesV3 = deviceListData.data.devices_v3
        for (let i = 0; i < devicesV3.length; i += 1) {
          if (devicesV3[i].isActive && devicesV3[i].locate) {
            const d = devicesV3[i]
            let singleDeviceTags = []
            if (d.groups) {
              const groups = clone(d.groups)
              for (let j = 0; j < groups.length; j += 1) {
                groups[j].ynGroup = 1
              }
              singleDeviceTags = singleDeviceTags.concat(groups)
            }
            if (d.labels) {
              const labels = clone(d.labels)
              for (let j = 0; j < labels.length; j += 1) {
                labels[j].ynGroup = 0
              }
              singleDeviceTags = singleDeviceTags.concat(labels)
            }
            for (let k = 0; k < singleDeviceTags.length; k += 1) {
              let exist = false
              for (let j = 0; j < existingTagList.length; j += 1) {
                if (singleDeviceTags[k].id === existingTagList[j].id) {
                  exist = true
                }
              }
              if (!exist) {
                existingTagList.push(singleDeviceTags[k])
              }
            }
          }
        }
      }
      return existingTagList
    }

    /**
     * Assigns driver to a device then refetches deviceList
     */
    assignDriverToDevice = async (deviceId, driverId) => {
      const { assignDeviceToDriverQuery, driversData } = this.props
      try {
        const assignResult = await assignDeviceToDriverQuery({
          variables: {
            deviceId,
            driverId,
          },
        })
        if (assignResult.data.assignDeviceToDriver.code === 1000) {
          // updated with no errors, refetch data
          // refetch device list and check selected device
          this.refetchAndCheckSelected()
          // refetch available drivers
          driversData.refetch()
        }
      } catch (err) {
        throw err
      }
    }

    /**
     * Checks for at least one garmin device to set state (used in hiding the logistics tab)
     */
    checkForGarmin = () => {
      const { deviceListData } = this.props
      const { deviceList } = this.state
      const devices = deviceList?.length > 0 ? deviceList : deviceListData.data.devices_v3
      for (const device in devices) {
        if (devices[device].garmin) {
          this.setState({ hasGarmin: true })
          break
        }
      }
    }

    devicesWithNewLocates = () => {
      try {
        // NOTE this is currently called after deviceListLocateData.refetch()
        const { deviceListData, deviceListLocateData } = this.props
        if (deviceListLocateData
          && deviceListLocateData.data
          && deviceListLocateData.data.devices_v3_meta) {
          const devices = []
          // deviceLocates are the potentially new locates
          const deviceLocates = deviceListLocateData.data.devices_v3_meta
          deviceLocates.sort((a, b) => (a.alias.toLowerCase() > b.alias.toLowerCase() ? 1 : -1))
          for (let i = 0; i < deviceLocates.length; i += 1) {
            // dl is the potentially new locate from refetch
            const dl = deviceLocates[i]
            for (const d of deviceListData.data.devices_v3) {
              if (dl.id === d.id) {
                const newDeviceLocateMomentUTC = moment.utc(dl.locate.datetimeUTC, 'YYYY-MM-DDTHH:mm:ss.SSSSZ')
                const deviceListItemUTC = moment.utc(d.locate.datetimeUTC, 'YYYY-MM-DDTHH:mm:ss.SSSSZ')
                if (newDeviceLocateMomentUTC.isAfter(deviceListItemUTC)) {
                  // if dl is newer, update d locate values
                  if (dl.status) d.status = dl.status
                  if (dl.icon) d.icon = dl.icon
                  if (dl.locate) d.locate = dl.locate
                  if (dl.address) d.address = dl.address
                }
                devices.push(d)
              }
            }
          }
          return devices
        }
        if (deviceListData && deviceListData.data && deviceListData.data.devices_v3) {
          const devicesV3 = deviceListData.data.devices_v3
          return devicesV3.sort((a, b) => (a.alias.toLowerCase() > b.alias.toLowerCase() ? 1 : -1))
        }
        return []
      } catch (err) {
        return []
      }
    }

    render = () => {
      const {
        deviceListData, driversData,
      } = this.props
      const {
        selectedDevice, hoveredDevice, filterStr, filterTags, hasGarmin,
      } = this.state

      const { canLocateNow, locateNowLoading, deviceList } = this.state

      const drivers = driversData && driversData.data
        && driversData.data.driver ? driversData.data.driver : []

      const locateNowObject = {
        run: this.locateNow,
        getStatus: this.locateNowStatus,
        canRun: canLocateNow,
        loading: locateNowLoading,
      }

      const filteredDevices = this.devices()
      return (
        <WrappedComponent
          existingTagList={this.getExistingTagList()}
          devices={deviceList?.length > 0 ? deviceList : deviceListData.data.devices_v3}
          driversForAssignment={drivers}
          assignDriverToDevice={this.assignDriverToDevice}
          deviceList={filteredDevices}
          refetchDevices={this.refetchAndCheckSelected}
          refetchDeviceListDrivers={() => { driversData.refetch() }}
          selectDevice={this.selectDevice}
          unselectDevice={this.unselectDevice}
          selectedDevice={selectedDevice}
          hoverDevice={this.hoverDevice}
          hoveredDevice={hoveredDevice}
          filterDevice={this.filterDevice}
          filterDeviceByTag={this.filterDeviceByTag}
          filterStr={filterStr}
          deviceLoading={
            !deviceList
            || (deviceList?.length === 0
            && deviceListData.loading
            && !deviceListData.data.devices_v3)
          }
          locateNow={locateNowObject}
          setStarterInterrupt={this.setStarterInterrupt}
          filterTags={filterTags}
          hasGarmin={hasGarmin}
          /** REVIEW - this is whats passed to timeline and seems to be most recent locate generally */
          selectedDeviceSocketEvent={this.socketEvents[selectedDevice?.id]}
          {...this.props}
        />
      )
    }
  }

  return compose(
    queryConnector(deviceQuery, { type: 'client', input: '0', active: true }, 'deviceListData', true),
    queryConnector(deviceLocatesQuery, { type: 'client', input: '0', active: true }, 'deviceListLocateData'),
    queryConnector(driversQuery, {}, 'driversData'),
    mutationConnector(assignDeviceToDriver, 'assignDeviceToDriverQuery'),
    mutationConnector(sendSMSMessage, 'sendSMSMessageMutation'),
    mutationConnector(updateStarterInterrupted, 'updateStarterInterruptedMutation'),
    consumerConnector(),
  )(DeviceListHOC)
}

export default deviceListHOC
