/* eslint-disable global-require */
import React, { Component } from 'react'
import { Icon } from 'antd'

export default class DistanceAddressMarker extends Component {
  /**
   * @todo
   * aggregator
   */

  /**
   * React / CSS Styles
   */
  styles = {
    /**
     * The whole view of the component
     */
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      transform: 'translate(-50%, -100%)', // puts bottom marker point on the exact location
      width: 60, // determines how big the whole marker will be
      // backgroundColor: 'blue'
    },
  };


  render() {
    return (
      <div style={this.styles.container}>
        <Icon
          theme="twoTone"
          type="environment"
          style={{ fontSize: '3em' }}
        />
      </div>
    )
  }
}
