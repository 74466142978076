import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import Menu from '@mol/fullScreenMenu'

const { Option } = Select

const styles = {
  dot: {
    height: '8px',
    width: '8px',
    flexShrink: 0,
    borderRadius: '50%',
    margin: '0 5px 0 0',
  },
  vin: {
    margin: 0,
    color: 'grey',
    fontSize: '10px',
  },
  itemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    // alignIt: 'space-between',
  },
  itemRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '5px',
    paddingLeft: '5px',
    overflow: 'hidden',
  },
  textEllipse: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  // leftColumn: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   // width: '70%',
  //   // height: '100%',
  // },
  // rightColumn: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'flex-end',
  //   // width: '30%',
  //   // height: '100%',
  // },
}

const colors = ['#4482FF', '#FF5500', '#FFBA44', '#87D068']

export default class ServiceMenu extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    onGroupChange: PropTypes.func.isRequired,
    menuItems: PropTypes.array.isRequired,
    selectItem: PropTypes.func.isRequired,
    filterMenu: PropTypes.func.isRequired,
    handleShowCreate: PropTypes.func,
    selectedKey: PropTypes.string,
  }

  static defaultProps = {
    selectedKey: null,
    handleShowCreate: null,
  }

  renderStatus = (status) => {
    const { wMatrix } = this.props
    switch (status) {
      case 'overdue':
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ ...styles.dot, backgroundColor: colors[1] }} />
            <h5 style={{ fontSize: '10px', margin: 0 }}>{wMatrix('Overdue')}</h5>
          </div>
        )
      case 'upcoming':
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ ...styles.dot, backgroundColor: colors[2] }} />
            <h5 style={{ fontSize: '10px', margin: 0 }}>{wMatrix('Upcoming')}</h5>
          </div>
        )
      case 'active':
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ ...styles.dot, backgroundColor: colors[0] }} />
            <h5 style={{ fontSize: '10px', margin: 0 }}>{wMatrix('Active')}</h5>
          </div>
        )
      default:
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ ...styles.dot, backgroundColor: colors[3] }} />
            <h5 style={{ fontSize: '10px', margin: 0 }}>{wMatrix('Completed')}</h5>
          </div>
        )
    }
  }

  renderItem = (item) => {
    if (item.data) {
      return (
        <div style={styles.itemWrapper}>
          <div style={{ ...styles.itemRow, justifyContent: 'flex-start' }}>
            <h3 style={{ margin: 0, ...styles.textEllipse, maxWidth: '70%' }}>{item.data.alias}</h3>
          </div>
          <div style={styles.itemRow}>
            <h5 style={{
              ...styles.vin, margin: 0, ...styles.textEllipse, maxWidth: '70%',
            }}
            >
              {item.data.type.name}
            </h5>
            <h5 style={{ margin: 0 }}>{`#${item.data.id}`}</h5>
          </div>
          <div style={styles.itemRow}>
            <p style={styles.vin}>{`VIN: ${item.data.vehicle.vin || 'none'}`}</p>
            {this.renderStatus(item.data.details.status)}
          </div>
        </div>
      )
    }
    return null
  }

  /**
   * @public
   * @returns {Component}
   */
  returnPostHeader = () => {
    const { wMatrix, onGroupChange } = this.props
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 4,
      }}
      >
        {`${wMatrix('groupBy')}:`}
        <Select defaultValue="status" style={{ width: 150 }} onChange={onGroupChange}>
          <Option value="status">{wMatrix('ticketStatus')}</Option>
          <Option value="type">{wMatrix('serviceType')}</Option>
          <Option value="device">{wMatrix('Device')}</Option>
        </Select>
      </div>
    )
  }

  render() {
    const {
      menuItems, selectItem, filterMenu, handleShowCreate, selectedKey, wMatrix,
    } = this.props
    return (
      <Menu
        noSubIndent
        searchPlaceholder={`${wMatrix('Device')}, VIN, ${wMatrix('service')}`}
        menuItems={menuItems}
        renderItem={this.renderItem}
        selectItem={selectItem}
        onInputChange={filterMenu}
        handleCreate={handleShowCreate}
        selectedKey={selectedKey}
        postHeader={this.returnPostHeader()}
      />
    )
  }
}
