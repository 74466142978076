import React from 'react'
import { compose } from 'react-apollo'
import mutationConnector from '@graphql/mutationConnector'
import { verifyCodeAndSetPassword } from '@graphql/mutation'

const forgotHOC = () => (WrappedComponent) => {
  class ForgotHOC extends React.Component {
    render() {
      return (
        <WrappedComponent
          verifyCodeAndSetPassword = {this.props.verifyCodeAndSetPassword}
        />
      )
    }
  }

  return compose(
    mutationConnector(verifyCodeAndSetPassword, 'verifyCodeAndSetPassword')
  )(ForgotHOC)
}

export default forgotHOC
