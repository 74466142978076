import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Progress, Button } from 'antd'
import Card from '@atom/card'
import Skeleton from '@atom/skeleton'
import helper from '@helper'

const meterColors = [
  {
    hex: '#D75B4E', r: 215, g: 91, b: 78, rgb: [215, 91, 78], // red
  },
  {
    hex: '#EEDD74', r: 238, g: 221, b: 116, rgb: [238, 221, 116], // yellow
  },
  {
    hex: '#54B184', r: 84, g: 177, b: 132, rgb: [84, 177, 132], // green
  },
]

const styles = {
  contentWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'stretch',
  },
  cardColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10px',
  },
  guageValueWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  guageValue: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    width: 50,
    borderRadius: '50%',
  },
  skeleton: {
    row: {
      display: 'flex',
      flexDirection: 'row',
      height: 21,
      width: '80%',
      alignSelf: 'center',
      justifyContent: 'flex-start',
    },
    count: {
      height: '80%',
      width: 12,
      flexShrink: '0',
    },
    name: {
      height: '80%',
      minWidth: 75,
      marginLeft: 32,
      flexGrow: 1,
      flexShrink: '0',
    },
  },
  topFive: {
    column: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      width: '80%',
      flexWrap: 'nowrap',
      alignSelf: 'center',
    },
    name: {
      marginLeft: 32,
      flexGrow: 1,
      flex: 2,
      minWidth: 100,
      flexWrap: 'nowrap',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}

class DriverExceptions extends Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    setDSCNav: PropTypes.func.isRequired,
    returnColor: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    data: PropTypes.object,
  }

  static defaultProps = {
    loading: false,
    data: {
      speeding:
      {
        total: null,
        max: null,
        orderedDrivers: null,
      },
      hardBrake:
      {
        total: null,
        max: null,
        orderedDrivers: null,
      },
      fastStart:
      {
        total: null,
        max: null,
        orderedDrivers: null,
      },
    },
  }

  /**
   * renders guage center circle. If loading param is passed, a skeleton is placed
   */
  renderGuageCenter = (percentage, count, loading) => {
    const { returnColor } = this.props
    return (
      <div style={styles.guageValueWrapper}>
        {loading
          ? <Skeleton wrapperStyle={{ ...styles.guageValue }} />
          : (
            <div
              style={{
                ...styles.guageValue,
                backgroundColor: returnColor(
                  (percentage / 100),
                  meterColors[0].rgb,
                  meterColors[1].rgb,
                  meterColors[2].rgb,
                ),
                color: '#FFF',
              }}
            >
              {count}
            </div>
          )}
      </div>
    )
  }

  /**
   * Returns array that composes top 5 skeleton for loading.
   */
  topFiveSkel = () => {
    const skel = []
    for (let i = 0; i < 5; i += 1) {
      skel.push(
        <div style={styles.skeleton.row} key={i}>
          <Skeleton wrapperStyle={{ ...styles.skeleton.count, marginLeft: i !== 0 ? 5 : 0 }} />
          <Skeleton wrapperStyle={styles.skeleton.name} />
        </div>,
      )
    }
    return skel
  }

  /**
   * Renders top five column scores and names
   */
  renderTopFive = (data, key, loading) => {
    // const standings = data.top
    const { orderedDrivers } = data ? data[key] : { orderedDrivers: null }
    // copy top five values to new array
    const standings = []
    if (orderedDrivers) {
      for (let i = 0; i < 5; i += 1) {
        if (orderedDrivers[i]) {
          standings.push(orderedDrivers[i])
        }
      }
    }
    return (
      <div style={styles.topFive.column}>
        { loading
          ? this.topFiveSkel()
          : standings.map((item, index) => (
            <div style={styles.topFive.row} key={`${item.name}`}>
              <div style={{
                flex: 1,
                marginLeft: index !== 0 ? 5 : 0,
                fontWeight: index === 0 ? 'bold' : 'normal',
              }}
              >
                {item.count}
              </div>
              <div style={{
                ...styles.topFive.name,
                fontWeight: index === 0 ? 'bold' : 'normal',
              }}
              >
                {item.name}
              </div>
            </div>
          ))}
      </div>
    )
  }

  /**
   * Renders column in exception card
   */
  renderColumn = (title, key) => {
    const { data, loading } = this.props
    let percent = 0
    let totalVal = 0
    if (data) {
      const { max, total } = data[key]
      percent = (total / max) * 100
      totalVal = total
    }
    return (
      <div style={styles.cardColumn}>
        <Progress
          type="dashboard"
          percent={percent}
          strokeColor={{
            '0%': meterColors[0].hex,
            '50%': meterColors[1].hex,
            '100%': meterColors[2].hex,
          }}
          strokeWidth={10}
          width={110}
          gapDegree={130}
          format={percentage => this.renderGuageCenter(percentage, totalVal, loading)}
        />
        <h4>{title}</h4>
        {this.renderTopFive(data, key, loading)}
      </div>
    )
  }

  /**
   * Renders details button to the right of the card
   */
  renderPostTitle = () => {
    const { wMatrix, setDSCNav } = this.props
    return (
      <div style={{
        display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center',
      }}
      >
        <span style={{ color: 'rgba(0,0,0,0.65)' }}>
          {`(${wMatrix('thisWeek')})`}
        </span>
        <Button
          size="small"
          style={{ marginRight: 8 }}
          onClick={() => setDSCNav('exceptions')}
        >
          {wMatrix('details')}
        </Button>
      </div>
    )
  }

  render() {
    const { wMatrix } = this.props

    return (
      <Card
        title={wMatrix('exceptions')}
        postTitle={this.renderPostTitle()}
      >
        <div style={styles.contentWrapper}>
          {this.renderColumn(wMatrix('speedingExceptions'), 'speeding')}
          {this.renderColumn(wMatrix('hardBrakes'), 'hardBrake')}
          {this.renderColumn(wMatrix('fastStarts'), 'fastStart')}
        </div>
      </Card>
    )
  }
}

export default helper()(DriverExceptions)
