import React, { Component } from 'react'
import queryConnector from '@graphql/queryConnector'
import mutationConnector from '@graphql/mutationConnector'
import {
  createPersonalLabel,
  DeletePersonalLabel,
  assignPersonalLabelToDevice,
} from '@graphql/mutation'
import { compose } from 'react-apollo'
import { getAllPersonalLabels } from '@graphql/query'
import PropTypes from 'prop-types'

const labelsHOC = () => (WrappedComponent) => {
  class LabelsHOC extends Component {
    static propTypes = {
      devices: PropTypes.array,
      labelData: PropTypes.object,
      createLabel: PropTypes.func,
      assignLabelToDevice: PropTypes.func,
      refetchDevices: PropTypes.func,
      deleteLabel: PropTypes.func,
      filterDeviceByTag: PropTypes.func,
    }

    static defaultProps = {
      devices: [],
      labelData: null,
      createLabel: null,
      assignLabelToDevice: null,
      refetchDevices: null,
      deleteLabel: null,
      filterDeviceByTag: null,
    }

    state = {
      labelsLoading: true,
      message: {},
    }

    componentDidUpdate = (prevProps) => {
      const { labelData } = this.props

      if (prevProps.labelData.loading && !labelData.loading) this.setState({ labelsLoading: false })
    }

    save = (tag, checkedItems) => {
      const {
        createLabel, assignLabelToDevice, labelData, refetchDevices, filterDeviceByTag,
      } = this.props

      this.setState({
        labelsLoading: true,
        message: {},
      })

      createLabel({ variables: tag }).then((response) => {
        if (response) {
          return assignLabelToDevice({
            variables: {
              labelIds: [response.data.createPersonalLabel.id],
              deviceIds: checkedItems.devices,
              ifEditingTag: true,
            },
          }).then((response1) => {
            if (response1.data.assignPersonalLabelToDevice.code === 1000) {
              filterDeviceByTag([])
              this.setState({
                message: {
                  success: 'labelHasBeenUpdated',
                },
              })
            } else {
              this.setState({
                message: {
                  error: 'Could not add or remove devices to label. Did not save changes.',
                },
              })
            }

            // workaround for .finally in Edge browser
          })
        }
        this.setState({
          message: {
            error: 'Could not save label',
          },
        })


        // workaround for .finally in Edge browser
      }).then(() => { // workaround for .finally in Edge browser
        refetchDevices()
        labelData.refetch()
      })
    }

    deleteLabel = (labelId) => {
      const { deleteLabel, labelData, refetchDevices } = this.props

      this.setState({
        labelsLoading: true,
        message: {},
      })

      deleteLabel({ variables: { id: labelId } }).then((response) => {
        if (response) {
          refetchDevices()
          labelData.refetch()
          this.setState({
            message: {
              success: 'labelHasBeenUpdated',
            },
          })
        } else {
          this.setState({
            message: {
              error: 'Could not delete label',
            },
          })
        }
      })
    }

    render() {
      const { labelData, devices } = this.props
      const { labelsLoading, message } = this.state

      return (
        <WrappedComponent
          labels={labelData.data.label}
          labelsLoading={labelsLoading}
          devices={devices}
          save={this.save}
          deleteTag={this.deleteLabel}
          message={message}
        />
      )
    }
  }

  return compose(
    queryConnector(getAllPersonalLabels, {}, 'labelData', true),
    mutationConnector(createPersonalLabel, 'createLabel'),
    mutationConnector(DeletePersonalLabel, 'deleteLabel'),
    mutationConnector(assignPersonalLabelToDevice, 'assignLabelToDevice'),
  )(LabelsHOC)
}

export default labelsHOC
