import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Alert, Button, Checkbox, DatePicker, Divider, Form, Input, Modal, Select, TimePicker,
} from 'antd'
import ScheduledReportHOC from '@hoc/scheduledReport.hoc'
import helper from '@helper'


const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0',
  },
  h2: {
    margin: '30px 0 10px 0px',
  },
  h4: {
    margin: '0',
  },
  formLabel: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '35%',
    margin: '0 15px 0 0',
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    width: '100%',
    justifyContent: 'flex-start',
  },
  formItem: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'no-wrap',
  },
  p: {
    width: '10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalRow: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '10px',
  },
}

class ScheduledReport extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    handleShowModal: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    generateFormItem: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    freqOptions: PropTypes.array,
    dateRangeOptions: PropTypes.array,
    reportInputs: PropTypes.array.isRequired,
    scheduledInputs: PropTypes.object,
    reportType: PropTypes.string.isRequired,
  }

  static defaultProps = {
    freqOptions: [],
    dateRangeOptions: [],
    scheduledInputs: null,
  }

  state = {
    alert: {
      display: 'none',
      message: '',
      type: 'success',
    },
    deleteAlert: {
      display: 'none',
      message: '',
      type: 'success',
    },
    allDevices: true,
    deleteModal: false,
    driversOnly: false,
  }

  componentDidMount() {
    const { reportInputs } = this.props
    let initialDevices = null
    let initialGroups = null
    let initialLabels = null
    let driversOnly = false
    for (let i = 0; i < reportInputs.length; i += 1) {
      if (reportInputs[i].key === 'deviceIds') initialDevices = reportInputs[i].data
      if (reportInputs[i].key === 'groupIds') initialGroups = reportInputs[i].data
      if (reportInputs[i].key === 'labelIds') initialLabels = reportInputs[i].data
      if (reportInputs[i].key === 'driversOnly') driversOnly = true
    }
    if (initialDevices || initialGroups || initialLabels) {
      if (initialDevices.length === 0
        && (initialGroups && initialGroups.length === 0)
        && (initialLabels && initialLabels.length === 0)
      ) {
        this.setState({ allDevices: true, driversOnly })
      } else {
        this.setState({ allDevices: false, driversOnly })
      }
    } else if (driversOnly) {
      this.setState({ driversOnly })
    }
  }

  handleShowDeleteModal = () => {
    const { deleteModal } = this.state
    this.setState({ deleteModal: !deleteModal })
  }

  handleChangeCheckbox = (event, form) => {
    this.setState({ [event.target.id]: event.target.checked })
    form.resetFields(['deviceIds', 'groupIds', 'labelIds'])
  }

  disablePastDates = (dateValue) => {
    const today = moment()
    if (!dateValue) {
      return false
    }
    return dateValue.valueOf() <= today.valueOf()
  }

  handleSubmit = async () => {
    const {
      form, handleSave, handleShowModal,
    } = this.props

    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          await handleSave(values)
          const success = {
            display: 'block',
            message: 'Success',
            type: 'success',
          }
          this.setState({ alert: success })
          setTimeout(() => {
            handleShowModal()
          }, 2200)
        } catch (e) {
          const error = {
            display: 'block',
            message: 'Something went wrong',
            type: 'error',
          }
          this.setState({ alert: error })
        }
      }
    })
  }

  handleDeleteReport = async () => {
    const { handleDelete } = this.props
    try {
      await handleDelete()
    } catch (e) {
      const error = {
        display: 'block',
        message: 'Something went wrong',
        type: 'error',
      }
      this.setState({ deleteAlert: error })
    }
  }

  renderDeleteModal = () => {
    const { wMatrix } = this.props
    const { deleteModal, deleteAlert } = this.state
    return (
      <>
        <Modal
          visible={deleteModal}
          onCancel={this.handleShowDeleteModal}
          footer={null}
          width={500}
          zIndex={4}
          closable={false}
        >
          <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            <div style={styles.modalRow}>
              <h2>{wMatrix('areYouSure')}</h2>
            </div>
            <div style={styles.modalRow}>
              <Button onClick={this.handleShowDeleteModal} type="default">
                {wMatrix('Cancel')}
              </Button>
              <div style={{ width: '30%' }} />
              <Button onClick={this.handleDeleteReport} type="danger">
                {wMatrix('Delete')}
              </Button>
            </div>
            <div style={styles.modalRow}>
              <Alert
                style={{
                  display: deleteAlert.display,
                  width: '275px',
                  margin: '0px 10px',
                }}
                closable
                showIcon
                message={deleteAlert.message}
                type={deleteAlert.type}
              />
            </div>
          </div>
        </Modal>
      </>
    )
  }

  renderInputs = (inputBlob, form) => {
    const { generateFormItem } = this.props
    const inputs = inputBlob.map(i => ({
      key: i.key,
      formItem: generateFormItem(i, form, '50%'),
    }))
    const filters = []
    let deviceIdsItem = null
    let groupsItem = null
    let labelsItem = null
    for (let i = 0; i < inputs.length; i += 1) {
      switch (inputs[i].key) {
        case 'deviceIds':
          deviceIdsItem = inputs[i].formItem
          break
        case 'groupIds':
          groupsItem = inputs[i].formItem
          break
        case 'labelIds':
          labelsItem = inputs[i].formItem
          break
        case 'driversOnly':
        case 'dateRange':
        case 'startDateTime':
        case 'endDateTime':
        case 'startDate':
        case 'endDate':
          break
        default:
          filters.push(inputs[i].formItem)
      }
    }

    return {
      deviceIdsItem,
      groupsItem,
      labelsItem,
      filters,
    }
  }

  renderFreqOptions = () => {
    const { freqOptions, wMatrix } = this.props
    return freqOptions.map(o => (
      <Select.Option key={o.key} value={o.key}>
        {wMatrix(o.title)}
      </Select.Option>
    ))
  }

  renderDateRangeOptions = () => {
    const { dateRangeOptions, reportType, wMatrix } = this.props
    const selectOptions = []

    for (const o of dateRangeOptions) {
      // Only service report can have "all" as date range
      if (!(reportType !== 'service' && o.key === 'all')) {
        selectOptions.push(
          <Select.Option key={o.key} value={o.key}>
            {wMatrix(o.title)}
          </Select.Option>,
        )
      }
    }
    return selectOptions
  }

  renderRecipients = (form) => {
    const { scheduledInputs, wMatrix } = this.props
    const emailValidator = (rule, values, callback) => {
      const emailRegex = /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/
      const invalidInputs = values.filter(value => !value.match(emailRegex))
      if (invalidInputs.length === 0) {
        callback()
      } else if (invalidInputs.length === 1) {
        callback(`${invalidInputs.join('')} is not a valid email`)
      } else {
        callback(`${invalidInputs.slice(0, -1).join(', ')} and ${invalidInputs.slice(-1)} are not valid emails`)
      }
    }
    let initialValue = []
    if (scheduledInputs && scheduledInputs.recipients) {
      initialValue = scheduledInputs.recipients.split(',')
    }

    return (
      <div key="recipients" style={{ width: '100%' }}>
        <Form.Item style={{ width: '100%' }}>
          {form.getFieldDecorator('recipients', {
            initialValue,
            rules: [
              { validator: emailValidator },
              { required: true, message: wMatrix('This field is required') },
            ],
          })(
            <Select style={{ width: '100%' }} mode="tags" />,
          )}
        </Form.Item>
      </div>
    )
  }

  renderScheduleInputs = (form) => {
    const {
      wMatrix, freqOptions, dateRangeOptions, scheduledInputs,
    } = this.props

    if (freqOptions.length < 1 || dateRangeOptions.length < 1) return <div />
    const initialFreq = scheduledInputs ? scheduledInputs.frequency : 'weekly'
    const initialDateWindow = scheduledInputs ? scheduledInputs.dateRange : 'last_week'
    const initialDeliveryTime = scheduledInputs
      ? moment(scheduledInputs.deliveryTime, 'h:mm a')
      : moment('08:00', 'h:mm a')
    const initialStartDate = scheduledInputs ? moment(scheduledInputs.deliveryStartDate) : moment().add(1, 'days')

    return (
      <>
        <div style={styles.row}>
          <div style={styles.filters}>
            <div key="freq" style={styles.formItem}>
              <div style={styles.formLabel}>
                <h4>{`${wMatrix('deliveryFrequency')}: `}</h4>
              </div>
              <div style={{ flexGrow: 1 }}>
                <Form.Item style={{ width: '100%' }}>
                  {form.getFieldDecorator('freq', { initialValue: initialFreq })(
                    <Select style={{ width: '100%' }}>
                      {this.renderFreqOptions()}
                    </Select>,
                  )}
                </Form.Item>
              </div>
            </div>
            <div key="dateWindow" style={styles.formItem}>
              <div style={styles.formLabel}>
                <h4>{`${wMatrix('dateRange')}: `}</h4>
              </div>
              <div style={{ flexGrow: '1' }}>
                <Form.Item style={{ width: '100%' }}>
                  {form.getFieldDecorator('dateWindow', { initialValue: initialDateWindow })(
                    <Select style={{ width: '100%' }}>
                      {this.renderDateRangeOptions()}
                    </Select>,
                  )}
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
        <div style={styles.row}>
          <div style={styles.filters}>
            <div key="deliveryTime" style={styles.formItem}>
              <div style={styles.formLabel}>
                <h4>{`${wMatrix('deliveryTime')}: `}</h4>
              </div>
              <div style={{ flexGrow: '1' }}>
                <Form.Item style={{ width: '100%' }}>
                  {form.getFieldDecorator('deliveryTime', { initialValue: initialDeliveryTime })(
                    <TimePicker
                      use12Hours
                      format="h:mm a"
                      minuteStep={30}
                      allowClear={false}
                      style={{ width: '100%' }}
                    />,
                  )}
                </Form.Item>
              </div>
            </div>
            <div key="deliveryStartDate" style={styles.formItem}>
              <div style={styles.formLabel}>
                <h4>{`${wMatrix('startDate')}: `}</h4>
              </div>
              <div style={{ flexGrow: '1' }}>
                <Form.Item style={{ width: '100%' }}>
                  {form.getFieldDecorator('deliveryStartDate', { initialValue: initialStartDate })(
                    <DatePicker
                      disabledDate={this.disablePastDates}
                      showToday={false}
                      allowClear={false}
                      style={{ width: '100%' }}
                    />,
                  )}
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  render() {
    const {
      showModal, handleShowModal, wMatrix, form, reportInputs, scheduledInputs,
    } = this.props
    const { alert, allDevices, driversOnly } = this.state
    const inputs = this.renderInputs(reportInputs, form)
    const scheduleInputs = this.renderScheduleInputs(form)
    const recipientsInput = this.renderRecipients(form)

    let groupsTouched = false
    let labelsTouched = false
    let deviceIdsTouched = false
    const fieldValues = form.getFieldsValue(['groupIds', 'labelIds', 'deviceIds'])
    if (fieldValues.groupIds && fieldValues.groupIds.length > 0) groupsTouched = true
    if (fieldValues.labelIds && fieldValues.labelIds.length > 0) labelsTouched = true
    if (fieldValues.deviceIds && fieldValues.deviceIds.length > 0) deviceIdsTouched = true
    let saveDisabled = false
    if (!groupsTouched && !labelsTouched && !deviceIdsTouched && !allDevices) {
      saveDisabled = true
    }

    return (
      <>
        <Modal
          visible={showModal}
          onCancel={handleShowModal}
          footer={null}
          width={1024}
          zIndex={3}
          maskClosable={false}
        >
          {this.renderDeleteModal()}
          <h1>{wMatrix('scheduledReport')}</h1>
          <div style={{ width: '80%' }}>
            <Divider style={{ marginTop: 0 }} />
          </div>
          <div style={{ width: '100%', marginBottom: '35px' }}>
            <Form className="reportInputForm" onSubmit={this.handleSubmit}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '90%' }}>

                <h2 style={styles.h2}>{wMatrix('name')}</h2>
                <div style={styles.row}>
                  <div style={{ width: '100px' }} />
                  <Form.Item style={{ width: '40%' }}>
                    {form.getFieldDecorator('scheduledReportName', {
                      initialValue: scheduledInputs ? scheduledInputs.name : '',
                      rules: [{ required: true, message: wMatrix('This field is required') }],
                    })(<Input placeholder={wMatrix('name')} />)}
                  </Form.Item>
                </div>

                {driversOnly ? <div /> : (
                  <>
                    <h2 style={styles.h2}>{wMatrix('devices')}</h2>
                    <div style={styles.row}>
                      <div style={styles.filters}>
                        <div style={styles.formItem}>
                          <div style={styles.formLabel}>
                            <h4 style={styles.h4}>{`${wMatrix('all')} ${wMatrix('Vehicles')}: `}</h4>
                          </div>
                          <div style={{ flexGrow: 1 }}>
                            <Checkbox
                              id="allDevices"
                              checked={allDevices}
                              onChange={e => this.handleChangeCheckbox(e, form)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {allDevices || deviceIdsTouched ? <div /> : (
                  <>
                    <div style={styles.row}>
                      <div style={{ width: '17.5%' }} />
                      <div style={{ width: '35%', margin: '0 0 0 15px' }}><Divider /></div>
                      <p style={styles.p}>OR</p>
                      <div style={{ width: '35%', margin: '0 15px 0 0' }}><Divider /></div>
                    </div>
                    <div style={styles.row}>
                      {inputs.groupsItem}
                      {inputs.labelsItem}
                    </div>
                  </>
                )}
                {allDevices || groupsTouched || labelsTouched ? <div /> : (
                  <>
                    <div style={styles.row}>
                      <div style={{ width: '17.5%' }} />
                      <div style={{ width: '35%', margin: '0 0 0 15px' }}><Divider /></div>
                      <p style={styles.p}>OR</p>
                      <div style={{ width: '35%', margin: '0 15px 0 0' }}><Divider /></div>
                    </div>
                    <div style={styles.row}>
                      {inputs.deviceIdsItem}
                    </div>
                  </>
                )}

                {inputs.filters.length < 1 ? <div /> : (
                  <>
                    <h2 style={styles.h2}>{wMatrix('filters')}</h2>
                    <div style={styles.row}>
                      <div style={styles.filters}>
                        {inputs.filters}
                      </div>
                    </div>
                  </>
                )}

                <h2 style={styles.h2}>{wMatrix('schedule')}</h2>
                {scheduleInputs}

                <h2 style={styles.h2}>{wMatrix('recipients')}</h2>
                <div style={{ marginLeft: '100px' }}>
                  {recipientsInput}
                </div>

              </div>
            </Form>
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
            <Alert
              style={{
                display: alert.display,
                width: '275px',
                margin: '0px 10px',
              }}
              closable
              showIcon
              message={alert.message}
              type={alert.type}
            />
          </div>
          <div style={{ display: 'flex' }}>
            {scheduledInputs && scheduledInputs.reportId > 17 ? (
              <Button onClick={this.handleShowDeleteModal} type="danger">
                {wMatrix('Delete')}
              </Button>
            ) : <div />}
            <div style={{ width: '70%' }} />
            <Button onClick={handleShowModal} type="default">
              {wMatrix('Cancel')}
            </Button>
            <div style={{ width: '10px' }} />
            <Button
              onClick={this.handleSubmit}
              type="primary"
              disabled={saveDisabled}
            >
              {wMatrix('save')}
            </Button>
          </div>
        </Modal>
      </>
    )
  }
}

const WrappedScheduledReportForm = Form.create({ name: 'scheduledReportForm' })(ScheduledReport)

export default helper()(ScheduledReportHOC()(WrappedScheduledReportForm))
