import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import Card from '@atom/card'
import Skeleton from '@atom/skeleton'
import DSCTable from '@atom/driver-scorecard/dscTable'
import helper from '@helper'

class DriverStandings extends Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    setDSCNav: PropTypes.func.isRequired,
    drivers: PropTypes.array,
    multipleWeeks: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
  }

  static defaultProps = {
    drivers: null,
    loading: false,
  }

  /**
   * Renders details button to the right of the card
   */
  renderPostTitle = () => {
    const { wMatrix, setDSCNav } = this.props
    return (
      <div style={{
        display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center',
      }}
      >
        <span style={{ color: 'rgba(0,0,0,0.65)' }}>
          {`(${wMatrix('thisWeek')})`}
        </span>
        <Button
          size="small"
          style={{ marginRight: 8 }}
          onClick={() => setDSCNav('standings')}
        >
          {wMatrix('details')}
        </Button>
      </div>
    )
  }

  /**
   * Renders total drivers count
   */
  renderTotalDrivers = () => {
    const { wMatrix, drivers, loading } = this.props

    return (
      <div style={{
        marginLeft: 20, alignSelf: 'flex-start', display: 'flex', alignItems: 'center',
      }}
      >
        <h3 style={{ marginRight: '1ch', marginBottom: 0 }}>
          {`${wMatrix('totalDrivers')}: `}
        </h3>
        {!loading && drivers
          ? <h3 style={{ marginBottom: 0 }}>{drivers.length}</h3>
          : <Skeleton wrapperStyle={{ width: '4ch', height: '1em' }} />
        }
      </div>
    )
  }

  render() {
    const {
      wMatrix, drivers, loading, multipleWeeks,
    } = this.props
    return (
      <Card
        title={wMatrix('standings')}
        postTitle={this.renderPostTitle()}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          flexShrink: 0,
          width: '100%',
          height: '100%',
        }}
        >
          {this.renderTotalDrivers()}
          <DSCTable
            wMatrix={wMatrix}
            drivers={drivers}
            multipleWeeks={multipleWeeks}
            loading={loading}
            view="dashboard"
          />
        </div>
      </Card>
    )
  }
}

export default helper()(DriverStandings)
