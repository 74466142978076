import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DeviceList from '@page/main/organisms/deviceList'
import { Input, Select } from 'antd'
import TagFilter from '@atom/tagsFilterDropDown'
import AddressAutoComplete from '@mol/addressAutoCompleteWithButton'
import Analytics from '@analytics'
import helper from '@helper'
import LeftContainer from './left.container'

const { Search } = Input
const { Option } = Select

const styles = {
  formContainer: {
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formItem: {
    width: '100%',
    marginBottom: 10,
  },
  addressSearch: {
    marginBottom: '15px',
  },
  sortByDropdown: {
    width: '100%',
    marginTop: 10,
  },
  search: {
    marginBottom: 10,
  },
}

/**
 * Composition of the LeftContainer
 */
class LeftDistanceContainer extends Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    selectModal: PropTypes.func.isRequired,
    mainNav: PropTypes.string,
    ifCheckDistance: PropTypes.bool,
    setNav: PropTypes.func.isRequired,
    deviceListMode: PropTypes.string,
    saveContactMeta: PropTypes.func.isRequired,
    mapIconMode: PropTypes.string,
    mapIconSizeMode: PropTypes.string,
    filterDevice: PropTypes.func.isRequired,
    existingTagList: PropTypes.array,
    filterTags: PropTypes.array,
    filterDeviceByTag: PropTypes.func.isRequired,
    sortedDevicelist: PropTypes.array,
    selectedDevice: PropTypes.object,
    selectDevice: PropTypes.func.isRequired,
    formatVehicleStatus: PropTypes.func.isRequired,
    setOriginPoint: PropTypes.func.isRequired,
    changeSortBy: PropTypes.func.isRequired,
    hoverDevice: PropTypes.func.isRequired,
    sortby: PropTypes.string,
    filterStr: PropTypes.string,
    setIfCheckDistance: PropTypes.func.isRequired,
    searchby: PropTypes.string,
    changeSearchBy: PropTypes.func.isRequired,
    hasLandmarks: PropTypes.bool,
    landmarks: PropTypes.array,
    changeLandmarkSelection: PropTypes.func.isRequired,
    setOriginFlag: PropTypes.bool,
  }

  static defaultProps = {
    mainNav: 'distance',
    deviceListMode: 'comfortable',
    mapIconMode: 'comfortable',
    mapIconSizeMode: 'large',
    ifCheckDistance: false,
    existingTagList: [],
    sortedDevicelist: [],
    selectedDevice: null,
    sortby: 'alias',
    searchby: 'address',
    filterStr: '',
    filterTags: [],
    hasLandmarks: false,
    landmarks: [],
    setOriginFlag: null,
  }

  /**
   * @private
   */
  filterDevice = (e) => {
    const { filterDevice } = this.props

    filterDevice(e.target.value)
  }

  /**
   * Callback function passed to addressAutoComplete to obtain and use the coordinates
   * @param {Object} coordinates Object containing `lat` and `lng` values
   */
  addressSearchButtonClick = (coordinates) => {
    const { mainNav, setOriginPoint } = this.props
    /** @analytics Record address search */
    Analytics.record({
      feature: 'filter',
      page: `${mainNav}`,
      event: 'clicked_address_search',
    })
    setOriginPoint(coordinates)
  }


  renderDistanceCenterElement = () => {
    const {
      wMatrix,
      searchby,
      landmarks,
      changeLandmarkSelection,
    } = this.props

    if (searchby && searchby === 'landmark') {
      const landmarksClone = JSON.parse(JSON.stringify(landmarks))
      const landmarksOptions = landmarksClone.map((landmark) => {
        const { id } = landmark
        return (
          <Select.Option
            key={`${id}`}
            value={id}
          >
            {landmark.name}
          </Select.Option>
        )
      })

      return (
        <Select
          style={styles.formItem}
          onChange={changeLandmarkSelection}
          placeholder={wMatrix('selectLandmark')}
        >
          {landmarksOptions}
        </Select>
      )
    }

    return (
      <AddressAutoComplete
        onButtonClick={this.addressSearchButtonClick}
        wMatrix={wMatrix}
      />
    )
  }

  renderHeaderDetails = () => {
    const {
      wMatrix, existingTagList, filterDeviceByTag, filterTags,
      changeSortBy, ifCheckDistance, sortby, filterStr, searchby,
      hasLandmarks, changeSearchBy,
    } = this.props

    return (
      <div style={styles.formContainer}>
        <Select
          style={styles.formItem}
          placeholder={`${wMatrix('searchBy')}:`}
          onChange={changeSearchBy}
          value={searchby}
        >
          <Option value="address">{wMatrix('Search by: Address')}</Option>
          <Option value="landmark" disabled={!hasLandmarks}>{wMatrix('Search by: Landmark')}</Option>
        </Select>

        {this.renderDistanceCenterElement()}

        <Search
          style={styles.formItem}
          placeholder={wMatrix('Search for Device')}
          onChange={this.filterDevice}
          allowClear
          defaultValue={filterStr}
        />
        <TagFilter
          className="tagFilter"
          existingTagList={existingTagList}
          handleFilterChange={filterDeviceByTag}
          defaultValues={filterTags}
          valueKey="id"
          width="100%"
        />
        <Select
          style={{ ...styles.formItem, marginTop: 10 }}
          placeholder={`${wMatrix('sortBy')}:`}
          onChange={changeSortBy}
          value={sortby}
        >
          <Option value="alias">{wMatrix('Sort by: Alias')}</Option>
          <Option value="distance" disabled={!ifCheckDistance}>{wMatrix('Sort by: Distance')}</Option>
        </Select>
      </div>
    )
  }

  setNav = (navString) => {
    const { setIfCheckDistance, setNav } = this.props
    if (navString !== 'distance') {
      setIfCheckDistance(false)
    }
    setNav(navString)
  }

  render() {
    const {
      mainNav, deviceListMode, sortedDevicelist, selectedDevice,
      selectDevice, formatVehicleStatus, ifCheckDistance,
      hoverDevice, selectModal, mapIconMode, mapIconSizeMode,
      saveContactMeta, setOriginFlag,
    } = this.props

    return (
      <LeftContainer
        mainNav={mainNav}
        setNav={this.setNav}
        deviceListMode={deviceListMode}
        saveContactMeta={saveContactMeta}
        mapIconMode={mapIconMode}
        mapIconSizeMode={mapIconSizeMode}
        renderHeaderDetails={this.renderHeaderDetails}
        selectModal={selectModal}
      >
        <DeviceList
          deviceList={sortedDevicelist}
          listMode={deviceListMode}
          selectedDevice={selectedDevice}
          selectDevice={selectDevice}
          hoverDevice={hoverDevice}
          deviceListMode={deviceListMode}
          formatVehicleStatus={formatVehicleStatus}
          ifShowDistance={ifCheckDistance}
          // used to force update the list after searching an address
          setOriginFlag={setOriginFlag}
        />
      </LeftContainer>
    )
  }
}

export default helper()(LeftDistanceContainer)
