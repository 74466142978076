import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch, Card } from 'antd'

export default class ToggleCard extends Component {
    static propTypes = {
      toggleClick: PropTypes.func.isRequired,
      toggleVal: PropTypes.bool,
      titleVal: PropTypes.string,
      disabled: PropTypes.bool,
    }


    static defaultProps = {
      toggleVal: false,
      titleVal: 'Email',
      disabled: false,
    }

    onChange = () => {
      const { toggleClick } = this.props
      toggleClick()
    }

    render() {
      const { titleVal, toggleVal, disabled } = this.props
      return (
        <Card size="small" style={{ maxWidth: '530px', margin: '10px 0px 10px 0px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            {titleVal}
            <Switch
              defaultChecked={toggleVal}
              onChange={this.onChange}
              checked={toggleVal}
              disabled={disabled}
            />
          </div>
        </Card>
      )
    }
}
