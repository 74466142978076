import React, { PureComponent } from 'react'
import { Menu, Dropdown } from 'antd'

import PropTypes from 'prop-types'
import helper from '@helper'


class Profile extends PureComponent {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    getPermission: PropTypes.func.isRequired,
    selectModal: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired,
    style: PropTypes.object.isRequired,
  }

  /**
   * @private
   *
   * Checks permissions and renders admin menu item
   */
  renderAdmin = () => {
    const { getPermission, wMatrix, selectModal } = this.props

    if (getPermission('Admin', 'ynRead')) {
      return (
        <Menu.Item key="0" onClick={() => selectModal('admin')}>
          <p>{wMatrix('Admin')}</p>
        </Menu.Item>
      )
    }

    return null
  }

  /**
   * @private
   *
   * Checks permissions and renders preferences menu item
   */
  renderPreferences = () => {
    const { getPermission, wMatrix, selectModal } = this.props

    if (getPermission('Preference', 'ynRead')) {
      return (
        <Menu.Item key="1" onClick={() => selectModal('preference')}>
          <p>{wMatrix('Preferences')}</p>
        </Menu.Item>
      )
    }

    return null
  }

  menu = () => {
    const { wMatrix, logout } = this.props

    return (
      <Menu style={{ width: 200 }}>
        {this.renderAdmin()}
        {this.renderPreferences()}
        <Menu.Divider />
        <Menu.Item key="2" onClick={logout}>
          <p>{wMatrix('Log out')}</p>
        </Menu.Item>
      </Menu>
    )
  }

  render() {
    const { icon, style } = this.props

    return (
      <Dropdown overlay={this.menu} placement="bottomRight" trigger={['click']}>
        <img style={style} src={icon} alt="alt" />
      </Dropdown>
    )
  }
}

export default helper()(Profile)
