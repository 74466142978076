import React, { Component } from 'react'

import { Table, Button } from 'antd'
import PropTypes from 'prop-types'
import { InputSearch } from '@atom/input'
import FilterDropDown from '@atom/tagsFilterDropDown'

import check from '@assets/img/icons/checkmark-circled.svg'

import equal from 'deep-equal'
import WeekSliderCard from './weekSliderCard'

/**
 * search, and table with device alias, email, text, push, \
 * and possible other values (ie speed, details, etc)
 */


/**
 * @todo
 * - maybe pull which eventTypes are general from db
 * - add HOO expanded row render
 */

/**
  * @requires
  * When adding a new table type with new columns, edit adjustTableColumns()
  */

export default class NotificationTable extends Component {
    state = {
      searchVal: '',
      filteredGroups: '',
      tableColumns: [],
      expandedRowKeys: [],
    }

    static propTypes = {
      devices: PropTypes.array,
      eventTypeId: PropTypes.number,
      eventTypeTableIds: PropTypes.array,
      alertMenu: PropTypes.number,
      reset: PropTypes.bool,
      language: PropTypes.string.isRequired,
      getAlertSettingsDeviceList: PropTypes.func.isRequired,
      checkedDeviceKeys: PropTypes.array,
      wMatrix: PropTypes.func.isRequired,
      updateSelectedRows: PropTypes.func.isRequired,
      filterDeviceByTag: PropTypes.func.isRequired,
      alertSettingDevList: PropTypes.array,
      editMode: PropTypes.bool.isRequired,
      handleEditClick: PropTypes.func.isRequired,
      alertSettingsData: PropTypes.object.isRequired,
      formatNullHOO: PropTypes.func.isRequired,
      landmarks: PropTypes.array,
    }

    static defaultProps = {
      devices: [],
      eventTypeId: 17,
      eventTypeTableIds: [17],
      alertMenu: 1,
      reset: false,
      alertSettingDevList: [],
      checkedDeviceKeys: [],
      landmarks: [],
    }

    componentDidUpdate(prevProps) {
      const { getAlertSettingsDeviceList, eventTypeTableIds } = this.props
      if (!equal(prevProps, this.props)) {
        getAlertSettingsDeviceList(eventTypeTableIds)
        this.adjustTableColumns()
        if (!equal(prevProps.eventTypeTableIds, eventTypeTableIds)) {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ expandedRowKeys: [] })
        }
      }
    }

    adjustTableColumns = () => {
      const { language, eventTypeTableIds, wMatrix } = this.props
      const generalTableColumns = [{
        title: wMatrix('Devices'),
        dataIndex: 'alias',
        // width: '50%'
      }, {
        title: wMatrix('email'),
        dataIndex: 'ynEmail',
        align: 'center',
        width: language === 'EN' ? '60px' : '100px',
        render: boolVal => (
          boolVal ? <img src={check} alt="yes" /> : null
        ),
      }, {
        title: wMatrix('text'),
        dataIndex: 'ynText',
        align: 'center',
        width: language === 'EN' ? '60px' : '100px',
        render: boolVal => (
          boolVal ? <img src={check} alt="yes" /> : null
        ),
      },
      {
        title: wMatrix('app'),
        dataIndex: 'ynApp',
        align: 'center',
        width: language === 'EN' ? '60px' : '100px',
        render: boolVal => (
          boolVal ? <img src={check} alt="yes" /> : null
        ),
      },
      /**
       * @TEMP Removing push until implemented
       */
      // {
      //   title: wMatrix('push'),
      //   dataIndex: 'ynPush',
      //   align: 'center',
      //   width: language === 'EN' ? '60px' : '100px',
      //   render: boolVal => (
      //     boolVal ? <img src={check} alt="yes" /> : null
      //   ),
      // }
      ]

      // mosl
      if (eventTypeTableIds.includes(30)) {
        generalTableColumns.splice(1, 0, {
          title: wMatrix('milesOver'),
          dataIndex: 'numMOSL',
          align: 'center',
          render: boolVal => (
            boolVal ? <img style={{ display: 'block', margin: 'auto' }} src={check} alt="yes" /> : null
          ),
        })
      }
      // speed thresh
      if (eventTypeTableIds.includes(1)) {
        generalTableColumns.splice(2, 0, {
          title: wMatrix('speedThreshold'),
          dataIndex: 'numSpeed',
          align: 'center',
        })
      }
      // accel
      if (eventTypeTableIds.includes(14)) {
        generalTableColumns.splice(1, 0, {
          title: wMatrix('Acceleration'),
          dataIndex: 'accel',
          align: 'center',
          width: '105px',
          render: boolVal => (
            boolVal ? <img src={check} alt="yes" /> : null
          ),
        })
      }
      // decel
      if (eventTypeTableIds.includes(15)) {
        generalTableColumns.splice(2, 0, {
          title: wMatrix('Deceleration'),
          dataIndex: 'decel',
          align: 'center',
          width: language === 'EN' ? '105px' : '120px',
          render: boolVal => (
            boolVal ? <img src={check} alt="yes" /> : null
          ),
        })
      }
      // movement initiate
      if (eventTypeTableIds.includes(20)) {
        generalTableColumns.splice(1, 0, {
          title: wMatrix('Movement Initiated'),
          dataIndex: 'movementInitiated',
          align: 'center',
          width: '105px',
          render: boolVal => (
            boolVal ? <img src={check} alt="yes" /> : null
          ),
        })
      }
      // movement concluded
      if (eventTypeTableIds.includes(27)) {
        generalTableColumns.splice(2, 0, {
          title: wMatrix('Movement Concluded'),
          dataIndex: 'movementConcluded',
          align: 'center',
          width: language === 'EN' ? '105px' : '120px',
          render: boolVal => (
            boolVal ? <img src={check} alt="yes" /> : null
          ),
        })
      }
      // reconnect
      if (eventTypeTableIds.includes(21)) {
        generalTableColumns.splice(1, 0, {
          title: wMatrix('Reconnect'),
          dataIndex: 'reconnect',
          align: 'center',
          width: language === 'EN' ? '105px' : '120px',
          render: boolVal => (
            boolVal ? <img src={check} alt="yes" /> : null
          ),
        })
      }
      // disconnect
      if (eventTypeTableIds.includes(3)) {
        generalTableColumns.splice(2, 0, {
          title: wMatrix('Disconnect'),
          dataIndex: 'disconnect',
          align: 'center',
          width: language === 'EN' ? '105px' : '120px',
          render: boolVal => (
            boolVal ? <img src={check} alt="yes" /> : null
          ),
        })
      }
      // secondary on,off
      if (eventTypeTableIds.includes(39)) {
        generalTableColumns.splice(1, 0, {
          title: wMatrix('Secondary'),

          children: [{
            title: wMatrix('On'),
            dataIndex: 'secondaryOn',
            width: '50px',
            align: 'center',
            render: boolVal => (
              boolVal ? <img src={check} alt="yes" /> : null
            ),
          }, {
            title: wMatrix('Off'),
            dataIndex: 'secondaryOff',
            width: '50px',
            align: 'center',
            render: boolVal => (
              boolVal ? <img src={check} alt="yes" /> : null
            ),
          }],
        })
      }
      // primary on,off
      if (eventTypeTableIds.includes(37)) {
        generalTableColumns.splice(1, 0, {
          title: wMatrix('Primary'),
          children: [{
            title: wMatrix('On'),
            dataIndex: 'primaryOn',
            width: '50px',
            align: 'center',
            render: boolVal => (
              boolVal ? <img src={check} alt="yes" /> : null
            ),
          }, {
            title: wMatrix('Off'),
            dataIndex: 'primaryOff',
            width: '50px',
            align: 'center',
            render: boolVal => (
              boolVal ? <img src={check} alt="yes" /> : null
            ),
          }],
        })
      }
      this.setState({ tableColumns: generalTableColumns })
    }

    expandedRowRender = (record) => {
      const {
        eventTypeTableIds, wMatrix, formatNullHOO, landmarks,
      } = this.props
      // This is to render the landmarks
      if (eventTypeTableIds.includes(6) && record.landmarks) {
        const columns = [
          {
            title: wMatrix('Landmark Name'),
            dataIndex: 'name',
            key: 'key',
          },
          {
            title: wMatrix('Trigger'),
            render: (row) => {
              let description
              switch (row.direction) {
                case 'BOTH':
                  description = wMatrix('Enter and Exit')
                  break
                case 'ENTER':
                  description = wMatrix('Enter')
                  break
                case 'EXIT':
                  description = wMatrix('Exit')
                  break
                default:
              }
              return description
            },
          },
        ]
        const data = []
        if (record.landmarks && record.landmarks != null) {
          for (let i = 0; i < record.landmarks.length; i += 1) {
            for (let j = 0; j < landmarks.length; j += 1) {
              /**
               * In mssql landmark alert settings, we only have landmark_id.
               * The landmarks are in Postgres and we should have this info when the customer login
               * No need to have a seperate PostGres call. We can just do an ID comparison to
               * find the landmark name
               *  */
              if (record.landmarks[i].id === landmarks[j].id) {
                data.push({
                  key: i,
                  name: landmarks[j].name,
                  id: record.landmarks[i].id,
                  direction: record.landmarks[i].direction,
                })
              }
            }
          }
        }
        return (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{ pageSize: 5 }}
            size="middle"
          />
        )
      }
      if (eventTypeTableIds.includes(5) && record.hoo) {
        return (
          <WeekSliderCard
            viewOnly
            mon={record.hoo.mon}
            tue={formatNullHOO(record.hoo.tue)}
            wed={formatNullHOO(record.hoo.wed)}
            thu={formatNullHOO(record.hoo.thu)}
            fri={formatNullHOO(record.hoo.fri)}
            sat={formatNullHOO(record.hoo.sat)}
            sun={formatNullHOO(record.hoo.sun)}
            wMatrix={wMatrix}
          />
        )
      }
      return (<div>{wMatrix('noDetails')}</div>)
    }

    getRowClassName = (record) => {
      const { eventTypeTableIds } = this.props
      if (eventTypeTableIds.includes(6) && record.landmarks && record.landmarks.length === 0) {
        return 'hide-expand'
      }
      if (eventTypeTableIds.includes(5) && !record.hoo) {
        return 'hide-expand'
      }
      return ''
    };

    onSelectChange = (onChangeSelectedRowKeys, selectedRows) => {
      const { updateSelectedRows } = this.props
      updateSelectedRows(selectedRows, onChangeSelectedRowKeys)
    }

    updateSearch = (event) => {
      this.setState({ searchVal: event.target.value.substr(0, 20) })
    }

    handleFilterChange = (filterValues) => {
      this.setState({ filteredGroups: filterValues })
    }

    collectExpandedRowKeys = (expanded, record) => {
      const { expandedRowKeys } = this.state
      if (expanded) {
        expandedRowKeys.push(record.id)
      } else {
        expandedRowKeys.splice(expandedRowKeys.indexOf(record.id), 1)
      }
      this.setState({ expandedRowKeys })
    }

    render() {
      const {
        editMode, alertSettingDevList, alertSettingsData,
        wMatrix, handleEditClick, filterDeviceByTag, eventTypeTableIds, checkedDeviceKeys,
      } = this.props
      const {
        filteredGroups, searchVal, tableColumns, expandedRowKeys,
      } = this.state

      let filteredDevices = filterDeviceByTag(alertSettingDevList, filteredGroups)

      if (searchVal) {
        filteredDevices = filteredDevices.filter(
          device => (
            (device.alias.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1)
          ),
        )
      }
      return (
        <div>
          <div style={{ display: editMode ? 'none' : 'flex', flexDirection: 'column' }}>
            <div style={{
              display: 'flex', alignSelf: 'flex-end', marginBottom: '5px', flexDirection: 'column', alignItems: 'flex-end',
            }}
            >
              <InputSearch
                className="groupPanelSearch"
                onChange={this.updateSearch}
                placeholder={wMatrix('Device')}
              />
              <div style={{ marginRight: '20px' }}>
                <FilterDropDown
                  {...this.props}
                  placeholderColor="#595959"
                  handleFilterChange={this.handleFilterChange}
                />
              </div>
            </div>
            <Table
              expandedRowRender={
                (eventTypeTableIds.includes(5) || eventTypeTableIds.includes(6))
                  ? record => this.expandedRowRender(record)
                  : undefined
              }
              dataSource={filteredDevices}
              rowClassName={record => this.getRowClassName(record)}
              columns={tableColumns}
              rowSelection={{
                selectedRowKeys: checkedDeviceKeys,
                onChange: this.onSelectChange,
              }}
              onExpand={this.collectExpandedRowKeys}
              expandedRowKeys={expandedRowKeys}
              pagination={{
                showSizeChanger: true,
                pageSizeOptions: ['10', '25', '50', '100'],
              }}
              rowKey="id"
              size="middle"
              bordered={false}
              loading={alertSettingsData.loading}
            />
            <Button
              type="primary"
              style={{ alignSelf: 'flex-end', marginTop: '12px' }}
              disabled={!((checkedDeviceKeys.length > 0))}
              onClick={handleEditClick}
            >
              Edit
            </Button>
          </div>

        </div>
      )
    }
}
