import React, { Component } from 'react'
import {
  Form, Input, InputNumber, Select,
} from 'antd'
import PropTypes from 'prop-types'
import { EditableContext } from '@mol/editableUserTable'
import Tag from '@atom/tag'
import helper from '../helper'

const { Option } = Select
const FormItem = Form.Item


class EditableCell extends Component {
  static propTypes = {
    inputType: PropTypes.string,
    editing: PropTypes.bool,
    dataIndex: PropTypes.string,
    title: PropTypes.string,
    record: PropTypes.object,
    allGroups: PropTypes.array,
    wMatrix: PropTypes.func.isRequired,
    children: PropTypes.array,
    onRoleChange: PropTypes.func,
  }

  static defaultProps = {
    inputType: 'text',
    editing: false,
    dataIndex: null,
    title: null,
    record: null,
    allGroups: null,
    children: [],
    onRoleChange: null,
  }

  getInput = (recordValue, allGroups, getPermission, wMatrix) => {
    const { inputType, onRoleChange } = this.props
    // text input
    if (inputType === 'text') {
      return <Input size="small" /> // may just remove this.. i beleive phone is stored as string anyways
    }
    // role input
    if (inputType === 'roleDropdown') {
      return (
      // this needs to pull the actual roles
        <Select style={{ width: '100px' }} default={recordValue} onChange={onRoleChange}>
          {getPermission('Global', 'ynCreate') === true ? <Option value={1}>{wMatrix('global')}</Option> : null}
          <Option value={2}>{wMatrix('Admin')}</Option>
          <Option value={3}>{wMatrix('supervisor')}</Option>
          <Option value={6}>{wMatrix('serviceTech')}</Option>
          <Option value={4}>{wMatrix('readOnly')}</Option>
        </Select>
      )
    }
    // groups selection
    if (inputType === 'groupsDropdown') {
      // return ()
      return (
        this.returnGroupSelectBox(false, allGroups)
      // <GroupSelectionBox disable={false} groups={allGroups} />
      )
    }
    // default
    return <InputNumber />
  }

  // returns the selection box
  returnGroupSelectBox = (disable, groups) => {
    const children = []

    for (let i = 0; i < groups.length; i += 1) {
      children.push(
        <Option key={groups[i].id}>
          <div style={{ display: 'flex' }}>
            <Tag
              id={groups[i].id}
              color={groups[i].color}
              name={groups[i].name}
            />
          </div>
        </Option>,
      )
    }

    return (
      <Select
          // if permissions is val 1 or 2:
        disabled={disable}
        mode="multiple"
        style={{ width: '100%' }}
        placeholder={disable ? '(Global and Admin view all)' : 'Select (Optional)'}
        size="small"
      >
        {children}
      </Select>
    )
  }

  getInitialValue = (title, record, dataIndex) => {
    switch (dataIndex) {
      case 'role.id':
        return record.role.id
      case 'groups':
        return record.groups.map(group => String(group.id))
      default:
        return record[dataIndex]
    }
  }

  render() {
    const {
      editing,
      dataIndex,
      title,
      record,
      allGroups,
      wMatrix,
      children,
    } = this.props

    return (
      <EditableContext.Consumer>
        {(valueObj) => {
          //   console.log('valueObj',valueObj)
          const { getFieldDecorator } = valueObj.form
          return (
            <td>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules: [{
                      required: (!((dataIndex === 'phone') || (dataIndex === 'groups') || (dataIndex === 'mobilePhone'))),
                      message: `${wMatrix('pleaseInput')} ${wMatrix(title)}`,
                    }],
                    initialValue: this.getInitialValue(title, record, dataIndex),
                  })(this.getInput(
                    record[dataIndex],
                    allGroups, valueObj.getPermission,
                    valueObj.wMatrix,
                  ))}
                </FormItem>
              ) : children}
            </td>
          )
        }}
      </EditableContext.Consumer>
    )
  }
}

export default helper()(EditableCell)
