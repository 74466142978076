import React, { Component } from 'react'
import { compose } from 'react-apollo'
import PropTypes from 'prop-types'
import helper from '@helper'
import moment from 'moment'
import {
  Tabs, Badge, Table,
  Input, Button,
} from 'antd'
import CustomIcon from '@atom/icon'
import ButtonWrapper from '@atom/textButton'
import SkeletonComponent from '@atom/skeletonComponents'
import VideoClipsHOC from '@hoc/videoClips.hoc'
import CheckBox from '@atom/checkbox'
import OneClickButton from '@atom/oneClickButton'
import EventIcon from '@atom/eventIcon'
import RequestClipForm from '@mol/requestClipForm'

const { TabPane } = Tabs
const { Search } = Input

class VideoClipsView extends Component {
  static propTypes = {
    /** @Helper */
    wMatrix: PropTypes.func.isRequired,
    markClipFavorite: PropTypes.func.isRequired,
    eventToString: PropTypes.func.isRequired,
    getPermission: PropTypes.func.isRequired,
    minutesToStringV2: PropTypes.func.isRequired,
    speedToString: PropTypes.func.isRequired,
    /** @Main */
    clipsSearchValue: PropTypes.string.isRequired,
    updateClipSearch: PropTypes.func.isRequired,
    selectModal: PropTypes.func.isRequired,
    markClipViewed: PropTypes.func.isRequired,
    appHeight: PropTypes.number.isRequired,
    /** @VideoClipsHOC */
    cameraDevices: PropTypes.array.isRequired,
    clipsLoading: PropTypes.bool.isRequired,
    cameraClips: PropTypes.array.isRequired,
    deleteClip: PropTypes.func.isRequired,
    requestCustomCameraClipUpload: PropTypes.func.isRequired,
    refreshClips: PropTypes.func.isRequired,
  }

  viewClip = (record) => {
    const { selectModal, markClipViewed } = this.props
    const {
      eventType, device, replayURL, date, time, id, address, landmarks,
    } = record
    const camModalProps = {
      clipType: 'event',
      device,
      url: replayURL,
      details: {
        eventType,
        date,
        time,
        address,
        landmarks,
      },
    }
    selectModal('camera', camModalProps)
    markClipViewed(id)
  }

  favoriteClipToggle = (clipId, favoriteState) => {
    const { markClipFavorite } = this.props
    markClipFavorite(clipId, favoriteState)
  }

  /**
   * @description - renders event table cell using event (in snake case)
   * @param {String} event event type in snake_case
   */
  renderEventCell = (event) => {
    const { eventToString } = this.props
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <EventIcon event={event} />
        <div style={{ margin: 10 }}>{eventToString(event)}</div>
      </div>
    )
  }

  /**
   * Returns array of column data
   * @param {String} type 'available' or 'pending'
   */
  clipColumns = () => {
    const {
      wMatrix, getPermission, clipsLoading, deleteClip, minutesToStringV2, speedToString,
    } = this.props
    const columns = [
      {
        title: ' ',
        dataIndex: 'operationFavorite',
        align: 'center',
        width: '42px',
        sorter: (a, b) => {
          if (a.favorite === b.favorite) return 0
          if (a.favorite && !b.favorite) return 1
          return -1
        },
        render: (text, record) => {
          const { favorite, id, viewed } = record
          const uploaded = record.status.toLowerCase() === 'uploaded'
          const disableState = !uploaded || !viewed || clipsLoading
          return (
            <CheckBox
              type="star"
              onChange={checkedState => this.favoriteClipToggle(id, checkedState)}
              defaultChecked={favorite}
              disabled={disableState}
            />
          )
        },
      },
      {
        title: ' ',
        dataIndex: 'operationView',
        align: 'center',
        width: '62px',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => {
          if (a.viewed === b.viewed) return 0
          if (a.viewed && !b.viewed) return 1
          return -1
        },
        render: (text, record) => {
          const { viewed } = record
          const uploaded = record.status.toLowerCase() === 'uploaded'
          return (
            <ButtonWrapper
              onClick={() => this.viewClip(record)}
              useAsWrapper
              disabled={!uploaded}
            >
              <Badge
                className="clipBadge"
                dot
                // count={1}
                count={uploaded && !viewed ? 1 : 0}
                style={{ height: 8, width: 8 }}
                offset={[-18, 0]}
              >
                <CustomIcon
                  type={uploaded ? 'tv-play' : 'tv-loading'}
                  width={18}
                />
              </Badge>
            </ButtonWrapper>
          )
        },
      },
      {
        title: 'Alias',
        dataIndex: 'device.alias',
        key: 'alias',
        width: '200px',
        sorter: (a, b) => a - b,
      },
      {
        title: 'Drivers',
        dataIndex: 'drivers',
        key: 'drivers',
        width: '200px',
        // sorter: (a, b) => a - b,
        render: (text, record) => {
          if (typeof record.drivers !== 'object' || !record.drivers) return null
          return (
            <div>
              {record.drivers.map(driver => <div key={`${driver.id}`}>{driver.name}</div>)}
            </div>
          )
        },
      },
      {
        title: 'Event',
        dataIndex: 'eventType',
        key: 'event',
        width: '200px',
        sorter: (a, b) => a - b,
        render: (item) => {
          // if data is not string, its an object
          if (item) {
            return this.renderEventCell(item)
          }
          return null
        },
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        width: '100px',
        sorter: (a, b) => moment(a.datetime, 'YYYY-MM-DD HH:mm a') - moment(b.datetime, 'YYYY-MM-DD HH:mm a'),
      },
      {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
        width: '100px',
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        width: '200px',
        render: (item) => {
          if (item) {
            return (
              <div>
                <div>{`${item.street || ''}`}</div>
                <div>{`${item.city || ''}${item.city ? ', ' : ''}${item.state || ''}`}</div>
              </div>
            )
          }
          return null
        },
      },
      {
        title: 'Landmarks',
        dataIndex: 'landmarks',
        key: 'landmarks',
        width: '200px',
        render: (text) => {
          let list = []
          if (typeof text === 'string') {
            try {
              list = JSON.parse(text)
            } catch (err) {
              list = []
            }
          } else {
            list = text
          }

          let listString = ''
          if (list && list !== undefined && list.length > 0) {
            list = list.map(landmark => landmark.name)
            for (let i = 0; i < list.length; i += 1) {
              listString = `${listString}${i === 0 ? '' : ','} ${list[i].replace('"', '')}`
            }
          }
          return <div>{listString}</div>
        },
      },
      {
        title: 'Details',
        dataIndex: 'eventDetails',
        key: 'eventDetails',
        width: '200px',
        render: (object) => {
          if (!object) return null
          const objectKeys = Object.keys(object)
          return (
            <div>
              {objectKeys.map((key) => {
                if (!object[key]) {
                  return null
                }
                switch (key) {
                  case 'duration':
                    return (<div key={key}>{`${wMatrix('eventDuration')}: ${minutesToStringV2(object[key])}`}</div>)
                  case 'endtime':
                    return (<div key={key}>{`${wMatrix('endedAt')}: ${moment(object[key]).format('h:mm a')}`}</div>)
                  case 'mosl':
                    return (<div key={key}>{`${wMatrix('milesOverPostedSpeed')}: ${minutesToStringV2(object[key])}`}</div>)
                  case 'speed':
                    return (<div key={key}>{`${wMatrix('Speed')}: ${speedToString(object[key])}`}</div>)
                  case 'roadspeed':
                    return (<div key={key}>{`${wMatrix('postedSpeedLimit')}: ${speedToString(object[key])}`}</div>)
                  default:
                    return (<div key={key}>{`${wMatrix(key)}: ${object[key]}`}</div>)
                }
              })}
            </div>
          )
        },
      },
    ]
    if (getPermission('Camera', 'ynDelete')) {
      columns.push({
        title: ' ',
        dataIndex: 'delete',
        width: '54px',
        render: (text, record) => (
          <div>
            <span style={{ verticalAlign: 'middle' }}>
              <OneClickButton
                loading={clipsLoading}
                title={`${wMatrix('okToDelete')}?`}
                okText={wMatrix('Delete')}
                cancelText={wMatrix('Cancel')}
                onClick={() => deleteClip(record.id, record.deviceId)}
                okButtonProps={{
                  type: 'danger',
                }}
                iconType="delete"
              />
            </span>
          </div>
        ),
      })
    }
    return columns
  }

  /**
   * Returns div containing the search field as well as the refresh button
   */
  renderSearchAndRefreshButton = () => {
    const {
      refreshClips, clipsLoading, updateClipSearch, clipsSearchValue,
    } = this.props
    return (
      <div style={{
        display: 'flex', flexDirection: 'row', alignItems: 'center', alignSelf: 'flex-end', marginBottom: '5px',
      }}
      >
        <Search
          placeholder="search"
          className="groupPanelSearch"
          value={clipsSearchValue}
          onChange={e => updateClipSearch(e.target.value)}
        />
        <Button
          icon="reload"
          onClick={() => refreshClips()}
          disabled={clipsLoading}
          style={{ height: 28, width: 28 }}
        />
      </div>
    )
  }

  renderAvailableTable = () => {
    const {
      cameraClips, clipsLoading, appHeight,
    } = this.props
    if (clipsLoading && cameraClips.length === 0) {
      return (<SkeletonComponent type="table" />)
    }
    let availableClips = JSON.parse(JSON.stringify(cameraClips))
    availableClips = availableClips.filter(clip => clip.status.toLowerCase() === 'uploaded')
    // containerContentsHeight
    // 106 above container, 34 below container, 10 padding top and bottom
    const containerContentsHeight = appHeight - 106 - 34 - 20
    // 36 accounts for request button
    const availableTableHeight = containerContentsHeight - 170 - 48 - 36
    return (
      <div style={{
        display: 'flex', marginTop: 10, flexDirection: 'column', height: '100%', width: '100%',
      }}
      >
        {this.renderSearchAndRefreshButton()}
        <div style={{ flexGrow: 1 }}>
          <Table
            className="overFlowedTable"
            columns={this.clipColumns()}
            dataSource={availableClips}
            rowKey="id"
            size="middle"
            pagination={{
              defaultPageSize: 50, pageSizeOptions: ['50', '100', '250', '500'], showSizeChanger: true,
            }}
            scroll={{
              y: availableTableHeight, // `calc(100vh - ${paddingTop})`,
              x: true,
            }}
          />
        </div>
      </div>
    )
  }

  renderPendingTable = () => {
    const {
      cameraClips, clipsLoading, appHeight,
    } = this.props
    if (clipsLoading && cameraClips.length === 0) {
      return (<SkeletonComponent type="table" />)
    }
    let pendingClips = JSON.parse(JSON.stringify(cameraClips))
    pendingClips = pendingClips.filter(clip => clip.status.toLowerCase() !== 'uploaded')
    // containerContentsHeight
    // 106 above container, 34 below container, 10 padding top and bottom
    const containerContentsHeight = appHeight - 106 - 34 - 20
    // 36 accounts for request button
    const availableTableHeight = containerContentsHeight - 170 - 48 - 36
    return (
      <div style={{
        display: 'flex', marginTop: 10, flexDirection: 'column', height: '100%', width: '100%',
      }}
      >
        {this.renderSearchAndRefreshButton()}
        <div style={{ flexGrow: 1 }}>
          <Table
            className="overFlowedTable"
            columns={this.clipColumns()}
            dataSource={pendingClips}
            rowKey="id"
            size="middle"
            pagination={{
              defaultPageSize: 50, pageSizeOptions: ['50', '100', '250', '500'], showSizeChanger: true,
            }}
            scroll={{
              y: availableTableHeight, // `calc(100vh - ${paddingTop})`,
              x: true,
            }}
          />
        </div>
      </div>
    )
  }

  render() {
    const {
      wMatrix, cameraDevices, minutesToStringV2,
      requestCustomCameraClipUpload,
    } = this.props
    return (
      <div style={{ height: '100%', width: '100%', padding: '10px 50px' }}>
        <RequestClipForm
          wMatrix={wMatrix}
          cameraDevices={cameraDevices}
          minutesToStringV2={minutesToStringV2}
          requestCustomCameraClipUpload={requestCustomCameraClipUpload}
        />
        <Tabs defaultActiveKey="1" style={{ height: '100%', width: '100%' }}>
          <TabPane tab={wMatrix('availableClips')} key="1">
            {this.renderAvailableTable()}
          </TabPane>
          <TabPane tab={wMatrix('pending')} key="2">
            {this.renderPendingTable()}
          </TabPane>
        </Tabs>
      </div>
    )
  }
}

export default compose(
  helper(),
  VideoClipsHOC(),
)(VideoClipsView)
