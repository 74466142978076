import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import Menu from '@mol/fullScreenMenu'
// make progress bar an atom?

const styles = {
  dot: {
    height: '6px',
    width: '6px',
    flexShrink: 0,
    borderRadius: '50%',
    margin: '0 0px 0px 0',
  },
  dash: {
    height: '2px',
    width: '3px',
    margin: 0,
    backgroundColor: '#A7A5A2',
  },
  driver: {
    margin: 0,
    color: 'grey',
    fontSize: '10px',
  },
  itemWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '5px',
    paddingLeft: '5px',
    overflow: 'hidden',
  },
  textEllipse: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  progressContainer: {
    justifyContent: 'flex-start',
    marginTop: '5px',
    paddingBottom: '2px',
  },
}

const colors = {
  active: '#4482FF',
  overdue: '#FF5500',
  completed: '#87D068',
  pending: '#A7A5A2',
  sent_unconfirmed: '#FFBA44',
  driver_unconfirmed: '#A7A5A2',
  driver_confirmed: '#A7A5A2',
  unassigned: '#A7A5A2',
}

export default class LogisticsMenu extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    menuItems: PropTypes.array.isRequired,
    onGroupChange: PropTypes.func.isRequired,
    filterMenu: PropTypes.func.isRequired,
    navCreate: PropTypes.func.isRequired,
    handleSelectJob: PropTypes.func.isRequired,
    selectedKey: PropTypes.string,
  }

  static defaultProps = {
    selectedKey: null,
  }

  /**
   * @public
   * @returns {Component}
   */
  renderPostHeader = () => {
    const { wMatrix, onGroupChange } = this.props
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 4,
      }}
      >
        {`${wMatrix('groupBy')}:`}
        <Select defaultValue="status" style={{ width: 150 }} onChange={onGroupChange}>
          <Select.Option value="status">{wMatrix('status')}</Select.Option>
          <Select.Option value="device">{wMatrix('Device')}</Select.Option>
        </Select>
      </div>
    )
  }

  renderStatus = (status) => {
    const { wMatrix } = this.props

    switch (status) {
      case 'completed':
        return (
          <div style={{ display: 'flex', alignItems: 'center', fontSize: '10px' }}>
            <div style={{ ...styles.dot, backgroundColor: colors.completed }} />
            <div style={{ width: '3px' }} />
            {wMatrix('Completed')}
          </div>
        )
      case 'overdue':
        return (
          <div style={{ display: 'flex', alignItems: 'center', fontSize: '10px' }}>
            <div style={{ ...styles.dot, backgroundColor: colors.overdue }} />
            <div style={{ width: '3px' }} />
            {wMatrix('Overdue')}
          </div>
        )
      case 'unassigned':
        return (
          <div style={{ display: 'flex', alignItems: 'center', fontSize: '10px' }}>
            <div style={{ ...styles.dot, backgroundColor: colors.unassigned }} />
            <div style={{ width: '3px' }} />
            {wMatrix('Unassigned')}
          </div>
        )
      default:
        return (
          <div style={{ display: 'flex', alignItems: 'center', fontSize: '10px' }}>
            <div style={{ ...styles.dot, backgroundColor: colors.active }} />
            <div style={{ width: '3px' }} />
            {wMatrix('Active')}
          </div>
        )
    }
  }

  renderProgress = (destinations) => {
    const progress = []

    for (let i = 0; i < destinations.length; i += 1) {
      let color = colors.unassigned // default to unassigned gray
      if (destinations[i].status === 'completed') color = colors.completed // green
      if (i > 0 && destinations[i - 1].status === 'completed'
        && destinations[i].status === 'active'
      ) color = colors.active // blue
      if (destinations[i].status === 'overdue') color = colors.overdue // red
      if (destinations[i].status === 'sent_unconfirmed') color = colors.sent_unconfirmed // yellow
      if (i === 0) {
        if (destinations[i].status === 'active') color = colors.active
        progress.push(
          <div
            key={`${destinations[i].id}_${i}`}
            style={{
              ...styles.dot,
              backgroundColor: color,
            }}
          />,
        )
      } else {
        progress.push(
          <div key={i} style={{ display: 'flex', alignItems: 'center' }}>
            <div key={`dash_${destinations[i].id}_${i}`} style={styles.dash} />
            <div
              key={`${destinations[i].id}_${i}`}
              style={{
                ...styles.dot,
                backgroundColor: color,
              }}
            />
          </div>,
        )
      }
    }
    return progress
  }

  renderItem = (item) => {
    const { wMatrix } = this.props
    if (item.data) {
      return (
        <div style={styles.itemWrapper}>
          <div style={{ ...styles.itemRow, justifyContent: 'space-between' }}>
            <h4 style={{ margin: 0, ...styles.textEllipse, maxWidth: '60%' }}>{item.data.deviceAlias ? item.data.deviceAlias : wMatrix('Unassigned')}</h4>
            <h5 style={{ margin: 0 }}>
              {
                item.data.alias !== 'null'
                  ? `${item.data.alias}`
                  : `${wMatrix('Job')} #${item.data.id}`
              }
            </h5>
          </div>
          <div style={{ ...styles.itemRow, alignItems: 'flex-start' }}>
            <h5 style={{
              ...styles.driver, margin: 0, ...styles.textEllipse, maxWidth: '70%',
            }}
            >
              {item.data.driver}
            </h5>
            {this.renderStatus(item.data.status)}
          </div>
          <div style={{ ...styles.itemRow, ...styles.progressContainer }}>
            {this.renderProgress(item.data.destinations)}
          </div>
        </div>
      )
    }
    return null
  }

  render() {
    const {
      wMatrix, menuItems, filterMenu, handleSelectJob, navCreate, selectedKey,
    } = this.props
    return (
      <Menu
        noSubIndent
        searchPlaceholder={`${wMatrix('Device')}, ${wMatrix('Job')}, ${wMatrix('Driver')}`}
        menuItems={menuItems}
        renderItem={this.renderItem}
        selectItem={handleSelectJob}
        onInputChange={filterMenu}
        handleCreate={navCreate}
        selectedKey={selectedKey}
        postHeader={this.renderPostHeader()}
      />
    )
  }
}
