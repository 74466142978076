import React from 'react'
import PropTypes from 'prop-types'
import {
  Alert, Button, Input, Icon,
} from 'antd'
import ForgotHOC from './forgot.hoc'
import Logo from '../../assets/img/logo.png'

class Forgot extends React.Component {
  state = {
    showPasswordError: false,
    showSystemError: false,
    showSuccess: false,
    passwordInput: '',
    confirmInput: '',
    veriCodeInput: '',
    emailInput: '',
  }

  static propTypes = {
    verifyCodeAndSetPassword: PropTypes.func.isRequired,
  }

  componentDidMount() {
    // Decode parameter from url to get verification code and email address
    const url = window.location.href
    const paramIndex = url.indexOf('?p=')
    const param = url.substring(paramIndex + 3)
    const buf = Buffer.from(param, 'base64')
    const decoded = buf.toString()
    const veriCode = decoded.substring(0, decoded.indexOf('RFT'))
    const email = decoded.substring(decoded.indexOf('RFT') + 3)
    this.setState({ veriCodeInput: veriCode, emailInput: email })
  }

  validatePasswords = () => {
    const { passwordInput, confirmInput } = this.state
    if (passwordInput !== confirmInput || passwordInput.length < 8) {
      this.setState({ showPasswordError: true })
      return false
    }

    this.setState({ showPasswordError: false })
    return true
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      passwordInput, emailInput, veriCodeInput,
    } = this.state
    const { verifyCodeAndSetPassword } = this.props

    if (this.validatePasswords()) {
      verifyCodeAndSetPassword({
        variables: {
          password: passwordInput,
          email: emailInput,
          veriCode: veriCodeInput,
        },
      }).then((result) => {
        if (result.data && result.data.verifyCodeAndSetPassword.code === 1000) {
          this.setState({ showSuccess: true })
          setTimeout(() => {
            window.location.href = 'https://v3.rhinofleettracking.com'
          }, 4000)
        } else {
          this.setState({ showSystemError: true })
        }
      }).catch(() => {
        this.setState({ showSystemError: true })
      })
    }
  }

  renderSuccess() {
    const { showSuccess } = this.state
    let alert = null
    if (showSuccess) {
      alert = (
        <Alert
          style={{ textAlign: 'center' }}
          type="success"
          message={(
            <div>
              Success! Redirecting to login page
              <Icon type="loading" style={{ marginLeft: '10px' }} />
            </div>
          )}
        />
      )
    }
    return alert
  }

  renderPasswordError() {
    const { showPasswordError } = this.state
    let error = null
    if (showPasswordError) {
      error = (
        <Alert
          style={{ textAlign: 'center' }}
          type="error"
          message="Passwords must match and be at least 8 characters"
        />
      )
    }
    return error
  }

  renderSystemError() {
    const { showSystemError } = this.state
    let error = null
    if (showSystemError) {
      error = (
        <Alert
          style={{ textAlign: 'center' }}
          type="error"
          message="Something went wrong, please contact our customer support team"
        />
      )
    }
    return error
  }

  render() {
    const { passwordInput, confirmInput } = this.state

    // TODO
    // graphQL to receive veriCode, email, new password
    // delete vericode record in ForgotVeriCodes
    // set password to new password
    // redirect to login page

    return (
      <div className="isoSignInPage">
        <div className="isoLoginContent">
          <div className="isoLogoWrapper">
            <label><img src={Logo} className="logo" alt="logo" /></label>
          </div>
          {this.renderSuccess()}
          {this.renderSystemError()}
          {this.renderPasswordError()}
          <div className="isoSignInForm">
            <div className="isoInputWrapper">
              <h4>New Password</h4>
              <Input
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="New Password"
                type="password"
                value={passwordInput}
                onChange={e => this.setState({ passwordInput: e.target.value })}
              />
            </div>
            <div className="isoInputWrapper">
              <h4>Confirm New Password</h4>
              <Input
                prefix={<Icon type="unlock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Confirm New Password"
                type="password"
                value={confirmInput}
                onChange={e => this.setState({ confirmInput: e.target.value })}
              />
            </div>
            <div className="isoCenterComponent">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ForgotHOC()(Forgot)
