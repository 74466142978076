/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Layout,
} from 'antd'
import profileIcon from '@assets/img/icons/profile_icon.svg'
import helpIcon from '@assets/img/icons/help_icon.svg'
import Profile from '@mol/header/profile'
import Logo from '@atom/logo'
import NotifMenu from '@page/main/organisms/notificationMenu'
import ButtonWrapper from '@atom/textButton'

import DynamicMenu from '@mol/dynamicMenu'

const { Header } = Layout

const styles = {
  container: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'row',
    height: 50,
  },
  logoContainer: {
    height: '90%',
    alignSelf: 'center',
    flexShrink: 0,
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  rightContent: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
    alignItems: 'center',
  },
  divider: {
    width: 1,
    marginTop: 5,
    marginLeft: 8,
    marginRight: 12,
    marginBottom: 5,
    backgroundColor: '#D3D3D3D3',
  },
  menuContainer: {
    maxWidth: '50%',
    margin: '0px 15px',
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 64,
    height: 50,
  },
  profileIcon: {
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 5,
    padding: '4px 0 4px 10px',
    height: 40,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 15,
  },
}

class HeaderContainer extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    selectModal: PropTypes.func.isRequired,
    setNav: PropTypes.func.isRequired,
    selectedKey: PropTypes.string.isRequired,
    navKeys: PropTypes.object.isRequired,
    hasGarmin: PropTypes.bool.isRequired,
    /** @Helper */
    wMatrix: PropTypes.func.isRequired,
    hasFeature: PropTypes.func.isRequired,
    getPermission: PropTypes.func.isRequired,
    /** @ContactMetaHOC */
    logo: PropTypes.object,
  }

  static defaultProps = {
    logo: null,
  }

  handleUserResourcesClick = () => {
    const { selectModal } = this.props
    selectModal('userResources')
  }

  verticalDivider = () => (
    <div
      style={{
        width: 1,
        height: 35,
        backgroundColor: 'white',
        margin: '2px 15px',
        flexShrink: 0,
      }}
    />
  )

  render() {
    const {
      setNav, selectedKey, wMatrix, navKeys, hasFeature, getPermission, hasGarmin, logo,
    } = this.props

    const overrideKeys = {}
    overrideKeys[navKeys.LANDMARKS] = navKeys.MAP
    overrideKeys[navKeys.DISTANCE] = navKeys.MAP
    if (!hasFeature('ELD') || !getPermission('ELD', 'ynRead')) {
      overrideKeys[navKeys.ELD] = null
    }
    if (!hasFeature('Cameras') || !getPermission('Camera', 'ynRead')) {
      overrideKeys[navKeys.VIDEOCLIPS] = null
    }
    if (!getPermission('Reports', 'ynRead')) {
      overrideKeys[navKeys.REPORTS] = null
    }
    if (!getPermission('DriverScorecard', 'ynRead')) {
      overrideKeys[navKeys.DRIVERSCORECARD] = null
    }
    if (!hasFeature('Logistics') && !hasFeature('Field Service') && !hasGarmin) {
      overrideKeys[navKeys.LOGISTICS] = null
    }
    const keyValues = Object.values(navKeys)
    const menuItems = keyValues.map(key => ({
      key,
      title: wMatrix(key),
    }))

    return (
      <Header style={styles.container}>
        <div style={styles.logoContainer}>
          <Logo logo={logo} onClick={() => setNav(navKeys.MAP)} />
        </div>
        <div style={styles.headerContent}>
          <div style={styles.menuContainer}>
            <DynamicMenu
              onSelect={setNav}
              selectedKey={selectedKey}
              wMatrix={wMatrix}
              menuItems={menuItems}
              overrideKeys={overrideKeys}
            />
          </div>
          <div style={{ ...styles.rightContent }}>
            <NotifMenu />
            {this.verticalDivider()}
            <ButtonWrapper
              onClick={this.handleUserResourcesClick}
              useAsWrapper
              style={styles.buttonWrapper}
            >
              <img src={helpIcon} alt="help" />
            </ButtonWrapper>
            <Profile
              style={styles.profileIcon}
              icon={profileIcon}
              {...this.props}
            />
          </div>
        </div>
      </Header>
    )
  }
}

export default HeaderContainer
