import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MessengerWindow from '@page/main/molecules/messengerWindow'


const styles = {
  messengersContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    height: '24px',
    marginRight: 15,
  },
}

/**
 * This should contain all the active message windows and connect to the HOC
 */
export default class MessengerView extends Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    sendMessage: PropTypes.func,
    activeMessengers: PropTypes.array,
    closeMessenger: PropTypes.func,
    markMessagesRead: PropTypes.func,
  }

  static defaultProps = {
    sendMessage: null,
    activeMessengers: [],
    closeMessenger: null,
    markMessagesRead: null,
  }

  render() {
    const {
      wMatrix, activeMessengers, closeMessenger, sendMessage, markMessagesRead,
    } = this.props
    /** Map all active messengers into Messenger Windows contained by a single flex div */
    return (
      <div style={styles.messengersContainer}>
        {activeMessengers.map((messengerObj) => {
          const messengerDevice = {
            id: messengerObj.id,
            alias: messengerObj.alias,
            driver: messengerObj.driver,
          }
          return (
            <MessengerWindow
              key={`messenger-${messengerObj.id}`}
              wMatrix={wMatrix}
              messages={messengerObj.messages}
              device={messengerDevice}
              loading={messengerObj.loading}
              // messengerData={messengerObj}
              closeMessenger={closeMessenger}
              sendMessage={sendMessage}
              markMessagesRead={markMessagesRead}
            />
          )
        })}
      </div>
    )
  }
}
