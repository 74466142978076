import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Alert, Button, Checkbox, DatePicker, Form, Input, InputNumber, Radio, Select,
} from 'antd'

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    // height: '100%',
    // width: '100%',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  alert: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: '0 0 10px 0',
  },
  h2: {
    margin: 0,
  },
  p: {
    margin: '0',
  },
  formLabel: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '35%',
    minWidth: '100px',
    margin: '0 15px 0 0',
  },
  formItem: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: 0,
  },
  recurringItems: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '0 0 0 5px',
  },
}

class ServiceForm extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    nav: PropTypes.string.isRequired,
    selectedDevices: PropTypes.array.isRequired,
    form: PropTypes.object.isRequired,
    types: PropTypes.array.isRequired,
    valueTypes: PropTypes.array.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleShowDashboard: PropTypes.func,
    handleCreate: PropTypes.func,
    handleEdit: PropTypes.func,
    existingTicket: PropTypes.object,
    getMeasurement: PropTypes.func.isRequired,
  }

  static defaultProps = {
    existingTicket: null,
    handleCreate: null,
    handleEdit: null,
    handleShowDashboard: null,
  }

  state = {
    alertState: {
      display: 'none',
      message: '',
      type: 'success',
    },
    radioValue: 'date',
  }

  componentDidMount() {
    const { existingTicket } = this.props
    const t = existingTicket
    let radioType = 'date'
    if (t && t.due && t.due.typeId) {
      if (t.due.typeId === 1) radioType = 'mileage'
      if (t.due.typeId === 6) radioType = 'engineHours'
    }
    this.setState({ radioValue: radioType })
  }

  componentDidUpdate(prevProps) {
    const { existingTicket, form } = this.props
    const t = existingTicket
    let radioType = 'date'
    const pT = JSON.stringify(prevProps.existingTicket)
    const eT = JSON.stringify(t)

    if (pT !== eT) {
      if (t && t.due && t.due.typeId) {
        if (t.due.typeId === 1) radioType = 'mileage'
        if (t.due.typeId === 6) radioType = 'engineHours'
      }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ radioValue: radioType })
      form.resetFields()
    }
  }

  disablePastDates = (dateValue) => {
    const today = moment()
    if (!dateValue) {
      return false
    }
    return dateValue.valueOf() <= today.valueOf()
  }

  disableFutureDates = (dateValue) => {
    const today = moment()
    if (!dateValue) {
      return false
    }
    return (dateValue.valueOf() > today.valueOf() || today.diff(dateValue, 'days') > 365)
  }

  /**
   * Resets alertState
   */
  handleAlertClose = () => {
    this.setState({
      alertState: {
        display: 'none',
        message: '',
        type: 'success',
      },
    })
  }

  handleCreateClick = async () => {
    const {
      form, selectedDevices, handleCreate, handleShowDashboard,
    } = this.props
    if (selectedDevices.length < 1) {
      this.setState({
        alertState: {
          display: 'block',
          message: 'Please select at least one device',
          type: 'error',
        },
      })
      return
    }
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          const promises = selectedDevices.map(async (d) => {
            const saveDevice = await handleCreate(values, d)
            return saveDevice
          })
          const res = await Promise.all(promises)
          let alert = {
            display: 'block',
            message: 'Success',
            type: 'success',
          }
          for (let i = 0; i < res.length; i += 1) {
            const { code, description } = res[i].data.createServiceTicket
            if (code === 4000) {
              alert = {
                message: description,
                type: 'error',
              }
            }
          }
          this.setState({ alertState: alert })
          if (alert.type !== 'error') {
            setTimeout(() => {
              handleShowDashboard()
            }, 2200)
          }
        } catch (e) {
          const error = {
            display: 'block',
            message: 'Something went wrong',
            type: 'error',
          }
          this.setState({ alertState: error })
        }
      }
    })
  }

  handleEditClick = async () => {
    const { form, existingTicket, handleEdit } = this.props
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          const res = await handleEdit(values, existingTicket)
          const { description, code } = res.data.updateServiceTicket
          const alert = {
            display: 'block',
            message: description,
            type: code === 4000 ? 'error' : 'success',
          }
          this.setState({ alertState: alert })
          setTimeout(() => {
            this.handleAlertClose()
          }, 2200)
        } catch (e) {
          const error = {
            display: 'block',
            message: 'Something went wrong',
            type: 'error',
          }
          this.setState({ alertState: error })
        }
      }
    })
  }

  renderTypeOptions = () => {
    const { types, wMatrix } = this.props
    let sortedTypes = JSON.parse(JSON.stringify(types))
    sortedTypes = sortedTypes.sort((a, b) => {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })
    return sortedTypes.map(t => (
      <Select.Option key={t.id} value={t.id}>
        {wMatrix(t.name)}
      </Select.Option>
    ))
  }

  renderDueTypeInputs = (form) => {
    const { wMatrix, existingTicket, getMeasurement } = this.props
    const { radioValue } = this.state

    const mileageLabel = getMeasurement() === 'k' ? 'kilometers' : 'miles'
    const t = existingTicket
    if (radioValue === 'mileage') {
      return (
        <div style={styles.formItem}>
          <Form.Item>
            {form.getFieldDecorator('dueMiles', {
              initialValue: t && t.due && t.due.value ? t.due.value : 0,
            })(
              <InputNumber min={0} />,
            )}
          </Form.Item>
          <Form.Item style={{ display: 'none' }}>
            {form.getFieldDecorator('dueTypeId', { initialValue: 1 })(
              <InputNumber min={0} />,
            )}
          </Form.Item>
          <div style={{ height: '40px', margin: '0 0 0 10px' }}>{wMatrix(mileageLabel)}</div>
        </div>
      )
    }
    if (radioValue === 'engineHours') {
      return (
        <div style={styles.formItem}>
          <Form.Item>
            {form.getFieldDecorator('dueEngineHours', {
              initialValue: t && t.due && t.due.value ? t.due.value : 0,
            })(
              <InputNumber min={0} />,
            )}
          </Form.Item>
          <Form.Item style={{ display: 'none' }}>
            {form.getFieldDecorator('dueTypeId', { initialValue: 6 })(
              <InputNumber min={0} />,
            )}
          </Form.Item>
          <div style={{ height: '40px', margin: '0 0 0 10px' }}>{wMatrix('engineHours')}</div>
        </div>
      )
    }
    return (
      <div style={styles.formItem}>
        <Form.Item>
          {form.getFieldDecorator('dueDate', {
            initialValue: t && t.due && t.due.value
              ? moment().add(t.due.value - t.details.currentValue, t.due.type.toLowerCase())
              : moment().add(1, 'day'),
          })(
            <DatePicker
              disabledDate={this.disablePastDates}
              showToday={false}
              allowClear={false}
              style={{ width: '100%' }}
              size="small"
            />,
          )}
        </Form.Item>
        <Form.Item style={{ display: 'none' }}>
          {form.getFieldDecorator('dueTypeId', { initialValue: 3 })(
            <InputNumber min={0} />,
          )}
        </Form.Item>
      </div>
    )
  }

  /**
   * Filters the recurring option types based on the selected due type
   * ex. If due type is "date", then the recurring options will be time units
   */
  getRecurringOptionsFromDueType = () => {
    const { valueTypes } = this.props
    const { radioValue } = this.state
    let recurringOptions = []
    switch (radioValue) {
      case 'mileage':
        recurringOptions = valueTypes.filter(v => (
          v.name === 'Miles' || v.name === 'Kilometers'
        ))
        break
      case 'engineHours':
        recurringOptions = valueTypes.filter(v => (
          v.name === 'Engine Hours'
        ))
        break
      case 'date':
        recurringOptions = valueTypes.filter(v => (
          v.name === 'Hours'
          || v.name === 'Days'
          || v.name === 'Months'
          || v.name === 'Years'
        ))
        break
      default:
    }
    return recurringOptions
  }

  /**
   * Return select options based on due type
   */
  renderRecurringOptions = () => {
    const recurringOptions = this.getRecurringOptionsFromDueType()
    return recurringOptions.map(t => (
      <Select.Option key={t.id} value={t.id}>
        {t.name}
      </Select.Option>
    ))
  }

  /**
   * Handles default values on Recurring checkbox click
   * @param {Event} e Event passed by Ant Designs check box
   */
  onRecurringToggle = (e) => {
    const {
      form,
    } = this.props
    // boolean
    const toggleValue = e.target.checked
    // if recurring === false, reset fields to null
    if (!toggleValue) {
      form.resetFields(['recurringType', 'recurringValue'])
    } else {
      // else, default to value of 1 and default type to 1st option
      const recurringTypeOptions = this.getRecurringOptionsFromDueType()
      const recurringTypeValue = recurringTypeOptions.length > 0 ? recurringTypeOptions[0].id : null

      form.setFieldsValue({
        recurringValue: 1,
        recurringType: recurringTypeValue,
      })
    }
  }

  /**
   * Renders both the Due form items as well as Recurring form items
   * @param {Object} form Ant Design form object (which wraps this component)
   */
  renderDueInputs = (form) => {
    const { wMatrix, existingTicket } = this.props
    const { radioValue } = this.state

    const initialValues = {
      recurring: !!(existingTicket && existingTicket.interval && existingTicket.interval.type
        && existingTicket.interval.type !== null),
      recurringValue: existingTicket && existingTicket.interval
        && existingTicket.interval.value ? existingTicket.interval.value : null,
      recurringType: existingTicket && existingTicket.interval
        && existingTicket.interval.typeId ? existingTicket.interval.typeId : null,
    }

    return (
      <>
        <div style={styles.row}>
          <div style={styles.formLabel}>
            <p style={styles.p}>{`${wMatrix('due')}: `}</p>
          </div>
          <div style={styles.formItem}>
            <Radio.Group
              onChange={(e) => {
                this.setState({ radioValue: e.target.value })
                form.resetFields(['recurringType', 'recurringValue'])
                form.setFieldsValue({ recurring: false })
              }}
              value={radioValue}
            >
              <Radio value="date">{wMatrix('date')}</Radio>
              <Radio value="mileage">{wMatrix('mileage')}</Radio>
              <Radio value="engineHours">{wMatrix('engineHours')}</Radio>
            </Radio.Group>
          </div>
        </div>

        <div style={{ ...styles.row, margin: 0 }}>
          <div style={styles.formLabel} />
          {this.renderDueTypeInputs(form)}
        </div>

        <div style={styles.row}>
          <div style={styles.formLabel}>
            <p style={styles.p}>{`${wMatrix('recurring')}: `}</p>
          </div>
          <div style={styles.formItem}>
            <Form.Item style={{ ...styles.recurringItems }}>
              {form.getFieldDecorator('recurring', {
                initialValue: initialValues.recurring,
                valuePropName: 'checked',
              })(
                <Checkbox onChange={this.onRecurringToggle} />,
              )}
            </Form.Item>
            <Form.Item style={{ ...styles.recurringItems }}>
              {form.getFieldDecorator('recurringValue', {
                initialValue: initialValues.recurringValue,
              })(
                <InputNumber min={0} disabled={!form.getFieldValue('recurring')} />,
              )}
            </Form.Item>
            <Form.Item style={{ ...styles.recurringItems }}>
              {form.getFieldDecorator('recurringType', {
                initialValue: initialValues.recurringType,
              })(
                <Select style={{ width: '100px' }} disabled={!form.getFieldValue('recurring')}>
                  {this.renderRecurringOptions(form)}
                </Select>,
              )}
            </Form.Item>
          </div>
        </div>
      </>
    )
  }

  render() {
    const {
      wMatrix, nav, form, existingTicket, handleCancel,
    } = this.props
    const { alertState } = this.state
    const t = existingTicket
    /**
     * 3 initial dtCompleted cases:
     * - completed with no date (v2 records)
     * - completed today: use todays date
     * - not completed
     */
    let initialDateValue = moment()
    if (t && t.details && t.completion) {
      // ticket is currently set to completed
      if (t.details.status === 'completed' && t.completion.date) {
        initialDateValue = moment(t.completion.date)
      }
    }

    return (
      <div style={styles.wrapper}>
        <Form
          name="serviceForm"
          style={{ width: '100%' }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

            <div style={styles.row}>
              <div style={styles.formLabel}>
                <h2 style={styles.h2}>{`${wMatrix('service')}: `}</h2>
              </div>
              <Form.Item style={styles.formItem}>
                {form.getFieldDecorator('serviceType', {
                  initialValue: t && t.type && t.type.id
                    ? t.type.id
                    : 1,
                })(
                  <Select style={{ width: '250px' }}>
                    {this.renderTypeOptions()}
                  </Select>,
                )}
              </Form.Item>
            </div>

            <div style={styles.row}>
              <div style={styles.formLabel}>
                <p style={styles.p}>{`${wMatrix('created')}: `}</p>
              </div>
              <div style={styles.formItem}>
                {t && t.creation && t.creation.date
                  ? moment(t.creation.date).format('YYYY-MM-DD')
                  : moment().format('YYYY-MM-DD')
                }
              </div>
            </div>

            {this.renderDueInputs(form)}

            <div style={styles.row}>
              <div style={styles.formLabel}>
                <p style={styles.p}>{`${wMatrix('estimatedCost')}: `}</p>
              </div>
              <Form.Item style={styles.formItem}>
                {form.getFieldDecorator('estimatedCost', {
                  initialValue: t && t.cost && t.cost.estimated ? t.cost.estimated : 0,
                })(
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                  />,
                )}
              </Form.Item>
            </div>

            <div style={styles.row}>
              <div style={styles.formLabel}>
                <p style={styles.p}>{`${wMatrix('cost')}: `}</p>
              </div>
              <Form.Item style={styles.formItem}>
                {form.getFieldDecorator('cost', {
                  initialValue: t && t.cost && t.cost.actual ? t.cost.actual : 0,
                })(
                  <InputNumber
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                  />,
                )}
              </Form.Item>
            </div>

            <div style={styles.row}>
              <div style={styles.formLabel}>
                <p style={styles.p}>{`${wMatrix('Completed')}: `}</p>
              </div>
              <Form.Item style={styles.recurringItems}>
                {form.getFieldDecorator('completed', {
                  initialValue: !!(t && t.details && t.details.status && t.details.status === 'completed'),
                  valuePropName: 'checked',
                })(
                  <Checkbox />,
                )}
              </Form.Item>
              {form.getFieldValue('completed') ? (
                <Form.Item style={styles.recurringItems}>
                  {form.getFieldDecorator('dtCompleted', {
                    initialValue: initialDateValue,
                    rules: [{ required: !!form.getFieldValue('completed'), message: wMatrix('Completion requires a completion date') }],
                  })(
                    <DatePicker
                      disabledDate={this.disableFutureDates}
                      showToday={false}
                      allowClear={false}
                      style={{ width: '100%' }}
                      size="small"
                    />,
                  )}
                </Form.Item>
              ) : null}
            </div>

            <div style={styles.row}>
              <div style={{ ...styles.formLabel, alignItems: 'flex-start' }}>
                <p style={styles.p}>{`${wMatrix('notes')}: `}</p>
              </div>
              <Form.Item style={styles.formItem}>
                {form.getFieldDecorator('notes', {
                  initialValue: t && t.details && t.details.notes ? t.details.notes : '',
                })(
                  <Input.TextArea rows={5} style={{ maxWidth: '250px' }} />,
                )}
              </Form.Item>
            </div>

          </div>
        </Form>
        { alertState.display !== 'none' ? (
          <div style={styles.alert}>
            <Alert
              style={{
                display: alertState.display,
                width: '275px',
                margin: '0px 10px',
              }}
              closable
              showIcon
              message={alertState.message}
              type={alertState.type}
              onClose={this.handleAlertClose}
            />
          </div>
        ) : null}
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <Button onClick={handleCancel} type="info" style={{ margin: '0 10px 0 0' }}>
            {wMatrix('Cancel')}
          </Button>
          {t === null || nav === 'create' ? (
            <Button onClick={this.handleCreateClick} type="primary">
              {wMatrix('create')}
            </Button>
          ) : (
            <Button onClick={this.handleEditClick} type="primary">
              {wMatrix('update')}
            </Button>
          )}
        </div>
      </div>
    )
  }
}

export default Form.create({ name: 'serviceForm' })(ServiceForm)
