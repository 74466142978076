import React, { Component } from 'react';
import { Mutation } from "react-apollo";
const mutationConnector = (mutation, name) => WrappedComponent => {
  class MutationConnector extends Component {

    render = () => {
      return (
        <Mutation mutation={mutation}>
          {(mutation, { loading, error, data }) => {
            const dynamicProps ={
              [name]: mutation
            }
            return (
              <WrappedComponent
                loading={loading} error={error} data={data}
                {...this.props} {...dynamicProps}
              />
             )
          }}
        </Mutation>
      )
    }
  }
  return MutationConnector
}
export default mutationConnector
