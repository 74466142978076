import React from 'react'
import PropTypes from 'prop-types'
import DriverScorecardHOC from '@hoc/driverScorecard.hoc'
import DriverPerformance from '@mol/driver-scorecard/driverPerformance'
import DriverException from '@mol/driver-scorecard/driverExceptions'
import DriverExceptionsDetails from '@mol/driver-scorecard/driverExceptionsDetails'
import DriverStandings from '@mol/driver-scorecard/driverStandings'
import DriverStandingsDetails from '@mol/driver-scorecard/driverStandingsDetails'

const styles = {
  outerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: '100%',
    width: '100%',
  },
  dashWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    flexShrink: 0,
  },
  dashCol: {
    flex: '1',
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
  },
  dashSecCol: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    marginLeft: -5,
  },
}

class DriverScorecardView extends React.Component {
  static propTypes = {
    appHeight: PropTypes.number.isRequired,
    dscNav: PropTypes.string.isRequired,
    multipleWeeks: PropTypes.bool.isRequired,
    DSCDateRangeOptions: PropTypes.array.isRequired,
    setDSCNav: PropTypes.func.isRequired,
    getDSCData: PropTypes.func.isRequired,
    returnColor: PropTypes.func.isRequired,
    dscData: PropTypes.object,
    loading: PropTypes.bool,
    performanceData: PropTypes.array,
    exceptionData: PropTypes.object,
  }

  static defaultProps = {
    dscData: null,
    loading: false,
    performanceData: [
      { name: 'good', value: 50 },
      { name: 'fair', value: 50 },
      { name: 'poor', value: 50 },
    ],
    exceptionData: null,
  }

  /**
   * Renders Dashboard
   */
  renderDashboard = () => {
    const {
      performanceData, exceptionData, setDSCNav, returnColor, dscData,
      loading, appHeight, multipleWeeks,
    } = this.props
    return (
      <div style={styles.outerWrapper}>
        <div style={styles.dashWrapper}>
          <div style={styles.dashCol}>
            <DriverStandings
              drivers={dscData ? dscData.drivers : null}
              setDSCNav={setDSCNav}
              multipleWeeks={multipleWeeks}
              loading={loading}
            />
          </div>
          <div style={styles.dashSecCol}>
            <div style={{ flex: 1 }}>
              <DriverPerformance data={performanceData} loading={loading} appHeight={appHeight} />
            </div>
            <div style={{ flex: 1, marginTop: -5 }}>
              <DriverException
                data={exceptionData}
                setDSCNav={setDSCNav}
                returnColor={returnColor}
                dscData={dscData}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  /**
   * Renders the correct contents based on dscNav
   */
  renderContainer = () => {
    const {
      dscNav, setDSCNav, returnColor, exceptionData, dscData, getDSCData,
      loading, appHeight, DSCDateRangeOptions, multipleWeeks,
    } = this.props
    switch (dscNav) {
      case 'standings':
        return (
          <DriverStandingsDetails
            DSCDateRangeOptions={DSCDateRangeOptions}
            setDSCNav={setDSCNav}
            drivers={dscData ? dscData.drivers : null}
            getDSCData={getDSCData}
            multipleWeeks={multipleWeeks}
            loading={loading}
          />
        )
      case 'exceptions':
        return (
          <DriverExceptionsDetails
            DSCDateRangeOptions={DSCDateRangeOptions}
            exceptionData={exceptionData}
            setDSCNav={setDSCNav}
            returnColor={returnColor}
            dscData={dscData}
            getDSCData={getDSCData}
            multipleWeeks={multipleWeeks}
            loading={loading}
            appHeight={appHeight}
          />
        )
      default:
        return (
          this.renderDashboard()
        )
    }
  }

  render() {
    return this.renderContainer()
  }
}

export default DriverScorecardHOC()(DriverScorecardView)
