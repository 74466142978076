import PropTypes from 'prop-types'
/**
 * This file is used for resuable custom prop types and jsdoc defintions
 */

/**
 * Tag - either a group or label
 * @typedef {Object} Tag
 * @property {number} id
 * @property {string} name
 * @property {string} color hex string
 */


export default {
  /**
   * @type {Tag} Tag
   */
  tags: PropTypes.shape({
    /** id of tag */
    id: PropTypes.number,
    /** name */
    name: PropTypes.string,
    /** color in hex string */
    color: PropTypes.string,
  }),
}
