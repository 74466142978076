import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'react-apollo'
import moment from 'moment'
import helper from '@helper'
import equal from 'deep-equal'
import queryConnector from '@graphql/queryConnector'
import mutationConnector from '@graphql/mutationConnector'
import { serviceTickets, serviceTypes, serviceValueTypes } from '@graphql/query'
import { createServiceTicket, updateServiceTicket, deleteServiceTicket } from '@graphql/mutation'

const groupByEnum = {
  STATUS: 'status',
  VEHICLE: 'vehicle',
  TYPE: 'type',
  DEVICE: 'device',
}

/**
 * #52A5D9
 * #0A5959
 * #F2CD5C
 * #F28D52
 * #D9564A
 */
const COLORSCHEME = [
  [82, 165, 217],
  [10, 89, 89],
  [242, 205, 92],
  [242, 141, 82],
  [217, 86, 74],
]

const serviceHOC = () => (WrappedComponent) => {
  class ServiceHOC extends React.Component {
    static propTypes = {
      /** @App */
      appHeight: PropTypes.number.isRequired,
      /** @helper */
      wMatrix: PropTypes.func.isRequired,
      getPermission: PropTypes.func.isRequired,
      createColorsArray: PropTypes.func.isRequired,
      /** @queryData */
      devices: PropTypes.array.isRequired,
      tickets: PropTypes.object.isRequired,
      types: PropTypes.object.isRequired,
      valueTypes: PropTypes.object.isRequired,
      createTicket: PropTypes.func.isRequired,
      updateTicket: PropTypes.func.isRequired,
      deleteTicket: PropTypes.func.isRequired,
      getMeasurement: PropTypes.func.isRequired,
    }

    state = {
      selectedTicket: null,
      groupBy: groupByEnum.STATUS,
      filterString: '',
      pieTimeOption: 0,
      pieTopOption: 3,
      barTimeOption: 0,
      barTopOption: 3,
    }

    componentDidUpdate = (prevProps) => {
      const { tickets } = this.props
      const { selectedTicket } = this.state
      if (!equal(prevProps.tickets, tickets) && selectedTicket) {
        this.handleSelectTicket(selectedTicket.id)
      }
    }

    refetchTickets = async () => {
      const { tickets } = this.props
      if (tickets && tickets.data) {
        await tickets.refetch()
      }
    }

    /**
     * @description - update filterString state
     */
    filterMenu = (input) => {
      this.setState({ filterString: input })
    }

    /**
     * @description - returns array of option data for time frames
     * @todo maybe move this to dashboard file?...
     */
    returnTimeOptions = () => {
      const { wMatrix } = this.props
      return [
        {
          key: 0,
          value: 1,
          valueType: 'weeks',
          displayString: `1 ${wMatrix('week')}`,
        },
        {
          key: 1,
          value: 2,
          valueType: 'weeks',
          displayString: `2 ${wMatrix('weeks')}`,
        },
        {
          key: 2,
          value: 1,
          valueType: 'months',
          displayString: `1 ${wMatrix('month')}`,
        },
        {
          key: 3,
          value: 3,
          valueType: 'months',
          displayString: `3 ${wMatrix('months')}`,
        },
        {
          key: 4,
          value: 6,
          valueType: 'months',
          displayString: `6 ${wMatrix('months')}`,
        },
        {
          key: 5,
          value: 1,
          valueType: 'years',
          displayString: `1 ${wMatrix('year')}`,
        },
      ]
    }

    /**
     * @description - handles onChange for pie time options
     */
    onPieTimeChange = e => this.setState({ pieTimeOption: e })

    /**
     * @description - handle pie top select change
     */
    onPieTopChange = e => this.setState({ pieTopOption: e })

    /**
     * @description - handles onChange for bar chart time options
     */
    onBarTimeChange = e => this.setState({ barTimeOption: e })

    /**
     * @description - handle bar chart top select change
     */
    onBarTopChange = e => this.setState({ barTopOption: e })

    /**
     * @public
     * @description sets state of selected ticket by iterating tickets and selecting based on id
     */
    handleSelectTicket = (ticketId) => {
      const { tickets } = this.props
      if (tickets && tickets.data && tickets.data.serviceTickets) {
        const serviceTicketsClone = JSON.parse(JSON.stringify(tickets.data.serviceTickets))
        for (let i = 0; i < serviceTicketsClone.length; i += 1) {
          // NOTE: ticketId comes in as a string from menu
          if (typeof ticketId === 'string') {
            if (serviceTicketsClone[i].id.toString() === ticketId) {
              this.setState({ selectedTicket: serviceTicketsClone[i] })
            }
          } else if (serviceTicketsClone[i].id === ticketId) {
            this.setState({ selectedTicket: serviceTicketsClone[i] })
          }
        }
      }
    }

    handleClearSelectedTicket = () => {
      this.setState({ selectedTicket: null })
    }

    /**
     * @public
     * @description Takes form values and existingTicket values, executes the update, then
     * returns the response.
     * @param {Object} values
     * @param {Object} et
     * @returns {Object}
     */
    handleEdit = async (values, et) => {
      const { updateTicket } = this.props
      let dueValue = null

      if (values.dueDate) {
        dueValue = values.dueDate.diff(moment(), 'days')
      } else if (values.dueMiles) {
        dueValue = values.dueMiles
      } else if (values.dueEngineHours) {
        dueValue = values.dueEngineHours
      }

      const variables = {
        id: et.id,
        deviceId: et.deviceId,
        typeId: values.serviceType || et.type.id,
        dueTypeId: values.dueTypeId || et.due.typeId,
        intervalTypeId: values.recurringType || et.interval.typeId,
        currentValue: et.details.currentValue,
        dueValue: dueValue || et.due.value,
        intervalValue: values.recurringValue || et.interval.value,
        estimatedCost: values.estimatedCost,
        actualCost: values.cost,
        completed: !!values.completed,
        dtCompleted: values.completed ? moment(values.dtCompleted).format('YYYY-MM-DD') : null,
        notes: values.notes,
      }
      // created to compare changes with variables above
      const etVariables = {
        id: et.id,
        deviceId: et.deviceId,
        typeId: et.type.id,
        dueTypeId: et.due.typeId,
        intervalTypeId: et.interval.typeId,
        currentValue: et.details.currentValue,
        dueValue: et.due.value,
        intervalValue: et.interval.value,
        estimatedCost: et.cost.estimated,
        actualCost: et.cost.actual,
        completed: !!et.completion.date,
        dtCompleted: et.completion.date ? moment(et.completion.date).format('YYYY-MM-DD') : null,
        notes: et.details.notes,
      }
      // If no changes, return null and do not refetch
      if (equal(variables, etVariables)) {
        return null
      }

      try {
        const res = await updateTicket({ variables })
        await this.refetchTickets()

        return res
      } catch (e) {
        throw e
      }
    }

    /**
     * @public
     * @description Takes form values and device values, executes the create, then
     * returns the response.
     * @param {Object} values
     * @param {Object} et
     * @returns {Object}
     */
    handleCreate = async (values, device) => {
      const { createTicket } = this.props
      let dueValue = 0
      if (values.dueDate) {
        dueValue = values.dueDate.diff(moment(), 'days')
      } else if (values.dueMiles) {
        dueValue = values.dueMiles
      } else if (values.dueEngineHours) {
        dueValue = values.dueEngineHours
      }

      const variables = {
        deviceId: device.id,
        typeId: values.serviceType,
        dueTypeId: values.dueTypeId,
        intervalTypeId: values.recurringType,
        startingValue: 0,
        dueValue,
        intervalValue: values.recurringValue,
        estimatedCost: values.estimatedCost,
        actualCost: values.cost,
        completed: !!values.completed,
        dtCompleted: values.completed && values.dtCompleted ? moment(values.dtCompleted).format('YYYY-MM-DD') : null,
        notes: values.notes,
      }

      try {
        const res = await createTicket({ variables })
        await this.refetchTickets()
        return res
      } catch (e) {
        throw e
      }
    }

    /**
     * @public
     * @description Takes a service ticket id then deletes the ticket.
     * @param {Int} id
     */
    handleDelete = async (id) => {
      const { deleteTicket } = this.props
      try {
        await deleteTicket({ variables: { id } })
        this.refetchTickets()
      } catch (e) {
        throw e
      }
    }

    /**
     * @description - filter for ticket menu. Can filter by alias, vin, and service type
     */
    filterTickets = () => {
      const { tickets, wMatrix } = this.props
      const { filterString } = this.state
      let records = []
      if (tickets && tickets.data && tickets.data.serviceTickets) {
        records = JSON.parse(JSON.stringify(tickets.data.serviceTickets))
        if (filterString !== '') {
          records = records.filter(r => r.alias.toLowerCase().includes(filterString.toLowerCase())
              || (r.vehicle.vin && r.vehicle.vin.toLowerCase().includes(filterString.toLowerCase()))
              || r.type.name.toLowerCase().includes(filterString.toLowerCase()))
        }
      }
      for (const i in records) {
        if (Object.prototype.hasOwnProperty.call(records, i)) {
          records[i].type.name = wMatrix(records[i].type.name)
        }
      }
      return records
    }

    /**
     * @public
     * @description - handles groupBy select change
     */
    onGroupChange = (value) => {
      if (Object.values(groupByEnum).includes(value)) this.setState({ groupBy: value })
    }

    /**
     * @description - groups submenu items given a grouBy value
     */
    groupItems = (records) => {
      const { groupBy } = this.state
      if (groupBy === groupByEnum.STATUS) {
        const overdue = []
        const upcoming = []
        const active = []
        const completed = []
        for (let i = 0; i < records.length; i += 1) {
          switch (records[i].details.status) {
            case 'overdue':
              overdue.push({
                id: records[i].id,
                data: records[i],
              })
              break
            case 'upcoming':
              upcoming.push({
                id: records[i].id,
                data: records[i],
              })
              break
            case 'active':
              active.push({
                id: records[i].id,
                data: records[i],
              })
              break
            // default = completed tickets
            default:
              completed.push({
                id: records[i].id,
                data: records[i],
              })
          }
        }
        return {
          overdue,
          upcoming,
          active,
          completed,
        }
      }
      return records
    }

    /**
     * @description - renders top level menu options given sub menu items
     */
    renderMenu = (items) => {
      const { groupBy } = this.state
      const { wMatrix } = this.props
      const { STATUS, TYPE, DEVICE } = groupByEnum
      if (groupBy === STATUS) {
        return [
          {
            id: 'g0',
            title: wMatrix('Overdue'),
            items: items.overdue,
          },
          {
            id: 'g1',
            title: wMatrix('Upcoming'),
            items: items.upcoming,
          },
          {
            id: 'g2',
            title: wMatrix('activeTickets'),
            items: items.active,
          },
          {
            id: 'g3',
            title: wMatrix('Completed'),
            items: items.completed,
          },
        ]
      }

      let menu = []
      const menuGroups = {}

      const ordering = {
        overdue: 0,
        upcoming: 1,
        active: 2,
        completed: 3,
      }

      if (groupBy === TYPE) {
        for (let i = 0; i < items.length; i += 1) {
          const key = items[i].type.name
          if (!menuGroups[key]) {
            menuGroups[key] = {
              id: `g${items[i].type.id}`,
              title: items[i].type.name,
              items: [],
            }
          }
          menuGroups[key].items.push({
            id: items[i].id,
            data: items[i],
          })
        }

        menu = Object.values(menuGroups)

        // Sort Menu Groups by Service type
        menu.sort((x, y) => {
          if (x.title < y.title) { return -1 }
          if (x.title > y.title) { return 1 }
          return 0
        })

        // Sort Menu Items by Status, Alias
        for (const menuKey in menuGroups) {
          if (menuGroups[menuKey]) {
            menuGroups[menuKey].items.sort((x, y) => {
              const xStatus = x.data.details.status
              const yStatus = y.data.details.status
              const orderSortResult = (ordering[xStatus] - ordering[yStatus])

              if (orderSortResult === 0) {
                if (x.data.alias < y.data.alias) { return -1 }
                if (x.data.alias > y.data.alias) { return 1 }
                return 0
              }

              return orderSortResult
            })
          }
        }
      } else if (groupBy === DEVICE) {
        for (let i = 0; i < items.length; i += 1) {
          const key = items[i].alias
          if (!menuGroups[key]) {
            menuGroups[key] = {
              id: `g${items[i].deviceId}`,
              title: items[i].alias,
              items: [],
            }
          }
          menuGroups[key].items.push({
            id: items[i].id,
            data: items[i],
          })
        }
        menu = Object.values(menuGroups)

        // Sort Menu Items by Status, ServiceItem
        for (const menuKey in menuGroups) {
          if (menuGroups[menuKey]) {
            menuGroups[menuKey].items.sort((x, y) => {
              const xStatus = x.data.details.status
              const yStatus = y.data.details.status
              const orderSortResult = (ordering[xStatus] - ordering[yStatus])

              if (orderSortResult === 0) {
                if (x.data.type.name < y.data.type.name) { return -1 }
                if (x.data.type.name > y.data.type.name) { return 1 }
                return 0
              }

              return orderSortResult
            })
          }
        }
      }

      return menu
    }

    /**
     * @private
     * @description - returns history of ticket (same service type, device, and completed)
     * @returns {Array} - array of ticket objects
     */
    returnHistoryTickets = (serviceTicket) => {
      const { tickets } = this.props
      if (serviceTicket && tickets && tickets.data && tickets.data.serviceTickets) {
        // clone to not overwrite or manipulate original
        const allTickets = JSON.parse(JSON.stringify(tickets.data.serviceTickets))
        const historyTickets = allTickets.filter((ticket) => {
          if (ticket.type.id === serviceTicket.type.id
            && ticket.deviceId === serviceTicket.deviceId
            && ticket.id !== serviceTicket.id
            && ticket.details.status === 'completed') {
            return true
          } return false
        })
        return historyTickets
      }
      return []
    }

    /**
     * @private
     * @description - returns related tickets (same device different type. Same type is in
     * returnHistory)
     * @returns {Array} - array of ticket objects
     */
    returnRelatedTickets = (serviceTicket) => {
      const { tickets } = this.props
      if (serviceTicket && tickets && tickets.data && tickets.data.serviceTickets) {
        // clone to not overwrite or manipulate original
        const allTickets = JSON.parse(JSON.stringify(tickets.data.serviceTickets))
        const relatedTickets = allTickets.filter((ticket) => {
          if (ticket.deviceId === serviceTicket.deviceId
            && ticket.type.id !== serviceTicket.type.id
            && ticket.id !== serviceTicket.id) {
            return true
          } return false
        })
        return relatedTickets
      }
      return []
    }

    /**
     * compares two tickets and returns the one that is due sooner
     */
    compareNextDue = (nextTicket, ticket) => {
      if (nextTicket && ticket) {
        const { details, due } = ticket
        const nextDetails = nextTicket.details
        const nextDue = nextTicket.due
        // check if this ticket is due sooner than the nextTicket (by percentage of current/due)
        const ticketWeightedDue = (details.currentValue / due.value)
        const nextTicketWeightedDue = (nextDetails.currentValue / nextDue.value)
        if (ticketWeightedDue < nextTicketWeightedDue) return nextTicket
      }
      return ticket
    }

    /**
     * @description - formats due value to string (date, miles, etc)
     * @returns {string}
     */
    formatNextDue = (ticket) => {
      const { wMatrix, getMeasurement } = this.props
      if (ticket) {
        let dueLabel = ticket.due.type
        if (ticket.due.type === 'Miles' && getMeasurement() === 'k') {
          dueLabel = 'Kilometers'
        }
        let delta = ticket.due.value - ticket.details.currentValue
        // time is special case format
        if (['Hours', 'Days', 'Months', 'Years'].includes(ticket.due.type)) {
          const today = moment()
          const dueDate = today.add(delta, ticket.due.type.toLowerCase())
          return `${dueDate.format('YYYY-MM-DD')} (${wMatrix('in')} ${delta} ${wMatrix(dueLabel)})`
        }
        delta = delta.toFixed(0)
        return `${wMatrix('in')} ${delta} ${wMatrix(dueLabel)}`
      }
      return null
    }

    statusData = () => {
      const { tickets } = this.props
      let active = 0
      let overdue = 0
      let upcoming = 0
      let completed = 0
      let nextDue = null

      if (tickets && tickets.data && tickets.data.serviceTickets) {
        const allTickets = JSON.parse(JSON.stringify(tickets.data.serviceTickets))
        for (let i = 0; i < allTickets.length; i += 1) {
          const { status } = allTickets[i].details
          switch (status) {
            case 'completed':
              completed += 1
              break
            case 'overdue':
              overdue += 1
              break
            case 'active':
              active += 1
              nextDue = this.compareNextDue(nextDue, allTickets[i])
              break
            case 'upcoming':
              upcoming += 1
              nextDue = this.compareNextDue(nextDue, allTickets[i])
              break
            default:
              break
          }
        }
      }
      const nextDueString = nextDue ? this.formatNextDue(nextDue) : null
      return {
        active,
        overdue,
        upcoming,
        completed,
        nextDue: nextDueString,
      }
    }

    /**
     * @description filters out tickets previous to a timevalue and timeType (ie. 1 week)
     */
    filterTicketsByCompletionTime = (timeValue, timeType) => {
      const { tickets } = this.props
      const filteredTickets = []
      const timeFrameStart = moment().subtract(timeValue, timeType)
      if (tickets && tickets.data && tickets.data.serviceTickets) {
        const allTickets = JSON.parse(JSON.stringify(tickets.data.serviceTickets))
        for (let i = 0; i < allTickets.length; i += 1) {
          if (allTickets[i].details.status === 'completed'
            && moment(allTickets[i].completion.date, 'YYYY-MM-DD hh:mm').isAfter(timeFrameStart)
          ) {
            filteredTickets.push(allTickets[i])
          }
        }
      }
      return filteredTickets
    }

    /**
     * @description - filters array of tickets and returns top costing vehicles
     */
    filterTopCost = (tickets, topNumber) => {
      const vehicleCosts = {}
      const allTickets = JSON.parse(JSON.stringify(tickets))
      const costingTickets = allTickets.filter((ticket) => {
        if (ticket.cost.actual !== null) return true
        return false
      })
      // 0 means return all with a cost (actual)
      if (!topNumber) {
        return costingTickets
      }
      if (costingTickets.length > 0) {
        // groups by device id and totals costs
        for (let i = 0; i < costingTickets.length; i += 1) {
          if (!vehicleCosts[costingTickets[i].deviceId]) {
            vehicleCosts[costingTickets[i].deviceId] = {
              cost: 0,
              id: costingTickets[i].deviceId,
              alias: costingTickets[i].alias,
            }
          }
          vehicleCosts[costingTickets[i].deviceId].cost += costingTickets[i].cost.actual
        }
        let costArray = Object.values(vehicleCosts)
        // sort by highest costing first
        costArray.sort((a, b) => b.cost - a.cost)
        // only grab top x values
        costArray = costArray.slice(0, topNumber)
        return costArray
      }
      return []
    }

    /**
     * @private
     * @description function that creates the necessary columns and keys for the
     * service Costs bar graph
     */
    costTimeLoop = (tickets, timeOption, compareFormat, iterateBy) => {
      const columns = []
      const totalCosts = {}
      const timeFrameEnd = moment()
      const timeFrameIterator = moment().subtract(timeOption.value, timeOption.valueType)

      /**
       * Loop takes earliest date (today - <value><unit>) and does not include current <unit>
       * ex. if unit is month, does not include this month, if unit is week,
       * does not include this week, etc
       */
      while (timeFrameEnd > timeFrameIterator
        || timeFrameIterator.format(compareFormat) !== timeFrameEnd.format(compareFormat)
      ) {
        let label = null
        // iterating by week
        if (iterateBy === 'w') {
          label = `${timeFrameIterator.startOf('week').format('M/DD')}-${timeFrameIterator.endOf('week').format('M/DD')}`
        } else if (iterateBy === 'd') {
          label = `${timeFrameIterator.format('dd')} (${timeFrameIterator.format('M/DD')})`
        } else if (iterateBy === 'month') {
          label = `${timeFrameIterator.format('MMM-YY')}`
        }
        const colObj = {
          xLabel: label,
          Total: 0,
        }
        for (let i = 0; i < tickets.length; i += 1) {
          const cd = moment(tickets[i].completion.date, 'YYYY-MM-DD hh:mm').format(compareFormat)
          if (cd === timeFrameIterator.format(compareFormat)) {
            if (!colObj[tickets[i].alias]) {
              colObj[tickets[i].alias] = 0
            }
            colObj[tickets[i].alias] += tickets[i].cost.actual || 0
            colObj.Total += tickets[i].cost.actual || 0
            // running totals for each device
            if (!totalCosts[tickets[i].alias]) {
              totalCosts[tickets[i].alias] = 0
            }
            totalCosts[tickets[i].alias] += tickets[i].cost.actual || 0
          }
        }
        columns.push(colObj)
        timeFrameIterator.add(1, iterateBy)
      }
      const keyCostArray = Object.entries(totalCosts)
      return {
        columns,
        keys: keyCostArray,
      }
    }

    /**
     * @description - filters array of tickets and returns top costing vehicles in
     * format for service costs bar graph
     */
    formatCostOverTime = (topNumber, timeOption) => {
      const { tickets } = this.props
      let data = {
        columns: [],
        keys: [],
      }
      // Use ticket data if we have it, else return empty array
      if (tickets && tickets.data && tickets.data.serviceTickets) {
        const allTickets = JSON.parse(JSON.stringify(tickets.data.serviceTickets))
        // filter array to only contain usable entries
        const costingTickets = allTickets.filter((ticket) => {
          if (ticket.details.status === 'completed' && ticket.completion.date) return true
          return false
        })
        /** If showing weeks, display in days */
        if (timeOption.valueType === 'weeks') {
          data = this.costTimeLoop(costingTickets, timeOption, 'YYYY-MM-DD', 'd')
        }
        /** If one month, show in terms of week */
        if (timeOption.valueType === 'months' && timeOption.value === 1) {
          data = this.costTimeLoop(costingTickets, timeOption, 'YYYY-w', 'w')
        }
        /** If showing a year, show in months */
        if (timeOption.valueType === 'years'
          || (timeOption.valueType === 'months' && timeOption.value !== 1)
        ) {
          data = this.costTimeLoop(costingTickets, timeOption, 'YYYY-MM', 'month')
        }

        /**
         * data.keys is array of object entry arrays: [key,value] where key is alias and value
         * is the total cost
         */
        data.keys.sort((a, b) => b[1] - a[1])
        data.keys = data.keys.slice(0, topNumber)
        data.keys = data.keys.map(entry => entry[0])
      }
      return data
    }

    /**
     * @public
     * @description Returns the formatted data for the top costing vehicles pie chart
     */
    returnPieData = () => {
      const { pieTimeOption, pieTopOption } = this.state
      const timeOption = this.returnTimeOptions()[pieTimeOption]
      let pieArray = this.filterTicketsByCompletionTime(timeOption.value, timeOption.valueType)
      pieArray = this.filterTopCost(pieArray, pieTopOption)
      return pieArray
    }

    /**
     * @public
     * @description Returns the formatted data for the service costs bar graph
     */
    returnBarData = () => {
      const { barTimeOption, barTopOption } = this.state
      const timeOption = this.returnTimeOptions()[barTimeOption]
      const barArray = this.formatCostOverTime(barTopOption, timeOption)
      return barArray
    }

    /**
     * @private
     * @description Takes an array of devices and adapts them to send the the table columns.
     * @param {[Object]} devices
     */
    shapeDevices = (devices) => {
      const shaped = []
      for (let i = 0; i < devices.length; i += 1) {
        shaped.push({
          id: devices[i].id,
          alias: devices[i].alias,
          vin: devices[i].vehicle.vin || '',
          groups: devices[i].groups,
          labels: devices[i].labels,
        })
      }
      return shaped
    }

    render = () => {
      const {
        getPermission, wMatrix, devices, tickets, types, valueTypes, 
        appHeight, createColorsArray, getMeasurement,
      } = this.props
      const { selectedTicket, pieTopOption, barTopOption } = this.state
      const filteredItems = this.filterTickets(tickets)
      const groupedItems = this.groupItems(filteredItems)
      const menuItems = this.renderMenu(groupedItems)
      const devicesShaped = this.shapeDevices(devices)
      const historyTickets = this.returnHistoryTickets(selectedTicket)
      const relatedTickets = this.returnRelatedTickets(selectedTicket)
      const statusData = this.statusData()
      const timeOptions = this.returnTimeOptions()
      const pieData = this.returnPieData()
      const pieColorsArray = createColorsArray(pieTopOption, null, null, COLORSCHEME)
      const barColorsArray = createColorsArray(barTopOption, null, null, COLORSCHEME)
      const barData = this.returnBarData()

      return (
        <WrappedComponent
          wMatrix={wMatrix}
          devices={devicesShaped}
          selectedTicket={selectedTicket}
          menuItems={menuItems}
          filterMenu={this.filterMenu}
          handleSelectTicket={this.handleSelectTicket}
          handleClearSelectedTicket={this.handleClearSelectedTicket}
          historyTickets={historyTickets}
          relatedTickets={relatedTickets}
          types={types && types.data && types.data.serviceTypes
            ? types.data.serviceTypes
            : []
          }
          valueTypes={valueTypes && valueTypes.data && valueTypes.data.serviceValueTypes
            ? valueTypes.data.serviceValueTypes
            : []
          }
          handleCreate={this.handleCreate}
          handleEdit={this.handleEdit}
          handleDelete={this.handleDelete}
          onGroupChange={this.onGroupChange}
          statusData={statusData}
          timeOptions={timeOptions}
          pieData={pieData}
          onPieTimeChange={this.onPieTimeChange}
          onPieTopChange={this.onPieTopChange}
          pieColorsArray={pieColorsArray}
          barData={barData}
          onBarTimeChange={this.onBarTimeChange}
          onBarTopChange={this.onBarTopChange}
          barColorsArray={barColorsArray}
          showAdminEdit={getPermission('Service', 'ynUpdate')}
          showCreateButton={getPermission('Service', 'ynCreate')}
          appHeight={appHeight}
          getMeasurement={getMeasurement}
        />
      )
    }
  }
  return compose(
    helper(),
    mutationConnector(createServiceTicket, 'createTicket'),
    mutationConnector(updateServiceTicket, 'updateTicket'),
    mutationConnector(deleteServiceTicket, 'deleteTicket'),
    queryConnector(serviceTickets, {}, 'tickets'),
    queryConnector(serviceTypes, {}, 'types'),
    queryConnector(serviceValueTypes, {}, 'valueTypes'),
  )(ServiceHOC)
}

export default serviceHOC
