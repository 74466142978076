import React, { Component } from 'react'
import PropTypes from 'prop-types'
import clusterIcon from '@assets/img/icons/clusterIconV2.svg'

// base style : note that diameter is added in returnCluster()
const styles = {
  // main container
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  // icon Image container
  iconContainer: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    // opacity: '0.85',
  },

  // Label (count)
  label: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '150%',
    transform: 'translate(-50%, -50%)',
    objectFit: 'contain',
    color: 'white',
  },
}

export default class ClusterMarker extends Component {
  static propTypes = {
    onClick: PropTypes.func,
  }

  static defaultProps = {
    onClick: null,
  }

  // calculate the diameter and return the marker
  returnCluster = (count) => {
    const { onClick } = this.props
    const markerStyle = {}
    let dynamicDiameter = 50 + (count * 2)
    if (dynamicDiameter > 92) dynamicDiameter = 92
    Object.assign(markerStyle, styles.container)
    Object.assign(markerStyle, {
      height: dynamicDiameter, width: dynamicDiameter, top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
    })
    return (
      <div role="button" tabIndex="-1" onClick={() => { onClick() }} style={markerStyle}>
        <img src={clusterIcon} alt="clusterIcon" style={styles.iconContainer} />
        <label style={styles.label}>{count}</label>
      </div>
    )
  }

  render = ({ count } = this.props) => this.returnCluster(count)
}
