import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'react-apollo'
import queryConnector from '@graphql/queryConnector'
import mutationConnector from '@graphql/mutationConnector'
import { moslQuery } from '@graphql/query'
import { updateMOSL } from '@graphql/mutation'

const clientPreferencesHOC = () => (WrappedComponent) => {
  class ClientPrefernecesHOC extends React.Component {
    static propTypes = {
      moslMutation: PropTypes.func.isRequired,
    }

    updateMOSL = (mosl) => {
      const { moslMutation } = this.props

      moslMutation({ variables: { mosl } })
    }

    render() {
      return (
        <WrappedComponent
          updateMOSL={this.updateMOSL}
          {...this.props}
        />
      )
    }
  }

  return compose(
    queryConnector(moslQuery, null, 'moslInfo', true),
    mutationConnector(updateMOSL, 'moslMutation'),
  )(ClientPrefernecesHOC)
}

export default clientPreferencesHOC
