import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'antd'

export default class NotificationHeader extends Component {
  static propTypes = {
    notifications: PropTypes.object,
    currentSelection: PropTypes.string,
    editMode: PropTypes.bool,
    ifShowAlert: PropTypes.bool,
    saveResponseAlertType: PropTypes.string,
    wMatrix: PropTypes.func.isRequired,
  }


  static defaultProps = {
    notifications: {
      1: {
        title: 'Device Battery',
        description: 'Lorem ipsum dolor amet veniam pickled paleo fam photo booth ugh lorem man bun artisan everyday carry sartorial sustainable.',
      },
    },
    currentSelection: 1,
    editMode: false,
    ifShowAlert: false,
    saveResponseAlertType: '',
  }

  render() {
    const {
      notifications, currentSelection, editMode, ifShowAlert, wMatrix, saveResponseAlertType,
    } = this.props
    return (
      <div style={{ marginBottom: '10px' }}>
        <h2 style={{ marginBottom: editMode ? '43px' : '18px' }}>
          {notifications[currentSelection].title}
          {ifShowAlert
            ? (
              <Alert
                style={{ display: 'inline-block', marginLeft: 30 }}
                showIcon
                message={saveResponseAlertType === 'success' ? wMatrix('saveAlertSuccessMsg') : wMatrix('saveAlertErrorMsg')}
                type={saveResponseAlertType}
              />
            ) : null}
        </h2>

        <p style={{ display: editMode ? 'none' : 'block', marginTop: '18px', marginRight: '18px' }}>{notifications[currentSelection].description}</p>
      </div>
    )
  }
}
