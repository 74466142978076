import React from 'react'
import PropTypes from 'prop-types'
import {
  Popconfirm, Icon, Form, Input, Checkbox,
} from 'antd'
import moment from 'moment'
import EditableTaskTable from '@mol/logistics/editableTaskTable'
import LogisticsStatus from '@atom/logistics/status'
import ButtonWrapper from '@atom/textButton'

const styles = {
  row: {
    display: 'flex',
    width: '100%',
    minHeight: '50px',
  },
  cell: {
    display: 'flex',
    flexGrow: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    alignItems: 'center',
  },
  cellTextWrapper: {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginRight: 10,
  },
  line: {
    width: '17px',
    height: '4px',
    borderRadius: '1px',
    background: '#d8d8d8',
    margin: '4px',
  },
  label: {
    marginLeft: 4,
    marginRight: 4,
    whiteSpace: 'nowrap',
    display: 'block',
    color: '#4482FF',
    cursor: 'pointer',
  },
  rowDestinationAndTaskWrapper: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #e8e8e8',
    flex: 13,
  },
  background: {
    paddingLeft: 13,
    display: 'flex',
    borderBottom: '1px solid #e8e8e8',
    background: '#f9f9f9',
    // flex: 13,
    width: '100%',
    height: '50px',
  },
  formItem: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: 0,
  },
  tasksContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 13,
    border: '1px solid #e8e8e8',
    borderRadius: '3px',
    padding: 20,
  },
}

const dateFormat = 'MMM DD, YYYY h:mm a' // 'YYYY-MM-DD h:mm a'

class DestinationRow extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    destination: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    updateDestination: PropTypes.func.isRequired,
    deleteDestination: PropTypes.func.isRequired,
    createTask: PropTypes.func.isRequired,
    updateTask: PropTypes.func.isRequired,
    deleteTask: PropTypes.func.isRequired,
    job: PropTypes.object,
    draggableHandleProps: PropTypes.object.isRequired,
    setExpandedState: PropTypes.func.isRequired,
    expanded: PropTypes.bool,
  }

  static defaultProps ={
    expanded: false,
    job: null,
  }

  state = {
    editing: false,
  }

  save = async () => {
    const { updateDestination, destination, form } = this.props
    form.validateFields(async (error, row) => {
      if (error) return
      const updatedData = { ...destination, ...row }
      this.setState({ editing: false })
      await updateDestination(updatedData)
    })
  }

  cancel = () => {
    this.setState({ editing: false })
  }

  edit = () => {
    const { setExpandedState, destination } = this.props
    this.setState({ editing: true })
    setExpandedState('collapse', destination.id)
  }

  delete = () => {
    const { deleteDestination, destination, job } = this.props
    deleteDestination(destination, job)
  }

  renderActionsCell = () => {
    const { wMatrix, destination } = this.props
    return (
      <div
        style={{
          ...styles.cell, width: 100, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
        }}
      >
        {this.renderEditIcon()}
        <div style={{
          ...styles.cell, justifyContent: 'center', margin: 10,
        }}
        >
          <Popconfirm
            title={`${wMatrix('okToDelete')}?`}
            okText={wMatrix('Delete')}
            cancelText={wMatrix('Cancel')}
            onConfirm={this.delete}
            disabled={destination.status === 'completed'}
          >
            <label style={styles.label}>
              <Icon
                type="delete"
                theme="twoTone"
                twoToneColor={destination.status === 'completed' ? '#d8d8d8' : '#f64744'}
              />
            </label>
          </Popconfirm>
        </div>
      </div>
    )
  }

  renderEditIcon = () => {
    const { wMatrix, destination } = this.props
    const { editing } = this.state
    if (editing) {
      return (
        <div style={{ margin: 10 }}>
          <ButtonWrapper
            onClick={this.save}
            useAsWrapper
          >
            <div style={{ ...styles.label, fontSize: '12px' }}>
              <Icon style={{ margin: '5px 5px 5px 0px' }} type="save" />
              {wMatrix('Save')}
            </div>
          </ButtonWrapper>
          <ButtonWrapper
            onClick={this.cancel}
            useAsWrapper
          >
            <div style={{ ...styles.label, fontSize: '12px' }}>
              {wMatrix('Cancel')}
            </div>
          </ButtonWrapper>
        </div>
      )
    }
    return (
      <ButtonWrapper
        style={{ margin: 10 }}
        onClick={this.edit}
        useAsWrapper
        disabled={destination.status === 'completed'}
      >
        <Icon
          type="edit"
          theme="twoTone"
          twoToneColor={destination.status === 'completed' ? '#d8d8d8' : '#4482ff'}
        />
      </ButtonWrapper>

    )
  }

  renderCompleted = () => {
    const { destination, form } = this.props
    if (destination.completionDate) {
      return (
        <>
          <Input
            value={moment(destination.completionDate).format(dateFormat)}
            disabled
          />
        </>
      )
    }
    return (
      <div style={{ display: 'flex', width: '70%', justifyContent: 'center' }}>
        <Form.Item style={styles.formItem}>
          {form.getFieldDecorator('completed', {
            initialValue: false,
            valuePropName: 'checked',
          })(
            <Checkbox />,
          )}
        </Form.Item>
      </div>
    )
  }

  /**
   * renders the Order Cell for the Destination row
   * @returns Element
   */
  renderOrderCell = () => {
    const { destination, draggableHandleProps } = this.props
    return (
      <div
        style={{
          ...styles.cell,
          // flex: 1,
          justifyContent: 'flex-start',
          marginRight: 0,
          paddingRight: 0,
          flexShrink: 0,
          alignSelf: 'flex-start',
          marginTop: 14,
          width: 65,
        }}
        {...draggableHandleProps}
      >
        <div>
          <div style={styles.line} />
          <div style={styles.line} />
        </div>
        <div style={{ width: '5px' }} />
        {destination.sequence}
      </div>
    )
  }

  /**
   * Temp placeholder
   * @returns Element
   */
  renderExpandButton = () => {
    const { setExpandedState, destination, expanded } = this.props
    return (
      <ButtonWrapper
        onClick={() => setExpandedState(expanded ? 'collapse' : 'expand', destination.id)}
        useAsWrapper
        style={{ ...styles.cell }}
      >
        <div
          role="button"
          tabIndex="0"
          className={`ant-table-row-expand-icon ${expanded ? 'ant-table-row-expanded' : 'ant-table-row-collapsed'}`}
          aria-label={expanded ? 'Collapse row' : 'Expand row'}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        />
      </ButtonWrapper>
    )
  }

  renderDestination = () => {
    const { destination, wMatrix, form } = this.props
    const { editing } = this.state
    let completeText = ''
    if (destination.completionDate) {
      completeText = moment(destination.completionDate).format(dateFormat)
    }

    return (
      <Form
        name="destinationForm"
        style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
      >
        <div style={styles.row}>
          {this.renderOrderCell()}
          <div style={styles.rowDestinationAndTaskWrapper}>
            <div style={styles.background}>
              <div style={{ ...styles.cell, flex: 1 }}>
                {this.renderExpandButton()}
                <div style={{ ...styles.cell, marginLeft: 10 }}>
                  {destination.tasks.length}
                </div>
              </div>
              <div style={{ ...styles.cell, flex: 2 }}>
                {editing ? (
                  <Form.Item style={{ marginBottom: 0, marginRight: 10 }}>
                    {form.getFieldDecorator('label', {
                      initialValue: destination.label,
                    })(
                      <Input size="small" style={{ }} />,
                    )}
                  </Form.Item>
                ) : (
                  <div style={styles.cellTextWrapper}>
                    {destination.label}
                  </div>
                )}
              </div>
              <div style={{ ...styles.cell, flex: 3 }}>
                {editing ? (
                  <Form.Item style={{ marginBottom: 0, marginRight: 10 }}>
                    {form.getFieldDecorator('address', {
                      initialValue: destination.address,
                    })(
                      <Input size="small" style={{ }} />,
                    )}
                  </Form.Item>
                )
                  : (
                    <div style={styles.cellTextWrapper}>
                      {destination.address}
                    </div>
                  )
                }
              </div>
              <div style={{ ...styles.cell, flex: 2 }}>
                <div style={styles.cellTextWrapper}>
                  {destination.coordinates}
                </div>
              </div>
              <div style={{ ...styles.cell, flex: 2, flexShrink: 0 }}>
                <LogisticsStatus
                  wMatrix={wMatrix}
                  status={destination.status}
                />
              </div>
              <div style={{ ...styles.cell, flex: 2 }}>
                {editing
                  ? this.renderCompleted()
                  : (
                    <div style={styles.cellTextWrapper}>
                      {completeText}
                    </div>
                  )}
              </div>
              {this.renderActionsCell()}
            </div>
            {this.renderTasks()}
          </div>
        </div>
      </Form>
    )
  }

  renderTasks = () => {
    const {
      destination, wMatrix, form,
      updateTask, deleteTask, createTask,
      expanded,
    } = this.props
    // if (!destination.expanded) return null
    if (!expanded) return null
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
      }}
      >
        <h3 style={{ margin: '5px 0 0 0px' }}>{wMatrix('additionalItems')}</h3>
        <EditableTaskTable
          form={form}
          tasks={destination.tasks}
          wMatrix={wMatrix}
          updateTask={updateTask}
          deleteTask={deleteTask}
          destinationId={destination.id}
          destination={destination}
          createTask={createTask}
        />
      </div>
    )
  }

  render() {
    return (
      <div style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        marginBottom: 10,
        flexShrink: 0,
      }}
      >
        {this.renderDestination()}
      </div>
    )
  }
}

export default Form.create({ name: 'destinationForm' })(DestinationRow)
