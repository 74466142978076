import PropTypes from 'prop-types'
import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import Marker from '@atom/marker'
import ClusterMarker from '@atom/clusterMarker'
import DistanceAddressMarker from '@atom/distanceAddressMarker'
import Supercluster from 'supercluster'
import equal from 'deep-equal'
import helper from '@helper'
import Analytics from '@analytics'

class DistanceMap extends Component {
  map = null

  maps = null

  directionService = null

  directionPath = null

  static propTypes = {
    initMap: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired,
    devices: PropTypes.array,
    selectedDevice: PropTypes.object,
    selectDevice: PropTypes.func,
    fitBounds: PropTypes.func,
    center: PropTypes.object,
    zoom: PropTypes.number,
    mapIconMode: PropTypes.string,
    distanceOrigin: PropTypes.object,
    ifCheckDistance: PropTypes.bool,
    sortedDevicelist: PropTypes.array,
    hoveredDevice: PropTypes.object,
    setDirectionLeg: PropTypes.func.isRequired,
    setOriginFlag: PropTypes.bool,
    mapIconSizeMode: PropTypes.string.isRequired,
    /** @Main */
    mainNav: PropTypes.string,
  }

  static defaultProps = {
    center: {
      lat: 30.116936,
      lng: -97.129397,
    },
    zoom: 1,
    devices: [],
    selectedDevice: null,
    selectDevice: () => { },
    fitBounds: () => { },
    mapIconMode: 'comfortable',
    distanceOrigin: null,
    ifCheckDistance: false,
    sortedDevicelist: [],
    hoveredDevice: null,
    setOriginFlag: false,
    mainNav: 'distance',
  }

  state = {
    clusters: [],
    supercluster: new Supercluster({
      radius: 150,
      maxZoom: 16,
    }),
    bounds: null,
    zoom: null,
  }

  componentDidUpdate(prevProps) {
    const {
      selectedDevice, distanceOrigin, setOriginFlag, sortedDevicelist,
    } = this.props
    /**
     * if switch between devices, or force to center the direction
     * zoom in accordingly
     */

    if (!equal(prevProps.selectedDevice, selectedDevice)
      || !equal(prevProps.setOriginFlag, setOriginFlag)) {
      if (distanceOrigin) {
        // also need to reset direction path
        if (!selectedDevice) {
          this.fitToDistanceAddress()
          this.clearDirection()
        } else {
          this.getDirection()
        }
      } else {
        this.fitToCoordinates(!!selectedDevice)
      }
    }

    if (!this.compareDeviceList(prevProps.sortedDevicelist, sortedDevicelist)) {
      const { bounds, zoom } = this.state
      this.createClusters(bounds, zoom)
    }
  }

  /** compare two devicelist to see if the list contain the same devices */
  compareDeviceList = (deviceList1, deviceList2) => {
    if (deviceList1.length !== deviceList2.length) {
      return false
    }
    let ifEqual = true
    for (let i = 0; i < deviceList1.length; i += 1) {
      if (deviceList1[i].id !== deviceList2[i].id) {
        ifEqual = false
      }
    }
    return ifEqual
  }

  /**
   * @private
   *
   * reset direction path on map
   */
  clearDirection = () => {
    if (this.directionPath !== null) {
      this.directionPath.setMap(null)
      this.directionPath = null
    }
    return null
  }

  /**
   * @public
   *
   * Handles when the cluster makers is clicked.
   * It zooms and expands that cluster
   */
  onClickClusterMarker = (clickedCluster) => {
    const { supercluster } = this.state
    const { fitBounds } = this.props
    const clusterPoints = supercluster.getLeaves(clickedCluster.id, Infinity)
    const bounds = new this.maps.LatLngBounds()

    for (const clusterPoint of clusterPoints) {
      const locatePos = clusterPoint.geometry.coordinates
      bounds.extend(new this.maps.LatLng(locatePos[1], locatePos[0]))
    }

    fitBounds(bounds)
  }

  /**
   * @private
   */
  selectMarkerOnMap = (id) => {
    const { selectDevice, devices, mainNav } = this.props
    for (let i = 0; i < devices.length; i += 1) {
      if (devices[i].id === id) {
        Analytics.record({
          feature: 'map',
          page: `${mainNav}`,
          event: 'select_device',
        })

        selectDevice(devices[i])
      }
    }
    return null
  }

  /**
   * @private
   *
   * Creates the markers
   */
  markers = () => {
    const { clusters } = this.state
    const { mapIconMode, mapIconSizeMode, hoveredDevice } = this.props // selectedDevice
    return (
      clusters.map((cluster) => {
        if (cluster.id) {
          return (
            <ClusterMarker
              lat={cluster.geometry.coordinates[1]}
              lng={cluster.geometry.coordinates[0]}
              count={cluster.properties.point_count}
              onClick={() => { this.onClickClusterMarker(cluster) }}
              key={`c${cluster.id}`}
            />
          )
        }

        return (
          <Marker
            view={mapIconMode}
            size={mapIconSizeMode}
            className={(hoveredDevice && hoveredDevice.id === cluster.properties.id) ? 'bounceMarker' : ''}
            lat={cluster.geometry.coordinates[1]}
            lng={cluster.geometry.coordinates[0]}
            heading={cluster.properties.heading}
            alias={cluster.properties.alias}
            driver={cluster.properties.driver}
            state={cluster.properties.state}
            key={cluster.properties.id}
            icon={cluster.properties.icon}
            onClick={() => {
              this.selectMarkerOnMap(cluster.properties.id) /** @todo */
            }}
          />
        )
      })
    )
  }

  /**
   * conditionally render this if the selected device is filtered out.
   * we still want to have a marker of the selected device on the map
   */
  selectedDeviceMarker = () => {
    const {
      sortedDevicelist, selectedDevice, mapIconMode, mapIconSizeMode, hoveredDevice,
    } = this.props
    if (!selectedDevice) {
      return null
    }
    let ifFound = false
    for (let i = 0; i < sortedDevicelist.length; i += 1) {
      if (sortedDevicelist[i].id === selectedDevice.id) {
        ifFound = true
      }
    }
    if (!ifFound) {
      return (
        <Marker
          view={mapIconMode}
          size={mapIconSizeMode}
          className={(hoveredDevice && hoveredDevice.id === selectedDevice.id) ? 'bounceMarker' : ''}
          lat={selectedDevice.locate.latitude}
          lng={selectedDevice.locate.longitude}
          heading={selectedDevice.locate.heading}
          alias={selectedDevice.alias}
          driver={selectedDevice.currentDriver ? selectedDevice.drivername : null}
          state={selectedDevice.status.state}
          key={selectedDevice.id}
          icon={selectedDevice.icon}
          onClick={() => {
            this.selectMarkerOnMap(selectedDevice.id) /** @todo */
          }}
        />
      )
    }
    return null
  }

  /**
   * @private
   *
   */
  attachDistanceAddressMarker = () => {
    const { ifCheckDistance, distanceOrigin } = this.props
    if (!ifCheckDistance) {
      return null
    }
    return (
      <DistanceAddressMarker
        lat={distanceOrigin.lat}
        lng={distanceOrigin.lng}
        key="distance"
      />
    )
  }

  /**
   * @private
   *
   * Fits the map to the distance origin and up to 5 nearest devices
   *
   */
  fitToDistanceAddress = () => {
    if (this.maps) {
      const bounds = new this.maps.LatLngBounds()
      const { fitBounds, distanceOrigin, sortedDevicelist } = this.props
      bounds.extend(new this.maps.LatLng(distanceOrigin.lat, distanceOrigin.lng))
      const boundsDeviceAmount = sortedDevicelist.length < 5 ? sortedDevicelist.length : 5
      for (let i = 0; i < boundsDeviceAmount; i += 1) {
        const d = sortedDevicelist[i]
        bounds.extend(new this.maps.LatLng(d.locate.latitude, d.locate.longitude))
      }
      if (sortedDevicelist.length === 0) {
        fitBounds(bounds, 10)
      } else {
        fitBounds(bounds)
      }
      this.map.panTo(distanceOrigin)
    }
  }

  /**
   * @private
   *
   * Fits the map to the devices or a single device
   *
   * @param {boolean} isSelectedDevice
   */
  fitToCoordinates = (isSelectedDevice) => {
    if (this.maps) {
      const bounds = new this.maps.LatLngBounds()
      const {
        devices, selectedDevice, fitBounds,
      } = this.props
      if (isSelectedDevice) {
        bounds.extend(new this.maps.LatLng(
          selectedDevice.locate.latitude, selectedDevice.locate.longitude,
        ))

        fitBounds(bounds, 16)
      } else if (devices.length > 0) {
        devices.forEach((device) => {
          bounds.extend(new this.maps.LatLng(device.locate.latitude, device.locate.longitude))
        })
        fitBounds(bounds)
      }

      return null
    }
    return null
  }

  /**
   * @public
   *
   * Callback for our custom initialization
   */
  initMap = (map, maps) => {
    const { devices } = this.props
    this.map = map
    this.maps = maps
    if (devices) {
      this.fitToCoordinates(false)
    }
  }

  /**
   * @private
   *
   * Creates an array of GeoJSONs from devices.
   * Used when loading supercluster with device locates
   *
   * @typedef GeoJSON
   * @property {string} type
   * @property {Object} properties
   * @property {Object} geometry
   * @property {string} geometry.type
   * @property {[number, number]} geometry.coordinates --> [longitude, latitude]
   *
   *
   * @returns {[GeoJSON]} array
   */
  geoJSON = () => {
    const { devices } = this.props

    return devices.filter(device => device.locate !== null).map(device => ({
      type: 'Feature',
      properties: {
        id: device.id,
        point_count: 1,
        heading: device.locate.heading,
        alias: device.alias,
        driver: (device.currentDriver ? device.currentDriver.name : device.currentDriver),
        state: (device.status ? device.status.state : device.status),
        icon: device.icon,
      },
      geometry: {
        type: 'Point',
        coordinates: [device.locate.longitude, device.locate.latitude], // [longitude, latitude]
      },
    }))
  }

  /**
   * @private
   *
   * Provides the bounds of the cluster
   *
   * @param {object} bounds NOT Google Map Bounds
   */
  clusterBounds = (bounds) => {
    const westLng = bounds.sw.lng
    const southLat = bounds.sw.lat
    const eastLng = bounds.ne.lng
    const northLat = bounds.ne.lat

    return [westLng, southLat, eastLng, northLat]
  }

  /**
   * @private
   *
   * Creates the clusters and sets the cluster state
   *
   * @param {object} bounds NOT Google Map Bounds
   * @param {number} zoom current zoom level of map
   */
  createClusters = (bounds, zoom) => {
    const { devices, settings } = this.props
    const { supercluster } = this.state

    if (devices.length > 0) {
      supercluster.load(this.geoJSON())
      if (settings.clustering) {
        this.setState({ clusters: supercluster.getClusters(this.clusterBounds(bounds), zoom + 1) })
      } else {
        // Faking the zoom level to 17 since the Supercluster's max zoom level is 16
        this.setState({ clusters: supercluster.getClusters(this.clusterBounds(bounds), 17) })
      }
    }
  }

  /**
   * @public
   *
   * When the map changes the zoom either programmatically or user control,
   * we need to reprocess the clusters
   *
   * @param {object} view
   */
  boundsChange = ({ bounds, zoom }) => {
    this.setState({ bounds, zoom })
    this.createClusters(bounds, zoom)
  }

  /**
   * @private
   *
   * Customize the map on init
   */
  createMapOptions = maps => ({
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: maps.ControlPosition.LEFT_BOTTOM,
    },
    streetViewControl: true,
    streetViewControlOptions: {
      position: maps.ControlPosition.LEFT_BOTTOM,
    },
    zoomControlOptions: {
      position: maps.ControlPosition.LEFT_BOTTOM,
    },
  })

  /**
   * @private
   * Get direction from google maps API
   */
  getDirection = () => {
    const { selectedDevice, distanceOrigin, setDirectionLeg } = this.props
    if (!this.map) {
      return null
    }

    if (this.directionsDisplay != null) {
      this.directionsDisplay.setMap(null)
      this.directionsDisplay = null
    }
    this.directionsService = new this.maps.DirectionsService()

    this.directionsService.route({
      origin: {
        lat: selectedDevice.locate.latitude,
        lng: selectedDevice.locate.longitude,
      },
      destination: {
        lat: distanceOrigin.lat,
        lng: distanceOrigin.lng,
      },
      travelMode: 'DRIVING',
    }, (result, status) => {
      if (status === 'OK') {
        // console.log(result)
        const directionBounds = result.routes[0].bounds
        if (this.directionPath !== null) {
          this.directionPath.setMap(null)
          this.directionPath = null
        }
        this.directionPathPoints = result.routes[0].overview_path
        this.directionPath = new this.maps.Polyline({
          path: this.directionPathPoints,
          strokeColor: '#2784ff',
          strokeOpacity: 1.0,
          strokeWeight: 3,
        })
        this.directionPath.setMap(this.map)
        this.map.fitBounds(directionBounds, {
          top: 50,
          left: 50,
          bottom: 50,
          right: 325,
        })
        setDirectionLeg(result.routes[0].legs[0])
      }
    })

    return null
  }

  render() {
    const { center, zoom, initMap } = this.props
    return (
      <GoogleMapReact
        bootstrapURLKeys={{
          client: 'gme-twmatters',
        }}
        onGoogleApiLoaded={({ map, maps }) => initMap(map, maps, this.initMap)}
        onChange={this.boundsChange}
        defaultCenter={center}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        options={this.createMapOptions}
      >
        {this.markers()}
        {this.selectedDeviceMarker()}
        {this.attachDistanceAddressMarker()}
      </GoogleMapReact>
    )
  }
}

export default helper()(DistanceMap)
