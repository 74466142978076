import React from 'react'
import PropTypes from 'prop-types'

import helper, { isValidEmail } from '@helper'
import {
  Modal, Form, Select, Button, Alert,
} from 'antd'

import PreferenceHOC from '@hoc/preference.hoc'

class ReportDownloadModal extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    ifShowReportDownloadModal: PropTypes.bool.isRequired,
    toggleReportDownloadModal: PropTypes.func.isRequired,
    reportDownloadMeta: PropTypes.object,
    selectedReport: PropTypes.object,
    createReportDownload: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    preferenceData: PropTypes.object,
    ifReportTooLarge: PropTypes.bool,
  }

  static defaultProps = {
    reportDownloadMeta: null,
    selectedReport: null,
    preferenceData: null,
    ifReportTooLarge: false,
  }

  state = {
    reportQueuedSuccess: null,
    reportQueueButtonDisabled: false,
  }

  closeModal = () => {
    const { toggleReportDownloadModal } = this.props
    toggleReportDownloadModal(false)
  }

  handleSubmit = async () => {
    const {
      form, reportDownloadMeta, selectedReport, createReportDownload, toggleReportDownloadModal,
    } = this.props


    this.setState({ reportQueueButtonDisabled: true })

    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          createReportDownload(
            selectedReport.type,
            JSON.stringify(reportDownloadMeta),
            values.recipients.toString(),
          ).then((res) => {
            if (res) {
              this.setState({ reportQueuedSuccess: true })
              setTimeout(() => {
                toggleReportDownloadModal(false)
                this.setState({ reportQueuedSuccess: null })
              }, 5000)
            } else {
              this.setState({ reportQueuedSuccess: false })
              setTimeout(() => {
                this.setState({ reportQueuedSuccess: null, reportQueueButtonDisabled: false })
              }, 5000)
            }
          })
        } catch (err1) {
          this.setState({ reportQueuedSuccess: false })
          setTimeout(() => {
            this.setState({ reportQueuedSuccess: null, reportQueueButtonDisabled: false })
          }, 5000)
        }
      }
    })
  }

  renderRecipients = (form) => {
    const { wMatrix, preferenceData } = this.props
    const emailValidator = (rule, values, callback) => {
      const invalidInputs = values.filter(value => !isValidEmail(value))
      if (invalidInputs.length === 0) {
        callback()
      } else if (invalidInputs.length === 1) {
        callback(`${invalidInputs.join('')} is not a valid email`)
      } else {
        callback(`${invalidInputs.slice(0, -1).join(', ')} and ${invalidInputs.slice(-1)} are not valid emails`)
      }
    }

    let initialValue = []
    if (preferenceData && preferenceData.data && preferenceData.data.preference) {
      initialValue = [preferenceData.data.preference.email]
    }

    return (
      <div key="recipients" style={{ width: '100%' }}>
        <Form.Item style={{ width: '100%' }}>
          {wMatrix('email')}
          :
          {form.getFieldDecorator('recipients', {
            initialValue,
            rules: [
              { validator: emailValidator },
              { required: true, message: wMatrix('This field is required') },
            ],
          })(
            <Select style={{ width: '100%' }} mode="tags" />,
          )}
        </Form.Item>
      </div>
    )
  }

  resetFormUI = () => {
    this.setState({ reportQueueButtonDisabled: false })
  }

  render() {
    const {
      ifShowReportDownloadModal, form, wMatrix, ifReportTooLarge,
    } = this.props
    const { reportQueueButtonDisabled, reportQueuedSuccess } = this.state
    const recipientsInput = this.renderRecipients(form)

    return (
      <>
        <Modal
          width={720}
          visible={ifShowReportDownloadModal}
          onCancel={this.closeModal}
          footer={null}
          afterClose={this.resetFormUI}
          maskClosable={false}
        >
          <div>
            <h2>{ifReportTooLarge ? wMatrix('reportTooLarge') : wMatrix('reportDownload')}</h2>
            <p>{ifReportTooLarge ? wMatrix('reportTooLargeDescription') : wMatrix('reportDownloadDescription')}</p>
            <Form className="reportInputForm" onSubmit={this.handleSubmit}>
              {recipientsInput}
            </Form>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
            {reportQueuedSuccess
              ? <Alert style={{ padding: '8px 16px 8px 16px', marginRight: 20 }} type="success" message={wMatrix('saveReportDownloadSuccess')} />
              : null
            }
            {reportQueuedSuccess === false
              ? <Alert style={{ padding: '8px 16px 8px 16px', marginRight: 20 }} type="error" message={wMatrix('saveReportDownloadError')} />
              : null
            }
            <Button
              type="primary"
              onClick={this.handleSubmit}
              disabled={reportQueueButtonDisabled}
            >
              {wMatrix('request')}
            </Button>
          </div>
        </Modal>
      </>
    )
  }
}

const WrappedReportDownloadForm = Form.create({ name: 'reportDownloadForm' })(ReportDownloadModal)

export default helper()(PreferenceHOC()(WrappedReportDownloadForm))
