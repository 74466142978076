
import React from 'react'
import PropTypes from 'prop-types'
import {
  Alert, Button, Checkbox, Icon, InputNumber, Tooltip, Select,
} from 'antd'
import ModalHeader from '@mol/modalHeader'
import helper from '@helper'

const { Option } = Select

class AdvancedMgrPanel extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    selectedDevice: PropTypes.object.isRequired,
    assignStopLength: PropTypes.func.isRequired,
    assignSpeedThreshold: PropTypes.func.isRequired,
    assignFobStatus: PropTypes.func.isRequired,
    assignTimelineRange: PropTypes.func.isRequired,
    getMeasurement: PropTypes.func.isRequired,
    refetchDevices: PropTypes.func.isRequired,
  }

  state = {
    stopMinutes: 5,
    speedLimit: 75,
    alert: {
      display: false,
      message: '',
      type: 'success',
    },
    saving: false,
    measurement: 'm',
    fobEnabled: false,
    timelineRange: 'thirty-history',
  }

  componentDidMount() {
    const { selectedDevice, getMeasurement } = this.props
    if (selectedDevice.stopMinutes) {
      this.setState({ stopMinutes: selectedDevice.stopMinutes })
    }
    if (selectedDevice.speedThreshold) {
      this.setState({ speedLimit: selectedDevice.speedThreshold })
    }
    if (selectedDevice.timelineRange) {
      this.setState({ timelineRange: selectedDevice.timelineRange })
    }
    this.setState({ measurement: getMeasurement(), fobEnabled: selectedDevice.fobEnabled })
  }

  handleSave = async () => {
    const {
      selectedDevice,
      assignStopLength,
      assignSpeedThreshold,
      refetchDevices,
      assignFobStatus,
      assignTimelineRange,
    } = this.props
    const {
      stopMinutes, speedLimit, measurement, fobEnabled, timelineRange,
    } = this.state

    if (selectedDevice.stopMinutes === stopMinutes
      && selectedDevice.speedThreshold === speedLimit
      && selectedDevice.fobEnabled === fobEnabled
      && selectedDevice.timelineRange === timelineRange
    ) {
      return
    }
    try {
      this.setState({ saving: true })
      const speedInMPH = measurement === 'k' ? Math.round(speedLimit * 0.621371) : speedLimit
      if (selectedDevice.stopMinutes !== stopMinutes) {
        await assignStopLength(selectedDevice.id, stopMinutes)
      }
      if (selectedDevice.speedThreshold !== speedLimit) {
        await assignSpeedThreshold(selectedDevice.id, speedInMPH)
      }

      if (selectedDevice.fobEnabled !== fobEnabled) {
        await assignFobStatus(selectedDevice.id, fobEnabled)
      }

      if (selectedDevice.timelineRange !== timelineRange) {
        await assignTimelineRange(selectedDevice.id, timelineRange)
      }

      await refetchDevices()

      const success = {
        display: true,
        message: 'Success',
        type: 'success',
      }
      this.setState({ alert: success, saving: false })
      setTimeout(() => {
        const hidden = {
          display: false,
          message: '',
          type: 'success',
        }
        this.setState({ alert: hidden })
      }, 5000)
    } catch (e) {
      const error = {
        display: false,
        message: 'Something went wrong',
        type: 'error',
      }
      this.setState({ alert: error })
    }
  }

  handleFobAssignment = (e) => {
    this.setState({ [e.target.id]: e.target.checked })
  }

  render() {
    const { wMatrix, selectedDevice } = this.props
    const {
      alert, saving, measurement, fobEnabled, timelineRange,
    } = this.state
    const displayFobEnabled = selectedDevice.type.toLowerCase().indexOf('genx') > -1

    return (
      <div style={{ display: 'flex', flexDirection: 'column', margin: '0 50px' }}>
        <ModalHeader
          headerName={wMatrix('advancedOptions')}
          description=""
        />
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <div style={{ margin: '0 5px 0 0', minWidth: 200 }}>
            {wMatrix('stopLength')}
            <span style={{ width: '5px', display: 'inline-block' }} />
            <Tooltip
              placement="right"
              title={wMatrix('stopLengthDescription')}
              arrowPointAtCenter
            >
              <Icon type="question-circle" theme="outlined" />
            </Tooltip>
          </div>

          <InputNumber
            min={3}
            max={60}
            onChange={e => this.setState({ stopMinutes: e })}
            defaultValue={selectedDevice.stopMinutes}
          />
          <div style={{ margin: '0 0 0 10px' }}>{wMatrix('min')}</div>
        </div>

        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <div style={{ margin: '0 5px 0 0', minWidth: 200 }}>
            {wMatrix('speedLimit')}
            <span style={{ width: '5px', display: 'inline-block' }} />
            <Tooltip
              placement="right"
              title={wMatrix('speedLimitDescription')}
              arrowPointAtCenter
            >
              <Icon type="question-circle" theme="outlined" />
            </Tooltip>
          </div>
          <InputNumber
            min={0}
            max={1000}
            onChange={e => this.setState({ speedLimit: e })}
            defaultValue={measurement === 'k' ? Math.round(selectedDevice.speedThreshold * 0.621371) : selectedDevice.speedThreshold}
          />
          <div style={{ margin: '0 0 0 10px' }}>{measurement === 'k' ? 'kph' : 'mph'}</div>
        </div>

        {selectedDevice.unit.abbr === 'AT' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ margin: '0 5px 0 0', minWidth: 200 }}>
              {wMatrix('defaultTimeRange')}
              <span style={{ width: '5px', display: 'inline-block' }} />
              <Tooltip
                placement="right"
                title={wMatrix('defaultTimeRangeDescription')}
                arrowPointAtCenter
              >
                <Icon type="question-circle" theme="outlined" />
              </Tooltip>
            </div>
            <Select
              style={{ width: '150px' }}
              onChange={e => this.setState({ timelineRange: e })}
              value={timelineRange}
            >
              <Option value="thirty-history">{wMatrix('thirtyHistory')}</Option>
              <Option value="daily-history">{wMatrix('dailyHistory')}</Option>
            </Select>
          </div>
        ) : null }

        {displayFobEnabled ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ margin: '0 5px 0 0', minWidth: 200 }}>
              {wMatrix('fobEnabled')}
              <span style={{ width: '5px', display: 'inline-block' }} />
              <Tooltip
                placement="right"
                title={wMatrix('fobEnabledDescription')}
                arrowPointAtCenter
              >
                <Icon type="question-circle" theme="outlined" />
              </Tooltip>
            </div>
            <Checkbox
              id="fobEnabled"
              checked={fobEnabled}
              onChange={this.handleFobAssignment}
            />
          </div>
        ) : null }

        <div style={{
          margin: '15px 0 0 0',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
            {alert.display
              ? (
                <Alert
                  style={{
                    width: '275px',
                    margin: '0px 10px',
                  }}
                  closable
                  showIcon
                  message={alert.message}
                  type={alert.type}
                />
              ) : null}
          </div>
          <Button
            type="primary"
            onClick={this.handleSave}
            disabled={saving}
          >
            {saving ? wMatrix('Saving') : wMatrix('Save')}
          </Button>
        </div>
      </div>
    )
  }
}

export default helper()(AdvancedMgrPanel)
