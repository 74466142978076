import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  AutoComplete, Input, Icon,
} from 'antd'
import PoweredByGoogle from '@assets/img/powered_by_google_on_white.png'


const Google = window.google

const { Option } = AutoComplete

const styles = {
  poweredByFooter: {
    borderTop: '1px solid #e8e8e8',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
}

/**
 * @todo
 * - maybe: instead of forcing a valid address, place warning stating that address
 *    is invalid (instead of currentl onBlur function)
 */

class addressAutoComplete extends Component {
  autoCompleteService = null

  geoCoder = null

  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    onSelect: PropTypes.func, // callback: function(coordinates, address)
    defaultAddress: PropTypes.string,
    style: PropTypes.object,
    showPoweredByGoogle: PropTypes.bool, // shows powered by google image as last item/footer
    placeholder: PropTypes.string,
    showSearchIcon: PropTypes.bool,
  }

  static defaultProps = {
    onSelect: null,
    defaultAddress: null,
    style: null,
    showPoweredByGoogle: false,
    placeholder: null,
    showSearchIcon: false,
  }

  state = {
    address: '',
    placePredictions: [],
  }

  constructor(props) {
    super(props)
    // const { defaultAddress } = props
    this.autoCompleteService = new Google.maps.places.AutocompleteService()
    this.geoCoder = new Google.maps.Geocoder()
    this.state = {
      address: '',
      placePredictions: [],
    }
  }


  componentDidMount() {
    const { defaultAddress } = this.props
    if (defaultAddress && defaultAddress.length > 0) {
      this.onSelect(defaultAddress)
    }
  }

  componentDidUpdate = (prevProps) => {
    const { defaultAddress } = this.props
    if (defaultAddress !== prevProps.defaultAddress) {
      if (defaultAddress && defaultAddress.length > 0) {
        this.onSelect(defaultAddress)
      } else {
        this.resetInput()
      }
    }
  }

  resetInput = () => {
    this.setState({
      address: '',
      placePredictions: [],
    })
  }

  /**
   * OnChange/OnSearch callback function passed to AutoComplete. Updates the address
   * state as well as the placePredictions state array.
   * @param {String} input input address string
   */
  onInputChange = (input) => {
    const { onSelect } = this.props
    // if no valid input, set states accordingly and return early.
    if (!input || input === '') {
      this.setState({
        address: '',
        placePredictions: [],
      })
      /**
       * If onSelect callback is defined, call with cleared values on clearing input
       */
      if (onSelect) {
        onSelect(null, '')
      }
      return
    }
    // Input is valid string
    // set address input state
    this.setState({ address: input })
    // obtain and update predictions
    this.autoCompleteService.getPlacePredictions({ input },
      (predictions) => {
        if (predictions && predictions.length > 0) {
          this.setState({ placePredictions: predictions })
        } else {
          this.setState({ placePredictions: [] })
        }
      })
  }

  /**
   * Returns a promise that when resolved, returns an object containing lat and lng.
   * @param {String} address Address to geocode for lat lng
   * @returns Promise <Object>
   */
  getGeoCodePromise = address => new Promise((resolve, reject) => {
    this.geoCoder.geocode({ address }, (results) => {
      if (results.length === 0) {
        reject()
      }
      const latlng = {
        lat: results[0].geometry.location.lat(),
        lng: results[0].geometry.location.lng(),
      }
      resolve(latlng)
    })
  })

  /**
   * OnSelect function callback run by ant designs autocomplete component
   * @param {String} address Address value of ant designs autocomplete component
   */
  onSelect = async (address) => {
    const { onSelect } = this.props
    this.setState({ address })
    let coordinate = null
    try {
      // get lat lng of address
      coordinate = await this.getGeoCodePromise(address)
    } catch (e) {
      coordinate = null
    }
    // if callback is passed, run callback function and pass values
    if (onSelect) {
      onSelect(coordinate, address)
    }
  }

  /**
 * Returns array of options used in autocomplete.
 * @returns {[AutoComplete.Option]} Array of Options for Autocomplete
 */
  returnAutoCompleteOptions = () => {
    const { showPoweredByGoogle } = this.props
    const { placePredictions } = this.state
    if (!placePredictions || placePredictions.length === 0) {
      return []
    }
    const placePredictionsClone = JSON.parse(JSON.stringify(placePredictions))
    const mappedPredictions = placePredictionsClone.map(place => (
      <Option
        key={`${place.place_id}`}
        address={place.description}
        value={place.description}
      >
        {place.description}
      </Option>
    ))
    /** If showPoweredByGoogle, show credit image as last item/footer */
    if (showPoweredByGoogle) {
      mappedPredictions.push(
        <Option
          key="addressAutoCompleteFooter"
          disabled
          value=""
          style={styles.poweredByFooter}
        >
          <img src={PoweredByGoogle} alt="powered-by-google" />
        </Option>,
      )
    }
    return mappedPredictions
  }

  /**
  * Used with onBlur to force a google address as input. If incomplete address is
  * passed, when leaving the component, the first prediction is autoselected.
  */
  selectFirstPrediction = () => {
    const { placePredictions, address } = this.state
    if (placePredictions && placePredictions.length > 0) {
      const predictionDescriptions = JSON.parse(JSON.stringify(placePredictions))
        .map(place => place.description)
      const firstPrediction = placePredictions[0].description
      /**
       * only run when the first prediction is not already selected and an item
       * from the list wasn't already selected
       */
      if (firstPrediction !== address && !predictionDescriptions.includes(address)) {
        this.onSelect(firstPrediction)
      }
    }
  }

  customSearchInput = () => {
    const { showSearchIcon } = this.props
    if (!showSearchIcon) return null
    return (
      <Input
        allowClear
        className="mapAddressSearch"
        suffix={<Icon type="search" style={{ right: '12px', color: '#bababa' }} />}
      />
    )
  }

  /**
 * @NOTE
 * Ant design AutoComplete doesnt need to show attribution "powered by google"
 * when on a page with google map
 * https://developers.google.com/maps/documentation/places/web-service/policies#powered
 */

  render() {
    const {
      wMatrix, defaultAddress, style, placeholder, showSearchIcon,
    } = this.props
    const { address } = this.state

    const autoCompleteChildren = this.returnAutoCompleteOptions()

    return (
      <AutoComplete
        allowClear={!showSearchIcon}
        value={address}
        placeholder={placeholder || wMatrix('addressSearch')}
        onSearch={this.onInputChange}
        onSelect={this.onSelect}
        onBlur={this.selectFirstPrediction}
        defaultValue={defaultAddress}
        style={style || undefined}
        dataSource={autoCompleteChildren}
      >
        {this.customSearchInput()}
      </AutoComplete>
    )
  }
}

export default addressAutoComplete
