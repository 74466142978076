import React, { Component } from 'react'
import { compose } from 'react-apollo'
import { message } from 'antd'
import { cameraClipsQuery } from '@graphql/query'
import {
  markClipAsViewed, updateClip, requestCustomClipUpload, deleteClip,
} from '@graphql/mutation'
import queryConnector from '@graphql/queryConnector'
import consumerConnector from '@graphql/consumerConnector'
import PropTypes from 'prop-types'
import helper from '@helper'

const videoClipsHOC = () => (WrappedComponent) => {
  class VideoClipsHOC extends Component {
    static propTypes = {
      wMatrix: PropTypes.func.isRequired,
      eventToString: PropTypes.func.isRequired,
      devices: PropTypes.array.isRequired,
      cameraClipsQueryConnector: PropTypes.object.isRequired,
      apolloClient: PropTypes.object.isRequired,
      clipsSearchValue: PropTypes.string.isRequired,
      updateClipSearch: PropTypes.func.isRequired,
    }

    state = {
      cameraDevices: [],
    }

    /**
     * Isolates camera devices and sets as query variables
     */
    componentDidMount = () => {
      this.setCameraDevices()
    }

    /**
     * Isolates camera devices and sets as query variables
     */
    componentDidUpdate = (prevProps) => {
      const { devices } = this.props
      if (devices.length !== prevProps.devices.length) {
        // refetch cameraClips
        this.setCameraDevices()
      }
    }

    /**
     * Updates the deviceIds variable of cameraClipsQuery
     */
    setCameraDevices = () => {
      const { cameraClipsQueryConnector, devices } = this.props
      let devicesClone = JSON.parse(JSON.stringify(devices))
      devicesClone = devicesClone.filter(device => device.type.includes('-CAMERA'))
      this.setState({ cameraDevices: devicesClone })
      const cameraDeviceIds = devicesClone.map(device => device.id)
      cameraClipsQueryConnector.setVariables({ deviceIds: cameraDeviceIds })
    }

    /**
     * Deletes camera clip references in Rhino DB tables
     * @param {Number} clipId clip identifier
     * @param {Number} deviceId device identifer (Rhino DB)
     */
    deleteClip = async (clipId, deviceId) => {
      if (!clipId || !deviceId) return
      const {
        cameraClipsQueryConnector,
        apolloClient,
        wMatrix,
      } = this.props
      try {
        const markClipRes = await apolloClient.mutate({
          mutation: deleteClip,
          variables: {
            clipId,
            deviceId,
          },
        })
        if (markClipRes.data && markClipRes.data.deleteClip
          && markClipRes.data.deleteClip.code === 1000) {
          await cameraClipsQueryConnector.refetch()
        }
      } catch (err) {
        message.success(wMatrix('errorDeleting'), 2)
      }
    }

    /**
     * Set clip as viewed by user
     * @param {Number} clipId clip identifier
     */
    markClipViewed = async (clipId) => {
      if (!clipId) return
      const {
        cameraClipsQueryConnector,
        apolloClient,
      } = this.props
      try {
        const markClipRes = await apolloClient.mutate({
          mutation: markClipAsViewed,
          variables: {
            clipId,
          },
        })
        if (markClipRes.data && markClipRes.data.markClipAsViewed
          && markClipRes.data.markClipAsViewed.code === 1000) {
          await cameraClipsQueryConnector.refetch()
        }
      } catch (err) {
        // error marking viewed
        /** No need to show error for marking clip viewed */
      }
    }

    /**
     * Marks clip as favorite or not for user
     * @param {Number} clipId clip identifier
     * @param {Boolean} favoriteState favorite state
     */
    markClipFavorite = async (clipId, favoriteState) => {
      if (!clipId) return
      // updateClip
      const {
        cameraClipsQueryConnector,
        apolloClient,
      } = this.props
      try {
        const markClipRes = await apolloClient.mutate({
          mutation: updateClip,
          variables: {
            clipId,
            favorite: favoriteState,
          },
        })
        if (markClipRes.data && markClipRes.data.updateClip
          && markClipRes.data.updateClip.code === 1000) {
          await cameraClipsQueryConnector.refetch()
        }
      } catch (err) {
        // error marking viewed
        /** No need to show error for marking clip favorite */
      }
    }

    /**
     * Requests custom camera clip given a time frame for a specific device
     * @param {Number} deviceId device id
     * @param {String} startDatetime start datetime of event
     * @param {String} endDatetime end datetime of event
     */
    requestCustomCameraClipUpload = async (deviceId, startDatetime, duration) => {
      const { apolloClient, wMatrix } = this.props
      const messageKey = Date.now().toString()
      try {
        message.info({ content: wMatrix('clipUploadRequestedWarning'), messageKey, duration: 15 })
        const uploadReqRes = await apolloClient.mutate({
          mutation: requestCustomClipUpload,
          variables: {
            deviceId,
            startDatetime,
            duration,
          },
        })
        if (uploadReqRes.data && uploadReqRes.data.uploadCustomClip
          && uploadReqRes.data.uploadCustomClip.code === 1000) {
          message.success({ content: wMatrix('clipUploadRequested'), messageKey, duration: 2 })
        }
      } catch (err) {
        message.error({ content: wMatrix('errorRequestingClipUpload'), messageKey, duration: 2 })
      }
    }

    /**
     * Formats camera clip data by adding associated device information based on device id
     */
    formatCameraClipData = () => {
      // clip doesnt have alias. so get alias
      const { cameraClipsQueryConnector, devices } = this.props
      // cameraClipsQueryConnector.data.videoClips
      if (cameraClipsQueryConnector.data && cameraClipsQueryConnector.data.videoClips) {
        const devicesClone = JSON.parse(JSON.stringify(devices))
        const devicesById = {}
        devicesClone.forEach((element) => {
          devicesById[element.id] = element
        })
        let cameraClips = JSON.parse(JSON.stringify(cameraClipsQueryConnector.data.videoClips))
        /** Append device info to clips */
        cameraClips = cameraClips.map(clip => ({
          device: devicesById[clip.deviceId],
          ...clip,
        }))
        return cameraClips
      }
      return []
    }

    /**
     * @description - filter for camera clips.
     */
    filterClipsBySearch = (clip) => {
      const { clipsSearchValue, eventToString } = this.props
      return (
        (clip.device
          && clip.device.alias.toLowerCase().includes(clipsSearchValue.toLowerCase())) // alias
        || (clip.eventType // event
          && eventToString(clip.eventType).toLowerCase().includes(clipsSearchValue.toLowerCase()))
        || (clip.driver && (typeof clip.driver === 'string') && clip.driver.toLowerCase().includes(clipsSearchValue.toLowerCase()))
        || (clip.drivers && (typeof clip.drivers === 'object') && JSON.stringify(clip.drivers).toLowerCase().includes(clipsSearchValue.toLowerCase()))
        || (clip.address && (typeof clip.address === 'string') && clip.address.toLowerCase().includes(clipsSearchValue.toLowerCase()))
        || (clip.address && (typeof clip.address === 'object') && JSON.stringify(clip.address).toLowerCase().includes(clipsSearchValue.toLowerCase()))
        || (clip.landmarks && (typeof clip.landmarks === 'string') && clip.landmarks.toLowerCase().includes(clipsSearchValue.toLowerCase()))
        || (clip.landmarks && (typeof clip.landmarks === 'object') && JSON.stringify(clip.landmarks).toLowerCase().includes(clipsSearchValue.toLowerCase()))
      )
    }

    render() {
      const { cameraClipsQueryConnector, updateClipSearch, clipsSearchValue } = this.props
      const { cameraDevices } = this.state
      let cameraClipsArray = this.formatCameraClipData()
      cameraClipsArray = cameraClipsArray.filter(this.filterClipsBySearch)
      const clipsLoading = cameraClipsQueryConnector.loading
      return (
        <WrappedComponent
          cameraClips={cameraClipsArray}
          clipsLoading={clipsLoading}
          markClipViewed={this.markClipViewed}
          markClipFavorite={this.markClipFavorite}
          deleteClip={this.deleteClip}
          updateClipSearch={updateClipSearch}
          clipsSearchValue={clipsSearchValue}
          cameraDevices={cameraDevices}
          requestCustomCameraClipUpload={this.requestCustomCameraClipUpload}
          refreshClips={cameraClipsQueryConnector.refetch}
          {...this.props}
        />
      )
    }
  }
  return compose(
    queryConnector(cameraClipsQuery, { deviceIds: [] }, 'cameraClipsQueryConnector'),
    consumerConnector(),
    helper(),
  )(VideoClipsHOC)
}

export default videoClipsHOC
