import React, { Component } from 'react'
import {
  Table, Popconfirm, Icon, Form,
} from 'antd'
import PropTypes from 'prop-types'
import { InputSearch } from '@atom/input'
import EditableCell from '@atom/editDriverCell'

export const EditableContext = React.createContext()

/**
 * @private
 * This checks the editing key of the table to see if the table needs to be re-rendered. If
 * the editing key is the same, we don't re-render the component. If the editing
 * key has changed, we re-render.
 * Return true if props are equal (no re-render)
 * Return false if props are NOT equal (re-render)
 * @param {Object} prevProps previous props
 * @param {Object} nextProps next props
 * @returns {boolean}
 */
function tableSkipRender(prevProps, nextProps) {
  /**
   * return true if nextprops would result in same render
   * return false if need rerender
   */
  return prevProps.editingKey === nextProps.editingKey && (prevProps.editingKey !== '' && nextProps.editingKey !== '')
}

/**
 * @private
 * Creates a React.memo version of ant.Table component. This is to prevent ant designs
 * constant table re-renders. We use tableSkipRender to govern when the table re-renders.
 * ( This prevents table items from being overwritten in the middle of editing a row)
 * @returns {Component}
 */
/* eslint-disable react/display-name */
const MemoTable = React.memo((props) => {
  const {
    loading, className, components, dataSource, columns, rowKey, scroll, size,
  } = props
  return (
    <Table
      loading={loading}
      className={className}
      components={components}
      dataSource={dataSource}
      columns={columns}
      rowKey={rowKey}
      scroll={scroll}
      size={size}
      pagination={{
        defaultPageSize: 10, pageSizeOptions: ['10', '20', '50', '100'], showSizeChanger: true,
      }}
    />
  )
}, tableSkipRender)

MemoTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  components: PropTypes.object.isRequired,
  dataSource: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  rowKey: PropTypes.string.isRequired,
  scroll: PropTypes.object.isRequired,
  size: PropTypes.string.isRequired,
}

const getInputType = (dataIndex) => {
  if (dataIndex === 'device') return 'deviceDropdown'
  if (dataIndex === 'wexCard') return 'wexCard'
  return 'text'
}

/**
 * @todo
 * Maybe somehow disable groups cell when admin or global roles are selected
 *  - this.props.allDevices
 *  - add delete icon/column in edit table
 */
export default class EditableDriverTable extends Component {
  static propTypes = {
    drivers: PropTypes.array,
    isDetailed: PropTypes.bool,
    isSelectable: PropTypes.bool,
    updateDriver: PropTypes.func.isRequired,
    wMatrix: PropTypes.func.isRequired,
    showFob: PropTypes.bool,
    showWexCard: PropTypes.bool,
    deleteDriver: PropTypes.func.isRequired,
    getPermission: PropTypes.func.isRequired,
    availableDeviceOptions: PropTypes.func.isRequired,
    driversLoading: PropTypes.bool,
  }

  static defaultProps = {
    drivers: [],
    isDetailed: false,
    isSelectable: true,
    showFob: false,
    showWexCard: false,
    driversLoading: false,
  }

  state = {
    editingKey: '',
    searchVal: '',
  }

  updateSearch = (event) => {
    this.setState({ searchVal: event.target.value.substr(0, 20) })
  }

  /**
   * @description Conditionally create table columns
   */
  returnColumns = () => {
    const { wMatrix, showFob, showWexCard } = this.props
    const devColumns = [
      {
        title: ' ',
        dataIndex: 'operation',
        render: (text, record) => {
          const editable = this.isEditing(record)
          return (
            <div>
              {editable ? (
                <span style={{ verticalAlign: 'middle' }}>
                  <EditableContext.Consumer>
                    {valueObj => (
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                      <label
                        style={{
                          marginLeft: 4, marginRight: 4, whiteSpace: 'nowrap', display: 'block', color: '#4482FF', cursor: 'pointer',
                        }}
                        onClick={() => this.save(valueObj.form, record.id)}
                      >
                        <Icon style={{ margin: '5px' }} type="save" />
                        {wMatrix('Save')}
                      </label>
                    )}
                  </EditableContext.Consumer>
                  { /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */ }
                  <label
                    style={{
                      marginLeft: 4,
                      marginRight: 4,
                      whiteSpace: 'nowrap',
                      display: 'block',
                      color: '#4482FF',
                      cursor: 'pointer',
                    }}
                    onClick={() => this.cancel(record.id)}
                  >
                    {wMatrix('Cancel')}
                  </label>
                </span>
              ) : (
                <Icon type="edit" theme="twoTone" twoToneColor="#4482ff" onClick={() => this.edit(record.id)} />
              )}
            </div>
          )
        },
      },
      {
        title: wMatrix('lastName'),
        dataIndex: 'lastName',
        editable: true,
        sorter: (a, b) => {
          const nameA = a.lastName.toUpperCase()
          const nameB = b.lastName.toUpperCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        },
      },
      {
        title: wMatrix('firstName'),
        dataIndex: 'firstName',
        editable: true,
        sorter: (a, b) => {
          const nameA = a.firstName.toUpperCase()
          const nameB = b.firstName.toUpperCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        },
      },
      {
        title: wMatrix('dotNumber'),
        dataIndex: 'dotNumber',
        editable: true,
        width: 'auto',
      },
    ]

    // conditionally show Fob feature
    if (showFob) {
      devColumns.push({
        title: wMatrix('fobId'),
        dataIndex: 'fob',
        editable: true,
      })
    }

    if (showWexCard) {
      devColumns.push({
        title: wMatrix('wexCard'),
        dataIndex: 'wexCard',
        editable: true,
        sorter: (a, b) => {
          const first = parseInt(a.wexCard, 10)
          const second = parseInt(b.wexCard, 10)
          if (first < second) return -1
          if (first > second) return 1
          return 0
        },
        render: card => (
          <span style={{ whiteSpace: 'nowrap' }}>
            {card || wMatrix('unassigned')}
          </span>
        ),
      })
    }

    devColumns.push({
      title: wMatrix('assignToDevice'),
      dataIndex: 'device',
      width: '100',
      sorter: (a, b) => this.sortByDevice(a, b),
      editable: true,
      render: device => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {device ? device.alias : wMatrix('unassigned')}
        </span>
      ),
    })

    devColumns.push({
      title: ' ',
      dataIndex: 'delete',
      render: (text, record) => (
        <div>
          <span style={{ verticalAlign: 'middle' }}>
            <EditableContext.Consumer>
              {valueObj => (
                <Popconfirm
                  title={`${wMatrix('okToDelete')}?`}
                  okText={wMatrix('Delete')}
                  cancelText={wMatrix('Cancel')}
                  onConfirm={() => this.delete(valueObj.form, record.id)}
                >
                  <label style={{
                    marginLeft: 4, marginRight: 4, whiteSpace: 'nowrap', display: 'block', color: '#4482FF',
                  }}
                  >
                    <Icon
                      style={{
                        cursor: 'pointer',
                      }}
                      theme="twoTone"
                      twoToneColor="#f64744"
                      type="delete"
                    />
                  </label>
                </Popconfirm>
              )}
            </EditableContext.Consumer>
          </span>
        </div>
      ),
    })
    return devColumns
  }

  sortByDevice = (a, b) => {
    if (a.device === null) return 1
    if (b.device === null) return -1
    return a.device.alias - b.device.alias
  }

  // returns boolean based on which row is being edited
  isEditing = (record) => {
    const { editingKey } = this.state
    return record.id === editingKey
  }

  cancel = () => {
    this.setState({ editingKey: '' })
  }

  save(form, key) {
    const { updateDriver } = this.props
    // only edits one user at a time
    form.validateFields((error, row) => {
      if (error) {
        return
      }
      const { drivers } = this.props
      const newData = []
      // eslint-disable-next-line guard-for-in
      drivers.map(driver => newData.push(driver))
      const index = newData.findIndex(item => key === item.id)
      if (index > -1) {
        const item = newData[index]
        newData.splice(index, 1, {
          ...item,
          ...row,
        })
        this.setState({ editingKey: '' })
        updateDriver(newData[index])
      } else {
        newData.push(row)
        this.setState({ editingKey: '' })
      }
    })
  }

  delete(form, key) {
    const { deleteDriver } = this.props
    // the key is set to be the driver ID
    deleteDriver(key)
    this.setState({ editingKey: '' })
  }

  // gets/sets the row that is being edited by its row key
  edit(key) {
    this.setState({ editingKey: key })
  }

  render() {
    const {
      drivers, driversLoading,
      wMatrix, getPermission, availableDeviceOptions,
    } = this.props

    const {
      searchVal, editingKey,
    } = this.state

    const EditableRow = ({ form, index, ...props }) => (
      <EditableContext.Provider
        value={{
          form,
          wMatrix,
          getPermission,
          availableDeviceOptions,
        }}
      >
        <tr {...props} />
      </EditableContext.Provider>
    )

    const EditableFormRow = Form.create()(EditableRow)

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    }

    const filteredDrivers = drivers.filter((driver) => {
      const sv = searchVal.toLowerCase()
      return (
        driver.firstName.toLowerCase().indexOf(sv) !== -1
        || driver.lastName.toLowerCase().indexOf(sv) !== -1
        || (driver.dotNumber && driver.dotNumber.toLowerCase().indexOf(sv) !== -1)
        // fob number, not displayed in the table
        || (driver.fob && driver.fob.toLowerCase().indexOf(sv) !== -1)
        // device assigned
        || (driver.device && driver.device.alias.toLowerCase().indexOf(sv) !== -1)
      )
    })

    let devColumns = null
    devColumns = this.returnColumns()
    const columns = devColumns.map((col) => {
      const type = getInputType(col.dataIndex)
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: type, // logic for number usage: //'age' ? 'number' : 'text'
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      }
    })

    return (
      <div style={{ display: 'flex', marginTop: 50, flexDirection: 'column' }}>
        <InputSearch
          style={{ alignSelf: 'flex-end', marginBottom: '5px' }}
          className="groupPanelSearch"
          onChange={this.updateSearch}
          placeholder={wMatrix('nameDotDevice')}
        />
        <MemoTable
          editingKey={editingKey}
          loading={driversLoading}
          className="userTable listTableNoPagiArrow"
          components={components}
          dataSource={filteredDrivers}
          columns={columns}
          rowKey="id"
          scroll={editingKey !== '' ? { x: 1000 } : { x: false }}
          size="middle"
        />
      </div>
    )
  }
}
