import React from 'react'
import PropTypes from 'prop-types'
import ToggleCard from '@atom/toggleCard'
import { Button } from 'antd'
import equal from 'deep-equal'

export default class GlobalAlertSwitch extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    alertSettings: PropTypes.object,
    updateGeneralAlertSettings: PropTypes.func.isRequired,
    setIfShowAlert: PropTypes.func.isRequired,
    alertSettingsData: PropTypes.object,
    currentSelection: PropTypes.string.isRequired,
    notifications: PropTypes.object.isRequired,
  }

  static defaultProps = {
    alertSettings: null,
    alertSettingsData: null,
  }

  state = {
    ifSaving: false,
    emailToggle: false,
  }

  componentDidUpdate(prevProps) {
    const { alertSettings, currentSelection, notifications } = this.props
    const eventId = notifications[currentSelection].eventTypeIds[0]
    if (!equal(prevProps.alertSettings, alertSettings)) {
      if (alertSettings[eventId] && alertSettings[eventId].length > 0) {
        /**
         * The toggleVal in <ToggleCard/> is both value and defaultValue
         * I can't bind alertSettings in this.props to toggleVal because it will never change.
         * Thus the you can't toggle the value with the props, I have to use a local state.
         * I will just keep a local state here. --Jayli
         */
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ emailToggle: alertSettings[eventId][0].ynEmail })
      }
    }
  }

  toggleEmail = () => {
    const { emailToggle } = this.state
    this.setState({ emailToggle: !emailToggle })
  }

  handleSaveGlobalAlert = () => {
    const {
      updateGeneralAlertSettings, setIfShowAlert, alertSettingsData,
      currentSelection, notifications,
    } = this.props
    const { emailToggle } = this.state
    this.setState({ ifSaving: true })

    updateGeneralAlertSettings(
      [0],
      [notifications[currentSelection].eventTypeIds[0]],
      emailToggle,
      false,
      false,
      false,
      null,
      null,
      null,
      null,
    )
      .then((response) => {
        if (response) {
          alertSettingsData.refetch().then(() => {
            this.setState({ ifSaving: false })
            setIfShowAlert(true, 'success')
            setTimeout(() => {
              setIfShowAlert(false)
            }, 5000)
          })
        } else {
          this.setState({ ifSaving: false })
          setIfShowAlert(true, 'error')
          setTimeout(() => {
            setIfShowAlert(false)
          }, 5000)
        }
      })
  }

  render() {
    const { wMatrix } = this.props
    const { ifSaving, emailToggle } = this.state
    return (
      <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 530 }}>
        <div style={{ width: '80%' }}>
          <ToggleCard
            titleVal={wMatrix('email')}
            toggleVal={emailToggle}
            toggleClick={this.toggleEmail}
          />
        </div>
        <div style={{ alignSelf: 'flex-end', marginRight: '20%' }}>
          <Button
            type="primary"
            style={{ marginLeft: '12px' }}
            onClick={this.handleSaveGlobalAlert}
            disabled={ifSaving}
          >
            {ifSaving
              ? wMatrix('Saving')
              : wMatrix('Save')
            }
          </Button>
        </div>
      </div>
    )
  }
}
