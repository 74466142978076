import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'

const loading = keyframes`
100% {
  transform: translateX(100%);
}
`

const AnimatedGradient = styled.div`
  display: block;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, .4), transparent);
  animation: ${loading} 1.5s infinite;
`

const ContentWrapper = styled.div`
  position: relative;
  background-color: #E3E3E3;
  overflow: hidden;
`
/** This is a simple component that contains a div with loading animation. You can
 * give this component a @prop of `wrapperStyle` to customize the shape of the div.
 */
class Skeleton extends Component {
  static propTypes = {
    wrapperStyle: PropTypes.object,
  }

  static defaultProps = {
    wrapperStyle: {
      height: 20,
      width: 80,
      flexShrink: '0',
    },
  }

  render() {
    const { wrapperStyle } = this.props
    return (
      <ContentWrapper style={{ ...wrapperStyle }}>
        <AnimatedGradient>
          {' '}
        </AnimatedGradient>
      </ContentWrapper>
    )
  }
}

export default Skeleton
