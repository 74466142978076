import React from 'react'
import PropTypes from 'prop-types'
import { Input, Table } from 'antd'
import Tag from '@atom/tag'
import TagsDropDown from '@atom/tagsFilterDropDown'

const styles = {
  table: {
    marginRight: 20,
    position: 'relative',
    flexGrow: 1,
  },
  tableColumns: {
    width: '8vw',
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}

export default class DeviceListTable extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    devices: PropTypes.array.isRequired,
    tags: PropTypes.array.isRequired,
    rowSelection: PropTypes.object.isRequired,
  }

  state = {
    devices: [],
    aliasFilter: '',
  }

  componentDidMount() {
    const { devices } = this.props
    this.setState({ devices })
  }

  componentDidUpdate(prevProps) {
    const { devices } = this.props
    if (prevProps.devices.length !== devices.length) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        devices,
        aliasFilter: '',
      })
    }
  }

  renderAliasFilterInput = () => {
    const { aliasFilter } = this.state
    const { devices, wMatrix } = this.props
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Input
          style={{
            alignSelf: 'flex-end',
            width: 250,
            margin: '10px 20px 10px 0',
            flexShrink: 0,
          }}
          size="small"
          placeholder={wMatrix('searchDeviceAliasOrVIN')}
          value={aliasFilter}
          onChange={(e) => {
            if (!devices || devices.length < 1) return
            this.setState({ aliasFilter: e.target.value })
            const filteredDevices = devices.filter((d) => {
              const lowerValue = e.target.value.toLowerCase()
              const lowerAlias = d.alias.toLowerCase()
              const lowerVIN = d.vin.toLowerCase()
              return lowerAlias.includes(lowerValue) || lowerVIN.includes(lowerValue)
            })
            this.setState({ devices: filteredDevices })
          }}
        />
      </div>
    )
  }

  handleTagSelect = (filterValues) => {
    const { devices } = this.props
    if (filterValues.length === 0) {
      this.setState({ devices })
      return
    }
    let lowerTagNames = ''
    for (let i = 0; i < filterValues.length; i += 1) {
      lowerTagNames += `${filterValues[i].toLowerCase()}, `
    }
    const filteredDevices = devices.filter((d) => {
      let includes = false
      for (let i = 0; i < d.tags.length; i += 1) {
        if (lowerTagNames.includes(d.tags[i].name.toLowerCase())) {
          includes = true
        }
      }
      return includes
    })
    this.setState({ devices: filteredDevices })
  }

  renderTagsSelect = () => {
    const { wMatrix, tags } = this.props
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px 20px 10px 0px' }}>
        <TagsDropDown
          wMatrix={wMatrix}
          existingTagList={tags}
          handleFilterChange={this.handleTagSelect}
        />
      </div>
    )
  }

  renderColumns = () => {
    const { wMatrix } = this.props
    return [
      {
        title: wMatrix('alias'),
        dataIndex: 'alias',
        width: '33.33%',
        render: text => (
          <div style={styles.tableColumns} title={text}>
            {text}
          </div>
        ),
        sorter: (a, b) => {
          const nameA = a.alias.toUpperCase()
          const nameB = b.alias.toUpperCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        },
      },
      {
        title: 'VIN',
        dataIndex: 'vin',
        width: '33.33%',
        render: text => (
          <div style={styles.tableColumns} title={text}>
            {text}
          </div>
        ),
        sorter: (a, b) => {
          const nameA = a.vin.toUpperCase()
          const nameB = b.vin.toUpperCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        },
      },
      {
        title: wMatrix('groupsAndLabels'),
        dataIndex: 'tags',
        width: '33.33%',
        render: tags => (
          <div style={{ display: 'flex', flexWrap: 'wrap', width: '8vw' }}>
            {tags && tags.length > 0 ? tags.map(tag => (
              <div key={tag.id} style={{ display: 'flex' }} title={tag.name}>
                <Tag
                  id={tag.id}
                  name={tag.name}
                  color={tag.color}
                  personal={tag.personal}
                  size="small"
                />
              </div>
            )) : null}
          </div>
        ),
      },
    ]
  }

  render() {
    const { rowSelection } = this.props
    const { devices } = this.state
    const paddingTop = '450px'

    return (
      <>
        {this.renderAliasFilterInput()}
        {this.renderTagsSelect()}
        <div style={styles.table}>
          <Table
            className="serviceDeviceTable"
            columns={this.renderColumns()}
            rowKey="id"
            dataSource={devices}
            bordered
            size="small"
            scroll={{
              y: `calc(100vh - ${paddingTop})`,
            }}
            pagination={false}
            rowSelection={rowSelection}
          />
        </div>
      </>
    )
  }
}
