import PropTypes from 'prop-types'
import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import Marker from '@atom/marker'
import ClusterMarker from '@atom/clusterMarker'
import Supercluster from 'supercluster'
import equal from 'deep-equal'
import helper from '@helper'
import Analytics from '@analytics'


/**
 *
 * Procedure:
 * 1. Initialize the map and save its reference.
 * 2. When devices are given, make the map bounds fit all or most of the markers
 * 3. After bounds is updated, create clusters
 * 4. Rerender map, to show the correct markers
 * (steps 1 -> 3 happen almost instantaneously, that it will
 * seem like it automatically shows the correct makers)
 *
 * @link https://github.com/google-map-react/google-map-react/blob/master/API.md
 * @link https://developers.google.com/maps/documentation/javascript/reference/map
 *
 * @todo
 * the radius of supercluster is in pixels, might need to be changed to a
 * dynamic value based on map dimension
 */

const styles = {
  landmarkShape: {
    strokeColor: '#4482FF',
    strokeOpacity: 0.83,
    strokeWeight: 2,
    fillColor: '#4482FF',
    fillOpacity: 0.38,
  },
}

class ClusterMap extends Component {
  map = null

  maps = null

  landmarkShapes = {}

  static propTypes = {
    initMap: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired,
    devices: PropTypes.array,
    selectedDevice: PropTypes.object,
    selectDevice: PropTypes.func,
    fitBounds: PropTypes.func,
    center: PropTypes.object,
    zoom: PropTypes.number,
    mapIconMode: PropTypes.string,
    landmarks: PropTypes.array,
    findLandmarkCenter: PropTypes.func.isRequired,
    /** @Main */
    mainNav: PropTypes.string.isRequired,
    mapIconSizeMode: PropTypes.string,
  }

  static defaultProps = {
    center: {
      lat: 30.116936,
      lng: -97.129397,
    },
    zoom: 1,
    devices: [],
    selectedDevice: null,
    selectDevice: () => { },
    fitBounds: () => { },
    mapIconMode: 'comfortable',
    mapIconSizeMode: 'large',
    landmarks: null,
  }

  state = {
    clusters: [],
    supercluster: new Supercluster({
      radius: 150,
      maxZoom: 16,
    }),
    boundsChanged: false,
  }

  componentDidUpdate(prevProps) {
    const { devices, selectedDevice } = this.props

    /**
     * Whenever devices have loaded or a device has been selected, we need to update
     * the view and zoom to fit the marker(s)
     */
    if (!equal(prevProps, this.props)) {
      if ((devices.length > 0 && prevProps.devices.length === 0) || selectedDevice) {
        this.fitToCoordinates(((selectedDevice !== prevProps) && selectedDevice))
      } else if (this.map && this.maps) {
        /**
         * Markers on the map are created using a state value (clusters). This state value needs
         * to be updated whenever the devices have updated, and in turn refresh the map
         */
        const clusterBounds = this.clusterBoundsFromGoogleBounds()
        if (clusterBounds) {
          const currentZoom = this.map.getZoom()
          this.createClusters(clusterBounds, currentZoom)
        }
      }
    }

    this.handleBoundChange()
  }

  /**
   * @description Cleanly clears timer. Removes event listener for resizing
   */
  componentWillUnmount = () => {
    this.clearBoundsTimer()
  }

  /**
  * @private
  * @description handles map bounds change
  */
  handleBoundChange = () => {
    if (this.boundsTimer) {
      return
    }
    this.boundsTimer = setTimeout(() => {
      this.toggleLandmarkShapes()
      this.boundsTimer = 0

      this.setState({ boundsChanged: true })
    }, 1200)
  }

  /**
  * @private
  * @description Used to cleanly clear bounds timer
  */
  clearBoundsTimer = () => {
    // If timer is running, clear
    if (this.boundsTimer) {
      clearTimeout(this.boundsTimer)
      this.boundsTimer = 0
    }
  }

  /**
   * @public
   *
   * Handles when the cluster makers is clicked.
   * It zooms and expands that cluster
   */
  onClickClusterMarker = (clickedCluster) => {
    const { supercluster } = this.state
    const { fitBounds } = this.props
    const clusterPoints = supercluster.getLeaves(clickedCluster.id, Infinity)
    const bounds = new this.maps.LatLngBounds()

    for (const clusterPoint of clusterPoints) {
      const locatePos = clusterPoint.geometry.coordinates
      bounds.extend(new this.maps.LatLng(locatePos[1], locatePos[0]))
    }

    fitBounds(bounds)
  }

  /**
   * @private
   *
   * Creates the markers
   */
  markers = () => {
    const { clusters } = this.state
    const {
      selectDevice, mapIconMode, mapIconSizeMode, mainNav,
    } = this.props

    return (
      clusters.map((cluster) => {
        if (cluster.id) {
          return (
            <ClusterMarker
              lat={cluster.geometry.coordinates[1]}
              lng={cluster.geometry.coordinates[0]}
              count={cluster.properties.point_count}
              onClick={() => { this.onClickClusterMarker(cluster) }}
              key={`c${cluster.id}`}
            />
          )
        }

        return (
          <Marker
            view={mapIconMode}
            size={mapIconSizeMode}
            lat={cluster.geometry.coordinates[1]}
            lng={cluster.geometry.coordinates[0]}
            heading={cluster.properties.heading}
            alias={cluster.properties.alias}
            driver={cluster.properties.driver}
            state={cluster.properties.state}
            key={cluster.properties.id}
            icon={cluster.properties.icon}
            onClick={() => {
              const { devices } = this.props

              for (const device of devices) {
                if (device.id === cluster.properties.id) {
                  /** @analytics Record cluster map device click */
                  Analytics.record({
                    feature: 'map',
                    page: `${mainNav}`,
                    event: 'select_device',
                  })

                  selectDevice(device)
                  break
                }
              }
            }}
          />
        )
      })
    )
  }

  /**
   * @description Creates a Google shape based on the landmark's definition
   * @param {Object} landmark
   * @returns Google Circle, Polygon, or Rectangle object
   */
  getLandmarkShape = (landmark) => {
    let shape
    if (landmark.type === 'circle') {
      shape = new this.maps.Circle({
        ...styles.landmarkShape,
        map: this.map,
        center: landmark.center[0],
        radius: landmark.radius,
        editable: false,
        draggable: false,
        zIndex: 1,
      })
    } else if (landmark.type === 'polygon') {
      shape = new this.maps.Polygon({
        ...styles.landmarkShape,
        map: this.map,
        paths: landmark.points,
        editable: false,
        draggable: false,
        zIndex: 1,
      })
    } else {
      const south = landmark.points[0].lat
      const north = landmark.points[1].lat
      const east = landmark.points[2].lng
      const west = landmark.points[0].lng
      shape = new this.maps.Rectangle({
        ...styles.landmarkShape,
        map: this.map,
        bounds: {
          north,
          south,
          east,
          west,
        },
        editable: false,
        draggable: false,
        zIndex: 1,
      })
    }

    return shape
  }

  /**
   * @description Creates or changes a landmark's shape based on the zoom level
   */
  toggleLandmarkShapes = () => {
    const { landmarks, settings } = this.props

    // Check to see if landmark shape was already created
    if (this.map && landmarks) {
      for (let i = 0; i < landmarks.length; i += 1) {
        const landmark = landmarks[i]
        if (this.landmarkShapes[i] === undefined && settings.showLandmarks) {
          this.landmarkShapes[i] = this.getLandmarkShape(landmark)
        } else if (this.landmarkShapes[i] !== undefined) {
          if (this.map.zoom > 10 && settings.showLandmarks) {
            this.landmarkShapes[i].setMap(this.map)
          } else {
            this.landmarkShapes[i].setMap(null)
          }
        }
      }
    }
  }

  /**
   * @private
   * @description Creates landmark markers for map
   * @returns {[Marker]}  Returns array of marker components
   */
  landmarkMarkers = () => {
    const { landmarks, settings, findLandmarkCenter } = this.props
    const { boundsChanged } = this.state

    if (boundsChanged && settings.showLandmarks && landmarks && this.maps) {
      const landmarkMarkers = []
      for (let i = 0; i < landmarks.length; i += 1) {
        const landmark = landmarks[i]
        const position = findLandmarkCenter(landmark)
        if (this.map.getBounds().contains(position)) {
          landmarkMarkers.push(
            <Marker
              lat={position.lat}
              lng={position.lng}
              alias={landmark.name}
              key={landmark.id}
              type="landmark"
            />,
          )
        }
      }
      return landmarkMarkers
    }
    return null
  }

  /**
   * @private
   *
   * Fits the map to the devices or a single device
   *
   * @param {boolean} isSelectedDevice
   */
  fitToCoordinates = (isSelectedDevice) => {
    if (this.maps) {
      const bounds = new this.maps.LatLngBounds()
      const {
        devices, selectedDevice, fitBounds, center,
      } = this.props

      if (isSelectedDevice) {
        bounds.extend(new this.maps.LatLng(
          selectedDevice.locate.latitude, selectedDevice.locate.longitude,
        ))

        fitBounds(bounds, 16)
      } else if (devices.length > 0) {
        devices.forEach((device) => {
          if (device.locate) {
            bounds.extend(new this.maps.LatLng(device.locate.latitude, device.locate.longitude))
          }
        })

        fitBounds(bounds)
      } else {
        // Case when map first loads
        bounds.extend(new this.maps.LatLng(
          center.lat, center.lng,
        ))

        fitBounds(bounds, 4)
      }
    }
  }

  /**
   * @public
   *
   * Callback for our custom initialization
   */
  initMap = (map, maps) => {
    this.map = map
    this.maps = maps

    this.fitToCoordinates(false)
  }

  /**
   * @private
   *
   * Creates an array of GeoJSONs from devices.
   * Used when loading supercluster with device locates
   *
   * @typedef GeoJSON
   * @property {string} type
   * @property {Object} properties
   * @property {Object} geometry
   * @property {string} geometry.type
   * @property {[number, number]} geometry.coordinates --> [longitude, latitude]
   *
   *
   * @returns {[GeoJSON]} array
   */
  geoJSON = () => {
    const { devices } = this.props

    return devices.filter(device => device.locate !== null).map(device => ({
      type: 'Feature',
      properties: {
        id: device.id,
        point_count: 1,
        heading: device.locate.heading,
        alias: device.alias,
        driver: (device.currentDriver ? device.currentDriver.name : device.currentDriver),
        state: (device.status ? device.status.state : device.status),
        icon: device.icon,
      },
      geometry: {
        type: 'Point',
        coordinates: [device.locate.longitude, device.locate.latitude], // [longitude, latitude]
      },
    }))
  }

  /**
   * @private
   *
   * Provides the bounds of the cluster (in superCluster format)
   *
   * @param {object} bounds NOT Google Map Bounds
   */
  clusterBounds = (bounds) => {
    const westLng = bounds.sw.lng
    const southLat = bounds.sw.lat
    const eastLng = bounds.ne.lng
    const northLat = bounds.ne.lat

    return [westLng, southLat, eastLng, northLat]
  }

  /**
   * @private
   *
   * Provides the bounds in superCluster format using this.map's bounds
   * @returns {[numbers]} Array of bounds in form [westLng, southLat, eastLng, northLat]
   */
  clusterBoundsFromGoogleBounds = () => {
    if (this.map) {
      // gmaps always seem to change their object key names, so we'll grab it generically
      const gMapBounds = Object.values(this.map.getBounds())
      // 0 = Latitudes south, north
      // 1 = Longitudes west, east
      const latitudes = Object.values(gMapBounds[0])
      const longitudes = Object.values(gMapBounds[1])

      return [longitudes[0], latitudes[0], longitudes[1], latitudes[1]]
    }
    return null
  }

  /**
   * @private
   *
   * Creates the clusters and sets the cluster state
   *
   * @param {[number]} bounds NOT Google Map Bounds. Bounds need to be in superCluster form:
   *  [westLng, southLat, eastLng, northLat]. clusterBounds() and\
   *  clusterBoundsFromGoogleBounds() will format for you
   * @param {number} zoom current zoom level of map
   */
  createClusters = (bounds, zoom) => {
    const { devices, settings } = this.props
    const { supercluster } = this.state

    if (devices.length > 0) {
      supercluster.load(this.geoJSON())
      if (settings.clustering) {
        this.setState({ clusters: supercluster.getClusters(bounds, zoom + 1) })
      } else {
        // Faking the zoom level to 17 since the Supercluster's max zoom level is 16
        this.setState({ clusters: supercluster.getClusters(bounds, 17) })
      }
    }
  }

  /**
   * @public
   *
   * When the map changes the zoom either programmatically or user control,
   * we need to reprocess the clusters
   *
   * @param {object} view
   */
  boundsChange = ({ bounds, zoom }) => {
    const clusterBounds = this.clusterBounds(bounds)
    this.createClusters(clusterBounds, zoom)
    this.handleBoundChange()
  }

  /**
   * @private
   *
   * Customize the map on init
   */
  createMapOptions = maps => ({
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: maps.ControlPosition.LEFT_BOTTOM,
    },
    streetViewControl: true,
    streetViewControlOptions: {
      position: maps.ControlPosition.LEFT_BOTTOM,
    },
    zoomControlOptions: {
      position: maps.ControlPosition.LEFT_BOTTOM,
    },
  })

  render() {
    const { center, zoom, initMap } = this.props

    return (
      <GoogleMapReact
        bootstrapURLKeys={{
          client: 'gme-twmatters',
        }}
        onGoogleApiLoaded={({ map, maps }) => initMap(map, maps, this.initMap)}
        onChange={this.boundsChange}
        defaultCenter={center}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        options={this.createMapOptions}
      >
        {this.markers()}
        {this.landmarkMarkers()}
      </GoogleMapReact>
    )
  }
}

export default helper()(ClusterMap)
