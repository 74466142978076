import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TabBar from '@mol/tabBar'
import {
  Icon,
  Tabs,
} from 'antd'

const { TabPane } = Tabs

const styles = {
  animation: {
    transition: 'all 0.5s ease-out',
  },
  wrapper: {
    position: 'relative',
    height: '100%',
    width: '100%',
    zIndex: 2,
    top: '-100%',
    pointerEvents: 'none',
  },
  container: {
    backgroundColor: '#FFFFFF',
    height: '95%',
    position: 'absolute',
    top: '2%',
    right: '0px',
    borderRadius: '3px 0px 0px 3px',
    boxShadow: ' -4px 1px 8px',
    display: 'flex',
    flexDirection: 'column',
    pointerEvents: 'auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '45px',
    flexShrink: 0,
  },
}

/**
 * @description This is a container that can Open and Minimize from the right side of its
 * containing parent. This component is placed 'relative' to its parent and must be placed *AFTER*
 * its sibling compnent. All instances of this container should be surrounded by
 * a div (no style override) and that div must only contain one other component.
 *
 * @prop { bool } show - (Required) boolean to show the container
 * @prop { array } tabs - (Required) array of objects giving tab names (String) and ids(String).
 * example below.
 * @prop { func } getExpansion - (Optional) callback function to get expansion state (bool). *NOTE*
 * This callback is only called on the press of the expansion button.
 * @prop { object } wrapperOverride - (Optional) This is a last resort and should not be used.
 * Please talk to Gus first if you are having issues. Wrapper style override.
 * (note. Cannot override {position: 'relative'})
 * @prop { object } selectTab - (Optional) callback/overwrite for selectTab function
 *
 * @examples
 *  - *tabs* tabs = [{ name: 'Trips', id: '1' },{ name: 'All Locates', id: '2' }]
 *  - *getExpansion* getExpansion = (expanded) => {return expanded}
 * @usage -
 * <RightDrawer
 *  show={show}
 *  tabs={[
 *    { name: 'Trips', id: '1' },
 *    { name: 'All Locates', id: '2' },
 *  ]}
 *  expandGet={this.expandGet}
 * >
 *
 * </RightDrawer>
 */

export default class RightDrawer extends Component {
  state = {
    width: '325px',
    expanded: true,
    activeKey: '1',
  }

  static propTypes = {
    children: PropTypes.any,
    show: PropTypes.bool,
    tabs: PropTypes.array.isRequired,
    wrapperOverride: PropTypes.object,
    getExpansion: PropTypes.func, /** this is a function passed from
    parent to read the current expand state */
    selectTab: PropTypes.func,
  }

  static defaultProps = {
    children: <TabPane tab="demo" key="1">Contents</TabPane>,
    show: true,
    getExpansion: null,
    wrapperOverride: null,
    selectTab: null,
  }

  selectTab = (id, name) => {
    // uncomment to enable selectTab overwrite by parent
    const { selectTab } = this.props
    this.setState({ activeKey: id })
    if (selectTab) {
      selectTab(id, name)
    }
  }

  expansionToggle() {
    const { expanded } = this.state
    const { getExpansion } = this.props
    // address optional callback
    if (getExpansion) {
      getExpansion(!expanded)
    }
    if (expanded) {
      // set closed
      this.setState({ width: '55px', expanded: false })
    } else {
      // set open
      this.setState({ width: '325px', expanded: true })
    }
  }

  /**
   * @description Displays all children if no keys. Displays selected child if keys
   */
  renderChild = () => {
    const { activeKey } = this.state
    const { children } = this.props
    // if no key exists, display all
    if (Array.isArray(children)) {
      let child = null
      for (child in children) {
        if (children[child].key === activeKey) {
          return children[child]
        }
      }
    }
    return children
  }

  /**
   * Renders the Tab bar with expansion button
   * @returns Element
   */
  renderHeader = () => {
    const { activeKey, expanded } = this.state
    const {
      tabs,
    } = this.props

    return (
      <div style={styles.header}>
        <Icon
          type={expanded ? 'right' : 'left'}
          style={{
            fontSize: '20px',
            marginLeft: '15px',
            // margin: '10px 0px 0px 15px',
            flexGrow: 0,
            alignSelf: 'center',
          }}
          onClick={() => this.expansionToggle()}
        />
        {expanded ? (
          <div style={{
            flexGrow: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 'auto',
          }}
          >
            <TabBar
              tabs={tabs}
              onSelect={this.selectTab}
              activeKey={activeKey}
            />
          </div>

        ) : null}
      </div>
    )
  }

  render() {
    const { width } = this.state
    const { show, wrapperOverride } = this.props
    return (
      show ? (
        <div className="drawerWrapper" style={wrapperOverride ? { ...wrapperOverride, position: 'relative' } : styles.wrapper}>
          <div style={{
            ...styles.animation, width, ...styles.container,
          }}
          >
            {this.renderHeader()}
            {this.renderChild()}
          </div>
        </div>
      ) : null
    )
  }
}
