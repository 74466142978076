import React, { Component } from 'react'
import { Select, Divider, Button } from 'antd'
import PropTypes from 'prop-types'
import helper from '@helper'
import Tag from './tag'

const { Option } = Select
const ButtonGroup = Button.Group

const styles = {
  buttonGroup: {
    marginTop: '5px',
    marginBottom: '5px',
  },
  menuButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

/**
 * If you would like for the select all and clear all to work,
 * this component will need form props to reference a form
 */
class CustomSelect extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    defaultSelectAll: PropTypes.bool,
    onChange: PropTypes.func,
    filterOption: PropTypes.func,
    form: PropTypes.object,
    wMatrix: PropTypes.func.isRequired,
  }

  static defaultProps = {
    defaultSelectAll: true,
    onChange: () => { },
    filterOption: () => { },
    form: null,
  }

  componentDidMount = () => {
    const { defaultSelectAll } = this.props
    if (defaultSelectAll) this.handleSelectAll()
  }

  /**
   * @private
   * @function
   *
   * Creates an array of ids from the data array
   *
   * @returns {[Number]} ids
   */
  ids = () => {
    const { data } = this.props

    const ids = []

    for (const object of data) {
      ids.push(object.id)
    }

    return ids
  }

  /**
   * @private
   * @function
   */
  handleSelectAll = () => {
    const { type, form } = this.props

    if (form) {
      const field = {}

      field[type] = this.ids()
      form.setFieldsValue(field)
    }
  }

  /**
   * @private
   * @function
   */
  handleClearAll = () => {
    const { type, form } = this.props

    const field = {}

    field[type] = []

    form.setFieldsValue(field)
  }

  /**
   * @private
   * @component
   *
   * @returns {Component}
   */
  configureOptions = () => {
    const { type, data, wMatrix } = this.props

    if (type === 'deviceIds') {
      return data.map(device => (
        <Option key={device.id} value={device.id}>
          {device.alias}
        </Option>
      ))
    } if (type === 'landmarkIds') {
      return data.map(landmark => (
        <Option key={landmark.id} value={landmark.id}>
          {landmark.name}
        </Option>
      ))
    } if (type === 'driverIds') {
      return data.map(driver => (
        <Option key={driver.id} value={driver.id}>
          {driver.name}
        </Option>
      ))
    } if (type === 'groupIds') {
      return data.map(group => (
        <Option key={group.id} value={group.id}>
          <Tag
            id={group.id}
            color={group.color}
            name={group.name}
          />
          {/* {group.name} */}
        </Option>
      ))
    } if (type === 'labelIds') {
      return data.map(label => (
        <Option key={label.id} value={label.id}>
          <Tag
            personal
            color={label.color}
            name={label.name}
            id={label.id}
          />
        </Option>
      ))
    } if (type === 'events') {
      return data.map(event => (
        <Option key={event.id} value={event.id}>
          {wMatrix(event.displayName)}
        </Option>
      ))
    }
    // if no custom key return default option format
    if (data && data.length && data.length > 0) {
      return data.map((item, index) => (
        <Option key={item.id} value={item.id}>
          {item.name ? item.name : `Item ${index}`}
        </Option>
      ))
    }
    // no data ? return empty list
    return []
  }

  /**
   * @private
   * @component
   *
   * @returns {Component}
   */
  customMenu = (options) => {
    const { wMatrix } = this.props

    return (
      <div>
        <div style={styles.menuButtonWrapper}>
          <ButtonGroup style={styles.buttonGroup}>
            <Button onClick={this.handleSelectAll} onMouseDown={e => e.preventDefault()} size="small">{wMatrix('selectAll')}</Button>
            <Button onClick={this.handleClearAll} onMouseDown={e => e.preventDefault()} size="small">{wMatrix('clearAll')}</Button>
          </ButtonGroup>
        </div>
        <Divider style={{ margin: '4px 0' }} />
        {options}
      </div>
    )
  }

  renderAllTags = (omittedValues, type, maxTagCount) => {
    const { wMatrix } = this.props
    if (type === 'driverIds' && maxTagCount === 0) {
      return wMatrix('allDrivers')
    }
    if (type === 'landmarkIds' && maxTagCount === 0) {
      return wMatrix('allLandmarks')
    }

    return `+${omittedValues.length} ${wMatrix('more')}`
  }

  getMaxTagCount = () => {
    const { type, form } = this.props

    if (form
      && form.getFieldValue(type)
      && form.getFieldValue(type).length === this.configureOptions().length
    ) {
      if (type === 'driverIds' || type === 'landmarkIds') {
        return 0
      }
    }
    return 1
  }

  render() {
    const {
      type, onChange, filterOption, form,
    } = this.props
    const maxTagCount = this.getMaxTagCount()
    return (
      <Select
        style={{ width: '100%' }}
        mode="multiple"
        onChange={e => onChange(e, type)}
        maxTagCount={maxTagCount}
        maxTagTextLength={10}
        maxTagPlaceholder={omittedVals => this.renderAllTags(omittedVals, type, maxTagCount)}
        dropdownRender={this.customMenu}
        value={form ? form.getFieldValue(type) : []}
        filterOption={(inputValue, option) => filterOption(inputValue, option, type)}
      >
        {this.configureOptions()}
      </Select>
    )
  }
}

export default helper()(CustomSelect)
