import React from 'react'
import PropTypes from 'prop-types'
import { Breadcrumb, Progress } from 'antd'
import Card from '@atom/card'

const styles = {
  guageWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    maxWidth: '915px',
  },
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 0 20px 0',
  },
  cardColumn: {
    flex: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 5,
  },
  legendRow: {
    display: 'flex',
    minWidth: '200px',
  },
  guageLegendContainer: {
    flex: 1,
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    margin: '0 0 0 50px',
  },
  dot: {
    height: '10px',
    width: '10px',
    flexShrink: 0,
    borderRadius: '50%',
    margin: '0 5px 0 0',
  },
  li: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  postTitle: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  dashboardWrapper: {
    flexShrink: 0,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    height: '100%',
  },
  topHalfWrapper: {
    minHeight: '50%',
    flexShrink: 0,
  },
  botHalfWrapper: {
    minHeight: '50%',
    marginTop: -5,
    flexShrink: 0,
    display: 'flex',
    flexGrow: 1,
  },
  botCard: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
  },
  chartCardContentWrapper: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    margin: '0px 8px 8px',
  },
  pieChart: {
    alias: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      flexShrink: 1,
      marginRight: 5,
    },
    data: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-between',
      whiteSpace: 'nowrap',
      width: '80%',
    },
    legendColor: {
      height: '20px',
      width: '10%',
      margin: 8,
      flexShrink: 0,
    },
  },
}

const colors = ['#4482FF', '#FF5500', '#FFBA44', '#87D068']

export default class LogisticsDashboard extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    appHeight: PropTypes.number.isRequired,
    statusData: PropTypes.object.isRequired,
  }

  renderLegendRow = (status, data) => {
    const { wMatrix } = this.props

    return (
      <div style={{ display: 'flex' }}>
        <h3 style={{ margin: 0, width: '100px' }}>{`${wMatrix(status)}: `}</h3>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h3 style={{ margin: 0 }}>
            {data.jobs === 1
              ? `${data.jobs} ${wMatrix('Job')}`
              : `${data.jobs} ${wMatrix('Jobs')}`
            }
          </h3>
          <h5 style={{ margin: 0 }}>
            {data.packages === 1
              ? `${data.packages} ${wMatrix('package')}`
              : `${data.packages} ${wMatrix('packages')}`
            }
          </h5>
          <h5 style={{ margin: 0 }}>
            {data.workOrders === 1
              ? `${data.workOrders} ${wMatrix('workOrder')}`
              : `${data.workOrders} ${wMatrix('workOrders')}`
            }
          </h5>
        </div>
      </div>
    )
  }

  /**
  * @description renders dashboard status guages
  */
  renderGauges = (availableHeight) => {
    const { wMatrix, statusData } = this.props
    const activeTotal = statusData.active.jobs + statusData.overdue.jobs
    const completeTotal = activeTotal + statusData.completed.jobs

    return (
      <Card
        contentWrapper={{
          flexGrow: 1,
          flexShrink: 0,
          minHeight: availableHeight,
          display: 'flex',
          flexDirection: 'column',
          margin: '0px 8px 8px 8px',
        }}
      >
        <Breadcrumb>
          <Breadcrumb.Item>{wMatrix('field-service')}</Breadcrumb.Item>
          <Breadcrumb.Item>{wMatrix('dashboard')}</Breadcrumb.Item>
        </Breadcrumb>
        <div style={{
          display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center',
        }}
        >
          <div style={styles.guageWrapper}>
            <div style={styles.cardColumn}>
              <div style={{ height: '25px' }} />
              <Progress
                type="dashboard"
                percent={Math.floor((statusData.overdue.jobs / activeTotal) * 100)}
                strokeColor={colors[1]}
                strokeWidth={6}
                width={150}
                gapDegree={90}
                format={() => (
                  <div style={{ fontSize: '25px' }}>
                    {statusData.overdue.jobs === 1
                      ? `${statusData.overdue.jobs} ${wMatrix('Job')}`
                      : `${statusData.overdue.jobs} ${wMatrix('Jobs')}`
                    }
                  </div>
                )}
              />
              <h2>{wMatrix('Overdue')}</h2>
            </div>
            <div style={styles.cardColumn}>
              <Progress
                type="dashboard"
                percent={Math.floor((statusData.active.jobs / activeTotal) * 100)}
                strokeColor={colors[0]}
                strokeWidth={6}
                width={150}
                gapDegree={90}
                format={() => (
                  <div style={{ fontSize: '25px' }}>
                    {statusData.active.jobs === 1
                      ? `${statusData.active.jobs} ${wMatrix('Job')}`
                      : `${statusData.active.jobs} ${wMatrix('Jobs')}`
                    }
                  </div>
                )}
              />
              <h2>{wMatrix('Active')}</h2>
            </div>
            <div style={styles.cardColumn}>
              <div style={{ height: '25px' }} />
              <Progress
                type="dashboard"
                percent={Math.floor((statusData.completed.jobs / completeTotal) * 100)}
                strokeColor={colors[3]}
                strokeWidth={6}
                width={150}
                gapDegree={90}
                format={() => (
                  <div style={{ fontSize: '25px' }}>
                    {statusData.completed.jobs === 1
                      ? `${statusData.completed.jobs} ${wMatrix('Job')}`
                      : `${statusData.completed.jobs} ${wMatrix('Jobs')}`
                    }
                  </div>
                )}
              />
              <h2>{wMatrix('Completed')}</h2>
            </div>
            <div style={styles.guageLegendContainer}>
              <div style={styles.cardRow}>
                <div style={styles.legendRow}>
                  <div style={{ ...styles.dot, backgroundColor: colors[3], marginTop: '7px' }} />
                  {this.renderLegendRow('Completed', statusData.completed)}
                </div>
              </div>
              <div style={styles.cardRow}>
                <div style={styles.legendRow}>
                  <div style={{ ...styles.dot, backgroundColor: colors[0], marginTop: '7px' }} />
                  {this.renderLegendRow('Active', statusData.active)}
                </div>
              </div>
              <div style={styles.cardRow}>
                <div style={styles.legendRow}>
                  <div style={{ ...styles.dot, backgroundColor: colors[2], marginTop: '7px' }} />
                  <h3 style={{ margin: 0 }}>{`${wMatrix('nextDue')}: ${statusData.nextDue || ''}`}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    )
  }

  renderBotHalf = () => {
    const { wMatrix } = this.props
    return (
      <Card wMatrix={wMatrix} />
    )
  }

  render() {
    const { appHeight } = this.props
    // 106 above grey container
    // 34 below grey container
    const halfAvailableHeight = ((appHeight - 106 - 34) / 2)
    // take out card padding and use 80% of result for content
    const availableCardHeight = (halfAvailableHeight - 5 - 2.5) * 0.8
    return (
      <div style={styles.dashboardWrapper}>
        <div style={styles.topHalfWrapper}>
          {this.renderGauges(availableCardHeight)}
        </div>
        <div style={styles.botHalfWrapper}>
          {this.renderBotHalf()}
        </div>
      </div>
    )
  }
}
