import React from 'react'
import PropTypes from 'prop-types'
import {
  Form, Input, InputNumber, Checkbox, DatePicker, Select,
} from 'antd'
import moment from 'moment'
import { EditableContext } from '@mol/logistics/editableTaskTable'

const dateFormat = 'YYYY-MM-DD h:mm a'

export default class EditableTaskCell extends React.Component {
  static propTypes = {
    inputType: PropTypes.string,
    editing: PropTypes.bool,
    dataIndex: PropTypes.string,
    title: PropTypes.string,
    record: PropTypes.object,
    children: PropTypes.array,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    validator: PropTypes.object,
  }

  static defaultProps = {
    inputType: 'text',
    editing: false,
    dataIndex: null,
    title: null,
    record: null,
    children: [],
    placeholder: null,
    required: false,
    validator: null,
  }

  getInitialValue = (record, dataIndex) => {
    switch (dataIndex) {
      case 'completed':
        if (record.completionDate) return moment(record.completionDate).format(dateFormat)
        return false
      default:
        return record[dataIndex]
    }
  }

  // @TODO add placeholders for inputs based on column (ie. "task alias", "notes", etc)
  getInput = (inputType, record) => {
    const { placeholder } = this.props
    switch (inputType) {
      case 'number':
        return <InputNumber />

      case 'checkbox':
        if (!record.completionDate) return <Checkbox />
        return <Input disabled style={{ width: '120px' }} />

      case 'date':
        if (record.completionDate) {
          return <Input disabled style={{ width: '120px' }} />
        }
        return (
          <DatePicker
            style={{ width: '50%' }}
            allowClear
            format={dateFormat}
            showTime={{ format: 'h:mm a' }}
            size="default"
          />
        )

      case 'select':
        return (
          <Select style={{ width: 105 }}>
            <Select.Option value="package">Package</Select.Option>
            <Select.Option value="work_order">Work Order</Select.Option>
          </Select>
        )

      default:
        return <Input placeholder={placeholder || undefined} />
    }
  }

  getValuePropName = (record, index) => {
    if (index === 'completed' && !record.completionDate) {
      return 'checked'
    }
    return 'value'
  }

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing, dataIndex, title, inputType, record, children, required, validator, ...restProps
    } = this.props
    const divStyle = { display: 'flex' }
    if ((dataIndex === 'completed' && !record.completionDate) || !dataIndex) {
      divStyle.justifyContent = 'center'
    }

    const rules = [{
      // passed from column props in editableTaskTable
      required,
      message: 'Required',
    }]
    if (validator) {
      rules.push(validator)
    }

    return (
      <td {...restProps}>
        <div style={divStyle}>
          {editing ? (
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator(dataIndex, {
                rules,
                validateTrigger: 'onChange',
                initialValue: this.getInitialValue(record, dataIndex),
                valuePropName: this.getValuePropName(record, dataIndex),
              })(this.getInput(inputType, record, dataIndex))}
            </Form.Item>
          ) : (
            children
          )}
        </div>
      </td>
    )
  }

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
  }
}
