import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ModalHeader from '@mol/modalHeader'
import DeviceIcon from '@atom/deviceIcon'
import { CirclePicker } from 'react-color'
import { Input, Button, Alert } from 'antd'
import helper from '@helper'

/**
 * The following is a reference of the colors being used (this is stored and grabbed from the DB)
 * const COLORS = {
 *   '#DE3026': 'red',
 *   '#FF6430': 'tomato',
 *   '#FF9830': 'orange',
 *   '#F5CE1D': 'yellow',
 *   '#21CACA': 'turquoise',
 *   '#16B921': 'green',
 *   '#0F58D4': 'blue',
 *   '#2122CA': 'navy',
 *   '#173F5F': 'navy',
 *   '#8B39C5': 'purple',
 *   '#501B`75`': 'indigo',
 *   '#E592FF': 'pink',
 *   '#FF979D': 'salmon',
 *   '#808080': 'gray',
 *   '#404040': 'black',
 *   '#F0F0F0': 'white',
 * }
 */

const styles = {
  wrapper: {
    marginLeft: 50,
    marginRight: 50,
    display: 'flex',
    flexDirection: 'column',
  },
  iconItem: {
    width: '16.6%', // 8 per row
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    border: '1px solid #e9e9e9',
    borderRadius: 4,
    padding: 5,
  },
}

// ALERT Message Enum
const ALERT = {
  SUCCESS: {
    display: 'block',
    message: 'iconUpdated',
    type: 'success',
  },
  ERROR: {
    display: 'block',
    message: 'somethingWentWrong',
    type: 'error',
  },
  NONE: {
    display: 'none',
    message: null,
    type: '',
  },
}

class IconMgrPanel extends Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    updateIcon: PropTypes.func.isRequired,
    selectedDevice: PropTypes.object.isRequired,
    iconTypes: PropTypes.array.isRequired,
    iconColors: PropTypes.array.isRequired,
  }

  /**
   * @private
   * @param {Object} props
   * @description We use a constructor here to default the necessary icon attributes in state
   */
  constructor(props) {
    super(props)
    const { selectedDevice } = this.props
    this.state = {
      color: selectedDevice.icon.color, // this will be updated to have the vehicle icons color
      selectedType: selectedDevice.icon.type,
      label: selectedDevice.icon.text || '',
      alertState: ALERT.NONE,
      saveLoading: false,
    }
  }

  /**
   * @description used to cleanly clear timer if user closes before timer is finished
   */
  componentWillUnmount = () => {
    this.clearAlertTimer()
  }

  /**
   * @private
   * @description sets alert timer for close. this.alertTimer is also cleared on unmount
   */
  setAlertTimer = () => {
    if (this.alertTimer) {
      return
    }
    this.alertTimer = setTimeout(() => {
      this.setState({ alertState: ALERT.NONE })
      this.alertTimer = 0
    }, 2200)
  }

  /**
   * @private
   * @description Used to cleanly clear alert timer
   */
  clearAlertTimer = () => {
    // If timer is running, clear
    if (this.alertTimer) {
      clearTimeout(this.alertTimer)
      this.alertTimer = 0
    }
  }

  /**
   * @private
   * @description Handles onChange of label input
   */
  onLabelChange = (e) => {
    const { value } = e.target
    this.setState({ label: value })
  }

  /**
   * @private
   * @description Renders a formatted section of icon manager
   */
  renderSection = (label, content) => (
    <div>
      <div>{label}</div>
      <div style={{ margin: 10 }}>
        {content}
      </div>
    </div>
  )

  /**
   * @private
   * @description Renders the clickable icon with border if selected
   */
  renderSelectableIcon = (type, label, color) => {
    const { selectedType } = this.state
    return (
      <div
        key={`${type.name}_${color}`}
        style={styles.iconItem}
        onClick={() => this.setState({ selectedType: type.name })}
        role="button"
        tabIndex="-1"
      >
        <div style={{
          width: 90,
          height: 90,
          padding: 4,
          border: type.name === selectedType ? '2px solid #808080' : undefined,
          borderRadius: type.name === selectedType ? '4px' : undefined,
        }}
        >
          <DeviceIcon
            icon={{
              type: type.name,
              color,
              text: label.length > 0 ? label : null,
            }}
            inIconMgr
          />
        </div>
      </div>
    )
  }

  /**
   * @private
   * @description Renders the icon options container with contents
   */
  renderIconOptions = () => {
    const { color, label } = this.state
    const { iconTypes } = this.props
    const typeClones = JSON.parse(JSON.stringify(iconTypes))
    return (
      <div style={styles.iconsContainer}>
        {typeClones.map(type => (
          this.renderSelectableIcon(type, label, color)
        ))}
      </div>
    )
  }

  /**
   * @private
   * @description Used to get color name from hex value (from color picker)
   */
  returnColorNameFromHex = (hex) => {
    const { iconColors } = this.props
    const colorsClone = JSON.parse(JSON.stringify(iconColors))
    for (let i = 0; i < colorsClone.length; i += 1) {
      if (colorsClone[i].hex.toLowerCase() === hex) {
        return colorsClone[i].name
      }
    }
    return null
  }

  /**
   * @private
   * @description handles onclick for color picker
   */
  onColorChange = (color) => {
    const colorName = this.returnColorNameFromHex(color.hex)
    this.setState({ color: colorName })
  }

  /**
   * @private
   * @description update icon. This checks if it needs to send a label or null and also
   *  sets loading state.
   */
  updateIcon = async () => {
    const { selectedType, color, label } = this.state
    const { updateIcon, selectedDevice } = this.props
    let correctedLabel = label
    if (!label || label.length === 0 || !['circle', 'horizontalRectangle', 'verticalRectangle'].includes(selectedType)) {
      correctedLabel = null
    }
    this.setState({ saveLoading: true })
    try {
      const res = await updateIcon(selectedDevice.id, selectedType, color, correctedLabel)
      if (res) {
        this.setState({ alertState: ALERT.SUCCESS })
      } else {
        this.setState({ alertState: ALERT.ERROR })
      }
    } catch (err) {
      this.setState({ alertState: ALERT.ERROR })
    }
    this.setState({ saveLoading: false })
    this.setAlertTimer()
  }

  render() {
    const { alertState, saveLoading, label } = this.state
    const { wMatrix, iconColors } = this.props
    const colorsClone = JSON.parse(JSON.stringify(iconColors))

    return (
      <div style={styles.wrapper}>
        <ModalHeader
          headerName={wMatrix('iconManager')}
          description=""
        />
        {this.renderSection(wMatrix('color'),
          <CirclePicker
            colors={colorsClone.map(clr => clr.hex)}
            circleSpacing={10}
            onChange={this.onColorChange}
            width={575}
            height={12}
          />)}
        {this.renderSection(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {wMatrix('label')}
            <div style={{ fontSize: 12, marginLeft: 10 }}>
              {`(${wMatrix('iconTextLimit')})`}
            </div>
          </div>,
          <Input
            maxLength={4}
            placeholder="e.g. X123"
            style={{ maxWidth: 100 }}
            value={label}
            onChange={this.onLabelChange}
          />,
        )}
        {this.renderSection(wMatrix('icon'), this.renderIconOptions())}
        <div style={{ alignSelf: 'flex-end', display: 'flex', justifyContent: 'flex-end' }}>
          <Alert
            style={{
              display: alertState.display,
              width: '275px',
              margin: '0px 10px',
            }}
            closable
            showIcon
            message={alertState.message ? wMatrix(alertState.message) : ''}
            type={alertState.type}
          />
          <Button
            type="primary"
            loading={saveLoading}
            onClick={this.updateIcon}
          >
            {wMatrix('Save')}
          </Button>
        </div>
      </div>
    )
  }
}

export default helper()(IconMgrPanel)
