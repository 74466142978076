import React, { Component } from 'react'
import TabItem from '@page/main/atoms/tabItem'
import PropTypes from 'prop-types'

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  indicator: {
    position: 'absolute',
    height: 3,
    left: 0,
    bottom: 0,
    backgroundColor: '#4482FF',
    transition: 'transform .5s',
  },
}

export default class TabBar extends Component {
  tabNames = {}

  /** @NOTE examples commented below */
  static propTypes = {
    tabs: PropTypes.array.isRequired, // this is an array of objects {name: String, id: String}
    onSelect: PropTypes.func.isRequired, // This is function that takes id as argument
    activeKey: PropTypes.string.isRequired, // this is the active key (id from above)
    /**
     * Use this if you want to declare a width. If not, it will just grow to the tab bar item text.
     * @note this helps the indicator be more accurate
     */
    tabWidth: PropTypes.number,
  }

  static defaultProps = {
    // tabs: [{ name: 'one', id: '1' }, { name: 'two', id: '2' }],
    // onSelect: {function onSelect (id) => { this.setState({activeKey: id})}},
    // activeKey: '1',
    tabWidth: null,
  }

  // This allows the tabBar to only parse the tabs once and return tab names onSelect callback
  constructor(props) {
    super(props)
    const { tabs } = props
    for (let i = 0; i < tabs.length; i += 1) {
      const { id, name } = tabs[i]
      this.tabNames[id] = name
    }
  }

  onSelect = (id) => {
    const { onSelect } = this.props
    onSelect(id, this.tabNames[id])
  }

  renderTabs = () => {
    const { tabs, activeKey, tabWidth } = this.props
    return tabs.map(tab => (
      <TabItem
        value={tab.name}
        key={tab.id}
        id={tab.id}
        selectedKey={activeKey}
        onSelect={this.onSelect}
        width={tabWidth}
      />
    ))
  }

  render() {
    const { tabs, activeKey, tabWidth } = this.props

    // i is used to determinte the position for the indicator
    // ie. if i = 0, the indicator is at the first tab, so left: 0%
    // ie. if i = 2, the indicator is at the third tab, so left: 300%
    let i = 0
    for (const tab of tabs) {
      if (tab.id === activeKey) break
      i += 1
    }

    return (
      <div style={styles.container}>
        {this.renderTabs()}
        <div style={{
          ...styles.indicator,
          width: tabWidth || `${(100 / tabs.length)}%`,
          transform: `translateX(${100 * i}%)`,
        }}
        />
      </div>
    )
  }
}
