import React, { Component } from 'react'
import PropTypes from 'prop-types'
import rhinoIcon from '@assets/img/icons/rhinoIcon.svg'
import { Icon } from 'antd'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderColor: '#E9E9E9',
    borderWidth: 1,
    borderStyle: 'solid',
    padding: '5px 0 5px 10px',
  },
}

class DirectionStep extends Component {
  static propTypes = {
    step: PropTypes.object,
    stepNumber: PropTypes.number,
  }

  static defaultProps = {
    step: null,
    stepNumber: 0,
  }

  renderDirectionStepIcon = () => {
    const { step } = this.props
    // console.log(step.maneuver)
    switch (step.maneuver) {
      case '':
        return <img src={rhinoIcon} alt="icon" style={styles.icon} />
      default:
        return <Icon type="question" />
    }
  }

  renderDiretcionStepInstruction = () => {
    const { step, stepNumber } = this.props
    return (
      <div>
        {`${stepNumber}. `}
        { // eslint-disable-next-line react/no-danger
          <span dangerouslySetInnerHTML={{ __html: step.instructions }} />
        }
      </div>
    )
  }

  render() {
    return (
      <div style={styles.container}>
        {
          /**
           * @todo: find icons for turn-right/turn-left/head-straight
           * this.renderDirectionStepIcon()
           */
        }
        {this.renderDiretcionStepInstruction()}
      </div>
    )
  }
}

export default DirectionStep
