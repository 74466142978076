/* hold the token in another place than localStorage/sessionStorage
   this can help to compare if the token has been changed manually. If it is changed, then we go to login page.
   More info check src/apollo/index.js, the function authLink
*/

class TokenValidator {
  consturctor() {
    this.tokenHolder = ''
  }

  updateTokenHolder = (newToken) => {
    this.tokenHolder = newToken
  }

}

export default (new TokenValidator())