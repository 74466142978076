import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import PropTypes from 'prop-types'
import equal from 'deep-equal'
import Marker from '@atom/marker'

const styles = {
  landmarkShape: {
    strokeColor: '#4482FF',
    strokeOpacity: 0.83,
    strokeWeight: 2,
    fillColor: '#4482FF',
    fillOpacity: 0.38,
  },
}

export default class DeviceMap extends Component {
  map = null

  maps = null

  landmarkShapes = {}

  static propTypes = {
    initMap: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired,
    device: PropTypes.object,
    fitBounds: PropTypes.func,
    center: PropTypes.object,
    zoom: PropTypes.number,
    mapIconMode: PropTypes.string,
    mapIconSizeMode: PropTypes.string,
    landmarks: PropTypes.array,
    findLandmarkCenter: PropTypes.func.isRequired,
  }

  static defaultProps = {
    center: {
      lat: 30.116936,
      lng: -97.129397,
    },
    zoom: 1,
    device: null,
    fitBounds: () => {},
    mapIconMode: 'comfortable',
    mapIconSizeMode: 'large',
    landmarks: null,
  }

  state = {
    boundsChanged: false,
  }

  componentDidUpdate(prevProps) {
    const { device } = this.props

    if (!equal(prevProps, this.props)) {
      if (device) this.fitToCoordinates()
    }
  }

  /**
   * @description Cleanly clears timer. Removes event listener for resizing
   */
  componentWillUnmount = () => {
    this.clearBoundsTimer()
  }

  onBoundsChange = (/* center, zoom, bounds, marginBounds */) => {
    this.handleBoundChange()
  }

  /**
  * @private
  * @description handles map bounds change
  */
  handleBoundChange = () => {
    if (this.boundsTimer) {
      return
    }
    this.boundsTimer = setTimeout(() => {
      this.toggleLandmarkShapes()
      this.boundsTimer = 0

      this.setState({ boundsChanged: true })
    }, 1200)
  }

  /**
  * @private
  * @description Used to cleanly clear bounds timer
  */
  clearBoundsTimer = () => {
    // If timer is running, clear
    if (this.boundsTimer) {
      clearTimeout(this.boundsTimer)
      this.boundsTimer = 0
    }
  }

  /**
   * @private
   *
   * Fits the map to the devices or a single device
   */
  fitToCoordinates = () => {
    // update this to fit to coordinates at current zoom level?
    if (this.maps && this.map) {
      const bounds = new this.maps.LatLngBounds()
      const { device, fitBounds } = this.props
      // On map load, map zoom defaults to 3
      // Because we're focussed on a single device, if we are zoomed too far out, zoom back in.
      if (this.map.zoom < 5) {
        bounds.extend(new this.maps.LatLng(device.locate.latitude, device.locate.longitude))
        fitBounds(bounds, 16)
      } else {
        // If the user has adjusted zoom to anything greater than 5, keep that
        // zoom level and simply pan the map
        const panPoint = new this.maps.LatLng(device.locate.latitude, device.locate.longitude)
        this.map.panTo(panPoint)
      }
    }
  }

  /**
   * @public
   *
   * Callback for our custom initialization
   */
  initMap = (map, maps) => {
    this.map = map
    this.maps = maps

    const { device } = this.props

    if (device) this.fitToCoordinates()
  }

  /**
   * @private
   *
   * Creates the markers
   */
  marker = () => {
    const { device, mapIconMode, mapIconSizeMode } = this.props
    if (device) {
      return (
        <Marker
          view={mapIconMode}
          size={mapIconSizeMode}
          lat={device.locate.latitude}
          lng={device.locate.longitude}
          heading={device.locate.heading}
          alias={device.alias}
          driver={device.currentDriver ? device.currentDriver.name : null}
          state={device.status.state}
          key={device.id}
          icon={device.icon}
        />
      )
    }

    return null
  }


  /**
   * @description Creates a Google shape based on the landmark's definition
   * @param {Object} landmark
   * @returns Google Circle, Polygon, or Rectangle object
   */
  getLandmarkShape = (landmark) => {
    let shape
    if (landmark.type === 'circle') {
      shape = new this.maps.Circle({
        ...styles.landmarkShape,
        map: this.map,
        center: landmark.center[0],
        radius: landmark.radius,
        editable: false,
        draggable: false,
        zIndex: 1,
      })
    } else if (landmark.type === 'polygon') {
      shape = new this.maps.Polygon({
        ...styles.landmarkShape,
        map: this.map,
        paths: landmark.points,
        editable: false,
        draggable: false,
        zIndex: 1,
      })
    } else {
      const south = landmark.points[0].lat
      const north = landmark.points[1].lat
      const east = landmark.points[2].lng
      const west = landmark.points[0].lng
      shape = new this.maps.Rectangle({
        ...styles.landmarkShape,
        map: this.map,
        bounds: {
          north,
          south,
          east,
          west,
        },
        editable: false,
        draggable: false,
        zIndex: 1,
      })
    }

    return shape
  }

  /**
   * @description Creates or changes a landmark's shape based on the zoom level
   */
  toggleLandmarkShapes = () => {
    const { landmarks, settings } = this.props

    // Check to see if landmark shape was already created
    if (this.map && landmarks) {
      for (let i = 0; i < landmarks.length; i += 1) {
        const landmark = landmarks[i]
        if (this.landmarkShapes[i] === undefined && settings.showLandmarks) {
          this.landmarkShapes[i] = this.getLandmarkShape(landmark)
        } else if (this.landmarkShapes[i] !== undefined) {
          if (this.map.zoom > 10 && settings.showLandmarks) {
            this.landmarkShapes[i].setMap(this.map)
          } else {
            this.landmarkShapes[i].setMap(null)
          }
        }
      }
    }
  }

  /**
   * @private
   * @description Creates landmark markers for map
   * @returns {[Marker]}  Returns array of marker components
   */
  landmarkMarkers = () => {
    const { landmarks, settings, findLandmarkCenter } = this.props
    const { boundsChanged } = this.state

    if (boundsChanged && this.map && settings.showLandmarks && landmarks) {
      const landmarkMarkers = []
      for (let i = 0; i < landmarks.length; i += 1) {
        const landmark = landmarks[i]
        const position = findLandmarkCenter(landmark)
        if (this.map.getBounds().contains(position)) {
          landmarkMarkers.push(
            <Marker
              lat={position.lat}
              lng={position.lng}
              alias={landmark.name}
              key={landmark.id}
              type="landmark"
            />,
          )
        }
      }
      return landmarkMarkers
    }
    return null
  }

  /**
   * @private
   *
   * Customize the map on init
   */
  createMapOptions = maps => ({
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: maps.ControlPosition.LEFT_BOTTOM,
    },
    streetViewControl: true,
    streetViewControlOptions: {
      position: maps.ControlPosition.LEFT_BOTTOM,
    },
    zoomControlOptions: {
      position: maps.ControlPosition.LEFT_BOTTOM,
    },
  })

  render() {
    const { center, zoom, initMap } = this.props
    return (
      <GoogleMapReact
        bootstrapURLKeys={{
          client: 'gme-twmatters',
        }}
        onGoogleApiLoaded={({ map, maps }) => initMap(map, maps, this.initMap)}
        defaultCenter={center}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        options={this.createMapOptions}
        onBoundsChange={this.onBoundsChange}
      >
        {this.marker()}
        {this.landmarkMarkers()}
      </GoogleMapReact>
    )
  }
}
