import { ApolloClient } from 'apollo-boost'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import TokenValidator from './tokenValidator'
import { createUploadLink } from 'apollo-upload-client'

const httpLink = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === undefined ? new createUploadLink({
  /** @Prod */
  // uri: 'https://v3.rhinofleettracking.com/graphql'
  /** @LocalDev */
  uri: 'http://localhost:9005/graphql'
  /** @PublicDev */
  // uri: 'https://staging.rhinofleettracking.com/graphql'

}) : new createUploadLink({
  uri: '/graphql/'
})

const authLink = new ApolloLink((operation, forward) => {
  let token = ''
  if (localStorage.getItem('rft-token')) {
    if (!TokenValidator.tokenHolder) {
      /* On first call to Apollo, record the token in token validitor too */
      TokenValidator.updateTokenHolder(localStorage.getItem('rft-token'))
    }
    /* Compare the token in localStorage with the one in token validator */
    if (localStorage.getItem('rft-token') === TokenValidator.tokenHolder) {
      token = localStorage.getItem('rft-token')
    } else {
      localStorage.removeItem('rft-token')
      sessionStorage.removeItem('rft-token')
      window.location.replace('/login')
    }
  }
  if (sessionStorage.getItem('rft-token')) {
    if (!TokenValidator.tokenHolder) {
      /* On first call to Apollo, record the token in token validitor too */
      TokenValidator.updateTokenHolder(sessionStorage.getItem('rft-token'))
    }
    /* Compare the token in sessionStorage with the one in token validator */
    if (sessionStorage.getItem('rft-token') === TokenValidator.tokenHolder) {
      token = sessionStorage.getItem('rft-token')
    } else {
      localStorage.removeItem('rft-token')
      sessionStorage.removeItem('rft-token')
      window.location.replace('/login')
    }
  }
  operation.setContext(({ headers }) => ({ headers: {
    ...headers,
    token: token,
    secret: 'BEB8AEE33AEE4B3A813B1724922F5B6E'
  } }))

  return forward(operation)
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>{
      /** @todo Add error logging */
      // console.log(
      //   `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      // )
      if (message === 'Invalid token') {
        localStorage.removeItem('rft-token')
        sessionStorage.removeItem('rft-token')
        window.location.replace('/login')
      }
    })
  }
  if (networkError) {
    /** @todo Add error logging */
    // console.log(`[Network error]: ${networkError}`)
    if (networkError.result === 600) {
      localStorage.removeItem('rft-token')
      sessionStorage.removeItem('rft-token')
    }
  }
})

export default new ApolloClient({
  // @ts-ignore
  link: ApolloLink.from([errorLink, authLink, httpLink ]), // the order of the links matter
  cache: new InMemoryCache({
    addTypename: false
  })
})
