import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'antd'
import helper from '@helper'
import polygonIcon from '@assets/img/icons/landmarkPolygon.svg'
import rectangleIcon from '@assets/img/icons/landmarkRectangle.svg'
import radiusIcon from '@assets/img/icons/landmarkRadius.svg'

const styles = {
  card: {
    display: 'flex',
    width: '100%',
    padding: '5px 10px 5px 10px',
  },
  icon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
}

/**
 * @todo add tags
 */
class LandmarksListItem extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    landmark: PropTypes.object,
    id: PropTypes.number,
    isSelected: PropTypes.bool,
    selectLandmark: PropTypes.func,
    listMode: PropTypes.string.isRequired,
    filterLandmarkStr: PropTypes.string,
  }

  static defaultProps = {
    landmark: null,
    id: 0,
    isSelected: false,
    selectLandmark: () => { },
    filterLandmarkStr: null,
  }

  /**
   * Returns a substring of notes showing the matching search in bold if applicable.
   * @param {Object} landmark landmark from props
   * @returns Paragraph Element with notes match as contents
   */
  displayNotesMatch = (landmark) => {
    const { filterLandmarkStr, wMatrix } = this.props
    const { notes } = landmark
    if (!notes || notes.length === 0 || !filterLandmarkStr || filterLandmarkStr.length === 0) {
      return null
    }
    const matchIndex = notes.toLowerCase().indexOf(filterLandmarkStr.toLowerCase())
    // if there is a match in the notes
    if (matchIndex !== -1) {
      // create a substring that is the filterstring with 5 additional characters before and after
      let portionStart = matchIndex - 5
      if (portionStart < 0) portionStart = 0
      const portionEnd = matchIndex + filterLandmarkStr.length + 5
      const viewablePortionString = notes.substr(portionStart, portionEnd)
      // Split on highlight term and include term into parts, ignore case
      // source: https://stackoverflow.com/questions/29652862/highlight-text-using-reactjs
      const parts = viewablePortionString.split(new RegExp(`(${filterLandmarkStr})`, 'gi'))
      const styledSpans = parts.map((part, i) => (
        <span
          // eslint-disable-next-line react/no-array-index-key
          key={`highlight-${i}`}
          style={{ fontWeight: part.toLowerCase() === filterLandmarkStr.toLowerCase() ? 'bold' : 'normal' }}
        >
          {part}
        </span>
      ))
      return (
        <p style={{ fontSize: '0.8em', marginBottom: 0 }}>
          {`${wMatrix('notes')}: `}
          {styledSpans}
        </p>
      )
    }
    return null
  }

  renderItemContent = (landmark) => {
    const { wMatrix, listMode } = this.props
    let icon = null
    if (landmark.type === 'circle') {
      icon = <img src={radiusIcon} alt="circle" style={styles.icon} />
    }
    if (landmark.type === 'rectangle') {
      icon = <img src={rectangleIcon} alt="rectangle" style={styles.icon} />
    }
    if (landmark.type === 'polygon') {
      icon = <img src={polygonIcon} alt="polygon" style={styles.icon} />
    }
    switch (listMode) {
      case 'comfortable':
        return (
          <div style={styles.card}>
            <div style={{ flex: 5, flexDirection: 'column' }}>
              <h3 style={{ marginBottom: '0px', display: 'inline' }}>
                {landmark.name}
              </h3>
              {landmark.billable ? (
                <p style={{ fontSize: '0.8em', marginBottom: 0 }}>{wMatrix('billable')}</p>
              ) : null}
              {landmark.destinationAddress ? (
                <p style={{ fontSize: '0.8em' }}>{landmark.destinationAddress}</p>
              ) : null}
              {this.displayNotesMatch(landmark)}
            </div>
            <div style={{ flex: 1 }}>
              {icon}
            </div>
          </div>
        )
      case 'cozy':
        return (
          <div style={styles.card}>
            <div style={{ flexDirection: 'column' }}>
              <h3 style={{ marginBottom: '0px', display: 'inline' }}>
                {landmark.name}
              </h3>
              {landmark.billable ? (
                <p style={{ fontSize: '0.8em' }}>{wMatrix('billable')}</p>
              ) : null}
            </div>
          </div>
        )
      case 'compact':
        return (
          <div style={styles.card}>
            <h4 style={{ marginBottom: '0px', display: 'inline' }}>
              {landmark.name}
            </h4>
          </div>
        )
      default:
        return null
    }
  }

  render() {
    const {
      landmark, isSelected, selectLandmark,
    } = this.props

    return (
      <List.Item
        key={landmark.id}
        className={`deviceListItem ${isSelected ? 'selected' : ''}`}
        onClick={() => selectLandmark(landmark.id)}
      >
        {this.renderItemContent(landmark)}
      </List.Item>
    )
  }
}

export default helper()(LandmarksListItem)
