import React, { Component } from 'react'
import EventIcon from '@atom/eventIcon'
import { Icon } from 'antd'
import PropTypes from 'prop-types'
import helper from '@helper'
import ButtonWrapper from '@atom/textButton'

class NotificationCard extends Component {
  static propTypes = {
    event: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    alias: PropTypes.string,
    driver: PropTypes.string,
    details: PropTypes.string,
    eventToString: PropTypes.func.isRequired,
    highlightOnMount: PropTypes.bool,
  }

  static defaultProps = {
    onClose: null,
    alias: '',
    driver: '',
    details: '',
    highlightOnMount: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      highlight: props.highlightOnMount || false,
    }
  }

  /**
   * If highlighted on mount, unhighlight after 5 seconds
   */
  componentDidMount = () => {
    const { highlight } = this.state
    if (highlight) {
      setTimeout(() => {
        this.setState({ highlight: false })
      }, 5000)
    }
  }

  /**
   * @NOTE - is time in user timezone?...
   *
   */

  render() {
    const {
      event, alias, driver, eventToString, onClose, details,
    } = this.props
    const { highlight } = this.state
    return (
      <div
        style={{
          flexShrink: 0,
          backgroundColor: highlight ? '#e8ebf0' : 'white',
          transition: 'all 1s ease',
        }}
      >
        <div
          style={{
            display: 'flex',
            padding: '10px 15px',
            borderBottom: '1px solid #e9e9e9',
            alignItems: 'flex-start',

          }}
        >
          <EventIcon
            event={event}
            imgStyle={{ marginTop: 5, marginLeft: 5, marginRight: 5 }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              flexGrow: 1,
              margin: 5,
            }}
          >
            <div style={{ marginBottom: 10, fontSize: 16 }}>
              {eventToString(event)}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>
                <strong>{alias}</strong>
                {driver ? ` (${driver})` : ''}
              </span>
              <span>
                {details}
              </span>
            </div>
          </div>
          <ButtonWrapper
            useAsWrapper
            onClick={onClose}
            style={{
              height: 10,
              width: 10,
            }}
          >
            <Icon type="close" />
          </ButtonWrapper>
        </div>
      </div>
    )
  }
}

export default helper()(NotificationCard)
