import gql from 'graphql-tag'

export const assignStopLength = gql`
  mutation assignStopLength($deviceId: Int!, $stopLength: Int!) {
    assignStopLength(deviceId: $deviceId, stopLength: $stopLength) {
      code
      description
    }
  }
`

export const updateSpeedThreshold = gql`
  mutation updateSpeedThreshold($deviceId: Int!, $speedThreshold: Int!) {
    assignSpeedThreshold(deviceId: $deviceId, speedThreshold: $speedThreshold) {
      code
      description
    }
  }
`

export const createServiceTicket = gql`
  mutation createServiceTicket(
    $deviceId: Int!,
    $typeId: Int!,
    $dueTypeId: Int!,
    $intervalTypeId: Int,
    $startingValue: Int,
    $dueValue: Int,
    $intervalValue: Int,
    $estimatedCost: Float,
    $actualCost: Float,
    $completed: Boolean!,
    $dtCompleted: String,
    $notes: String
   ){
    createServiceTicket(
      deviceId: $deviceId,
      typeId: $typeId,
      dueTypeId: $dueTypeId,
      intervalTypeId: $intervalTypeId,
      startingValue: $startingValue,
      dueValue: $dueValue,
      intervalValue: $intervalValue,
      estimatedCost: $estimatedCost,
      actualCost: $actualCost,
      completed: $completed,
      dtCompleted: $dtCompleted,
      notes: $notes
    ){
      code
      description
    }
  }
`

export const updateServiceTicket = gql`
  mutation updateServiceTicket(
    $id: Int!,
    $deviceId: Int!,
    $typeId: Int!,
    $dueTypeId: Int!,
    $intervalTypeId: Int,
    $currentValue: Float!,
    $dueValue: Int,
    $intervalValue: Int,
    $estimatedCost: Float,
    $actualCost: Float,
    $completed: Boolean!,
    $dtCompleted: String,
    $notes: String
  ){
    updateServiceTicket(
      id: $id,
      deviceId: $deviceId,
      typeId: $typeId,
      dueTypeId: $dueTypeId,
      intervalTypeId: $intervalTypeId,
      currentValue: $currentValue,
      dueValue: $dueValue,
      intervalValue: $intervalValue,
      estimatedCost: $estimatedCost,
      actualCost: $actualCost,
      completed: $completed,
      dtCompleted: $dtCompleted,
      notes: $notes
    ){
      code
      description
    }
  }
`

export const deleteServiceTicket = gql`
  mutation deleteServiceTicket($id: Int!) {
    deleteServiceTicket(id: $id) {
      code
      description
    }
  }
`

export const createReportSchedule = gql`
  mutation createReportSchedule(
    $name: String!,
    $type: ReportTypeEnum!,
    $deviceIds: [Int],
    $groupIds: [Int],
    $labelIds: [Int],
    $landmarkIds: [Int],
    $driverIds: [Int],
    $dateRange: ReportScheduleDateRangeEnum!,
    $frequency: ReportScheduleFrequencyEnum!,
    $deliveryTime: String!,
    $recipients: String!,
    $deliveryStartDate: String!,
    $startTime: String,
    $endTime: String,
    $events: [String],
    $speedThreshold: Int,
    $serviceTypes: [Int],
    $serviceStatus: [Int]
  ){
    createReportSchedule(
      name: $name,
      type: $type
      deviceIds:$deviceIds
      groupIds: $groupIds
      labelIds: $labelIds
      landmarkIds: $landmarkIds
      driverIds: $driverIds
      dateRange: $dateRange
      frequency: $frequency
      deliveryStartDate: $deliveryStartDate
      deliveryTime: $deliveryTime
      recipients: $recipients
      startTime: $startTime
      endTime: $endTime
      events: $events
      speedThreshhold: $speedThreshold,
      serviceTypes: $serviceTypes,
      serviceStatus: $serviceStatus
    )
  }
`

export const updateReportSchedule = gql`
  mutation updateReportSchedule(
    $id: Int!,
    $name: String!,
    $type: ReportTypeEnum!,
    $deviceIds: [Int],
    $groupIds: [Int],
    $labelIds: [Int],
    $landmarkIds: [Int],
    $driverIds: [Int],
    $dateRange: ReportScheduleDateRangeEnum!,
    $frequency: ReportScheduleFrequencyEnum!,
    $deliveryTime: String!,
    $recipients: String!,
    $deliveryStartDate: String!,
    $startTime: String,
    $endTime: String,
    $events: [String],
    $speedThreshold: Int,
    $serviceTypes: [Int],
    $serviceStatus: [Int],
  ){
    updateReportSchedule(
      id: $id,
      name: $name,
      type: $type
      deviceIds:$deviceIds,
      groupIds: $groupIds,
      labelIds: $labelIds,
      landmarkIds: $landmarkIds,
      driverIds: $driverIds,
      dateRange: $dateRange,
      frequency: $frequency,
      deliveryTime: $deliveryTime,
      recipients: $recipients,
      deliveryStartDate: $deliveryStartDate,
      startTime: $startTime,
      endTime: $endTime,
      events: $events,
      speedThreshhold: $speedThreshold,
      serviceTypes: $serviceTypes,
      serviceStatus: $serviceStatus
    ){
      code
      description
    }
  }
`

export const deleteReportSchedule = gql`
  mutation deleteReportSchedule($id: Int!) {
    deleteReportSchedule(id: $id) {
      code
      description
    }
  }
`

export const verifyCodeAndSetPassword = gql`
  mutation verifyCodeAndSetPassword($password: String, $email: String, $veriCode: String) {
    verifyCodeAndSetPassword(password: $password, email: $email, veriCode: $veriCode) {
      code
      description
    }
  }
`

export const generateForgotCode = gql`
  mutation generateForgotCode($email: String) {
    generateForgotCode(email: $email) {
      code
      description
    }
  }
`

export const sendMail = gql`
  mutation sendMail($subject: String, $body: String, $to: String) {
    sendMail(subject: $subject, body: $body, to: $to) {
      code
      description
    }
  }
`

export const updateAdmins = gql`
mutation admins($admins: [AdminInput]) {
  updateAdmins(admins: $admins) {
    id
    name
    password
    isActive
    isSuperAdmin
  }
}
`

export const createAdmin = gql`
mutation create($admin: String) {
  createAdmin(name: $admin) {
    code
    description
  }
}

`

export const updateClient = gql`
mutation updateClient($clientId: Int!, $isActive: Boolean) {
  updateClient(clientId: $clientId, isActive: $isActive) {
    code
  	description
  }
}`

export const createContact = gql `
mutation createContact($clientId: Int!, $contact: CreateContactInput) {
  createContact(clientId: $clientId contact: $contact) {
    code
    description
  }
}`

export const sendSMSMessage = gql `
mutation sendSMS($number: String!, $body: String!, $isATT: Boolean!) {
  sendSMSMessage(number: $number, body: $body, isATT: $isATT) {
    code
    description
  }
}`

export const sendSMSToDevice = gql `
mutation sendSMSToDevice($request: RequestInput) {
  sendSMSToDevice(request: $request) {
    code
    description
  }
}`

export const createFirmware = gql `
mutation createFirmware($firmware: FirmwareInput) {
  createFirmware(firmware: $firmware) {
    code
    description
  }
}`

export const unassignDevices = gql `
mutation unassignDevices($devices: [DeviceInput!]!) {
  unassignDevices(devices: $devices) {
    code
    description
  }
}`

export const addDevices = gql `
mutation bulkAddDevice($devices: [UnassignedDeviceInput]!, $clientId: Int!, $type: CreateDevicesEnum!) {
  createDevices(devices: $devices, clientId: $clientId, type: $type) {
    code
    description
  }
}`

export const addSingleDevice = gql `
mutation addSingleDevice($clientId: Int, $device: SingleDeviceInput, $type: CreateDevicesEnum){
  createDevices(clientId: $clientId, device: $device, type : $type){
    code
    description
  }
}`

export const updateFeatures = gql `
mutation updateFeatures($activeClient: Int, $features: [FeatureInput]) {
  updateFeatures(clientId: $activeClient, features: $features) {
    code
    description
  }
}
`

export const updateContacts = gql `
mutation updateContacts($contacts: [ContactInput]) {
  updateContacts(contacts: $contacts) {
    id
    firstName
    lastName
    homePhone
    mobilePhone
    password
    isActive
    email
    role {
      id
      name
    }
    groups {
      id
      name
      color
    }
  }
}`

export const updateDevices = gql `
mutation updateDevices($devices: [DeviceInput]) {
  updateDevices(devices: $devices) {
    id
    productKey
    alias
    imei
    typeId
    type
    phoneNumber
    simSerial
    simCardNumber
    firmwareId
    firmware
    networkId
    network
    networkProviderId
    networkProvider
    warrantyDate
    warranty
  	isActive
  }
}`

export const assignDevices = gql `
mutation assignDevices($numClientID: Int, $numVehicleDeviceID: [Int]) {
  assignDevices(numClientID: $numClientID, numVehicleDeviceID: $numVehicleDeviceID) {
    code
    description
  }
}`

export const createCompanyGroup = gql`
mutation createCompanyGroup($id: Int, $name: String, $color: String) {
  createCompanyGroup(id: $id, name: $name, color:$color) {
    id
    name
    color
  }
}
`

export const assignCompanyGroupToDevice = gql`
mutation assignCompanyGroupToDevice( $groupIds: [Int], $deviceIds: [Int], $ifEditingTag: Boolean){
	assignCompanyGroupToDevice(groupIds : $groupIds, devices : $deviceIds, ifEditingTag: $ifEditingTag){
		code
		description
	}
}
`

export const assignCompanyGroupToUser = gql`
mutation assignCompanyGroupToUser( $groupIds: [Int], $contactIds: [Int], $ifEditingTag: Boolean){
	assignCompanyGroupToUser(groupIds: $groupIds, contacts: $contactIds, ifEditingTag: $ifEditingTag){
		code
		description
	}
}
`

export const deleteCompanyGroupMutation = gql`
mutation deleteCompanyGroup_mutation($id: Int) {
  deleteCompanyGroup(id: $id) {
    code
    description
  }
}
`

export const createContact_mutation = gql`
mutation createContact_mutation( $contact: CreateContactInput){
  createContactBcrypt(contact: $contact){
      id,
      firstName,
      lastName,
      email,
      password
      homePhone,
      mobilePhone,
      role{
          id
      }
      isActive
  }
}`

export const updatePreference_mutation = gql`
mutation updatePreference_mutation(
  $email: String, $password: String,
  $firstName: String,$lastName: String,
  $mobilePhone: String, $textAddress: String,
  $language: String, $measurement: String,
  $timeZoneId: Int, $mapRefresh: Int,
  $country: String
) {
  updatePreference(
    email: $email, password: $password,
    firstName : $firstName, lastName: $lastName,
    mobilePhone: $mobilePhone, textAddress: $textAddress,
    language: $language, measurement: $measurement,
    timeZoneId: $timeZoneId, mapRefresh: $mapRefresh,
    country: $country
  ) {
    code
    description
  }
}
`

export const createPersonalLabel = gql`
mutation CreatePersonalLabel( $id: Int, $name: String, $color: String){
  createPersonalLabel(id: $id, name: $name, color: $color){
      id
      name
      color
  }
}
`

export const DeletePersonalLabel = gql`
mutation DeletePersonalLabel( $id: Int){
  deletePersonalLabel(id: $id){
      code
      description
  }
}
`
export const assignPersonalLabelToDevice = gql`
mutation assignPersonalLabelToDevice( $labelIds: [Int], $deviceIds: [Int], $ifEditingTag: Boolean){
  assignPersonalLabelToDevice(labelIds : $labelIds, devices : $deviceIds, ifEditingTag: $ifEditingTag){
      code
      description
  }
}
`

export const updateAlertSettings = gql`
mutation UpdateAlertSettings($numVehicleDeviceIds: [Int], $numEventTypeIds: [Int], $ynEmail: Boolean, $ynText: Boolean, $ynPush: Boolean, $ynApp: Boolean, $numSpeed: Int, $landmarkDetails: [LandmarkDirectionInput], $HOO: VehicleHoursOfOperationSettingInput, $numStopLength: Int ){
  updateAlertSettings(numVehicleDeviceIds: $numVehicleDeviceIds, numEventTypeIds: $numEventTypeIds, ynText: $ynText, ynEmail :$ynEmail, ynPush: $ynPush, ynApp: $ynApp, numSpeed: $numSpeed, landmarkDetails: $landmarkDetails, HOO:$HOO, numStopLength: $numStopLength){
    description
    code
  }
}
`

export const updateAlertMOSL = gql`
mutation UpdateAlertMOSL($numMOSL: Int){
  updateAlertMOSL(numMOSL:$numMOSL){
    description
    code
  }
}
`

export const updateVehicleIcon = gql`
mutation updateVehicleIcon($deviceId: Int!, $type: String!, $color: String!, $label: String){
  updateVehicleIcon(deviceId: $deviceId, type: $type, color: $color, label: $label){
    type
    color
    text
  }
}
`

export const updateVehicleInfo = gql`
mutation updateVehicleInfo($numVehicleDeviceID: Int, $alias: String, $make: Int, $model: Int, $year: Int, $color: String, $license: String, $vin: String){
  updateVehicleInfo(numVehicleDeviceID:$numVehicleDeviceID, alias:$alias, make:$make, model:$model, year:$year, color:$color, license:$license, vin:$vin){
    code
    description
  }
}
`
export const updateDriver = gql`
  mutation updateDriver(
    $id: Int,
    $firstName: String,
    $lastName: String,
    $fob: String,
    $dotNumber: String,
    $ifActive: Boolean
  ) {
    updateDriver (
      id:$id,
      firstName:$firstName,
      lastName:$lastName,
      fob: $fob,
      dotNumber: $dotNumber,
      ifActive: $ifActive
    ) {
      id
      name
      firstName
      lastName
      fob
      dotNumber
    }
  }
`

export const createDriver = gql`
  mutation createDriver(
    $firstName: String,
    $lastName: String,
    $fob: String,
    $dotNumber: String,
    $ifActive: Boolean
  ) {
    createDriver(
      firstName:$firstName,
      lastName:$lastName,
      fob: $fob,
      dotNumber: $dotNumber,
      ifActive: $ifActive
    ) {
      id
      name
      firstName
      lastName
      fob
      dotNumber
    }
  }
`

export const deleteDriver = gql`
  mutation updateDriver($id: Int, $ifActive: Boolean) {
    updateDriver(id:$id, ifActive: $ifActive) {
      id
      name
      firstName
      lastName
      fob
      dotNumber
    }
  }
`

export const assignDeviceToDriver = gql`
mutation assignDeviceToDriver($deviceId:Int, $driverId: Int){
  assignDeviceToDriver(deviceId: $deviceId, driverId:$driverId){
    code
    description
  }
}
`

export const assignDriverToWexCard = gql`
  mutation assignDriverToWexCard($wexCard: String, $driverId: Int!) {
    assignDriverToWexCard(wexCard: $wexCard, driverId: $driverId) {
      code
      description
    }
  }
`

export const createLandmark = gql`
mutation createLandmark(
  $type: LandmarkTypeEnum!,
  $name: String!,
  $points: [PointInput]!,
  $radius: Float,
  $billable: Boolean!,
  $destinationAddress: String,
  $notes: String
 ){
  createLandmark(
    type:$type,
    name: $name,
    points: $points,
    radius: $radius,
    billable: $billable,
    destinationAddress: $destinationAddress,
    notes:$notes
  ){
    code
    description
  }
 }
`

export const updateLandmark = gql`
mutation updateLandmark(
  $id: Int!,
  $type: LandmarkTypeEnum!,
  $name: String!,
  $points: [PointInput]!,
  $radius: Float,
  $billable: Boolean!,
  $destinationAddress: String,
  $notes: String
 ){
  landmarkUpdate(
    id: $id,
    type: $type,
    name: $name,
    points: $points,
    radius: $radius,
    billable: $billable,
    destinationAddress: $destinationAddress,
    notes: $notes
  ){
    code
    description
  }
 }
`

export const deleteLandmark = gql`
mutation deleteLandmark($id: Int!){
  landmarkDelete(id: $id){
    code
  }
}
`

export const updateStarterInterrupted = gql`
mutation updateStarterInterrupted ($deviceId: Int!, $enable: Int!) {
  updateStarterInterrupted (deviceId: $deviceId, enable: $enable) {
    code
    description
  }
}
`

export const updateMOSL = gql`
mutation updateMOSL($mosl: Int!) {
  updateMOSL(mosl: $mosl) {
    code
    description
  }
}
`

export const createReportDownloadQueueItem = gql`
mutation createReportDownloadQueueItem($type: String!, $inputBlob: String!, $email: String!){
  createReportDownloadQueueItem(type: $type, inputBlob: $inputBlob, emails: $email){
    code
    description
  }
}
`

export const updateClip = gql`
mutation updateClip($clipId: Int!, $favorite: Boolean!) {
  updateClip(clipId: $clipId, favorite: $favorite){
    description
    code
  }
}
`

export const markClipAsViewed = gql`
mutation markClipAsViewed($clipId: Int!) {
  markClipAsViewed(clipId: $clipId){
    description
    code
  }
}
`

export const requestClipUpload = gql`
mutation uploadClip(
  $deviceId: Int!,
  $datetime: String!,
  $event: String,
  $lat: Float,
  $lng:Float,
  $address: String,
  $landmarks: String,
  $drivers: String,
  $eventDetails: String
){
  uploadClip(
    deviceId: $deviceId,
    datetime: $datetime,
    event: $event,
    lat: $lat,
    lng: $lng,
    address: $address,
    landmarks: $landmarks,
    drivers: $drivers,
    eventDetails: $eventDetails
  ){
    description
    code
  }
}
`

export const requestCustomClipUpload = gql`
mutation uploadCustomClip($deviceId: Int!, $startDatetime: String!, $duration: Int!){
  uploadCustomClip(deviceId: $deviceId, startDatetime: $startDatetime, duration: $duration){
    description
    code
  }
}
`

export const deleteClip = gql`
mutation deleteClip($clipId: Int!, $deviceId: Int!){
  deleteClip(clipId: $clipId, deviceId: $deviceId){
    description
    code
  }
}
`

export const updateFobStatus = gql`
  mutation updateFobStatus($deviceId: Int!, $enable: Boolean!) {
    updateFobStatus(deviceId: $deviceId, enable: $enable) {
      code
      description
    }
  }
`

export const updateTimelineRange = gql`
  mutation updateTimelineRange($deviceId: Int!, $timeRange: TimelineRangeEnum!) {
    updateTimelineRange(deviceId: $deviceId, timeRange: $timeRange) {
      code
      description
    }
  }
`

export const createLogisticsMessageMutation = gql`
mutation createMessage(
  $deviceIds: [Int]!,
  $content: String!,
  $origin: MessageOriginEnum!
 ){
  createLogisticsMessage(
    deviceIds: $deviceIds,
    content: $content,
    origin: $origin
  ){
    code
    description
  }
 }
`

export const markMessagesAsReadMutation = gql`
mutation markMessagesAsRead($messageIds:[Int]!){
  markMessagesAsRead(messageIds: $messageIds){
    code
  }
 }
`

export const quickCreateJob = gql`
  mutation quickCreateJob($jobId:Int!){
    quickCreateJob(jobId:$jobId)
  }
`

export const createJobMutation = gql`
  mutation createLogisticsJob(
    $alias: String!,
    $notes: String,
    $deviceId: Int,
    $dueDate: String
  ) {
    createLogisticsJob(
      alias: $alias,
      notes: $notes,
      deviceId: $deviceId,
      dueDate: $dueDate
    )
  }
`

export const updateJobMutation = gql`
  mutation updateLogisticsJob(
    $id: Int!,
    $alias: String,
    $notes: String,
    $deviceId: Int,
    $dueDate: String,
    $completed: Boolean
  ) {
    updateLogisticsJob(
      id: $id,
      alias: $alias,
      notes: $notes,
      deviceId: $deviceId,
      dueDate: $dueDate,
      completed: $completed
    ) {
      code
      description
    }
  }
`

export const deleteJobMutation = gql`
  mutation deleteLogisticsJob($id: Int!){
    deleteLogisticsJob(id: $id){
      code
      description
    }
  }
`

export const createDestinationMutation = gql`
  mutation createLogisticsDestination(
    $jobId: Int!,
    $label: String!,
    $sequence: Int!,
    $address: String!,
    $coordinates: String
  ) {
    createLogisticsDestination(
      jobId: $jobId,
      label: $label,
      sequence: $sequence,
      address: $address,
      coordinates: $coordinates
    )
  }
`

export const updateDestinationMutation = gql`
  mutation updateLogisticsDestination(
    $id: Int!,
    $label: String,
    $sequence: Int,
    $address: String,
    $completed: Boolean
  ) {
    updateLogisticsDestination(
      id: $id,
      label: $label,
      sequence: $sequence,
      address: $address,
      completed: $completed
    ) {
      code
      description
    }
  }
`

export const deleteDestinationMutation = gql`
  mutation deleteLogisticsDestination($id: Int!){
    deleteLogisticsDestination(id: $id){
      code
      description
    }
  }
`

export const createTaskMutation = gql`
  mutation createLogisticsTask(
    $alias: String!,
    $type: LogisticsTaskTypeEnum!,
    $notes: String,
    $email: String,
    $destinationId: Int!
  ) {
    createLogisticsTask(
      alias: $alias,
      type : $type,
      notes: $notes,
      email: $email,
      destinationId: $destinationId
    )
  }
`
export const updateTaskMutation = gql`
  mutation updateLogisticsTask(
    $id: Int!,
    $alias: String,
    $type: LogisticsTaskTypeEnum,
    $notes: String,
    $email: String,
    $completed: Boolean
  ) {
    updateLogisticsTask(
      id: $id,
      alias: $alias,
      type : $type,
      notes: $notes,
      email: $email,
      completed: $completed
    ) {
      code
      description
    }
  }

`

export const deleteTaskMutation = gql`
  mutation deleteLogisticsTask($id: Int!){
    deleteLogisticsTask(id: $id){
      code
      description
    }
  }
`

export const updateContactMetaMutation = gql`
  mutation updateContactMeta($listDensity: DensityEnum!, $mapIconDensity: DensityEnum!, $mapIconSize: IconSizeEnum!){
    updateContactMeta(listDensity: $listDensity, mapIconDensity: $mapIconDensity, mapIconSize: $mapIconSize){
      code
      description
    }
  }
`

export const uploadLandmarksMutation = gql`
  mutation uploadLandmarks($file: Upload!, $deleteExisting: Boolean){
    uploadLandmarks(file: $file, deleteExisting: $deleteExisting){
      code
      description
    }
  }
`
