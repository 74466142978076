import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import TimelineCard from '@atom/timelineCard'
import TimelineHeader from '@mol/timelineHeader'
import moment from 'moment'
import TripHeader from '@atom/tripHeader'
import SectionScrollView from '@page/main/molecules/sectionScrollView'
import TimelineCard from '@atom/timelineCard'
import { Spin } from 'antd'

/**
 * @NOTES
 * - timeline
 *  - trips
 *    - Header
 *      - trip #
 *      - ending address
 *      - start and end time
 *      - distance
 *    - timelineCard
 *      - event
 *      - time
 *      - address
 *      - details
 *    - time to next trip
 */

/**
  * @TODOs
  *  - make sure to address when there are No Trips
  */

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  scrollContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  blueHeader: {
    minHeight: '50px',
    padding: '4px',
    display: 'flex',
    flexShrink: '0',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    alignItems: 'center',
    color: 'white',
    backgroundColor: '#102A96',
  },
  daySummary: {
    display: 'flex',
    flexShrink: '0',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    backgroundColor: '#EBEBEB',
    paddingLeft: '5px',
    paddingRight: '5px',
    borderColor: '#B2B2B2',
    borderWidth: '0px 0px 1px 0px',
    borderStyle: 'solid',
    boxShadow: ' 0px -2px 8px',
  },
  HOOHour: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    marginRight: '5px',
  },
}

export default class Timeline extends Component {
  static propTypes = {
    eventToString: PropTypes.func.isRequired,
    changeTripDate: PropTypes.func.isRequired,
    selectedDevice: PropTypes.object,
    setSelectedTrip: PropTypes.func.isRequired,
    selectEvent: PropTypes.func.isRequired,
    wMatrix: PropTypes.func.isRequired,
    toggleFilterPage: PropTypes.func.isRequired,
    selectedTrip: PropTypes.number,
    selectedEventID: PropTypes.number,
    sortOrder: PropTypes.string,
    date: PropTypes.string.isRequired,
    historyTrips: PropTypes.array,
    hoursOfOperation: PropTypes.object,
    redTripsLoading: PropTypes.bool,
    tripsEnabled: PropTypes.bool,
    expanded: PropTypes.bool,
    requestCameraClipUpload: PropTypes.func,
    selectModal: PropTypes.func,
  }

  static defaultProps = {
    selectedTrip: null,
    selectedEventID: null,
    selectedDevice: null,
    tripsEnabled: true,
    historyTrips: null,
    expanded: true,
    hoursOfOperation: null,
    redTripsLoading: false,
    sortOrder: 'descending',
    requestCameraClipUpload: null,
    selectModal: null,
  }

  // maybe move to hoc?
  formatTimeString = (timeString) => {
    if (timeString) {
      return moment(timeString, 'MM/DD/YYYY h:mm:ss A').format('h:mm a')
      // return timeString.substring(11, 21).toLowerCase()
    }
    return ''
  }

  /**
   * Renders HOO for the day or last thirty days.
   */
  renderDaySummary = () => {
    const {
      hoursOfOperation, wMatrix, expanded, selectedDevice,
    } = this.props

    let hooEnd = null
    let hooStart = null
    if (hoursOfOperation.start) {
      hooStart = selectedDevice.timelineRange === 'thirty-history'
        ? hoursOfOperation.start
        : moment(hoursOfOperation.start, 'hh:mm a').format('h:mm a')
    }
    if (hoursOfOperation.end) {
      hooEnd = selectedDevice.timelineRange === 'thirty-history'
        ? hoursOfOperation.end
        : moment(hoursOfOperation.end, 'hh:mm a').format('h:mm a')
    }

    return (
      expanded ? (
        <div style={styles.daySummary}>
          <span>{wMatrix('hoursOfOperation')}</span>
          <div style={styles.HOOHour}>
            <h5 style={{ marginBottom: 0 }}>
              {wMatrix('Start')}
              {': '}
            </h5>
            <h5 style={{ marginBottom: 0, fontWeight: 'normal', marginLeft: '2px' }}>
              {hooStart}
            </h5>
          </div>
          <div style={styles.HOOHour}>
            <h5 style={{ marginBottom: 0 }}>
              {wMatrix('End')}
              {': '}
            </h5>
            <h5 style={{ marginBottom: 0, fontWeight: 'normal', marginLeft: '2px' }}>
              {hooEnd}
            </h5>
          </div>
        </div>
      ) : null
    )
  }

  /**
   * Renders the header for timeline
   */
  renderHeader = () => {
    const {
      date, expanded, changeTripDate, toggleFilterPage, wMatrix,
      selectedDevice,
    } = this.props

    return (
      <TimelineHeader
        lastThirty={selectedDevice.timelineRange === 'thirty-history'}
        date={date}
        expanded={expanded}
        changeTripDate={changeTripDate}
        filterPage={false}
        toggleFilterPage={toggleFilterPage}
        wMatrix={wMatrix}
      />
    )
  }

  renderTimelineCard = (section, item) => {
    const {
      selectedTrip, selectedEventID, expanded, wMatrix,
      selectEvent, selectedDevice, eventToString,
      selectModal, requestCameraClipUpload,
    } = this.props

    if (item.type !== 'hour') {
      return (
        <TimelineCard
          event={item.event}
          device={selectedDevice}
          unit={selectedDevice.unit}
          deviceType={selectedDevice.type}
          eventToString={eventToString}
          lat={item.lat}
          lng={item.lng}
          // location={location}
          address={item.address}
          landmarks={item.landmarks}
          drivers={section.drivers}
          details={item.details}
          heading={item.heading}
          speed={item.speed}
          time={item.time}
          date={item.date}
          datetime={item.datetime}
          expanded={expanded}
          wMatrix={wMatrix}
          key={`${section.tripOrder}-${item.id}`}
          scrollid={`${section.tripOrder}-${item.id}`}
          focused={selectedTrip === section.tripOrder && item.id === selectedEventID}
          videoClip={item.videoClip}
          selectEvent={selectEvent}
          onClickEvent={() => {
            selectEvent(item.id, section.tripOrder)
          }}
          selectModal={selectModal}
          requestCameraClipUpload={requestCameraClipUpload}
        />
      )
    }
    return (
      <TimelineCard
        event={item.event}
        time={item.time}
        expanded={expanded}
        wMatrix={wMatrix}
        scrollid={`${section.tripOrder}-${item.id}`}
        key={`${section.tripOrder}-${item.id}`}
        disabled
      />
    )
  }

  renderTripHeader = (trip) => {
    const {
      wMatrix, expanded, selectedTrip, setSelectedTrip, sortOrder,
    } = this.props
    return (
      <TripHeader
        sortOrder={sortOrder}
        wMatrix={wMatrix}
        trip={trip}
        setSelectedTrip={setSelectedTrip}
        selectedTrip={selectedTrip}
        expanded={expanded}
      />
    )
  }

  renderTripFooter = (trip) => {
    const {
      expanded, wMatrix,
      // sortOrder
    } = this.props
    if (trip.timeToNextTrip && expanded) {
      let hrs = null
      let mins = trip.timeToNextTrip
      if (mins > 60) {
        hrs = Math.floor(mins / 60)
        mins %= 60
      }
      return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <h5>
            {wMatrix('Time Between Trips')}
            :
          </h5>
          <h5 style={{
            marginLeft: '5px',
            fontWeight: 'normal',
          }}
          >
            {hrs || null}
            {hrs ? ' hr' : ''}
            {hrs > 1 ? 's ' : ' '}
            {' '}
            {mins}
            {mins > 1 ? ' mins ' : ' min '}
          </h5>
        </div>
      )
    }
    // if (!expanded) {
    //   return (
    //     <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
    //       <h5 style={{ whiteSpace: 'nowrap' }}>
    //         {
    //           sortOrder === 'descending'
    //             ? moment(trip.startTime, 'MM/DD/YYYY h:mm A').format('h:mm a')
    //             : moment(trip.endTime, 'MM/DD/YYYY h:mm A').format('h:mm a')
    //         }
    //       </h5>
    //     </div>
    //   )
    // }
    return null
  }

  render() {
    const {
      wMatrix, selectedTrip, selectedEventID, historyTrips, tripsEnabled,
      expanded, redTripsLoading,
    } = this.props
    const noData = (!redTripsLoading && historyTrips.length === 0)
    // Temporarily adding spin here to display loading
    return (
      <div style={{ ...styles.container }}>
        {this.renderHeader()}
        {this.renderDaySummary()}
        {redTripsLoading ? <Spin style={{ margin: 10 }} /> : null}
        {noData
          ? (<div style={{ alignSelf: 'center' }}>{wMatrix('noTripData')}</div>)
          : (
            <SectionScrollView
              // Identifiers will be in form '<sectionID>-<itemID>'
              sections={historyTrips}
              selectedId={`${selectedTrip}-${selectedEventID}`}
              renderItem={this.renderTimelineCard}
              renderSectionHeader={this.renderTripHeader}
              renderSectionFooter={this.renderTripFooter}
              itemArrayKey="tripDetails.events" // separate nested keys by '.'
              sectionIdKey="tripOrder"
              itemIdKey="id"
              collapsable
              miniMode={!expanded}
              // collapsable={expanded}
              noHeaders={!tripsEnabled}
              noFooters={!tripsEnabled}
            />
          )
        }
      </div>
    )
  }
}
