import packageJSON from '../../../package.json'

const release = {
  version: packageJSON.version,
  msgs: [],
}

function buildMsg(date, msg) {
  return {
    date,
    msg,
  }
}

release.msgs.push(buildMsg('09/13/2021', 'V3.1.16: Bug fixes for showing driver name in device left panel'))
release.msgs.push(buildMsg('08/27/2021', 'V3.1.15: Performance Enhancements using virtualization'))
release.msgs.push(buildMsg('08/23/2021', 'V3.1.14: Performance Enhancements and Bug fixes'))
release.msgs.push(buildMsg('08/07/2021', 'V3.1.13: New Feature - Driver Scorecard Report'))
release.msgs.push(buildMsg('07/30/2021', 'V3.1.12: New Feature - Trip view for special Asset Trackers'))
release.msgs.push(buildMsg('07/27/2021', 'V3.1.11: Performance Enhancements and Bug fixes'))
release.msgs.push(buildMsg('07/24/2021', 'V3.1.10: New Feature - Field Service Job Quick Create'))

release.msgs = release.msgs.slice(0, 8)
for (const i in release.msgs) {
  if (i % 3 === 0) release.msgs[i].color = 'red'
  else if (i % 3 === 1) release.msgs[i].color = 'green'
  else release.msgs[i].color = 'blue'
}


export default release
