import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button } from 'antd'
import TabBar from '@mol/tabBar'
// import ModalContainer from '../containers'
import helper from '@helper'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flex: 1,
    color: '#2b303b',
    padding: 10,
    overflow: 'hidden',
    position: 'relative',
  },
  smallHeader: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    // height: 50,
    backgroundColor: '#fff',
    borderBottom: '2px solid #E9e9e9',
    justifyContent: 'flex-end',
  },
  largeHeader: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: '#fff',
    borderBottom: '2px solid #E9e9e9',
  },
  tabs: {
    alignSelf: 'center',
    // height: 50,
    bottom: 0,
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'relative',
    overflow: 'auto',
    marginTop: 4,
    backgroundColor: '#FFFFFF',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  headerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'no-wrap',
  },
  alias: {
    float: 'left',
    lineHeight: '50px',
    marginBottom: '0px',
    maxWidth: '70%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  driver: {
    height: '50px',
    minWidth: '200px',
    lineHeight: '55px',
    display: 'inline-block',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'no-wrap',
    flexShrink: '0',
    marginTop: '10px',
    justifyContent: 'flex-end',
    width: 84,
  },
  button: {
    marginLeft: '5px',
    marginRight: '5px',
    paddingLeft: 8,
    paddingRight: 8,
  },
}


/**
 * Reusable Right Container
 *
 * There are functions passed in the props to render specific views.
 * For the tab content, you need to pass them via the children prop (example below).
 *
 * Example:
 *  <RightContainer>
 *    <div key="1">Content of tab 1</div>
 *    <div key="2">Content of tab 2</div>
 *  </RightContainer>
 *
 * Default renders:
 * * Title
 * * Close Button
 *
 * Custom Renders:
 * * Tabs (should always have two sets, 'small' and 'large')
 * * Subtitle Action
 * * Header Details
 * * Child Content
 *
 * View Options:
 * * Small
 * * Large
 *
 */
class RightContainer extends Component {
  static propTypes = {
    headerMode: PropTypes.string,
    tabs: PropTypes.object.isRequired,
    title: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    defaultTabKey: PropTypes.string,
    renderHeaderDetails: PropTypes.func,
    onSettingsClick: PropTypes.func,
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    customHeading: PropTypes.object,
    onTabChange: PropTypes.func,
  }

  static defaultProps = {
    headerMode: 'small',
    children: null,
    defaultTabKey: 1,
    title: null,
    renderHeaderDetails: () => { },
    onSettingsClick: null,
    customHeading: null,
    onTabChange: null,
  }

  state = {
    currentTab: null,
  }

  componentDidMount = () => {
    const { defaultTabKey } = this.props

    this.setState({
      currentTab: defaultTabKey,
    })
  }

  componentDidUpdate = (prevProps) => {
    const { defaultTabKey } = this.props

    if (defaultTabKey !== prevProps.defaultTabKey) {
      this.onTabChange(defaultTabKey)
    }
  }

  /**
   * @private
   *
   * @todo driver assignment
   *
   * Renders header and header details
   */
  renderHeader = () => {
    const {
      title, renderHeaderDetails, onSettingsClick, customHeading,
    } = this.props

    return (
      <div style={styles.header}>
        <div style={styles.headerTop}>
          <div style={{ display: 'flex', flexDirection: 'row', flexGrow: '1' }}>
            {customHeading || <h2 style={styles.alias}>{title}</h2>}
          </div>
          <div style={styles.buttonContainer}>
            {onSettingsClick ? <Button icon="setting" style={styles.button} onClick={onSettingsClick} /> : null}
            <Button icon="close" style={styles.button} onClick={this.closeContainer} />
          </div>
        </div>
        {renderHeaderDetails()}
      </div>
    )
  }

  onTabChange = (id) => {
    const { onTabChange } = this.props
    this.setState({
      currentTab: id,
    })
    // optional callback for tab change
    if (onTabChange) {
      onTabChange(id)
    }
  }

  closeContainer = () => {
    const { onClose, tabs, defaultTabKey } = this.props
    this.onTabChange(defaultTabKey || tabs.small[0].id)
    onClose()
  }

  /**
   * Displays all children if no keys. Displays selected child if keys
   */
  renderTabContent = () => {
    const { currentTab } = this.state
    const { children } = this.props

    if (Array.isArray(children)) {
      let child = null
      for (child in children) {
        if (children[child].key === currentTab) {
          return children[child]
        }
      }
    }

    return children
  }

  render() {
    const { headerMode, tabs, defaultTabKey } = this.props
    const { currentTab } = this.state
    if (headerMode === 'small') {
      return (
        <div style={styles.container}>
          <div style={styles.smallHeader}>
            <div style={styles.tabs}>
              <TabBar
                tabs={tabs.small}
                onSelect={this.onTabChange}
                activeKey={currentTab || defaultTabKey}
                tabWidth={100}
              />
            </div>
          </div>
          <div style={styles.tabContainer}>
            {this.renderTabContent()}
          </div>
        </div>
      )
    }

    return (
      <div style={styles.container}>
        <div style={styles.largeHeader}>
          {this.renderHeader()}
          <div style={styles.tabs}>
            <TabBar
              tabs={tabs.large}
              onSelect={this.onTabChange}
              activeKey={currentTab || defaultTabKey}
              tabWidth={100}
            />
          </div>
        </div>
        <div style={styles.tabContainer}>
          {this.renderTabContent()}
        </div>
      </div>
    )
  }
}

export default helper()(RightContainer)
