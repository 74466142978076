/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import landmark from '@assets/img/icons/nav/landmark.svg'
import landmarkSelected from '@assets/img/icons/nav/landmarkSelected.svg'
import device from '@assets/img/icons/nav/rhino.svg'
import deviceSelected from '@assets/img/icons/nav/rhinoSelected.svg'
import distance from '@assets/img/icons/nav/ruler.svg'
import distanceSelected from '@assets/img/icons/nav/rulerSelected.svg'

const styles = {
  container: {
    height: '36px',
    minHeight: '36px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: '#F9F9F9',
    borderColor: '#E9E9E9',
    borderWidth: 1,
    borderStyle: 'solid',
  },
  iconSelected: {
    height: '21px',
    marginVertical: '16px',
  },
  icon: {
    height: '21px',
    marginVertical: '16px',
    cursor: 'pointer',
  },
}

class IconNav extends Component {
  static propTypes = {
    selectedNav: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
  }

  static defaultProps = {
    selectedNav: 'map',
  }

  returnDevice = () => {
    const { selectedNav, onSelect } = this.props
    if (selectedNav === 'map') {
      return (<img src={deviceSelected} alt="deviceSelected" style={styles.iconSelected} />)
    }
    return (<img src={device} alt="device" style={styles.icon} onClick={() => onSelect('map')} />)
  }

  returnDistance = () => {
    const { selectedNav, onSelect } = this.props
    if (selectedNav === 'distance') {
      return (<img src={distanceSelected} alt="distanceSelected" style={styles.iconSelected} />)
    }
    return (<img src={distance} alt="distance" style={styles.icon} onClick={() => onSelect('distance')} />)
  }

  returnLandmark = () => {
    const { selectedNav, onSelect } = this.props
    if (selectedNav === 'landmarks') {
      return (<img src={landmarkSelected} alt="landmarkSelected" style={styles.iconSelected} />)
    }
    return (<img src={landmark} alt="landmark" style={styles.icon} onClick={() => onSelect('landmarks')} />)
  }


  render() {
    return (
      <div style={styles.container}>
        {this.returnDevice()}
        {this.returnDistance()}
        {this.returnLandmark()}
      </div>
    )
  }
}

export default IconNav
