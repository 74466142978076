import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Header from '@mol/leftPanel/header'
import IconNav from '@atom/iconNav'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    minHeight: 0,
    width: 240,
    backgroundColor: '#F9F9F9',
    borderColor: '#E9E9E9',
    borderWidth: 1,
    borderStyle: 'solid',
  },
  childContainer: {

  },
}

/**
 * Reusable LeftContainer
 */
export default class LeftContainer extends Component {
  static propTypes = {
    setNav: PropTypes.func.isRequired,
    saveContactMeta: PropTypes.func.isRequired,
    selectModal: PropTypes.func.isRequired,
    mainNav: PropTypes.string,
    deviceListMode: PropTypes.string,
    mapIconMode: PropTypes.string,
    mapIconSizeMode: PropTypes.string,
    renderHeaderDetails: PropTypes.func,
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
  }

  static defaultProps = {
    mainNav: 'map',
    deviceListMode: 'comfortable',
    mapIconMode: 'comfortable',
    mapIconSizeMode: 'large',
    renderHeaderDetails: () => {},
    children: {},
  }

  render() {
    const {
      mainNav,
      setNav,
      deviceListMode,
      renderHeaderDetails,
      children,
      selectModal,
      saveContactMeta,
      mapIconMode,
      mapIconSizeMode,
    } = this.props

    return (
      <div style={styles.container}>
        <Header
          mainNav={mainNav}
          deviceListMode={deviceListMode}
          mapIconMode={mapIconMode}
          mapIconSizeMode={mapIconSizeMode}
          saveContactMeta={saveContactMeta}
          renderHeaderDetails={renderHeaderDetails}
          selectModal={selectModal}
        />
        <div style={{
          flexShrink: 0,
          flexGrow: 1,
          flex: 1,
          marginTop: 8,
          position: 'relative',
          minHeight: 0,
          left: 0,
          right: 0,
          overflowX: 'hidden',
          overflowY: 'overlay',
        }}
        >
          {children}
        </div>
        <IconNav selectedNav={mainNav} onSelect={setNav} />
      </div>
    )
  }
}
