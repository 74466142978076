import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'antd'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}

export default class CheckboxOption extends Component {
  static propTypes = {
    checked: PropTypes.bool.isRequired,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    label: 'Option',
    value: 'Option',
    onChange: () => {},
  }

  onChange = (e) => {
    const { value, onChange } = this.props
    if (onChange) onChange(value, e.target.checked)
    // eslint-disable-next-line no-console
    else console.log(`${value} checked = ${e.target.checked}`)
  }

  render() {
    const { label, checked } = this.props
    return (
      <div style={styles.container}>
        <h4 style={{ fontWeight: 'normal' }}>{label}</h4>
        <Checkbox onChange={this.onChange} checked={checked} />
      </div>
    )
  }
}
