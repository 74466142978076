import React from 'react'
import { compose } from 'react-apollo'
import { sendMail } from '@graphql/mutation'
import mutationConnector from '@graphql/mutationConnector'

const userResourcesHOC = () => WrappedComponent => {
  class UserResourcesHOC extends React.Component {
    handleCreateTicket = (subject, email, phoneNumber, relatedFeatures, details) => {
      let topics = ''
      Object.keys(relatedFeatures).forEach((key) => {
        if (relatedFeatures[key] && key !== 'alert') {
          topics += key + ', '
        }
      })

      const body = `
        Customer Email: ${email} \n
        Phone Number: ${phoneNumber} \n
        Related Features: ${topics} \n
        Details: ${details}
      `

      return this.props.sendMail({
        variables: {
          subject: subject,
          body: body,
          to: 'customerservice@rhinofleettracking.com'
        }
      })
    }

    render(){
      return (
        <WrappedComponent
          handleCreateTicket={this.handleCreateTicket}
          {...this.props}
        />
      )
    }
  }

  return compose(
    mutationConnector(sendMail, 'sendMail')
  )(UserResourcesHOC)
}

export default userResourcesHOC;
