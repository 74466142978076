import React, { Component } from 'react'
import PropTypes from 'prop-types'
import comfortableSelected from '@assets/img/icons/toggle_comfortable_Selected.svg'
import comfortableUnselected from '@assets/img/icons/toggle_comfortable_Unselected.svg'
import cozySelected from '@assets/img/icons/toggle_cozy_Selected.svg'
import cozyUnselected from '@assets/img/icons/toggle_cozy_Unselected.svg'
import compactSelected from '@assets/img/icons/toggle_compact_Selected.svg'
import compactUnselected from '@assets/img/icons/toggle_compact_Unselected.svg'
import elipse from '@assets/img/icons/toggle_Elipse_Vertical.svg'

export default class DensityToggle extends Component {
    static propTypes = {
      saveContactMeta: PropTypes.func.isRequired,
      elipseClick: PropTypes.func,
      deviceListMode: PropTypes.string,
      mapIconMode: PropTypes.string,
      mapIconSizeMode: PropTypes.string,
    }

    static defaultProps = {
      deviceListMode: 'comfortable',
      mapIconMode: 'comfortable',
      mapIconSizeMode: 'large',
      elipseClick: null,
    }

    static state = {
      modeChanged: false,
    }

    handleSwitchMode = (mode) => {
      const {
        saveContactMeta, mapIconMode, mapIconSizeMode,
      } = this.props

      saveContactMeta(mode, mapIconMode, mapIconSizeMode)
    }

    render() {
      const { deviceListMode, elipseClick } = this.props
      return (
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          <label style={{ marginLeft: '2px', marginRight: '2px' }}>View: </label>
          <div tabIndex="-1" onClick={() => { this.handleSwitchMode('comfortable') }} role="button">
            <img
              src={deviceListMode === 'comfortable' ? comfortableSelected : comfortableUnselected}
              style={{ marginLeft: '2px', marginRight: '2px', cursor: 'pointer' }}
              alt="toggleIcon"
            />
          </div>
          <div tabIndex="-1" onClick={() => { this.handleSwitchMode('cozy') }} role="button">
            <img
              src={deviceListMode === 'cozy' ? cozySelected : cozyUnselected}
              style={{ marginLeft: '2px', marginRight: '3px', cursor: 'pointer' }}
              alt="toggleIcon"
            />
          </div>
          <div tabIndex="-1" onClick={() => { this.handleSwitchMode('compact') }} role="button">
            <img
              src={deviceListMode === 'compact' ? compactSelected : compactUnselected}
              style={{ marginLeft: '2px', marginRight: '2px', cursor: 'pointer' }}
              alt="toggleIcon"
            />
          </div>
          <div tabIndex="-1" onClick={elipseClick} role="button">
            <img
              src={elipse}
              style={{ marginLeft: '2px', marginRight: '2px', cursor: 'pointer' }}
              alt="toggleIcon"
            />
          </div>
        </span>
      )
    }
}
