import React, { Component } from 'react'
import PropTypes from 'prop-types'

const styles = {
  outerWrapper: {
    height: '100%',
    width: '100%',
    padding: 5,
    flexShrink: 0,
    flexGrow: 1,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: '#FFF',
    padding: '10px',
    overflow: 'hidden',
  },
  headingContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%',
    marginTop: '8px',
    marginBottom: '10px',
  },
}

/**
 * @description This component creates a padded container with a white background. These Cards
 * are made to fill thier parent container 100%.
 */
export default class Card extends Component {
  static propTypes = {
    title: PropTypes.string,
    titleSize: PropTypes.string,
    postTitle: PropTypes.object,
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    contentWrapper: PropTypes.object,
  }

  static defaultProps = {
    title: null,
    titleSize: 'middle', // small, middle, large
    postTitle: null,
    children: null,
    contentWrapper: {
      flexGrow: 1,
      margin: '0px 8px 8px 8px',
      overflow: 'hidden',
    },
  }

  /**
   * @private
   * @returns {Component}
   * @description Returns appropriate heading size given titleSize prop
   */
  returnTitle = () => {
    const { title, titleSize } = this.props
    if (title) {
      switch (titleSize) {
        case 'small':
          return (<h3 style={{ margin: '0px 10px 0px 8px' }}>{title}</h3>)
        case 'large':
          return (<h1 style={{ margin: '0px 10px 0px 8px' }}>{title}</h1>)
        default:
          return (<h2 style={{ margin: '0px 10px 0px 8px' }}>{title}</h2>)
      }
    } return null
  }

  render() {
    const {
      title, postTitle, children, contentWrapper,
    } = this.props
    return (
      <div style={styles.outerWrapper}>
        <div style={styles.card}>
          {!title ? null : (
            <div style={styles.headingContainer}>
              {this.returnTitle()}
              {postTitle || null}
            </div>
          )}
          <div style={contentWrapper}>
            {children}
          </div>
        </div>
      </div>
    )
  }
}
