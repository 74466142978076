import React, { Component } from 'react'
import en from '../../locale/en'
import sp from '../../locale/sp'

export const tests = {
  /**
   * Compare first level objects / functions
   *
   * @param {*} a locale file of first language
   * @param {*} b locale file of second language
   * @param {[string]} options ['print', 'return']
   *
   * @returns {string|null} if options includes 'return' and there is a mismatch,
   *                        this will return a string that represents an error
   */
  compareFirstLevel: (a, b, options) => {
    let didPrint = false
    for (const aKey in a) {
      if (Object.prototype.hasOwnProperty.call(a, aKey)) {
        let match = false
        for (const bKey in b) {
          if (Object.prototype.hasOwnProperty.call(b, bKey)) {
            if (aKey === bKey) {
              match = true
              break
            }
          }
        }
        if (options.includes('print') && !match) {
          didPrint = true
          console.log(`%c ${a} {${aKey}} key does not exist in ${b}`, 'color:#ffa500')
        }
      }
    }

    if (options.includes('return') && didPrint) return `ERROR: ${a} first level keys do not match`
    return null
  },
  /**
   * Compare language dictionaries
   *
   * @param {*} a locale file of first language
   * @param {*} b locale file of second language
   * @param {[string]} options ['printKey', 'printValue', 'return']
   *
   * @returns {string|null} if options includes 'return' and there is a mismatch,
   *                        this will return a string that represents an error
   */
  compareDictionaries: (a, b, options) => {
    let didPrint = false
    for (const aKey in a.dictionary) {
      if (Object.prototype.hasOwnProperty.call(en.dictionary, aKey)) {
        let keyMatch = false
        let valueMatch = false
        for (const bKey in b.dictionary) {
          if (Object.prototype.hasOwnProperty.call(sp.dictionary, bKey)) {
            if (aKey === bKey) {
              keyMatch = true
              if (en.dictionary[aKey] === sp.dictionary[bKey]) valueMatch = true
              break
            }
          }
        }

        if (!keyMatch) {
          didPrint = true
          if (options.includes('printKey')) console.log(`%c English dictionary {${aKey}} key does not exist in Spanish dictionary`, 'color:#ffa500')
        }

        // This one is more optional because some words are the same in both languages
        if (options.includes('printValue') && valueMatch) {
          didPrint = true
          console.log(`%c English dictionary {${aKey}}'s value should not equal to the Spanish version`, 'color:#ffa500')
        }
      }
    }

    if (options.includes('return') && didPrint) return 'ERROR: There\'s something wrong with the dictionaries. Use options \'printKey\' to know more information'
    return null
  },
}

/**
 * @private checks keys from en and sp files to find which ones are missing and are mismatched
 *
 * @param {string} output 'console'
 */
const test = () => {
  // 1. Check if first level objects / functions
  // Compare en to sp
  tests.compareFirstLevel(en, sp, ['print'])
  // Compare sp to en
  tests.compareFirstLevel(sp, en, ['print'])

  // 2) Check dictionary keys and values
  // Compare en to sp
  // tests.compareDictionaries(en, sp, ['printKey', 'printValue'])
  tests.compareDictionaries(sp, en, ['printKey'])
}

if (process.env.NODE_ENV !== 'test') test()

export const isValidPhoneNumber = (phoneNumber) => {
  const reg = /^\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
  const chadReg = /^\d{2}[\s.-]?\d{2}[\s.-]?\d{2}[\s.-]?\d{2}$/
  return reg.test(phoneNumber.toLowerCase()) || chadReg.test(phoneNumber.toLowerCase())
}

export const isValidEmail = (emailStr) => {
  const reg = /^([A-Za-z0-9-_.])+@([A-Za-z0-9-_.])+\.([A-Za-z]{2,})$/
  return reg.test(emailStr.toLowerCase())
}

const helper = () => (WrappedComponent) => {
  const initLanguage = () => {
    if (['EN', 'SP'].indexOf(localStorage.getItem('rft-language')) > -1) {
      return localStorage.getItem('rft-language')
    }
    return 'EN'
  }

  const initMeasure = () => {
    if (['m', 'k'].indexOf(localStorage.getItem('rft-measurement')) > -1) {
      return localStorage.getItem('rft-measurement')
    }
    return 'm'
  }


  class Helper extends Component {
    state = {
      preference: {
        language: initLanguage(),
        distanceMeasurement: initMeasure(),
        timeZoneId: localStorage.getItem('rft-timeZoneId') || 1, // 1,
        mapRefresh: localStorage.getItem('rft-mapRefresh') || 600, // 600,
      },
      permission: {
        needInit: true,
        caps: null,
      },
      features: null,
    }

    // rename of the componentWillMount since react 16.3
    UNSAFE_componentWillMount = () => {
      this.loadCapabilities()
      this.loadFeatures()
    }

    /** check if the user is on a mobile device */
    checkIfMobileDevice = () => {
      let isMobile = false // initiate as false
      // device detection
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        isMobile = true
      }
      return isMobile
    }

    /* helper functions for updating the class prop */
    switchLanguage = (newLanguage) => {
      localStorage.setItem('rft-language', newLanguage)
      const { preference } = this.state
      preference.language = newLanguage
      this.setState({ preference })
    }

    switchDistanceMeasurement = (newDistanceMeasurement) => {
      localStorage.setItem('rft-measurement', newDistanceMeasurement)
      const { preference } = this.state
      preference.distanceMeasurement = newDistanceMeasurement
      this.setState({ preference })
    }

    switchTimeZoneId = (timeZoneId) => {
      localStorage.setItem('rft-timeZoneId', timeZoneId)
      const { preference } = this.state
      preference.timeZoneId = timeZoneId
      this.setState({ preference })
    }

    switchMapRefresh = (newMapRefresh) => {
      localStorage.setItem('rft-mapRefresh', newMapRefresh)
      const { preference } = this.state
      preference.mapRefresh = newMapRefresh
      this.setState({ preference })
    }

    loadPermission = () => {}

    getLanguage = () => {
      const { preference } = this.state
      return preference.language
    }

    getMeasurement = () => {
      const { preference } = this.state
      return preference.distanceMeasurement
    }

    /**
     * @description
     * loads capabilities from local storage and adds each capability to
     * this.state.permission.caps
     */
    loadCapabilities = () => {
      const { permission } = this.state
      if (permission.needInit) {
        if (localStorage.getItem('rft-roleToCap') === null) {
          // refetch to store in local?
        }
        // get array of capabilities
        const capArray = JSON.parse(localStorage.getItem('rft-roleToCap')) // error for incorrect parse
        if (capArray === null) {
          return null
        }
        // add capabilities by name to tempObject
        const capObj = {}
        for (let i = 0; i < capArray.length; i += 1) {
          capObj[capArray[i].capability] = capArray[i]
        }
        // adds capabilities by capability name into permissions.caps
        const updatedPermissions = {
          needInit: false,
          caps: capObj,
        }
        this.setState({ permission: updatedPermissions })
      }
      return true
    }

    /**
     * @description
     * loads features from local storage and adds each active/available feature for this user to
     * this.state.features
     */
    loadFeatures = () => {
      const { features } = this.state
      if (!features) {
        // get array of features
        try {
          let featureArray = JSON.parse(localStorage.getItem('rft-features')) // error for incorrect parse
          if (featureArray !== null) {
            featureArray = featureArray.filter(feature => feature.isActive)
              .map((feature => feature.name))
          }
          this.setState({ features: featureArray })
        } catch {
          this.setState({ features: [] })
        }
      }
    }

    /**
     * Returns Boolean based on whether the user has access to a feature.
     * @param {String} featureName String referring to feature name
     */
    hasFeature = (featureName) => {
      const { features } = this.state
      if (features && features.includes(featureName)) {
        return true
      }
      return false
    }

    /**
     * @description
     * - returns true if user has permission for specific action
     * - returns false if user does not
     * - can also return all create, delete, update, read permissions for
     * a specific capability as object
     */
    getPermission = (capName, optionalAction) => {
      const { permission } = this.state
      // load will only run once (returns when it has already been ran)
      this.loadCapabilities()
      if (capName === null || permission.caps === null) {
        return false
      }
      if (optionalAction != null) {
        if (permission.caps[capName]) {
          return permission.caps[capName][optionalAction]
        }
        return false
      }

      return permission.caps[capName]
    }

    /* helper functions for language */
    wMatrix = (key) => {
      const { preference } = this.state
      let word = ''
      switch (preference.language) {
        case 'EN':
          word = en.dictionary[key]
          break
        case 'SP':
          word = sp.dictionary[key]
          break
        default:
          /* Default to EN */
          word = en.dictionary[key]
      }
      if (!word && key) {
        word = key
        console.log(`%c No word for key {${key}} in ${preference.language}
        dictionary`, 'color:#ffa500')
      }
      return word
    }

    /* helper functions for string formatting and validating */

    formatVehicleStatus = (device) => {
      if (device.status.state === 'stopped') {
        return this.convertTime(device.status.stoppedMinutes)
      }
      if (device.status.state === 'moving') {
        return `${device.locate.speed} mph`
      }
      return null
    }

    convertTime = (minDiff) => {
      const day = Math.floor(minDiff / 1440)
      const hour = Math.floor((minDiff - day * 1440) / 60)
      const min = minDiff - day * 1440 - hour * 60
      if (day === 0) {
        if (hour === 0) {
          return `${min} m`
        }
        return `${hour} h ${min} m`
      }
      if (day > 9) {
        return `${day} days +`
      }
      return `${day}d ${hour}h ${min}m`
    }

    trim = (str, length) => {
      if (str.length > length + 3) {
        return `${str.substring(0, length)}...`
      }
      return str
    }

    getLanguageFullName = (languageAbbreviation) => {
      switch (languageAbbreviation) {
        case 'EN':
          return this.wMatrix('English')
        case 'SP':
          return this.wMatrix('Spanish')
        /* By default use English */
        default:
          return this.wMatrix('English')
      }
    }

    getMeasurementFullName = (measurementAbbreviation) => {
      switch (measurementAbbreviation) {
        case 'm':
          return this.wMatrix('Miles')
        case 'k':
          return this.wMatrix('Kilometers')
        /* By default use Miles */
        default:
          return this.wMatrix('Miles')
      }
    }

    /**
     * Validates email format using simple regex.
     * @param {String} emailStr email string
     * Note: this only allows emails with letters,
     *  dashes, underscores, and periods
     */
    validateEmail = (emailStr) => {
      if (isValidEmail(emailStr)) {
        return {
          valid: true,
          help: '',
          validateStatus: 'success',
        }
      }
      return {
        valid: false,
        help: this.wMatrix('Invalid email'),
        validateStatus: 'error',
      }
    }

    /**
     * Uses above validation to test comma separated emails.
     * @param {String} emailStr email string that can include multiples separated by a comma
     * Note: this only allows emails with letters,
     *  dashes, underscores, and periods
     */
    validateMultipleEmails = (emailsStr) => {
      const emailsArray = emailsStr.split(',')
      const trimmedEmails = emailsArray.map(email => email.trim())
      for (let i = 0; i < trimmedEmails.length; i += 1) {
        if (!this.validateEmail(trimmedEmails[i]).valid) return false
      }
      return true
    }

    validatePassword = (password, passwordConfirm) => {
      if (password === '') {
        return {
          valid: false,
          help: this.wMatrix('This field is required'),
          validateStatus: 'error',
        }
      }
      if (password !== passwordConfirm) {
        return {
          valid: false,
          help: this.wMatrix('Fields must match'),
          validateStatus: 'error',
        }
      }
      return {
        valid: true,
        help: '',
        validateStatus: 'success',
      }
    }

    validateName = (name) => {
      const reg = /^([a-zA-Z]+[,.]?[ ]?|[a-zA-Z]+['-]?)+$/
      if (reg.test(name.toLowerCase())) {
        return {
          valid: true,
          help: '',
          validateStatus: 'success',
        }
      }
      return {
        valid: false,
        help: this.wMatrix('Invalid name'),
        validateStatus: 'error',
      }
    }

    validatePhone = (phone) => {
      if (isValidPhoneNumber(phone)) {
        return {
          valid: true,
          help: '',
          validateStatus: 'success',
        }
      }
      return {
        valid: false,
        help: this.wMatrix('Invalid phone number'),
        validateStatus: 'error',
      }
    }

    /**
     * @public
     *
     * converts string to title case
     *
     * @example "san diego" => "San Diego"
     */
    toTitleCase = str => str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())

    /**
     * @public
     *
     * @todo incorporate different languages
     *
     * Converts minutes to words
     *
     * @example 68 minutes --> 1 hr 8m mins
     *
     * @param {number} item minutes
     * @param {number} maxDays *OPTIONAL* max days to show '+' if item is greater
     */
    minutesToString = (item, maxDays, multipleValues = false, highestUnit = 'days') => {
      const number = Number(item)
      if (number === 0) {
        return '-'
      }
      if (number === null) {
        return ''
      }
      let days = 0
      if (highestUnit === 'days') days = Math.floor(number / 24 / 60)
      let hours = Math.floor(number / 60)
      if (highestUnit === 'days') hours = Math.floor(number / 60) % 24
      const minutes = number % 60
      let daysText = ''
      let hoursText = ''
      let minutesText = ''

      if (multipleValues) {
        if (days === 1) daysText = `${days} ${this.wMatrix('day')}`
        if (days > 1) daysText = `${maxDays && days > maxDays ? `${maxDays}+` : days} ${this.wMatrix('days')}`
        if (hours === 1) hoursText = `${hours} hr`
        if (hours > 1) hoursText = `${hours} hrs`
        if (minutes === 1) minutesText = `${minutes} min`
        if (minutes > 1) minutesText = `${minutes} mins`
      } else if (!multipleValues) {
        if (days === 1) daysText = `${days} ${this.wMatrix('day')}`
        else if (days > 1) daysText = `${maxDays && days > maxDays ? `${maxDays}+` : days} ${this.wMatrix('days')}`
        else if (hours === 1) hoursText = `${hours} hr`
        else if (hours > 1) hoursText = `${hours} hrs`
        else if (minutes === 1) minutesText = `${minutes} min`
        else if (minutes > 1) minutesText = `${minutes} mins`
      }
      return `${daysText} ${hoursText} ${minutesText}`
    }

    /**
     * Converts minutes to words
     *
     * @param {Number} duration duration in minutes
     * @param {Array} units Array of units to display ['d','h','m']
     *
     * @todo
     *  - add maxUnitValues (like max days)
     *  - replace above function (minutesToString) and make sure all instances work
     */
    minutesToStringV2 = (duration, units = ['d', 'h', 'm']) => {
      const totalMinutes = Number(duration)
      // to handle negative values
      const roundTime = number => (totalMinutes > 0 ? Math.floor(number) : Math.ceil(number))
      // check zero case
      if (roundTime(totalMinutes) === 0) {
        return '-'
      }
      let days = 0
      let hours = 0
      let minutes = 0
      // days
      if (units.includes('d')) {
        days = roundTime(totalMinutes / 24 / 60)
      }
      // hours
      if (units.includes('h')) {
        if (units.includes('d')) {
          // hours left after days
          hours = roundTime(totalMinutes / 60) % 24
        } else {
          // all in hours
          hours = roundTime(totalMinutes / 60)
        }
      }
      // minutes
      if (units.includes('m')) {
        if (units.includes('h')) {
          // mod on hrs
          minutes = roundTime(totalMinutes % 60)
        } else if (units.includes('d')) {
          // if includes days but not hours
          minutes = roundTime(totalMinutes % (60 * 24))
        } else minutes = roundTime(totalMinutes)
      }
      let daysText = ''
      let hoursText = ''
      let minutesText = ''
      if (days !== 0) {
        daysText = days === 1 || days === -1 ? `${days} ${this.wMatrix('day')}` : `${days} ${this.wMatrix('days')}`
      }
      if (hours !== 0) {
        hoursText = hours === 1 || hours === -1 ? `${hours} hr` : `${hours} hrs`
      }
      if (minutes !== 0) {
        minutesText = minutes === 1 || minutes === -1 ? `${minutes} min` : `${minutes} mins`
      }
      return `${daysText} ${hoursText} ${minutesText}`
    }

    locatedXAgo = (value) => {
      const { preference } = this.state
      switch (preference.language) {
        case 'SP':
          return `Localizado hace ${value}`
        default:
          /* Default to EN */
          return `Located ${value} ago`
      }
    }

    /**
     * @public
     *
     * Coverts mph to speed conversion + string
     *
     * @example 60 --> 60 mph OR 96.56 kph
     *
     * @param {number} mph speed
     */
    speedToString = (mph) => {
      const { preference: { distanceMeasurement } } = this.state

      if (distanceMeasurement === 'm') return `${Math.round(Number(mph))} mph`

      return `${Math.round(Number(mph) * 1.609)} kph`
    }

    /**
     * @public
     *
     * Coverts miles to distance conversion + string
     *
     * @example 60 --> 60 miles OR 96.56 kilometers
     *
     * @param {number} miles distance
     */
    distanceToString = (miles) => {
      const { preference: { distanceMeasurement } } = this.state

      if (distanceMeasurement === 'm') return `${Math.round(Number(miles))} mi`

      return `${Math.round(Number(miles) * 1.609)} km`
    }

    /**
     * @public
     *
     * Converts database style event name to User facing string (wMatrix style String)
     *
     * @param {string} event event name in snake case
     */
    eventToString = (event, verbose) => {
      if (!event) return ''
      const eventArray = event.split('_')
      for (let i = 0; i < eventArray.length; i += 1) {
        // first word stays the same
        if (i !== 0) {
          if (!verbose && eventArray.includes('idle') && (eventArray[i] === 'end' || eventArray[i] === 'begin')) {
            // eventArray[i] = null
            eventArray[i] = eventArray[i].charAt(0).toUpperCase() + eventArray[i].slice(1)
          } else {
            // first character uppercased + rest of string
            eventArray[i] = eventArray[i].charAt(0).toUpperCase() + eventArray[i].slice(1)
          }
        }
      }
      const eventString = eventArray.join('')
      return this.wMatrix(eventString)
    }

    /**
     * Simply converts an address object sent from database or alerts to a single string.
     * @param {Object} address Address object
     * @returns {String}
     */
    addressToString = address => (
      `${address.street ? address.street.trim() : ''} ${address.city || ''}${address.city ? ', ' : ''}${address.state || ''}`
    )

    /**
     * @public
     *
     * Returns interpolated color string based on percentage given and 2-3 colors. Defaults to
     * white if not enough arguments passed.
     *
     * @argument {Number} percentage - float between {0,1}
     * @argument {[Number]} color1 - Color at 100%. rgb in form [r,g,b].
     * @argument {[Number]} color2 - Color at 50% (0% if color3 is undefined). rgb in form [r,g,b].
     * @argument {[Number]?} color3 - (Optional) If defined, color at 100%. rgb in form [r,g,b].
     *
     * @returns {String} - string of form 'rgb(r,g,b)'
     */
    interpolateColors = (percentage, color1, color2, color3) => {
      let correctedPercentage = percentage
      // Make sure percentage is valid
      if (correctedPercentage > 1) correctedPercentage = 1
      if (correctedPercentage < 0) correctedPercentage = 0
      const weight = correctedPercentage
      const weightInv = 1 - correctedPercentage
      let r = 0
      let g = 0
      let b = 0
      // if required variables are not passed, return white
      if (percentage === null || color1 === null || color2 === null) {
        return '#FFF'
      }
      if (percentage > 0.5 || color3 === null || color3 === undefined) {
        r = Math.round(color1[0] * weight + color2[0] * weightInv)
        g = Math.round(color1[1] * weight + color2[1] * weightInv)
        b = Math.round(color1[2] * weight + color2[2] * weightInv)
      } else {
        r = Math.round(color2[0] * weight + color3[0] * weightInv)
        g = Math.round(color2[1] * weight + color3[1] * weightInv)
        b = Math.round(color2[2] * weight + color3[2] * weightInv)
      }

      const colorString = `rgb(${r},${g},${b})`
      return colorString
    }

    /**
     * @public
     *
     * Returns an array of rgb strings given serveral optional parameters.
     * - No Parameters - parameters are passed, returns an array of 7 rgb colors (Red, Yellow,
     * Green, Cyan, Blue, Pink, Red).
     *
     * @param {number} totalColors - number of desired colors in array
     * @param {number} floor - floor value of any given rgb value (this is the white balance)
     *  - only effects the colors if no baseColors are provided
     * @param {number} ceiling - ceiling value of any given rgb value (this is the black balance)
     *  - only effects the colors if no baseColors are provided
     * @param {[[int]]} baseColors - Array of color arrays of form [[r1,g1,b1],[r2,g2,b2],...]
     * @returns {[String]}
     */
    createColorsArray = (totalColors, floor, ceiling, baseColors) => {
      let floorVal = 0
      let ceilVal = 255
      // check and correct floor and ceiling values
      if (ceiling > 255 || !ceiling) {
        ceilVal = 255
      }
      if (ceiling < 125) {
        ceilVal = 125
      }
      if (floor < 0 || !floor) {
        floorVal = 0
      }
      if (floor > 124) {
        floorVal = 124
      }
      // create base colors that span the color spectrum
      const colors = baseColors || [
        [ceilVal, floorVal, floorVal], // Red
        [ceilVal, ceilVal, floorVal], // Yellow
        [floorVal, ceilVal, floorVal], // Green
        [floorVal, ceilVal, ceilVal], // Cyan
        [floorVal, floorVal, ceilVal], // Blue
        [ceilVal, floorVal, ceilVal], // Pink
        [ceilVal, floorVal, floorVal], // Red
      ]
      // if no special inputs, just return the standard colors
      if ((!totalColors) || (totalColors <= colors.length)) {
        return colors.map(color => `rgb(${color[0]},${color[1]},${color[2]})`)
      }
      const finalColors = []
      const colorsPerWindow = Math.floor(totalColors / (colors.length - 1))
      let index = 0
      while (finalColors.length < totalColors && index < (colors.length - 1)) {
        finalColors.push(`rgb(${colors[index][0]},${colors[index][1]},${colors[index][2]})`)
        // iterate
        for (let i = 1; i <= colorsPerWindow; i += 1) {
          const percentage = ((i) / (colorsPerWindow + 1))
          const newColor = this.interpolateColors(percentage, colors[index], colors[index + 1])
          finalColors.push(newColor)
        }
        index += 1
      }
      return finalColors
    }


    formatAddress = (address) => {
      const defaultAddress = {
        street: null,
        zip: null,
        city: null,
        state: null,
        country: null,
      }
      if (address === null || address === '{}' || address === '') {
        return defaultAddress
      }
      try {
        const {
          // eslint-disable-next-line camelcase
          house_number, street, postal_code, locality, region,
        } = address
        const fa = {
          // eslint-disable-next-line camelcase
          street_address: `${house_number && house_number !== '-1' ? house_number : ''}${house_number && house_number !== '-1' ? ' ' : ''}${street && street !== '-1' ? street : ''}`,
          // eslint-disable-next-line camelcase
          street: `${house_number && house_number !== '-1' ? house_number : ''}${house_number && house_number !== '-1' ? ' ' : ''}${street && street !== '-1' ? street : ''}`,
          // eslint-disable-next-line camelcase
          zip: postal_code && postal_code !== '-1' ? postal_code : null,
          city: locality && locality !== '-1' ? locality : null,
          state: region && region !== '-1' ? region : null,
        }
        if (!fa.street_address && !fa.city && !fa.state && !fa.zip) {
          fa.full = 'No Address'
        }
        fa.full = `${fa.street_address || ''}${fa.street_address ? ' ' : ''}${fa.city || ''}${fa.city && fa.state ? ', ' : ''}${fa.state || ''} ${fa.zip || ''}`
        return fa
      } catch (err) {
        return defaultAddress
      }
    }

    render = () => {
      const { preference } = this.state
      return (
        <WrappedComponent
          checkIfMobileDevice={this.checkIfMobileDevice}
          switchLanguage={this.switchLanguage}
          switchDistanceMeasurement={this.switchDistanceMeasurement}
          switchMapRefresh={this.switchMapRefresh}
          switchTimeZoneId={this.switchTimeZoneId}
          loadPermission={this.loadPermission}
          getLanguage={this.getLanguage}
          getMeasurement={this.getMeasurement}
          getMapIconSize={this.getMapIconSize}
          timeZoneId={preference.timeZoneId}
          mapRefresh={preference.mapRefresh}
          loadCapabilities={this.loadCapabilities}
          getPermission={this.getPermission}
          hasFeature={this.hasFeature}
          wMatrix={this.wMatrix}
          eventToString={this.eventToString}
          formatVehicleStatus={this.formatVehicleStatus}
          trim={this.trim}
          getLanguageFullName={this.getLanguageFullName}
          getMeasurementFullName={this.getMeasurementFullName}
          validateEmail={this.validateEmail}
          validateMultipleEmails={this.validateMultipleEmails}
          validatePassword={this.validatePassword}
          validateName={this.validateName}
          validatePhone={this.validatePhone}
          language={preference.language}
          toTitleCase={this.toTitleCase}
          minutesToString={this.minutesToString}
          minutesToStringV2={this.minutesToStringV2}
          speedToString={this.speedToString}
          distanceToString={this.distanceToString}
          addressToString={this.addressToString}
          locatedXAgo={this.locatedXAgo}
          interpolateColors={this.interpolateColors}
          createColorsArray={this.createColorsArray}
          formatAddress={this.formatAddress}
          {...this.props}
        />
      )
    }
  }

  return Helper
}

export default helper
