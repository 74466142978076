import React, { Component } from 'react'
import PropTypes from 'prop-types'

const styles = {
  animation: {
    transition: 'all 0.5s ease-out',
  },
  container: {
    padding: '6px 16px',
    fontSize: '14px',
    cursor: 'pointer',
    textAlign: 'center',
  },
}

class TabItem extends Component {
  static propTypes = {
    selectedKey: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    value: PropTypes.string,
    /**
     * Use this if you want to declare a width. If not, it will just grow to the text.
     */
    width: PropTypes.number,
  }

  static defaultProps = {
    value: 'header',
    width: null,
  }

  render() {
    const {
      value, selectedKey, id, onSelect, width,
    } = this.props
    return (
      <div
        role="button"
        tabIndex="-1"
        style={{
          // ...styles.animation,
          ...styles.container,
          width: width || null,
          color: selectedKey === id ? '#4482FF' : 'rgba(0,0,0,0.65)',
        }}
        onClick={() => onSelect(id)}
      >
        {value}
      </div>
    )
  }
}

export default TabItem
