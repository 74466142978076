import React from 'react'
import PropTypes from 'prop-types'
import Logistics from '@page/main/organisms/logisticsView'
import helper from '@helper'
import FullScreenContainer from './fullScreen.container'

class LogisticsContainer extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    appHeight: PropTypes.number.isRequired,
    devices: PropTypes.array,
    openMessenger: PropTypes.func,
    landmarks: PropTypes.array,
  }

  static defaultProps = {
    devices: null,
    openMessenger: null,
    landmarks: [],
  }

  /**
   * Roughly:
   *  111px above content
   *
   *  39px below content
   */
  render() {
    const {
      wMatrix, devices, appHeight, openMessenger, landmarks,
    } = this.props

    return (
      <FullScreenContainer
        heading={wMatrix('field-service')}
        filled
        devices={devices}
      >
        <Logistics
          wMatrix={wMatrix}
          devices={devices}
          appHeight={appHeight}
          openMessenger={openMessenger}
          landmarks={landmarks}
        />
      </FullScreenContainer>
    )
  }
}

export default helper()(LogisticsContainer)
