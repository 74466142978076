import React, { Component } from 'react'
import { compose } from 'react-apollo'
import { logisticsMessageQuery } from '@graphql/query'
import {
  createLogisticsMessageMutation,
  markMessagesAsReadMutation,
} from '@graphql/mutation'
import consumerConnector from '@graphql/consumerConnector'
import mutationConnector from '@graphql/mutationConnector'
import PropTypes from 'prop-types'
import helper from '@helper'

/**
 * @NOTE The active messengers array is currently moreso used as a reference
 * rather than a state, but for the purposes of the messenger works well.
 */
const messengerHOC = () => (WrappedComponent) => {
  class MessengerHOC extends Component {
    static propTypes = {
      wMatrix: PropTypes.func.isRequired,
      createMessage: PropTypes.func.isRequired,
      markMessagesAsRead: PropTypes.func.isRequired,
      apolloClient: PropTypes.object.isRequired,
    }

    constructor(props) {
      super(props)
      this.state = {
        activeMessengers: [],
      }
    }

    /**
     * Adds the messenger to the active messengers array state to be
     * displayed. Currently, a limitter of 1 messenger is in place, but can still handle multiple.
     * @param {Objecy} device Device Object including device id, alias, driver, etc. Note that
     * only the id, alias, and driver are used
     */
    openMessenger = (device) => {
      const { activeMessengers } = this.state
      const activeMessengersUpdate = activeMessengers
      if (activeMessengersUpdate.length < 1) {
        activeMessengersUpdate.push({
          id: device.id,
          deviceId: device.id,
          alias: device.alias,
          driver: device.currentDriver,
          // loading state
          loading: true,
          messages: [],
        })
        this.setState({ activeMessengers: activeMessengersUpdate })
        this.refetchMessages(device.id)
      }
    }

    /**
     * Closes the designated messenger given a device ID by removing the associated
     * messenger from the Active messenger array
     * @param {Number} deviceId Device Id of messenger you want to close
     */
    closeMessenger = (deviceId) => {
      const { activeMessengers } = this.state
      let activeMessengersUpdate = activeMessengers
      activeMessengersUpdate = activeMessengersUpdate.filter(device => device.id !== deviceId)
      this.setState({ activeMessengers: activeMessengersUpdate })
    }

    /**
     * Sends Message based on device id, given the message content
     * @param {Number} deviceId Device ID
     * @param {String} message Message to be sent
     */
    sendMessage = async (deviceId, message) => {
      try {
        // createLogisticsMessageMutation
        const { createMessage } = this.props
        await createMessage({
          variables: {
            deviceIds: [deviceId],
            content: message,
            origin: 'WEBAPP',
          },
        })
        await this.refetchMessages(deviceId)
      } catch (err) {
        /** @TODO add code to handle errors? maybe display alert? */
        console.log(err)
      }
    }

    /**
     * Marks messages read by user that is logged in
     * @param {Number} deviceId Device Id associated with messages
     * @param {[Number]} messageIds Array of message id's
     */
    markMessagesRead = async (deviceId, messageIds) => {
      const { markMessagesAsRead } = this.props
      if (messageIds && messageIds.length > 0) {
        try {
          await markMessagesAsRead({
            variables: {
              messageIds,
            },
          })
          await this.refetchMessages(deviceId)
        } catch (err) {
          /** @TODO add code to handle errors? maybe display alert? */
          console.log(err)
        }
      }
    }

    /**
     * Queries Messages given a device id
     * @param {Number} deviceId Device Id
     */
    refetchMessages = async (deviceId) => {
      const { apolloClient } = this.props
      const { activeMessengers } = this.state
      try {
        // refetch call for specific device
        const messageResponse = await apolloClient.query({
          query: logisticsMessageQuery,
          fetchPolicy: 'network-only',
          variables: {
            deviceId,
          },
        })
        // then update activeMessengers Array with new messages
        if (messageResponse.data && messageResponse.data.logisticsMessage) {
          const activeMessengersClone = JSON.parse(JSON.stringify(activeMessengers))
          for (let i = 0; i < activeMessengersClone.length; i += 1) {
            if (activeMessengersClone[i].id === deviceId) {
              activeMessengersClone[i].messages = messageResponse.data.logisticsMessage
              activeMessengersClone[i].loading = false
              break
            }
          }
          this.setState({ activeMessengers: activeMessengersClone })
        }
      } catch (err) {
        /** @TODO add code to handle errors? maybe display alert? */
        console.log(err)
      }
    }

    render() {
      const { activeMessengers } = this.state
      return (
        <WrappedComponent
          activeMessengers={activeMessengers}
          openMessenger={this.openMessenger}
          closeMessenger={this.closeMessenger}
          sendMessage={this.sendMessage}
          markMessagesRead={this.markMessagesRead}
          {...this.props}
        />
      )
    }
  }
  return compose(
    mutationConnector(createLogisticsMessageMutation, 'createMessage'),
    mutationConnector(markMessagesAsReadMutation, 'markMessagesAsRead'),
    consumerConnector(),
    helper(),
  )(MessengerHOC)
}

export default messengerHOC
