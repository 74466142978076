import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Input, Table } from 'antd'
import greenBadge from '@assets/img/icons/rankBadgeGreen.svg'
import yellowBadge from '@assets/img/icons/rankBadgeYellow.svg'
import redBadge from '@assets/img/icons/rankBadgeRed.svg'
import grayBadge from '@assets/img/icons/rankBadgeGray.svg'

export default class DSCTable extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    drivers: PropTypes.array,
    multipleWeeks: PropTypes.bool.isRequired,
    view: PropTypes.string.isRequired,
  }

  static defaultProps = {
    drivers: [],
  }

  state = {
    drivers: [],
    filteredName: '',
  }

  componentDidMount() {
    const { drivers } = this.props
    this.setState({ drivers })
  }

  componentDidUpdate(prevProps) {
    const { drivers } = this.props
    const prevString = JSON.stringify(prevProps.drivers)
    const currString = JSON.stringify(drivers)
    if (prevString !== currString) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ drivers, filteredName: '' })
    }
  }

  renderRank = (rank, performance) => {
    let image = null
    let text = rank
    switch (performance) {
      case 'fair':
        image = yellowBadge
        break
      case 'poor':
        image = redBadge
        break
      default:
        image = greenBadge
        break
    }
    if (rank === 0) {
      image = grayBadge
      text = 'N/A'
    }
    return (
      <div style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <img src={image} alt="good" style={{ height: '90%', objectFit: 'contain' }} />
        <h4 style={{ color: 'white', position: 'absolute' }}>{text}</h4>
      </div>
    )
  }

  renderFilteredNameInput = () => {
    const { filteredName } = this.state
    const { drivers, wMatrix } = this.props
    return (
      <Input
        style={{
          alignSelf: 'flex-end',
          width: 150,
          margin: '10px 0',
          flexShrink: 0,
        }}
        size="small"
        placeholder={wMatrix('searchDriverName')}
        value={filteredName}
        onChange={(e) => {
          if (!drivers || drivers.length < 1) return
          this.setState({ filteredName: e.target.value })
          const filteredDrivers = drivers.filter((d) => {
            const lowerName = d.name.toLowerCase()
            const lowerValue = e.target.value.toLowerCase()
            return lowerName.includes(lowerValue)
          })
          this.setState({ drivers: filteredDrivers })
        }}
      />
    )
  }

  renderColumns = () => {
    const { wMatrix, drivers } = this.props
    if (drivers && drivers[0]) {
      if (drivers[0].dateSegments.length > 1) {
        // calculate Rank Columns total width (each rank subcolumn has 180px width)
        const ranksWidth = 180 * drivers[0].dateSegments.length
        // map children columns
        const children = drivers[0].dateSegments.map((week, i) => {
          const bottomLine = `
            ${moment.parseZone(week.startDate).format('MMM D')} - ${moment.parseZone(week.endDate).format('MMM D')}
          `
          const title = (
            <div>
              {bottomLine}
            </div>
          )
          return {
            title,
            width: '180px',
            dataIndex: `dateSegments[${i}].rank`,
            render: text => text || 'N/A',
            align: 'center',
          }
        })
        return [
          {
            title: wMatrix('overallRank'),
            dataIndex: 'rank',
            align: 'center',
            width: '130px',
            render: (text, record) => this.renderRank(record.rank, record.performance),
            sorter: (a, b) => a.rank - b.rank,
            defaultSortOrder: 'descend',
            key: 'rank',
          },
          {
            title: wMatrix('driver'),
            dataIndex: 'name',
            width: '180px',
          },
          {
            // want each subcolumn to be 180px
            title: wMatrix('rank'),
            width: `${ranksWidth}px`,
            children,
          },
        ]
      }
    }
    return [
      {
        title: wMatrix('rank'),
        dataIndex: 'rank',
        align: 'center',
        render: (text, record) => this.renderRank(record.rank, record.performance),
        sorter: (a, b) => a.rank - b.rank,
        defaultSortOrder: 'descend',
        key: 'rank',
        width: 100,
      },
      {
        title: wMatrix('driver'),
        dataIndex: 'name',
        width: 150,
      },
      {
        title: wMatrix('fastStarts'),
        dataIndex: 'fastStart',
        width: 100,
      },
      {
        title: wMatrix('hardBrakes'),
        dataIndex: 'hardBrake',
        width: 100,
      },
      {
        title: wMatrix('speeding'),
        dataIndex: 'speeding',
        width: 100,
      },
      {
        title: wMatrix('driveTime'),
        dataIndex: 'driveTime',
        width: 100,
        sorter: (a, b) => a.driveTime - b.driveTime,
        render: (text) => {
          const time = `${Math.round(text * 100 / 60) / 100} hr`
          return (
            <div style={{ width: '55px' }}>
              {time}
            </div>
          )
        },
      },
    ]
  }

  render() {
    const { drivers, loading } = this.state
    const {
      view, multipleWeeks,
    } = this.props
    /**
      * The padding top variable is an approximation of the distance from the top of the
      * view to the top of the table PLUS the distance from the bottom of the table to the
      * bottom of the view.
      * This is used so the ant table can scroll properly.
      */
    let paddingTop = view === 'dashboard' ? 380 : 415
    if (multipleWeeks) {
      paddingTop += 50
    }
    paddingTop += 'px'
    /**
     * Note that the 'dscStandingsTable' class is to correct antDesign table bug where Header width
     * doesn't match horizontal width of table
     */
    return (
      <>
        {this.renderFilteredNameInput()}
        <div style={{
          flexGrow: 1,
          position: 'relative',
        }}
        >
          <Table
            className="dscStandingsTable"
            columns={this.renderColumns()}
            rowKey="id"
            dataSource={loading ? null : drivers}
            size="medium"
            scroll={{
              y: `calc(100vh - ${paddingTop})`,
              x: view === 'dashboard' ? undefined : true,
            }}
            pagination={false}
          />
        </div>
      </>
    )
  }
}
