import React, { Component } from 'react'
import {
  Button, Select, Row, Col,
} from 'antd'
import {
  PieChart, Pie, Cell, ResponsiveContainer, Sector,
} from 'recharts'
import Card from '@atom/card'
import PropTypes from 'prop-types'
import SectionScrollView from '@mol/sectionScrollView'
import Skeleton from '@atom/skeleton'
import helper from '@helper'

const { Option } = Select

const chartColors = {
  orange: [219, 128, 32],
  pink: [211, 93, 129],
  blue: [87, 175, 222],
}

const meterColors = {
  red: [215, 91, 78], // red
  yellow: [238, 221, 116], // yellow
  green: [84, 177, 132], // green
}

const styles = {
  cardContentWrapper: {
    flexGrow: 1,
    flexShrink: 0,
    margin: '0px 8px 8px 8px',
    display: 'flex',
    flexDirection: 'row',
  },
  contentWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    overflow: 'hidden',
  },
  exceptionsRowWrapper: {
    flex: '1 0 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    overflow: 'hidden',
  },
  exceptionColumnContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    alignItems: 'center',
  },
  chartTableContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
  },
  pieContainer: {
    width: '75%',
    position: 'relative',
    flexShrink: 0,
    maxWidth: '327px',
    minHeight: '200px',
  },
  labelWrapper: {
    position: 'absolute',
    width: '20%',
    maxWidth: 50,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  labelAspectCircle: {
    // the following is what creates an adaptive square div that is 1:1 by width
    width: '100%',
    paddingTop: '100%',
    borderRadius: '50%',
    position: 'relative',
    zIndex: 0,
  },
  label: {
    position: 'absolute',
    top: '0%',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFF',
  },
  tableOuterWrapper: {
    flexGrow: 1,
    width: '80%',
    position: 'relative',
    overflow: 'hidden',
  },
  tableInnerWrapper: {
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
}

class DriverExceptionsDetails extends Component {
  static propTypes = {
    // driverExceptions: PropTypes.array,
    appHeight: PropTypes.number.isRequired,
    wMatrix: PropTypes.func.isRequired,
    getDSCData: PropTypes.func.isRequired,
    DSCDateRangeOptions: PropTypes.array.isRequired,
    setDSCNav: PropTypes.func.isRequired,
    returnColor: PropTypes.func.isRequired,
    dscData: PropTypes.object,
    exceptionData: PropTypes.object,
    loading: PropTypes.bool,
  }

  static defaultProps = {
    dscData: null,
    exceptionData: {},
    loading: false,
  }

  state = {
    selectedItem: null,
    selectedSection: null,
    selectedSectorIndex: null,
  }

  columns = () => {
    const { wMatrix } = this.props
    return ([
      {
        title: '',
        dataIndex: 'color',
        key: 'color',
        render: color => (
          <div
            style={{
              height: 14,
              width: 14,
              backgroundColor: color,
            }}
          />
        ),
      },
      {
        title: wMatrix('driver'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: wMatrix('count'),
        dataIndex: 'count',
        key: 'count',
      },
      {
        title: `% ${wMatrix('ofTotal')}`,
        dataIndex: 'percent',
        key: 'percent',
      },
    ])
  }

  /**
   * grabs from props.driverExceptions based on key given.
   * Also calculates and adds chart item color
   */
  tableData = (key) => {
    const {
      exceptionData, returnColor,
    } = this.props
    if (exceptionData) {
      const data = []
      const { total } = exceptionData[key]

      const drivers = JSON.parse(JSON.stringify(exceptionData[key].orderedDrivers))
      const numberOfDrivers = drivers.length

      // const driver in driverExceptions
      let index = 0
      for (const driver of drivers) {
        const percent = total > 0 ? Math.floor((driver.count / total) * 100) : 0
        data.push({
          name: driver.name,
          id: driver.id,
          chartIndex: index,
          exception: key,
          count: driver.count,
          color: returnColor(
            (index / numberOfDrivers),
            chartColors.orange,
            chartColors.pink,
            chartColors.blue,
          ),
          percent: `${percent}%`,
        })
        index += 1
      }
      return data
    }
    return null
  }

  /**
   * On Click for Dashboard Button
   */
  onDashClick = () => {
    const { setDSCNav, getDSCData } = this.props
    // set data back to one week
    getDSCData('current_week')
    // navigate back to dashboard
    setDSCNav('dashboard')
  }

  /**
   * renders dashboard button
   */
  renderPostTitle = () => {
    const { wMatrix } = this.props
    return (
      <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
        <Button
          size="small"
          style={{ marginRight: 8 }}
          type="primary"
          onClick={() => this.onDashClick()}
        >
          {wMatrix('dashboard')}
        </Button>
      </div>
    )
  }

  /**
   * handles sector click of pie chart
   */
  onPieClick = (item, index, section) => {
    if (item && section && index !== null) {
      this.setState({
        selectedSection: section,
        selectedItem: item.id,
        selectedSectorIndex: item.chartIndex,
      })
    }
  }

  /**
   * Renders the active shape of the chart to be slightly further out from the center
   */
  renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180
    const {
      cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
      fill,
      // payload, percent, value,
    } = props
    const sin = Math.sin(-RADIAN * midAngle)
    const cos = Math.cos(-RADIAN * midAngle)
    const ncx = cx + 5 * cos
    const ncy = cy + 5 * sin

    return (
      <g>
        <Sector
          cx={ncx}
          cy={ncy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    )
  };

  /**
   * Renders Pie chart
   */
  returnPieChart = (key) => {
    const {
      exceptionData, returnColor, dscData, appHeight,
    } = this.props
    const { selectedSectorIndex, selectedSection } = this.state
    const chartHeight = (appHeight - 106 - 34) * 0.35
    if (dscData) {
      const drivers = Object.entries(dscData.drivers)
      // create copy and find total number of exceptions
      const data = this.tableData(key)
      const { total } = exceptionData[key]
      const numberOfDrivers = drivers.length
      const colorPercentage = exceptionData[key]
        ? exceptionData[key].total / exceptionData[key].max : 0

      return (
        <div style={{
          width: '75%',
          position: 'relative',
          flexShrink: 0,
          maxWidth: '327px',
          minHeight: '200px',
          height: chartHeight,
        }}
        >
          <ResponsiveContainer height="100%" width="100%">
            <PieChart cursor="pointer">
              <Pie
                activeIndex={selectedSection === key ? selectedSectorIndex : null}
                activeShape={this.renderActiveShape}
                data={data}
                dataKey="count"
                cx="50%"
                cy="50%"
                innerRadius="34%"
                outerRadius="80%"
                fill="#FF0000"
                onClick={(item, index) => this.onPieClick(item, index, key)}
              >
                {
                data.map(
                  (entry, index) => (
                    <Cell
                      key={entry}
                      fill={returnColor(
                        (index / numberOfDrivers),
                        chartColors.orange,
                        chartColors.pink,
                        chartColors.blue,
                      )}
                    />
                  ),
                )
              }
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <div style={styles.labelWrapper}>
            <div
              style={{
                ...styles.labelAspectCircle,
                backgroundColor: returnColor(
                  colorPercentage,
                  meterColors.red,
                  meterColors.yellow,
                  meterColors.green,
                ),
              }}
            >
              <div style={styles.label}>
                {total}
              </div>
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  /**
   * Handles click on table row
   */
  onTableClick = (section, item) => {
    if (section && item) {
      this.setState({
        selectedSection: section,
        selectedItem: item.id,
        selectedSectorIndex: item.chartIndex,
      })
    }
  }

  /**
   * renders table row given section and item
   */
  renderTableRow = (section, item) => {
    const { selectedSection, selectedItem } = this.state
    let focused = false
    if (selectedSection === section.id && selectedItem === item.id) {
      focused = true
    }
    return (
      <Row
        style={{ padding: 10, backgroundColor: focused ? '#102A961A' : 'transparent', cursor: 'pointer' }}
        key={`${section.id}-${item.id}`}
        onClick={() => this.onTableClick(section.id, item)}
      >
        <Col span={4}>
          <div style={{
            height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexShrink: 0,
          }}
          >
            <div
              style={{
                height: '14px',
                width: '14px',
                backgroundColor: item.color,
              }}
            />
          </div>
        </Col>
        <Col span={8}>{item.name}</Col>
        <Col span={6}>{item.count}</Col>
        <Col span={6}>{`${item.percent}`}</Col>
      </Row>
    )
  }

  /**
   * renders table header
   */
  renderTableHeader = () => {
    const { wMatrix } = this.props
    return (
      <Row style={{
        // backgroundColor: '#FAFAFA',
        height: 30,
        display: 'flex',
        alignItems: 'center',
      }}
      >
        <Col span={4} />
        <Col span={8}>{wMatrix('driver')}</Col>
        <Col span={6}>{wMatrix('count')}</Col>
        <Col span={6}>{`% ${wMatrix('ofTotal')}`}</Col>
      </Row>
    )
  }


  /**
   * @description Creates a custom table by using SectionScrollView. The ScrollView is
   * wrapped in an absolute positioned container that is wrapped in a relative positioned
   * container. This is to be sure that the ScrollView does not overflow outside the allotted
   * space (Safari Compatibility).
   */
  customTable = (key) => {
    const data = this.tableData(key)
    const dataArray = [{ id: key, data }]
    const { selectedItem, selectedSection } = this.state

    return (
      <div style={styles.tableOuterWrapper}>
        <div style={styles.tableInnerWrapper}>
          <SectionScrollView
              // Identifiers will be in form '<sectionID>-<itemID>'
            sections={dataArray}
            selectedId={`${selectedSection}-${selectedItem}`}
            renderItem={this.renderTableRow}
            renderSectionHeader={this.renderTableHeader}
            itemArrayKey="data" // separate nested keys by '.'
            sectionIdKey="id"
            itemIdKey="id"
            noHeaders={false}
            noFooters
          />
        </div>
      </div>
    )
  }

  /**
   * renders time selection label and select box
   */
  renderTimeSelection = () => {
    const { wMatrix, getDSCData, DSCDateRangeOptions } = this.props
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexShrink: 0,
        flexGrow: 0,
      }}
      >
        {`${wMatrix('timeRange')}:`}
        <Select
          defaultValue="current_week"
          style={{ marginLeft: 24, width: 106 }}
          onChange={getDSCData}
        >
          {DSCDateRangeOptions ? DSCDateRangeOptions.map(option => (
            <Option value={option.key} key={option.key}>{`${wMatrix(option.title)}`}</Option>
          )) : null}
        </Select>
      </div>
    )
  }

  /**
   * Renders total drivers text
   */
  renderTotalDrivers = () => {
    const { wMatrix, dscData, loading } = this.props
    if (dscData) {
      const drivers = Object.entries(dscData.drivers)
      return (
        <div style={{
          alignSelf: 'flex-start',
          display: 'flex',
          alignItems: 'center',
          flexShrink: 0,
          flexGrow: 0,
        }}
        >
          <h3 style={{ marginRight: '1ch', marginBottom: 0 }}>
            {`${wMatrix('totalDrivers')}: `}
          </h3>
          {!loading && drivers
            ? <h3 style={{ marginBottom: 0 }}>{drivers.length}</h3>
            : <Skeleton wrapperStyle={{ width: '4ch', height: '1em' }} />
          }
        </div>
      )
    }
    return null
  }

  render() {
    const { wMatrix } = this.props
    return (
      <Card
        title={wMatrix('exceptions')}
        postTitle={this.renderPostTitle()}
        contentWrapper={styles.cardContentWrapper}
      >
        <div style={styles.contentWrapper}>
          {this.renderTotalDrivers()}
          {this.renderTimeSelection()}
          <div style={styles.exceptionsRowWrapper}>
            <div style={styles.exceptionColumnContainer}>
              <h3 style={{ margin: 15 }}>{wMatrix('speedingExceptions')}</h3>
              {this.returnPieChart('speeding')}
              {this.customTable('speeding')}
            </div>
            <div style={styles.exceptionColumnContainer}>
              <h3 style={{ margin: 15 }}>{wMatrix('hardBrakes')}</h3>
              {this.returnPieChart('hardBrake')}
              {this.customTable('hardBrake')}
            </div>
            <div style={styles.exceptionColumnContainer}>
              <h3 style={{ margin: 15 }}>{wMatrix('fastStarts')}</h3>
              {this.returnPieChart('fastStart')}
              {this.customTable('fastStart')}
            </div>
          </div>
        </div>
      </Card>
    )
  }
}

export default helper()(DriverExceptionsDetails)
