import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import DistanceMap from '@mol/distanceMap'
import Map from '@mol/map'
import { compose } from 'react-apollo'
import DeviceDetails from '@mol/rightPanel/deviceDetails'
import helper from '@helper'
import RightContainer from './right.container'
import DirectionDrawer from '../rightDrawer/directionDrawer'

const styles = {
  container: {
    display: 'flex',
    flex: 1,
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
  },
  timelineContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingTop: '4px',
    backgroundColor: '#367',
  },
}

class DistanceContainer extends PureComponent {
  static propTypes = {
    devices: PropTypes.array,
    selectedDevice: PropTypes.object,
    selectDevice: PropTypes.func,
    unselectDevice: PropTypes.func,
    mapSettings: PropTypes.object,
    updateMapSettings: PropTypes.func,
    deviceListMode: PropTypes.string,
    mapIconMode: PropTypes.string,
    mapIconSizeMode: PropTypes.string,
    distanceOrigin: PropTypes.object,
    ifCheckDistance: PropTypes.bool,
    sortedDevicelist: PropTypes.array,
    hoveredDevice: PropTypes.object,
    hoveredDeviceIndex: PropTypes.number,
    wMatrix: PropTypes.func.isRequired,
    mainNav: PropTypes.string.isRequired,
    setOriginFlag: PropTypes.bool,
    locateNow: PropTypes.object.isRequired,
    selectModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    devices: [],
    selectedDevice: null,
    mapSettings: {},
    updateMapSettings: () => {},
    deviceListMode: 'comfortable',
    mapIconMode: 'comfortable',
    mapIconSizeMode: 'large',
    selectDevice: () => {},
    unselectDevice: () => {},
    distanceOrigin: null,
    ifCheckDistance: false,
    sortedDevicelist: [],
    hoveredDevice: null,
    hoveredDeviceIndex: -1,
    setOriginFlag: false,
  }

  state = {
    directionLeg: null,
  }

  setDirectionLeg = (directionLeg) => {
    this.setState({ directionLeg })
  }

  renderLiveViewMap = (initMap, fitBounds) => {
    const {
      devices, selectedDevice, selectDevice, deviceListMode, mapIconMode, mapIconSizeMode,
      ifCheckDistance, distanceOrigin, sortedDevicelist,
      hoveredDevice, hoveredDeviceIndex, mainNav, setOriginFlag,
      mapSettings,
    } = this.props

    return (
      <DistanceMap
        initMap={initMap}
        settings={mapSettings}
        fitBounds={fitBounds}
        devices={devices}
        deviceListMode={deviceListMode}
        mapIconMode={mapIconMode}
        mapIconSizeMode={mapIconSizeMode}
        selectDevice={selectDevice}
        selectedDevice={selectedDevice}
        ifCheckDistance={ifCheckDistance}
        distanceOrigin={distanceOrigin}
        sortedDevicelist={sortedDevicelist}
        hoveredDevice={hoveredDevice}
        hoveredDeviceIndex={hoveredDeviceIndex}
        setDirectionLeg={this.setDirectionLeg}
        mainNav={mainNav}
        setOriginFlag={setOriginFlag}
      />
    )
  }

  renderLiveView = () => {
    const {
      mapSettings,
      updateMapSettings,
      deviceListMode,
      mapIconMode,
      mapIconSizeMode,
      wMatrix,
      selectedDevice,
      distanceOrigin,
      selectModal,
    } = this.props
    const { directionLeg } = this.state

    return (
      <div style={styles.contentContainer}>
        <div style={{ flexGrow: 1, position: 'relative' }}>
          <div style={{ display: 'flex', height: '100%', background: 'none' }}>
            <Map
              settings={mapSettings}
              updateSettings={updateMapSettings}
              renderMap={this.renderLiveViewMap}
              deviceListMode={deviceListMode}
              mapIconMode={mapIconMode}
              mapIconSizeMode={mapIconSizeMode}
              selectModal={selectModal}
              animationToggle
              clusteringToggle
              hidePlaceSearch
            />
          </div>
          {selectedDevice && distanceOrigin
            ? (
              <DirectionDrawer
                wMatrix={wMatrix}
                directionLeg={directionLeg}
                selectedDevice={selectedDevice}
              />
            ) : <></>
        }
        </div>
      </div>
    )
  }

  render() {
    const { selectedDevice, unselectDevice, locateNow } = this.props
    const tabs = {
      small: [{
        id: 'l1',
        name: 'Distance',
      }],
      large: [{
        id: 'l1',
        name: 'Distance',
      }],
    }

    return (
      <div style={styles.container}>
        <RightContainer
          headerMode={selectedDevice ? 'large' : 'small'}
          title={selectedDevice ? selectedDevice.alias : ''}
          onClose={unselectDevice}
          defaultTabKey="l1"
          renderHeaderDetails={() => (
            <DeviceDetails device={selectedDevice} locateNow={locateNow} />
          )}
          tabs={tabs}

        >
          {this.renderLiveView()}
        </RightContainer>
      </div>
    )
  }
}

export default compose(
  helper(),
)(DistanceContainer)
