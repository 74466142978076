import React from 'react'
import { compose } from 'react-apollo'
import queryConnector from '@graphql/queryConnector'
import consumerConnector from '@graphql/consumerConnector'
import mutationConnector from '@graphql/mutationConnector'
import { featuresQuery, driversQuery, assignedDevicesQuery } from '@graphql/query'
import {
  updateDriver,
  createDriver,
  deleteDriver,
  assignDeviceToDriver,
  assignDriverToWexCard,
} from '@graphql/mutation'
import PropTypes from 'prop-types'

const driverMgrHOC = () => (WrappedComponent) => {
  class DriverMgrHOC extends React.Component {
    static propTypes = {
      refetch: PropTypes.func,
      createDriverQuery: PropTypes.func.isRequired,
      updateDriverQuery: PropTypes.func.isRequired,
      deleteDriverPost: PropTypes.func.isRequired,
      assignDeviceToDriverPost: PropTypes.func.isRequired,
      assignWexCard: PropTypes.func.isRequired,
      FeatureData: PropTypes.object.isRequired,
      driversData: PropTypes.object.isRequired,
      assignedDevicesData: PropTypes.object.isRequired,
      showELDButton: PropTypes.bool,
      /** @deviceListHOC */
      refetchDevices: PropTypes.func.isRequired,
      refetchDeviceListDrivers: PropTypes.func.isRequired,
    }

    state = {
      notificationMessage: '',
      notificationType: '',
      notificationFlag: false,
      // drivers: [],
    }

    static defaultProps = {
      refetch: null,
      showELDButton: false,
    }

    /**
     * Sets alert state for driver panel
     * @param {string} message alert message
     * @param {string} type alert type (success, info, warning, error)
     */
    notificationFromDriverPanel = (message, type) => {
      this.setState({
        notificationMessage: message,
        notificationType: type,
        notificationFlag: true,
      })
      this.alertTimer = setTimeout(() => {
        this.setState({
          notificationMessage: '',
          notificationType: 'success',
          notificationFlag: false,
        })
      }, 5000)
    }

    /**
     * Clears notification timer.
     */
    clearAlertTimer = () => {
      // If timer is running, clear
      if (this.alertTimer) {
        clearTimeout(this.alertTimer)
        this.alertTimer = 0
      }
    }

    /**
     * Clears alert timer on dismount
     */
    componentWillUnmount = () => {
      this.clearAlertTimer()
    }

    createDriver = async (firstname, lastname, dotNumber, fob, device, wexCard) => {
      const { createDriverQuery } = this.props
      try {
        const response = await createDriverQuery({
          variables: {
            firstName: firstname,
            lastName: lastname,
            fob: (fob || null),
            dotNumber: (dotNumber || null),
          },
        })
        const driverId = response.data.createDriver.id
        if (driverId) {
          this.notificationFromDriverPanel('driverCreatedSuccessfully', 'success')
          if (device) this.assignDeviceToDriver(device, driverId)
          if (wexCard) this.assignDriverToWexCard(wexCard, driverId)
          this.refetchDriversAndDevices()
        } else {
          this.notificationFromDriverPanel('failedToCreateDriver', 'error')
        }
      } catch (err) {
        this.notificationFromDriverPanel('failedToCreateDriver', 'error')
      }
    }

    updateDriver = async (data) => {
      const {
        id, firstName, lastName, dotNumber, fob, device, wexCard,
      } = data
      const { updateDriverQuery } = this.props
      try {
        const response = await updateDriverQuery({
          variables: {
            id,
            firstName,
            lastName,
            fob: (fob || null),
            dotNumber: (dotNumber || null),
          },
        })
        if (response && response.data && response.data.updateDriver.id) {
          const driverId = response.data.updateDriver.id
          const deviceResult = await this.assignDeviceToDriver(device, driverId)
          let wexResult = 'Success'
          if (wexCard || wexCard === '') {
            wexResult = await this.assignDriverToWexCard(wexCard, driverId)
          }
          if (deviceResult !== 'Success') {
            this.notificationFromDriverPanel(deviceResult, 'error')
          } else if (wexResult !== 'Success') {
            this.notificationFromDriverPanel(wexResult, 'error')
          } else {
            this.notificationFromDriverPanel('driverUpdatedSuccessfully', 'success')
          }
        } else {
          this.notificationFromDriverPanel('failedToUpdateDriver', 'error')
        }
      } catch (err) {
        this.notificationFromDriverPanel('failedToUpdateDriver', 'error')
      }
    }

    deleteDriver = async (id) => {
      const { deleteDriverPost } = this.props
      try {
        const response = await deleteDriverPost({
          variables: {
            id,
            ifActive: false,
          },
        })
        if (response.data.updateDriver.id) {
          if (this.hasFeature('Fuelcard')) this.assignDriverToWexCard('', id)
          this.notificationFromDriverPanel('driverDeletedSuccessfully', 'success')
          this.refetchDriversAndDevices()
        } else {
          this.notificationFromDriverPanel('failedToDeleteDriver', 'error')
        }
      } catch (err) {
        this.notificationFromDriverPanel('failedToDeleteDriver', 'error')
      }
    }

    assignDeviceToDriver = async (deviceId, driverId) => {
      const { assignDeviceToDriverPost } = this.props
      const res = await assignDeviceToDriverPost({
        variables: {
          deviceId,
          driverId,
        },
      })
      if (res.data.assignDeviceToDriver.code === 1000) {
        await this.refetchDriversAndDevices()
        return 'Success'
      }
      return 'failedToUpdateDriver'
    }

    assignDriverToWexCard = async (wexCard, driverId) => {
      const { assignWexCard } = this.props
      try {
        const res = await assignWexCard({
          variables: {
            wexCard,
            driverId,
          },
        })
        if (res.data.assignDriverToWexCard.code === 1000) {
          await this.refetchDriversAndDevices()
          return 'Success'
        }
        return 'failedToUpdateWexCard'
      } catch (err) {
        return 'failedToUpdateWexCard'
      }
    }

    hasFeature = (feature) => {
      const { FeatureData } = this.props
      const { features } = FeatureData.data
      for (const ft in features) {
        if (features[ft].name === feature) {
          return features[ft].isActive
        }
      }
      return false
    }

    /**
     * Refetches drivers and devices for both driverMgr as well as deviceList
     */
    refetchDriversAndDevices = () => {
      const {
        driversData, assignedDevicesData, refetchDeviceListDrivers, refetchDevices,
      } = this.props
      driversData.refetch()
      assignedDevicesData.refetch()
      refetchDeviceListDrivers()
      refetchDevices()
    }

    render() {
      const { refetch, driversData, assignedDevicesData } = this.props
      const { notificationMessage, notificationType, notificationFlag } = this.state
      return (
        <WrappedComponent
          refetch={refetch}
          hasFeature={this.hasFeature}
          drivers={driversData && driversData.data && driversData.data.driver
            ? driversData.data.driver : []}
          driversLoading={driversData.loading}
          createDriver={this.createDriver}
          updateDriver={this.updateDriver}
          assignedDevices={
            assignedDevicesData && assignedDevicesData.data
            && assignedDevicesData.data.assignedDevicesQuery
              ? assignedDevicesData.data.assignedDevicesQuery
              : []
          }
          deleteDriver={this.deleteDriver}
          notificationMessage={notificationMessage}
          notificationType={notificationType}
          notificationFlag={notificationFlag}
          {...this.props}
        />
      )
    }
  }

  return compose(
    queryConnector(featuresQuery, {}, 'FeatureData'),
    queryConnector(driversQuery, {}, 'driversData'),
    queryConnector(assignedDevicesQuery, {}, 'assignedDevicesData'),
    mutationConnector(updateDriver, 'updateDriverQuery'),
    mutationConnector(createDriver, 'createDriverQuery'),
    mutationConnector(deleteDriver, 'deleteDriverPost'),
    mutationConnector(assignDeviceToDriver, 'assignDeviceToDriverPost'),
    mutationConnector(assignDriverToWexCard, 'assignWexCard'),
    consumerConnector(),
  )(DriverMgrHOC)
}

export default driverMgrHOC
