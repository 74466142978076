import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'react-apollo'
import moment from 'moment'
import queryConnector from '@graphql/queryConnector'
import mutationConnector from '@graphql/mutationConnector'
import { logisticsJobQuery } from '@graphql/query'
import {
  createJobMutation, updateJobMutation, deleteJobMutation,
  createDestinationMutation, updateDestinationMutation, deleteDestinationMutation,
  createTaskMutation, updateTaskMutation, deleteTaskMutation,
  quickCreateJob,
} from '@graphql/mutation'
import equal from 'deep-equal'

const groupByEnum = {
  STATUS: 'status',
  DEVICE: 'device',
}

const dateFormat = 'YYYY-MM-DD hh:mm a'
const dbDateFormat = 'YYYY-MM-DD HH:mm'

const logisticsHOC = () => (WrappedComponent) => {
  class LogisticsHOC extends Component {
    static propTypes = {
      wMatrix: PropTypes.func.isRequired,
      devices: PropTypes.array.isRequired,
      appHeight: PropTypes.number.isRequired,
      getJobs: PropTypes.object.isRequired,
      openMessenger: PropTypes.func.isRequired,
      quickCreate: PropTypes.func.isRequired,
      createJob: PropTypes.func.isRequired,
      updateJob: PropTypes.func.isRequired,
      deleteJob: PropTypes.func.isRequired,
      createDestination: PropTypes.func.isRequired,
      updateDestination: PropTypes.func.isRequired,
      deleteDestination: PropTypes.func.isRequired,
      createTask: PropTypes.func.isRequired,
      updateTask: PropTypes.func.isRequired,
      deleteTask: PropTypes.func.isRequired,
      landmarks: PropTypes.array.isRequired,
    }

    state = {
      nav: 'dashboard',
      filterString: '',
      groupBy: 'status',
      jobs: [],
      selectedJob: null,
      newDestinationLoading: false,
      createJobLoading: false,
      deleteJobLoading: false,
      statusData: {
        overdue: {
          jobs: 0,
          destinations: 0,
          packages: 0,
          workOrders: 0,
        },
        active: {
          jobs: 0,
          destinations: 0,
          packages: 0,
          workOrders: 0,
        },
        completed: {
          jobs: 0,
          destinations: 0,
          packages: 0,
          workOrders: 0,
        },
        nextDue: null,
      },
      taskLoading: false,
      reorderDestLoading: false,
      destLoading: false,
      updateJobLoading: false,
      quickCreateLoading: false,
    }

    componentDidUpdate = (prevProps) => {
      const { getJobs, devices } = this.props
      if (!equal(prevProps.getJobs.data?.logisticsJob, getJobs.data?.logisticsJob)
        || prevProps.devices.length !== devices.length) {
        const jobsArray = this.getUpdatedJobsArray()
        this.updateJobAndStatusData(jobsArray)
      }
    }

    /**
     * This function replaces the standard refetch for the getJobs query. If the query parameters
     * are the same, it calls refetch. If the parameters have changed, it will set the
     * appropriate variables and fetch the new data.
     * @param {Boolean} refetchAll Optional boolean to force refetch of all jobs
     */
    refetch = async (refetchAll) => {
      const { getJobs } = this.props
      const { selectedJob } = this.state
      // setting the variables should force a refetch with new variables
      const idToFetch = refetchAll ? null : selectedJob.id
      // if id is same as current query parameters, refetch
      if (idToFetch === getJobs.params.id) {
        await getJobs.refetch()
      } else {
        // if query parameters are different, set new variables and refetch
        await getJobs.setVariables({ id: idToFetch })
      }
    }

    /**
     * @description This function checks the getJob's query results and returns the entire jobs
     * array. If getJobs only supplied a single job, this function takes the existing jobs array
     * and updates the matching job to return an updated array. If the query returned a
     * list of jobs (all jobs) then it simply returns this list.
     * @returns {[Object]} Returns array of job objects
     */
    getUpdatedJobsArray = () => {
      const { jobs } = this.state
      const { getJobs } = this.props
      if (
        getJobs.data
        && getJobs.data.logisticsJob
        && getJobs.data.logisticsJob.length > 0
      ) {
        const queriedJobs = JSON.parse(JSON.stringify(getJobs.data.logisticsJob))
        let updatedJobs = JSON.parse(JSON.stringify(jobs))
        /**
         * update single job (if only one job was returned from the query and
         * there were already jobs existing)
         */
        if (queriedJobs.length === 1 && jobs.length !== 0) {
          // update single job
          for (let i = 0; i < updatedJobs.length; i += 1) {
            //
            if (updatedJobs[i].id === queriedJobs[0].id) {
              const queriedJob = queriedJobs[0]
              updatedJobs[i] = queriedJob
              break
            }
          }
        } else {
          // update all jobs
          updatedJobs = queriedJobs
        }
        return updatedJobs
      }
      return jobs
    }

    /**
     * Updates Job and Status Data. This was originally in the componentDidUpdate but has now been
     * exported to this function for clarity of lifecycle functions. This has now been updated to
     * update jobs as well as status data based on the supplied array. (If a single job is
     * fetched, it is first spliced with the existing array and then passed to this function)
     */
    updateJobAndStatusData = (newJobsArray) => {
      const { devices } = this.props
      const { selectedJob } = this.state
      const statusData = {
        overdue: {
          jobs: 0,
          destinations: 0,
          packages: 0,
          workOrders: 0,
        },
        active: {
          jobs: 0,
          destinations: 0,
          packages: 0,
          workOrders: 0,
        },
        completed: {
          jobs: 0,
          destinations: 0,
          packages: 0,
          workOrders: 0,
        },
        unassigned: {
          jobs: 0,
          destinations: 0,
          workOrders: 0,
        },
        nextDue: null,
      }
      const newJobs = JSON.parse(JSON.stringify(newJobsArray))
      let formattedJobs = []
      if (devices && devices.length > 0) {
        formattedJobs = newJobs.map((original) => {
          const job = JSON.parse(JSON.stringify(original))
          // set selectedJob state
          // @ANCHOR - This is basically update and check selected job
          if (selectedJob && job.id === selectedJob.id) {
            this.setState({ selectedJob: job })
          }

          /**
           * If the job is over due, then the related destinations and tasks are overdue,
           * unless the any of the destination and task is completed
           */
          if (job.status === 'active') {
            if (job.dueDate && moment(job.dueDate) < moment()) {
              job.status = 'overdue'
              statusData.overdue.jobs += 1
            } else {
              statusData.active.jobs += 1
              if (statusData.nextDue === null && job.dueDate) {
                statusData.nextDue = moment(job.dueDate).format(dateFormat)
              } else if (job.dueDate && (moment(job.dueDate) < moment(statusData.nextDue))) {
                statusData.nextDue = moment(job.dueDate).format(dateFormat)
              }
            }
          } else if (job.status === 'completed') {
            statusData.completed.jobs += 1
          } else if (job.status === 'unassigned') {
            statusData.unassigned.jobs += 1
          }

          // Destination can be unassigned, completed, active or overdue
          for (const dest of job.destinations) {
            if (job.status === 'unassigned') {
              statusData.unassigned.destinations += 1
            } else if (dest.status === 'completed') {
              statusData.completed.destinations += 1
            } else if (job.status === 'overdue') {
              dest.status = 'overdue'
              statusData.overdue.destinations += 1
            } else if (job.status === 'active') {
              statusData.active.destinations += 1
            }

            // Destination can be unassigned, completed, active or overdue
            for (const task of dest.tasks) {
              if (job.status === 'unassigned') {
                if (task.type === 'package') statusData.unassigned.packages += 1
                else statusData.unassigned.workOrders += 1
              } else if (task.status === 'completed') {
                if (task.type === 'package') statusData.completed.packages += 1
                else statusData.completed.workOrders += 1
              } else if (job.status === 'overdue') {
                task.status = 'overdue'
                if (task.type === 'package') statusData.overdue.packages += 1
                else statusData.overdue.workOrders += 1
              } else if (job.status === 'active') {
                if (task.type === 'package') statusData.active.packages += 1
                else statusData.active.workOrders += 1
              }
            }
          }

          for (const device of devices) {
            if (device.id === job.deviceId) {
              job.deviceAlias = device.alias

              if (device.currentDriver && device.currentDriver.name) {
                job.driver = device.currentDriver.name
              } else {
                job.driver = ''
              }
            }
          }
          return job
        })
      }
      this.setState({ jobs: formattedJobs, statusData })
    }

    navCreate = () => {
      this.setState({ selectedJob: null, nav: 'details' })
    }

    navDashboard = () => {
      this.setState({ selectedJob: null, nav: 'dashboard' })
    }

    handleSelectJob = (jobId) => {
      const { jobs } = this.state
      if (jobs.length > 0) {
        const jobsClone = JSON.parse(JSON.stringify(jobs))
        for (const job of jobsClone) {
          // NOTE: jobId comes in as a string from menu
          if (typeof jobId === 'string') {
            if (job.id.toString() === jobId) {
              this.setState({ selectedJob: job, nav: 'details' })
            }
          } else if (job.id === jobId) {
            this.setState({ selectedJob: job, nav: 'details' })
          }
        }
      }
    }

    /**
     * @description - update filterString state
    */
    filterMenu = (input) => {
      this.setState({ filterString: input })
    }

    /**
     * @description - filter for ticket menu. Can filter by alias, vin, and service type
     */
    filterJobs = (jobs) => {
      const { filterString } = this.state
      let records = []
      if (jobs) {
        records = JSON.parse(JSON.stringify(jobs))
        if (filterString !== '') {
          records = records.filter(r => r.alias.toLowerCase().includes(filterString.toLowerCase())
            || (r.driver && r.driver.toLowerCase().includes(filterString.toLowerCase()))
            || (r.deviceAlias
              && r.deviceAlias.toLowerCase().includes(filterString.toLowerCase())
            ))
        }
      }
      return records
    }

    /**
     * @public
     * @description - handles groupBy select change
     */
    onGroupChange = (value) => {
      if (Object.values(groupByEnum).includes(value)) {
        this.setState({ groupBy: value })
      }
    }

    /**
     * @description - renders top level menu options given sub menu items
     */
    handleMenuItems = (jobs) => {
      const { groupBy } = this.state
      const { devices } = this.props
      let menuItems = []

      switch (groupBy) {
        case 'device':
          for (const device of devices) {
            const deviceJobs = jobs.filter(job => job.deviceId === device.id)

            if (deviceJobs.length > 0) {
              const items = []
              for (const dj of deviceJobs) {
                items.push({
                  id: dj.id,
                  data: dj,
                })
              }

              menuItems.push({
                title: device.alias,
                id: device.id,
                items,
              })
            }
          }
          break
        default:
          menuItems = [
            {
              title: 'Overdue',
              id: 'g0',
              items: [],
            },
            {
              title: 'Active',
              id: 'g1',
              items: [],
            },
            {
              title: 'Completed',
              id: 'g2',
              items: [],
            },
            {
              title: 'Unassigned',
              id: 'g3',
              items: [],
            },
          ]

          for (const job of jobs) {
            if (job.status === 'overdue') {
              menuItems[0].items.push({
                id: job.id,
                data: job,
              })
            } else if (job.status === 'active') {
              menuItems[1].items.push({
                id: job.id,
                data: job,
              })
            } else if (job.status === 'completed') {
              menuItems[2].items.push({
                id: job.id,
                data: job,
              })
            } else if (job.status === 'unassigned') {
              menuItems[3].items.push({
                id: job.id,
                data: job,
              })
            }
          }
      }

      return menuItems
    }

    /**
     * @private
     * @description Takes an array of devices and adapts them to send the the table columns.
     * @param {[Object]} devices
     */
    shapeDevices = (devices) => {
      const { jobs } = this.state
      const activeJobs = JSON.parse(JSON.stringify(jobs))
        .filter(job => job.status !== 'completed')
      // create device mapping to search through
      const assignedDevices = {}
      activeJobs.forEach((job) => {
        if (job.deviceId) {
          assignedDevices[job.deviceId] = {
            jobId: job.id,
            jobAlias: job.alias,
          }
        }
      })
      // only care about jobs that are active
      const shaped = []
      for (let i = 0; i < devices.length; i += 1) {
        const assignedJob = assignedDevices[devices[i].id] ? {
          id: assignedDevices[devices[i].id].jobId,
          alias: assignedDevices[devices[i].id].jobAlias,
        } : null
        shaped.push({
          id: devices[i].id,
          alias: devices[i].alias,
          groups: devices[i].groups,
          labels: devices[i].labels,
          garmin: devices[i].garmin,
          assignedJob,
        })
      }
      return shaped
    }

    handleQuickCreate = async () => {
      const { selectedJob } = this.state
      const { quickCreate } = this.props

      this.setState({ createJobLoading: true, quickCreateLoading: true })

      const quickRes = await quickCreate({
        variables: {
          jobId: selectedJob.id,
        },
      })

      await this.refetch(true)
      this.handleSelectJob(quickRes.data.quickCreateJob)
      this.setState({ createJobLoading: false, quickCreateLoading: false })
    }

    createJob = async (job, isQuick = false) => {
      const { createJob } = this.props
      let dueDate = null
      if (job.dueDate) dueDate = moment(job.dueDate).format(dbDateFormat)
      if (!isQuick) this.setState({ createJobLoading: true })

      const res = await createJob({
        variables: {
          alias: job.alias,
          notes: job.notes,
          deviceId: job.deviceId || null,
          dueDate,
        },
      })
      const jobId = res.data.createLogisticsJob

      if (isQuick) {
        return jobId
      }

      this.setState({ createJobLoading: false })
      await this.refetch(true)
      this.handleSelectJob(jobId)
      return null
    }

    // Filter out unchanged values
    updateJob = async (data) => {
      const { updateJob } = this.props
      const { jobs } = this.state
      this.setState({ updateJobLoading: true })
      let match = null
      for (const job of jobs) {
        if (job.id === data.id) match = job
      }

      if (match) {
        const variables = { id: match.id }
        if (match.alias !== data.alias) variables.alias = data.alias
        if (match.deviceId !== data.deviceId) variables.deviceId = data.deviceId
        if (variables.deviceId === '') variables.deviceId = null
        if (match.notes !== data.notes) variables.notes = data.notes
        if (match.dueDate || data.dueDate) {
          if (data.dueDate === null && match.dueDate) {
            variables.dueDate = ''
          } else if (data.dueDate
            && moment(match.dueDate).format(dbDateFormat) !== data.dueDate.format(dbDateFormat)
          ) {
            variables.dueDate = moment(data.dueDate).format(dbDateFormat)
          }
        }
        if (data.completed && !match.completionDate) variables.completed = true
        await updateJob({ variables })
        // await getJobs.refetch()
        await this.refetch()
      }
      this.setState({ updateJobLoading: false })
    }

    // Update job status, all destinations, and all tasks associated to 5
    deleteJob = async (jobId) => {
      const { deleteJob } = this.props
      this.setState({ deleteJobLoading: true })
      await deleteJob({ variables: { id: jobId } })
      await this.refetch(true)
      this.setState({ deleteJobLoading: false })
    }

    /**
     * Creates Destination and returns alert details to be displayed (success or error).
     * @param {Object} dest Destination object to be created
     * @returns {Object} Alert Object containing type and message
     */
    createDestination = async (dest, isQuick = false) => {
      const { createDestination, wMatrix } = this.props
      this.setState({ newDestinationLoading: true })
      try {
        const destRes = await createDestination({
          variables: {
            jobId: dest.jobId,
            label: dest.label,
            address: dest.address || '',
            coordinates: (dest.latitude && dest.longitude) ? `${dest.latitude.toString()},${dest.longitude.toString()}` : null,
            sequence: dest.sequence,
          },
        })

        if (isQuick) {
          return destRes.data.createLogisticsDestination
        }

        await this.refetch()
        return {
          type: 'success',
          message: wMatrix('destinationCreatedSuccessfully'),
        }
      } catch (e) {
        return {
          type: 'error',
          message: wMatrix('issueCreatingDestination'),
        }
      } finally {
        this.setState({ newDestinationLoading: false })
      }
    }

    // Update one destination at a time, filter out unchanged values
    updateDestination = async (data) => {
      const { updateDestination } = this.props
      const { jobs } = this.state
      this.setState({ destLoading: true })
      let match = null
      for (const job of jobs) {
        for (const dest of job.destinations) {
          if (dest.id === data.id) {
            match = dest
            break
          }
        }
      }

      if (match) {
        const variables = { id: match.id }
        if (match.label !== data.label) variables.label = data.label
        if (data.completed && !match.completionDate) variables.completed = true
        if (match.address !== data.address) variables.address = data.address

        await updateDestination({ variables })
        await this.refetch()
      }
      this.setState({ destLoading: false })
    }

    // Update destination status and all tasks associated to 5
    deleteDestination = async (dest, job) => {
      const { deleteDestination } = this.props
      this.setState({ destLoading: true })
      await deleteDestination({ variables: { id: dest.id } })
      // Check to see if need to reorder the left over destinations
      const changes = []
      for (let i = 0; i < job.destinations.length; i += 1) {
        const jd = job.destinations[i]
        // we only need to reorder the destination if it is after the one deleted
        if (jd.sequence > dest.sequence) {
          changes.push({
            id: jd.id,
            sequence: jd.sequence - 1,
          })
        }
      }
      await this.reorderDestinations(changes)
      this.setState({ destLoading: false })
      await this.refetch()
    }

    createTask = async (task, isQuick = false) => {
      const { createTask } = this.props
      this.setState({ taskLoading: true })
      await createTask({ variables: task })
      this.setState({ taskLoading: false })
      if (!isQuick) await this.refetch()
    }

    // Update one task at a time, filter out unchanged values
    updateTask = async (data) => {
      const { updateTask } = this.props
      const { jobs } = this.state
      let match = null
      for (const job of jobs) {
        for (const dest of job.destinations) {
          if (dest.id === data.destinationId) {
            for (const task of dest.tasks) {
              if (task.id === data.id) {
                match = task
                break
              }
            }
          }
        }
      }

      if (match) {
        const variables = { id: match.id }
        if (match.alias !== data.alias) variables.alias = data.alias
        if (match.notes !== data.notes) variables.notes = data.notes
        if (match.type !== data.type) variables.type = data.type
        if (match.email !== data.email) variables.email = data.email
        if (data.completed && !match.completionDate) variables.completed = true

        this.setState({ taskLoading: true })
        await updateTask({ variables })
        this.setState({ taskLoading: false })
        await this.refetch()
      }
    }

    deleteTask = async (taskId) => {
      const { deleteTask } = this.props
      this.setState({ taskLoading: true })
      await deleteTask({ variables: { id: taskId } })
      this.setState({ taskLoading: false })
      await this.refetch()
    }

    reorderDestinations = async (changes) => {
      const { updateDestination } = this.props
      const promises = []
      this.setState({ reorderDestLoading: true })
      for (const change of changes) {
        promises.push(updateDestination({ variables: change }))
      }
      await Promise.all(promises)
      this.setState({ reorderDestLoading: false })
      await this.refetch()
    }

    render() {
      const {
        wMatrix, devices, appHeight, openMessenger, landmarks,
        getJobs, updateDestination,
      } = this.props
      const {
        jobs, statusData, selectedJob, nav, newDestinationLoading, taskLoading, reorderDestLoading,
        createJobLoading, deleteJobLoading, destLoading, updateJobLoading, quickCreateLoading,
      } = this.state
      const filteredItems = this.filterJobs(jobs)
      /**
       * @NOTE Currently, destinations table loading is governed by getJobs loading state.
       * If we change the job refetching method, this should be updated
       */
      return (
        <WrappedComponent
          wMatrix={wMatrix}
          handleQuickCreate={this.handleQuickCreate}
          nav={nav}
          handleSelectJob={this.handleSelectJob}
          navDashboard={this.navDashboard}
          navCreate={this.navCreate}
          handleCreateJob={this.handleCreateJob}
          devices={this.shapeDevices(devices)}
          jobs={jobs}
          selectedJob={selectedJob}
          filterMenu={this.filterMenu}
          menuItems={this.handleMenuItems(filteredItems)}
          appHeight={appHeight}
          onGroupChange={this.onGroupChange}
          statusData={statusData}
          openMessenger={openMessenger}
          createJob={this.createJob}
          updateJob={this.updateJob}
          deleteJob={this.deleteJob}
          createDestination={this.createDestination}
          updateDestination={this.updateDestination}
          deleteDestination={this.deleteDestination}
          createTask={this.createTask}
          updateTask={this.updateTask}
          deleteTask={this.deleteTask}
          reorderDestinations={this.reorderDestinations}
          newDestinationLoading={newDestinationLoading}
          createJobLoading={createJobLoading}
          deleteJobLoading={deleteJobLoading}
          updateJobLoading={updateJobLoading}
          quickCreateLoading={quickCreateLoading}
          destinationsTableLoading={getJobs.loading || updateDestination.loading
            || taskLoading || reorderDestLoading || destLoading}
          /** from main/landmarksHOC */
          landmarks={landmarks}
        />
      )
    }
  }

  return compose(
    mutationConnector(quickCreateJob, 'quickCreate'),
    mutationConnector(createJobMutation, 'createJob'),
    mutationConnector(updateJobMutation, 'updateJob'),
    mutationConnector(deleteJobMutation, 'deleteJob'),
    mutationConnector(createDestinationMutation, 'createDestination'),
    mutationConnector(updateDestinationMutation, 'updateDestination'),
    mutationConnector(deleteDestinationMutation, 'deleteDestination'),
    mutationConnector(createTaskMutation, 'createTask'),
    mutationConnector(updateTaskMutation, 'updateTask'),
    mutationConnector(deleteTaskMutation, 'deleteTask'),
    queryConnector(logisticsJobQuery, { id: null }, 'getJobs', true),
  )(LogisticsHOC)
}

export default logisticsHOC
