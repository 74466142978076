import React from 'react'
import PropTypes from 'prop-types'
import {
  Layout, message, Tooltip, Modal as AntModal,
} from 'antd'
import { compose } from 'react-apollo'
import equal from 'deep-equal'
import { withRouter } from 'react-router-dom'
import ContactMetaHOC from '@hoc/contactMeta.hoc'
import DeviceListHOC from '@hoc/deviceList.hoc'
import DistanceHOC from '@hoc/distance.hoc'
import LandmarksHOC from '@hoc/landmarks.hoc'
import mapHOC from '@hoc/map.hoc'
import messengerHOC from '@hoc/messenger.hoc'
import helper from '@helper'
import Analytics from '@analytics'
import packageJSON from '../../../package.json'
import Header from './containers/header/header.container'
import Modal from './containers/modal/modal.container'
import DevicesLeftPanel from './containers/leftPanel/devices.container'
import LandmarksLeftPanel from './containers/leftPanel/landmarks.container'
import DevicesRightContainer from './containers/rightPanel/devices.container'
import LandmarksRightContainer from './containers/rightPanel/landmarks.container'
import DistanceLeftPanel from './containers/leftPanel/distance.container'
import DistanceRightContainer from './containers/rightPanel/distance.container'
import DriverScorecardContainer from './containers/fullScreen/driverScorecard.container'
import ReportsContainer from './containers/fullScreen/reports.container'
import ServiceContainer from './containers/fullScreen/service.container'
import LogisticsContainer from './containers/fullScreen/logistics.container'
import VideoClipsContainer from './containers/fullScreen/videoClips.container'
import MessengerView from './organisms/messengerView'
import Banner from './atoms/banner'

const { Footer } = Layout

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxHeight: '100vh',
    backgroundColor: '#F1F3F6',
  },
  footer: {
    height: '24px',
    width: '100%',
    backgroundColor: '#003375',
    padding: '0px 5px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: 'white',
  },
}

/** @NAV */
// Enum used for header/navigation
// Note that this is the order that is passed to header
const NavEnum = {
  MAP: 'map',
  REPORTS: 'reports',
  SERVICE: 'service',
  DRIVERSCORECARD: 'driver-scorecard',
  DISTANCE: 'distance',
  LANDMARKS: 'landmarks',
  ELD: 'eld',
  LOGISTICS: 'field-service',
  VIDEOCLIPS: 'video-clips',
}

class Main extends React.Component {
  static propTypes = {
    /** @App */
    setNavPath: PropTypes.func.isRequired,
    location: PropTypes.object,
    navPath: PropTypes.string,
    history: PropTypes.object,
    appHeight: PropTypes.number,
    newAlert: PropTypes.object,
    filterDevice: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    mapSettings: PropTypes.object,
    updateMapSettings: PropTypes.func.isRequired,
    /** @DeviceListHOC */
    deviceLoading: PropTypes.bool,
    deviceList: PropTypes.array.isRequired,
    filterTags: PropTypes.array,
    devices: PropTypes.array,
    refetchDevices: PropTypes.func.isRequired,
    driversForAssignment: PropTypes.array.isRequired,
    assignDriverToDevice: PropTypes.func.isRequired,
    setStarterInterrupt: PropTypes.func.isRequired,
    refetchDeviceListDrivers: PropTypes.func.isRequired,
    selectDevice: PropTypes.func.isRequired,
    unselectDevice: PropTypes.func.isRequired,
    selectedDevice: PropTypes.object,
    locateNow: PropTypes.object.isRequired,
    filterDeviceByTag: PropTypes.func.isRequired,
    existingTagList: PropTypes.array,
    noLocateNowTypeList: PropTypes.array.isRequired,
    hasGarmin: PropTypes.bool.isRequired,
    socketEvents: PropTypes.object,
    selectedDeviceSocketEvent: PropTypes.object,
    /** @LandmarksHOC */
    landmarks: PropTypes.array,
    selectedLandmark: PropTypes.object,
    findLandmarkCenter: PropTypes.func.isRequired,
    selectLandmark: PropTypes.func.isRequired,
    unselectLandmark: PropTypes.func.isRequired,
    deleteLandmark: PropTypes.func.isRequired,
    updateLandmark: PropTypes.func.isRequired,
    createLandmark: PropTypes.func.isRequired,
    landmarksLoading: PropTypes.bool,
    landmarksRefetch: PropTypes.func,
    filterLandmark: PropTypes.func.isRequired,
    overlayType: PropTypes.string,
    ifNewLandmark: PropTypes.bool,
    switchDrawingOverlay: PropTypes.func.isRequired,
    setIfNewLandmark: PropTypes.func.isRequired,
    changeLandmarkSortBy: PropTypes.func.isRequired,
    landmarkSortBy: PropTypes.string.isRequired,
    selectedLandmarkCenter: PropTypes.object,
    updateSelectedLandmarkCenter: PropTypes.func.isRequired,
    polygonCentroid: PropTypes.func.isRequired,
    landmarkImportAlert: PropTypes.object.isRequired,
    landmarkImportAlertOnClose: PropTypes.func.isRequired,
    uploadLandmarks: PropTypes.func.isRequired,
    downloadLandmarkTemplate: PropTypes.func.isRequired,
    uploadLandmarksLoading: PropTypes.bool,
    uploadLandmarksAndDeleteLoading: PropTypes.bool,
    /** @DistanceHOC */
    setIfCheckDistance: PropTypes.func.isRequired,
    ifCheckDistance: PropTypes.bool,
    distanceOrigin: PropTypes.object,
    sortedDevicelist: PropTypes.array,
    hoverDevice: PropTypes.func,
    hoveredDevice: PropTypes.object,
    hoveredDeviceIndex: PropTypes.number,
    wMatrix: PropTypes.func.isRequired,
    savingLandmark: PropTypes.bool,
    deletingLandmark: PropTypes.bool,
    setOriginFlag: PropTypes.bool,
    formatVehicleStatus: PropTypes.func.isRequired,
    setOriginPoint: PropTypes.func.isRequired,
    changeSortBy: PropTypes.func.isRequired,
    sortby: PropTypes.string,
    filterStr: PropTypes.string,
    filterLandmarkStr: PropTypes.string,
    searchby: PropTypes.string,
    changeSearchBy: PropTypes.func.isRequired,
    changeLandmarkSelection: PropTypes.func.isRequired,
    /** @helper */
    hasFeature: PropTypes.func.isRequired,
    getPermission: PropTypes.func.isRequired,
    /** @messenger */
    activeMessengers: PropTypes.array,
    closeMessenger: PropTypes.func,
    sendMessage: PropTypes.func,
    markMessagesRead: PropTypes.func,
    openMessenger: PropTypes.func,
    /** @contactMetaHOC */
    logo: PropTypes.object,
    minuteTZOffset: PropTypes.number.isRequired,
    writeContactMeta: PropTypes.func.isRequired,
    mapIconSize: PropTypes.string,
    mapIconDensity: PropTypes.string,
    listDensity: PropTypes.string,
    contactMetaLoading: PropTypes.bool,
  }

  static defaultProps = {
    // App
    appHeight: 700,
    location: null,
    navPath: NavEnum.MAP, // 'devices',
    loading: true,
    newAlert: null,
    mapSettings: null,
    selectedDevice: null,
    setOriginFlag: false,
    history: null,
    existingTagList: [],
    // deviceList.hoc
    devices: [],
    deviceLoading: false,
    filterTags: [],
    socketEvents: {},
    selectedDeviceSocketEvent: null,
    // landmarksHOC
    landmarks: [],
    selectedLandmark: null,
    overlayType: 'CIRCLE',
    ifNewLandmark: false,
    landmarksLoading: false,
    landmarksRefetch: null,
    ifCheckDistance: false,
    distanceOrigin: null,
    sortedDevicelist: [],
    hoverDevice: null,
    hoveredDevice: null,
    hoveredDeviceIndex: -1,
    savingLandmark: false,
    deletingLandmark: false,
    selectedLandmarkCenter: null,
    uploadLandmarksLoading: false,
    uploadLandmarksAndDeleteLoading: false,
    // distance
    sortby: '',
    searchby: '',
    filterStr: '',
    filterLandmarkStr: '',
    // messenger
    activeMessengers: [],
    closeMessenger: null,
    sendMessage: null,
    markMessagesRead: null,
    openMessenger: null,
    // contactMetaHOC
    logo: PropTypes.object,
    contactMetaLoading: false,
    listDensity: 'comfortable',
    mapIconDensity: 'comfortable',
    mapIconSize: 'large',
  }

  constructor(props) {
    super(props)

    this.state = {
      modalUI: {
        ifShowModal: false,
        modalType: null,
        modalProps: null,
      },
      /** Refer to NavEnum above for possible mainNav keys */
      mainNav: NavEnum.MAP,
      clipsSearchValue: '',
    }
  }

  // /**
  //  * Open Warning modal on mount of Main app page. Comment out
  //  * if you would like to remove warning modal
  //  */
  // componentDidMount() {
  //   this.showWarning()
  // }

  componentDidUpdate(prevProps) {
    const {
      newAlert, location, navPath,
    } = this.props

    // Force set the page based on the path
    const currentLocation = location.pathname.replace(/\//g, '')
    if (currentLocation !== navPath) {
      this.setNav(currentLocation)
    }

    if (!equal(prevProps, this.props)) {
      if (prevProps.newAlert !== newAlert) {
        if (newAlert) {
          if (newAlert.type === 'info') {
            message.info(newAlert.content)
          }
          if (newAlert.type === 'success') {
            message.success(newAlert.content)
          }
          if (newAlert.type === 'error') {
            message.error(newAlert.content)
          }
          if (newAlert.type === 'warning') {
            message.warning(newAlert.content)
          }
        }
      }
    }
  }

  /** Function to show warnings on login as of 9-24-2024 */
  showWarning = () => {
    AntModal.warning({
      title: 'Important update from Rhino',
      content: (
        <div style={{ paddingTop: 20 }}>
          <p>GPS Insight has acquired Rhino, and we&apos;re excited to offer GPS Insight services to all Rhino customers. </p>
          <p>While current Rhino users can still access the platform, no new vehicles can be added since Rhino has reached its end of life and won&apos;t receive further updates.</p>
          <p>GPS Insight is a better platform, and we&apos;re offering exclusive discounts to Rhino customers for upgrading. We appreciate your understanding during this transition and will continue to provide top-notch service throughout and beyond.</p>
          <p>Call us at (866) 477-4321 Ext. 2 to get started.</p>
        </div>
      ),

      // `GPS Insight has acquired Rhino, and we're excited to offer GPS
      //   Insight services to all Rhino customers. While current Rhino users can still access the platform,
      //   no new vehicles can be added since Rhino has reached its end of life and won't receive further updates. GPS Insight
      //   is a better platform, and we're offering exclusive discounts to Rhino customers for upgrading. We appreciate your understanding
      //   during this transition and will continue to provide top-notch service throughout and beyond. Call us at (866) 477-4321 Ext. 2 to get started.`,
      closable: true,
    })
  }

  /**
   * @public
   * @description Updates serach filter for video clips
   */
  updateClipSearch = (value) => {
    this.setState({ clipsSearchValue: value })
  }

  /**
   * @private
   * @description show tooltip on safari ipad to swipe up
   */
  renderSwipeUpTooltip = () => {
    const { appHeight } = this.props
    const { userAgent } = window.navigator
    const windowHeight = window.outerHeight

    const isLandscape = Math.abs(window.orientation) === 90
    const showTip = appHeight < windowHeight
    const isMobileSafari = (userAgent.indexOf('Macintosh') || userAgent.indexOf('iPad')) && userAgent.indexOf('CriOS') === -1
    // const isAndroid = userAgent.indexOf('Android')

    if (isLandscape && showTip && isMobileSafari) {
      return (
        <Tooltip
          title="Swipe up on address bar to hide."
          defaultVisible
          placement="bottom"
        >
          <span style={{ width: '100%', height: '1px', position: 'absolute' }} />
        </Tooltip>
      )
    }
    return null
  }

  /**
   * @public
   * @param {string} deviceListMode
   * @param {string} mapIconMode
   * @param {string} mapIconSizeMode
   */
  saveContactMeta = (deviceListMode, mapIconMode, mapIconSizeMode) => {
    const { writeContactMeta } = this.props
    if (writeContactMeta) {
      writeContactMeta(deviceListMode, mapIconMode, mapIconSizeMode)
    }
    this.setState({ deviceListMode, mapIconMode, mapIconSizeMode })
  }

  /**
   * Start of UI control functions for show/hide modal
   */
  selectModal = (type, props) => {
    this.setState({
      modalUI: {
        ifShowModal: true,
        modalType: type,
        modalProps: props || null,
      },
    })
  }

  closeModal = () => {
    this.setState({
      modalUI: {
        ifShowModal: false,
        modalType: null,
      },
    })
  }
  /* End of UI control for modal */

  /**
   * @param {*string} navString
   * @description Sets the nav state given string. Refer to const NavEnum above for possible values
   * @NOTE If adding new nav key/page, be sure to add the new key to the NavEnum and use the
   *  Enum when calling the value
   */
  setNav = (navString) => {
    const { location, setNavPath, history } = this.props
    const navOptions = Object.values(NavEnum)
    if (navOptions.includes(navString)) {
      const currentLocation = location.pathname.replace(/\//g, '')
      /** @analytics Record Navigation */
      Analytics.record({
        feature: 'header',
        page: `${currentLocation}`,
        event: `navigate_to_${navString}`,
      })
      if (navString === NavEnum.ELD) {
        window.open('http://eld.atsfleetmanagement.com/Login.aspx', '_blank')
      } else {
        setNavPath(navString)
        if (navString !== currentLocation) {
          history.push(`/${navString}`)
        }
      }
    }
  }

  logout = () => {
    localStorage.removeItem('rft-token')
    sessionStorage.removeItem('rft-token')
    window.location.replace('/login')
  }

  /**
   * @description Returns Map Page Content. This is a separate
   * function to set it as the default case in renderContainer()
   * @returns {Element}
   */
  returnMapPage = () => {
    const {
      // route
      navPath,
      // devices
      deviceList, mapSettings, deviceLoading, filterTags,
      updateMapSettings, selectDevice, selectedDevice, unselectDevice,
      locateNow, driversForAssignment, assignDriverToDevice, setStarterInterrupt,
      noLocateNowTypeList, selectedDeviceSocketEvent,
      filterDevice, existingTagList, filterDeviceByTag,
      filterStr,
      // landmark
      landmarks, landmarksLoading, landmarksRefetch, selectedLandmark, selectLandmark,
      unselectLandmark, createLandmark, findLandmarkCenter,
      // messenger
      openMessenger,
      // contactMeta
      minuteTZOffset, mapIconSize, mapIconDensity, listDensity,
    } = this.props

    return (
      <div style={{
        display: 'flex', flexDirection: 'row', alignItems: 'stretch', flex: 1, overflow: 'auto',
      }}
      >
        <DevicesLeftPanel
          deviceListMode={listDensity}
          mapIconMode={mapIconDensity}
          mapIconSizeMode={mapIconSize}
          filterDevice={filterDevice}
          existingTagList={existingTagList}
          filterDeviceByTag={filterDeviceByTag}
          deviceList={deviceList}
          selectedDevice={selectedDevice}
          selectDevice={selectDevice}
          filterStr={filterStr}
          mainNav={navPath}
          loading={deviceLoading}
          setNav={this.setNav}
          saveContactMeta={this.saveContactMeta}
          selectModal={this.selectModal}
          filterTags={filterTags}
        />
        <DevicesRightContainer
          mainNav={navPath}
          driversForAssignment={driversForAssignment}
          assignDriverToDevice={assignDriverToDevice}
          createLandmark={createLandmark}
          mapSettings={mapSettings}
          updateMapSettings={updateMapSettings}
          // devices={[]}
          devices={deviceList}
          locateNow={locateNow}
          selectDevice={selectDevice}
          selectedDevice={selectedDevice}
          unselectDevice={unselectDevice}
          deviceListMode={listDensity}
          mapIconMode={mapIconDensity}
          mapIconSizeMode={mapIconSize}
          selectModal={this.selectModal}
          setStarterInterrupt={setStarterInterrupt}
          noLocateNowTypeList={noLocateNowTypeList}
          selectedDeviceSocketEvent={selectedDeviceSocketEvent}
          // landmark props
          landmarks={landmarks}
          findLandmarkCenter={findLandmarkCenter}
          landmarksLoading={landmarksLoading}
          landmarksRefetch={landmarksRefetch}
          selectedLandmark={selectedLandmark}
          selectLandmark={selectLandmark}
          unselectLandmark={unselectLandmark}
          // messenger
          openMessenger={openMessenger}
          // contactMeta
          minuteTZOffset={minuteTZOffset}
        />
      </div>
    )
  }

  /**
   * @description Returns component based on current navPath.
   * @returns {Element}
   * @NOTE If adding new nav key/page, be sure to add the new key to the const NavEnum and use the
   *  Enum when calling the value
   */
  renderContainer = () => {
    const { clipsSearchValue } = this.state
    const {
      // route
      navPath,
      // meta
      existingTagList,
      // devices
      devices, deviceList, mapSettings, wMatrix, filterDevice, filterDeviceByTag,
      updateMapSettings, selectDevice, selectedDevice, unselectDevice,
      locateNow, filterTags,
      // landmark
      landmarks, landmarksLoading, landmarksRefetch, selectedLandmark, changeLandmarkSortBy,
      landmarkSortBy, selectLandmark, unselectLandmark, deleteLandmark, updateLandmark,
      createLandmark, filterLandmark, overlayType, ifNewLandmark, setIfNewLandmark,
      switchDrawingOverlay, savingLandmark, deletingLandmark, findLandmarkCenter,
      selectedLandmarkCenter, updateSelectedLandmarkCenter, polygonCentroid,
      // distance
      ifCheckDistance, distanceOrigin, sortedDevicelist,
      hoveredDevice, hoveredDeviceIndex, setIfCheckDistance, setOriginFlag,
      formatVehicleStatus, setOriginPoint, changeSortBy, hoverDevice, sortby, filterStr,
      filterLandmarkStr, searchby, changeSearchBy, changeLandmarkSelection,
      // app
      appHeight,
      // messenger
      openMessenger,
      // contactMeta,
      mapIconSize, mapIconDensity, listDensity,
    } = this.props

    switch (navPath) {
      case NavEnum.DISTANCE:
        return (
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems: 'stretch', flex: 1, overflow: 'auto',
          }}
          >
            <DistanceLeftPanel
              wMatrix={wMatrix}
              setNav={this.setNav}
              mainNav={navPath}
              ifCheckDistance={ifCheckDistance}
              deviceListMode={listDensity}
              mapIconMode={mapIconDensity}
              mapIconSizeMode={mapIconSize}
              saveContactMeta={this.saveContactMeta}
              filterDevice={filterDevice}
              existingTagList={existingTagList}
              filterTags={filterTags}
              filterDeviceByTag={filterDeviceByTag}
              sortedDevicelist={sortedDevicelist}
              selectedDevice={selectedDevice}
              selectDevice={selectDevice}
              formatVehicleStatus={formatVehicleStatus}
              setOriginPoint={setOriginPoint}
              changeSortBy={changeSortBy}
              hoverDevice={hoverDevice}
              sortby={sortby}
              filterStr={filterStr}
              setIfCheckDistance={setIfCheckDistance}
              selectModal={this.selectModal}
              searchby={searchby}
              changeSearchBy={changeSearchBy}
              hasLandmarks={landmarks && landmarks.length > 1}
              landmarks={landmarks}
              changeLandmarkSelection={changeLandmarkSelection}
              setOriginFlag={setOriginFlag}
            />
            <DistanceRightContainer
              ifCheckDistance={ifCheckDistance}
              distanceOrigin={distanceOrigin}
              sortedDevicelist={sortedDevicelist}
              mapSettings={mapSettings}
              updateMapSettings={updateMapSettings}
              devices={deviceList}
              selectDevice={selectDevice}
              selectedDevice={selectedDevice}
              unselectDevice={unselectDevice}
              hoveredDevice={hoveredDevice}
              hoveredDeviceIndex={hoveredDeviceIndex}
              deviceListMode={listDensity}
              mapIconMode={mapIconDensity}
              mapIconSizeMode={mapIconSize}
              mainNav={navPath}
              setIfCheckDistance={setIfCheckDistance}
              setOriginFlag={setOriginFlag}
              locateNow={locateNow}
              selectModal={this.selectModal}
            />
          </div>
        )

      case NavEnum.LANDMARKS:
        return (
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems: 'stretch', flex: 1, overflow: 'auto',
          }}
          >
            <LandmarksLeftPanel
              wMatrix={wMatrix}
              setNav={this.setNav}
              mainNav={navPath}
              deviceListMode={listDensity}
              mapIconMode={mapIconDensity}
              mapIconSizeMode={mapIconSize}
              saveContactMeta={this.saveContactMeta}
              // landmark props
              landmarks={landmarks}
              landmarksLoading={landmarksLoading}
              landmarksRefetch={landmarksRefetch}
              selectedLandmark={selectedLandmark}
              selectLandmark={selectLandmark}
              deleteLandmark={deleteLandmark}
              updateLandmark={updateLandmark}
              createLandmark={createLandmark}
              filterLandmark={filterLandmark}
              setIfNewLandmark={setIfNewLandmark}
              unselectLandmark={unselectLandmark}
              changeLandmarkSortBy={changeLandmarkSortBy}
              landmarkSortBy={landmarkSortBy}
              filterLandmarkStr={filterLandmarkStr}
              selectModal={this.selectModal}
            />
            <LandmarksRightContainer
              mainNav={navPath}
              mapSettings={mapSettings}
              updateMapSettings={updateMapSettings}
              devices={deviceList}
              // landmark props
              landmarks={landmarks}
              findLandmarkCenter={findLandmarkCenter}
              landmarksLoading={landmarksLoading}
              landmarksRefetch={landmarksRefetch}
              selectedLandmark={selectedLandmark}
              wMatrix={wMatrix}
              selectLandmark={selectLandmark}
              unselectLandmark={unselectLandmark}
              deleteLandmark={deleteLandmark}
              updateLandmark={updateLandmark}
              createLandmark={createLandmark}
              overlayType={overlayType}
              switchDrawingOverlay={switchDrawingOverlay}
              ifNewLandmark={ifNewLandmark}
              setIfNewLandmark={setIfNewLandmark}
              savingLandmark={savingLandmark}
              deletingLandmark={deletingLandmark}
              selectModal={this.selectModal}
              selectedLandmarkCenter={selectedLandmarkCenter}
              updateSelectedLandmarkCenter={updateSelectedLandmarkCenter}
              polygonCentroid={polygonCentroid}
            />
          </div>
        )
      case NavEnum.DRIVERSCORECARD:
        return (
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems: 'stretch', flex: 1, overflow: 'auto',
          }}
          >
            <DriverScorecardContainer appHeight={appHeight} />
          </div>
        )
      case NavEnum.REPORTS:
        return (
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems: 'stretch', flex: 1, overflow: 'auto',
          }}
          >
            <ReportsContainer
              mainNav={navPath}
              devices={devices}
              landmarks={landmarks}
              landmarksLoading={landmarksLoading}
              selectModal={this.selectModal}
            />
          </div>
        )
      case NavEnum.SERVICE:
        return (
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems: 'stretch', flex: 1, overflow: 'auto',
          }}
          >
            <ServiceContainer
              devices={devices}
              appHeight={appHeight}
            />
          </div>
        )
      case NavEnum.LOGISTICS:
        return (
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems: 'stretch', flex: 1, overflow: 'auto',
          }}
          >
            <LogisticsContainer
              devices={devices}
              appHeight={appHeight}
              landmarks={landmarks}
              landmarksLoading={landmarksLoading}
              // messenger
              openMessenger={openMessenger}
            />
          </div>
        )
      case NavEnum.VIDEOCLIPS:
        return (
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems: 'stretch', flex: 1, overflow: 'auto',
          }}
          >
            <VideoClipsContainer
              appHeight={appHeight}
              devices={devices}
              selectModal={this.selectModal}
              updateClipSearch={this.updateClipSearch}
              clipsSearchValue={clipsSearchValue}
            />
          </div>
        )
      case NavEnum.MAP:
        return this.returnMapPage()
      default:
        return this.returnMapPage()
    }
  }

  bannerView = () => {
    const style = { marginTop: 10, color: '#FFFFFF', padding: '5px 10px' }

    return (
      <p style={style}>
        <span style={{ fontWeight: 'bold' }}>
          Unlock the Offer for FREE Hardware & Enhanced Platform Features!
        </span>
        Upgrade to our latest hardware and explore new platform features with
        ease. Contact us for a smooth and effortless migration today! We’re
        here to help you every step of the way! (866) 477-4321 ext. 2
      </p>
      // <p style={style}>
      //   We are experiencing a phone outage today impacting inbound and outbound calls. We appreciate your patience during this time. If you need immediate assistance please email us at support@gpsinsight.com.
      // </p>
    )
  }

  render() {
    if (sessionStorage.getItem('rft-token') === null && localStorage.getItem('rft-token') === null) {
      window.location.replace('/login')
      return null
    }
    const {
      refetchDevices, devices, filterDeviceByTag, filterDevice,
      selectedDevice, landmarks, navPath, hasFeature,
      refetchDeviceListDrivers, getPermission, wMatrix,
      activeMessengers, closeMessenger, sendMessage, markMessagesRead,
      hasGarmin, logo, mapIconSize, mapIconDensity, listDensity,
      uploadLandmarks, downloadLandmarkTemplate, uploadLandmarksLoading,
      uploadLandmarksAndDeleteLoading, landmarkImportAlert,
      landmarkImportAlertOnClose,
    } = this.props
    const {
      modalUI,
    } = this.state
    const navKeys = NavEnum
    return (
      <div style={styles.container}>
        {this.renderSwipeUpTooltip()}
        <Banner view={this.bannerView()} backgroundColor="#c90000" />
        <Header
          {...this.props}
          {...modalUI}
          logo={logo}
          getPermission={getPermission}
          hasFeature={hasFeature}
          selectModal={this.selectModal}
          setNav={this.setNav}
          logout={this.logout}
          selectedKey={navPath}
          navKeys={navKeys}
          hasGarmin={hasGarmin}
        />
        {this.renderContainer()}
        <Footer style={styles.footer}>
          <MessengerView
            wMatrix={wMatrix}
            activeMessengers={activeMessengers}
            closeMessenger={closeMessenger}
            sendMessage={sendMessage}
            markMessagesRead={markMessagesRead}
          />
          {`v${packageJSON.version}`}
        </Footer>
        <Modal
          style={{ top: 70 }}
          key="modal"
          devices={devices}
          landmarks={landmarks}
          filterDevice={filterDevice}
          selectedDevice={selectedDevice}
          filterDeviceByTag={filterDeviceByTag}
          refetchDevices={refetchDevices}
          refetchDeviceListDrivers={refetchDeviceListDrivers}
          selectModal={this.selectModal}
          closeModal={this.closeModal}
          modalUI={modalUI}
          updateClipSearch={this.updateClipSearch}
          setNav={this.setNav}
          navPath={navPath}
          NavEnum={NavEnum}
          deviceListMode={listDensity}
          mapIconMode={mapIconDensity}
          mapIconSizeMode={mapIconSize}
          saveContactMeta={this.saveContactMeta}
          uploadLandmarks={uploadLandmarks}
          downloadLandmarkTemplate={downloadLandmarkTemplate}
          uploadLandmarksLoading={uploadLandmarksLoading}
          uploadLandmarksAndDeleteLoading={uploadLandmarksAndDeleteLoading}
          landmarkImportAlert={landmarkImportAlert}
          landmarkImportAlertOnClose={landmarkImportAlertOnClose}
          {...this.state}
          {...this.props}
        />
      </div>
    )
  }
}

export default compose(
  withRouter,
  helper(),
  ContactMetaHOC(),
  DeviceListHOC(),
  LandmarksHOC(),
  DistanceHOC(),
  mapHOC(),
  messengerHOC(),
)(Main)
