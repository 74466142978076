import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'antd'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}

export default class SwitchOption extends Component {
  static propTypes = {
    label: PropTypes.string,
    defaultChecked: PropTypes.bool,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    label: 'Option',
    defaultChecked: false,
    onChange: () => {},
  }

  onChange = (checked) => {
    const { label, onChange } = this.props
    if (onChange) onChange(checked)
    // eslint-disable-next-line no-console
    else console.log(`${label} checked = ${checked}`)
  }

  render() {
    const { label, defaultChecked } = this.props
    return (
      <div style={styles.container}>
        <div style={{
          display: 'flex', flexDirection: 'row', flexGrow: '1', justifyContent: 'space-between',
        }}
        >
          <h4 style={{ fontWeight: 'normal' }}>{label}</h4>
          <Switch defaultChecked={defaultChecked} onChange={this.onChange} />
        </div>
      </div>
    )
  }
}
