import React, { Component } from 'react'
import PropTypes from 'prop-types'
import equal from 'deep-equal'
import NotificationMenu from '@atom/notificationMenu'
import RightContainer from '@mol/notificationSettings/notificationRightContainer'
import NotifSettingsHOC from '@hoc/notifSettings.hoc'

/**
 * @todo
 * - get "notifications" object value from stored procedure call
 */

class NotificationSettings extends Component {
    state = {
      currentMenuSelection: '1',
      editMode: false,
      checkedDevices: [],
      checkedDeviceKeys: [],
      tableLoading: false,
      collapsed: false,
      notifications: {},
    }

    static propTypes = {
      wMatrix: PropTypes.func.isRequired,
      getUserAlertSettings: PropTypes.func.isRequired,
    }

    /**
     * updating info here should update elsewhere, simply add the menu value in notificationMenu.js
     * ==========> This info should eventually be gathered from a stored procedure and
     * mapped in the following format
     */
    makeNotifications = () => {
      const { wMatrix } = this.props
      this.setState({
        notifications: {
          1: {
            title: wMatrix('DeviceBattery'),
            description: wMatrix('deviceBatteryDescription'),
            keyVal: '1',
            exceptionTypeIds: [17],
            eventTypeIds: [17],
            detailed: false,
          },
          2: {
            title: wMatrix('Speeding'),
            description: wMatrix('speedingDescription'),
            keyVal: '2',
            exceptionTypeIds: [30, 1],
            eventTypeIds: [30, 1],
            detailed: true,
          },
          3: {
            title: wMatrix('DTC'),
            description: wMatrix('dtcDescription'),
            keyVal: '3',
            exceptionTypeIds: [31],
            eventTypeIds: [31],
            detailed: false,
          },
          4: {
            title: wMatrix('HoursOfOperations'),
            description: wMatrix('hooDescription'),
            keyVal: '4',
            exceptionTypeIds: [5],
            eventTypeIds: [5],
            detailed: true,
          },
          5: {
            title: wMatrix('Landmarks'),
            description: wMatrix('landmarksDescription'),
            keyVal: '5',
            exceptionTypeIds: [6], // [2],
            eventTypeIds: [6], // [2],
            detailed: true,
          },
          6: {
            title: wMatrix('ReconnectDisconnect'),
            description: wMatrix('reconDisconDescription'),
            keyVal: '6',
            exceptionTypeIds: [3, 21],
            eventTypeIds: [3, 21],
            detailed: false,
          },
          7: {
            title: wMatrix('AccelerationDeceleration'),
            description: wMatrix('accelerationDescription'),
            keyVal: '7',
            exceptionTypeIds: [14, 15],
            eventTypeIds: [14, 15],
            detailed: false,
          },
          8: {
            titleShort: wMatrix('PTO'),
            title: wMatrix('powerTakeOffSwitches'),
            description: wMatrix('ptoDescription'),
            keyVal: '8',
            exceptionTypeIds: [37, 38, 39, 40],
            eventTypeIds: [37, 38, 39, 40],
            detailed: false,
          },
          9: {
            title: wMatrix('Panic Button'),
            description: wMatrix('panicButtonDescription'),
            keyVal: '9',
            exceptionTypeIds: [29],
            eventTypeIds: [29],
            detailed: false,
          },
          10: {
            title: wMatrix('Stop'),
            description: wMatrix('stopDescription'),
            keyVal: '10',
            exceptionTypeIds: [34],
            eventTypeIds: [34],
            detailed: false,
          },
          11: {
            title: wMatrix('MovementInitiatedConcluded'),
            description: wMatrix('movementAlertDescription'),
            keyVal: '11',
            exceptionTypeIds: [27, 20],
            eventTypeIds: [27, 20],
            detailed: false,
          },
          12: {
            title: wMatrix('fuelcardMisuse'),
            description: wMatrix('fuelcardAlertDescription'),
            keyVal: '12',
            exceptionTypeIds: [36],
            eventTypeIds: [36],
            detailed: false,
          },
          13: {
            title: wMatrix('serviceDue'),
            description: wMatrix('serviceAlertDescription'),
            keyVal: '13',
            exceptionTypeIds: [52],
            eventTypeIds: [52],
            detailed: false,
          },
        },
      })
    }

    // rename of the componentWillMount since react 16.3
    UNSAFE_componentWillMount = () => {
      this.makeNotifications()
    }

    componentDidMount = () => {
      const { currentMenuSelection, notifications } = this.state
      const { getUserAlertSettings } = this.props
      const eventTypes = notifications[currentMenuSelection].eventTypeIds
      getUserAlertSettings(eventTypes)
    }

    componentDidUpdate = (prevProps, prevState) => {
      const { currentMenuSelection, notifications } = this.state
      const { getUserAlertSettings } = this.props
      if (!equal(prevState.currentMenuSelection, currentMenuSelection)) {
        const eventTypes = notifications[currentMenuSelection].eventTypeIds
        getUserAlertSettings(eventTypes)
      }
    }

    updateSelectedRows = (arrayOfDevices, deviceKeys) => {
      this.setState({ checkedDevices: arrayOfDevices })
      this.setState({ checkedDeviceKeys: deviceKeys })
    }

    handleMenuSelect = (event) => {
      this.setState({ checkedDevices: [] })
      this.setState({ checkedDeviceKeys: [] })
      this.setState({ currentMenuSelection: event.key })
    }

    handleEditClick = () => {
      const { editMode } = this.state
      if (editMode === false) {
        this.setState({ editMode: true })
      }
    }

    handleSaveClick = () => {
      const { editMode } = this.state
      if (editMode === true) {
        this.setState({ editMode: false })
        this.setState({ checkedDeviceKeys: [] })
        this.setState({ checkedDevices: [] })
        // wait for update => set to false when props update
        this.setState({ tableLoading: true })
      }
    }

    handleCancelClick = () => {
      const { editMode } = this.state
      if (editMode === true) {
        this.setState({ editMode: false })
        this.setState({ checkedDeviceKeys: [] })
        this.setState({ checkedDevices: [] })
      }
    }

    // Table Methods
    tableLoadingOff = () => {
      const { tableLoading } = this.state
      if (tableLoading === true) {
        this.setState({ tableLoading: false })
      }
    }

    toggleCollapsed = () => {
      const { collapsed } = this.state
      const collapse = !collapsed
      this.setState({ collapsed: collapse })
    }

    collapseMenu = () => {
      this.setState({ collapsed: true })
    }

    openMenu = () => {
      this.setState({ collapsed: false })
    }

    render() {
      const {
        currentMenuSelection, editMode, collapsed, checkedDeviceKeys, checkedDevices, tableLoading,
        notifications, refetchAlertSettings, numMOSL,
      } = this.state
      return (
        <div style={{ display: 'flex' }}>
          <NotificationMenu
            handleSelect={this.handleMenuSelect}
            currentSelection={currentMenuSelection}
            notifications={notifications}
            editMode={editMode}
            toggleCollapsed={this.toggleCollapsed}
            collapseVal={collapsed}
            collapseMenu={this.collapseMenu}
            openMenu={this.openMenu}
            {...this.props}
          />
          <RightContainer
            checkedDeviceKeys={checkedDeviceKeys}
            checkedDevices={checkedDevices}
            updateSelectedRows={this.updateSelectedRows}
            handleSelect={this.handleMenuSelect}
            currentSelection={currentMenuSelection}
            notifications={notifications}
            editMode={editMode}
            handleEditClick={this.handleEditClick}
            handleSaveClick={this.handleSaveClick}
            handleCancelClick={this.handleCancelClick}
            tableLoading={tableLoading}
            turnOffTableLoading={this.tableLoadingOff}
            collapseMenu={this.collapseMenu}
            openMenu={this.openMenu}
            refetchAlertSettings={refetchAlertSettings}
            numMOSL={numMOSL}
            {...this.props}
          />
        </div>
      )
    }
}

export default NotifSettingsHOC()(NotificationSettings)
