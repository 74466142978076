import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button, Icon, Select, Alert,
} from 'antd'
import ModalHeader from '@mol/modalHeader'
import NewDriverForm from '@mol/newDriverForm'
import EditableDriverTable from '@mol/editableDriverTable'
import DriverMgrHOC from '@hoc/driverMgr.hoc'

/**
 * @todo
 * - CALL REFETCH OR CALL QUERY to update what devices are available in availableDeviceOptions
 */

const { Option } = Select

class DriverPanel extends Component {
    state = {
      showAddDriver: false,
      showELD: false,
    }

    static propTypes = {
      assignedDevices: PropTypes.array,
      wMatrix: PropTypes.func.isRequired,
      getPermission: PropTypes.func.isRequired,
      hasFeature: PropTypes.func.isRequired,
      drivers: PropTypes.array,
      updateDriver: PropTypes.func.isRequired,
      getDrivers: PropTypes.func,
      driversLoading: PropTypes.bool,
      deleteDriver: PropTypes.func.isRequired,
      createDriver: PropTypes.func.isRequired,
      notificationMessage: PropTypes.string,
      notificationType: PropTypes.string,
      notificationFlag: PropTypes.bool,
    }

    static defaultProps = {
      assignedDevices: null,
      drivers: null,
      driversLoading: false,
      getDrivers: null,
      notificationMessage: '',
      notificationType: '',
      notificationFlag: false,
    }

    onCancel = () => {
      this.setState({ showAddDriver: false })
    }

    toggleAddDriver = () => {
      const { showAddDriver } = this.state
      this.setState({ showAddDriver: !showAddDriver })
    }

    showELDToggle = () => {
      const { showELD } = this.state
      if (showELD === true) {
        this.setState({ showELD: false })
      } else this.setState({ showELD: true })
    }

    /**
     * @description creates option map based on available devices
     */
    availableDeviceOptions = (defaultDeviceId) => {
      const { assignedDevices, wMatrix } = this.props
      const deviceList = assignedDevices
      const devicesClone = []
      // add unassign option
      devicesClone.push({ id: 0, alias: wMatrix('unassigned') })

      // create copy to not overwrite original data
      for (const obj in deviceList) {
        if (Object.prototype.hasOwnProperty.call(deviceList, obj)) {
          const device = deviceList[obj]
          devicesClone.push(device)
        }
      }
      return (devicesClone.map((device) => {
        // TODO: maybe add group dots to the option display
        const { driver, fobEnabled } = device
        const disabled = !!(driver && device.id !== defaultDeviceId) || fobEnabled
        return (
          <Option
            value={device.id}
            key={device.id}
            label={device.alias}
            style={disabled ? { color: 'rgba(0,0,0,0.25)' } : {}}
            disabled={disabled}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                {device.alias}
              </div>
              <div style={{ marginLeft: 2 }}>
                {device.currentDriver ? `Assigned : ${device.currentDriver.firstName} ${device.currentDriver.lastName}` : null}
              </div>
            </div>
          </Option>
        )
      }))
    }

    render() {
      const { showAddDriver } = this.state
      const {
        wMatrix, hasFeature, getPermission, drivers, assignedDevices, updateDriver,
        getDrivers, driversLoading, deleteDriver, createDriver,
        notificationFlag, notificationMessage, notificationType,
      } = this.props
      return (
        <div>
          <div style={{ display: 'flex', width: '100%' }}>
            <ModalHeader
              headerName={wMatrix('Drivers')}
              description=""
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Button type="primary" onClick={this.toggleAddDriver} disabled={showAddDriver}>
              {wMatrix('addDriver')}
            </Button>
            {notificationFlag
              ? (
                <Alert
                  style={{ width: '275px', marginLeft: '10px' }}
                  closable
                  showIcon
                  message={wMatrix(notificationMessage)}
                  type={notificationType}
                />
              )
              : null
            }
            {hasFeature('ELD') ? (
              <Button
                style={{ marginLeft: 'auto' }}
                type="danger"
                onClick={this.showELDToggle}
                disabled
                ghost
              >
                {wMatrix('manageELDDrivers')}
                <Icon type="right" />
              </Button>
            ) : null}
          </div>
          <div style={{ display: showAddDriver === true ? 'block' : 'none' }}>
            <NewDriverForm
              showToggle={this.toggleAddDriver}
              showFob={hasFeature('Fob')}
              showWexCard={hasFeature('Fuelcard')}
              wMatrix={wMatrix}
              getPermission={getPermission}
              onCreate={createDriver}
              availableDeviceOptions={this.availableDeviceOptions}
            />
          </div>
          <EditableDriverTable
            wMatrix={wMatrix}
            showFob={hasFeature('Fob')}
            showWexCard={hasFeature('Fuelcard')}
            getPermission={getPermission}
            hasFeature={hasFeature}
            availableDeviceOptions={this.availableDeviceOptions}
            drivers={drivers}
            devices={assignedDevices}
            updateDriver={updateDriver}
            getDrivers={getDrivers}
            driversLoading={driversLoading}
            deleteDriver={deleteDriver}
          />
        </div>
      )
    }
}

export default DriverMgrHOC()(DriverPanel)
