import React, { Component } from 'react'
import { compose } from 'react-apollo'
import PropTypes from 'prop-types'

import consumerConnector from '@graphql/consumerConnector'
import queryConnector from '@graphql/queryConnector'
import {
  batteryReport,
  driversQuery,
  companyGroupQuery,
  getAllPersonalLabels,
  reportMetaData,
  eventsReport,
  speedThresholdReport,
  afterHoursReport,
  eventTypes,
  locationsReport,
  fuelCardTransactionReport,
  fuelCardMileageReport,
  mileageReport,
  odometerReport,
  tripsReport,
  stopsReport,
  idleReport,
  engineHoursReport,
  hoursOfOperationReport,
  reportSchedule,
  reportScheduleFrequencies,
  reportScheduleDateRanges,
  serviceTicketReport,
  serviceTypes,
  serviceTicketStatus,
  dscReport,
} from '@graphql/query'
import {
  createReportDownloadQueueItem,
} from '@graphql/mutation'
import equal from 'deep-equal'
import helper from '@helper'
import mutationConnector from '@graphql/mutationConnector'
import moment from 'moment'

/**
 * How we will receive the report metaData
 * report: {
 *  id,
 *  contactId (if null, global) ,
 *  clientId,
 *  name,
 *  outputBlob (json),
 *  inputBlob (json),
 *  scheduled,
 *  category ('custom', 'standard', 'legacy'),
 *      Note: 'recently used' will be made from local storage?
 * }
 * @NOTE
 * - Below is an early version of reporting hoc. Note that we'd ideally clean the portions noted
 * below when the time allows
 */
const reportsHOC = () => (WrappedComponent) => {
  class ReportsHOC extends Component {
    static propTypes = {
      /** @helper */
      wMatrix: PropTypes.func.isRequired,
      speedToString: PropTypes.func.isRequired,
      eventToString: PropTypes.func.isRequired,
      minutesToString: PropTypes.func.isRequired,
      /** @queryData */
      devices: PropTypes.array,
      landmarks: PropTypes.array,
      apolloClient: PropTypes.object.isRequired,
      driversQueryData: PropTypes.object.isRequired,
      groupsQuery: PropTypes.object.isRequired,
      labelsQuery: PropTypes.object.isRequired,
      metaQuery: PropTypes.object.isRequired,
      eventTypesQuery: PropTypes.object.isRequired,
      serviceTypesQuery: PropTypes.object.isRequired,
      serviceTicketStatusQuery: PropTypes.object.isRequired,
      scheduledReports: PropTypes.object.isRequired,
      frequencyOptions: PropTypes.object.isRequired,
      dateRangeOptions: PropTypes.object.isRequired,
      createReportDownloadQueue: PropTypes.func,
    }

    static defaultProps = {
      devices: [],
      landmarks: [],
      createReportDownloadQueue: null,
    }

    state = {
      menuFilter: '',
      selectedReport: null,
      generatedData: [],
      reportLoading: false,
      showDownloadButton: false,
      reportsMenu: [
        {
          title: 'Scheduled',
          items: [],
          id: 'g0',
          icon: {
            type: 'antd',
            name: 'scheduled',
          },
        },
        /* 2021-01-06 this needs to be hidden until it is availble
           https://trello.com/c/q2FTJ2ei/2189-edit-reports-menu-1-1
        */
        /*
        {
          title: 'Custom',
          items: [],
          id: 'g1',
          icon: {
            type: 'antd',
            name: 'form',
          },
        },
        */
        {
          title: 'Standard',
          items: [],
          id: 'g2',
          icon: {
            type: 'antd',
            name: 'file-text',
          },
        },
      ],
      reportDownloadMeta: null,
      ifShowReportDownloadModal: false,
      ifReportTooLarge: false,
    }

    /**
     * @description - this is used to make sure the menu loads based on the metadata pulled
     */
    componentDidUpdate = (prevProps) => {
      const { metaQuery } = this.props
      if (!equal(prevProps.metaQuery, metaQuery)) {
        // compose the menu
        if (metaQuery.data && metaQuery.data.reportMeta) {
          this.createMenu(metaQuery.data.reportMeta)
        }
      }
    }

    /**
     * @description - creates report menu based on meta data
     */
    createMenu = (menuData) => {
      const { wMatrix } = this.props
      const scheduledItems = []
      const customItems = []
      const standardItems = []
      const sortedMenu = menuData.sort((a, b) => (a.title > b.title ? 1 : -1))
      for (let i = 0; i < sortedMenu.length; i += 1) {
        const report = sortedMenu[i]
        switch (report.category) {
          case 'standard':
            standardItems.push({
              id: report.id,
              title: wMatrix(report.type),
              type: report.type,
            })
            break
          case 'custom':
            customItems.push({
              id: report.id,
              title: report.title,
              type: report.type,
            })
            break
          case 'scheduled':
            scheduledItems.push({
              id: report.id,
              title: report.title,
              type: report.type,
            })
            break
          default:
            break
        }
      }
      const menu = [
        {
          title: wMatrix('Scheduled'),
          items: scheduledItems,
          id: 'g0',
          icon: {
            type: 'antd',
            name: 'scheduled',
          },
        },

        /**
         * Hide the custom until it is ready
         */
        // {
        //   title: wMatrix('Custom'),
        //   items: customItems,
        //   id: 'g1',
        //   icon: {
        //     type: 'antd',
        //     name: 'form',
        //   },
        // },
        {
          title: wMatrix('Standard'),
          items: standardItems,
          id: 'g2',
          icon: {
            type: 'antd',
            name: 'file-text',
          },
        },
      ]

      this.setState({ reportsMenu: menu })
    }

    refetchMetaAndSchedule = async (shouldClear = false) => {
      if (shouldClear) this.setState({ selectedReport: null })

      const { metaQuery, scheduledReports } = this.props
      const { selectedReport } = this.state
      if (metaQuery && metaQuery.data && scheduledReports && scheduledReports.data) {
        await metaQuery.refetch()
        await scheduledReports.refetch().then((res) => {
          // below is to refetch the schedule for the selected report
          if (selectedReport
            && selectedReport.category === 'scheduled'
            && res.data.reportSchedule
            && res.data.reportSchedule.length > 0
          ) {
            for (const scheduledReport of res.data.reportSchedule) {
              if (selectedReport.id === scheduledReport.reportId) {
                this.setState({
                  selectedReport: {
                    category: 'scheduled',
                    id: selectedReport.id,
                    inputBlob: scheduledReport.inputBlob,
                    outputBlob: scheduledReport.outputBlob,
                    title: scheduledReport.name,
                    type: selectedReport.type,
                  },
                })
              }
            }
          }
        })
      }
    }

    /**
     * @public
     * @returns {void}
     * @description - Selects a report given a report id and sets the state to have
     * the report metadata
     */
    selectReport = (reportId) => {
      this.setState({
        selectedReport: null,
        generatedData: [],
        reportLoading: false,
        showDownloadButton: false,
      })
      const { metaQuery } = this.props
      const reportsMetaData = metaQuery.data.reportMeta
      if (reportsMetaData) {
        for (let i = 0; i < reportsMetaData.length; i += 1) {
          const report = reportsMetaData[i]
          if (report.id === parseInt(reportId, 10)) {
            const copyReport = JSON.parse(JSON.stringify(report))
            // copyReport.outputBlob = this.formatColumns(report.outputBlob)
            this.setState({
              selectedReport: copyReport,
              generatedData: [],
            })
          }
        }
      }
    }

    /**
     * @private
     * @returns {Promise => Object}
     * @description - Runs the necessary report query with the given variables.
     */
    executeQuery = (reportQuery, reportVariables) => new Promise((resolve, reject) => {
      const { apolloClient } = this.props
      return apolloClient.query({
        query: reportQuery,
        fetchPolicy: 'network-only',
        variables: reportVariables,
      }).then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
    })

    /**
     * @public
     * @description - updates filterString state
     */
    filterMenu = (filterString) => {
      this.setState({ menuFilter: filterString })
    }

    /**
     * @private
     * @description - returns filtered menu clone based on state filter
     */
    returnFilteredMenu = () => {
      const { menuFilter, reportsMenu } = this.state
      const menuClone = JSON.parse(JSON.stringify(reportsMenu))
      for (let i = 0; i < menuClone.length; i += 1) {
        if (menuClone[i].items && menuFilter) {
          menuClone[i].items = menuClone[i].items.filter(
            item => item.title.toLowerCase().includes(menuFilter.toLowerCase()),
          )
        }
      }
      return menuClone
    }

    /**
     * @description - returns devices given groupIds
     */
    returnDevicesWithGroup = (groupIds) => {
      const { devices } = this.props
      const deviceList = JSON.parse(JSON.stringify(devices))
      const selectDeviceIds = []
      for (const device in deviceList) {
        if (deviceList[device].groups && deviceList[device].groups.length > 0) {
          const { groups } = deviceList[device]
          for (const group in groups) {
            if (groupIds.includes(groups[group].id)) {
              selectDeviceIds.push(deviceList[device].id)
              break
            }
          }
        }
      }
      return selectDeviceIds
    }

    /**
     * @description - returnDevices given labelIds
     */
    returnDevicesWithLabel = (labelIds) => {
      const { devices } = this.props
      const deviceList = JSON.parse(JSON.stringify(devices))
      const selectDeviceIds = []
      for (const device in deviceList) {
        if (deviceList[device].labels && deviceList[device].labels.length > 0) {
          const { labels } = deviceList[device]
          for (const label in labels) {
            if (labelIds.includes(labels[label].id)) {
              selectDeviceIds.push(deviceList[device].id)
              break
            }
          }
        }
      }
      return selectDeviceIds
    }

    /**
     * @private
     * @returns {Object}
     * @description - takes different date/time types and formats into start/endDateTimes
     *
     * @Note - This may be something to move to the gql level/ or revisit the naming
     * of gql variables.
     */
    formatTimeFilters = (filters) => {
      let startDateTime = null
      let endDateTime = null
      // datetime
      if (filters.startDateTime && filters.endDateTime) {
        startDateTime = filters.startDateTime.format('YYYY-MM-DD HH:mm:ss')
        endDateTime = filters.endDateTime.format('YYYY-MM-DD HH:mm:ss')
      } if (filters.dateRange && filters.startTime && filters.endTime) {
        startDateTime = `${filters.dateRange[0].format('YYYY-MM-DD')} ${filters.startTime.format('HH:mm:ss')}`
        endDateTime = `${filters.dateRange[1].format('YYYY-MM-DD')} ${filters.endTime.format('HH:mm:ss')}`
      }

      // if startDate and endDate
      if (filters.startDate && filters.endDate) {
        if (filters.startTime && filters.endTime) {
          // if we also have a startTime and endTime, combine
          startDateTime = `${filters.startDate.format('YYYY-MM-DD')} ${filters.startTime.format('HH:mm:ss')}`
          endDateTime = `${filters.endDate.format('YYYY-MM-DD')} ${filters.endTime.format('HH:mm:ss')}`
        } else if (filters.startDate) {
          startDateTime = filters.startDate
          endDateTime = `${filters.endDate.format('YYYY-MM-DD')}`
        } else {
          // if no start/endTime, just use dates
          startDateTime = `${filters.startDate.format('YYYY-MM-DD')}`
          endDateTime = `${filters.endDate.format('YYYY-MM-DD')}`
        }
      }
      return {
        startDateTime,
        endDateTime,
      }
    }

    formatReportArgs = (filters) => {
      const { devices } = this.props
      const { selectedReport } = this.state
      const keyedData = []
      let query = null
      let resultKey = ''
      let events = []
      switch (selectedReport.type) {
        // after hours report
        case 'afterHours': {
          query = afterHoursReport
          resultKey = 'afterHoursReport'
          break
        }
        // battery report
        case 'battery': {
          query = batteryReport
          resultKey = 'batteryReport'
          break
        }
        // trips
        case 'trips': {
          query = tripsReport
          resultKey = 'tripsReport'
          break
        }
        // ignition on/off
        case 'ignition': {
          query = eventsReport
          resultKey = 'eventsReport'
          events = ['ignition_on', 'ignition_off']
          break
        }
        // locations report
        case 'location': {
          query = locationsReport
          resultKey = 'locationReport'
          break
        }
        // movement
        case 'movement': {
          query = eventsReport
          resultKey = 'eventsReport'
          events = ['start', 'stop']
          break
        }
        // mosl report
        case 'mosl': {
          query = eventsReport
          resultKey = 'eventsReport'
          events = ['mosl']
          break
        }
        // stops report
        case 'stop': {
          query = stopsReport
          resultKey = 'stopsReport'
          break
        }
        // idle on/off
        case 'idle': {
          query = idleReport
          resultKey = 'idleReport'
          break
        }
        // mileage report
        case 'mileage': {
          query = mileageReport
          resultKey = 'mileageReport'
          break
        }
        // odometer
        case 'odometer': {
          query = odometerReport
          resultKey = 'odometerReport'
          break
        }
        // pto
        case 'pto': {
          query = eventsReport
          resultKey = 'eventsReport'
          events = ['pto_primary_on', 'pto_primary_off', 'pto_secondary_on', 'pto_secondary_off', 'pto_tertiary_on', 'pto_tertiary_off']
          break
        }
        // events report
        case 'event': {
          query = eventsReport
          resultKey = 'eventsReport'
          break
        }
        // hours of operation report
        case 'hoursOfOperation': {
          query = hoursOfOperationReport
          resultKey = 'hoursOfOperationReport'
          break
        }
        // speed threshold report
        case 'speedThreshold': {
          query = speedThresholdReport
          resultKey = 'speedThresholdReport'
          break
        }
        // fuelcard transactions
        case 'fuelCardTransaction': {
          query = fuelCardTransactionReport
          resultKey = 'fuelCardTransactionReport'
          break
        }
        case 'fuelCardMileage': {
          query = fuelCardMileageReport
          resultKey = 'fuelCardMileageReport'
          break
        }
        case 'service': {
          query = serviceTicketReport
          resultKey = 'serviceTicketReport'
          break
        }
        case 'engineHours': {
          query = engineHoursReport
          resultKey = 'engineHoursReport'
          break
        }
        case 'driverScorecard': {
          query = dscReport
          resultKey = 'dscReport'
          break
        }
        // default to events report
        default:
          query = eventsReport
          resultKey = 'eventsReport'
          break
      }

      // if we added static events format and use those
      if (filters.events && filters.events.length !== 0) {
        ({ events } = filters)
      }
      // format different date/time values
      const timeFilters = this.formatTimeFilters(filters)

      let deviceIdArray = selectedReport.type === 'fuelCardTransaction' ? [] : devices.map(i => i.id)
      if (filters.deviceIds !== undefined) {
        if (filters.deviceIds.length > 0) {
          deviceIdArray = filters.deviceIds.map(i => i)
        }
      }
      if (filters.groupIds !== undefined && filters.labelIds !== undefined) {
        deviceIdArray = []
        if (filters.groupIds.length > 0) {
          deviceIdArray = deviceIdArray.concat(this.returnDevicesWithGroup(filters.groupIds))
        }
        if (filters.labelIds.length > 0) {
          deviceIdArray = deviceIdArray.concat(this.returnDevicesWithLabel(filters.labelIds))
        }
      }

      return {
        query,
        selectedReport,
        deviceIdArray,
        timeFilters,
        events,
        resultKey,
        keyedData,
      }
    }

    /**
     * @public
     * @description - generates the data given the input values. This decides what query to call and
     * calls it
     *
     * @Note - Trips is ommitted and will be added in a separate branch
     */
    handleGenerateData = async (filters) => {
      const formattedArgs = this.formatReportArgs(filters)
      this.setState({ reportLoading: true })
      const {
        query, selectedReport, deviceIdArray, timeFilters, events, resultKey,
      } = formattedArgs
      const { minutesToString, wMatrix, speedToString } = this.props
      this.setState({
        reportDownloadMeta: {
          reportId: selectedReport.id,
          deviceIds: deviceIdArray,
          startDateTime: timeFilters.startDateTime,
          endDateTime: timeFilters.endDateTime,
          driverIds: filters.driverIds || null,
          landmarkIds: filters.landmarkIds || null,
          speed: filters.speedThreshold || null,
          typeIds: filters.serviceTypes || null,
          serviceTicketStatus: filters.serviceStatus || null,
          events,
        },
      })

      let { keyedData } = formattedArgs

      // call the query using the given input filters and choice of query
      const result = await this.executeQuery(query, {
        reportId: selectedReport.id,
        deviceIds: deviceIdArray,
        startDateTime: timeFilters.startDateTime,
        endDateTime: timeFilters.endDateTime,
        driverIds: filters.driverIds || null,
        landmarkIds: filters.landmarkIds || null,
        speed: filters.speedThreshold || null,
        typeIds: filters.serviceTypes || null,
        serviceTicketStatusIds: filters.serviceStatus || null,
        events,
      })
      if (result[resultKey]) {
        if (result[resultKey].rows) {
          keyedData = result[resultKey].rows.map((r, i) => Object.assign(r, { key: i }))
        } else {
          /** @Note - temporary - trips does not have rows structure yet */
          keyedData = result[resultKey]
        }
        if (result[resultKey].downloadFlag) {
          this.setState({ ifShowReportDownloadModal: true, ifReportTooLarge: true })
        }
      }
      // Add time and date keys to object
      /**
       * @Note - ideally, this type of logic should be in the gql and not here
       */
      if (keyedData.length > 0) {
        if (selectedReport.category === 'scheduled') {
          keyedData = keyedData.slice(0, 10)
        }
        keyedData.map((row) => {
          if (row.datetime) {
            Object.assign(row, { datetimeUnix: moment(row.datetime, 'YYYY-MM-DD HH:mm a').unix() })
          } else if (row.date && row.firstMoveTime) {
            Object.assign(row, { datetimeUnix: moment(`${row.date} ${row.firstMoveTime}`, 'YYYY-MM-DD HH:mm a').unix() })
          }

          if (row.eventType) {
            Object.assign(row, { event: row.eventType })
          }

          if (row.details) {
            try {
              const details = JSON.parse(row.details)
              if (row.event === 'stop' || row.event === 'idle') {
                Object.assign(row, { details: `${wMatrix('Duration')}: ${minutesToString(details.duration)}` })
              }
              if (row.event === 'dtc') {
                Object.assign(row, { details: `${wMatrix('Diagnose Trouble Code')}: ${details.dtcCode}` })
              }
              if (row.event === 'landmark_exit') {
                Object.assign(row, { details: `${wMatrix('Exited Landmark')}: ${details.landmarks}` })
              }
              if (row.event === 'landmark_enter') {
                Object.assign(row, { details: `${wMatrix('Entered Landmark')}: ${details.landmarks}` })
              }
              if (row.event === 'mosl') {
                Object.assign(row, {
                  details: `${wMatrix('Driving at')} ${speedToString(details.speed)}, ${speedToString(details.mosl)} ${wMatrix('over the road speed')} ${speedToString(details.roadSpeed)}`,
                })
              }
              if (row.event === 'speeding') {
                Object.assign(row, {
                  details: `${wMatrix('Speeding at')} ${speedToString(details.speed)}`,
                })
              }
              if (row.event === 'driver_in') {
                let driverInDetails = `Fob ID: ${details.fobId}`
                if (details.fobDriver && details.fobDriver.name) {
                  driverInDetails = details.fobDriver.name
                }
                Object.assign(row, {
                  details: driverInDetails,
                })
              }
            } catch (e) {
              // json parse error
            }
          }

          return row
        })
      }
      if (keyedData && keyedData.length > 0) {
        this.setState({ showDownloadButton: true })
      }
      this.setState({
        reportLoading: false,
        generatedData: keyedData,
      })
    }

    /**
     * @description - returns the query name given the event type name?
     * @Note - I believe this should also be done in the gql level. It is used above to manipulate
     * the queried data and add the event field
     */
    returnEventName = (eventType) => {
      const { eventTypesQuery } = this.props
      if (eventTypesQuery.data && eventTypesQuery.data.eventTypes) {
        const types = eventTypesQuery.data.eventTypes
        for (const type of types) {
          if (type.queryName === eventType) {
            return type.displayName
          }
        }
      }
      return null
    }

    /**
     * @description - this formats the event types for use with the select box. (events with begin
     * and end are shown as just the event) These are then remapped in the above formatEventTypes
     * for query
     */
    formatEventTypesForUI = () => {
      const { eventTypesQuery } = this.props
      const eventTypesArray = []
      if (eventTypesQuery.data && eventTypesQuery.data.eventTypes) {
        const { data } = eventTypesQuery
        for (let i = 0; i < data.eventTypes.length; i += 1) {
          const event = data.eventTypes[i]
          const newEventType = {
            displayName: event.displayName,
            queryName: event.queryName,
            id: event.queryName,
          }
          eventTypesArray.push(newEventType)
        }
      }
      return eventTypesArray
    }

    setReportDownloadMeta = (filters) => {
      const formattedArgs = this.formatReportArgs(filters)
      const {
        selectedReport, deviceIdArray, timeFilters, events,
      } = formattedArgs

      this.setState({
        reportDownloadMeta: {
          reportId: selectedReport.id,
          deviceIds: deviceIdArray,
          startDateTime: timeFilters.startDateTime,
          endDateTime: timeFilters.endDateTime,
          driverIds: filters.driverIds || null,
          landmarkIds: filters.landmarkIds || null,
          speed: filters.speedThreshold || null,
          typeIds: filters.serviceTypes || null,
          serviceTicketStatus: filters.serviceStatus || null,
          events,
        },
      })
    }

    toggleReportDownloadModal = (ifShow) => {
      this.setState({ ifShowReportDownloadModal: ifShow, ifReportTooLarge: false })
    }

    createReportDownload = (type, inputBlob, email) => {
      const { createReportDownloadQueue } = this.props
      return createReportDownloadQueue({
        variables: {
          type,
          inputBlob,
          email,
        },
      }).then((res) => {
        if (res && res.data && res.data.createReportDownloadQueueItem) {
          if (res.data.createReportDownloadQueueItem.code === 1000) {
            return true
          }
        }
        return false
      }).catch(() => false)
    }

    render = () => {
      const {
        selectedReport, generatedData, reportLoading, reportDownloadMeta,
        ifShowReportDownloadModal, showDownloadButton, ifReportTooLarge,
      } = this.state

      const {
        wMatrix, driversQueryData, groupsQuery, labelsQuery, devices, landmarks,
        speedToString, scheduledReports, frequencyOptions, dateRangeOptions,
        serviceTypesQuery, serviceTicketStatusQuery,
        eventTypesQuery, eventToString, minutesToString,
      } = this.props

      // filter menu
      const filteredMenu = this.returnFilteredMenu()
      // format event types for ui menu
      const events = this.formatEventTypesForUI()
      return (
        <WrappedComponent
          wMatrix={wMatrix}
          speedToString={speedToString}
          eventToString={eventToString}
          minutesToString={minutesToString}
          reportMenu={filteredMenu}
          selectReport={this.selectReport}
          selectedReport={selectedReport}
          filterMenu={this.filterMenu}
          devices={devices}
          landmarks={landmarks}
          handleGenerateData={this.handleGenerateData}
          generatedData={generatedData}
          reportLoading={reportLoading}
          events={events}
          drivers={driversQueryData.data && driversQueryData.data.driver
            ? driversQueryData.data.driver : []
          }
          groups={groupsQuery.data && groupsQuery.data.group ? groupsQuery.data.group : []}
          labels={labelsQuery.data && labelsQuery.data.label ? labelsQuery.data.label : []}
          serviceTypes={serviceTypesQuery.data && serviceTypesQuery.data.serviceTypes
            ? serviceTypesQuery.data.serviceTypes : []
          }
          serviceTicketStatus={
            serviceTicketStatusQuery.data && serviceTicketStatusQuery.data.serviceTicketStatus
              ? serviceTicketStatusQuery.data.serviceTicketStatus
              : []
          }
          returnDevicesWithGroup={this.returnDevicesWithGroup}
          returnDevicesWithLabel={this.returnDevicesWithLabel}
          returnEventName={this.returnEventName}
          eventTypesArray={eventTypesQuery.data && eventTypesQuery.data.eventTypes
            ? eventTypesQuery.data.eventTypes : []
          }
          scheduledReports={scheduledReports.data && scheduledReports.data.reportSchedule
            ? scheduledReports.data.reportSchedule : []
          }
          freqOptions={frequencyOptions.data.reportScheduleFrequencies}
          dateRangeOptions={dateRangeOptions.data.reportScheduleDateRanges}
          refetchMetaAndSchedule={this.refetchMetaAndSchedule}
          showDownloadButton={showDownloadButton}
          setReportDownloadMeta={this.setReportDownloadMeta}
          ifShowReportDownloadModal={ifShowReportDownloadModal}
          ifReportTooLarge={ifReportTooLarge}
          toggleReportDownloadModal={this.toggleReportDownloadModal}
          reportDownloadMeta={reportDownloadMeta}
          createReportDownload={this.createReportDownload}
        />
      )
    }
  }
  return compose(
    helper(),
    consumerConnector(),
    queryConnector(serviceTypes, {}, 'serviceTypesQuery'),
    queryConnector(serviceTicketStatus, {}, 'serviceTicketStatusQuery'),
    queryConnector(eventTypes, {}, 'eventTypesQuery'),
    queryConnector(driversQuery, {}, 'driversQueryData'),
    queryConnector(companyGroupQuery, {}, 'groupsQuery'),
    queryConnector(getAllPersonalLabels, {}, 'labelsQuery'),
    queryConnector(reportMetaData, {}, 'metaQuery'),
    queryConnector(reportSchedule, {}, 'scheduledReports'),
    queryConnector(reportScheduleFrequencies, {}, 'frequencyOptions'),
    queryConnector(reportScheduleDateRanges, {}, 'dateRangeOptions'),
    mutationConnector(createReportDownloadQueueItem, 'createReportDownloadQueue'),
  )(ReportsHOC)
}

export default reportsHOC
