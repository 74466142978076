import io from 'socket.io-client'

let SOCKET_URL
let SOCKET_OPTIONS

if (process.env.NODE_ENV === 'development') {
  SOCKET_URL = 'https://staging.rhinofleettracking.com'
  // SOCKET_URL = 'http://localhost:9004'
  SOCKET_OPTIONS = {
    path: '/socket',
    withCredentials: false,
    transports: ["websocket"]
  }
} else {
  SOCKET_URL = '/'
  SOCKET_OPTIONS = {
    path: '/socket',
    withCredentials: false,
  }
}
const socket = io(SOCKET_URL, SOCKET_OPTIONS)

// Socket server emit "connected" event
socket.on('connected', () => {
  const clientID = sessionStorage.getItem('clientId')
  if (clientID && clientID !== 'null') {
    socket.emit('joinRoom', parseInt(clientID, 10))
  } else {
    const reJoin = setInterval(() => {
      if (sessionStorage.getItem('clientId')) {
        socket.emit('joinRoom', parseInt(sessionStorage.getItem('clientId'), 10))
        clearInterval(reJoin)
      }
    }, 5000)
  }
})

socket.on('connect_error', e => {
  if (process.env.NODE_ENV === 'development') {
    //stop trying connect to local socket server in local environment if first try fails
    socket.close()
  }
})

socket.on('joined', clientID => {
  console.log(`Joined room ${clientID}`)
})

export default socket
