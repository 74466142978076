import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Divider, Button, Spin, Modal,
} from 'antd'
import ModalHeader from '@mol/modalHeader'
import TagPreview from '@mol/companyGroupPreview'
import StepButtons from '@mol/stepButtons'
import Groups from '@mol/groupOfGroups'

import customPropTypes from '@page/main/customPropTypes'
import helper from '@helper'

/**
 * Confirmation modal
 */
const { confirm } = Modal

/**
  * @typedef {import('../../customPropTypes').Tag} Tag
  */

/**
  * A default tag
  * @type {Tag}
  */
const defaultTag = {
  id: 0,
  name: '',
  color: '#003375',
}

/**
 * A modules that sets up the steps and data to create, update, or delete tags.
 * Tags can be either groups or labels
 *
 * @note this is a Render Prop
 * @see https://reactjs.org/docs/render-props.html
 *
 * This will just render:
 * - Title
 * - Title divider
 * - Description
 * - Tag preview
 * - Delete button
 * - Step buttons (cancel, previous, next)
 * - Tags List
 */
class EditTags extends Component {
  /**
   * Confirmation modal
   */
  confirmModal = null

  static propTypes = {
    /** The type of tags */
    type: PropTypes.oneOf(['groups', 'labels']).isRequired,
    /** Tags */
    tags: PropTypes.arrayOf(customPropTypes.tags).isRequired,
    /** Items that the edit tags can be associated with. Devices, users, etc. */
    items: PropTypes.arrayOf(PropTypes.shape({
      /** will be name of the key value in the render child items */
      name: PropTypes.string.isRequired,
      /** devices, users, etc. Used for checkbox / selection */
      items: PropTypes.array.isRequired,
    })),
    /** save tag */
    saveTag: PropTypes.func.isRequired,
    /** deletes tag */
    deleteTag: PropTypes.func.isRequired,
    wMatrix: PropTypes.func.isRequired,
    render: PropTypes.func.isRequired,
    /** Total number of steps to create or update a tag */
    totalSteps: PropTypes.number,
    /** For each step, included step 0, give a string. You can provide an empty string */
    descriptions: PropTypes.arrayOf(PropTypes.string),
    loading: PropTypes.bool,
  }

  static defaultProps = {
    items: [],
    totalSteps: 3,
    descriptions: [],
    loading: false,
  }

  /**
   * @typedef State
   *
   * @property {number} stepNum
   * @property {Tag} selectedTag
   * @property {*} checkedItems
   */

  /**
   * @type {State}
   */
  state = {
    stepNum: 0,
    selectedTag: null,
    checkedItems: {},
  }

  /**
   * componentDidUpdate
   * @param {*} prevProps
   * @param {State} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    const { selectedTag } = this.state

    if (prevState.selectedTag !== selectedTag) this.configureCheckedItems()
  }

  /**
   * Configures what items are checked or unchecked.
   * Uses items given from the props and compares what tags
   * are associated with those items. This is dyanmic to
   * the items given.
   */
  configureCheckedItems = () => {
    const { selectedTag, checkedItems } = this.state
    const { items, type } = this.props

    for (const item of items) {
      if (selectedTag && selectedTag.id !== 0) {
        const array = []

        for (const object of item.items) {
          for (const tag of object[type]) {
            if (tag.id === selectedTag.id) {
              array.push(object.id)
              break
            }
          }
        }

        checkedItems[item.name] = array
      } else {
        checkedItems[item.name] = []
      }
    }

    this.setState({ checkedItems })
  }

  /**
   * Resets state
   * @public
   */
  reset = () => {
    this.setState({
      stepNum: 0,
      selectedTag: null,
      checkedItems: {},
    })
  }

  /**
   * Update state stepNum
   * @public
   */
  onStepChange = (stepNum) => {
    this.setState({ stepNum })
  }

  /**
   * Preps default tag to be used. When user
   * selects "New Group" or "New Label".
   * @public
   */
  createTag = () => {
    this.setState({
      stepNum: 1,
      selectedTag: defaultTag,
    })
  }

  /**
   * Preps selected tag to be used. When user
   * selects a tag from the tags lists
   * @public
   *
   * @param {Tag} selectedTag
   */
  editTag = (selectedTag) => {
    this.setState({
      stepNum: 1,
      selectedTag,
    })
  }

  /**
   * Changes the color
   * @public
   *
   * @param {object} color
   */
  changeColor = (color) => {
    const { selectedTag } = this.state
    const group = {
      id: selectedTag.id,
      name: selectedTag.name,
      color: color.hex,
    }
    this.setState({ selectedTag: group })
  }

  /**
   * Changes the name
   * @public
   *
   * @param {object} e event
   */
  changeName = (e) => {
    const { selectedTag } = this.state
    const group = {
      id: selectedTag.id,
      name: e.target.value,
      color: selectedTag.color,
    }
    this.setState({ selectedTag: group })
  }

  /**
   * Givem new array of checked items from tables
   * @public
   *
   * @param {string} name key in object. e.g. 'devices', 'users', etc
   * @param {[number]} ids the ids of the items
   */
  checkItems = (name, ids) => {
    const { checkedItems } = this.state

    checkedItems[name] = ids

    this.setState({ checkedItems })
  }

  /**
   * Save changes or creation of tag and reset state
   * @public
   */
  save = () => {
    const { selectedTag, checkedItems } = this.state
    const { saveTag } = this.props
    saveTag(selectedTag, checkedItems)
    this.reset()
  }

  /**
   * Delete tag, remove modal, and reset state
   * @public
   */
  delete = () => {
    const { selectedTag } = this.state
    const { deleteTag } = this.props
    deleteTag(selectedTag.id)
    this.confirmModal.destroy()
    this.reset()
  }

  /**
   * Show deletion confirmation
   * @public
   */
  showDeleteConfirm = () => {
    this.confirmModal = confirm({
      title: 'Are you Sure?',
      content: 'This will delete all instances of this group.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Nevermind',
      destroyOnClose: true,
      onOk: this.delete,
    })
  }

  /**
   * Render the right tag preview
   * @private
   *
   * @returns {Component|null}
   */
  renderTagPreview = () => {
    const { selectedTag } = this.state
    const { wMatrix, type } = this.props

    if (selectedTag) {
      return (
        <TagPreview
          color={selectedTag.color}
          name={selectedTag.name}
          show
          wMatrix={wMatrix}
          personal={type === 'labels'}
        />
      )
    }

    return null
  }

  /**
   * Render the groups list at the bottom
   * @private
   *
   * @returns {Component}
   */
  renderGroupsView = () => {
    const { stepNum } = this.state
    const { loading, type, tags } = this.props

    if (loading) {
      return (
        <Spin />
      )
    }

    return (
      <Groups
        style={{ display: 'flex' }}
        show={stepNum < 2}
        groups={type === 'groups' ? tags : []}
        labels={type === 'labels' ? tags : []}
        isEditable
        onClick={this.editTag}
      />
    )
  }

  /**
   * @typedef {Object} ChildRenderItemsSlideItems
   *
   * @property {[number]} checked the ids of the items checked
   * @property {function} update to update the checked items array
   */

  /**
   * @typedef {Object} ChildRenderItemsTag
   *
   * @property {Tag} selected tag
   * @property {function} create tells the state to use default tag
   * @property {function} changeColor
   * @property {function} changeName
   */

  /**
   * @typedef {Object} ChildRenderItems
   *
   * @property {number} stepNum
   * @property {ChildRenderItemsTag} tag
   * @property {ChildRenderItemsSlideItems} name dynamic
   */

  /**
   * Configures items to give to the render function prop
   *
   * @returns {ChildRenderItems}
   */
  childRenderItems = () => {
    const {
      stepNum, selectedTag, checkedItems,
    } = this.state
    const { items } = this.props

    const slideItems = {
      stepNum,
      tag: {
        selected: selectedTag,
        create: this.createTag,
        changeColor: this.changeColor,
        changeName: this.changeName,
        cancel: this.reset,
        save: this.save,
        delete: this.delete,
      },
    }

    for (const item of items) {
      slideItems[item.name] = {
        checked: checkedItems[item.name],
        update: this.checkItems,
      }
    }

    return slideItems
  }

  render() {
    const { stepNum, selectedTag, loading } = this.state
    const {
      wMatrix, render, type, totalSteps, descriptions,
    } = this.props

    return (
      <div>
        <div style={{
          display: 'flex', justifyContent: 'space-between', width: '100%',
        }}
        >
          <ModalHeader
            headerName={wMatrix(type === 'groups' ? 'Groups' : 'Labels')}
            stepNum={stepNum}
            showSteps={(stepNum > 0 && stepNum < 4)}
            description={descriptions[stepNum]}
            totalSteps={totalSteps}
          />
          {this.renderTagPreview()}
        </div>

        {render(this.childRenderItems())}

        <div style={{
          display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', marginTop: stepNum === 1 ? 30 : 0, width: stepNum === 1 ? 600 : '100%',
        }}
        >
          <Button type="danger" onClick={this.showDeleteConfirm} style={{ display: stepNum === 1 && selectedTag.id !== 0 ? 'flex' : 'none' }}>{wMatrix('Delete')}</Button>
          <StepButtons
            style={{ marginLeft: 'auto' }}
            current={stepNum}
            onStepChange={this.onStepChange}
            onCancel={this.reset}
            onCustom={this.save}
            customText={wMatrix('Save')}
            disabled={selectedTag && selectedTag.name === ''}
            loading={loading}
            totalSteps={totalSteps}
          />
        </div>

        <Divider style={{ display: stepNum < 2 ? 'block' : 'none', marginTop: 125 }} />
        {this.renderGroupsView()}
      </div>
    )
  }
}

export default helper()(EditTags)
