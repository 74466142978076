import PropTypes from 'prop-types'
import React, { Component } from 'react'

const styles = {
  // wizardStep
  // wizardStepCount
  stepContainer: {
    float: 'right',
    // display: this.props.createMode?'flex':'none',
    // position: 'absolute',
    flexDirection: 'row',
    width: '120px',
    height: '26px',
    marginLeft: 'auto',
    // backgroundColor: 'rgba(255,0,0,1)'
  },

  // stepCount: {
  //   float: 'left',
  //   backgroundColor: '#fff',
  //   color: '#333',
  //   width: '26px',
  //   height: '26px',
  //   borderRadius: '50%',
  //   textAlign: 'center',
  //   lineHeight: '24px',
  //   fontSize: '13px',
  //   border: '1px solid #ddd',
  //   marginLeft: '5px',
  //   marginRight: '5px',
  // },

  // stepCountSelected: {
  //   backgroundColor: '#4482FF',
  //   color: '#fff',
  //   float: 'left',
  //   width: '26px',
  //   height: '26px',
  //   borderRadius: '50%',
  //   textAlign: 'center',
  //   lineHeight: '24px',
  //   fontSize: '13px',
  //   border: '1px solid #ddd',
  //   marginLeft: '5px',
  //   marginRight: '5px',
  // },
}

export default class StepCircles extends Component {
  static propTypes = {
    show: PropTypes.bool,
    totalSteps: PropTypes.number,
    // currentStep: PropTypes.number,
    stepNum: PropTypes.number,
  }

  static defaultProps = {
    totalSteps: 3,
    // currentStep: 0, // dont show on zero
    stepNum: 0,
    show: false,
  }

  getCircleStyle = (thisStep, selectedStep, totalSteps) => ({
    display: (thisStep <= totalSteps ? 'block' : 'none'),
    backgroundColor: (thisStep === selectedStep ? '#4482FF' : '#fff'),
    color: (thisStep === selectedStep ? '#fff' : '#333'),
    float: 'left',
    width: '26px',
    height: '26px',
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: '24px',
    fontSize: '13px',
    border: '1px solid #ddd',
    marginLeft: '5px',
    marginRight: '5px',
  })

  name = () => {
    const { show, stepNum, totalSteps } = this.props
    if (show === true) {
      return (
        <div style={styles.stepContainer}>
          <div style={(this.getCircleStyle(1, stepNum, totalSteps))}>1</div>
          <div style={(this.getCircleStyle(2, stepNum, totalSteps))}>2</div>
          <div style={(this.getCircleStyle(3, stepNum, totalSteps))}>3</div>
        </div>
      )
    }
    return <div />
  }

  render() {
    return this.name()
  }
}
