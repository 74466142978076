import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button } from 'antd'
import helper from '@helper'

class StepButtons extends Component {
  static propTypes = {
    style: PropTypes.object,
    totalSteps: PropTypes.number,
    current: PropTypes.number.isRequired,
    onStepChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,

    loading: PropTypes.bool,
    customText: PropTypes.string,
    onCustom: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    wMatrix: PropTypes.func.isRequired,
  }


  static defaultProps = {
    style: { },
    totalSteps: 3,
    disabled: false,

    loading: false,
    customText: '',
    onCustom: null,
  }

    state = {
      // customLoading: false,
    }

    showCancel = () => {
      const {
        current, totalSteps, onCancel, wMatrix,
      } = this.props

      if (current > 0 && current <= totalSteps) {
        return (
          <span role="button" tabIndex="-1" className="cancel" onClick={onCancel}>{wMatrix('Cancel')}</span>
        )
      }

      return null
    }


    showPrev = () => {
      const {
        current, totalSteps, onStepChange, wMatrix,
      } = this.props

      if (current > 1 && current <= totalSteps) {
        return (
          <Button style={{ marginLeft: '10px' }} onClick={() => { onStepChange(current - 1) }}>{wMatrix('Previous')}</Button>
        )
      }

      return null
    }

    showNext = () => {
      const {
        current, totalSteps, disabled, onStepChange, wMatrix,
      } = this.props

      if (current > 0 && current < totalSteps) {
        return <Button type="primary" style={{ marginLeft: '10px' }} disabled={disabled} onClick={() => { onStepChange(current + 1) }}>{wMatrix('Next')}</Button>
      }

      return null
    }

    showCustom = () => {
      const {
        current, totalSteps, customText, onCustom, loading,
      } = this.props
      if (current === totalSteps) {
        return <Button type="primary" style={{ marginLeft: '10px' }} loading={loading} onClick={() => { onCustom() }}>{customText}</Button>
      }
      return null
    }

    render() {
      const { style } = this.props
      return (
        <div style={{
          display: 'flex', justifyContent: 'flex-end', alignItems: 'center', ...style,
        }}
        >
          {this.showCancel()}
          {this.showPrev()}
          {this.showNext()}
          {this.showCustom()}
        </div>

      )
    }
}

export default helper()(StepButtons)
