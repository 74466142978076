import React from 'react'
import PropTypes from 'prop-types'
import { Button, Alert } from 'antd'
import ModalHeader from '@mol/modalHeader'
import EditableUserTable from '@mol/editableUserTable'
import NewUserForm from '@mol/newUserForm'
import UserModalHOC from '@hoc/userPanel.hoc'

class UserPanel extends React.Component {
    static propTypes = {
      wMatrix: PropTypes.func.isRequired,
      groups: PropTypes.array,
      createNewContact: PropTypes.func.isRequired,
      contacts: PropTypes.array,
      updateUser: PropTypes.func.isRequired,
      deleteUser: PropTypes.func.isRequired,
      showFormToggle: PropTypes.func.isRequired,
      showForm: PropTypes.bool,
      notificationMessage: PropTypes.string,
      notificationType: PropTypes.string,
      notificationFlag: PropTypes.bool,
      contactsRefetch: PropTypes.func.isRequired,
      activeKey: PropTypes.string.isRequired,
    }

    static defaultProps = {
      contacts: [],
      groups: [],
      showForm: false,
      notificationMessage: '',
      notificationType: '',
      notificationFlag: false,
    }

    componentDidUpdate(prevProps) {
      const { activeKey, contactsRefetch } = this.props
      if (prevProps.activeKey !== '2' && activeKey === '2') {
        contactsRefetch()
      }
    }

    onCancel = () => {
      const { showFormToggle } = this.props
      showFormToggle(false)
    }

    render() {
      const {
        wMatrix, groups, createNewContact, contacts, updateUser, deleteUser,
        showFormToggle, showForm, notificationType, notificationMessage, notificationFlag,
      } = this.props
      return (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <ModalHeader
              headerName="Users"
              description=""
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
            <Button type="primary" onClick={showFormToggle} disabled={showForm}>{wMatrix('Add User')}</Button>
            {notificationFlag
              ? (
                <Alert
                  style={{ width: '275px', marginLeft: '10px' }}
                  closable
                  showIcon
                  message={wMatrix(notificationMessage)}
                  type={notificationType}
                />
              )
              : null
            }
          </div>
          <div style={{ display: showForm === true ? 'block' : 'none' }}>
            <NewUserForm
              groups={groups}
              showToggle={showFormToggle}
              onCreate={createNewContact}
              {...this.props}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <EditableUserTable
              key="editableUserTable"
              style={{ marginTop: 5 }}
              contacts={contacts}
              isCozy
              isSelectable={false}
              updateUser={updateUser}
              deleteUser={deleteUser}
              groups={groups}
              {...this.props}
            />
          </div>
        </div>
      )
    }
}

export default UserModalHOC()(UserPanel)
