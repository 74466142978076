import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Skeleton from '@atom/skeleton'

const styles = {
  tableRow: {
    width: '100%',
    height: 53,
    borderLeft: '1px solid #d9d9d9',
    borderBottom: '1px solid #d9d9d9',
    borderRight: '1px solid #d9d9d9',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableEntry: {
    height: 21,
    margin: 16,
    backgroundColor: '#ececec',
  },
  tableHeader: {
    height: 53,
    width: '100%',
    backgroundColor: '#ececec',
    border: '1px solid #d9d9d9',
  },
  selectContainer: {
    height: '33px',
    padding: '4px 10px',
    borderRadius: '4px',
    border: '1px solid #d9d9d9',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  inputEntry: {
    margin: 10,
    height: '80%',
    width: '80%',
    backgroundColor: '#e9e9e9',
  },
}

/**
 * @description - This is a component class that contains skeleton primatives made using the
 * skeleton atom component. This collection will include skeletons of common components used within
 * the app (tables, selectBox, etc)
 * @property {String} type (Required) - used to specify which component skeleton to return.
 */
export default class SkeletonComponent extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // PropTypes.number
  }

  static defaultProps = {
    width: 200,
  }

  /**
   * @returns {Component}
   * @description - Returns a skeleton table
   */
  renderTable = () => {
    const skelArray = []
    for (let i = 0; i < 6; i += 1) {
      skelArray.push(this.renderTableRow(i))
    }
    return (
      <div style={{ width: '100%', height: '100%', marginTop: 16 }}>
        <Skeleton wrapperStyle={styles.tableHeader} />
        {skelArray}
      </div>
    )
  }

  /**
   * @returns {Component}
   * @description - Returns a skeleton table row used in the skeleton table.
   */
  renderTableRow = key => (
    <div key={`skelRow-${key}`} style={styles.tableRow}>
      <Skeleton wrapperStyle={{ ...styles.tableEntry, width: '10%' }} />
      <Skeleton wrapperStyle={{ ...styles.tableEntry, width: '20%' }} />
      <Skeleton wrapperStyle={{ ...styles.tableEntry, width: '35%' }} />
      <Skeleton wrapperStyle={{ ...styles.tableEntry, width: '35%' }} />
    </div>
  )

  /**
   * @returns {Component}
   * @description - Returns a skeleton input/select. This can take an optional width from props.
   */
  renderInputSelect = () => {
    const { width } = this.props
    return (
      <div style={{ ...styles.selectContainer, width }}>
        <Skeleton wrapperStyle={{
          margin: 10, height: '80%', width: '80%', backgroundColor: '#e9e9e9',
        }}
        />
      </div>
    )
  }

  renderComponent = () => {
    const { type } = this.props
    switch (type) {
      case 'table':
        return (this.renderTable())
      case 'input':
      case 'select':
        return (this.renderInputSelect())
      default:
        return null
    }
  }

  render() {
    return (this.renderComponent())
  }
}
