import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Divider, Button } from 'antd'
import Icon from '@atom/icon'
import helper from '@helper'
import DOMPurify from 'dompurify'

/**
 * clip: {
 *  type: ['live', 'event', 'custom?'],
 *  url: 'string',
 *  details: {
 *    eventType: 'string',
 *    datetime: datetime,
 *    landmarks: 'string',
 *    address: 'string',
 *  }
 * }
 */
class CameraClipModal extends Component {
  static propTypes = {
    /** @Helper */
    wMatrix: PropTypes.func.isRequired,
    eventToString: PropTypes.func.isRequired,
    /** @Parent */
    url: PropTypes.string.isRequired,
    device: PropTypes.object.isRequired,
    type: PropTypes.string,
    details: PropTypes.object,
    closeModal: PropTypes.func.isRequired,
    ifShowModal: PropTypes.bool.isRequired,
    setNav: PropTypes.func.isRequired,
    updateClipSearch: PropTypes.func.isRequired,
    navPath: PropTypes.string.isRequired,
    NavEnum: PropTypes.object.isRequired,
  }

  static defaultProps = {
    type: 'live',
    details: null,
  }

  /**
   * @private
   * @description Returns iframe of video clip
   * @returns {html}
   */
  returnIframe = () => {
    const { url } = this.props
    const sanitizer = DOMPurify.sanitize
    const iframe = `<iframe src="${url}" width="100%" height="535px"></iframe>`
    const sanitized = sanitizer(iframe, { ADD_TAGS: ['iframe'] })
    return {
      __html: sanitized,
    }
  }

  /**
   * @private
   * @description Returns header portion of modal contents
   * @returns {ReactFragment}
   */
  returnHeader = () => {
    const { wMatrix, device, type } = this.props
    let heading = 'liveVideo'
    if (type === 'event') {
      heading = 'eventVideo'
    }
    return (
      <>
        <h1>
          {`${wMatrix(heading)}: ${device.alias}`}
        </h1>
        <Divider style={{ width: '80%', marginTop: 0 }} />
      </>
    )
  }

  /**
   * Returns a styled row with label and value
   * @param {String} title Lable for row
   * @param {String} value Value of row
   */
  returnDetailRow = (title, value) => (
    <div
      style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}
    >
      <h2 style={{ marginBottom: 0 }}>{`${title}:`}</h2>
      <div style={{ marginLeft: 10, fontSize: '14px', color: '#000000A6' }}>
        {value}
      </div>
    </div>
  )

  handleRelatedClick = () => {
    const {
      setNav, navPath, NavEnum, updateClipSearch, device, closeModal,
    } = this.props
    closeModal()
    if (navPath !== NavEnum.VIDEOCLIPS) {
      setNav(NavEnum.VIDEOCLIPS)
    }
    updateClipSearch(device.alias)
  }

  /**
   * @private
   * @description Returns View Related Clips Button
   */
  returnRelatedClipButton = () => {
    const { wMatrix } = this.props
    /**
     * @todo
     * onclick should
     *  - close the modal
     *  - navigate to camera clips tab
     *  - filter the table by the selected device
     */
    return (
      <Button
        onClick={() => this.handleRelatedClick()}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div style={{ marginRight: 10 }}>
            <Icon
              type="tv-play"
              width={20}
            />
          </div>
          {wMatrix('viewRelatedClips')}
        </div>
      </Button>
    )
  }

  /**
   * Returns location string. Landmarks have priority over addresses.
   */
  returnLocation = () => {
    const { details, wMatrix } = this.props
    const { address, landmarks } = details
    let location = null
    if (landmarks && landmarks.length > 0) {
      // display landmarks as list
      location = (landmarks.join(', '))
    } else if (address) {
      location = (
        <>
          <div>{`${address.street || ''}`}</div>
          <div>{`${address.city || ''}${address.city ? ', ' : ''}${address.state || ''}`}</div>
        </>
      )
    }

    return (
      <div
        style={{ display: 'flex', alignItems: 'flex-start', marginBottom: 10 }}
      >
        <h2 style={{ marginBottom: 0 }}>{`${wMatrix('location')}:`}</h2>
        <div style={{
          marginLeft: 10, marginTop: 4, fontSize: '14px', color: '#000000A6',
        }}
        >
          {location}
        </div>
      </div>
    )
  }

  /**
   * @private
   * @description Returns the details of a clip if needed
   */
  returnDetails = () => {
    const {
      wMatrix, eventToString, type, details,
    } = this.props
    if (type === 'event' && details) {
      const { eventType, date, time } = details
      const datetimeLabel = `${wMatrix('date')} & ${wMatrix('Time')}`
      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: 10,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {this.returnDetailRow(wMatrix('event'), eventToString(eventType))}
            {this.returnDetailRow(datetimeLabel, `${date} ${time}`)}
            {this.returnLocation()}
          </div>
          {this.returnRelatedClipButton()}
        </div>
      )
    }
    return null
  }

  render() {
    const {
      closeModal, ifShowModal,
    } = this.props
    /** NOTE: added xss sanitizing in this.returnIframe url */
    return (
      <Modal
        visible={ifShowModal}
        onCancel={closeModal}
        footer={null}
        width="70%"
        maskClosable={false}
        zIndex={10}
      >
        {this.returnHeader()}
        <div dangerouslySetInnerHTML={this.returnIframe()} />
        {this.returnDetails()}
      </Modal>
    )
  }
}

export default helper()(CameraClipModal)
