import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GoogleMapReact from 'google-map-react'
import equal from 'deep-equal'
import landmarkMarkerFlag from '@assets/img/icons/landmarkFlag.svg'
import clone from 'clone'
import helper from '@helper'

const styles = {
  landmarkShape: {
    strokeColor: '#4482FF',
    strokeOpacity: 0.83,
    strokeWeight: 2,
    fillColor: '#4482FF',
    fillOpacity: 0.38,
  },
}

const Google = window.google

class LandmarksMap extends Component {
  map = null

  maps = null

  landmarkMarkerGroups = {}

  newShape = null

  landmarkBounds = null

  static propTypes = {
    initMap: PropTypes.func.isRequired,
    landmarks: PropTypes.array,
    selectedLandmark: PropTypes.object,
    fitBounds: PropTypes.func,
    center: PropTypes.object,
    zoom: PropTypes.number,
    overlayType: PropTypes.string,
    editLandmark: PropTypes.func.isRequired,
    ifNewLandmark: PropTypes.bool,
    setIfNewLandmark: PropTypes.func.isRequired,
    selectLandmark: PropTypes.func.isRequired,
    unselectLandmark: PropTypes.func.isRequired,
    zoomPoint: PropTypes.object,
    zoomToPoint: PropTypes.func.isRequired,
    updateSelectedLandmarkCenter: PropTypes.func,
    polygonCentroid: PropTypes.func.isRequired,
    findLandmarkCenter: PropTypes.func.isRequired,
  }

  static defaultProps = {
    center: {
      lat: 30.116936,
      lng: -97.129397,
    },
    zoom: 1,
    landmarks: [],
    selectedLandmark: null,
    fitBounds: () => {},
    overlayType: 'CIRCLE',
    ifNewLandmark: false,
    zoomPoint: null,
    updateSelectedLandmarkCenter: null,
  }

  state = {
    newShapeCenter: null,
  }

  componentWillUnmount() {
    const {
      ifNewLandmark, selectedLandmark, setIfNewLandmark, unselectLandmark,
    } = this.props
    /* if the landmark page just loaded, make sure everything is reset */
    if (!this.maps) {
      if (ifNewLandmark) {
        setIfNewLandmark(false)
        this.detachNewShapeFromMap()
      }
      if (selectedLandmark) {
        unselectLandmark()
      }
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      landmarks, selectedLandmark, overlayType,
      ifNewLandmark, setIfNewLandmark,
      unselectLandmark, zoomPoint, zoomToPoint, updateSelectedLandmarkCenter,
    } = this.props
    const { newShapeCenter } = this.state
    if (prevState.newShapeCenter !== newShapeCenter && newShapeCenter) {
      if (Array.isArray(newShapeCenter)) {
        updateSelectedLandmarkCenter(newShapeCenter[0])
      } else {
        updateSelectedLandmarkCenter(newShapeCenter)
      }
    }
    /* if landmarks data just loaded or filtered, then zoom to fit all landmarks on the map */
    if ((!prevProps.landmarks && landmarks) || (prevProps.landmarks.length !== landmarks.length)) {
      /* if filter, delete all markers and re-attached filtered markers */
      if (prevProps.landmarks && this.landmarkMarkerGroups !== {}) {
        this.clearLandmarkers()
        this.renderLandmarkers()
      }
      this.fitToCoordinates(false)
    }

    /* when create a new landmark, put a circle on the map by default */
    if (!prevProps.ifNewLandmark && ifNewLandmark) {
      this.detachNewShapeFromMap()
      this.attachNewShapeToMap('CIRCLE')
      /* if there is a landmark previously selected, make it uneditable */
      if (prevProps.selectedLandmark) {
        this.removeSingleLandmarkFromMap(prevProps.selectedLandmark)
        this.attachSingleLandmarkToMap(prevProps.selectedLandmark, false)
      }
    }

    /* when switch the overlay type (circle/rectangle/polygon) */
    if (prevProps.overlayType !== overlayType && prevProps.selectedLandmark === selectedLandmark) {
      // Retrieve bounds of previous shape
      let previousShapeBounds = this.landmarkBounds
      if (this.newShape !== null) {
        if (this.newShape.getBounds !== undefined) {
          previousShapeBounds = this.newShape.getBounds()
        } else {
          /* Polygon */
          previousShapeBounds = new this.maps.LatLngBounds()

          const path = this.newShape.getPath()
          for (let i = 0; i < path.length; i += 1) {
            const point = {
              lat: path.getAt(i).lat(),
              lng: path.getAt(i).lng(),
            }
            previousShapeBounds.extend(point)
          }
        }
      }

      this.detachNewShapeFromMap()
      if (prevProps.selectedLandmark) {
        this.removeSingleLandmarkFromMap(prevProps.selectedLandmark)
        if (selectedLandmark && !equal(selectedLandmark, prevProps.selectedLandmark)) {
          this.attachSingleLandmarkToMap(prevProps.selectedLandmark, false)
        }
      }
      if (ifNewLandmark || selectedLandmark) {
        this.attachNewShapeToMap(overlayType.toUpperCase(), previousShapeBounds)
      }
    }

    /* if a new address is set in the right drawer, move the drawed shape to the zoom point */
    if (!equal(prevProps.zoomPoint, zoomPoint) && zoomPoint) {
      if (selectedLandmark || ifNewLandmark) {
        this.moveShapeToZoomPoint()
        zoomToPoint(null)
      } else {
        zoomToPoint(zoomPoint)
      }
    }

    /* when a landmark is selected */
    if (!equal(prevProps.selectedLandmark, selectedLandmark) && selectedLandmark) {
      // console.log('trigger select landmark')
      setIfNewLandmark(false)
      this.detachNewShapeFromMap()
      /* if there was a landmark already selected, make the previously selected one uneditable */
      if (prevProps.selectedLandmark) {
        this.removeSingleLandmarkFromMap(prevProps.selectedLandmark)
        this.attachSingleLandmarkToMap(prevProps.selectedLandmark, false)
      }
      /* make the new selected landmark editable */
      this.removeSingleLandmarkFromMap(selectedLandmark)
      this.attachSingleLandmarkToMap(selectedLandmark, true)

      /* zoom in to selected landmark shape, for loop is to find the shape for selected landmark */
      const selectedLandmarkShape = this.landmarkMarkerGroups[selectedLandmark.id].shape
      this.zoomToSingleShape(selectedLandmarkShape, selectedLandmark.type)

      /** attach event listener to the shape */
      this.attachEventListener(selectedLandmark, selectedLandmarkShape)
    }

    /** if click cancel for selected landmark */
    if (prevProps.selectedLandmark && !selectedLandmark) {
      this.clearLandmarkers()
      this.renderLandmarkers()
      if (!ifNewLandmark) {
        this.fitToCoordinates()
      }
      /**
       * I think the parent file should not have access to the shape.
       * The shape here is a google.maps object, it should only exist in this file
       * I will just keep a local state for the newShape center
       */
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ newShapeCenter: null })
    }

    /** if click cancel for new landmark */
    if (prevProps.ifNewLandmark && !ifNewLandmark) {
      if (!selectedLandmark) {
        unselectLandmark()
        this.fitToCoordinates()
      }
      this.detachNewShapeFromMap()
      /**
       * I think the parent file should not have access to the shape.
       * The shape here is a google.maps object, it should only exist in this file
       * I will just keep a local state for the newShape center
       */
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ newShapeCenter: null })
    }
  }

  /** attach event listeners to the shape */
  attachEventListener = (selectedLandmark, selectedLandmarkShape) => {
    const { editLandmark } = this.props
    const tempLandmark = clone(selectedLandmark)
    if (selectedLandmark.type === 'circle') {
      tempLandmark.center = [{
        lat: selectedLandmarkShape.getCenter().lat(),
        lng: selectedLandmarkShape.getCenter().lng(),
      }]
      tempLandmark.radius = selectedLandmarkShape.getRadius()
      editLandmark(tempLandmark);
      ['center_changed', 'dragend', 'radius_changed', 'mouseout'].forEach((event) => {
        selectedLandmarkShape.addListener(event, () => {
          tempLandmark.center = [{
            lat: selectedLandmarkShape.getCenter().lat(),
            lng: selectedLandmarkShape.getCenter().lng(),
          }]
          tempLandmark.radius = selectedLandmarkShape.getRadius()
          editLandmark(tempLandmark)
          this.setState({ newShapeCenter: tempLandmark.center })
        })
      })
    } else if (selectedLandmark.type === 'rectangle') {
      let southWest = selectedLandmarkShape.getBounds().getSouthWest()
      let northEast = selectedLandmarkShape.getBounds().getNorthEast()
      editLandmark({
        radius: null,
        points: [
          {
            lat: southWest.lat(),
            lng: southWest.lng(),
          },
          {
            lat: northEast.lat(),
            lng: southWest.lng(),
          },
          {
            lat: northEast.lat(),
            lng: northEast.lng(),
          },
          {
            lat: southWest.lat(),
            lng: northEast.lng(),
          },
          {
            lat: southWest.lat(),
            lng: southWest.lng(),
          },
        ],
      });
      ['bounds_changed', 'mouseup'].forEach((event) => {
        selectedLandmarkShape.addListener(event, () => {
          this.setState({ newShapeCenter: this.findShapeCenter(selectedLandmarkShape) })
          southWest = selectedLandmarkShape.getBounds().getSouthWest()
          northEast = selectedLandmarkShape.getBounds().getNorthEast()
          editLandmark({
            radius: null,
            points: [
              {
                lat: southWest.lat(),
                lng: southWest.lng(),
              },
              {
                lat: northEast.lat(),
                lng: southWest.lng(),
              },
              {
                lat: northEast.lat(),
                lng: northEast.lng(),
              },
              {
                lat: southWest.lat(),
                lng: northEast.lng(),
              },
              {
                lat: southWest.lat(),
                lng: southWest.lng(),
              },
            ],
          })
        })
      })
    } else {
      let points = selectedLandmarkShape.getPath().getArray()
      let tempPoints = points.map(point => ({
        lat: point.lat(),
        lng: point.lng(),
      }))
      editLandmark({
        radius: null,
        points: tempPoints,
      });
      ['mouseup', 'dragend'].forEach((event) => {
        selectedLandmarkShape.addListener(event, () => {
          this.setState({ newShapeCenter: this.findShapeCenter(selectedLandmarkShape) })
          points = selectedLandmarkShape.getPath().getArray()
          tempPoints = points.map(point => ({
            lat: point.lat(),
            lng: point.lng(),
          }))
          editLandmark({
            radius: null,
            points: tempPoints,
          })
        })
      })
    }
  }

  /** @ANCHOR - moveShapeToZoomPoint() */
  /** move the shape to new address. It can be a new landmark of existing landmark */
  moveShapeToZoomPoint = () => {
    const {
      zoomPoint, selectedLandmark, overlayType, editLandmark,
    } = this.props
    /** check what kind of shape needs to move, a new one? an existing landmark shape? */
    const shapeToMove = this.newShape
      ? this.newShape
      : this.landmarkMarkerGroups[selectedLandmark.id].shape
    if (shapeToMove) {
      const oldCenter = this.findShapeCenter(shapeToMove)
      const rLat = zoomPoint.lat - oldCenter.lat
      const rLng = zoomPoint.lng - oldCenter.lng
      let bounds = new this.maps.LatLngBounds()
      if (overlayType === 'CIRCLE') {
        shapeToMove.setCenter(zoomPoint)
        // set bounds
        bounds = shapeToMove.getBounds()
      } else if (overlayType === 'RECTANGLE') {
        shapeToMove.setBounds({
          north: shapeToMove.getBounds().getNorthEast().lat() + rLat,
          south: shapeToMove.getBounds().getSouthWest().lat() + rLat,
          west: shapeToMove.getBounds().getSouthWest().lng() + rLng,
          east: shapeToMove.getBounds().getNorthEast().lng() + rLng,
        })
        // set bounds
        bounds = shapeToMove.getBounds()
      } else if (overlayType === 'POLYGON') {
        const points = shapeToMove.getPath().getArray()
        // update bounds and points
        for (let i = 0; i < points.length; i += 1) {
          points[i] = {
            lat: points[i].lat() + rLat,
            lng: points[i].lng() + rLng,
          }
          bounds.extend(points[i])
        }
        shapeToMove.setPaths(points)
        /** Needs to be called manually for polygons because zooming to point does not
         * trigger any event listeners
         */
        editLandmark({
          radius: null,
          points,
        })
      }
      // fitbounds around new shape
      // padding right: 325 for drawer + 25 all around shape
      this.map.fitBounds(bounds, {
        left: 25, top: 25, bottom: 25, right: 350,
      })
      const newCenter = this.findShapeCenter(shapeToMove)
      this.setState({ newShapeCenter: newCenter })
    }
  }

  zoomToSingleShape = (shape, type) => {
    let shapeBounds = null
    if (type === 'circle' || type === 'rectangle') {
      shapeBounds = shape.getBounds()
      this.map.fitBounds(shapeBounds, { right: 325 })
      this.map.setZoom(this.map.getZoom() - 2)
    } else {
      const bounds = new this.maps.LatLngBounds()
      const points = shape.getPath().getArray()
      for (let j = 0; j < points.length; j += 1) {
        bounds.extend(points[j])
      }
      shapeBounds = bounds
      this.map.fitBounds(shapeBounds, { right: 325 })
      this.map.setZoom(this.map.getZoom() - 2)
    }

    this.landmarkBounds = shapeBounds
  }

  /**
   * @todo
   * The map getCenter is not exact center because of the right drawer padding
   */
  attachNewShapeToMap = (type, previousShapeBounds) => {
    const { newShapeCenter } = this.state
    let tempLandmark
    if (newShapeCenter) {
      if (Array.isArray(newShapeCenter)) {
        this.map.panTo(newShapeCenter[0])
      } else {
        this.map.panTo(newShapeCenter)
      }
    }
    this.map.panBy(-325 / 8, 0)

    if (type === 'CIRCLE') {
      let radiusValue = 1000
      if (previousShapeBounds != null) {
        // Calculate radius from previous shape bounds
        const northEast = previousShapeBounds.getNorthEast()
        const southWest = previousShapeBounds.getSouthWest()
        const northWest = new this.maps.LatLng(southWest.lat(), northEast.lng())
        const southEast = new this.maps.LatLng(northEast.lat(), southWest.lng())

        const width = Google.maps.geometry.spherical.computeDistanceBetween(northEast, northWest)
        const height = Google.maps.geometry.spherical.computeDistanceBetween(northEast, southEast)
        if (width > height) {
          radiusValue = width / 2
        } else {
          radiusValue = height / 2
        }
      }

      this.newShape = new this.maps.Circle({
        ...styles.landmarkShape,
        map: this.map,
        center: this.map.getCenter(),
        radius: radiusValue,
        editable: true,
        draggable: true,
        zIndex: 10,
      })
      tempLandmark = {
        center: [{
          lat: this.newShape.getCenter().lat(),
          lng: this.newShape.getCenter().lng(),
        }],
        radius: this.newShape.getRadius(),
        type: 'circle',
      }
    } else if (type === 'RECTANGLE') {
      let newBounds = {
        north: this.map.getCenter().lat() + 0.005,
        south: this.map.getCenter().lat() - 0.005,
        east: this.map.getCenter().lng() + 0.01,
        west: this.map.getCenter().lng() - 0.01,
      }

      if (previousShapeBounds !== null) {
        newBounds = previousShapeBounds
      }

      this.newShape = new this.maps.Rectangle({
        ...styles.landmarkShape,
        map: this.map,
        bounds: newBounds,
        editable: true,
        draggable: true,
        zIndex: 10,
      })
      const southWest = this.newShape.getBounds().getSouthWest()
      const northEast = this.newShape.getBounds().getNorthEast()
      tempLandmark = {
        radius: null,
        points: [
          {
            lat: southWest.lat(),
            lng: southWest.lng(),
          },
          {
            lat: northEast.lat(),
            lng: southWest.lng(),
          },
          {
            lat: northEast.lat(),
            lng: northEast.lng(),
          },
          {
            lat: southWest.lat(),
            lng: northEast.lng(),
          },
          {
            lat: southWest.lat(),
            lng: southWest.lng(),
          },
        ],
        type: 'rectangle',
      }
    } else {
      // polygon
      let points = [
        {
          lat: this.map.getCenter().lat() + 0.01,
          lng: this.map.getCenter().lng(),
        },
        {
          lat: this.map.getCenter().lat() - 0.01,
          lng: this.map.getCenter().lng() + 0.01,
        },
        {
          lat: this.map.getCenter().lat() - 0.01,
          lng: this.map.getCenter().lng() - 0.01,
        },
      ]

      if (previousShapeBounds !== null) {
        const southWest = previousShapeBounds.getSouthWest()
        const northEast = previousShapeBounds.getNorthEast()
        points = [
          {
            lat: southWest.lat(),
            lng: southWest.lng(),
          },
          {
            lat: northEast.lat(),
            lng: southWest.lng(),
          },
          {
            lat: northEast.lat(),
            lng: northEast.lng(),
          },
          {
            lat: southWest.lat(),
            lng: northEast.lng(),
          },
          {
            lat: southWest.lat(),
            lng: southWest.lng(),
          },
        ]
      }

      this.newShape = new this.maps.Polygon({
        ...styles.landmarkShape,
        map: this.map,
        paths: points,
        editable: true,
        draggable: true,
        zIndex: 10,
      })
      tempLandmark = {
        radius: null,
        points,
        type: 'polygon',
      }
    }
    this.zoomToSingleShape(this.newShape, tempLandmark.type)
    this.attachEventListener(tempLandmark, this.newShape)
  }

  /**
   * @private
   *
   * @description detach the new drawed shape from map
   */
  detachNewShapeFromMap = () => {
    if (this.newShape !== null) {
      // console.log('detach new shape from map')
      this.newShape.setMap(null)
      this.newShape = null
    }
  }

  findShapeCenter = (shape) => {
    const { overlayType, polygonCentroid } = this.props
    if (overlayType === 'CIRCLE') {
      return {
        lat: shape.getCenter().lat(),
        lng: shape.getCenter().lng(),
      }
    } if (overlayType === 'RECTANGLE') {
      const southWest = shape.getBounds().getSouthWest()
      const northEast = shape.getBounds().getNorthEast()
      return {
        lat: (southWest.lat() + northEast.lat()) / 2,
        lng: (southWest.lng() + northEast.lng()) / 2,
      }
    }
    const points = shape.getPath().getArray()
    // console.log('running polycenter')
    return polygonCentroid(points)
    // for (let i = 0; i < points.length; i += 1) {
    //   sumLat += points[i].lat()
    //   sumLng += points[i].lng()
    // }
    // return {
    //   lat: sumLat / (points.length),
    //   lng: sumLng / (points.length),
    // }
  }

  /**
   * @private
   *
   * @description Creates circle markers and pushes them to an array
   */
  renderLandmarkers = () => {
    const { landmarks } = this.props
    if (!!landmarks && this.maps && this.map && this.landmarkMarkerGroups !== {}) {
      for (let i = 0; i < landmarks.length; i += 1) {
        const landmark = landmarks[i]
        this.attachSingleLandmarkToMap(landmark, false)
      }
    }
    return null
  }

  /**
   * @private
   *
   * @description Clear all landmark markers(flags) and shapes from the map
   */
  clearLandmarkers = () => {
    for (const prop in this.landmarkMarkerGroups) {
      if (Object.prototype.hasOwnProperty.call(this.landmarkMarkerGroups, prop)) {
        this.landmarkMarkerGroups[prop].marker.setMap(null)
        this.landmarkMarkerGroups[prop].shape.setMap(null)
      }
    }
    this.landmarkMarkerGroups = {}
  }

  /**
   * @private
   * @param {Object} landmark
   * @param {Boolean} editable
   */
  attachSingleLandmarkToMap = (landmark, editable) => {
    const { selectLandmark, findLandmarkCenter } = this.props
    const landmarkIcon = {
      url: landmarkMarkerFlag,
      size: new this.maps.Size(20, 20),
      origin: new this.maps.Point(0, 0),
      anchor: new this.maps.Point(10, 20),
    }
    const landmarkMarker = new this.maps.Marker({
      position: findLandmarkCenter(landmark),
      icon: landmarkIcon,
      map: this.map,
    })
    let shape
    if (landmark.type === 'circle') {
      shape = new this.maps.Circle({
        ...styles.landmarkShape,
        map: this.map,
        center: landmark.center[0],
        radius: landmark.radius,
        editable,
        draggable: editable,
        zIndex: editable ? 10 : 1,
      })
    } else if (landmark.type === 'polygon') {
      shape = new this.maps.Polygon({
        ...styles.landmarkShape,
        map: this.map,
        paths: landmark.points,
        editable,
        draggable: editable,
        zIndex: editable ? 10 : 1,
      })
    } else {
      const south = landmark.points[0].lat
      const north = landmark.points[1].lat
      const east = landmark.points[2].lng
      const west = landmark.points[0].lng
      shape = new this.maps.Rectangle({
        ...styles.landmarkShape,
        map: this.map,
        bounds: {
          north,
          south,
          east,
          west,
        },
        editable,
        draggable: editable,
        zIndex: editable ? 10 : 1,
      })
    }
    this.maps.event.addListener(landmarkMarker, 'click', () => {
      selectLandmark(landmark.id)
    })
    this.maps.event.addListener(shape, 'click', () => {
      selectLandmark(landmark.id)
    })
    this.landmarkMarkerGroups[landmark.id] = {
      marker: landmarkMarker,
      shape,
    }
  }

  /**
   * remove single landmark from map
   * @private
   */
  removeSingleLandmarkFromMap = (selectedLandmark) => {
    if (this.landmarkMarkerGroups[selectedLandmark.id]) {
      this.landmarkMarkerGroups[selectedLandmark.id].marker.setMap(null)
      this.landmarkMarkerGroups[selectedLandmark.id].shape.setMap(null)
    }
  }

  /**
   * @private
   *
   * Fits the map to the landmarks or a single landmark
   *
   * @param {boolean} isSelectedLandmark
   */
  fitToCoordinates = (isSelectedLandmark) => {
    if (this.maps) {
      const bounds = new this.maps.LatLngBounds()
      const {
        landmarks, fitBounds, center, findLandmarkCenter,
      } = this.props
      if (isSelectedLandmark) {
        fitBounds(bounds, 16)
      } else if (landmarks && landmarks.length > 0) {
        landmarks.forEach((landmark) => {
          bounds.extend(findLandmarkCenter(landmark))
        })
        fitBounds(bounds)
      } else {
        // first load case
        bounds.extend(new this.maps.LatLng(
          center.lat, center.lng,
        ))

        fitBounds(bounds, 4)
      }
    }
  }

  /**
   * @public
   *
   * Callback for our custom initialization
   */
  initMap = (map, maps) => {
    this.map = map
    this.maps = maps
    this.fitToCoordinates(false)
    this.renderLandmarkers()
  }

  /**
   * @private
   *
   * Customize the map on init
   */
  createMapOptions = maps => ({
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: maps.ControlPosition.LEFT_BOTTOM,
    },
    streetViewControl: true,
    streetViewControlOptions: {
      position: maps.ControlPosition.LEFT_BOTTOM,
    },
    zoomControlOptions: {
      position: maps.ControlPosition.LEFT_BOTTOM,
    },
  })

  render() {
    const { center, zoom, initMap } = this.props
    return (
      <GoogleMapReact
        bootstrapURLKeys={{
          client: 'gme-twmatters',
        }}
        onGoogleApiLoaded={({ map, maps }) => initMap(map, maps, this.initMap)}
        defaultCenter={center}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        options={this.createMapOptions}
      />
    )
  }
}

export default helper()(LandmarksMap)
