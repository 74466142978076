import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Alert } from 'antd'
import { HuePicker } from 'react-color'
import MakeGroup from '@mol/makeGroup'
import DevicesTable from '@mol/devicesTable'
import EditTags from '@mol/modal/editTags'

import { compose } from 'react-apollo'
import labelsHOC from '@hoc/labels.hoc'
import helper from '@helper'

class PersonalLabels extends Component {
  static propTypes = {
    labels: PropTypes.array,
    labelsLoading: PropTypes.bool,
    devices: PropTypes.array,
    save: PropTypes.func.isRequired,
    deleteTag: PropTypes.func.isRequired,
    message: PropTypes.object,
    wMatrix: PropTypes.func.isRequired,
  }

  static defaultProps = {
    labels: [],
    labelsLoading: false,
    devices: [],
    message: {},
  }

  state = {
    showColorPicker: false,
  }

  toggleColorPicker = () => {
    const { showColorPicker } = this.state
    this.setState({ showColorPicker: !showColorPicker })
  }

  getDescription = (step) => {
    const { wMatrix } = this.props

    let description = ' '
    switch (step) {
      case 0:
        break
      case 1:
        description = wMatrix('createLabelStep1')
        break
      case 2:
        description = wMatrix('createLabelStep2')
        break
      default:
        description = ' '
    }
    return description
  }

  renderSlide = (items) => {
    const { wMatrix, message, devices } = this.props
    const { showColorPicker } = this.state

    switch (items.stepNum) {
      case 1:
        return (
          <div>
            <div>
              <MakeGroup
                name={items.tag.selected.name}
                changeGroupName={items.tag.changeName}
                toggleColorPicker={this.toggleColorPicker}
                changeColor={items.tag.changeColor}
                show={(items.stepNum === 1)}
              />
            </div>
            <div style={{ position: 'relative' }}>
              <div className="huePicker" style={{ display: showColorPicker ? 'block' : 'none', marginLeft: '400px', marginBottom: '10px' }}>
                <HuePicker color={items.tag.selected.color} onChange={items.tag.changeColor} />
              </div>
            </div>
          </div>
        )
      case 2:
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <DevicesTable
              style={{ marginTop: 5 }}
              devices={devices}
              updateSelectedRows={items.devices.update}
              checkedDevices={items.devices.checked}
              wMatrix={wMatrix}
              ref={this.deviceTable}
            />
          </div>
        )
      default:
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
            <Button style={{ display: items.stepNum === 0 ? 'block' : 'none' }} type="primary" onClick={items.tag.create}>{wMatrix('New Label')}</Button>
            <Alert style={{ display: message.error || message.success ? 'block' : 'none', width: '275px', marginLeft: '10px' }} closable showIcon message={wMatrix(message.error || message.success)} type={message.error ? 'error' : 'success'} />
          </div>
        )
    }
  }

  render() {
    const {
      labels, labelsLoading, save, deleteTag, devices, wMatrix,
    } = this.props
    const items = [{
      name: 'devices',
      items: devices,
    }]
    const descriptions = ['', wMatrix('createLabelStep1'), wMatrix('createLabelStep2')]

    return (
      <div>
        <EditTags
          type="labels"
          tags={labels}
          loading={labelsLoading}
          items={items}
          render={this.renderSlide}
          saveTag={save}
          deleteTag={deleteTag}
          totalSteps={2}
          descriptions={descriptions}
        />
      </div>
    )
  }
}

export default compose(labelsHOC(), helper())(PersonalLabels)
