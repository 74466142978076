import React, { Component } from 'react'
import consumerConnector from '@graphql/consumerConnector'
import { compose } from 'react-apollo'
import clone from 'clone'

const distanceHOC = () => WrappedComponent => {
  class DistanceHOC extends Component {
    constructor(props) {
      super(props);
      this.state = {
        sortby: 'alias',
        searchby: 'address',
        deviceLocatesDistance: [],
        ifCheckDistance: false,
        distanceOrigin: null,
        directionDrawerRef: React.createRef(),
        /** the setOriginFlag is to make sure to re-zoom to the distance origin 
         * when click on the search button
         * even if the origin address is not changed */
        setOriginFlag: false,
      };
    }

    setIfCheckDistance = (ifCheckDistance) => {
      this.setState({ ifCheckDistance })
      if (!ifCheckDistance) {
        this.setState({ distanceOrigin: null })
      }
    }

    resetDistanceOrigin = () => {
      this.setState({ distanceOrigin: null })
    }

    getDistance = (coordinate, deviceLocates) => {
      let deviceLocatesDistance = {}
      let distance
      for (let i = 0; i < deviceLocates.length; i++) {
        distance = Math.round(Math.sqrt(Math.pow(69.1 * (coordinate.lat - deviceLocates[i].latitude), 2) + Math.pow(53 * (coordinate.lng - deviceLocates[i].longitude), 2)))
        distance = distance > 100 ? Math.round(distance) : distance
        deviceLocatesDistance[deviceLocates[i].id] = distance
      }
      this.setState(
        {
          deviceLocatesDistance: deviceLocatesDistance,
          ifCheckDistance: true,
          sortby: 'distance',
          distanceOrigin: coordinate
        }
      )
    }

    setOriginPoint = (coordinate) => {
      const { devices } = this.props
      const { setOriginFlag } = this.state
      
      if (devices.length === 0) return 

      const deviceLocates = devices.filter((device) => {
        return device.locate
      }).map((device) => {
        return {
          id: device.id,
          latitude: device.locate.latitude,
          longitude: device.locate.longitude
        }
      })

      this.getDistance(coordinate, deviceLocates)
      this.setState({ setOriginFlag: !setOriginFlag })
    }

    changeSortBy = (value) => {
      this.setState({ sortby: value })
    }

    changeLandmarkSelection = (value) => {
      const { landmarks, findLandmarkCenter } = this.props
      const landmarkAddressArray = JSON.parse(JSON.stringify(landmarks))
          .filter(landmark => landmark.id === value)
      const coordinates = findLandmarkCenter(landmarkAddressArray[0])
      this.setOriginPoint(coordinates)
    }

    changeSearchBy = (value) => {
      this.setState({ searchby: value })
    }

    sortedDevicelist = () => {
      const { sortby } = this.state
      return this.sortDevice(sortby)
    }

    sortDevice = (sortby) => {
      const { deviceList } = this.props
      const { deviceLocatesDistance } = this.state
      let _deviceList = clone(deviceList)
      for (let i = 0; i < _deviceList.length; i++) {
        _deviceList[i].distance = deviceLocatesDistance[_deviceList[i].id]
      }
      _deviceList.sort((a, b) => {
        return a[sortby] - b[sortby]
      })
      return _deviceList
    }

    render = ({ loading, error, data, refetch } = this.props) => {
      return (
        <WrappedComponent
          getDistance={this.getDistance}
          setOriginPoint={this.setOriginPoint}
          sortedDevicelist={this.sortedDevicelist()}
          changeSortBy={this.changeSortBy}
          changeSearchBy={this.changeSearchBy}
          changeLandmarkSelection={this.changeLandmarkSelection}
          ifCheckDistance={this.state.ifCheckDistance}
          sortby={this.state.sortby}
          searchby={this.state.searchby}
          distanceOrigin={this.state.distanceOrigin}
          directionDrawerRef={this.state.directionDrawerRef}
          setIfCheckDistance={this.setIfCheckDistance}
          resetDistanceOrigin={this.resetDistanceOrigin}
          setOriginFlag={this.state.setOriginFlag}
          {...this.props}
        />
      )
    }
  }
  return compose(
    consumerConnector(),
  )(DistanceHOC)
}


export default distanceHOC