/* eslint-disable global-require */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'
import DensityButtons from '@atom/densityToggleButtons'
import DensityMockup from '@mol/densityMockup'

const modalLayout = {
  // used to overwrite the css layout
  modal: {
    minWidth: '600px',
    maxWidth: '600px',
    margin: 'auto',
  },
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'wrap',
    width: '580',
    justifyContent: 'center',
  },
  flexItem: {
    padding: '5px',
    boxSizing: 'border-box',
    height: '160',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  flexToggle: {
    padding: '5px 0px 8px 0px',
    boxSizing: 'border-box',
    height: '160',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  saveButton: {
    padding: '8px',
    boxSizing: 'border-box',
    height: '160',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}

class DensityModal extends Component {
    static propTypes = {
      closeModal: PropTypes.func.isRequired,
      saveContactMeta: PropTypes.func.isRequired,
      ifShowModal: PropTypes.bool,
      deviceListMode: PropTypes.string,
      mapIconMode: PropTypes.string,
      wMatrix: PropTypes.func.isRequired,
      mapIconSizeMode: PropTypes.string,
    }

    static defaultProps = {
      deviceListMode: 'comfortable',
      mapIconMode: 'comfortable',
      mapIconSizeMode: 'large',
      ifShowModal: false,
    }

    constructor(props) {
      super(props)
      const { deviceListMode, mapIconMode, mapIconSizeMode } = this.props
      this.state = {
        loading: false,
        listDensity: deviceListMode,
        mapDensity: mapIconMode,
        iconSize: mapIconSizeMode,
      }
    }

    /**
     * @deprecated
     */
    returnMapMockup = () => {
      let wireframe = null
      const { listDensity, mapDensity } = this.state
      switch (listDensity) {
        case 'comfortable':
          if (mapDensity === 'comfortable') {
            wireframe = require('@assets/img/mock_ui/Comfortable_Comfortable.svg')
          } else if (mapDensity === 'cozy') {
            wireframe = require('@assets/img/mock_ui/Comfortable_Cozy.svg')
          } else {
            wireframe = require('@assets/img/mock_ui/Comfortable_Compact.svg')
          }
          break
        case 'cozy':
          if (mapDensity === 'comfortable') {
            wireframe = require('@assets/img/mock_ui/Cozy_Comfortable.svg')
          } else if (mapDensity === 'cozy') {
            wireframe = require('@assets/img/mock_ui/Cozy_Cozy.svg')
          } else {
            wireframe = require('@assets/img/mock_ui/Cozy_Compact.svg')
          }
          break

        case 'compact':
          if (mapDensity === 'comfortable') {
            wireframe = require('@assets/img/mock_ui/Compact_Comfortable.svg')
          } else if (mapDensity === 'cozy') {
            wireframe = require('@assets/img/mock_ui/Compact_Cozy.svg')
          } else {
            wireframe = require('@assets/img/mock_ui/Compact_Compact.svg')
          }
          break
        default:
          wireframe = require('@assets/img/mock_ui/Comfortable_Comfortable.svg')
          break
      }
      return (<img src={wireframe} alt="wireframeSVG" />)
    }

    /** Update the current state of views when modal is opened */
    showModal = () => {
      const {
        deviceListMode, mapIconMode, mapIconSizeMode,
      } = this.props
      this.setState({ listDensity: deviceListMode })
      this.setState({ mapDensity: mapIconMode })
      this.setState({ iconSize: mapIconSizeMode })
    }

    /**
     * This should call a function from parent to change the state of the
     * view (rather than the state of this component)
     */
    handleSave = () => {
      const { listDensity, mapDensity, iconSize } = this.state
      const {
        closeModal, saveContactMeta,
      } = this.props
      this.setState({ loading: true })
      setTimeout(() => {
        this.setState({ loading: false })
        closeModal()
      }, 500)

      saveContactMeta(listDensity, mapDensity, iconSize)
    }

    handleCancel = () => {
      const { closeModal } = this.props
      closeModal()
      // this.setState({ visible: false })
    }

    /**
     * the following two functions are to update the preview image. Not the parents settings
     */
    handleListChange = (comfort) => {
      this.setState({ listDensity: comfort })
    }

    handleMapChange = (comfort) => {
      this.setState({ mapDensity: comfort })
    }

    handleIconSizeChange = (size) => {
      this.setState({ iconSize: size })
    }

    render() {
      const {
        listDensity, mapDensity, iconSize, loading,
      } = this.state
      const {
        wMatrix, deviceListMode, mapIconMode, mapIconSizeMode, ifShowModal,
      } = this.props
      return (
        <Modal
          destroyOnClose
          style={modalLayout.modal}
          centered
          visible={ifShowModal}
          title={wMatrix('View')}
          onOk={this.handleSave}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>{wMatrix('Cancel')}</Button>,
          ]}
          zIndex={10}
        >
          <div style={modalLayout.container}>
            <DensityMockup
              listMode={listDensity}
              iconMode={mapDensity}
              iconSize={iconSize}
            />
            <div style={modalLayout.flexItem}>
              <h3>{wMatrix('List')}</h3>
            </div>
            <div style={modalLayout.flexToggle}>
              <DensityButtons
                handleChange={this.handleListChange}
                selection={deviceListMode}
                {...this.props}
              />
            </div>
            <div style={modalLayout.flexItem}>
              <h3>{wMatrix('Map')}</h3>
            </div>
            <div style={modalLayout.flexToggle}>
              <DensityButtons
                handleChange={this.handleMapChange}
                selection={mapIconMode}
                {...this.props}
              />
            </div>
            <div style={modalLayout.flexItem}>
              <h3>{wMatrix('iconSize')}</h3>
            </div>
            <div style={modalLayout.flexToggle}>
              <DensityButtons
                handleChange={this.handleIconSizeChange}
                selection={mapIconSizeMode}
                customOptions={[
                  { title: 'small', value: 'small' },
                  { title: 'medium', value: 'medium' },
                  { title: 'large', value: 'large' },
                ]}
                wMatrix={wMatrix}
              />
            </div>
            <div style={modalLayout.saveButton}>
              <Button type="primary" loading={loading} onClick={this.handleSave}>{wMatrix('Save')}</Button>
            </div>
          </div>
        </Modal>
      )
    }
}

export default (DensityModal)
