import React, { Component } from "react"
import queryConnector from '@graphql/queryConnector'
import consumerConnector from '@graphql/consumerConnector'
import mutationConnector from '@graphql/mutationConnector'
import { preferenceQuery, getTimeZones, phoneCarriersQuery, countryCodes } from "@graphql/query"
import { updatePreference_mutation,
  createPersonalLabel,
  DeletePersonalLabel,
  assignPersonalLabelToDevice,
 } from '@graphql/mutation'
import {compose} from 'react-apollo'
import { getAllPersonalLabels } from "../../../graphql/query"

const preferenceHOC = () => WrappedComponent => {
  class PreferenceHOC extends Component {
    savePreference = (preference) => {
      /* Compare the new preference and old preference, only pass the value if changed */
      let argPreference = {}
      let ifNeedLogout = false
      let ifSwitchLanguage = false
      let ifSwitchMeasurement = false
      let propsPreference = this.props.preferenceData.data.preference
      if (propsPreference.email !== preference.email) {
        argPreference.email = preference.email
        ifNeedLogout = true
      }
      if (propsPreference.password !== preference.password) {
        argPreference.password = preference.password
        ifNeedLogout = true
      }
      if (propsPreference.firstName !== preference.firstName) {
        argPreference.firstName = preference.firstName
      }
      if (propsPreference.lastName !== preference.lastName) {
        argPreference.lastName = preference.lastName
      }
      if (propsPreference.mobilePhone !== preference.mobilePhone) {
        argPreference.mobilePhone = preference.mobilePhone
      }
      if (propsPreference.mobilePhone !== preference.mobilePhone || propsPreference.textAddress !== preference.textAddress) {
        argPreference.textAddress = preference.textAddress
        /** if no mobile phone, no reason to keep phone carrier */
        if(preference.mobilePhone === ''){
          argPreference.textAddress = ''
        }
      }
      if (propsPreference.language !== preference.language) {
        argPreference.language = preference.language
        ifSwitchLanguage = true
      }
      if(propsPreference.distanceMeasurement !== preference.measurement) {
        argPreference.measurement = preference.measurement
        ifSwitchMeasurement = true
      }
      if (propsPreference.timeZoneId !== preference.timeZoneId) {
        argPreference.timeZoneId = preference.timeZoneId
        this.props.switchTimeZoneId(preference.timeZoneId)
      }
      if (propsPreference.mapRefresh !== preference.mapRefresh) {
        argPreference.mapRefresh = preference.mapRefresh
        this.props.switchMapRefresh(preference.mapRefresh)
      }
      if (propsPreference.country !== preference.country) {
        argPreference.country = preference.country
      }

      /* If nothing is changed, return a fake success promise */
      if (Object.keys(argPreference).length === 0) {
        return Promise.resolve({
          code: 1000,
          description: 'success',
          ifNeedLogout : ifNeedLogout
        })
      } else {
        return this.props.updatePreference({ variables: argPreference }).then(res => {
          let codeResponse = res.data.updatePreference
          if (codeResponse.code === 1000) {
            if (ifSwitchLanguage) {
              this.props.switchLanguage(preference.language)
            }
            if (ifSwitchMeasurement) {
              this.props.switchDistanceMeasurement(preference.measurement)
            }
            this.props.preferenceData.refetch()
          } else {
            ifNeedLogout = false
          }

          return {
            code: codeResponse.code,
            description: codeResponse.description,
            ifNeedLogout : ifNeedLogout
          }
        })
      }
    }

    createNewLabel = (newTag, devices) => {
      this.props.createPersonalLabel({variables: newTag}).then((response) => {
        // add this new tag to the list of device Ids
        if(response && devices != null){
          this.props.assignLabelToDevice({variables: {labelIds: response.data.createPersonalLabel.id, devices: devices, ifEditingTag: true}}).then((response2) => {
            this.props.refetchDevices().then(res => {
              this.props.personalLabelData.refetch()
            })
          })
        }
      })
    }

    deleteLabel = (labelId) => {
      this.props.deletePersonalLabel({variables: {id: labelId}}).then((response) => {
        if (response) {
          this.props.personalLabelData.refetch()
          this.props.deviceListData.refetch()
        }
      })
    }

    render = ({ loading, error, data, refetch } = this.props) => {
      const { countryCodesData } = this.props

      return (<WrappedComponent
        savePreference = {this.savePreference}
        refetch = {refetch}
        {...this.props}
        labels={this.props.personalLabelData.data.label}
        deleteLabel={this.deleteLabel}
        createNewLabel={this.createNewLabel}
        timeZoneData={this.props.timeZoneData}
        countryCodes={countryCodesData && countryCodesData.data && countryCodesData.data.countryCodes
          ? countryCodesData.data.countryCodes
          : []
        }
      />)
    }
  }

  return compose(
    // helper(),
    consumerConnector(),
    queryConnector(getTimeZones, {}, 'timeZoneData'),
    queryConnector(phoneCarriersQuery, {}, 'phoneCarriersData'),
    queryConnector(preferenceQuery, {}, 'preferenceData'),
    queryConnector(getAllPersonalLabels, {}, 'personalLabelData'),
    queryConnector(countryCodes, {}, 'countryCodesData'),
    mutationConnector(updatePreference_mutation, 'updatePreference'),
    mutationConnector(createPersonalLabel, 'createPersonalLabel'),
    mutationConnector(DeletePersonalLabel, 'deletePersonalLabel'),
    mutationConnector(assignPersonalLabelToDevice, 'assignLabelToDevice'),
  ) (PreferenceHOC)
}

export default preferenceHOC;
