import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ScrollElement from '@atom/scrollElement'

const styles = {
  scrollContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    scrollBehavior: 'smooth',
    alignItems: 'stretch',
    height: '100%',
    width: '100%',
  },
}

/**
 * @description This is a scrollView container that creates and manages references
 * to different containing elements. This view can programatically scroll to a selectedKey
 * NOTE that any elements you want to autoScroll based on the selectedKey will need to have
 * the 'key' prop set to the appropriate value
 */

export default class ScrollView extends Component {
  elements = {}

  scrollArea = React.createRef()

  static propTypes = {
    selectedKey: PropTypes.string,
    children: PropTypes.any,
  }

  static defaultProps = {
    selectedKey: null,
    children: null,
  }

  /**
   * @description This is used to update scroll offset given a selectedKey
   * @param {Object} prevProps
   */
  componentDidUpdate = (prevProps) => {
    const { selectedKey } = this.props
    if (prevProps.selectedKey !== selectedKey && selectedKey) {
      this.scrollToElement(selectedKey)
    }
  }

  /**
   * @description Add element reference by name to elements object.
   * This is passed as a prop to ScrollElements
   */
  register = (name, ref) => {
    this.elements[name] = ref
  }

  /**
   * @description Delete element reference by name from elements object.
   * This is passed as a prop to ScrollElements
   */
  unregister = (name) => {
    delete this.elements[name]
  }

  /**
   * Scroll to element based on item identifier
   */
  scrollToElement = (id) => {
    const node = this.elements[id]
    if (node) {
      const scrollViewNode = this.scrollArea
      const yOffset = node.current.offsetTop - scrollViewNode.current.offsetTop
      scrollViewNode.current.scrollTop = yOffset
    }
  }

  /**
   * @description Returns children as an array of scrollElements
   * @returns {[ScrollElement]} Array of ScrollElements
   */
  scrollElements = () => {
    const { children } = this.props
    const reactElementsItems = []
    if (children) {
      /** Create each item and push to render elements array */
      children.forEach((item, index) => {
        let identifier = `${index}`
        if (item.key) {
          identifier = item.key
        }
        reactElementsItems.push(
          <ScrollElement
            style={{ zIndex: '1' }}
            key={identifier}
            name={identifier}
            register={this.register}
            unregister={this.unregister}
          >
            {item}
          </ScrollElement>,
        )
      })
    }
    return reactElementsItems
  }

  render() {
    return (
      <div style={styles.scrollContainer} ref={this.scrollArea} className="scrollView div">
        {this.scrollElements()}
      </div>
    )
  }
}
