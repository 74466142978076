import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Button } from 'antd'
import { CSVLink } from 'react-csv'
import moment from 'moment'
import Tag from '@atom/tag'
import { InputSearch } from '@atom/input'

export default class DevicesTable extends Component {
  /* eslint-disable react/destructuring-assignment */
  devColumns = [{
    title: this.props.wMatrix('Alias Name'),
    dataIndex: 'alias',
  }, {
    title: this.props.wMatrix('Driver'),
    dataIndex: 'currentDriver.name',
    render: names => (
      <span style={{ whiteSpace: 'nowrap' }}>
        {names}
      </span>
    ),
  }, {
    // is this personal labels or company groups?
    title: this.props.personal ? this.props.wMatrix('Current Labels') : this.props.wMatrix('Current Groups'),
    dataIndex: this.props.personal ? 'labels' : 'groups',
    render: tags => (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {tags.map(tag => (
          <div key={tag.id} style={{ display: 'flex' }}>
            <Tag
              id={tag.id}
              name={tag.name}
              color={tag.color}
              personal={this.props.personal}
            />
          </div>
        ))}
      </div>
    ),
  },
  ]

  static propTypes = {
    devices: PropTypes.array,
    checkedDevices: PropTypes.array,
    // reset: PropTypes.bool,
    personal: PropTypes.bool,
    wMatrix: PropTypes.func.isRequired,
    updateSelectedRows: PropTypes.func,
  }

  static defaultProps = {
    devices: [],
    checkedDevices: [],
    // reset: false,
    personal: false,
    updateSelectedRows: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      searchVal: '',
    }
  }

  updateSearch = (event) => {
    this.setState({ searchVal: event.target.value.substr(0, 20) })
  }

  // rowSelection object indicates the need for row selection

  onSelectChange = (selectedRowKeys) => {
    const { updateSelectedRows } = this.props

    updateSelectedRows('devices', selectedRowKeys)
  }

  // used to test search cases of partial group names
  inGroupList(groupList) {
    const { searchVal } = this.state
    // create an array of just the names
    const groupArray = groupList.map(group => group.name)
    // test each name in array to have reactive search work with partial group names
    for (let i = 0; i < groupArray.length; i += 1) {
      if (groupArray[i].toLowerCase().indexOf(searchVal.toLowerCase()) !== -1) {
        return true
      }
    }
    return false
  }


  render() {
    const { searchVal } = this.state
    const { devices, wMatrix, checkedDevices } = this.props
    const rowSelection = {
      selectedRowKeys: checkedDevices,
      onChange: this.onSelectChange,
    }

    // for now, this just filters based on device alias and driver name
    const filteredDevices = devices.filter(
      device => (
        (device.alias.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1)
        || (device.currentDriver != null
          && (device.currentDriver.name.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1))
        || (this.inGroupList(device.groups))
      ),
    )

    return (
      <div style={{ display: 'flex', marginTop: 50, flexDirection: 'column' }}>
        <InputSearch
          style={{ alignSelf: 'flex-end', marginBottom: '5px' }}
          className="groupPanelSearch"
          onChange={this.updateSearch}
          placeholder={`${wMatrix('Device')}, ${wMatrix('Driver')}, ${wMatrix('Group')}`}
        />
        <Table dataSource={filteredDevices} columns={this.devColumns} rowSelection={rowSelection} rowKey="id" size="middle" />
      </div>
    )
  }
}

export class DevicesTableV2 extends Component {
  static propTypes = {
    style: PropTypes.object,
    columnKeys: PropTypes.arrayOf(PropTypes.string),
    devices: PropTypes.array,
    checkedDevices: PropTypes.array,
    updateCheckedDevices: PropTypes.func,
    selectDevice: PropTypes.func,
    wMatrix: PropTypes.func.isRequired,
  }

  static defaultProps = {
    style: {},
    columnKeys: ['alias', 'location'],
    devices: [],
    checkedDevices: [],
    updateCheckedDevices: null,
    selectDevice: null,
  }

  state = {
    searchVal: '',
  }

  /**
   * @public
   */
  onCheckboxChange = (selectedRowKeys) => {
    const { updateCheckedDevices } = this.props

    if (updateCheckedDevices) updateCheckedDevices('devices', selectedRowKeys)
  }

  /**
   * @private
   */
  updateSearch = (event) => {
    this.setState({ searchVal: event.target.value.substr(0, 20) })
  }

  /**
   * @private
   *
   * Creates columns and uses the ones determined by columnKeys
   *
   * @note searchVal from the state and filteredValue and onFilter, which are
   *  commented out, are used for the Ant Design filter dropdown if we choose to go that route.
   *  See https://ant.design/components/table/#components-table-demo-custom-filter-panel
   *
   * @returns {Array} columns
   */
  columns = () => {
    // const { searchVal } = this.state
    const { selectDevice, wMatrix } = this.props

    const columns = [{
      key: 'icon',
      dataIndex: 'icon',
    }, {
      key: 'alias',
      title: wMatrix('alias'),
      dataIndex: 'alias',
      filterable: true,
      sorter: (a, b) => a.alias.localeCompare(b.alias),
      render: (text, device) => {
        if (selectDevice) return (<Button type="link" style={{ borderWidth: 0, color: '#1890FF' }} onClick={() => selectDevice(device)}>{text}</Button>)
        return text
      },
    }, {
      key: 'driver',
      title: wMatrix('driver'),
      dataIndex: 'currentDriver.name',
      filterable: true,
      sorter: (a, b) => {
        const da = (a.driver === null) ? '' : `${a.currentDriver.name}`
        const db = (b.driver === null) ? '' : `${b.currentDriver.name}`

        if (da > db) {
          return 1
        }
        if (da === db) {
          return 0
        }
        return -1
      },
    }, {
      key: 'groups',
      title: wMatrix('Groups'),
      dataIndex: 'groups',
      render: tags => (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {tags.map(tag => (
            <div key={tag.id} style={{ display: 'flex' }}>
              <Tag
                id={tag.id}
                name={tag.name}
                color={tag.color}
              />
            </div>
          ))}
        </div>
      ),
      filterable: true,
    }, {
      key: 'labels',
      title: wMatrix('Labels'),
      dataIndex: 'labels',
      render: tags => (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {tags.map(tag => (
            <div key={tag.id} style={{ display: 'flex' }}>
              <Tag
                id={tag.id}
                name={tag.name}
                color={tag.color}
                personal
              />
            </div>
          ))}
        </div>
      ),
      filterable: true,
    }, {
      key: 'productKey',
      title: wMatrix('productKey'),
      dataIndex: 'productKey',
      filterable: true,
      sorter: (a, b) => a.productKey.localeCompare(b.productKey),
    }, {
      key: 'serialNumber',
      title: wMatrix('serial'),
      dataIndex: 'simSerial',
      filterable: true,
      sorter: (a, b) => a.simSerial.localeCompare(b.simSerial),
    }, {
      key: 'phoneNumber',
      title: wMatrix('phoneNumber'),
      dataIndex: 'phoneNumber',
      filterable: true,
      sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
    }, {
      key: 'carrier',
      title: wMatrix('Phone Carrier'),
      dataIndex: 'networkProvider',
      filterable: true,
      sorter: (a, b) => a.networkProvider.localeCompare(b.networkProvider),
    }, {
      key: 'network',
      title: wMatrix('network'),
      dataIndex: 'network',
      filterable: true,
      sorter: (a, b) => a.network.localeCompare(b.network),
    }, {
      key: 'location',
      title: wMatrix('location'),
      dataIndex: 'address.full',
      filterable: true,
      sorter: (a, b) => a.address.full.localeCompare(b.address.full),
    }, {
      key: 'address',
      title: wMatrix('address'),
      dataIndex: 'address.full',
      filterable: true,
      sorter: (a, b) => a.address.full.localeCompare(b.address.full),
    }, {
      /** @todo */
      key: 'landmarks',
      title: wMatrix('Landmarks'),
      dataIndex: 'landmarks',
      // filteredValue: [searchVal],
      // onFilter: (value, device) =>
      //    device.address.full.toLowerCase().indexOf(value.toLowerCase()) !== -1,
      // filterable: true,
      // sorter: (a, b) => a.address.full.localeCompare(b.address.full),
      // width: 200,
    }, {
      key: 'datetime',
      title: wMatrix('datetime'),
      dataIndex: 'locate.date',
      render: (text, device) => `${device.locate.date} ${device.locate.time}`,
      sorter: (a, b) => {
        const da = new Date(`${a.locate.date} ${a.locate.time}`)
        const db = new Date(`${b.locate.date} ${b.locate.time}`)

        return da - db
      },
    }, {
      key: 'status',
      title: wMatrix('status'),
      dataIndex: 'status',
    }]

    const { columnKeys } = this.props

    return columns.filter(column => columnKeys.includes(column.key))
  }

  /**
   * @private
   *
   * Filters supplied devices from props and returns a new array.
   * This functions uses the columnsKeys prop to dynamically determine
   * where to apply the filter.
   *
   * @returns {Array} devices
   */
  filteredDevices = () => {
    const { searchVal } = this.state
    const { devices, columnKeys } = this.props

    return devices.filter(
      device => (columnKeys.includes('alias') && device.alias.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1)
        || (columnKeys.includes('driver') && device.currentDriver != null
          && device.currentDriver.name.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1)
        || (columnKeys.includes('groups') && this.searchInTags(device.groups))
        || (columnKeys.includes('labels') && this.searchInTags(device.labels))
        || (columnKeys.includes('productKey') && device.productKey.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1)
        || (columnKeys.includes('serialNumber') && device.simSerial.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1)
        || (columnKeys.includes('phoneNumber') && device.phoneNumber.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1)
        || (columnKeys.includes('carrier') && device.phoneNumber.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1)
        || (columnKeys.includes('network') && device.phoneNumber.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1)
        || (columnKeys.includes('location') && device.address.full.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1)
        || (columnKeys.includes('address') && device.address.full.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1),
    )
  }

  /**
   * @private
   *
   * Determines the words in the filter's placeholder
   * using the column titles
   *
   * @param {Array} columns
   *
   * @returns {String} placeholder
   */
  filterPlaceholder = (columns) => {
    let placeholder = ''

    for (const column of columns) {
      if (column.filterable) placeholder += `${column.title}, `
    }

    return placeholder.length > 0 ? placeholder.slice(0, placeholder.length - 2) : placeholder
  }

  /**
   * @private
   *
   * Uses the searchVal from state and returns a boolean if
   * the search value is part of the name of one of the tags
   *
   * @param {Array} tags - can be either groups or labels
   *
   * @returns {Boolean} if search value is in supplied tags
   */
  searchInTags(tags) {
    const { searchVal } = this.state
    // create an array of just the names
    const tagsArray = tags.map(tag => tag.name)

    // test each name in array to have reactive search work with partial group names
    for (const tag of tagsArray) {
      if (tag.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1) return true
    }

    return false
  }

  generateCSVData() {
    const { devices, wMatrix } = this.props
    const csvData = [[
      wMatrix('Alias'),
      wMatrix('Driver'),
      wMatrix('Groups'),
      wMatrix('Labels'),
      wMatrix('productKey'),
      wMatrix('serial'),
      wMatrix('Location'),
      wMatrix('dateAndTime'),
    ]]

    for (const device of devices) {
      let groups = ''
      if (device.groups && device.groups.length > 0) {
        for (const group of device.groups) {
          groups += `${group.name}, `
        }
        groups = groups.substr(0, groups.length - 2)
      }
      let labels = ''
      if (device.labels && device.labels.length > 0) {
        for (const label of device.labels) {
          labels += `${label.name}, `
        }
        labels = labels.substr(0, labels.length - 2)
      }
      csvData.push([
        device.alias ? `${device.alias} ` : '',
        device.currentDriver ? `${device.currentDriver.name} ` : '',
        groups,
        labels,
        device.productKey ? `${device.productKey} ` : '',
        device.simSerial ? `${device.simSerial} ` : '',
        device.address ? device.address.full : '',
        `${device.locate.date} ${moment(device.locate.time, 'hh:mm:ss a').format('h:mm a')}`,
      ])
    }

    return csvData
  }

  render() {
    const {
      checkedDevices, updateCheckedDevices, style, wMatrix,
    } = this.props
    const csvData = this.generateCSVData()
    const columns = this.columns()
    let rowSelection = null

    if (updateCheckedDevices) {
      rowSelection = {
        selectedRowKeys: checkedDevices,
        onChange: this.onCheckboxChange,
      }
    }

    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 0 10px 0' }}>
          <div style={{ flexGrow: 1, alignSelf: 'center' }}>
            <span style={{ marginLeft: '5px', fontWeight: 'bolder' }}>
              {`${wMatrix('Total Devices')}: ${this.filteredDevices().length}`}
            </span>
          </div>

          <CSVLink data={csvData} target="_blank" filename="device_list.csv">
            <Button type="primary" style={{ borderWidth: 0, marginRight: '10px' }}>
              {wMatrix('exportDeviceList')}
            </Button>
          </CSVLink>
          <InputSearch
            style={{
              width: '30%', alignSelf: 'flex-end', marginBottom: '5px',
            }}
            onChange={this.updateSearch}
            placeholder={this.filterPlaceholder(columns)}
            className="groupPanelSearch"
          />
        </div>
        <div style={style || {
          flexGrow: 0,
          position: 'relative',
        }}
        >
          <Table
            className="listTableNoPagiArrow"
            rowKey="id"
            scroll={{ x: 'max-content', y: false }}
            dataSource={this.filteredDevices()}
            columns={columns}
            rowSelection={rowSelection}
            pagination={{
              defaultPageSize: 50, pageSizeOptions: ['50', '100', '250', '500'], showSizeChanger: true,
            }}
          />
        </div>
      </>
    )
  }
}
