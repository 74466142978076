import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LandmarkEditor from '@mol/landmarkEditor'
import helper from '@helper'
import RightDrawer from './rightDrawer'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    // overflow: 'auto',
    overflow: 'hidden',
    flex: 1,
  },
  blueHeader: {
    minHeight: '50px',
    flexShrink: '0',
    backgroundColor: '#102A96',
  },
}

/**
 * @description A container of instance rightDrawer that holds the timeline.
 *
 * @Note
 *  - If basing your drawer component on this, note that you do not *NEED* to keep track of the
 * expanded state. This is a special case. This component uses it to dynamically render its contents
 */

class LandmarksDrawer extends Component {
  state = {
    expanded: true,
    // activeKey: '1',
    // openDate: false,
  }

  static propTypes = {
    show: PropTypes.bool,
    wMatrix: PropTypes.func,
    selectedLandmark: PropTypes.object,
    setDrawType: PropTypes.func.isRequired,
    cancelEdit: PropTypes.func.isRequired,
    saveLandmark: PropTypes.func.isRequired,
    deleteLandmark: PropTypes.func.isRequired,
    zoomToPoint: PropTypes.func.isRequired,
    ifNewLandmark: PropTypes.bool,
    savingLandmark: PropTypes.bool,
    deletingLandmark: PropTypes.bool,
    selectedLandmarkCenter: PropTypes.object,
    /** @Main */
    mainNav: PropTypes.string.isRequired,
  }

  static defaultProps = {
    show: true,
    wMatrix: null,
    selectedLandmark: undefined,
    ifNewLandmark: false,
    savingLandmark: false,
    deletingLandmark: false,
    selectedLandmarkCenter: null,
  }

  expandGet = (expanded) => {
    this.setState({ expanded })
  }

  returnLandmarkEditor = () => {
    const {
      mainNav, selectedLandmark, wMatrix, setDrawType, cancelEdit,
      saveLandmark, zoomToPoint, ifNewLandmark, deleteLandmark,
      savingLandmark, deletingLandmark, selectedLandmarkCenter,
    } = this.props
    const { expanded } = this.state
    return (
      <LandmarkEditor
        mainNav={mainNav}
        wMatrix={wMatrix}
        landmark={selectedLandmark}
        setDrawType={setDrawType}
        cancelEdit={cancelEdit}
        saveLandmark={saveLandmark}
        deleteLandmark={deleteLandmark}
        zoomToPoint={zoomToPoint}
        ifNewLandmark={ifNewLandmark}
        savingLandmark={savingLandmark}
        deletingLandmark={deletingLandmark}
        expanded={expanded}
        selectedLandmarkCenter={selectedLandmarkCenter}
      />
    )
  }

  render() {
    const {
      show,
    } = this.props
    return (
      <RightDrawer
        show={show}
        tabs={[
          { name: 'Landmark Editor', id: '1' },
        ]}
        getExpansion={this.expandGet}
      >
        {this.returnLandmarkEditor()}
      </RightDrawer>
    )
  }
}

export default helper()(LandmarksDrawer)
