import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import GroupOfGroups from '@mol/groupOfGroups'
import movingIcon from '@assets/img/icons/moving_icon.svg'
import stopIcon from '@assets/img/icons/stop_icon.svg'
import calendarIcon from '@assets/img/icons/calendar.svg'
import locationIcon from '@assets/img/icons/location.svg'
import helper from '@helper'
import { Button, Tooltip } from 'antd'


const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  detailsRow: {
    margin: '0px 0px 8px 0px',
  },
  detailsText: {
    marginLeft: '4px',
  },
  detailsTextRed: {
    marginLeft: '4px',
    color: 'red',
  },
  sectionHeading: {
    marginTop: 0,
    alignSelf: 'flex-start',
  },
}

/**
 * @todo
 * Maybe:
 * - Add driver/driver score information
 * - Add service status icon/info
 */

class DeviceDetails extends PureComponent {
  static propTypes = {
    /** @helper */
    wMatrix: PropTypes.func.isRequired,
    locatedXAgo: PropTypes.func.isRequired,
    minutesToString: PropTypes.func.isRequired,
    speedToString: PropTypes.func.isRequired,
    /** @parent */
    device: PropTypes.object.isRequired,
    locateNow: PropTypes.object.isRequired,
  }

  componentDidMount = () => {
    const { locateNow, device } = this.props

    locateNow.getStatus(device.id)
  }

  componentDidUpdate = (prevProps) => {
    const { locateNow, device } = this.props

    if (prevProps.device.id !== device.id) locateNow.getStatus(device.id)
  }

  /**
   * returns stopped text
   */
  stopped = (stoppedMinutes) => {
    const { minutesToString, wMatrix } = this.props

    return `${wMatrix('Stopped')}  ${stoppedMinutes ? `(${minutesToString(stoppedMinutes)})` : ''}`
  }

  /**
   * returns moving text
   */
  moving = (speed) => {
    const { speedToString, wMatrix } = this.props

    return `${wMatrix('Moving')}  (${speedToString(speed)})`
  }

  /**
   * Returns details Item with icon label and value based on item type
   * @param {string} type
   * @param {string} value
   */
  detailItem = (type, value) => {
    let label = null
    if (type === 'location') {
      label = (<img src={locationIcon} alt="Status" style={{ height: 14, width: 14 }} />)
    } else if (type === 'date') {
      label = (<img src={calendarIcon} alt="Status" style={{ height: 14, width: 14 }} />)
    } else if (type === 'stopped') {
      label = (<img src={stopIcon} alt="Status" style={{ height: 14, width: 14 }} />)
    } else if (type === 'moving') {
      label = (<img src={movingIcon} alt="Status" style={{ height: 14, width: 14 }} />)
    } else {
      label = (<h4>{`${type}:`}</h4>)
    }
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 8,
      }}
      >
        {label}
        <span style={styles.detailsText}>{value}</span>
      </div>
    )
  }

  /**
   * Compares the last locate with the current time.
   *
   * Used to show if there is a problem with the device. If the device is still
   * in moving state and there has been some time, there's a problem.
   *
   * @param {string} datetimeUTC of last locate
    * @param {string} status of the device (moving, stopped, etc)
    */
  returnTimeSinceLastLocate = (datetimeUTC, status) => {
    const { locatedXAgo, minutesToString } = this.props
    // grab utc datetime to compare to current utc datetime
    const lastLocateUTC = moment(datetimeUTC)
    const momentNowUTC = moment.utc()
    const minSinceLastLocate = momentNowUTC.diff(lastLocateUTC, 'minutes')
    if (minSinceLastLocate > 10 && status.state === 'Moving') {
      return (
        <div
          style={minSinceLastLocate > (30 * 24 * 60) ? styles.detailsTextRed : styles.detailsText}
        >
          {locatedXAgo(minutesToString(minSinceLastLocate, 30))}
        </div>
      )
    }
    return null
  }

  /**
   * @private
   * @description Returns locate details section
   */
  returnLocateDetails = () => {
    const { device, wMatrix } = this.props
    const { locate, status } = device

    let statusText = ''
    if (status.state === 'Stopped' || status.state === 'Parking') {
      statusText = this.stopped(status.stoppedMinutes)
    } else {
      statusText = this.moving(locate.speed)
    }
    // Create landmark or address string
    let location = locate.address.full
    if (locate.landmarks.length > 0) {
      // placed in try catch just in case parsing or mapping fails
      try {
        const landmarksClone = JSON.parse(JSON.stringify(locate.landmarks))
        location = landmarksClone.map(landmark => landmark.name).join(', ')
        const landmarkLabel = (locate.landmarks.length === 1) ? 'landmark' : 'landmarks'
        location += ` (${wMatrix(landmarkLabel)})`
      } catch {
        location = locate.address.full
      }
    }

    return (
      <div style={{ display: 'flex', flexGrow: 1 }}>
        <h3 style={styles.sectionHeading}>{`${wMatrix('Last Locate')}:`}</h3>
        <div style={{
          display: 'flex', flexGrow: 1, flexDirection: 'column', marginLeft: 6, marginTop: 2,
        }}
        >
          <div style={{ display: 'flex' }}>
            {this.detailItem('date', (`${locate.date} ${moment(locate.time, 'LTS').format('h:mm a')}`))}
            <Tooltip title={locate.address.full}>
              {this.detailItem('location', location)}
            </Tooltip>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {status.state === 'Stopped' || status.state === 'Parking'
              ? this.detailItem('stopped', statusText)
              : this.detailItem('moving', statusText)
            }
            {this.returnTimeSinceLastLocate(locate.datetimeUTC, status)}
          </div>
        </div>
      </div>
    )
  }

  /**
   * @private
   * @description Return Camera Details section
   */
  returnCameraDetails = () => {
    const {
      device, wMatrix, minutesToString,
    } = this.props
    const { camera } = device
    // if no camera, return null
    if (!camera) {
      return null
    }
    const camMinutes = camera.data.remaining.minutes
    if (camMinutes) {
      return (
        <div style={{
          display: 'flex', flexGrow: 1, alignItems: 'flex-start', marginLeft: 90,
        }}
        >
          <h3 style={{ ...styles.sectionHeading, margin: 0 }}>{`${wMatrix('camera')}:`}</h3>
          <span style={{ marginTop: 2, marginLeft: 6 }}>{`${minutesToString(camMinutes)}`}</span>
        </div>
      )
    }

    return null
  }

  /**
   * @private
   * @description Return 'Locate Now' button if device has starter interrupt
   */
  returnLocateNowButton = () => {
    const {
      device, wMatrix, locateNow,
    } = this.props
    const { starterInterrupt } = device
    if (!starterInterrupt) {
      return null
    }
    return (
      <Button
        type="primary"
        size="small"
        style={{ alignSelf: 'flex-start', marginRight: 5 }}
        onClick={() => { locateNow.run(device.id) }}
        disabled={!locateNow.canRun}
        loading={locateNow.loading}
      >
        {locateNow.canRun ? wMatrix('locateNow') : wMatrix('pending')}
      </Button>
    )
  }

  /**
   * @private
   * @description Returns grid of locate details
   */
  returnDeviceDetails = () => (
    <div style={{
      display: 'flex', flexGrow: 1, alignItems: 'flex-start', padding: '10px 0px', justifyContent: 'space-between',
      // marginTop: 10,
    }}
    >
      <div style={{ display: 'flex' }}>
        {this.returnLocateDetails()}
        {this.returnCameraDetails()}
      </div>
      {this.returnLocateNowButton()}
    </div>
  )

  render() {
    const { device } = this.props
    return (
      <div style={styles.wrapper}>
        <GroupOfGroups
          groups={device.groups}
          labels={device.labels}
          wrap
          toggleable
          defaultSmall
        />
        {this.returnDeviceDetails()}
      </div>
    )
  }
}

export default helper()(DeviceDetails)
