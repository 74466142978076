/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Switch from '@atom/switch'
import FaIcon from '@atom/fontawesomeIcon'
import elipse from '@assets/img/icons/toggle_Elipse_Horizontal.svg'
import helper from '@helper'
import { Button } from 'antd'
import AddressAutoComplete from '@atom/addressAutoComplete'

const styles = {
  container: {
    position: 'absolute',
    zIndex: 1,
    fontSize: '14px',
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.004)',
    top: 0,
    height: 'auto',
    // height: '40px',
    paddingLeft: '10px',
    paddingTop: '5px',
    paddingBottom: '5px',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  /**
   * @NOTE that google map icons are usually 40x40px, however, ant design has a bug where the
   * autocomplete height can only be 35. It was easier just to make the settings icon
   * the same height.
   */
  svg: {
    float: 'left',
    width: 35,
    height: 35,
    paddingLeft: 8,
    paddingRight: 8,
    flexShrink: 0,
  },
  panel: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexGrow: 1,
    alignItems: 'center',
    height: 50,
  },
  closeMapControls: {
    marginLeft: 14,
  },
}

class MapControl extends Component {
  static propTypes = {
    settings: PropTypes.object.isRequired,
    updateSettings: PropTypes.func.isRequired,
    wMatrix: PropTypes.func.isRequired,
    selectModal: PropTypes.func.isRequired,
    landmarkToggle: PropTypes.bool, // when true, show landmark toggle
    animationToggle: PropTypes.bool,
    clusteringToggle: PropTypes.bool,
    fitBounds: PropTypes.func.isRequired,
    placeSearchMarker: PropTypes.func.isRequired,
    removeSearchMarker: PropTypes.func.isRequired,
    hidePlaceSearch: PropTypes.bool,
    maps: PropTypes.object,
  }

  static defaultProps = {
    landmarkToggle: false,
    animationToggle: false,
    clusteringToggle: false,
    hidePlaceSearch: false,
    maps: null,
  }

  state = {
    showControl: false,
  }

  toggleMapControl = () => {
    const { showControl } = this.state
    this.setState({ showControl: !showControl })
  }

  /**
   * Used to call prop function fitbounds and placeSearchMarker from map component to place a
   * marker according to the supplied coordinates and zoom to that point. This will also remove
   * the marker if the search is cleared.
   * @param {Object} coordinates coordinate object of form {lat, lng}
   */
  onPlaceSelect = (coordinates) => {
    const {
      fitBounds, maps, removeSearchMarker, placeSearchMarker,
    } = this.props

    // if no coordinates, search box was cleared
    if (!coordinates) {
      // clear search marker
      removeSearchMarker()
      /**
       * @TODO
       * - possibly zoom out on clear of search.
       *     - This is difficult because we do not have
       *        direct access to the map's markers/zoom bounds (device markers,
       *        landmark markers, etc)
       */
    } else if (fitBounds && maps) {
      // coordinates passed and maps and fitbounds exist
      // place search marker
      placeSearchMarker(coordinates)
      // zoom to search marker
      const bounds = new maps.LatLngBounds()
      bounds.extend(new maps.LatLng(
        coordinates.lat, coordinates.lng,
      ))
      fitBounds(bounds, 14)
    }
  }

  render() {
    const { showControl } = this.state
    const {
      settings, updateSettings, wMatrix,
      landmarkToggle, selectModal, animationToggle,
      clusteringToggle, hidePlaceSearch,
    } = this.props

    return (
      <div style={{ ...styles.container, background: showControl ? '#fff' : 'none', width: showControl ? '100%' : 'auto' }}>
        <div style={{ display: showControl ? 'none' : 'flex', alignItems: 'center' }}>
          <Button
            icon="setting"
            style={styles.svg}
            onClick={this.toggleMapControl}
          />
          {hidePlaceSearch ? null : (
            <AddressAutoComplete
              onSelect={this.onPlaceSelect}
              wMatrix={wMatrix}
              placeholder={wMatrix('searchMap')}
              style={{ marginLeft: 10, width: 300 }}
              showSearchIcon
            />
          )}
        </div>
        <div style={{ ...styles.closeMapControls, ...{ display: showControl ? 'inline' : 'none' } }}>
          <FaIcon onClick={this.toggleMapControl} color="dark-blue" icon="times" fontSize={14} />
        </div>
        <div style={{
          ...styles.panel, display: showControl ? 'flex' : 'none', paddingLeft: 10, paddingRight: 10,
        }}
        >
          <b style={styles.b}>
            {wMatrix('Satellite')}
            <Switch size="small" style={{ marginLeft: 6 }} defaultChecked={settings.satellite} onChange={(isChecked) => { updateSettings('satellite', isChecked) }} />
          </b>
          <b style={styles.b}>
            {wMatrix('Traffic')}
            <Switch size="small" style={{ marginLeft: 6 }} defaultChecked={settings.traffic} onChange={(isChecked) => { updateSettings('traffic', isChecked) }} />
          </b>
          { clusteringToggle ? (
            <b style={styles.b}>
              {wMatrix('Clustering')}
              <Switch size="small" style={{ marginLeft: 6 }} defaultChecked={settings.clustering} onChange={(isChecked) => { updateSettings('clustering', isChecked) }} />
            </b>
          ) : null }
          { animationToggle ? (
            <b style={styles.b}>
              {wMatrix('Animation')}
              <Switch size="small" style={{ marginLeft: 6 }} defaultChecked={settings.animation} onChange={(isChecked) => { updateSettings('animation', isChecked) }} />
            </b>
          ) : null }
          <b style={styles.b}>
            {wMatrix('Night Mode')}
            <Switch size="small" style={{ marginLeft: 6 }} defaultChecked={settings.nightMode} onChange={(isChecked) => { updateSettings('nightMode', isChecked) }} />
          </b>
          {landmarkToggle ? (
            <b style={styles.b}>
              {wMatrix('Landmarks')}
              <Switch size="small" style={{ marginLeft: 6 }} defaultChecked={settings.showLandmarks} onChange={(isChecked) => { updateSettings('showLandmarks', isChecked) }} />
            </b>
          ) : null}
          <b style={styles.b}>
            {wMatrix('View')}
            <img
              src={elipse}
              onClick={() => { selectModal('density') }}
              style={{ marginLeft: 6, cursor: 'pointer' }}
              alt="elipse"
            />
          </b>
        </div>
      </div>
    )
  }
}

export default helper()(MapControl)
