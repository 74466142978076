import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextButton from '@atom/textButton'
import CustomDatePicker from '@atom/customDatePicker'
import FilterIcon from '@assets/img/icons/filter_icon.svg'

const styles = {
  blueHeader: {
    minHeight: '50px',
    padding: '4px',
    display: 'flex',
    flexShrink: '0',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    alignItems: 'center',
    color: 'white',
    backgroundColor: '#102A96',
  },
}

class TimelineHeader extends Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    toggleFilterPage: PropTypes.func,
    changeTripDate: PropTypes.func,
    date: PropTypes.string.isRequired,
    expanded: PropTypes.bool,
    filterPage: PropTypes.bool,
    lastThirty: PropTypes.bool,
  }

  static defaultProps = {
    filterPage: false,
    expanded: true,
    toggleFilterPage: () => {},
    changeTripDate: () => {},
    lastThirty: false,
  }

  returnFilterButton = () => {
    const { filterPage, toggleFilterPage, wMatrix } = this.props
    return (
      filterPage ? <TextButton text={wMatrix('apply')} onClick={() => { toggleFilterPage() }} />
        : (
          <TextButton useAsWrapper onClick={() => { toggleFilterPage() }}>
            <img src={FilterIcon} alt="Filter" style={{ margin: '5px' }} />
          </TextButton>
        )
    )
  }

  renderHeader = () => {
    const {
      date, expanded, changeTripDate, lastThirty, wMatrix,
    } = this.props
    if (lastThirty) {
      return (
        <div style={styles.blueHeader}>
          <h3 style={{ color: '#FFF', margin: '0px 4px', textAlign: expanded ? 'left' : 'center' }}>
            {wMatrix('lastThirtyDays')}
          </h3>
          {expanded ? this.returnFilterButton() : null}
        </div>
      )
    }
    return (
      <div style={styles.blueHeader}>
        <CustomDatePicker selectedDate={date} small={!expanded} setDate={changeTripDate} />
        {expanded ? this.returnFilterButton() : null}
      </div>
    )
  }

  render() {
    return (
      this.renderHeader()
    )
  }
}

export default TimelineHeader
