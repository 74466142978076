import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import DeviceListOnly from '@org/deviceListOnly'
import { Input, Select, Button } from 'antd'
// import TagFilter from '@mol/tagFilter'
import LandmarksList from '@mol/landmarksList'
import LeftContainer from './left.container'

const { Search } = Input
const { Option } = Select
const styles = {
  formContainer: {
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  search: {
    marginBottom: 10,
  },
  sortByDropdown: {
    width: '100%',
  },
}

/**
 * Composition of the LeftContainer
 */
export default class LeftLandmarksContainer extends Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    selectModal: PropTypes.func.isRequired,
    mainNav: PropTypes.string,
    setNav: PropTypes.func.isRequired,
    deviceListMode: PropTypes.string.isRequired,
    saveContactMeta: PropTypes.func.isRequired,
    mapIconMode: PropTypes.string.isRequired,
    mapIconSizeMode: PropTypes.string.isRequired,
    filterLandmark: PropTypes.func.isRequired,
    // existingTagList: PropTypes.array,
    // filterDeviceByTag: PropTypes.func.isRequired,
    landmarks: PropTypes.array,
    selectedLandmark: PropTypes.object,
    selectLandmark: PropTypes.func.isRequired,
    unselectLandmark: PropTypes.func.isRequired,
    changeLandmarkSortBy: PropTypes.func.isRequired,
    landmarkSortBy: PropTypes.string,
    setIfNewLandmark: PropTypes.func.isRequired,
    // formatVehicleStatus: PropTypes.func.isRequired,
    filterLandmarkStr: PropTypes.string,
  }

  static defaultProps = {
    mainNav: 'devices',
    // existingTagList: [],
    landmarks: [],
    selectedLandmark: null,
    landmarkSortBy: 'name',
    filterLandmarkStr: '',
  }

  /**
   * @private
   */
  filterLandmark = (e) => {
    const { filterLandmark } = this.props

    filterLandmark(e.target.value)
  }


  /**
   * @todo add filter by tag
   */
  renderHeaderDetails = () => {
    const {
      wMatrix,
      changeLandmarkSortBy, landmarkSortBy,
      unselectLandmark, setIfNewLandmark,
      filterLandmarkStr, selectModal,
      // existingTagList
    } = this.props

    return (
      <div style={styles.formContainer}>
        <Search
          style={styles.search}
          placeholder={wMatrix('searchForLandmark')}
          onChange={this.filterLandmark}
          allowClear
          defaultValue={filterLandmarkStr}
        />
        <Select
          // className="distanceSortBy"
          style={styles.sortByDropdown}
          // placeholder="Sort by:"
          onChange={changeLandmarkSortBy}
          value={landmarkSortBy}
        >
          <Option key="name" value="name">{`${wMatrix('sortBy')}: ${wMatrix('Name')}`}</Option>
          <Option key="type" value="type">{`${wMatrix('sortBy')}: ${wMatrix('Type')}`}</Option>
        </Select>
        {/* <TagFilter
          className="tagFilter"
          existingTagList={existingTagList}
          filterDeviceByTag={filterDeviceByTag}
        /> */}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Button
            style={{ margin: '15px 0' }}
            type="primary"
            onClick={() => {
              unselectLandmark()
              setIfNewLandmark(true)
            }}
          >
            Create New
          </Button>
          <Button
            icon="upload"
            style={{ marginLeft: 15, width: 36 }}
            onClick={() => selectModal('landmarkImport')}
          />
        </div>
      </div>
    )
  }

  render() {
    const {
      mainNav, setNav, deviceListMode, landmarks, selectedLandmark,
      selectLandmark, selectModal, filterLandmarkStr, mapIconMode,
      mapIconSizeMode, saveContactMeta,
    } = this.props

    return (
      <LeftContainer
        mainNav={mainNav}
        setNav={setNav}
        saveContactMeta={saveContactMeta}
        deviceListMode={deviceListMode}
        mapIconMode={mapIconMode}
        mapIconSizeMode={mapIconSizeMode}
        renderHeaderDetails={this.renderHeaderDetails}
        selectModal={selectModal}
      >
        <LandmarksList
          landmarks={landmarks}
          selectedLandmark={selectedLandmark}
          selectLandmark={selectLandmark}
          listMode={deviceListMode}
          filterLandmarkStr={filterLandmarkStr}
        />
      </LeftContainer>
    )
  }
}
