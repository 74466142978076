import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer,
} from 'recharts'
import Card from '@atom/card'
import Skeleton from '@atom/skeleton'
import helper from '@helper'


const COLORS = ['#54B185', '#EFDE74', '#D75A4D']
const RADIAN = Math.PI / 180

const styles = {
  skelContainer: {
    height: '99%',
    width: '80%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: 'auto',
  },
  pieSkel: {
    width: '100%',
    paddingBottom: '100%',
    position: 'relative',
    borderRadius: '50%',
  },
}

/**
 * @todo
 * - potentially make the chart sizing more adaptive; if card has height>width
 * have key take less width
 */

class DriverPerformance extends Component {
  static propTypes = {
    appHeight: PropTypes.number.isRequired,
    wMatrix: PropTypes.func.isRequired,
    data: PropTypes.array,
    loading: PropTypes.bool,
  }

  static defaultProps = {
    data: null,
    loading: false,
  }

  renderLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent,
  }) => {
    const radius = innerRadius + ((outerRadius - innerRadius) * 0.3)
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {percent ? `${(percent * 100).toFixed(0)}%` : null}
      </text>
    )
  }

  /** Returns custom legend for chart */
  legendContent = () => {
    const { data, wMatrix } = this.props

    let total = 0
    for (let i = 0; i < data.length; i += 1) {
      total += data[i].value
    }

    return (
      <ul>
        <div>
          {`${wMatrix('totalDrivers')}: ${total}`}
        </div>
        {
          data.map((entry, index) => (
            <li key={`item-${entry.name}`} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{
                height: 20, width: 30, backgroundColor: COLORS[index % COLORS.length], margin: 8,
              }}
              />
              {`${wMatrix(entry.name)}: ${entry.value}`}
            </li>
          ))
        }
      </ul>
    )
  }

  /**
   * returns skeleton of pie chart
   */
  /**
   * @todo - add styling
   */
  renderLoading = () => (
    <div style={styles.skelContainer}>
      <div style={{ width: '80%', maxWidth: 260 }}>
        <Skeleton wrapperStyle={styles.pieSkel} />
      </div>
      <div style={{ width: 146, height: 130 }}>
        <Skeleton wrapperStyle={{ height: 20, width: 102, margin: 8 }} />
        <Skeleton wrapperStyle={{ height: 20, width: 102, margin: 8 }} />
        <Skeleton wrapperStyle={{ height: 20, width: 102, margin: 8 }} />
        <Skeleton wrapperStyle={{ height: 20, width: 102, margin: 8 }} />
      </div>
    </div>
  )

  /**
   * return the pie chart given that there is data passed in props
   */
  returnDataCard = () => {
    const { data, appHeight } = this.props
    // 106 above grey container
    // 34 below grey container
    const halfAvailableHeight = ((appHeight - 106 - 34) / 2)
    // take out card padding and use 80% of result for content
    const availableCardHeight = (halfAvailableHeight - 5 - 2.5) * 0.8
    return (
      <ResponsiveContainer height={availableCardHeight} width="80%">
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius="30%"
            outerRadius="80%"
            fill="#FF0000"
              // paddingAngle={1}
            labelLine={false}
            label={this.renderLabel}
          >
            {
              data.map(
                (entry, index) => <Cell key={entry} fill={COLORS[index % COLORS.length]} />,
              )
              }
          </Pie>
          <Tooltip />
          <Legend
            layout="vertical"
            align="right"
            verticalAlign="middle"
            iconSize={20}
              // width="30%"
            content={this.legendContent}
          />
        </PieChart>
      </ResponsiveContainer>
    )
  }

  render() {
    const { wMatrix, data, loading } = this.props

    let contents = null

    if (data) {
      contents = this.returnDataCard()
    } else if (loading) {
      contents = this.renderLoading()
    } else {
      contents = (
        <div>
          {wMatrix('noData')}
        </div>
      )
    }

    return (
      <Card
        title={wMatrix('Performance')}
        postTitle={(
          <span style={{ color: 'rgba(0,0,0,0.65)' }}>
            {`(${wMatrix('thisWeek')})`}
          </span>
        )}
      >
        {contents}
      </Card>
    )
  }
}

export default helper()(DriverPerformance)
