import React from 'react'
import PropTypes from 'prop-types'
import LogisticsJob from '@mol/logistics/job'
import FullScreenNav from '@mol/fullScreenNav'
import LogisticsHOC from '../hoc/logistics.hoc'
import LogisticsMenu from '../molecules/logistics/menu'
import LogisticsDashboard from '../molecules/logistics/dashboard'

const styles = {
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
  },
  rightWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: 0,
    overflow: 'auto',
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '240px',
  },
  blankPage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

class LogisticsView extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    handleQuickCreate: PropTypes.func.isRequired,
    devices: PropTypes.array.isRequired,
    appHeight: PropTypes.number.isRequired,
    menuItems: PropTypes.array.isRequired,
    onGroupChange: PropTypes.func.isRequired,
    statusData: PropTypes.object.isRequired,
    filterMenu: PropTypes.func.isRequired,
    nav: PropTypes.string.isRequired,
    handleSelectJob: PropTypes.func.isRequired,
    navCreate: PropTypes.func.isRequired,
    navDashboard: PropTypes.func.isRequired,
    selectedJob: PropTypes.object,
    openMessenger: PropTypes.func,
    createJob: PropTypes.func.isRequired,
    updateJob: PropTypes.func.isRequired,
    deleteJob: PropTypes.func.isRequired,
    createDestination: PropTypes.func.isRequired,
    updateDestination: PropTypes.func.isRequired,
    deleteDestination: PropTypes.func.isRequired,
    createTask: PropTypes.func.isRequired,
    updateTask: PropTypes.func.isRequired,
    deleteTask: PropTypes.func.isRequired,
    reorderDestinations: PropTypes.func.isRequired,
    landmarks: PropTypes.array,
    newDestinationLoading: PropTypes.bool,
    destinationsTableLoading: PropTypes.bool,
    createJobLoading: PropTypes.bool,
    deleteJobLoading: PropTypes.bool,
    updateJobLoading: PropTypes.bool,
    quickCreateLoading: PropTypes.bool,
  }

  static defaultProps = {
    selectedJob: null,
    openMessenger: null,
    landmarks: [],
    newDestinationLoading: false,
    destinationsTableLoading: false,
    createJobLoading: false,
    deleteJobLoading: false,
    updateJobLoading: false,
    quickCreateLoading: false,
  }

  renderContent = () => {
    const {
      wMatrix, appHeight, statusData, selectedJob, nav, devices, openMessenger, navDashboard,
      createJob, updateJob, deleteJob,
      createDestination, updateDestination, deleteDestination,
      createTask, updateTask, deleteTask,
      reorderDestinations, landmarks,
      newDestinationLoading, destinationsTableLoading, createJobLoading, deleteJobLoading,
      updateJobLoading,
    } = this.props

    switch (nav) {
      case 'details':
        return (
          <LogisticsJob
            nav={nav}
            wMatrix={wMatrix}
            job={selectedJob}
            devices={devices}
            navDashboard={navDashboard}
            openMessenger={openMessenger}
            createJob={createJob}
            updateJob={updateJob}
            deleteJob={deleteJob}
            createDestination={createDestination}
            updateDestination={updateDestination}
            deleteDestination={deleteDestination}
            createTask={createTask}
            updateTask={updateTask}
            deleteTask={deleteTask}
            reorderDestinations={reorderDestinations}
            landmarks={landmarks}
            newDestinationLoading={newDestinationLoading}
            createJobLoading={createJobLoading}
            deleteJobLoading={deleteJobLoading}
            updateJobLoading={updateJobLoading}
            destinationsTableLoading={destinationsTableLoading}
          />
        )
      default:
        return (
          <LogisticsDashboard
            wMatrix={wMatrix}
            appHeight={appHeight}
            statusData={statusData}
          />
        )
    }
  }

  render() {
    const {
      wMatrix, menuItems, onGroupChange, filterMenu, handleSelectJob, navCreate, selectedJob, nav,
      navDashboard, handleQuickCreate, quickCreateLoading,
    } = this.props
    return (
      <div style={styles.contentWrapper}>
        <div style={styles.menu}>
          <LogisticsMenu
            wMatrix={wMatrix}
            menuItems={menuItems}
            onGroupChange={onGroupChange}
            filterMenu={filterMenu}
            handleSelectJob={handleSelectJob}
            navCreate={navCreate}
            selectedKey={selectedJob ? selectedJob.id.toString() : undefined}
          />
        </div>
        <div style={styles.rightWrapper}>
          {nav !== 'dashboard' && nav !== 'create' ? (
            <div style={{
              display: 'flex', width: '100%', flexGrow: 0, flexShrink: 0,
            }}
            >
              <FullScreenNav
                wMatrix={wMatrix}
                nav={nav}
                handleDashboard={navDashboard}
                handleShowCreate={selectedJob ? handleQuickCreate : null}
                loading={quickCreateLoading}
              />
            </div>
          ) : null}
          <div style={{ flexGrow: 1 }}>
            {this.renderContent()}
          </div>
        </div>
      </div>
    )
  }
}

export default LogisticsHOC()(LogisticsView)
