import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MapMockup from '@assets/img/mock_ui/Map.svg'
import GreenMarker from '@assets/img/icons/marker-green.svg'
import YellowMarker from '@assets/img/icons/marker-yellow.svg'
import RedMarker from '@assets/img/icons/marker-red.svg'
import GreenArrow from '@assets/img/icons/location-arrow-green.svg'
import YellowArrow from '@assets/img/icons/location-arrow-yellow.svg'
import RedArrow from '@assets/img/icons/location-arrow-red.svg'


const styles = {
  mainContainer: {
    width: 550,
    height: 440,
    backgroundColor: '#D8D8D8',
    borderRadius: 5,
    position: 'relative',
    overflow: 'hidden',
    margin: 10,
  },
  contentContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    top: 30,
    left: 15,
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 225,
    height: 405,
    marginRight: 5,
    backgroundColor: '#F8F8F8',
    overflow: 'hidden',
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: 350,
    height: 405,
  },
  mapContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 5,
    backgroundColor: '#F8F8F8',
    position: 'relative',
  },
  deviceDetailsBox: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginBottom: 5,
    backgroundColor: '#F8F8F8',
  },
  densityButton: {
    width: 20,
    height: 20,
    margin: 5,
    backgroundColor: '#D8D8D8',
  },
  listHeaderContainer: {
    alignSelf: 'stretch',
    padding: 5,
    display: 'flex',
    flexDirection: 'column',
  },
  listTitle: {
    width: 60,
    height: 20,
    margin: 5,
    marginBottom: 20,
    backgroundColor: '#D8D8D8',
  },
  filterBar: {
    alignSelf: 'stretch',
    height: 20,
    margin: 5,
    backgroundColor: '#D8D8D8',
  },
  labelBase: {
    height: 12,
    borderRadius: 6,
    margin: 2,
  },
  mockupDevice: {
    height: 45,
    flex: 1,
    margin: 2,
    backgroundColor: '#D8D8D8',
  },
  largeAddressContainer: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    height: 45,
  },
  statusContainer: {
    flex: 1,
    margin: 2,
    display: 'flex',
    justifyContent: 'center',
  },
  markerLabel: {
    width: '150%',
    height: 26,
    borderRadius: 13,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 2,
  },
}

const COLORS = {
  green: '#40CB0B',
  yellow: '#CCCC33',
  red: '#FF3333',
}

export default class DensityMockup extends Component {
  static propTypes = {
    listMode: PropTypes.string,
    iconMode: PropTypes.string,
    iconSize: PropTypes.string,
  }

  static defaultProps = {
    listMode: 'comfortable',
    iconMode: 'comfortable',
    iconSize: 'large',
  }

  /**
   * Returns mockup header for device list
   */
  returnHeaderMockup = () => (
    <div style={styles.listHeaderContainer}>
      <div style={styles.listTitle} />
      <div style={styles.filterBar} />
      <div style={styles.filterBar} />
      <div style={{ display: 'flex' }}>
        <div style={styles.densityButton} />
        <div style={styles.densityButton} />
        <div style={styles.densityButton} />
      </div>
    </div>
  )

  /**
   * Returns the icon source to be used in <img> src.
   * @param {String} color Color String used for marker. Can be 'green', 'yellow', or 'red'
   */
  returnMarkerIconSrc = (color) => {
    const { iconMode } = this.props
    if (['comfortable', 'compact'].includes(iconMode)) {
      switch (color) {
        case 'yellow':
          return YellowMarker
        case 'red':
          return RedMarker
        default:
          return GreenMarker
      }
    } else {
      // cozy
      switch (color) {
        case 'yellow':
          return YellowArrow
        case 'red':
          return RedArrow
        default:
          return GreenArrow
      }
    }
  }

  /**
   * Returns mockup map marker label
   * @param {String} color Color String used for marker. Can be 'green', 'yellow', or 'red'
   */
  returnMarkerLabel = (color) => {
    const { iconMode } = this.props
    if (iconMode !== 'compact') {
      return (
        <div
          style={{
            ...styles.markerLabel,
            border: `1px solid ${COLORS[color]}`,
          }}
        >
          <div style={{ width: '80%', height: 10, backgroundColor: '#D8D8D8' }} />
        </div>
      )
    }
    return null
  }

  /**
   * Returns mockup map marker
   * @param {String} color Color String used for marker. Can be 'green', 'yellow', or 'red'
   */
  returnMarker = (color) => {
    const { iconMode, iconSize } = this.props
    let width = 60
    switch (iconSize) {
      case 'medium':
        width *= 0.5
        break
      case 'small':
        width *= 0.25
        break
      default:
        width *= 1
        break
    }

    return (
      <div style={{
        display: 'flex', flexDirection: 'column', width, alignItems: 'center', transform: 'translate(-50%, -100%)',
      }}
      >
        {this.returnMarkerLabel(color)}
        <img
          src={this.returnMarkerIconSrc(color)}
          alt="marker"
          style={{
            width: '100%',
            transform: iconMode === 'cozy' ? 'rotate(45deg)' : undefined,
          }}
        />
      </div>
    )
  }

  /**
   * Returns a flexbox of labels for both 'compact' or 'cozy' mockups based on the given array
   * @param {[String]} labels Array of labels to use (['r', 'g', 'b'])
   */
  returnLabels = (labels) => {
    const { listMode } = this.props
    if (['comfortable', 'compact'].includes(listMode)) {
      return (
        <div
          style={{
            display: 'flex', margin: 2, flexWrap: 1,
          }}
        >
          {labels.includes('r') ? (
            <div
              style={{
                ...styles.labelBase,
                width: listMode === 'compact' ? 12 : 65,
                backgroundColor: listMode === 'compact' ? 'red' : undefined,
                border: listMode === 'compact' ? undefined : '1px solid red',
              }}
            />
          ) : null}
          {labels.includes('g') ? (
            <div
              style={{
                ...styles.labelBase,
                width: listMode === 'compact' ? 12 : 35,
                backgroundColor: listMode === 'compact' ? 'green' : undefined,
                border: listMode === 'compact' ? undefined : '1px solid green',
              }}
            />
          ) : null}
          {labels.includes('b') ? (
            <div
              style={{
                ...styles.labelBase,
                width: listMode === 'compact' ? 12 : 40,
                backgroundColor: listMode === 'compact' ? 'blue' : undefined,
                border: listMode === 'compact' ? undefined : '1px solid blue',
              }}
            />
          ) : null}
        </div>
      )
    }
    return null
  }

  /**
   * Returns content for a 'comfortable' or 'cozy' mockup card
   * @param {[String]} labels Array of labels to use (['r', 'g', 'b'])
   */
  returnLargeCardContent = (labels) => {
    const { listMode } = this.props
    return (
      <>
        <div style={{ display: 'flex' }}>
          <div style={styles.mockupDevice} />
          <div style={styles.largeAddressContainer}>
            <div style={{ flex: 1, margin: 3, backgroundColor: '#D8D8D8' }} />
            <div style={{ flex: 1, margin: 3, backgroundColor: '#D8D8D8' }} />
          </div>
          <div style={styles.statusContainer}>
            <div style={{ width: 20, height: 20, backgroundColor: '#D8D8D8' }} />
          </div>
        </div>
        {listMode === 'comfortable' ? this.returnLabels(labels) : null}
      </>
    )
  }

  /**
   * Returns content for a 'compact' mockup card
   * @param {[String]} labels Array of labels to use (['r', 'g', 'b'])
   * @returns {Element}
   */
  returnSmallCardContent = labels => (
    <div style={{ display: 'flex', height: 22 }}>
      <div style={{ width: 106, margin: 3, backgroundColor: '#D8D8D8' }} />
      {this.returnLabels(labels)}
    </div>
  )

  /**
   * Returns a mockup card
   * @param {Number} key Key used for div key in list
   * @param {[String]} labels Array of labels to use (['r', 'g', 'b'])
   */
  returnCard = (key, labels) => {
    const { listMode } = this.props
    return (
      <div
        key={`card-${key}`}
        style={{
          alignSelf: 'stretch', margin: 5, border: '2px solid #D8D8D8', display: 'flex', flexDirection: 'column', alignItems: 'stretch', padding: 5, flexShrink: 0,
        }}
      >
        {listMode === 'compact' ? this.returnSmallCardContent(labels) : this.returnLargeCardContent(labels)}
      </div>
    )
  }

  render() {
    // create array of card objects
    const cardList = []
    const labelLists = [
      ['r', 'g', 'b'],
      ['g', 'b'],
      ['r', 'b'],
      ['r', 'g'],
    ]
    for (let i = 0; i < 10; i += 1) {
      const labels = labelLists[i] || labelLists[i % 4]
      cardList.push(this.returnCard(i, labels))
    }

    return (
      <div style={styles.mainContainer}>
        <div style={styles.contentContainer}>
          <div style={styles.leftContainer}>
            {this.returnHeaderMockup()}
            {cardList}
          </div>
          <div style={styles.rightContainer}>
            <div style={styles.deviceDetailsBox} />
            <div style={styles.mapContainer}>
              <div style={{ position: 'absolute', top: 140, left: 60 }}>
                {this.returnMarker('green')}
              </div>
              <div style={{ position: 'absolute', top: 280, left: 250 }}>
                {this.returnMarker('yellow')}
              </div>
              <div style={{ position: 'absolute', top: 287, left: 80 }}>
                {this.returnMarker('red')}
              </div>
              <img src={MapMockup} alt="map" style={{ height: '100%', width: '100%' }} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
