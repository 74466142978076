import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'antd'
import ServiceHOC from '@hoc/service.hoc'
import ServiceNav from '@page/main/molecules/fullScreenNav'
import ServiceMenu from '@mol/service/menu'
import ServiceCreate from '@mol/service/create'
import ServiceDetails from '@mol/service/details'
import ServiceDashboard from '@mol/service/dashboard'

const styles = {
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
  },
  rightWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    margin: '0',
    overflow: 'auto',
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '240px',
  },
  blankPage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

class Service extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    appHeight: PropTypes.number.isRequired,
    showAdminEdit: PropTypes.bool.isRequired,
    showCreateButton: PropTypes.bool.isRequired,
    onGroupChange: PropTypes.func.isRequired,
    handleSelectTicket: PropTypes.func.isRequired,
    handleClearSelectedTicket: PropTypes.func.isRequired,
    handleCreate: PropTypes.func.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    filterMenu: PropTypes.func.isRequired,
    types: PropTypes.array.isRequired,
    valueTypes: PropTypes.array.isRequired,
    statusData: PropTypes.object.isRequired,
    timeOptions: PropTypes.array.isRequired,
    pieData: PropTypes.array.isRequired,
    onPieTopChange: PropTypes.func.isRequired,
    onPieTimeChange: PropTypes.func.isRequired,
    pieColorsArray: PropTypes.array.isRequired,
    barData: PropTypes.object.isRequired,
    onBarTopChange: PropTypes.func.isRequired,
    onBarTimeChange: PropTypes.func.isRequired,
    barColorsArray: PropTypes.array.isRequired,
    selectedTicket: PropTypes.object,
    devices: PropTypes.array,
    menuItems: PropTypes.array,
    historyTickets: PropTypes.array,
    relatedTickets: PropTypes.array,
    getMeasurement: PropTypes.func.isRequired,
  }

  static defaultProps = {
    menuItems: [
      { title: 'Menu Item', id: 'g0', items: { title: 'Sub Item', id: 'id-1' } },
    ],
    devices: [],
    selectedTicket: null,
    historyTickets: [],
    relatedTickets: [],
  }

  /**
   * Nav:
   *  - create
   *  - dashboard
   *  - details
   */
  state = {
    nav: 'dashboard',
  }

  handleShowDashboard = () => this.setState({ nav: 'dashboard' })

  handleCancel = () => {
    const { selectedTicket } = this.props
    if (selectedTicket === null) this.handleShowDashboard()
    else this.setState({ nav: 'details' })
  }

  handleShowCreate = () => {
    const { handleClearSelectedTicket } = this.props
    handleClearSelectedTicket()
    this.setState({ nav: 'create' })
  }

  handleShowQuickCreate = () => this.setState({ nav: 'create' })

  handleSelect = (e) => {
    const { handleSelectTicket } = this.props
    handleSelectTicket(e)
    this.setState({ nav: 'details' })
  }

  renderContent = () => {
    const {
      wMatrix, showAdminEdit, devices, types, valueTypes, selectedTicket, handleCreate, handleEdit,
      handleDelete, historyTickets, relatedTickets, statusData, timeOptions, onPieTimeChange,
      pieData, barData, onPieTopChange, pieColorsArray, onBarTopChange, onBarTimeChange,
      barColorsArray, appHeight, getMeasurement,
    } = this.props
    const { nav } = this.state

    if (nav === 'details' || !showAdminEdit) {
      return (
        <ServiceDetails
          wMatrix={wMatrix}
          nav={nav}
          selectedTicket={selectedTicket}
          historyTickets={historyTickets}
          relatedTickets={relatedTickets}
          handleSelectTicket={this.handleSelect}
          types={types}
          valueTypes={valueTypes}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleDashboard={this.handleShowDashboard}
          showAdminEdit={showAdminEdit}
          getMeasurement={getMeasurement}
        />
      )
    }

    if (nav === 'create') {
      return (
        <ServiceCreate
          wMatrix={wMatrix}
          nav={nav}
          devices={devices}
          types={types}
          valueTypes={valueTypes}
          handleCreate={handleCreate}
          handleCancel={this.handleCancel}
          handleShowDashboard={this.handleShowDashboard}
          initialValues={selectedTicket}
          getMeasurement={getMeasurement}
        />
      )
    }
    return (
      <ServiceDashboard
        appHeight={appHeight}
        wMatrix={wMatrix}
        statusData={statusData}
        timeOptions={timeOptions}
        pieData={pieData}
        onPieTopChange={onPieTopChange}
        onPieTimeChange={onPieTimeChange}
        pieColorsArray={pieColorsArray}
        barData={barData}
        onBarTopChange={onBarTopChange}
        onBarTimeChange={onBarTimeChange}
        barColorsArray={barColorsArray}
      />
    )
  }

  render() {
    const {
      wMatrix, menuItems, filterMenu, selectedTicket, onGroupChange, showCreateButton,
      showAdminEdit,
    } = this.props
    const { nav } = this.state
    return (
      <div style={styles.contentWrapper}>
        <div style={styles.menu}>
          <ServiceMenu
            selectedKey={selectedTicket ? selectedTicket.id.toString() : undefined}
            wMatrix={wMatrix}
            menuItems={menuItems}
            selectItem={this.handleSelect}
            filterMenu={filterMenu}
            handleShowCreate={showCreateButton ? this.handleShowCreate : null}
            onGroupChange={onGroupChange}
          />
        </div>
        <div style={styles.rightWrapper}>
          {nav !== 'dashboard' && nav !== 'create' ? (
            <div style={{
              display: 'flex', width: '100%', flexGrow: 0, flexShrink: 0,
            }}
            >
              <ServiceNav
                wMatrix={wMatrix}
                nav={nav}
                handleDashboard={this.handleShowDashboard}
                handleShowCreate={this.handleShowQuickCreate}
              />
            </div>
          ) : null}
          {selectedTicket === null && !showAdminEdit ? (
            <div style={{ height: '100%', width: '100%' }}>
              <Card style={{ height: '100%', width: '100%' }}>
                <div style={styles.blankPage}>
                  <h4 style={{ margin: '350px 0 0 0' }}>
                    {wMatrix('selectAServiceTicket')}
                  </h4>
                </div>
              </Card>
            </div>
          ) : (
            this.renderContent()
          )}
        </div>
      </div>
    )
  }
}

export default ServiceHOC()(Service)
