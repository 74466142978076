import React, { Component } from 'react'
import PropTypes from 'prop-types'
import greenCarKey from '@assets/img/mapIcons/CarKey/Green.svg'
import redCarKey from '@assets/img/mapIcons/CarKey/Red.svg'
import landmarkIcon from '@assets/img/mapIcons/Landmark.svg'
import exceptionIcon from '@assets/img/mapIcons/Exception.svg'
import Speedometer from '@assets/img/mapIcons/Speedometer.svg'
import locateIcon from '@assets/img/mapIcons/Locate.svg'
import pedal from '@assets/img/mapIcons/Pedal.svg'
import turn from '@assets/img/mapIcons/Harsh Turn.svg'
import movementConclude from '@assets/img/mapIcons/Movement Concluded.svg'
import movementInitiate from '@assets/img/mapIcons/Movement Initiated.svg'
import stop from '@assets/img/mapIcons/Stop.svg'
import tripStart from '@assets/img/mapIcons/Trip Start.svg'
import tripEnd from '@assets/img/mapIcons/Trip End.svg'
import afterHours from '@assets/img/mapIcons/AfterHours.svg'
import disconnect from '@assets/img/mapIcons/Disconnect.svg'
import reconnect from '@assets/img/mapIcons/Reconnect.svg'
import idle from '@assets/img/mapIcons/Idle.svg'
import panicButton from '@assets/img/mapIcons/Panic Button.svg'
import ptoOn from '@assets/img/mapIcons/PTO On.svg'
import ptoOff from '@assets/img/mapIcons/PTO Off.svg'
import locateArrow from '@assets/img/mapIcons/locateArrow.svg'
import locateDot from '@assets/img/mapIcons/dot.png'
import fuelCardIcon from '@assets/img/mapIcons/FuelcardEvent.svg'

/**
 * This is a simple component that returns the necessary event icon based on the event type
 * Note that the event type is in the redshift notation (ie: ignition_on, idle_begin, etc)
 */
export default class EventIcon extends Component {
  static propTypes = {
    event: PropTypes.string.isRequired,
    heading: PropTypes.number,
    speed: PropTypes.number,
    imgStyle: PropTypes.object,
  }

  static defaultProps = {
    heading: null,
    speed: 0,
    imgStyle: { margin: 10 },
  }

  getEventIcon = () => {
    const { event, heading, speed } = this.props
    switch (event) {
      case 'locate':
        if (heading !== null && speed) return locateArrow
        return locateIcon
      case 'locateArrow':
        return locateArrow
      case 'locateDot':
        return locateDot
      case 'ignition_off':
        return redCarKey
      case 'ignition_on':
        return greenCarKey
      case 'speeding':
      case 'mosl':
        return Speedometer
      case 'landmark_enter':
      case 'landmark_exit':
      case 'geofence_enter':
      case 'geofence_exit':
        return landmarkIcon
      case 'fast_start':
        return pedal
      case 'hard_brake':
        return pedal
      case 'harsh_turn':
        return turn
      case 'stop':
        return movementConclude
      case 'stop_begin':
      case 'stop_end':
        return stop
      case 'start':
      case 'movement_initiated':
        return movementInitiate
      case 'trip_end':
        return tripEnd
      case 'trip_begin':
        return tripStart
      case 'after_hours':
        return afterHours
      case 'disconnect':
        return disconnect
      case 'reconnect':
        return reconnect
      case 'idling':
      case 'idle_begin':
      case 'idle_end':
      case 'idle':
        return idle
      case 'panic_Button':
        return panicButton
      case 'pto_primary_on':
      case 'pto_secondary_on':
      case 'pto_tertiary_on':
        return ptoOn
      case 'pto_primary_off':
      case 'pto_secondary_off':
      case 'pto_tertiary_off':
        return ptoOff
      case 'fuelcard':
        return fuelCardIcon
      default:
        return exceptionIcon
    }
  }

  render() {
    const { heading, event, imgStyle } = this.props
    return (
      <img
        src={this.getEventIcon()}
        alt="event"
        style={{
          transform: heading >= 0 && event === 'locate' ? `rotate(${heading - 90}deg)` : undefined,
          height: heading >= 0 && event === 'locate' ? 24 : undefined,
          ...imgStyle,
        }}
      />
    )
  }
}
