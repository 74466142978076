import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DensityToggle from '@atom/densityToggleIcons'
import helper from '@helper'

const styles = {
  container: {
    marginVertical: 8,
    marginLeft: 12,
    marginRight: 12,
  },
  title: {
    lineHeight: '90px',
    fontSize: '20px',
    color: '#595959',
  },
  formContainer: {
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}

class Header extends Component {
  static propTypes = {
    selectModal: PropTypes.func.isRequired,
    wMatrix: PropTypes.func.isRequired,
    toTitleCase: PropTypes.func.isRequired,
    mainNav: PropTypes.string,
    deviceListMode: PropTypes.string,
    mapIconMode: PropTypes.string,
    mapIconSizeMode: PropTypes.string,
    saveContactMeta: PropTypes.func.isRequired,
    renderHeaderDetails: PropTypes.func,
  }

  static defaultProps = {
    mainNav: 'devices',
    deviceListMode: 'comfortable',
    mapIconMode: 'comfortable',
    mapIconSizeMode: 'large',
    renderHeaderDetails: () => {},
  }

  returnTitle = () => {
    const { wMatrix, toTitleCase, mainNav } = this.props

    return (
      <span style={styles.title}>{wMatrix(toTitleCase(mainNav))}</span>
    )
  }

  returnViewToggles = () => {
    const {
      deviceListMode, selectModal, saveContactMeta,
      mapIconMode, mapIconSizeMode,
    } = this.props
    return (
      <div>
        <DensityToggle
          deviceListMode={deviceListMode}
          mapIconMode={mapIconMode}
          mapIconSizeMode={mapIconSizeMode}
          saveContactMeta={saveContactMeta}
          elipseClick={() => { selectModal('density') }}
        />
      </div>
    )
  }

  render() {
    const { renderHeaderDetails } = this.props

    return (
      <div style={styles.container}>
        {this.returnTitle()}
        {renderHeaderDetails()}
        {this.returnViewToggles()}
      </div>
    )
  }
}

export default helper()(Header)
