import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class FaIcon extends React.Component {
  static propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.number,
  }

  static defaultProps = {
    color: '',
    fontSize: 12,
  }

  getFaClass = () => {
    const { color } = this.props
    if (!color) {
      return ''
    }
    switch (color) {
      case 'red':
        return 'rft-red'
      case 'green':
        return 'rft-green'
      case 'yellow':
        return 'rft-yellow'
      case 'blue':
        return 'rft-blue'
      case 'dark-blue':
        return 'rft-dark-blue'
      case 'grey':
        return 'rft-grey'
      case 'orange':
        return 'rft-orange'
      case 'white':
        return 'rft-white'
      default:
        return 'rft-black'
    }
  }

  render() {
    const { fontSize } = this.props
    return (
      <FontAwesomeIcon
        {...this.props}
        className={this.getFaClass()}
        style={{ fontSize }}
      />
    )
  }
}

export default FaIcon
