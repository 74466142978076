import React from 'react'
import PropTypes from 'prop-types'
import Card from '@atom/card'
import Table from '@atom/service/deviceListTable'
import CreateForm from '@atom/service/form'

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    flexGrow: 1,
  },
  // this is the Card default wrapper with overflow auto added
  cardContentWrapper: {
    flexGrow: 1,
    margin: '0px 8px 8px 8px',
    overflow: 'auto',
  },
  createWrapper: {
    flexGrow: 1,
    flexShrink: 0,
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'row',
  },
  leftColumn: {
    flex: 2,
    flexGrow: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    borderRight: 'solid 6px #E6E6E6',
  },
  rightColumn: {
    flex: 1,
    paddingTop: '2em',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
  },
  table: {
    paddingTop: '2em',
    display: 'flex',
    flexGrow: 1,
    flexShrink: 0,
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    width: '100%',
  },
}

export default class ServiceCreate extends React.Component {
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ selectedDevices: selectedRows })
    },
  }

  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    nav: PropTypes.string.isRequired,
    devices: PropTypes.array.isRequired,
    types: PropTypes.array.isRequired,
    valueTypes: PropTypes.array.isRequired,
    handleCreate: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleShowDashboard: PropTypes.func,
    initialValues: PropTypes.object,
    getMeasurement: PropTypes.func.isRequired,
  }

  static defaultProps = {
    initialValues: null,
    handleShowDashboard: null,
  }

  state = {
    selectedDevices: [],
  }

  renderDevices = () => {
    const { wMatrix, devices } = this.props
    const shapedDevices = JSON.parse(JSON.stringify(devices))
    const tagMap = new Map()
    for (let i = 0; i < devices.length; i += 1) {
      for (let j = 0; j < devices[i].groups.length; j += 1) {
        const g = shapedDevices[i].groups[j]
        Object.assign(g, { personal: false })
        if (!tagMap.has(g.id)) {
          tagMap.set(g.id, {
            id: g.id,
            name: g.name,
            color: g.color,
            ynGroup: 1,
          })
        }
      }
      for (let k = 0; k < devices[i].labels.length; k += 1) {
        const l = shapedDevices[i].labels[k]
        Object.assign(l, { personal: true })
        if (!tagMap.has(l.id)) {
          tagMap.set(l.id, {
            id: l.id,
            name: l.name,
            color: l.color,
            ynGroup: 0,
          })
        }
      }
      Object.assign(shapedDevices[i], {
        tags: shapedDevices[i].groups.concat(shapedDevices[i].labels),
      })
    }

    const tagArray = []
    for (const value of tagMap.values()) {
      tagArray.push(value)
    }

    return (
      <div style={styles.table}>
        <Table
          wMatrix={wMatrix}
          devices={shapedDevices}
          tags={tagArray}
          rowSelection={this.rowSelection}
        />
      </div>
    )
  }

  renderCreateForm = () => {
    const {
      wMatrix, nav, types, valueTypes, handleCreate, handleCancel, handleShowDashboard,
      initialValues, getMeasurement,
    } = this.props
    const { selectedDevices } = this.state
    return (
      <CreateForm
        wMatrix={wMatrix}
        nav={nav}
        selectedDevices={selectedDevices}
        types={types}
        valueTypes={valueTypes}
        handleCreate={handleCreate}
        handleCancel={handleCancel}
        handleShowDashboard={handleShowDashboard}
        existingTicket={initialValues}
        getMeasurement={getMeasurement}
      />
    )
  }

  render() {
    const { wMatrix } = this.props
    return (
      <Card
        title={wMatrix('createService')}
        titleSize="large"
        contentWrapper={styles.cardContentWrapper}
      >
        <div style={styles.createWrapper}>
          <div style={styles.leftColumn}>
            {this.renderDevices()}
          </div>
          <div style={styles.rightColumn}>
            {this.renderCreateForm()}
          </div>
        </div>
      </Card>
    )
  }
}
