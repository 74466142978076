/* eslint-disable react/no-did-update-set-state */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react'
import {
  Checkbox, Collapse, DatePicker, Divider, InputNumber,
  TimePicker, Form, Button, Input,
} from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'
import ScheduledReport from '@org/modal/scheduledReport'
import helper from '@helper'
import Analytics from '@analytics'
import CustomSelect from '../../atoms/select'

const { Panel } = Collapse
const { RangePicker } = DatePicker
const { Item } = Form

const styles = {
  formItem: {
    width: '33%',
    minWidth: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'no-wrap',
  },
  formLabel: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '35%',
    minWidth: '100px',
    margin: '0px 15px 0px 0px',
  },
  h4: {
    marginBottom: '0px',
  },
  inputs: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'stretch',
    width: '100%',
    justifyContent: 'flex-start',
  },
  vehicleInputLabels: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '35%',
    margin: '0 15px 0 0',
  },
  vehicleInputItems: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '50%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0',
    flexWrap: 'wrap',
  },
  p: {
    width: '10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  saveButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: '10px',
  },
}

class ReportFilterContainer extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    reportInputs: PropTypes.array,
    reportType: PropTypes.string,
    reportId: PropTypes.number,
    reportCategory: PropTypes.string,
    devices: PropTypes.array,
    landmarks: PropTypes.array,
    events: PropTypes.array,
    refetchMetaAndSchedule: PropTypes.func.isRequired,
    form: PropTypes.object,
    wMatrix: PropTypes.func.isRequired,
    drivers: PropTypes.array.isRequired,
    labels: PropTypes.array.isRequired,
    groups: PropTypes.array.isRequired,
    serviceTypes: PropTypes.array.isRequired,
    serviceTicketStatus: PropTypes.array.isRequired,
    handleGenerateData: PropTypes.func.isRequired,
    scheduledReports: PropTypes.array.isRequired,
    freqOptions: PropTypes.array,
    dateRangeOptions: PropTypes.array,
    scheduleDateRange: PropTypes.object,
    toggleReportDownloadModal: PropTypes.func.isRequired,
    setReportDownloadMeta: PropTypes.func.isRequired,
    showDownloadButton: PropTypes.bool,
    /** @Main */
    mainNav: PropTypes.string,
  }

  static defaultProps = {
    devices: [],
    landmarks: [],
    form: null,
    events: [],
    reportInputs: [],
    reportType: '',
    reportId: 0,
    reportCategory: '',
    freqOptions: [],
    dateRangeOptions: [],
    scheduleDateRange: null,
    showDownloadButton: false,
    mainNav: 'reports',
  }

  state = {
    showModal: false,
    allDevices: true,
    driversOnly: false,
  }

  componentDidMount() {
    const { reportInputs } = this.props
    let deviceData = null
    let groupData = null
    let labelData = null
    let driversOnly = false
    if (reportInputs) {
      for (let i = 0; i < reportInputs.length; i += 1) {
        if (reportInputs[i].data && reportInputs[i].data.length > 0) {
          if (reportInputs[i].key === 'deviceIds') deviceData = reportInputs[i].data
          if (reportInputs[i].key === 'groupIds') groupData = reportInputs[i].data
          if (reportInputs[i].key === 'labelIds') labelData = reportInputs[i].data
        }
        if (reportInputs[i].key === 'driversOnly') driversOnly = true
      }
      if (deviceData || groupData || labelData) {
        this.setState({ allDevices: false, driversOnly })
      } else {
        this.setState({ allDevices: true, driversOnly })
      }
    }
  }

  componentDidUpdate = (prevProps) => {
    const { title, form, reportInputs } = this.props
    let deviceData = null
    let groupData = null
    let labelData = null
    let driversOnly = false
    if (prevProps.title !== title) {
      form.resetFields()
      if (reportInputs) {
        for (let i = 0; i < reportInputs.length; i += 1) {
          if (reportInputs[i].data && reportInputs[i].data.length > 0) {
            if (reportInputs[i].key === 'deviceIds') deviceData = reportInputs[i].data
            if (reportInputs[i].key === 'groupIds') groupData = reportInputs[i].data
            if (reportInputs[i].key === 'labelIds') labelData = reportInputs[i].data
          }
          if (reportInputs[i].key === 'driversOnly') driversOnly = true
        }
        if (deviceData || groupData || labelData) {
          this.setState({ allDevices: false, driversOnly })
        } else {
          this.setState({ allDevices: true, driversOnly })
        }
      }
    }
  }

  handleShowModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  generateFormItem = (reportInput, form, width) => {
    const { wMatrix } = this.props
    if (['deviceIds', 'groupIds', 'labelIds', 'driversOnly'].includes(reportInput.key)) return null
    return (
      <div
        key={`${reportInput.key}`}
        style={{ ...styles.formItem, width }}
      >
        <div style={styles.formLabel}>
          <h4 style={styles.h4}>
            {wMatrix(reportInput.title)}
            {':'}
          </h4>
        </div>
        <div style={{ flexGrow: '1', alignSelf: 'center' }}>
          {this.generateInput(reportInput, form)}
        </div>
      </div>
    )
  }

  generateScheduledFormItem = (reportInput, form, width) => {
    const { wMatrix } = this.props

    return (
      <div
        key={`${reportInput.key}`}
        style={{ ...styles.formItem, width }}
      >
        <div style={styles.formLabel}>
          <h4 style={styles.h4}>
            {wMatrix(reportInput.title)}
            {':'}
          </h4>
        </div>
        <div style={{ flexGrow: '1', alignSelf: 'center' }}>
          {this.generateInput(reportInput, form)}
        </div>
      </div>
    )
  }

  /**
   * Custom Filtering for dropdowns. Finds base child and accesses the desired text to
   * filter on.
   */
  filterOption = (inputValue, option, inputType) => {
    // Isolate the deepest child to access the text we want
    let { props } = option
    while (props.children && props.children.props) {
      // cannot destructure in nested case
      // eslint-disable-next-line prefer-destructuring
      props = props.children.props
    }
    // Special case filtering
    if (inputType === 'groupIds' || inputType === 'labelIds') {
      if (props.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0) {
        return true
      } return false
    }
    // default filter
    if (props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0) {
      return true
    } return false
  }

  /**
   * Returns what the select box will shows
   */
  customMaxTagPlaceholder = (omittedValues, type) => {
    const { wMatrix } = this.props
    // omittedValues is an array of values
    if (type === 'devices') {
      return `${omittedValues.length} ${wMatrix('vehiclesSelected')}`
    }
    return `${omittedValues.length} ${wMatrix('selected')}`
  }

  generateInput = (reportInput, form) => {
    const { reportType } = this.props
    const { getFieldDecorator } = form
    const initialValue = reportInput.data || null
    if (reportInput.type === 'date') {
      /**
       * According to Bill, we need to keep all v2 service tickets since 2017,
       * change the service report default start date to be 2017-01-01
       *  */
      const defaultStartDate = reportType === 'service' ? moment('2017-01-01') : moment()
      return (
        <Item>
          {getFieldDecorator(`${reportInput.key}`, {
            initialValue: reportInput.key.includes('start')
              ? defaultStartDate.set({
                hour: 0, minute: 0, second: 0, millisecond: 0,
              })
              : moment().set({
                hour: 23, minute: 59, second: 59, millisecond: 0,
              }),
          })(
            <DatePicker
              style={{ width: '100%' }}
              allowClear={false}
              format="YYYY-MM-DD h:mm a"
              disabledDate={(dateValue) => {
                const today = moment().set({
                  hour: 23, minute: 59, second: 59, millisecond: 0,
                })
                if (!dateValue) {
                  return false
                }
                if (moment().diff(dateValue, 'years') > 0 && reportType !== 'service') {
                  return true
                }
                // Service report can have start date since 2017-01-01
                if (moment('2017-01-01').diff(dateValue, 'days') > 0 && reportType === 'service') {
                  return true
                }
                return dateValue.isAfter(today)
              }}
              showTime={{ format: 'h:mm a' }}
            />,
          )}
        </Item>
      )
    } if (reportInput.type === 'dateRange') {
      return (
        <Item>
          {getFieldDecorator(`${reportInput.key}`, {
            initialValue: [moment().subtract(1, 'days'), moment()],
          })(
            <RangePicker
              allowClear={false}
              style={{ width: '100%' }}
              disabledDate={(dateValue) => {
                const today = moment().set({
                  hour: 23, minute: 59, second: 59, millisecond: 0,
                })
                if (!dateValue) {
                  return false
                }
                if (moment().diff(dateValue, 'years') > 0) {
                  return true
                }
                return dateValue.isAfter(today)
              }}
            />,
          )}
        </Item>
      )
    } if (reportInput.type === 'datetime') {
      const defaultStartDate = reportType === 'service' ? moment('2017-01-01') : moment()
      return (
        <Item>
          {getFieldDecorator(`${reportInput.key}`, {
            initialValue: reportInput.key.includes('start')
              ? defaultStartDate.set({
                hour: 0, minute: 0, second: 0, millisecond: 0,
              })
              : moment().set({
                hour: 23, minute: 59, second: 59, millisecond: 0,
              }),
          })(
            <DatePicker
              style={{ width: '100%' }}
              allowClear={false}
              format="YYYY-MM-DD h:mm a"
              disabledDate={(dateValue) => {
                const today = moment().set({
                  hour: 23, minute: 59, second: 59, millisecond: 0,
                })
                if (!dateValue) {
                  return false
                }
                if (moment().diff(dateValue, 'years') > 0) {
                  return true
                }
                const start = moment(form.getFieldValue('startDateTime'))
                return reportInput.key.includes('start')
                  ? dateValue.isAfter(today)
                  : dateValue.isAfter(today) || dateValue.isBefore(start)
              }}
              showTime={{ format: 'h:mm a' }}
            />,
          )}
        </Item>
      )
    } if (reportInput.type === 'time') {
      let initialTime = reportInput.key.includes('start')
        ? moment().set({
          hour: 9, minute: 0, second: 0, millisecond: 0,
        })
        : moment().set({
          hour: 17, minute: 0, second: 0, millisecond: 0,
        })
      if (initialValue) initialTime = moment(initialValue, 'h:mm a')
      return (
        <Item>
          {getFieldDecorator(`${reportInput.key}`, {
            initialValue: initialTime,
          })(
            <TimePicker
              style={{ width: '100%' }}
              use12Hours
              allowClear={false}
              format="h:mm a"
              showTime={{ format: 'h:mm a' }}
            />,
          )}
        </Item>
      )
    } if (reportInput.type === 'number') {
      return (
        <Item>
          {getFieldDecorator(`${reportInput.key}`, { initialValue: reportInput.data || 50 })(
            <InputNumber style={{ width: '100%' }} />,
          )}
        </Item>
      )
    } if (reportInput.type === 'array') {
      let data = []
      const { key } = reportInput
      const {
        devices, landmarks, drivers, labels, groups, events, serviceTypes, serviceTicketStatus,
      } = this.props

      if (key === 'deviceIds') {
        data = devices
      }
      if (key === 'groupIds') {
        data = groups
      }
      if (key === 'labelIds') {
        data = labels
      }
      if (key === 'landmarkIds') {
        data = landmarks
      }
      if (key === 'driverIds') {
        data = drivers
      }
      if (key === 'events') {
        data = events.sort((a, b) => {
          if (a.displayName < b.displayName) return -1
          return 1
        })
      }
      if (key === 'serviceTypes') {
        data = serviceTypes
      }

      if (key === 'serviceStatus') {
        data = serviceTicketStatus
      }

      let ids = []
      if (initialValue) {
        ids = initialValue
      }
      return (
        <Item>
          {getFieldDecorator(key, {
            initialValue: ids,
          })(
            <CustomSelect
              form={form}
              type={key}
              data={data}
              onChange={() => null}
              defaultSelectAll={false}
              filterOption={this.filterOption}
            />,
          )}
        </Item>
      )
    }

    return null
  }

  handleChangeCheckbox = (event, form) => {
    this.setState({ [event.target.id]: event.target.checked })
    form.resetFields(['deviceIds', 'groupIds', 'labelIds'])
  }

  renderGroupsItem = () => {
    const {
      groups, wMatrix, form, reportInputs,
    } = this.props
    let initialValue = []
    if (reportInputs) {
      for (let i = 0; i < reportInputs.length; i += 1) {
        if (reportInputs[i].data
          && reportInputs[i].data.length > 0
          && reportInputs[i].key === 'groupIds'
        ) {
          initialValue = reportInputs[i].data
        }
      }
    }

    return (
      <div
        key="groupIds"
        style={{ ...styles.formItem, width: '50%' }}
      >
        <div style={styles.formLabel}>
          <h4 style={styles.h4}>
            {`${wMatrix('Groups')}: `}
          </h4>
        </div>
        <div style={{ flexGrow: '1', alignSelf: 'center' }}>
          <Item>
            {form.getFieldDecorator('groupIds', {
              initialValue,
            })(
              <CustomSelect
                form={form}
                type="groupIds"
                data={groups}
                defaultSelectAll={false}
                onChange={() => null}
                filterOption={this.filterOption}
              />,
            )}
          </Item>
        </div>
      </div>
    )
  }

  renderLabelsItem = () => {
    const {
      labels, wMatrix, form, reportInputs,
    } = this.props
    let initialValue = []
    if (reportInputs) {
      for (let i = 0; i < reportInputs.length; i += 1) {
        if (reportInputs[i].data
          && reportInputs[i].data.length > 0
          && reportInputs[i].key === 'labelIds'
        ) {
          initialValue = reportInputs[i].data
        }
      }
    }

    return (
      <div
        key="labelIds"
        style={{ ...styles.formItem, width: '50%' }}
      >
        <div style={styles.formLabel}>
          <h4 style={styles.h4}>
            {`${wMatrix('labels')}: `}
          </h4>
        </div>
        <div style={{ flexGrow: '1', alignSelf: 'center' }}>
          <Item>
            {form.getFieldDecorator('labelIds', {
              initialValue,
            })(
              <CustomSelect
                form={form}
                type="labelIds"
                data={labels}
                defaultSelectAll={false}
                onChange={() => null}
                filterOption={this.filterOption}
              />,
            )}
          </Item>
        </div>
      </div>
    )
  }

  renderDevicesItem = () => {
    const {
      devices, wMatrix, form, reportInputs,
    } = this.props
    let initialValue = []
    if (reportInputs) {
      for (let i = 0; i < reportInputs.length; i += 1) {
        if (reportInputs[i].data
          && reportInputs[i].data.length > 0
          && reportInputs[i].key === 'deviceIds'
        ) {
          initialValue = reportInputs[i].data
        }
      }
    }

    return (
      <div
        key="deviceIds"
        style={{ ...styles.formItem, width: '50%' }}
      >
        <div style={styles.formLabel}>
          <h4 style={styles.h4}>
            {`${wMatrix('Vehicles')}: `}
          </h4>
        </div>
        <div style={{ flexGrow: '1', alignSelf: 'center' }}>
          <Item>
            {form.getFieldDecorator('deviceIds', {
              initialValue,
            })(
              <CustomSelect
                form={form}
                type="deviceIds"
                data={devices}
                defaultSelectAll={false}
                onChange={() => null}
                filterOption={this.filterOption}
              />,
            )}
          </Item>
        </div>
      </div>
    )
  }

  checkEmptyFields = () => {
    const { form } = this.props
    const { allDevices } = this.state

    const fieldValues = form.getFieldsValue(['groupIds', 'labelIds', 'deviceIds'])
    const groupsTouched = fieldValues.groupIds && fieldValues.groupIds.length > 0
    const labelsTouched = fieldValues.labelIds && fieldValues.labelIds.length > 0
    const deviceIdsTouched = fieldValues.deviceIds && fieldValues.deviceIds.length > 0
    return !groupsTouched && !labelsTouched && !deviceIdsTouched && !allDevices
  }

  renderDeviceSelection = () => {
    const { form, wMatrix, reportType } = this.props
    const { allDevices, driversOnly } = this.state

    if (driversOnly) return null

    const fieldValues = form.getFieldsValue(['groupIds', 'labelIds', 'deviceIds'])
    const groupsTouched = fieldValues.groupIds && fieldValues.groupIds.length > 0
    const labelsTouched = fieldValues.labelIds && fieldValues.labelIds.length > 0
    const deviceIdsTouched = fieldValues.deviceIds && fieldValues.deviceIds.length > 0
    const labelText = reportType === 'fuelCardTransaction' ? `${wMatrix('all')} ${wMatrix('transactions')}: ` : `${wMatrix('all')} ${wMatrix('Vehicles')}: `
    return (
      <div style={{ display: 'flex', flexDirection: 'column', margin: '0 0 15px 0' }}>
        <div style={{ display: 'flex', width: '33%' }}>
          <div style={styles.vehicleInputLabels}>
            <h4 style={styles.h4}>{labelText}</h4>
          </div>
          <div style={styles.vehicleInputItems}>
            <Checkbox
              id="allDevices"
              checked={allDevices}
              onChange={e => this.handleChangeCheckbox(e, form)}
            />
          </div>
        </div>
        {allDevices || deviceIdsTouched ? <div /> : (
          <>
            <div style={styles.row}>
              <div style={{ width: '17.5%' }} />
              <div style={{ width: '35%', margin: '0 0 0 0px' }}><Divider /></div>
              <p style={styles.p}>OR</p>
              <div style={{ width: '35%', margin: '0 0px 0 0' }}><Divider /></div>
            </div>
            <div style={styles.row}>
              {this.renderGroupsItem()}
              {this.renderLabelsItem()}
            </div>
          </>
        )}
        {allDevices || groupsTouched || labelsTouched ? <div /> : (
          <>
            <div style={styles.row}>
              <div style={{ width: '17.5%' }} />
              <div style={{ width: '35%', margin: '0 0 0 0px' }}><Divider /></div>
              <p style={styles.p}>OR</p>
              <div style={{ width: '35%', margin: '0 0px 0 0' }}><Divider /></div>
            </div>
            <div style={styles.row}>
              {this.renderDevicesItem()}
            </div>
          </>
        )}
      </div>
    )
  }

  /**
   * handle submit button click
   * @todo add submit function from hoc
   */
  handleGenerateReport = (e) => {
    e.preventDefault()

    const {
      mainNav, form, handleGenerateData, reportCategory, scheduleDateRange, reportType,
    } = this.props

    form.validateFields((err, values) => {
      const finalValues = values
      if (!err) {
        /** if it is a schedule report, this is for sample report.
         * We need to inject the startDate and endDate
         * */
        if (reportCategory === 'scheduled') {
          finalValues.startDate = scheduleDateRange.sampleReportStartDate
          finalValues.endDate = scheduleDateRange.sampleReportEndDate
        }
        /** @analytics Record Report Generation Click */
        Analytics.record({
          feature: `${reportCategory}_${reportType}_report`,
          page: `${mainNav}`,
          event: 'generate',
        })
        handleGenerateData(finalValues)
      }
    })
  }

  handleDownload = (e) => {
    e.preventDefault()
    const {
      form, toggleReportDownloadModal, setReportDownloadMeta,
    } = this.props

    form.validateFields((err, values) => {
      const finalValues = values
      if (!err) {
        toggleReportDownloadModal(true)
        setReportDownloadMeta(finalValues)
      }
    })
  }

  renderScheduledModal = () => {
    const {
      reportInputs, reportType, reportId, scheduledReports, refetchMetaAndSchedule,
      freqOptions, dateRangeOptions,
    } = this.props

    const { showModal } = this.state
    let scheduledInputs = null
    for (let i = 0; i < scheduledReports.length; i += 1) {
      if (scheduledReports[i].reportId === reportId) {
        scheduledInputs = scheduledReports[i]
      }
    }

    if (showModal) {
      return (
        <ScheduledReport
          showModal={showModal}
          handleShowModal={this.handleShowModal}
          generateFormItem={this.generateScheduledFormItem}
          reportInputs={reportInputs}
          reportType={reportType}
          scheduledInputs={scheduledInputs}
          refetchMetaAndSchedule={refetchMetaAndSchedule}
          freqOptions={freqOptions}
          dateRangeOptions={dateRangeOptions}
        />
      )
    }
    return null
  }

  renderScheduledReportSchedule = () => {
    const { scheduleDateRange, wMatrix } = this.props
    if (scheduleDateRange) {
      return (
        <>
          <div style={{ flexBasis: '100%', height: 0 }} />

          <div style={{ ...styles.formItem, width: '33%' }}>
            <div style={styles.formLabel}>
              <h4 style={styles.h4}>
                {wMatrix('Date Range')}
                {':'}
              </h4>
            </div>
            <div style={{ flexGrow: '1', alignSelf: 'center' }}>
              <Item>
                <Input defaultValue={wMatrix(scheduleDateRange.rangeTitle)} disabled />
              </Item>
            </div>
          </div>
        </>
      )
    }
    return null
  }

  render() {
    const {
      wMatrix, reportInputs, form, reportCategory, reportType, showDownloadButton,
    } = this.props
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <Collapse
            defaultActiveKey={['FilterCollapse']}
            expandIconPosition="left"
            style={{ width: '100%', marginRight: '10px' }}
          >
            <Panel header={wMatrix('modifyReportFilters')} key="FilterCollapse">
              {wMatrix(`${reportType}Description`)}
              <Form className="reportInputForm" style={{ marginTop: 10 }} onSubmit={this.handleSubmit}>
                {this.renderDeviceSelection()}
                <div style={styles.inputs}>
                  {reportInputs.map(input => this.generateFormItem(input, form, '33%'))}
                  {this.renderScheduledReportSchedule()}
                </div>
                <div style={{ display: 'flex', flexGrow: '1', marginTop: 10 }}>
                  <Button
                    type="primary"
                    onClick={this.handleShowModal}
                  >
                    {(reportCategory !== 'scheduled')
                      ? wMatrix('createScheduledReport')
                      : wMatrix('editScheduledReport')
                    }
                  </Button>

                  {reportCategory === 'scheduled'
                    ? null
                    : showDownloadButton && (
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={this.checkEmptyFields()}
                        onClick={this.handleDownload}
                        style={{ marginLeft: 'auto' }}
                      >
                        {wMatrix('download')}
                      </Button>
                    )
                  }

                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={this.checkEmptyFields()}
                    onClick={this.handleGenerateReport}
                    style={{ marginLeft: showDownloadButton ? 20 : 'auto' }}
                  >
                    {reportCategory === 'scheduled' ? wMatrix('sample') : wMatrix('generate')}
                  </Button>

                </div>
              </Form>
            </Panel>
          </Collapse>
        </div>
        {this.renderScheduledModal()}
      </div>
    )
  }
}

const WrappedReportForm = Form.create({ name: 'reportForm' })(ReportFilterContainer)

export default helper()(WrappedReportForm)
