/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react'
import PropTypes, { number } from 'prop-types'
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Alert,
} from 'antd'
import ModalHeader from '@mol/modalHeader'
import Skeleton from '@atom/skeleton'
import helper from '@helper'

const { Item } = Form
const { Option } = Select

class DeviceMgrPanel extends Component {
  state = {
    firstLoad: true,
    formUI: {
      alias: { validateStatus: '', help: '' },
      year: { validateStatus: '', help: '' },
      vin: { validateStatus: '', help: '' },
    },
    make: 0,
  }

  static propTypes = {
    selectedDevice: PropTypes.object.isRequired,
    vehicleTypeData: PropTypes.object.isRequired,
    getMakeID: PropTypes.func.isRequired,
    getModelID: PropTypes.func.isRequired,
    getModelsFromMakeID: PropTypes.func.isRequired,
    form: PropTypes.object,
    updateVehicleInfo: PropTypes.func.isRequired,
    showAlert: PropTypes.object,
    alertOnClose: PropTypes.func,
    /** @deviceHOC from main.js */
    deviceMgrUpdating: PropTypes.bool,
    /** @deviceMgrHOC */
    checkIfVinExists: PropTypes.func.isRequired,

    /** @Helper */
    getPermission: PropTypes.func.isRequired,
    wMatrix: PropTypes.func.isRequired,
  }

  static defaultProps = {
    form: null,
    showAlert: {
      show: false,
      type: 'error',
    },
    alertOnClose: null,
    deviceMgrUpdating: false,
  }

  componentDidUpdate = () => {
    const { firstLoad } = this.state
    const {
      vehicleTypeData, form,
    } = this.props
    // on first load of data, set the id values for make and model for form
    if (!vehicleTypeData.loading && firstLoad && form) {
      this.formatFirstLoadData()
    }
  }

  /**
   * Format first load state and form data
   */
  formatFirstLoadData = () => {
    const {
      getMakeID, getModelID, form, selectedDevice,
    } = this.props
    this.setState({ firstLoad: false })
    const deviceClone = JSON.parse(JSON.stringify(selectedDevice))
    const { make, model, year } = deviceClone.vehicle
    const makeID = getMakeID(make)
    const modelID = getModelID(make, model)
    // set correct form values
    form.setFieldsValue({
      make: makeID,
      model: modelID,
      year: year || null,
    })
    // We keep state of selected make to correctly show associated models
    this.setState({ make: makeID })
  }

  /**
   * @description simply return a list of Options (makes)
   */
  renderMakeOptions = ({ vehicleMakes } = this.props) => {
    if (!vehicleMakes) return null
    const makes = JSON.parse(JSON.stringify(vehicleMakes))
    return (makes.map(make => (
      <Option key={make.id} value={make.id}>{make.name}</Option>
    )))
  }

  /**
   * @description return a list of Options (models) given the make
   */
  renderModelOptions = (make) => {
    const { getModelsFromMakeID } = this.props
    // if there is a make chosen, render the correct options
    const models = getModelsFromMakeID(make)
    return models.map(model => <Option key={model.id} value={model.id}>{model.name}</Option>)
  }

  /**
   * @description changes the model option to unspecified on new make
   */
  handleMakeChange = (value) => {
    const { form } = this.props
    const { setFieldsValue } = form
    // set model to 'Unspecified'
    setFieldsValue({ model: 0 })
    // update 'make' state to render propper models
    this.setState({ make: value })
  }

  /**
   * @returns {void}
   * @description Handles onClick of update
   */
  handleSaveClick = () => {
    const { formUI } = this.state
    let formUIClone = JSON.parse(JSON.stringify(formUI))
    const {
      form, selectedDevice, updateVehicleInfo, wMatrix,
    } = this.props
    form.validateFields(async (err, values) => {
      const data = { id: selectedDevice.id, ...values }
      const yearValidation = this.validateVehicleYear(values.year)
      const vinValidation = await this.vinValidation(values.vin)
      if (values.alias === '') {
        formUIClone.alias = {
          help: wMatrix('theAliasCannotBeEmpty'),
          validateStatus: 'error',
        }
        this.setState({ formUI: formUIClone })
      } else if (yearValidation === 'error') {
        // undefined if no error
        formUIClone.year = {
          help: wMatrix('notAValidYear'),
          validateStatus: 'error',
        }
        this.setState({ formUI: formUIClone })
      } else if (vinValidation === 'error') {
        // undefined if no error
        formUIClone.vin = {
          help: wMatrix('duplicateVin'),
          validateStatus: 'error',
        }
        this.setState({ formUI: formUIClone })
      } else {
        formUIClone = {
          alias: { validateStatus: '', help: '' },
          year: { validateStatus: '', help: '' },
          vin: { validateStatus: '', help: '' },
        }
        this.setState({ formUI: formUIClone })
        updateVehicleInfo(data)
      }
    })
  }

  /**
   * @returns {Component}
   * @description Returns form with device info
   */
  returnDeviceForm = () => {
    const { formUI } = this.state
    const { form, getPermission, selectedDevice } = this.props
    const selectedDeviceClone = JSON.parse(JSON.stringify(selectedDevice))
    const { getFieldDecorator } = form
    const editable = getPermission('DeviceSettings', 'ynUpdate')
    return (
      <div>

        <Item
          label="Alias"
          validateStatus={formUI.alias.validateStatus}
          help={formUI.alias.help}
        >
          {getFieldDecorator('alias', {
            initialValue: selectedDeviceClone.alias,
          })(
            <Input disabled={!editable} />,
          )}
        </Item>
        <Item label="Serial Number">
          <Row gutter={4}>
            <Col span={12}>
              <span>{selectedDeviceClone.simSerial}</span>
            </Col>
          </Row>
        </Item>
        <Item label="IMEI">
          <Row gutter={4}>
            <Col span={18}>
              <span>{selectedDeviceClone.imei}</span>
            </Col>
          </Row>
        </Item>
      </div>
    )
  }

  /**
   * returns skeleton row for form
   */
  returnSkeletonRow = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24 }}>
      <Skeleton wrapperStyle={{
        width: '48px', height: 16, marginLeft: 5, alignSelf: 'center',
      }}
      />
      <Skeleton wrapperStyle={{
        marginTop: 3, marginBottom: 3, height: 29, width: 295, alignSelf: 'flex-end',
      }}
      />
    </div>
  )

  /**
   * returns skeleton of form
   */
  returnSkeletonForm = () => (
    // 295 35
    <div style={{ display: 'flex', flexDirection: 'column', width: '450px' }}>
      {this.returnSkeletonRow()}
      {this.returnSkeletonRow()}
      {this.returnSkeletonRow()}
      {this.returnSkeletonRow()}
      {this.returnSkeletonRow()}
    </div>
  )

  /**
   * @description handles validation status of vehicle year
   */
  validateVehicleYear = (yearVal) => {
    if (yearVal) {
      // make sure value is all numbers
      const num = /^\d+$/.test(yearVal)
      // not all numbers
      if (!num) return 'error'
      if (yearVal.toString().length !== 4) return 'error'
    }
    // No issue
    return undefined
  }

  /**
   * Validates VIN to make sure it is not already assigned to another vehicle
   * @param {String} vin vin number
   * @returns {String | undefined} returns 'error' if invalid/duplicate VIN
   */
  vinValidation = async (vin) => {
    const { checkIfVinExists, selectedDevice } = this.props
    const vinExists = await checkIfVinExists(vin)
    if (vinExists && selectedDevice.vehicle.vin !== vin) {
      return 'error'
    }
    // No issue
    return undefined
  }

  /**
   * @returns {Component}
   * @description Returns form for vehicle info
   */
  returnVehicleForm = () => {
    const { make, formUI } = this.state
    const {
      vehicleTypeData, form, getPermission, selectedDevice,
    } = this.props
    const { getFieldDecorator } = form

    const editable = getPermission('VehicleSettings', 'ynUpdate')

    if (vehicleTypeData.loading) {
      return (
        this.returnSkeletonForm()
      )
    }
    return (
      <div>
        <Item label="Make">
          {getFieldDecorator('make', {
            initialValue: selectedDevice.vehicle.make,
          })(
            <Select
              onChange={this.handleMakeChange}
              disabled={!editable}
              showSearch
              optionFilterProp="children"
            >
              {this.renderMakeOptions()}
            </Select>,
          )}
        </Item>
        <Item label="Model">
          {getFieldDecorator('model', {
            initialValue: selectedDevice.vehicle.model,
          })(
            <Select
              onChange={this.handleModelChange}
              disabled={!editable}
              showSearch
              optionFilterProp="children"
            >
              {this.renderModelOptions(make)}
            </Select>,
          )}
        </Item>
        <Item
          label="Year"
          validateStatus={formUI.year.validateStatus}
          help={formUI.year.help}
        >
          {getFieldDecorator('year', {
            type: number,
            initialValue: selectedDevice.vehicle.year || null,
          })(
            <Input disabled={!editable} />,
          )}
        </Item>
        <Item label="Color">
          {getFieldDecorator('color', {
            initialValue: selectedDevice.vehicle.color,
          })(
            <Input disabled={!editable} />,
          )}
        </Item>
        <Item label="License Plate">
          {getFieldDecorator('license', {
            initialValue: selectedDevice.vehicle.license,
          })(
            <Input disabled={!editable} />,
          )}
        </Item>
        <Item
          label="VIN Number"
          validateStatus={formUI.vin.validateStatus}
          help={formUI.vin.help}
        >
          {getFieldDecorator('vin', {
            initialValue: selectedDevice.vehicle.vin,
          })(
            <Input disabled={!editable} />,
          )}
        </Item>
      </div>
    )
  }

  showAlert = () => {
    const { wMatrix, showAlert, alertOnClose } = this.props
    if (showAlert.show) {
      return (
        <Alert
          style={{ marginRight: '4px' }}
          message={showAlert.type === 'success' ? wMatrix('updatedSuccessfully') : showAlert.message}
          type={showAlert.type}
          closable
          onClose={alertOnClose}
        />
      )
    }
    return null
  }


  render() {
    const {
      wMatrix, getPermission, deviceMgrUpdating,
    } = this.props
    const formItemLayout = {
      labelCol: {
        xs: { span: 4 },
        md: { span: 4 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 8 },
        md: { span: 8 },
        sm: { span: 8 },
      },
    }
    const vehicleEditable = getPermission('VehicleSettings', 'ynUpdate')
    const deviceEditable = getPermission('DeviceSettings', 'ynUpdate')
    const updatable = (vehicleEditable || deviceEditable)

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Form {...formItemLayout} labelAlign="left" style={{ marginLeft: 50 }}>
          <div style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%',
          }}
          >
            <ModalHeader
              headerName={wMatrix('Device Information')}
              description=""
            />
          </div>
          {this.returnDeviceForm()}
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <ModalHeader
              headerName={wMatrix('Vehicle Information')}
              description=""
            />
          </div>
          {this.returnVehicleForm()}
        </Form>
        <div style={{ alignSelf: 'flex-end', display: 'flex', alignItems: 'center' }}>
          {this.showAlert()}
          <Button type="primary" disabled={!updatable} loading={deviceMgrUpdating} onClick={this.handleSaveClick}>{deviceMgrUpdating ? wMatrix('updating') : wMatrix('update')}</Button>
        </div>
      </div>
    )
  }
}
const WrappedForm = Form.create({ name: 'deviceMgrForm' })(DeviceMgrPanel)

export default helper()(WrappedForm)
