import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Tag from '@atom/tag'
import helper from '@helper'

const styles = {
  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '10px',
  },

  rowFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'space-between',
  },

  previewContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    marginLeft: '20px',
    marginRight: '20px',
  },

  cozyDotContainerStyle: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },

  centerDotStyle: {
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    backgroundColor: 'rgba(255,255,255,1)',
  },
}

class GroupPrev extends Component {
  static propTypes = {
    show: PropTypes.bool,
    name: PropTypes.string,
    color: PropTypes.string,
    personal: PropTypes.bool,
    wMatrix: PropTypes.func,
  }

  static defaultProps = {
    show: true,
    name: 'Example',
    color: '#003375',
    personal: false,
    wMatrix: null,
  }

  colorDotStyle = color => ({
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 0 auto',
    display: 'flex',
    height: '26px',
    width: '26px',
    borderRadius: '50%',
    backgroundColor: color,
  })

  cozyDot = color => (
    <div style={styles.cozyDotContainerStyle}>
      <div style={this.colorDotStyle(color)}>
        <div style={styles.centerDotStyle} />
      </div>
    </div>
  )

  showPrev = () => {
    const {
      show, name, wMatrix, color, personal,
    } = this.props
    if (show === true) {
      let prevName = null
      if (name.length > 0 && name.length <= 10) {
        prevName = name
      } else if (name.length > 10) {
        prevName = `${name.substring(0, 10)}...`
      } else {
        prevName = wMatrix('Example')
      }

      return (
        <div style={styles.previewContainerStyle}>
          <h3 style={{ textAlign: 'center' }}>{wMatrix('Preview')}</h3>
          <div style={styles.rowFlex}>
            <div style={styles.labelContainer}>
              <label style={{ alignSelf: 'flex-end' }}>
                {wMatrix('Comfortable')}
                :
              </label>
              <label style={{ alignSelf: 'flex-end' }}>
                {wMatrix('Cozy')}
                :
              </label>
            </div>
            <div>
              <Tag
                id={0}
                name={prevName}
                color={color}
                personal={personal}
              />
              <Tag
                id={0}
                name={prevName}
                color={color}
                personal={personal}
                size="small"
              />
            </div>
          </div>
        </div>
      )
    }
    return <div />
  }

  render() {
    return this.showPrev()
  }
}

export default helper()(GroupPrev)
