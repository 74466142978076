import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'antd'


export default class DensityToggleButton extends Component {
  /**
     * @constructor
     * this toggle has to be given a handleChange function from props
     * ex. see densityModal.js
     */

    static propTypes = {
      handleChange: PropTypes.func.isRequired,
      selection: PropTypes.string.isRequired,
      wMatrix: PropTypes.func.isRequired,
      customOptions: PropTypes.array, // format: [{title, value}]
    }

    static defaultProps = {
      customOptions: null,
    }

    handleComfortChange = (e) => {
      const { handleChange } = this.props
      if (handleChange != null) {
        handleChange(e.target.value)
      }
    }

    render() {
      const { selection, wMatrix, customOptions } = this.props
      if (customOptions) {
        return (
          <div>
            <Radio.Group defaultValue={selection} onChange={this.handleComfortChange}>
              {customOptions.map(option => (
                <Radio.Button
                  value={option.value}
                  key={option.value}
                >
                  {wMatrix(option.title)}
                </Radio.Button>
              ))}
            </Radio.Group>
          </div>
        )
      }
      return (
        <div>
          <Radio.Group defaultValue={selection} onChange={this.handleComfortChange}>
            <Radio.Button value="comfortable">{wMatrix('Comfortable')}</Radio.Button>
            <Radio.Button value="cozy">{wMatrix('Cozy')}</Radio.Button>
            <Radio.Button value="compact">{wMatrix('Compact')}</Radio.Button>
          </Radio.Group>
        </div>
      )
    }
}
