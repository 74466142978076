import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextButton from '@atom/textButton'
import helper from '@helper'

const styles = {
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: '0',
    justifyContent: 'space-between',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: '0',
    flexGrow: 1,
    alignSelf: 'stretch',
  },
}

class TripHeader extends Component {
  static propTypes = {
    /** @helper */
    wMatrix: PropTypes.func.isRequired,
    distanceToString: PropTypes.func.isRequired,
    speedToString: PropTypes.func.isRequired,
    /** @parent */
    trip: PropTypes.object.isRequired,
    setSelectedTrip: PropTypes.func.isRequired,
    selectedTrip: PropTypes.number,
    // sortOrder: PropTypes.string,
    expanded: PropTypes.bool,
  }

  static defaultProps = {
    selectedTrip: null,
    // sortOrder: 'descending',
    expanded: true,
  }

  convertToMin = seconds => seconds / 60

  /**
   * @private
   * @description Returns ending location string given address and landmarks.
   * This can be a list of landmarks or a street or a state.
   */
  returnEndLocationString = () => {
    const { trip } = this.props
    const { endAddress, endLandmarks } = trip
    // showing landmarks takes precedence
    if (endLandmarks && endLandmarks.length > 0) {
      let landmarksString = ''
      // create landmark string
      for (let i = 0; i < endLandmarks.length; i += 1) {
        landmarksString += `${endLandmarks[i].name}`
        if (i !== endLandmarks.length - 1) {
          landmarksString += ', '
        }
      }
      return landmarksString
    }
    // else
    if (endAddress.street && endAddress.street.length > 0) {
      return endAddress.street
    }
    // else
    return endAddress.state
  }

  /**
   * @private
   * Renders the ending location component
   */
  renderEndedAt = () => {
    const { wMatrix, trip } = this.props
    return (
      trip.endAddress ? (
        <div style={{
          display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center',
        }}
        >
          {
            trip.ifCurrentTrip
              ? (
                <h5 style={{ fontWeight: 'normal', fontSize: 12, marginBottom: 0 }}>
                  {`${wMatrix('lastLocate')}: ${this.returnEndLocationString()}`}
                </h5>
              )
              : (
                <h5 style={{ fontWeight: 'normal', fontSize: 12, marginBottom: 0 }}>
                  {`${wMatrix('endedAt')} ${this.returnEndLocationString()}`}
                </h5>
              )
          }
        </div>
      ) : null
    )
  }


  /**
   * @private
   * Render start and end times
   */
  renderStartEnd = () => {
    const { trip, wMatrix } = this.props
    // format received: 08/28/2019 7:20 AM
    const { startTime } = trip
    const endTime = trip.ifCurrentTrip ? wMatrix('current') : trip.endTime
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <h5 style={{ marginBottom: 0 }}>
          {startTime}
          {' - '}
          {endTime}
        </h5>
        {trip.tripDetails && !trip.ifCurrentTrip ? (
          <h5 style={{ marginLeft: '5px', fontWeight: 'normal', marginBottom: 0 }}>
            {' ('}
            {this.convertToMin(trip.tripDetails.duration)}
            {' min) '}
          </h5>
        ) : null}
      </div>
    )
  }

  renderTripDetails = () => {
    const {
      wMatrix, trip, distanceToString, speedToString,
    } = this.props
    const details = {
      duration: trip.duration,
      topSpeed: trip.topSpeed,
      distance: trip.distance,
    }
    if (details) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {details.distance ? (
            <div style={{
              display: 'flex', flexDirection: 'row', marginRight: '5px',
            }}
            >
              <h5 style={{ fontWeight: 'normal', marginBottom: 0 }}>
                {wMatrix('Distance')}
                :
              </h5>
              <h5 style={{ marginLeft: '4px', fontWeight: 'normal', marginBottom: 0 }}>
                {distanceToString(details.distance)}
              </h5>
            </div>
          ) : (
            null
          )}
          {details.topSpeed ? (
            <div style={{
              display: 'flex', flexDirection: 'row', marginLeft: details.distance ? '5px' : 0, marginRight: '5px',
            }}
            >
              <h5 style={{ fontWeight: 'normal', marginBottom: 0 }}>
                {wMatrix('topSpeed')}
                :
              </h5>
              <h5 style={{ marginLeft: '4px', fontWeight: 'normal', marginBottom: 0 }}>
                {speedToString(details.topSpeed)}
              </h5>
            </div>
          ) : (
            null
          )}
        </div>
      )
    }
    return null
  }

  renderMapButton = () => {
    const {
      wMatrix, trip, setSelectedTrip, selectedTrip,
    } = this.props
    return (
      <div style={{
        display: 'flex', justifyContent: 'center', alignSelf: 'center', margin: '5px', alignContent: 'center', flexShrink: 0,
      }}
      >
        <TextButton
          style={{}}
          onClick={() => setSelectedTrip(trip.tripOrder)}
          useAsWrapper
        >
          <h5 style={{
            color: '#4482FF', fontStyle: 'normal', textDecoration: 'underline', textAlign: 'center', marginBottom: 0,
          }}
          >
            {selectedTrip === trip.tripOrder ? wMatrix('onMap') : wMatrix('showOnMap')}
          </h5>
        </TextButton>
      </div>

    )
  }

  renderMiniHeader = () => {
    const {
      trip, expanded, wMatrix,
      // sortOrder,
    } = this.props
    if (!expanded) {
      return (
        <div style={{ ...styles.mainContent, justifyContent: 'center', padding: '-5px' }}>
          <h4 style={{ fontSize: 12 }}>
            {wMatrix('Trip')}
            {' #'}
            {trip.tripOrder}
          </h4>
          {/* <h5
            style={{ whiteSpace: 'nowrap' }}
          >
            { sortOrder === 'descending'
              ? moment(trip.endTime, 'MM/DD/YYYY h:mm A').format('h:mm a')
              : moment(trip.startTime, 'MM/DD/YYYY h:mm A').format('h:mm a')}
          </h5> */}
        </div>
      )
    }
    return null
  }

  renderTripHeader = () => {
    const {
      trip, wMatrix, expanded,
    } = this.props
    return (
      <div style={styles.headerContainer}>
        <div style={styles.mainContent}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: expanded ? 'space-between' : 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {
              trip.ifCurrentTrip
                ? (
                  <h3 style={{ marginRight: '5px', marginBottom: 0 }}>
                    {wMatrix('Current Trip')}
                  </h3>
                ) : (
                  <h3 style={{ marginRight: '5px', marginBottom: 0 }}>
                    {wMatrix('Trip')}
                    {' #'}
                    {trip.tripOrder}
                  </h3>
                )
            }
            {this.renderStartEnd()}
          </div>
          {this.renderEndedAt()}
          {this.renderTripDetails()}
        </div>
        {this.renderMapButton()}
      </div>
    )
  }

  renderTripHeaderV2 = () => {
    const { trip, wMatrix } = this.props
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: 4 }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {
              trip.ifCurrentTrip
                ? (
                  <h3 style={{
                    marginRight: '5px', marginBottom: 0, fontSize: 12, whiteSpace: 'nowrap',
                  }}
                  >
                    {wMatrix('Current Trip')}
                  </h3>
                ) : (
                  <h3 style={{
                    marginRight: '5px', marginBottom: 0, fontSize: 12, whiteSpace: 'nowrap',
                  }}
                  >
                    {wMatrix('Trip')}
                    {' #'}
                    {trip.tripOrder}
                  </h3>
                )
            }
            {this.renderEndedAt()}
          </div>
          {this.renderStartEnd()}
          {this.renderTripDetails()}
        </div>
        {this.renderMapButton()}
      </div>
    )
  }

  render() {
    const { expanded } = this.props
    return (
      expanded ? this.renderTripHeaderV2() : this.renderMiniHeader()
      // expanded ? this.renderTripHeader() : this.renderMiniHeader()
    )
  }
}

export default helper()(TripHeader)
