import React, { Component } from 'react'
import Tag from '@page/main/atoms/tag'
import PropTypes from 'prop-types'


const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
}

export default class GroupOfGroups extends Component {
  static propTypes = {
    groups: PropTypes.array,
    labels: PropTypes.array,
    isEditable: PropTypes.bool,
    onClick: PropTypes.func,
    show: PropTypes.bool,
    wrap: PropTypes.bool,
    size: PropTypes.string,
    toggleable: PropTypes.bool,
    noContainer: PropTypes.bool, // prop that sets component to return array of tag components
    defaultSmall: PropTypes.bool,
  }

  static defaultProps = {
    groups: [],
    labels: [],
    isEditable: false,
    onClick: null,
    show: true,
    wrap: true,
    size: 'large',
    toggleable: false,
    noContainer: false,
    defaultSmall: false,
  }

  state = {
    allSize: 'large',
  }

  /**
   * This is used to default to size small for all tags if the prop is set
   */
  componentDidMount = () => {
    const { defaultSmall, toggleable } = this.props
    if (toggleable && defaultSmall) {
      this.setState({ allSize: 'small' })
    }
  }

  /**
   * @private
   * @description Toggles the tag sizes on click if toggleable prop is set.
   */
  onToggle = () => {
    const { toggleable } = this.props
    const { allSize } = this.state
    if (toggleable) {
      if (allSize === 'small') {
        this.setState({ allSize: 'large' })
      } else {
        this.setState({ allSize: 'small' })
      }
    }
  }

  /**
   * @private
   * @returns {Component}
   * @description Returns array of tags. If no tags, returns empty div.
   */
  displayTags = () => {
    const {
      show,
      groups,
      labels,
      isEditable,
      onClick,
      size,
      toggleable,
      defaultSmall,
    } = this.props

    if (show) {
      const tags = []
      // set default size to be that of passed prop
      let sizeVal = size
      // if toggleable prop set, use toggle state
      if (toggleable) {
        const { allSize } = this.state
        sizeVal = allSize
      }

      for (const group of groups) {
        tags.push(
          <Tag
            key={group.id}
            id={group.id}
            name={group.name}
            color={group.color}
            editable={isEditable}
            onClick={onClick}
            size={sizeVal}
            defaultSmall={defaultSmall}
          />,
        )
      }

      for (const label of labels) {
        tags.push(
          <Tag
            key={label.id}
            id={label.id}
            name={label.name}
            color={label.color}
            editable={isEditable}
            onClick={onClick}
            size={sizeVal}
            defaultSmall={defaultSmall}
            personal
          />,
        )
      }

      return tags
    }
    return <div />
  }

  render() {
    const { wrap, noContainer, toggleable } = this.props
    if (noContainer) {
      return this.displayTags()
    }
    if (toggleable) {
      // return clickable div
      return (
        <div
          style={{ ...styles.wrapper, flexWrap: wrap ? 'wrap' : 'nowrap' }}
          role="button"
          tabIndex="-1"
          onClick={() => this.onToggle()}
        >
          {this.displayTags()}
        </div>
      )
    }
    // default return simple div of groups and/or labels
    return (
      <div style={{ ...styles.wrapper, flexWrap: wrap ? 'wrap' : 'nowrap' }}>
        {this.displayTags()}
      </div>
    )
  }
}
