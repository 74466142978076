import React, { Component } from 'react'
import {
  Input, Button, Icon, Badge, Spin,
} from 'antd'
import ButtonWrapper from '@atom/textButton'
import PropTypes from 'prop-types'
import MessengerBubble from '@atom/messengerBubble'
import CustomIcon from '@atom/icon'

const styles = {
  mainContainer: {
    position: 'relative',
    flexShrink: 0,
    width: 300,
    alignSelf: 'flex-end',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'white',
    backgroundColor: '#003375',
    flexGrow: 0,
    height: '24px',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'white',
    fontSize: 14,
    fontWeight: 'medium',
  },
  windowContainer: {
    position: 'absolute',
    backgroundColor: 'white',
    width: 300,
    height: 400,
    top: -400,
    color: 'black',
    boxShadow: '0px -5px 25px 2px #88888880',
    display: 'flex',
    flexDirection: 'column',
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  windowHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'white',
    fontSize: 18,
    fontWeight: 'medium',
    height: 50,
    backgroundColor: '#003375',
    flexGrow: 0,
    flexShrink: 0,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    paddingLeft: 15,
    paddingRight: 15,
  },
  headerTextWrapper: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
  },
  headerDriverWrapper: {
    fontWeight: 'normal',
    fontSize: 12,
  },
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '10px',
  },
  button: {
    marginLeft: '5px',
    marginRight: '5px',
    paddingLeft: 8,
    paddingRight: 8,
  },
  minimizedText: {
    marginLeft: '5px',
    marginRight: '5px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  minimizedButtonWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    alignItems: 'center',
  },
  minimizedIconWrapper: {
    flexGrow: 0,
    flexShrink: 0,
    marginRight: 5,
    marginLeft: 10,
    marginTop: 5,
    alignSelf: 'center',
  },
  footerContainer: {
    borderColor: '#e9e9e9',
    borderWidth: 1,
    bottom: 0,
    borderStyle: 'solid',
    height: 54,
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
}

export default class MessengerWindow extends Component {
  /** Element used to "auto scroll" to bottom */
  lastElement = React.createRef()

  static propTypes = {
    device: PropTypes.object,
    messages: PropTypes.array,
    sendMessage: PropTypes.func,
    loading: PropTypes.bool,
    // onClose: PropTypes.func,
    markMessagesRead: PropTypes.func,
    closeMessenger: PropTypes.func,
    wMatrix: PropTypes.func.isRequired,
  }

  static defaultProps = {
    device: {
      id: null,
      alias: null,
      driver: null,
    },
    loading: false,
    messages: [],
    sendMessage: () => {},
    markMessagesRead: null,
    closeMessenger: null,
    // onClose: () => {},
  }

  constructor(props) {
    super(props)
    this.state = {
      message: '',
      minimized: false,
    }
  }

  /**
   * This componentDidUpdate handles the autoscrolling as well as marking messages read when the
   * number of messages has changed or the minimized state has changed.
   */
  componentDidUpdate(prevProps, prevState) {
    const { messages } = this.props
    const { minimized } = this.state
    if ((messages.length !== prevProps.messages.length) || (prevState.minimized && !minimized)) {
      this.scrollToBottom()
      /**
       * If window is open, mark unread messages as read.
       */
      if (!minimized) {
        this.updateReadMessages()
      }
    }
  }

  /**
   * Smooth scrolls to referenced element (at bottom of messages list)
   */
  scrollToBottom = () => {
    this.lastElement.current.scrollIntoView({ behavior: 'smooth' })
  }

  /**
   * Updates message state of input.
   * @param {String} message Message string
   */
  onInputChange = (message) => {
    this.setState({ message })
  }

  /**
   * Clears the input field by setting the message state to empty string.
   */
  clearInput = () => {
    this.setState({ message: '' })
  }

  /**
   * Sends the message content and clears the input field.
   */
  sendMessage = () => {
    const { message } = this.state
    const { device, sendMessage } = this.props
    sendMessage(device.id, message)
    this.clearInput()
  }

  /**
   * Used to update all unread messages when window is open.
   * Currently called in two places:
   *  - ComponentDidUpdate: when message length changes and window is open
   *  - When Minimized is being toggled open
   * @todo
   *  - Possibly update to be used only when message is in view instead of when window is open
   *    (this would require more time and references for each element)
   */
  updateReadMessages = () => {
    const { markMessagesRead, messages, device } = this.props
    const messageIds = []
    for (let i = 0; i < messages.length; i += 1) {
      if (!messages[i].read) {
        messageIds.push(messages[i].id)
      }
    }
    if (messageIds.length > 0) {
      markMessagesRead(device.id, messageIds)
    }
  }

  /**
   * Toggles minimized state of window
   */
  toggleMinimize = () => {
    const { minimized } = this.state
    /**
     * If opening from minimized, update read messages
     */
    if (minimized) {
      this.updateReadMessages()
    }
    this.setState({ minimized: !minimized })
    // this.scrollToBottom()
  }

  /**
   * Renders the Close Button Element (to be used in both the header as well as the minimized bar)
   * @returns Element
   */
  renderCloseButton = () => {
    const { closeMessenger, device } = this.props
    // closeMessenger={closeMessenger}
    return (
      <ButtonWrapper
        useAsWrapper
        onClick={() => closeMessenger(device.id)}
      >
        <Icon type="close" style={{ color: 'white', marginLeft: '5px', marginRight: '5px' }} />
      </ButtonWrapper>
    )
  }

  /**
   * Renders the Messenger Header
   */
  renderMessageHeader = () => {
    const { device } = this.props
    const { driver, alias } = device
    return (
      <div style={styles.windowHeader}>
        <div style={styles.headerTextWrapper}>
          {alias}
          <div style={styles.headerDriverWrapper}>
            {driver ? driver.name : ''}
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <ButtonWrapper
            useAsWrapper
            onClick={() => this.toggleMinimize()}
          >
            <Icon type="minus" style={{ color: 'white', marginLeft: '5px', marginRight: '5px' }} />
          </ButtonWrapper>
          {this.renderCloseButton()}
        </div>

      </div>
    )
  }

  /**
   * Renders Footer of window including text input and send button
   * @returns Element
   */
  renderFooter = () => {
    const { message } = this.state
    const { wMatrix } = this.props
    return (
      <div style={styles.footerContainer}>
        <Input
          style={{
            width: 200,
          }}
          size="small"
          placeholder={wMatrix('typeMessage')}
          value={message || undefined}
          onChange={e => this.onInputChange(e.target.value)}
          onPressEnter={() => this.sendMessage()}
          onFocus={() => { this.scrollToBottom() }}
        />
        <Button
          onClick={this.sendMessage}
          type="primary"
          size="small"
        >
          {wMatrix('send')}
        </Button>
      </div>
    )
  }

  /**
   * Returns a Message Bubble based on the message objects
   * contents (including origin, content, and date sent)
   * @param {Object} message Message object containing messageId, content,
   *  origin, read state, and deliveredDate
   * @returns Element
   */
  renderMessage = (message) => {
    const { wMatrix } = this.props
    const {
      id, content, origin, read, deliveredDate,
    } = message
    // origin for outbound can be either web_app or mobile_app
    return (
      <MessengerBubble
        key={`message-${id}`}
        wMatrix={wMatrix}
        id={id}
        content={content}
        type={origin.includes('app') ? 'outbound' : 'inbound'}
        read={read}
        deliveredDate={deliveredDate}
      />
    )
  }

  /**
   * Renders minimized Messenger Element
   * @returns Element
   */
  renderMinimizedContent = () => {
    const { messages } = this.props
    let unread = 0
    for (let i = 0; i < messages.length; i += 1) {
      if (!messages[i].read && messages[i].origin !== 'web_app') {
        unread += 1
      }
    }
    const {
      device,
    } = this.props
    const { driver, alias } = device
    const title = `${alias}${driver ? ` (${driver.name})` : ''}`
    return (
      <>
        <div style={{ flexGrow: 1, overflow: 'hidden' }}>
          <ButtonWrapper onClick={() => { this.toggleMinimize() }}>
            <div style={styles.minimizedButtonWrapper}>
              <div style={styles.minimizedIconWrapper}>
                <Badge
                  className="clipBadge"
                  dot
                  count={unread}
                  style={{ height: 8, width: 8 }}
                  offset={[-16, 2]}
                >
                  <CustomIcon
                    type="chat-bubble"
                    width={16}
                    color="white"
                  />
                </Badge>
              </div>
              <div style={styles.minimizedText}>
                {title}
              </div>
            </div>
          </ButtonWrapper>
        </div>
        <div style={{ flexGrow: 0, marginRight: 15 }}>
          {this.renderCloseButton()}
        </div>
      </>
    )
  }

  /**
   * Renders Loading Element based on loading prop
   * @returns Element
   */
  renderLoadingItem = () => {
    const { loading } = this.props
    if (!loading) {
      return null
    }
    return (
      <div style={styles.loadingWrapper}>
        <Spin />
      </div>
    )
  }

  /**
   * Returns Element that makes up the messenger window
   * @returns Element
   */
  renderMessageWindow = () => {
    const { messages } = this.props
    return (
      <>
        <div style={styles.windowContainer}>
          {this.renderMessageHeader()}
          {/* content - scrollable list */}
          <div style={{
            flexGrow: 1,
            overflow: 'auto',
          }}
          >
            {this.renderLoadingItem()}
            {messages.map(message => this.renderMessage(message))}
            <div ref={this.lastElement} />
          </div>
          {/* footer */}
          {this.renderFooter()}
        </div>
      </>
    )
  }

  render() {
    const { minimized } = this.state

    return (
      <div
        style={styles.mainContainer}
      >
        {minimized ? this.renderMinimizedContent() : this.renderMessageWindow()}
      </div>
    )
  }
}
