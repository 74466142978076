import React, { Component } from 'react'
import {
  Table, Popconfirm, Icon, Form,
} from 'antd'
import PropTypes from 'prop-types'
import GroupsDropDown from '@mol/groupsDropDown'
import { InputSearch } from '@atom/input'
import EditableCell from '@atom/editTableCell'
import helper from '@helper'

export const EditableContext = React.createContext()

const inGroupList = (groupList, searchVal) => {
  // create an array of just the names
  const groupArray = groupList.map(group => group.name)
  // test each name in array to have reactive search work with partial group names
  for (let i = 0; i < groupArray.length; i += 1) {
    if (groupArray[i].toLowerCase().indexOf(searchVal.toLowerCase()) !== -1) {
      return true
    }
  }
  return false
}

/**
 * @todo
 * Maybe somehow disable groups cell when admin or global roles are selected
 */

class EditableUsersTable extends Component {
  static propTypes = {
    isSelectable: PropTypes.bool,
    contacts: PropTypes.array,
    groups: PropTypes.array,
    wMatrix: PropTypes.func.isRequired,
    getPermission: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
  }

  static defaultProps = {
    contacts: [],
    isSelectable: true,
    groups: [],
  }

  state = {
    editingKey: '',
    deletingKey: '',
    searchVal: '',
    currentlyEditingRole: null,
  }

  constructor(props) {
    super(props)
    const { wMatrix, getPermission } = this.props

    this.devColumns = [
      {
        title: ' ',
        dataIndex: 'operationEdit',
        render: (text, record) => {
          const editable = this.isEditing(record)
          if (!getPermission('Global', 'ynUpdate') && record.role.id === 1) {
            return null
          }
          return (
            <div>
              {editable ? (
                <span style={{ verticalAlign: 'middle' }}>
                  <EditableContext.Consumer>
                    {valueObj => (
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                      <label // this will most likely change
                        onClick={() => this.save(valueObj.form, record.id)}
                        style={{
                          marginLeft: 4, marginRight: 4, whiteSpace: 'nowrap', display: 'block', color: '#4482FF', cursor: 'pointer',
                        }}
                      >
                        <Icon style={{ margin: '5px' }} type="save" />
                        {wMatrix('Save')}
                      </label>
                    )}
                  </EditableContext.Consumer>
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                  <label
                    style={{
                      marginLeft: 4,
                      marginRight: 4,
                      whiteSpace: 'nowrap',
                      display: 'block',
                      color: '#4482FF',
                      cursor: 'pointer',
                    }}
                    onClick={() => this.cancel(record.id)}
                  >
                    {wMatrix('Cancel')}
                  </label>
                </span>
              ) : (
                <Icon type="edit" theme="twoTone" twoToneColor="#4482ff" onClick={() => this.edit(record)} />
              )}
            </div>
          )
        },
      },
      {
        title: wMatrix('lastName'),
        dataIndex: 'lastName',
        editable: true,
        sorter: (a, b) => {
          const nameA = a.lastName.toUpperCase()
          const nameB = b.lastName.toUpperCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        },

      },
      {
        title: wMatrix('firstName'),
        dataIndex: 'firstName',
        editable: true,
        sorter: (a, b) => {
          const nameA = a.firstName.toUpperCase()
          const nameB = b.firstName.toUpperCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        },
      },
      {
        title: wMatrix('email'),
        dataIndex: 'email',
        editable: true,
      },
      {
        title: wMatrix('phone'),
        dataIndex: 'mobilePhone',
        editable: true,
      },
      {
        title: wMatrix('role'),
        dataIndex: 'role.id',
        width: '80px',
        sorter: (a, b) => a.role.id - b.role.id,
        editable: true,
        render: role => (
          <span style={{ whiteSpace: 'nowrap' }}>
            {this.getRoleName(role)}
          </span>
        ),
      },
      {
        title: wMatrix('Viewable Groups'),
        dataIndex: 'groups',
        editable: true,
        className: 'headerCenter',
        render: (tags) => {
          const { groups } = this.props
          const { currentlyEditingRole } = this.state
          if (tags && tags.length > 0) {
            return (
              <GroupsDropDown
                groups={tags}
                allGroups={groups}
                isEditable={false}
                currentlyEditingRole={currentlyEditingRole}
                cozy
              />
            )
          }
          return <span style={{ textAlign: 'center', display: 'block' }}>No Group Assigned</span>
        },
      },
      {
        title: ' ',
        dataIndex: 'operationDelete',
        render: (text, record) => {
          if (!getPermission('Global', 'ynDelete') && record.role.id === 1) {
            return null
          }
          return (
            <div>
              <span style={{ verticalAlign: 'middle' }}>
                <EditableContext.Consumer>
                  {valueObj => (
                    <Popconfirm
                      title={`${wMatrix('okToDelete')}?`}
                      okText={wMatrix('Delete')}
                      cancelText={wMatrix('Cancel')}
                      onConfirm={() => this.delete(valueObj.form, record.id)}
                      onCancel={() => this.cancel(record.id)}
                    >
                      <label style={{
                        marginLeft: 4, marginRight: 4, whiteSpace: 'nowrap', display: 'block', color: '#4482FF',
                      }}
                      >
                        <Icon type="delete" theme="twoTone" twoToneColor="#f64744" onClick={() => this.editDelete(record.id)} />
                      </label>
                    </Popconfirm>
                  )}

                </EditableContext.Consumer>
              </span>
            </div>
          )
        },
      },
    ]

    this.EditableFormRow = Form.create()(
      ({ form, index, ...trProps }) => (
        <EditableContext.Provider value={{
          form,
          getPermission,
          wMatrix,
        }}
        >
          <tr {...trProps} />
        </EditableContext.Provider>
      ),
    )
  }


  getRoleName = (number) => {
    const { wMatrix } = this.props
    switch (number) {
      case 1: return wMatrix('global')
      case 2: return wMatrix('Admin')
      case 3: return wMatrix('supervisor')
      case 4: return wMatrix('readOnly')
      case 6: return wMatrix('serviceTech')
      default: return wMatrix('readOnly')
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getInputType = (dataIndex) => {
    if (dataIndex === 'role.id') {
      return 'roleDropdown'
    }
    if (dataIndex === 'groups') {
      return 'groupsDropdown'
    }
    return 'text'
  }

  renderTable = (devColumns, rowSelection) => {
    const { isSelectable, contacts } = this.props
    if (isSelectable) {
      return (
        <div>
          <Table dataSource={contacts} columns={devColumns} rowSelection={rowSelection} rowKey="id" size="middle" />
        </div>
      )
    }

    return (
      <div>
        <Table dataSource={contacts} columns={devColumns} rowKey="id" size="middle" />
      </div>
    )
  }

  isEditing = (record) => {
    const { getPermission } = this.props
    const { editingKey } = this.state
    // if record of global user
    if (record.role.id === 1) {
      if (getPermission('Global', 'ynUpdate')) {
        return record.id === editingKey
      }
      return false
    }
    return record.id === editingKey
  }

  isDeleting = (record) => {
    const { getPermission } = this.props
    const { deletingKey } = this.state
    // if record of global user
    if (record.role.id === 1) {
      if (getPermission('Global', 'ynDelete')) {
        return record.id === deletingKey
      }
      return false
    }
    return record.id === deletingKey
  }

  cancel = () => {
    this.setState({ editingKey: '', deletingKey: ' ' })
  }

  updateSearch = (event) => {
    this.setState({ searchVal: event.target.value.substr(0, 20) })
  }

  save = (form, key) => {
    const { contacts, updateUser } = this.props
    // only edits one user at a time
    form.validateFields((error, row) => {
      if (error) {
        return
      }
      const newData = [...contacts]
      const index = newData.findIndex(item => key === item.id)
      if (index > -1) {
        const item = newData[index]
        const newItems = row

        // if role is global or admin, we do not want to update their groups
        if (newItems.role.id < 3) newItems.groups = []

        newData.splice(index, 1, {
          ...item,
          ...newItems,
        })
        this.setState({ editingKey: '' })
        if (newData[index].password !== undefined) {
          delete newData[index].password
        }
        updateUser(newData[index])
      } else {
        newData.push(row)
        this.setState({ editingKey: '' })
      }
    })
  }

  delete = (form, key) => {
    const { contacts, deleteUser } = this.props
    // only edits one user at a time
    form.validateFields((error, row) => {
      if (error) {
        return
      }
      const newData = [...contacts]
      const index = newData.findIndex(item => key === item.id)
      if (index > -1) {
        const item = newData[index]
        newData.splice(index, 1, {
          ...item,
          ...row,
        })
        this.setState({ deletingKey: '' })
        deleteUser(newData[index])
      } else {
        newData.push(row)
        this.setState({ deletingKey: '' })
      }
    })
  }

  edit = (record) => {
    this.setState({ editingKey: record.id, deletingKey: '', currentlyEditingRole: record.role.id })
  }

  editDelete = (key) => {
    this.setState({ editingKey: '', deletingKey: key, currentlyEditingRole: null })
  }

  filteredContacts = () => {
    const { searchVal } = this.state
    const { contacts } = this.props
    return contacts.filter(
      contact => (
        (contact.firstName.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1)
        || (contact.lastName.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1)
        || (contact.role.name != null
          && contact.role.name.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1
        )
        || (inGroupList(contact.groups, searchVal))
      ),
    )
  }

  onRoleChange = (val) => {
    this.setState({ currentlyEditingRole: val })
  }

  render() {
    const { wMatrix, groups } = this.props
    const { currentlyEditingRole } = this.state
    const components = {
      body: {
        row: this.EditableFormRow,
        cell: EditableCell,
      },
    }

    const columns = this.devColumns.map((col) => {
      const type = this.getInputType(col.dataIndex)
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: (record) => {
          let allowEditing = this.isEditing(record)
          // If the row is for Global user or Admin user then don't edit groups
          if (col.dataIndex === 'groups' && currentlyEditingRole < 3) {
            allowEditing = false
          }

          return {
            record,
            inputType: type, // logic for number usage: //'age' ? 'number' : 'text'
            dataIndex: col.dataIndex,
            title: col.title,
            editing: allowEditing,
            allGroups: groups,
            onRoleChange: this.onRoleChange,
          }
        },
      }
    })

    return (
      <div style={{ display: 'flex', marginTop: 50, flexDirection: 'column' }}>
        <InputSearch
          style={{ alignSelf: 'flex-end', marginBottom: '5px' }}
          className="groupPanelSearch"
          onChange={this.updateSearch}
          placeholder={wMatrix('nameRoleGroups')}
        />

        <Table
          className="userTable listTableNoPagiArrow"
          components={components}
          dataSource={this.filteredContacts()}
          columns={columns}
          rowKey="id"
          scroll={{ x: true }}
          size="middle"
          pagination={{
            defaultPageSize: 10, pageSizeOptions: ['10', '20', '50', '100'], showSizeChanger: true,
          }}
        />
      </div>
    )
  }
}

export default helper()(EditableUsersTable)
