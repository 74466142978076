import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Icon } from 'antd'
import TimeSlider from '@atom/timeSlider'
import equal from 'deep-equal'

class DynamicTimeSlider extends Component {
    state = {
      numOfSplits: 0,
      disable: false,
      split1Val: { start: 0, end: 1440 },
      split2Val: { start: 720, end: 1440 },
    }

    /**
     * @property {[Object,Object]} defaultVals An array of two objects
     * - The first object relates to the first time range
     * - The second object relates to the second time range (if applicable)
     *  ex: [{start: 0, end: 1440},{start: null, end: null}]
     */
    static propTypes = {
      dayId: PropTypes.string.isRequired,
      toggleDisableDay: PropTypes.func,
      onChangeHours: PropTypes.func,
      defaultVals: PropTypes.array,
      viewOnly: PropTypes.bool,
    }


    static defaultProps = {
      toggleDisableDay: null,
      viewOnly: false,
      defaultVals: [{ start: 0, end: 1440 }, { start: null, end: null }],
      onChangeHours: null,
    }

    componentDidMount() {
      const { defaultVals } = this.props
      // if there are default values, set up initial state
      if (defaultVals
        && defaultVals[1]
        && defaultVals[1].start !== null
        && defaultVals[1].end !== null) {
        this.setState({ numOfSplits: 1 })
      }
      // disable if necessary on Mount
      if (defaultVals && defaultVals.length > 0
        && defaultVals[0].start === null && defaultVals[0].end === null) {
        this.setState({ disable: true })
      }
    }

    /**
     * Component often mounts before it gets the default value props, so we need to update the
     *  state on the initial update of the props
     */
    componentDidUpdate = (prevProps) => {
      const { defaultVals } = this.props
      if (!equal(prevProps.defaultVals, defaultVals)) {
        this.initDefaults(defaultVals)
      }
    }

    /**
     * @private
     * @description Initiates default values and disables if necessary
     */
    initDefaults = (defaultVals) => {
      if (defaultVals && defaultVals.length > 0) {
        const disable = defaultVals[0].start === null && defaultVals[0].end === null
        this.setState({
          split1Val: disable ? { start: 0, end: 1440 } : defaultVals[0],
          split2Val: disable ? { start: null, end: null } : defaultVals[1],
          numOfSplits: !disable && defaultVals[1] && defaultVals[1].start !== null ? 1 : 0,
          disable,
        })
      }
    }

    /**
     * @description adjust split count (currently maxed out at 1 split)
     */
    onAdjustSplit = (increase) => {
      const { numOfSplits, disable } = this.state
      if (!disable) {
        if (increase) {
          if (numOfSplits >= 1) {
            this.setState({ numOfSplits: 1 })
          } else {
            this.setState({ numOfSplits: numOfSplits + 1 })
          }
          // reset default value
          this.setState({ split2Val: { start: 720, end: 1440 } })
        } else {
          if (numOfSplits <= 0) {
            this.setState({ numOfSplits: 0 })
          } else {
            this.setState({ numOfSplits: numOfSplits - 1 })
          }
          // reset default value
          this.setState({
            split1Val: { start: 0, end: 1440 },
            split2Val: { start: null, end: null },
          })
        }
      }
    }

    /**
     * @description Handle disable change
     * @param {Boolean} disableVal true = disable, false = enable
     */
    handleDisableChange = (disableVal) => {
      const { dayId, toggleDisableDay } = this.props
      this.setState({
        numOfSplits: 0,
        disable: disableVal,
      })
      toggleDisableDay(dayId)
    }

    // convert to array of objects for gql
    handleOnChange = (value, splitID) => {
      const { split1Val, split2Val, numOfSplits } = this.state
      const { onChangeHours, dayId } = this.props
      // if we have 2 time sliders
      if (numOfSplits > 0) {
        // first half of day
        if (splitID === 0) {
          this.setState({ split1Val: { start: value[0], end: value[1] } })
          onChangeHours(dayId, [{ start: value[0], end: value[1] }, split2Val])
        } else {
          // second half of day
          this.setState({ split2Val: { start: value[0], end: value[1] } })
          onChangeHours(dayId, [split1Val, { start: value[0], end: value[1] }])
        }
      } else {
        this.setState({ split1Val: { start: value[0], end: value[1] } })
        onChangeHours(dayId, [{ start: value[0], end: value[1] }])
      }
    }

    /**
     * @private
     * @description Renders time slider(s)
     */
    returnSliders = () => {
      const { numOfSplits, disable } = this.state
      const { viewOnly, defaultVals } = this.props
      // console.log('defaultVals:', defaultVals)
      if (numOfSplits === 0) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TimeSlider
              defaultVal={
                (defaultVals && defaultVals[0] != null)
                  ? [defaultVals[0].start, defaultVals[0].end]
                  : undefined
              }
              viewOnly={viewOnly ? true : undefined}
              showSwitch={!viewOnly} // just in case we want to show switch later
              disabled={disable}
              handleDisableChange={this.handleDisableChange}
              handleOnChange={this.handleOnChange}
              splitID={0}
            />
            <div style={{ width: 30, display: viewOnly ? 'none' : 'block' }}>
              <Icon type="plus" style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { this.onAdjustSplit(true) }} />
            </div>
          </div>
        )
      }
      if (numOfSplits === 1) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TimeSlider
              min={0}
              max={720}
              showSwitch={false}
              width={100}
              viewOnly={viewOnly ? true : undefined}
              disabled={viewOnly ? true : disable}
              defaultVal={
                (defaultVals && defaultVals[0] != null)
                  ? [defaultVals[0].start, defaultVals[0].end]
                  : undefined
              }
              handleOnChange={this.handleOnChange}
              splitID={0}
            />
            <TimeSlider
              min={720}
              max={1440}
              width={100}
              viewOnly={viewOnly ? true : undefined}
              showSwitch={!viewOnly} // just in case we want to show switch later
              disabled={disable}
              handleDisableChange={this.handleDisableChange}
              defaultVal={
                (defaultVals && defaultVals[1] != null)
                  ? [defaultVals[1].start, defaultVals[1].end]
                  : undefined
              }
              handleOnChange={this.handleOnChange}
              splitID={1}
            />
            <div style={{ width: 30, display: viewOnly ? 'none' : 'block' }}>
              <Icon type="minus" style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { this.onAdjustSplit(false) }} />
            </div>
          </div>
        )
      }
      return null
    }

    render() {
      // const { defaultVals } = this.props
      // console.log('defaultVals: ', defaultVals)
      return this.returnSliders()
    }
}

export default DynamicTimeSlider
