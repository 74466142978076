import gql from 'graphql-tag'

export const countryCodes = gql`
  query countryCodes {
    countryCodes{
      country
      abv
      phoneCode
    }
  }
`

export const serviceValueTypes = gql`
  query serviceValueTypes {
    serviceValueTypes {
      id
      name
    }
  }
`

export const serviceTickets = gql`
  query serviceTickets {
    serviceTickets {
      id
      type {
        id
        name
      }
      details
      deviceId
      alias
      creation
      completion
      interval
      due
      cost
      details
      vehicle {
        make
        model
        year
        color
        vin
        license
      }
    }
  }
`

export const serviceTypes = gql`
  query serviceTypes {
    serviceTypes{
      id
      name
      active
    }
  }
`

export const serviceTicketStatus = gql`
  query serviceTicketStatus {
    serviceTicketStatus{
      id
      name
    }
  }
`

export const reportSchedule = gql`
  query reportSchedule {
    reportSchedule {
      id
      reportId
      name
      inputBlob
      outputBlob
      type
      deliveryStartDate
      frequency
      dateRange
      deliveryTime
      recipients
    }
  }
`

export const reportScheduleDateRanges = gql`
  query reportScheduleDateRanges {
    reportScheduleDateRanges {
      key
      title
    }
  }
`

export const reportScheduleFrequencies = gql`
  query reportScheduleFrequencies {
    reportScheduleFrequencies {
      key
      title
    }
  }
`

export const batteryReport = gql`
  query batteryReport($deviceIds: [Int]!, $reportId: Int!) {
    batteryReport(deviceIds: $deviceIds, reportId: $reportId) {
      rows {
        deviceId
        alias
        battery
        datetime
        date
        time
      }
    }
  }
`

export const tripsReport = gql`
query tripsReport($reportId: Int,$deviceIds: [Int]!, $startDateTime: String!, $endDateTime: String!) {
  tripsReport(reportId: $reportId, deviceIds: $deviceIds, startDateTime: $startDateTime, endDateTime: $endDateTime) {
    rows {
      id
      alias
      trips {
        id
        tripOrder
        startTime
        endTime
        deviceId
        alias
        distance
        drivers {
          id
          name
        }
        events {
          id
          datetime
          date
          time
          deviceId
          alias
          lat
          lng
          event
          speed
          heading
          address
          landmarks {
            id
            name
          }
          actualOdometer
          virtualOdometer
          details
        }
      }
    }
    downloadFlag
  }
}
`

export const eventsReport = gql`
  query eventsReport(
    $deviceIds: [Int]!,
    $driverIds: [Int],
    $startDateTime: String!,
    $endDateTime: String!,
    $events: [String]!,
    $landmarkIds: [Int],
    $reportId: Int!,
  ) {
    eventsReport(
      deviceIds: $deviceIds,
      driverIds: $driverIds,
      startDateTime: $startDateTime,
      endDateTime: $endDateTime,
      events: $events,
      landmarkIds: $landmarkIds,
      reportId: $reportId,
    ) {
      rows {
        deviceId
        alias
        landmarks
        datetime
        date
        time
        fob
        eventType
        drivers
        address
        speed
        mosl
        roadSpeed
        details
      }
      downloadFlag
    }
  }
`

export const stopsReport = gql`
  query stopsReport(
    $deviceIds: [Int]!,
    $driverIds: [Int],
    $startDateTime: String!,
    $endDateTime: String!,
    $landmarkIds: [Int],
    $reportId: Int!,
  ) {
    stopsReport(
      deviceIds: $deviceIds,
      driverIds: $driverIds,
      startDateTime: $startDateTime,
      endDateTime: $endDateTime,
      landmarkIds: $landmarkIds,
      reportId: $reportId,
    ) {
      rows {
        deviceId
        alias
        landmarks
        startDateTime
        endDateTime
        datetimeUnix
        fob
        drivers
        address
        duration
      }
      downloadFlag
    }
  }
`

export const idleReport = gql`
  query idleReport(
    $deviceIds: [Int]!,
    $driverIds: [Int],
    $startDateTime: String!,
    $endDateTime: String!,
    $landmarkIds: [Int],
    $reportId: Int!,
  ) {
    idleReport(
      deviceIds: $deviceIds,
      driverIds: $driverIds,
      startDateTime: $startDateTime,
      endDateTime: $endDateTime,
      landmarkIds: $landmarkIds,
      reportId: $reportId,
    ) {
      rows {
        deviceId
        alias
        landmarks
        datetime
        date
        time
        fob
        drivers
        address
        duration
      }
      downloadFlag
    }
  }
`

export const speedThresholdReport = gql`
  query speedThresholdReport(
    $deviceIds: [Int]!
    $driverIds: [Int]
    $landmarkIds: [Int]
    $startDateTime: String!
    $endDateTime: String!
    $speed: Int!,
    $reportId: Int!
  ) {
    speedThresholdReport(
      driverIds: $driverIds
      deviceIds: $deviceIds
      landmarkIds: $landmarkIds
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      speed: $speed,
      reportId: $reportId,
    ) {
      rows {
        drivers
        alias
        deviceId
        violationCount
        maxSpeed
        date
        firstOccurrence
        lastOccurrence
      }
      downloadFlag
    }
  }
`

export const afterHoursReport = gql`
  query afterHoursReport(
    $deviceIds: [Int]!,
    $driverIds: [Int]!,
    $startDateTime: String!,
    $endDateTime: String!,
    $reportId: Int!,
  ){
    afterHoursReport(
      deviceIds: $deviceIds,
      driverIds: $driverIds,
      startDateTime: $startDateTime,
      endDateTime: $endDateTime,
      reportId: $reportId,
    ){
      rows {
        deviceId
        lat
        lng
        alias
        datetime
        date
        time
        speed
        heading
        drivers
        landmarks
        address
      }
      downloadFlag
    }
  }
`

export const locationsReport = gql`
  query locationReport(
    $deviceIds: [Int]!,
    $driverIds: [Int]!,
    $landmarkIds: [Int],
    $startDateTime: String!,
    $endDateTime: String!,
    $reportId: Int!,
  ){
    locationReport(
      deviceIds: $deviceIds,
      driverIds: $driverIds,
      startDateTime: $startDateTime,
      endDateTime: $endDateTime,
      reportId: $reportId,
      landmarkIds: $landmarkIds,
    ){
      rows {
        deviceId,
        lat,
        lng,
        alias,
        datetime,
        date,
        time,
        speed,
        heading,
        cardinalHeading,
        address,
        drivers,
        landmarks
      }
      downloadFlag
    }
  }
`

export const odometerReport = gql`
query odometerReport(
  $deviceIds: [Int]!,
  $startDateTime: String!,
  $endDateTime: String!,
  $reportId: Int!,
){
  odometerReport(
    deviceIds: $deviceIds,
    startDateTime: $startDateTime,
    endDateTime: $endDateTime,
    reportId: $reportId,
  ){
    rows {
      alias
      distance
      date
      state
    }
    downloadFlag
  }
}
`

export const mileageReport = gql`
  query mileageReport(
    $deviceIds: [Int]!,
    $startDateTime: String!,
    $endDateTime: String!,
    $reportId: Int!,
  ){
    mileageReport(
      deviceIds: $deviceIds,
      startDateTime: $startDateTime,
      endDateTime: $endDateTime,
      reportId: $reportId,
    ){
      rows {
        deviceId
        alias
        distance
        date
        state
      }
      downloadFlag
    }
  }
`

export const hoursOfOperationReport = gql`
  query hoursOfOperationReport(
    $deviceIds: [Int]!,
    $driverIds: [Int]!,
    $startDateTime: String!,
    $endDateTime: String!,
    $reportId: Int!,
    $downloading: Boolean
  ) {
    hoursOfOperationReport(
      deviceIds: $deviceIds,
      driverIds: $driverIds,
      startDateTime: $startDateTime,
      endDateTime: $endDateTime,
      reportId: $reportId,
      downloading: $downloading
    )
    {
      rows {
        deviceId
        alias
        date
        firstMoveTime
        lastMoveTime
        drivers
        duration
      }
      downloadFlag
    }
  }
`

export const fuelCardMileageReport = gql`
  query fuelCardMileageReport(
    $startDateTime: String!,
    $endDateTime: String!,
    $reportId: Int!,
    $deviceIds: [Int]!,
  ){
    fuelCardMileageReport(
      startDateTime: $startDateTime,
      endDateTime: $endDateTime,
      reportId: $reportId,
      deviceIds: $deviceIds,
    ){
      rows {
        prevTransactionDate
        transactionDate
        wexCardNumber
        alias
        driver
        quantityPurchased
        milesDriven
        mpg
      }
    }
  }
`

export const fuelCardTransactionReport = gql`
  query fuelCardTransactionReport(
    $startDateTime: String!,
    $endDateTime: String!,
    $reportId: Int!,
    $deviceIds: [Int]!
  ){
    fuelCardTransactionReport(
      startDateTime: $startDateTime,
      endDateTime: $endDateTime,
      reportId: $reportId,
      deviceIds: $deviceIds
    ){
      rows {
        transactionDate
        address
        state
        zip
        unitCost
        quantityPurchased
        wexCardNumber
        driver
        total
        possibleMisuse
        fuelPurchased
        rebate
        miscellaneous
        wexTransid
        alias
      }
    }
  }
`

export const serviceTicketReport = gql`
  query serviceTicketReport ($reportId: Int!, $deviceIds: [Int]!, $typeIds: [Int]!, $startDateTime: String!, $endDateTime: String!, $serviceTicketStatusIds: [Int]!) {
    serviceTicketReport (reportId: $reportId, deviceIds: $deviceIds, typeIds: $typeIds, startDateTime: $startDateTime, endDateTime: $endDateTime, serviceTicketStatusIds: $serviceTicketStatusIds) {
      rows {
        alias
        vin
        type
        dtStart
        interval
        currentValue
        status
        notes
      }
      downloadFlag
    }
  }
`

export const engineHoursReport = gql`
  query engineHours($deviceIds:[Int]!, $startDateTime: String!, $endDateTime: String!, $reportId: Int!) {
    engineHoursReport(deviceIds: $deviceIds, startDateTime: $startDateTime, endDateTime: $endDateTime, reportId: $reportId) {
      rows {
        deviceId
        date
        duration
        alias
      }
    }
  }
`

export const dscReport = gql`
  query dscReport($driverIds: [Int], $startDateTime: String!, $endDateTime: String!, $reportId: Int!, $rankSortOrder: RankSortEnum) {
    dscReport(driverIds: $driverIds, startDateTime: $startDateTime, endDateTime: $endDateTime, reportId: $reportId, rankSortOrder: $rankSortOrder) {
      rows {
        rank
        name
        hardBrake
        fastStart
        speeding
        driveTime
      }
    }
  }
`

/**
 * @TODO
 * Include all meta fields
 */
export const reportMetaData = gql`
  query getReportMeta {
    reportMeta {
      id
      title
      inputBlob
      outputBlob
      category
      type
    }
  }
`

export const loginQuery = gql`
  query loginQuery($username: String!, $password: String!) {
    loginBcrypt(username: $username, password: $password) {
      token
      resultCode
      description
      loginMessage
    }
  }
`

export const loginToV3VerifyQuery = gql`
  query loginToV3VerifyQuery($contactId: Int!, $randomToken: String!) {
    loginToV3FromExternalVerify(contactId: $contactId, randomToken: $randomToken) {
      token
      resultCode
    }
  }
`

export const deviceQuery = gql`
query deviceQuery{
  devices_v3{
    id
    client
    unit {
      id
      name
      abbr
    }
    camera{
      id
      vehicleId
      status
      data
      liveStreamURL
    }
    productKey
    alias
    imei
    typeId
    type
    phoneNumber
    simSerial
    simCardNumber
    firmwareId
    firmware
    networkId
    network
    networkProviderId
    networkProvider
    warrantyDate
    warranty
    isActive
    stopMinutes
    speedThreshold
    status {
      state
      stoppedMinutes
    }
    starterInterrupt
    icon{
      type
      color
      text
    }
    locate{
      latitude
      longitude
      speed
      heading
      date
      time
      datetimeUTC
      landmarks {
        id
        name
      }
      address {
        full
        street_address
        city
        state
        zip
      }
    }
    address{
      full
      street_address
      city
      state
      zip
    }
    driver {
      id
      name
      firstName
      lastName
    }
    currentDriver {
      id
      name
      firstName
      lastName
    }
    groups{
      id
      name
      color
    }
    labels{
    	id
      name
      color
    }
    vehicle{
      id
      vin
      color
      make
      model
      year
      license
    },
    fobEnabled
    garmin
    timelineRange
  }
}`


export const deviceLocatesQuery = gql`
query deviceLocatesQuery{
  devices_v3_meta{
    id
    alias
    camera{
      id
      vehicleId
      status
      data
      liveStreamURL
    }
    status {
      state
      stoppedMinutes
    }
    locate{
      latitude
      longitude
      speed
      heading
      date
      time
      datetimeUTC
      landmarks {
        id
        name
      }
      address{
        full
        street_address
        city
        state
        zip
      }
    }
  }
}`

export const companyGroupQuery = gql`
query companyGroupQuery($contactID: Int) {
	group(contactId : $contactID){
    id
    name
    color
  }
}`

export const contactToAssignGroupQuery = gql`
query contactToAssignGroupQuery{
  contactToAssignGroup{
    id
    email
    firstName
    lastName
    role {
      id
    }
    groups{
      id
      name
      color
    }
  }
}`

export const preferenceQuery = gql`
query preferenceQuery {
  preference {
		email
		password
		firstName
		lastName
    mobilePhone
    textAddress
		language
		distanceMeasurement
    timeZoneId
    mapRefresh
    country
  }
}`

export const phoneCarriersQuery = gql`
query phoneCarriersQuery {
  phoneCarriers{
    id
    name
    suffix
  }
}
`

export const contactQuery = gql`
query contactQuery{
  contacts{
      id
      firstName
      lastName
      email
      password
      mobilePhone
      role{
          id
      }
      groups{
        id
        name
        color
      }
      isActive
  }
}`

export const roleToCapQuery = gql`
query roleToCapQuery{
	rolesToCapability{
		capability
		ynCreate
		ynRead
		ynUpdate
		ynDelete
	}
}
`

export const getAllPersonalLabels = gql`
query getAllPersonalLabels{
  label{
      id
      name
      color
  }
}
`

export const getUserAlertSettings = gql`
query alertSettingGet($numEventTypeIds: [Int]){
  alertSettings(numEventTypeIds:$numEventTypeIds){
    alert_id
    numEventTypeId
    numVehicleDeviceId
    numMOSL
    numSpeed
    txtEventType
    txtAlias
    ynText
    ynEmail
    ynPush
    ynApp
    landmarks{
      id
      name
      direction
    }
    hoo{
      mon{
        start
        end
      }
      tue{
        start
        end
      }
      wed{
        start
        end
      }
      thu{
        start
        end
      }
      fri{
        start
        end
      }
      sat{
        start
        end
      }
      sun{
        start
        end
      }
    }
  }
}
`

export const getVehicleTypes = gql`
query getVehicleTypes{
  makes{
    id
    name
    models{
      id
      name
    }
  }
}
`

export const featuresQuery = gql`
query getFeatures{
  features{
    featureId
    nameId
    name
    isActive
  }
}
`

export const driversQuery = gql`
  query drivers {
    driver {
      id
      name
      firstName
      lastName
      dotNumber
      fob
      startTime
      endTime
      device {
        id
        alias
      }
      wexCard
    }
  }
`

export const assignedDevicesQuery = gql`
query assignedDevicesQuery{
  assignedDevicesQuery{
    id
    alias
    driver{
      id
      firstName
      lastName
      fob
      dotNumber
    }
    fobEnabled
  }
}
`

export const getTimeZones = gql`
  query getTimeZones {
    getTimeZones {
      id
      name
    }
  }
`

export const getLandmarks = gql`
query getLandmarks{
  landmarks{
    id
    name
    clientID
    contactID
    points {
      lat
      lng
    }
    type
    radius
    center{
      lat
      lng
    }
    billable
    notes
    destinationAddress
  }
}
`

export const DSCQuery = gql`
query getDSC($weekCount: Int!, $endDate: String!) {
  driverScorecards(weekCount: $weekCount, endDate: $endDate) {
    startDate
    endDate
    hardBrake
    fastStart
    speeding
    speed10
    speed15
    speed20
    speed30
    speed40
    driveTime
    max {
      hardBrake
      fastStart
      speeding
    }
    drivers {
      id
      name
      performance
      rank
      hardBrake
      fastStart
      speeding
      speed10
      speed15
      speed20
      speed30
      speed40
      driveTime
      dateSegments {
        startDate
        endDate
        performance
        rank
        hardBrake
        fastStart
        speeding
        speed10
        speed15
        speed20
        speed30
        speed40
        driveTime
      }
    }
  }
}
`

export const RelativeDSCQuery = gql`
query getDSCStatic($range: String!) {
  relativeDriverScorecards(range: $range) {
    startDate
    endDate
    hardBrake
    fastStart
    speeding
    speed5
    speed10
    speed15
    speed20
    speed30
    speed40
    driveTime
    max {
      hardBrake
      fastStart
      speeding
    }
    drivers {
      id
      name
      performance
      rank
      hardBrake
      fastStart
      speeding
      speed5
      speed10
      speed15
      speed20
      speed30
      speed40
      driveTime
      dateSegments {
        startDate
        endDate
        performance
        rank
        hardBrake
        fastStart
        speeding
        speed5
        speed10
        speed15
        speed20
        speed30
        speed40
        driveTime
      }
    }
  }
}
`

export const eventTypes = gql`
query eventTypes{
	eventTypes{
    id
    displayName
    queryName
    description
  }
}
`

export const iconColors = gql`
query iconColors{
  iconColors {
    id
    name
    hex
  }
}
`

export const iconTypes = gql`
query iconTypes{
  iconTypes {
    id
    name
    displayName
  }
}
`

export const locateNowQuery = gql`
query locateNow($id: Int){
  locateNow(id: $id){
		code
    description
  }
}
`

export const locateNowStatus = gql`
query locateNowStatus($id: Int){
  locateNowStatus(id: $id)
}
`

export const timelineTrips = gql`
query timelineTrips($reportId: Int,$deviceIds: [Int]!, $startDateTime: String!, $endDateTime: String!, $withLocates: Int) {
  tripsReport(reportId: $reportId, deviceIds: $deviceIds, startDateTime: $startDateTime, endDateTime: $endDateTime, withLocates: $withLocates) {
    rows {
      id
      alias
      trips {
        id
        tripOrder
        startTime
        endTime
        endAddress
        endLandmarks
        deviceId
        alias
        duration
        topSpeed
        distance
        timeToNext
        distance
        drivers {
          id
          name
        }
        events {
          id
          tripId
          datetime
          date
          time
          deviceId
          alias
          lat
          lng
          event
          speed
          heading
          address
          landmarks {
            id
            name
          }
          videoClip {
            id
            replayURL
            favorite
            viewed
            status
          }
          actualOdometer
          virtualOdometer
          details
        }
      }
    }
  }
}
`

export const allEventsLocates = gql`
query allEventsLocates($deviceId: Int!, $endDateTime: String!){
  allEventsLocates(deviceId: $deviceId, endDateTime: $endDateTime){
    id
    alias
    trips {
      id
      tripOrder
      startTime
      endTime
      startDate
      endDate
      endAddress
      endLandmarks
      deviceId
      alias
      duration
      topSpeed
      distance
      timeToNext
      drivers {
        id
        name
      }
      events {
        id
        tripId
        datetime
        date
        time
        deviceId
        alias
        lat
        lng
        event
        speed
        heading
        address
        landmarks {
          id
          name
        }
        videoClip {
          id
          replayURL
          favorite
          viewed
          status
        }
        actualOdometer
        virtualOdometer
        details
      }
    }
  }
}
`

export const moslQuery = gql`
query mosl {
  mosl
}
`
export const cameraClipsQuery = gql`
query videoClips($deviceIds: [Int]!) {
  videoClips(deviceIds: $deviceIds){
    id
    datetime
    date
    time
    eventType
    status
    replayURL
    favorite
    viewed
    deviceId
    address
    landmarks {
      name
    }
    drivers
    eventDetails
  }
}
`

export const logisticsMessageQuery = gql`
query logisticsMessage($deviceId:Int!){
  logisticsMessage(deviceId:$deviceId){
    id
    deviceId
    content
    createdDate
    origin
    read
    deliveredDate
  }
 }
`
export const logisticsJobQuery = gql`
query logisticsJob($id: Int ) {
  logisticsJob (id: $id) {
    id
    alias
    status
    notes
    deviceId
    createdDate
    dueDate
    completionDate
    destinations {
      id
      label
      jobId
      address
      coordinates
      sequence
      status
      createdDate
      completionDate
      tasks {
        id
        destinationId
        alias
        type
        status
        notes
        email
        createdDate
        completionDate
      }
    }
  }
}
`

export const contactMetaQuery = gql`
  query contactMeta {
    contactMeta {
      contactId
      clientId
      minuteTZOffset
      listDensity
      mapIconDensity
      mapIconSize
    }
  }
`

export const customBrandingQuery = gql`
  query customBranding {
    customBranding {
      logo
    }
  }
`

export const vinExists =  gql`
  query vinExists($vin: String) {
    vinExists(vin: $vin)
  }
`
