import React from 'react'
import PropTypes from 'prop-types'
import Map from '@mol/map'
import LandmarksMap from '@mol/landmarksMap'
import { Button } from 'antd'
import LandmarksDrawer from '../rightDrawer/landmarksDrawer'
// import DeviceMap from '@mol/deviceMap'
// import TimelineView from '@org/timelineView'
import RightContainer from './right.container'


const styles = {
  container: {
    display: 'flex',
    flex: 1,
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
  },
  timelineContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: 4,
    backgroundColor: '#367',
  },
}

class LandmarksRightContainer extends React.Component {
  static propTypes = {
    devices: PropTypes.array,
    mapSettings: PropTypes.object,
    updateMapSettings: PropTypes.func,
    landmarks: PropTypes.array,
    selectedLandmark: PropTypes.object,
    selectLandmark: PropTypes.func.isRequired,
    unselectLandmark: PropTypes.func.isRequired,
    setIfNewLandmark: PropTypes.func.isRequired,
    switchDrawingOverlay: PropTypes.func.isRequired,
    ifNewLandmark: PropTypes.bool.isRequired,
    overlayType: PropTypes.string.isRequired,
    updateLandmark: PropTypes.func.isRequired,
    createLandmark: PropTypes.func.isRequired,
    wMatrix: PropTypes.func.isRequired,
    deleteLandmark: PropTypes.func.isRequired,
    landmarksLoading: PropTypes.bool.isRequired,
    landmarksRefetch: PropTypes.func.isRequired,
    savingLandmark: PropTypes.bool,
    deletingLandmark: PropTypes.bool,
    updateSelectedLandmarkCenter: PropTypes.func,
    selectedLandmarkCenter: PropTypes.object,
    findLandmarkCenter: PropTypes.func.isRequired,
    polygonCentroid: PropTypes.func.isRequired,
    /** @Main */
    mainNav: PropTypes.string.isRequired,
    selectModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    devices: [],
    landmarks: [],
    mapSettings: {},
    selectedLandmark: null,
    updateMapSettings: () => {},
    savingLandmark: false,
    deletingLandmark: false,
    updateSelectedLandmarkCenter: null,
    selectedLandmarkCenter: null,
  }

  state = {
    editedLandmark: null,
    zoomPoint: null,
  }

  zoomToPoint = (coords) => {
    this.setState({ zoomPoint: coords })
  }

  /**
   * @public
   */
  editLandmark = (editedLandmark) => {
    this.setState({ editedLandmark })
  }

  /**
   * public
   */
  saveLandmark = (type, name, id, billable, address, notes) => {
    const { editedLandmark } = this.state
    const { updateLandmark, createLandmark } = this.props
    if (id === null) {
      createLandmark(
        type,
        name,
        (editedLandmark.center ? editedLandmark.center : editedLandmark.points),
        editedLandmark.radius ? editedLandmark.radius : null,
        billable,
        address,
        notes,
      )
    } else {
      updateLandmark(
        id,
        type,
        name,
        (editedLandmark.center ? editedLandmark.center : editedLandmark.points),
        (editedLandmark.radius ? editedLandmark.radius : null),
        billable,
        address,
        notes,
      )
    }

    this.setState({ editedLandmark: null })
  }

  cancelEdit = () => {
    const { setIfNewLandmark, unselectLandmark } = this.props
    unselectLandmark()
    setIfNewLandmark(false)
  }

  renderLandmarkMap = (initMap, fitBounds) => {
    const {
      landmarks, devices, selectedLandmark, selectLandmark, unselectLandmark,
      overlayType, ifNewLandmark, setIfNewLandmark, updateSelectedLandmarkCenter,
      findLandmarkCenter, polygonCentroid,
    } = this.props
    const {
      editedLandmark, zoomPoint,
    } = this.state
    return (
      <LandmarksMap
        initMap={initMap}
        fitBounds={fitBounds}
        landmarks={landmarks}
        devices={devices}
        overlayType={overlayType}
        editLandmark={this.editLandmark}
        selectLandmark={selectLandmark}
        unselectLandmark={unselectLandmark}
        selectedLandmark={selectedLandmark}
        editedLandmark={editedLandmark}
        ifNewLandmark={ifNewLandmark}
        setIfNewLandmark={setIfNewLandmark}
        zoomPoint={zoomPoint}
        zoomToPoint={this.zoomToPoint}
        updateSelectedLandmarkCenter={updateSelectedLandmarkCenter}
        findLandmarkCenter={findLandmarkCenter}
        polygonCentroid={polygonCentroid}
      />
    )
  }

  renderLandmarkView = () => {
    const {
      mapSettings,
      updateMapSettings,
      selectModal,
    } = this.props

    return (
      <div key="s1" style={styles.contentContainer}>
        <Map
          settings={mapSettings}
          updateSettings={updateMapSettings}
          renderMap={this.renderLandmarkMap}
          selectModal={selectModal}
          landmarkToggle={false}
          clusteringToggle={false}
          animationToggle={false}
        />
      </div>
    )
  }

  renderLandmarksLiveView = () => {
    const {
      mapSettings,
      updateMapSettings,
      selectedLandmark,
      wMatrix,
      selectLandmark,
      deleteLandmark,
      updateLandmark,
      createLandmark,
      landmarksLoading,
      landmarksRefetch,
      ifNewLandmark,
      switchDrawingOverlay,
      savingLandmark,
      deletingLandmark,
      mainNav,
      selectModal,
      selectedLandmarkCenter,
    } = this.props

    return (
      <div style={styles.contentContainer}>
        <div style={{ flexGrow: 1, position: 'relative' }}>
          <div style={{ display: 'flex', height: '100%', background: 'none' }}>
            <Map
              settings={mapSettings}
              updateSettings={updateMapSettings}
              renderMap={this.renderLandmarkMap}
              selectModal={selectModal}
              landmarkToggle={false}
              clusteringToggle={false}
              animationToggle={false}
            />
          </div>
          <LandmarksDrawer
            mainNav={mainNav}
            show={!!(selectedLandmark || ifNewLandmark)}
            setDrawType={switchDrawingOverlay}
            selectedLandmark={selectedLandmark || undefined}
            landmarksLoading={landmarksLoading}
            landmarksRefetch={landmarksRefetch}
            selectLandmark={selectLandmark}
            deleteLandmark={deleteLandmark}
            updateLandmark={updateLandmark}
            createLandmark={createLandmark}
            wMatrix={wMatrix}
            cancelEdit={this.cancelEdit}
            saveLandmark={this.saveLandmark}
            zoomToPoint={this.zoomToPoint}
            ifNewLandmark={ifNewLandmark}
            savingLandmark={savingLandmark}
            deletingLandmark={deletingLandmark}
            selectedLandmarkCenter={selectedLandmarkCenter}
          />
        </div>
      </div>
    )
  }

  /**
   * @public
   * save landmark
   */

  render() {
    const { selectedLandmark } = this.props
    const { ifNewLandmark } = this.state
    const tabs = {
      small: [{
        id: 's1',
        name: 'Landmarks',
      }],
      large: [{
        id: 'l1',
        name: 'Landmarks',
      }],
    }

    return (
      <div style={styles.container}>
        <div style={{
          position: 'fixed', top: 0, left: 0, zIndex: 20,
        }}
        >
          {ifNewLandmark ? <Button onClick={() => this.switchDrawingOverlay('CIRCLE')}>circle</Button> : null}
          {ifNewLandmark ? <Button onClick={() => this.switchDrawingOverlay('POLYGON')}>poly</Button> : null}
          {ifNewLandmark ? <Button onClick={() => this.switchDrawingOverlay('RECTANGLE')}>rect</Button> : null}
        </div>
        <RightContainer
          headerMode={selectedLandmark || ifNewLandmark ? 'large' : 'small'}
          title={selectedLandmark ? selectedLandmark.name : ''}
          onClose={this.cancelEdit}
          defaultTabKey={selectedLandmark || ifNewLandmark ? 'l1' : 's1'}
          renderHeaderDetails={() => null}
          onSettingsClick={this.showManager}
          tabs={tabs}
        >
          {this.renderLandmarksLiveView()}
        </RightContainer>
      </div>
    )
  }
}

export default LandmarksRightContainer
