import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button } from 'antd'
import ToggleCard from '@atom/toggleCard'
import NumberInputCard from '@atom/numberInputCard'
import LandmarksTable from '@mol/notificationSettings/landmarksTable'
import WeekSliderCard from '@mol/notificationSettings/weekSliderCard'
import equal from 'deep-equal'
import clone from 'clone'
import ButtonWrapper from '@atom/textButton'

class NotificationEditPanel extends Component {
  static propTypes = {
    checkedDevices: PropTypes.array,
    milesOverValue: PropTypes.number,
    handleCancelClick: PropTypes.func.isRequired,
    eventTypeIds: PropTypes.array.isRequired,
    currentSelection: PropTypes.string.isRequired,
    notifications: PropTypes.object.isRequired,
    updateGeneralAlertSettings: PropTypes.func.isRequired,
    handleSaveClick: PropTypes.func.isRequired,
    alertSettings: PropTypes.object.isRequired,
    getPermission: PropTypes.func.isRequired,
    wMatrix: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    alertSettingsData: PropTypes.object,
    setIfShowAlert: PropTypes.func.isRequired,
    numMOSL: PropTypes.number.isRequired,
  }

  static defaultProps = {
    checkedDevices: [],
    milesOverValue: 10,
    editMode: false,
    alertSettingsData: null,
  }

  state = {
    viewMoreOff: true,
    // preset toggle states are used to track changes
    presetEmail: false,
    presetText: false,
    presetPush: false,
    // notification media toggles
    emailToggle: false,
    textToggle: false,
    pushToggle: false,
    // notification setting states
    applyMOSLToggle: false,
    speedThreshVal: 0,
    applyReconn: false,
    applyDisconn: false,
    applyAccel: false,
    applyDecel: false,
    applyMoveInitiate: false,
    applyMoveConclude: false,
    primOn: false,
    primOff: false,
    secondOn: false,
    secondOff: false,
    HOOObj: {
      mon: [{ start: 0, end: 1440 }, { start: null, end: null }],
      tue: [{ start: 0, end: 1440 }, { start: null, end: null }],
      wed: [{ start: 0, end: 1440 }, { start: null, end: null }],
      thu: [{ start: 0, end: 1440 }, { start: null, end: null }],
      fri: [{ start: 0, end: 1440 }, { start: null, end: null }],
      sat: [{ start: 0, end: 1440 }, { start: null, end: null }],
      sun: [{ start: 0, end: 1440 }, { start: null, end: null }],
    },
    ifSaving: false,
    // for landmark alert
    landmarkDetails: [],
  }

  componentDidUpdate = (prevProps) => {
    const { checkedDevices } = this.props
    /** To preselect email/text/push if the selected devices have the same settings */
    if (!equal(prevProps.checkedDevices, checkedDevices) && checkedDevices.length > 0) {
      this.presetSetState(checkedDevices)
    }
  }


  viewMoreClick = () => {
    const { viewMoreOff } = this.state
    if (viewMoreOff === true) this.setState({ viewMoreOff: false })
    else this.setState({ viewMoreOff: true })
  }

  /**
   * @description Renders text list of devices being edited. Can be expanded.
   */
  returnEditingList = () => {
    // let listArray = []
    const { checkedDevices, wMatrix } = this.props
    const { viewMoreOff } = this.state
    const remainder = checkedDevices.length - 5
    const list = []

    for (let i = 0; i < checkedDevices.length; i += 1) {
      if (i < 5) {
        list.push(<div key={i}>{checkedDevices[i].alias}</div>)
      } else {
        list.push(<div key={i} style={{ display: viewMoreOff ? 'none' : 'block' }}>{checkedDevices[i].alias}</div>)
      }
    }
    return (
      <div style={{ marginLeft: '20px' }}>
        {list}
        <div style={{ marginLeft: '20px', display: (checkedDevices.length > 5) ? 'flex' : 'none' }}>
          {viewMoreOff ? `and ${remainder} more.` : ''}
          <ButtonWrapper
            useAsWrapper
            onClick={this.viewMoreClick}
          >
            <label style={{ marginLeft: 5, color: '#4595FF' }}>{viewMoreOff ? wMatrix('viewAll') : wMatrix('close')}</label>
          </ButtonWrapper>
        </div>
      </div>
    )
  }

  toggleState = (stateProp) => {
    this.setState(prevState => ({ [stateProp]: !prevState[stateProp] }))
  }

  onChangeSpeedThresh = (value) => {
    this.setState({ speedThreshVal: value })
  }

  onChangeHOO = (dayId, values) => {
    const { HOOObj } = this.state
    const HoursObj = HOOObj
    HoursObj[dayId] = values
    this.setState({ HOOObj: HoursObj })
  }

  /** Toggles disable state for a given day */
  toggleDisableDay = (dayId) => {
    const { HOOObj } = this.state
    const HoursObj = clone(HOOObj)
    if (HoursObj[dayId][0].start === null && HoursObj[dayId][0].end === null) {
      // enable
      HoursObj[dayId] = [{ start: 0, end: 1440 }, { start: null, end: null }]
    } else {
      HoursObj[dayId] = [{ start: null, end: null }, { start: null, end: null }]
    }
    this.setState({ HOOObj: HoursObj })
  }

  resetAndCancel = () => {
    const { handleCancelClick } = this.props
    handleCancelClick()
    this.resetEditPanel()
  }

  updateLandmarkDetails = (landmark) => {
    const { landmarkDetails } = this.state
    const landmarkDetailsClone = clone(landmarkDetails)
    let ifFound = false
    for (let i = 0; i < landmarkDetailsClone.length; i += 1) {
      if (landmarkDetailsClone[i].id === landmark.id) {
        landmarkDetailsClone[i] = landmark
        ifFound = true
      }
    }
    if (!ifFound) landmarkDetailsClone.push(landmark)
    this.setState({ landmarkDetails: landmarkDetailsClone })
  }

  resetEditPanel = () => {
    this.setState({
      emailToggle: false,
      textToggle: false,
      pushToggle: false,
      applyMOSLToggle: false,
      speedThreshVal: 0,
      applyReconn: false,
      applyDisconn: false,
      applyAccel: false,
      applyDecel: false,
      primOn: false,
      primOff: false,
      secondOff: false,
      secondOn: false,
      landmarkDetails: [],
      HOOObj: {
        mon: [], tue: [], wed: [], thu: [], fri: [], sat: [], sun: [],
      },
    })
  }

  getCorrectedIds = () => {
    const { eventTypeIds } = this.props
    const {
      applyAccel, applyDecel,
      applyReconn, applyDisconn,
      applyMoveInitiate, applyMoveConclude,
      primOn, primOff, secondOn, secondOff,
      applyMOSLToggle, speedThreshVal,
    } = this.state
    // check if event types is correct
    let checkedEventTypeIds = []
    if (eventTypeIds.length > 1) {
      if (eventTypeIds.includes(14) && (applyAccel !== applyDecel)) {
        // accel/decel
        checkedEventTypeIds.push(applyAccel ? 14 : 15)
      } else if (eventTypeIds.includes(20) && (applyMoveInitiate !== applyMoveConclude)) {
        // movement initiated/concluded
        checkedEventTypeIds.push(applyMoveInitiate ? 20 : 27)
      } else if (eventTypeIds.includes(21) && (applyReconn !== applyDisconn)) {
        // reconn/disconn
        checkedEventTypeIds.push(applyReconn ? 21 : 3)
      } else if (eventTypeIds.includes(37)) {
        // primaryOnOff and secondary
        if ((primOn && this.primOff && secondOn && secondOff)
          || (!primOn && !primOff && !secondOn && !secondOff)
        ) {
          checkedEventTypeIds.push(37, 38, 39, 40)
        } else {
          if (primOn) checkedEventTypeIds.push(37)
          if (primOff) checkedEventTypeIds.push(38)
          if (secondOn) checkedEventTypeIds.push(39)
          if (secondOff) checkedEventTypeIds.push(40)
        }
      } else if (eventTypeIds.includes(1)) {
        // speed thresh and MOSL
        if ((applyMOSLToggle && speedThreshVal)) {
          checkedEventTypeIds.push(1, 30)
        } else if (applyMOSLToggle && !speedThreshVal) {
          checkedEventTypeIds.push(30)
        } else if (!applyMOSLToggle && speedThreshVal) {
          checkedEventTypeIds.push(1)
        } else {
          checkedEventTypeIds.push(1, 30)
        }
      } else {
        checkedEventTypeIds = eventTypeIds
      }
    } else {
      checkedEventTypeIds = eventTypeIds
    }
    return checkedEventTypeIds
  }

  handleSave = () => {
    const {
      checkedDevices, currentSelection, notifications,
      updateGeneralAlertSettings, handleSaveClick, alertSettingsData,
      setIfShowAlert,
    } = this.props
    const {
      emailToggle, textToggle, pushToggle, appToggle, applyMOSLToggle,
      speedThreshVal, landmarkDetails, HOOObj,
    } = this.state
    const deviceIds = []
    if (checkedDevices.length > 0) {
      for (let i = 0; i < checkedDevices.length; i += 1) {
        deviceIds.push(checkedDevices[i].id)
      }
    }
    this.setState({ ifSaving: true })
    // check if event types is correct
    const checkedEventTypeIds = this.getCorrectedIds()
    updateGeneralAlertSettings(
      deviceIds,
      checkedEventTypeIds,
      emailToggle,
      textToggle,
      pushToggle,
      appToggle,
      speedThreshVal > 0 ? speedThreshVal : null,
      applyMOSLToggle || null,
      (landmarkDetails.length > 0) ? landmarkDetails : null,
      (notifications[currentSelection].eventTypeIds.includes(5)) ? HOOObj : null,
    ).then((response) => {
      if (response) {
        alertSettingsData.refetch().then(() => {
          handleSaveClick()
          this.resetEditPanel()
          this.setState({ ifSaving: false })
          setIfShowAlert(true, 'success')
          setTimeout(() => {
            setIfShowAlert(false)
          }, 5000)
        })
      } else {
        this.setState({ ifSaving: false })
        setIfShowAlert(true, 'error')
        setTimeout(() => {
          setIfShowAlert(false)
        }, 5000)
      }
    })
  }

  /**
   * @private
   * @description Governs whether the save button is disabled or not.
   */
  disableSaveButton = () => {
    const { eventTypeIds, checkedDevices } = this.props
    const {
      presetEmail, presetText, presetPush, presetApp,
      emailToggle, textToggle, pushToggle, appToggle,
      applyReconn, applyDisconn,
      applyAccel, applyDecel,
      primOn, primOff, secondOn, secondOff,
      applyMoveInitiate, applyMoveConclude,
      applyMOSLToggle, speedThreshVal,
    } = this.state
    let ifAdditionalOptionsSelected = false
    // True if at least one alert type is selected or at least one alert type has changed
    const ifAlertTypeSatisfied = (
      !!(emailToggle || textToggle || pushToggle || appToggle)
      || (
        presetEmail !== emailToggle
        || presetText !== textToggle
        || presetPush !== pushToggle
        || presetApp !== appToggle
      )
      || checkedDevices.length > 1
    )
    if (eventTypeIds.includes(5)) {
      // HOO
      ifAdditionalOptionsSelected = true
    } else if (eventTypeIds.includes(3)) {
      // Reconnect/Disconnect
      ifAdditionalOptionsSelected = !!(applyReconn + applyDisconn)
    } else if (eventTypeIds.includes(14)) {
      // Accel/Decel
      ifAdditionalOptionsSelected = !!(applyAccel + applyDecel)
    } else if (eventTypeIds.includes(37)) {
      // PTO
      ifAdditionalOptionsSelected = !!(primOn + primOff + secondOn + secondOff)
    } else if (eventTypeIds.includes(27)) {
      // Movement start/end
      ifAdditionalOptionsSelected = !!(applyMoveInitiate + applyMoveConclude)
    } else if (eventTypeIds.includes(30)) {
      // Speed MOSL
      ifAdditionalOptionsSelected = !!(applyMOSLToggle + !!speedThreshVal)
    } else {
      // no need for additional options: set to true
      ifAdditionalOptionsSelected = true
    }
    return (ifAlertTypeSatisfied + ifAdditionalOptionsSelected) === 1
  }

  // return the correct toggles/inputs based on selected alerts
  returnSpecificFields = () => {
    const {
      currentSelection, notifications,
      getPermission, wMatrix, numMOSL,
    } = this.props
    const {
      applyMOSLToggle,
      applyReconn, applyDisconn,
      applyAccel, applyDecel,
      applyMoveInitiate, applyMoveConclude,
      primOff, primOn, secondOff, secondOn,
      speedThreshVal, HOOObj,
    } = this.state
    const editable = getPermission('DeviceSettings', 'ynUpdate')
    return (
      <div>
        { // MOSL and speed threshold
          notifications[currentSelection].eventTypeIds[0] === 30
            ? (
              <>
                <div style={{ marginBottom: '40px' }}>
                  <h3>
                    {`${wMatrix('milesOverPostedSpeed')}: `}
                  </h3>
                  <div style={{ marginLeft: '20px' }}>
                    <div>
                      {`${wMatrix('MOCurrentlySet1')} `}
                      {!numMOSL ? 10
                        : numMOSL}
                      {` ${wMatrix('MOCurrentlySet2')} `}
                    </div>
                    <ToggleCard titleVal={wMatrix('applyToSelectedVehicles')} toggleVal={applyMOSLToggle} toggleClick={() => this.toggleState('applyMOSLToggle')} />
                  </div>
                </div>
                <div style={{ marginBottom: '40px' }}>
                  <h3>
                    {`${wMatrix('deviceSpeedThreshold')} `}
                  </h3>
                  <div style={{ marginLeft: '20px' }}>
                    <div style={{ display: editable ? 'block' : 'none' }}>
                      {`${wMatrix('leaveBlankOrZero')} `}
                    </div>
                    <NumberInputCard
                      onChangeVal={this.onChangeSpeedThresh}
                      titleVal={wMatrix('notifyMeSpeedThresh1')}
                      titleVal2={wMatrix('notifyMeSpeedThresh2')}
                      disable={!editable}
                      value={speedThreshVal || null}
                      {...this.props}
                    />
                  </div>
                </div>
              </>
            ) : null
        }

        { // After hour
          (notifications[currentSelection].eventTypeIds.includes(5) && editable)
            ? (
              <div style={{ marginBottom: '40px' }}>
                <h3>
                  {`${wMatrix('adjustHours')} `}
                </h3>
                <div style={{ marginLeft: '20px' }}>
                  <WeekSliderCard
                    toggleDisableDay={this.toggleDisableDay}
                    onChangeHours={this.onChangeHOO}
                    mon={HOOObj.mon}
                    tue={HOOObj.tue}
                    wed={HOOObj.wed}
                    thu={HOOObj.thu}
                    fri={HOOObj.fri}
                    sat={HOOObj.sat}
                    sun={HOOObj.sun}
                    wMatrix={wMatrix}
                    viewOnly={!editable}
                  />
                </div>
              </div>
            )
            : null
        }

        { // Reconnect
          notifications[currentSelection].eventTypeIds.includes(21)
            ? (
              <div style={{ marginBottom: '40px' }}>
                <h3>
                  {`${wMatrix('Reconnect')}: `}
                </h3>
                <div style={{ marginLeft: '20px' }}>
                  <ToggleCard titleVal={wMatrix('applyToSelectedVehicles')} toggleVal={applyReconn} toggleClick={() => this.toggleState('applyReconn')} />
                </div>
              </div>
            )
            : null
        }

        { // Disconnect
          notifications[currentSelection].eventTypeIds.includes(3)
            ? (
              <div style={{ marginBottom: '40px' }}>
                <h3>
                  {`${wMatrix('Disconnect')}: `}
                </h3>
                <div style={{ marginLeft: '20px' }}>
                  <ToggleCard titleVal={wMatrix('applyToSelectedVehicles')} toggleVal={applyDisconn} toggleClick={() => this.toggleState('applyDisconn')} />
                </div>
              </div>
            )
            : null
        }

        { // Acceleration
          notifications[currentSelection].eventTypeIds.includes(14)
            ? (
              <div style={{ marginBottom: '40px' }}>
                <h3>
                  {`${wMatrix('Acceleration')}: `}
                </h3>
                <div style={{ marginLeft: '20px' }}>
                  <ToggleCard titleVal={wMatrix('applyToSelectedVehicles')} toggleVal={applyAccel} toggleClick={() => this.toggleState('applyAccel')} />
                </div>
              </div>
            )
            : null
        }

        { // Deceleration
          notifications[currentSelection].eventTypeIds.includes(15)
            ? (
              <div style={{ marginBottom: '40px' }}>
                <h3>
                  {`${wMatrix('Deceleration')}: `}
                </h3>
                <div style={{ marginLeft: '20px' }}>
                  <ToggleCard titleVal={wMatrix('applyToSelectedVehicles')} toggleVal={applyDecel} toggleClick={() => this.toggleState('applyDecel')} />
                </div>
              </div>
            )
            : null
        }

        { // Primary PTO
          notifications[currentSelection].eventTypeIds.includes(37)
            ? (
              <div style={{ marginBottom: '40px' }}>
                <h3>
                  {`${wMatrix('PrimaryPtoSwitch')} `}
                </h3>
                <div style={{ marginLeft: '20px' }}>
                  <ToggleCard titleVal={wMatrix('notifSwitchOn')} toggleVal={primOn} toggleClick={() => this.toggleState('primOn')} />
                  <ToggleCard titleVal={wMatrix('notifSwitchOff')} toggleVal={primOff} toggleClick={() => this.toggleState('primOff')} />
                </div>
              </div>
            )
            : null
        }

        { // Secondary PTO
          notifications[currentSelection].eventTypeIds.includes(39)
            ? (
              <div style={{ marginBottom: '40px' }}>
                <h3>
                  {`${wMatrix('SecondaryPtoSwitch')} `}
                </h3>
                <div style={{ marginLeft: '20px' }}>
                  <ToggleCard titleVal={wMatrix('notifSwitchOn')} toggleVal={secondOn} toggleClick={() => this.toggleState('secondOn')} />
                  <ToggleCard titleVal={wMatrix('notifSwitchOff')} toggleVal={secondOff} toggleClick={() => this.toggleState('secondOff')} />
                </div>
              </div>
            )
            : null
        }

        { // Landmarks
          notifications[currentSelection].eventTypeIds.includes(6)
            ? (
              <div style={{ marginBottom: '40px' }}>
                <div style={{ marginLeft: '20px' }}>
                  <LandmarksTable
                    updateLandmarkDetails={this.updateLandmarkDetails}
                    {...this.props}
                  />
                </div>
              </div>
            )
            : null
        }

        { // Movement Initiated
          notifications[currentSelection].eventTypeIds.includes(20)
            ? (
              <div style={{ marginBottom: '40px' }}>
                <h3>
                  {`${wMatrix('Movement Initiated')}: `}
                </h3>
                <div style={{ marginLeft: '20px' }}>
                  <ToggleCard titleVal={wMatrix('applyToSelectedVehicles')} toggleVal={applyMoveInitiate} toggleClick={() => this.toggleState('applyMoveInitiate')} />
                </div>
              </div>
            )
            : null
        }

        { // Movement Concluded
          notifications[currentSelection].eventTypeIds.includes(27)
            ? (
              <div style={{ marginBottom: '40px' }}>
                <h3>
                  {`${wMatrix('Movement Concluded')}: `}
                </h3>
                <div style={{ marginLeft: '20px' }}>
                  <ToggleCard titleVal={wMatrix('applyToSelectedVehicles')} toggleVal={applyMoveConclude} toggleClick={() => this.toggleState('applyMoveConclude')} />
                </div>
              </div>
            )
            : null
        }
      </div>
    )
  }

  /**
   * Sets preset values of edit options
   * @param {[Object]} checkedDevices Array of devices
   */
  presetSetState(checkedDevices) {
    let allEmail = true
    let allText = true
    let allPush = true
    let allApp = true
    let applyMOSLToggle = true
    let applySpeedThreshVal = true
    let applyHOO = true
    let HOOIndex = null
    let applyReconn = true
    let applyDisconn = true
    let applyAccel = true
    let applyDecel = true
    let applyPTOPriOn = true
    let applyPTOPriOff = true
    let applyPTOSecondOn = true
    let applyPTOSecondOff = true
    let applyMoveInit = true
    let applyMoveConclude = true

    for (let i = 0; i < checkedDevices.length; i += 1) {
      allEmail *= checkedDevices[i].ynEmail
      allText *= checkedDevices[i].ynText
      allPush *= checkedDevices[i].ynPush
      allApp *= checkedDevices[i].ynApp
      applyMOSLToggle *= (checkedDevices[i].numMOSL === checkedDevices[0].numMOSL
        && checkedDevices[0].numMOSL > 0)
      applySpeedThreshVal *= (checkedDevices[i].numSpeed === checkedDevices[0].numSpeed
        && checkedDevices[0].numSpeed > 0)
      // check if multiple devices have HOO settings
      const HOOBool = checkedDevices[i].hoo ? 1 : 0
      applyHOO *= HOOBool// equal(checkedDevices[i].hoo, checkedDevices[0].hoo)
      // simply overwrite index for first selected device with HOO settings
      if (checkedDevices[i].hoo && HOOIndex === null) {
        HOOIndex = i
      }
      applyReconn *= checkedDevices[i].reconnect
      applyDisconn *= checkedDevices[i].disconnect
      applyAccel *= checkedDevices[i].accel
      applyDecel *= checkedDevices[i].decel
      applyPTOPriOn *= checkedDevices[i].primaryOn
      applyPTOPriOff *= checkedDevices[i].primaryOff
      applyPTOSecondOn *= checkedDevices[i].secondaryOn
      applyPTOSecondOff *= checkedDevices[i].secondaryOff
      applyMoveInit *= checkedDevices[i].movementInitiated
      applyMoveConclude *= checkedDevices[i].movementConcluded
    }
    this.setState({
      presetEmail: !!allEmail,
      presetText: !!allText,
      presetPush: !!allPush,
      emailToggle: !!allEmail,
      textToggle: !!allText,
      pushToggle: !!allPush,
      appToggle: !!allApp,
      applyMOSLToggle: !!applyMOSLToggle,
      speedThreshVal: applySpeedThreshVal && checkedDevices[0].numSpeed
        ? checkedDevices[0].numSpeed
        : 0,
      HOOObj: applyHOO && HOOIndex !== null
        && checkedDevices[HOOIndex].hoo ? checkedDevices[HOOIndex].hoo
        : {
          mon: [{ start: 0, end: 1440 }, { start: null, end: null }],
          tue: [{ start: 0, end: 1440 }, { start: null, end: null }],
          wed: [{ start: 0, end: 1440 }, { start: null, end: null }],
          thu: [{ start: 0, end: 1440 }, { start: null, end: null }],
          fri: [{ start: 0, end: 1440 }, { start: null, end: null }],
          sat: [{ start: 0, end: 1440 }, { start: null, end: null }],
          sun: [{ start: 0, end: 1440 }, { start: null, end: null }],
        },
      applyReconn: !!applyReconn,
      applyDisconn: !!applyDisconn,
      applyAccel: !!applyAccel,
      applyDecel: !!applyDecel,
      primOn: !!applyPTOPriOn,
      primOff: !!applyPTOPriOff,
      secondOn: !!applyPTOSecondOn,
      secondOff: !!applyPTOSecondOff,
      applyMoveInitiate: !!applyMoveInit,
      applyMoveConclude: !!applyMoveConclude,
      landmarkDetails: [],
    })
  }

  render() {
    const { checkedDevices, editMode, wMatrix } = this.props
    const {
      emailToggle,
      textToggle,
      // pushToggle,
      appToggle,
      ifSaving,
    } = this.state
    let general = false
    if (checkedDevices.length === 0) {
      general = true
    }

    return (
      <div style={{ display: editMode ? 'flex' : 'none', flexDirection: 'column' }}>
        <div style={{ margin: '0px 0px 32px 40px' }}>
          <p style={{ display: general ? 'none' : 'block' }}>
            {`${wMatrix('editingDevices')} `}
          </p>
          <p style={{ display: general ? 'block' : 'none' }}>Editing All Devices. </p>
          {this.returnEditingList()}
        </div>
        {this.returnSpecificFields()}
        <h3>
          {`${wMatrix('selectNotificationType')}: `}
        </h3>
        <div style={{ marginLeft: '20px' }}>
          <ToggleCard titleVal={wMatrix('email')} toggleVal={emailToggle} toggleClick={() => this.toggleState('emailToggle')} />
          <ToggleCard titleVal={wMatrix('text')} toggleVal={textToggle} toggleClick={() => this.toggleState('textToggle')} />
          <ToggleCard titleVal={wMatrix('app')} toggleVal={appToggle} toggleClick={() => this.toggleState('appToggle')} />
          {/* <ToggleCard
            titleVal={wMatrix('push')}
            toggleVal={pushToggle}
            toggleClick={() => this.toggleState('pushToggle')}
          /> */}
        </div>
        <div style={{
          alignSelf: 'flex-end', marginTop: '12px', display: 'flex', alignItems: 'center',
        }}
        >
          <ButtonWrapper
            useAsWrapper
            onClick={this.resetAndCancel}
          >
            <label style={{ fontSize: '14px' }}>
              {`${wMatrix('Cancel')} `}
            </label>
          </ButtonWrapper>
          <Button
            type="primary"
            style={{ marginTop: '12px', marginLeft: '12px' }}
            onClick={this.handleSave}
            disabled={ifSaving || this.disableSaveButton()}
          >
            {ifSaving
              ? wMatrix('Saving')
              : wMatrix('Save')
            }
          </Button>
        </div>
      </div>
    )
  }
}

export default NotificationEditPanel
