import React, { Component } from 'react'
import { Select, Button } from 'antd'
import PropTypes from 'prop-types'

const { Option } = Select

export default class PageSelect extends Component {
  static propTypes = {
    options: PropTypes.array,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    options: [],
    onChange: null,
  }

  state = {
    selected: null,
  }

  /**
   * @Note - figure out how to default to first option when select is populated
   */
  componentDidUpdate = (prevProps) => {
    const { options } = this.props
    if (prevProps.options.length === 0 && options.length > 0) {
      this.change(0)
    }
  }

  change = (page) => {
    const { onChange } = this.props
    this.setState({ selected: page })
    // console.log('page:', page)
    if (onChange) {
      onChange(page)
    }
  }

  onNext = () => {
    const { options } = this.props
    const { selected } = this.state
    const next = selected + 1
    if (next < (options.length)) {
      this.change(next)
    }
  }

  onPrev = () => {
    const { selected } = this.state
    const prev = selected - 1
    if (prev >= 0) {
      this.setState({ selected: prev })
      this.change(prev)
    }
  }

  onSelect = (value) => {
    // console.log(value, option)
    this.change(value)
  }


  render() {
    const { options } = this.props
    const { selected } = this.state
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button disabled={selected === 0 || options.length === 0} onClick={() => this.onPrev()} icon="left" size="small" style={{ width: 36, height: 36, padding: 0 }} />
        {options.length > 0 ? (
          <Select
            showSearch
            showArrow={false}
            style={{
              minWidth: 150, marginLeft: 10, marginRight: 10,
            }}
            defaultActiveFirstOption
            onSelect={this.onSelect}
            value={selected}
            optionFilterProp="title"
          >
            {options.map((option, index) => (
              <Option style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} key={option.key || index} value={index} title={option.name}>{option.name}</Option>
            ))}
          </Select>
        )
          : null}
        <Button disabled={selected === options.length - 1 || options.length === 0} onClick={() => this.onNext()} icon="right" size="small" style={{ width: 36, height: 36, padding: 0 }} />
      </div>
    )
  }
}
