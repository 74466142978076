import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DeviceListItem from '@mol/deviceListItem'
import ScrollView from '@mol/scrollView'
import DeviceVirtualList from '@page/main/molecules/deviceVirtualList'


export default class DeviceList extends Component {
  static propTypes = {
    deviceList: PropTypes.array,
    selectedDevice: PropTypes.object,
    selectDevice: PropTypes.func.isRequired,
    listMode: PropTypes.string,
    ifShowDistance: PropTypes.bool,
    hoverDevice: PropTypes.func,
    loading: PropTypes.bool,
    /** @Main */
    mainNav: PropTypes.string,
    /** @DistanceHOC */
    setOriginFlag: PropTypes.bool,
  }

  static defaultProps = {
    deviceList: [],
    selectedDevice: null,
    listMode: 'comfortable',
    ifShowDistance: false,
    hoverDevice: null,
    loading: false,
    mainNav: 'map',
    setOriginFlag: null,
  }

  render() {
    const {
      selectedDevice, loading, mainNav, deviceList, selectDevice, listMode, formatVehicleStatus,
      ifShowDistance, hoverDevice, setOriginFlag,
    } = this.props

    // if loading, return the skeleton placeholder
    if (loading) {
      // create skeleton items
      const skeletonItems = []
      for (let i = 0; i < 10; i += 1) {
        skeletonItems.push(
          <DeviceListItem
            skeleton
            key={`${i}`}
          />,
        )
      }
      return (
        <ScrollView
          selectedKey={selectedDevice ? `${selectedDevice.id}` : null}
        >
          {skeletonItems}
        </ScrollView>
      )
    }
    // return Virtual List
    return (
      <DeviceVirtualList
        mainNav={mainNav}
        deviceList={deviceList}
        selectedDevice={selectedDevice}
        selectDevice={selectDevice}
        listMode={listMode}
        formatVehicleStatus={formatVehicleStatus}
        ifShowDistance={ifShowDistance}
        hoverDevice={hoverDevice}
        loading={loading}
        // used to force update the list
        setOriginFlag={setOriginFlag}
      />
    )
  }
}
