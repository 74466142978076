import React, { Component } from 'react'
import PropTypes from 'prop-types'

/**
 * @description This is a simple button to take an optional text style and take an onClick event
 * @prop { string } text
 * @prop { object } style (Optional)
 * @prop { func } onClick (Required)
 */

export default class TextButton extends Component {
  static propTypes = {
    text: PropTypes.string,
    children: PropTypes.any,
    style: PropTypes.object,
    onClick: PropTypes.func.isRequired,
    useAsWrapper: PropTypes.bool,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    text: 'button',
    style: {
      color: '#FFFFFF',
      fontWeight: 'normal',
      margin: '0px 5px 0px 5px',
    },
    children: null,
    useAsWrapper: false,
    disabled: false,
  }

  render() {
    const {
      text, style, children, onClick, useAsWrapper, disabled,
    } = this.props
    const wrapperStyle = useAsWrapper ? {
      ...style,
      cursor: !disabled ? 'pointer' : 'not-allowed',
    } : {
      cursor: !disabled ? 'pointer' : 'not-allowed',
    }
    return (
      <div
        role="button"
        tabIndex="-1"
        onClick={!disabled ? (e) => { onClick(e) } : undefined}
        style={wrapperStyle}
      >
        {useAsWrapper ? children
          : (
            <h4
              style={style}
            >
              {children || text}
            </h4>
          )}
      </div>
    )
  }
}
