import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import PageSelect from '@atom/pageSelect'
import SkeletonComponent from '@atom/skeletonComponents'
import helper from '../../helper'

const styles = {
  tripHeader: {
    // width: '100%',
    padding: '10px 15px 10px 15px',
    backgroundColor: '#ececec',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  vehicleHeader: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: '#ececec',
    borderBottom: '1px solid #d9d9d9',
  },
  headingLabel: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: '0',
    // margin: 10,
  },
  vehicleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
  },
  vehicleBody: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: '#ececec',
    borderBottom: '1px solid #d9d9d9',
  },
}

class TripsReport extends Component {
  static propTypes = {
    /** @helper */
    wMatrix: PropTypes.func.isRequired,
    speedToString: PropTypes.func.isRequired,
    distanceToString: PropTypes.func.isRequired,
    minutesToString: PropTypes.func.isRequired,
    /** @parent */
    columns: PropTypes.array.isRequired,
    tripVehicles: PropTypes.array,
    loading: PropTypes.bool,
  }

  static defaultProps = {
    tripVehicles: [],
    loading: false,
  }

  state = {
    displayIndex: 0,
  }

  /**
   * @private
   * @description - Returns div for top speed
   */
  renderTopSpeed = (topSpeed) => {
    const { wMatrix, speedToString } = this.props
    if (topSpeed && topSpeed !== undefined) {
      return (
        <div style={styles.headingLabel}>
          {`${wMatrix('topSpeed')}: ${speedToString(topSpeed)}`}
        </div>
      )
    }
    return null
  }

  /**
   * @private
   * @description - Returns div for trip duration and distance
   */
  renderDistanceDuration = (distance, duration) => {
    const { distanceToString, minutesToString } = this.props
    if (
      (distance === null || distance === undefined)
      && (duration === null || duration === undefined)
    ) {
      return null
    }
    let displayString = '('
    if (distance !== null) {
      displayString += `${distanceToString(distance)}`
    }
    if (duration && duration > 0) {
      if (displayString.length > 1) displayString += ', '
      displayString += `${minutesToString(duration)}`
    }
    displayString += ')'
    return (<div>{displayString}</div>)
  }

  /**
   * @private
   * @description - Renders trip header
   */
  renderTripHeader = (trip) => {
    const { wMatrix } = this.props
    return (
      <div style={styles.tripHeader}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <h3 style={{ whiteSpace: 'nowrap', marginBottom: 10 }}>{`${wMatrix('Trip')}# ${trip.tripOrder}`}</h3>
          {this.renderDistanceDuration(trip.distance, trip.duration)}
          {/* {this.renderDistanceDuration(10, 65)} */}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <h3 style={{ marginBottom: 10 }}>{`${trip.startTime} - ${trip.endTime}`}</h3>
          {this.renderTopSpeed(trip.topSpeed)}
          {/* {this.renderTopSpeed(65)} */}
        </div>
      </div>
    )
  }

  /**
   * @private
   * @description - Renders Header, footer and tables given list of trips
   */
  tripsTables = (trips) => {
    const { columns } = this.props
    const tripsClone = JSON.parse(JSON.stringify(trips))
    return tripsClone.map(trip => (
      <div key={`${trip.id}`}>
        {this.renderTripHeader(trip)}
        <Table columns={columns} dataSource={trip.events} pagination={false} rowKey="id" />
        {this.renderTripFooter(trip)}
      </div>
    ))
  }

  /**
   * @callback
   * @description - Passed to pageSelect to return the selected page index
   */
  onPageChange = (page) => {
    this.setState({ displayIndex: page })
  }

  /**
   * @private
   * @returns {Component}
   * Returns Trip tables per vehicle
   */
  returnTripsByVehicle = (vehicle) => {
    const { wMatrix } = this.props
    if (vehicle) {
      return (
        <div style={styles.vehicleContainer}>
          <div style={styles.vehicleBody}>
            <h2 style={{ margin: 10 }}>{vehicle.alias}</h2>
            {`(${vehicle.trips.length} ${wMatrix('trip')}${vehicle.trips.length > 1 ? 's' : ''})`}
          </div>
          <div style={{ width: '100%', alignSelf: 'flex-end' }}>
            {this.tripsTables(vehicle.trips)}
          </div>
        </div>
      )
    }
    return null
  }

  /**
   * @private
   * @description - Renders footer to show time to next trip
   */
  renderTripFooter = (trip) => {
    const { wMatrix } = this.props
    if (trip.timeToNextTrip) {
      let hrs = null
      let mins = trip.timeToNextTrip
      if (mins > 60) {
        hrs = Math.floor(mins / 60)
        mins %= 60
      }
      return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <h5>
            {wMatrix('Time Between Trips')}
            :
          </h5>
          <h5 style={{
            marginLeft: '5px',
            fontWeight: 'normal',
          }}
          >
            {hrs || null}
            {hrs ? ' hr' : ''}
            {hrs > 1 ? 's ' : ' '}
            {' '}
            {mins}
            {mins > 1 ? ' mins ' : ' min '}
          </h5>
        </div>
      )
    }
    return null
  }

  render() {
    const { tripVehicles, loading } = this.props
    const { displayIndex } = this.state
    let vehicleOptions = JSON.parse(JSON.stringify(tripVehicles))
    vehicleOptions = vehicleOptions.map(vehicle => ({ name: vehicle.alias }))
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginTop: 16, alignSelf: 'flex-end' }}>
          <PageSelect onChange={this.onPageChange} options={vehicleOptions} />
        </div>
        { loading
          ? <SkeletonComponent type="table" />
          : this.returnTripsByVehicle(tripVehicles[displayIndex])
        }
      </div>
    )
  }
}

export default helper()(TripsReport)
