import React from 'react'
import PropTypes from 'prop-types'
import {
  Breadcrumb, Progress, Select, Tooltip,
} from 'antd'
import {
  Bar, BarChart, CartesianGrid, Cell, Legend, Pie, PieChart, ResponsiveContainer, XAxis,
  YAxis, Tooltip as ReToolTip,
} from 'recharts'
import Card from '@atom/card'

const styles = {
  guageWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    maxWidth: '915px',
  },
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 0 20px 0',
  },
  cardColumn: {
    flex: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 5,
  },
  guageLegendContainer: {
    flex: 1,
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    margin: '0 0 0 50px',
  },
  dot: {
    height: '10px',
    width: '10px',
    flexShrink: 0,
    borderRadius: '50%',
    margin: '0 5px 0 0',
  },
  li: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  postTitle: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  dashboardWrapper: {
    flexShrink: 0,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  topHalfWrapper: {
    minHeight: '50%',
    flexShrink: 0,
  },
  botHalfWrapper: {
    minHeight: '50%',
    marginTop: -5,
    flexShrink: 0,
    display: 'flex',
    flexGrow: 1,
  },
  botCard: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
  },
  chartCardContentWrapper: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    margin: '0px 8px 8px',
  },
  pieChart: {
    alias: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      flexShrink: 1,
      marginRight: 5,
    },
    data: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-between',
      whiteSpace: 'nowrap',
      width: '80%',
    },
    legendColor: {
      height: '20px',
      width: '10%',
      margin: 8,
      flexShrink: 0,
    },
  },
}

const colors = ['#4482FF', '#FF5500', '#FFBA44', '#87D068']

export default class ServiceDashboard extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    appHeight: PropTypes.number.isRequired,
    statusData: PropTypes.object.isRequired,
    pieData: PropTypes.array.isRequired,
    pieColorsArray: PropTypes.array.isRequired,
    onPieTimeChange: PropTypes.func.isRequired,
    onPieTopChange: PropTypes.func.isRequired,
    barData: PropTypes.object.isRequired,
    onBarTimeChange: PropTypes.func.isRequired,
    onBarTopChange: PropTypes.func.isRequired,
    barColorsArray: PropTypes.array.isRequired,
    timeOptions: PropTypes.array.isRequired,
  }

  /**
   * @description renders post title select boxes given the onChange functions
   */
  renderPostTitleSelectBoxes = (onTimeChange, onTopChange) => {
    const {
      wMatrix, timeOptions,
    } = this.props
    return (
      <div style={styles.postTitle}>
        <Select
          defaultValue={3}
          style={{ width: 85, marginRight: 8 }}
          onChange={onTopChange}
        >
          <Select.Option value={3}>{`${wMatrix('top')} 3`}</Select.Option>
          <Select.Option value={5}>{`${wMatrix('top')} 5`}</Select.Option>
          <Select.Option value={10}>{`${wMatrix('top')} 10`}</Select.Option>
        </Select>
        <Select
          defaultValue={0}
          style={{ width: 106 }}
          onChange={onTimeChange}
        >
          {timeOptions.map((option, index) => (
            <Select.Option
              key={option.key}
              value={index}
            >
              {option.displayString}
            </Select.Option>
          ))}
        </Select>
      </div>
    )
  }

  /**
   * @description renders dashboard status guages
   */
  renderGauges = (availableHeight) => {
    const { wMatrix, statusData } = this.props
    return (
      <Card
        contentWrapper={{
          flexGrow: 1,
          flexShrink: 0,
          minHeight: availableHeight,
          display: 'flex',
          flexDirection: 'column',
          margin: '0px 8px 8px 8px',
        }}
      >
        <Breadcrumb>
          <Breadcrumb.Item>{wMatrix('service')}</Breadcrumb.Item>
          <Breadcrumb.Item>{wMatrix('dashboard')}</Breadcrumb.Item>
        </Breadcrumb>
        <div style={{
          display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center',
        }}
        >
          <div style={styles.guageWrapper}>
            <div style={styles.cardColumn}>
              <div style={{ height: '25px' }} />
              <Progress
                type="dashboard"
                percent={Math.floor((statusData.active / 20) * 100)}
                strokeColor={colors[0]}
                strokeWidth={6}
                width={180}
                gapDegree={90}
                format={() => (
                  <div style={{ fontSize: '25px' }}>
                    {`${statusData.active} ${wMatrix('tickets')}`}
                  </div>
                )}
              />
              <h2>{wMatrix('Current')}</h2>
            </div>
            <div style={styles.cardColumn}>
              <Progress
                type="dashboard"
                percent={Math.floor((statusData.overdue / 5) * 100)}
                strokeColor={colors[1]}
                strokeWidth={6}
                width={180}
                gapDegree={90}
                format={() => (
                  <div style={{ fontSize: '25px' }}>
                    {`${statusData.overdue} ${wMatrix('tickets')}`}
                  </div>
                )}
              />
              <h2>{wMatrix('Overdue')}</h2>
            </div>
            <div style={styles.cardColumn}>
              <div style={{ height: '25px' }} />
              <Progress
                type="dashboard"
                percent={Math.floor((statusData.upcoming / 20) * 100)}
                strokeColor={colors[2]}
                strokeWidth={6}
                width={180}
                gapDegree={90}
                format={() => (
                  <div style={{ fontSize: '25px' }}>
                    {`${statusData.upcoming} ${wMatrix('tickets')}`}
                  </div>
                )}
              />
              <h2>{wMatrix('Upcoming')}</h2>
            </div>
            <div style={styles.guageLegendContainer}>
              <div style={styles.cardRow}>
                <div style={{ ...styles.dot, backgroundColor: colors[3] }} />
                <h3 style={{ margin: 0 }}>{`${wMatrix('Completed')}: ${statusData.completed} ${wMatrix('tickets')}`}</h3>
              </div>
              <div style={styles.cardRow}>
                <div style={{ ...styles.dot, backgroundColor: colors[0] }} />
                <h3 style={{ margin: 0 }}>{`${wMatrix('Active')}: ${statusData.active} ${wMatrix('tickets')}`}</h3>
              </div>
              <div style={styles.cardRow}>
                <div style={{ ...styles.dot, backgroundColor: colors[2] }} />
                <h3 style={{ margin: 0 }}>{`${wMatrix('nextDue')}: ${statusData.nextDue || ''}`}</h3>
              </div>
            </div>
          </div>
        </div>
      </Card>
    )
  }

  renderServiceCosts = (availableHeight) => {
    const {
      onBarTopChange, onBarTimeChange, barColorsArray, barData, wMatrix,
    } = this.props
    const { columns, keys } = barData
    return (
      <div style={{ ...styles.botCard, marginRight: -2.5 }}>
        <Card
          title={wMatrix('serviceCost')}
          postTitle={this.renderPostTitleSelectBoxes(onBarTimeChange, onBarTopChange)}
          contentWrapper={{ ...styles.chartCardContentWrapper, height: availableHeight }}
        >
          <ResponsiveContainer height="99%" width="95%">
            <BarChart width={730} height={availableHeight} data={columns}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="xLabel" interval="preserveStartEnd" />
              <YAxis label={{ value: `${wMatrix('cost')}`, angle: -90, position: 'insideLeft' }} />
              <ReToolTip cursor />
              <Legend />
              {keys.map((key, index) => (
                <Bar key={key} dataKey={key} stackId="a" fill={barColorsArray[index % barColorsArray.length]} />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </Card>
      </div>
    )
  }

  /**
   * @private
   * @description renders the pie legend
   */
  renderPieLegendContent = () => {
    const { pieData, pieColorsArray } = this.props
    return (
      <div style={{
        height: '80%',
        marginTop: '10%',
        marginBottom: '10%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: pieData.length <= 5 ? 'center' : undefined,
        flexGrow: 0,
      }}
      >
        <div>
          {pieData.map((entry, index) => (
            <div key={`item-${entry.alias}`} style={styles.li}>
              <div style={{
                ...styles.pieChart.legendColor,
                backgroundColor: pieColorsArray[index % pieColorsArray.length],
              }}
              />
              <div style={styles.pieChart.data}>
                <div style={styles.pieChart.alias}>
                  <Tooltip title={`${entry.alias}`}>
                    {`${entry.alias}`}
                  </Tooltip>
                </div>
                <div style={{ flexShrink: 0 }}>{`$${entry.cost.toFixed(2)}`}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  /**
   * @private
   * @description renders pie chart of top costing vehicles
   */
  renderTopCosting = (availableHeight) => {
    const {
      pieData, pieColorsArray, onPieTimeChange, onPieTopChange, wMatrix,
    } = this.props
    const entries = pieData.map((e, i) => (
      <Cell key={e} fill={pieColorsArray[i % pieColorsArray.length]} name={e.alias} />
    ))
    return (
      <div style={{ ...styles.botCard, marginLeft: -2.5 }} className="rechartsPie-ScrollableLegend">
        <Card
          title={wMatrix('highestCostingVehicles')}
          postTitle={this.renderPostTitleSelectBoxes(onPieTimeChange, onPieTopChange)}
          contentWrapper={{ ...styles.chartCardContentWrapper, height: availableHeight }}
        >
          {!pieData || pieData.length === 0
            ? <h3 style={{ alignSelf: 'center', color: '#797979' }}>{wMatrix('noData')}</h3>
            : (
              <ResponsiveContainer height="99%" width="95%">
                <PieChart>
                  <Pie
                    data={pieData}
                    dataKey="cost"
                    cx="50%"
                    cy="50%"
                    innerRadius="40%"
                    outerRadius="70%"
                    labelLine={false}
                  >
                    {entries}
                  </Pie>
                  <ReToolTip />
                  <Legend
                    layout="vertical"
                    align="right"
                    verticalAlign="middle"
                    width="40%"
                    content={this.renderPieLegendContent}
                  />
                </PieChart>
              </ResponsiveContainer>
            )
          }
        </Card>
      </div>
    )
  }

  render() {
    const { appHeight } = this.props
    // 106 above grey container
    // 34 below grey container
    const halfAvailableHeight = ((appHeight - 106 - 34) / 2)
    // take out card padding and use 80% of result for content
    const availableCardHeight = (halfAvailableHeight - 5 - 2.5) * 0.8
    return (
      <div style={styles.dashboardWrapper}>
        <div style={styles.topHalfWrapper}>
          {this.renderGauges(availableCardHeight)}
        </div>
        <div style={styles.botHalfWrapper}>
          {this.renderServiceCosts(availableCardHeight)}
          {this.renderTopCosting(availableCardHeight)}
        </div>
      </div>
    )
  }
}
