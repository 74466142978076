import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Alert, Checkbox, DatePicker, Form, Input, InputNumber,
} from 'antd'

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  alert: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: '0 0 10px 0',
  },
  h2: {
    margin: 0,
  },
  p: {
    margin: '0',
  },
  formLabel: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '35%',
    minWidth: '100px',
    margin: '0 15px 0 0',
  },
  formItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: 0,
  },
}

class TechnicianForm extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    handleEdit: PropTypes.func.isRequired,
    existingTicket: PropTypes.object,
  }

  static defaultProps = {
    existingTicket: null,
  }

  state = {
    alertState: {
      display: 'none',
      message: '',
      type: 'success',
    },
  }

  componentDidUpdate(prevProps) {
    const { existingTicket, form } = this.props
    if (prevProps.existingTicket) {
      const pt = JSON.stringify(prevProps.existingTicket)
      const nt = JSON.stringify(existingTicket)
      if (pt !== nt) form.resetFields()
    }
  }

  disableFutureDates = (dateValue) => {
    const today = moment()
    if (!dateValue) {
      return false
    }
    return (dateValue.valueOf() > today.valueOf() || today.diff(dateValue, 'days') > 365)
  }

  handleHideAlert = () => {
    this.setState({
      alertState: {
        display: 'none',
        message: '',
        type: 'success',
      },
    })
  }

  handleSubmit = async () => {
    const { form, existingTicket, handleEdit } = this.props
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          const editResponse = await handleEdit(values, existingTicket)
          if (editResponse && editResponse.data) {
            const { description, code } = editResponse.data.updateServiceTicket
            // only show alert if there's an error
            if (code === 4000) {
              const error = {
                display: 'block',
                message: description,
                type: 'error',
              }
              this.setState({ alertState: error })
            }
          }
        } catch (e) {
          const error = {
            display: 'block',
            message: 'Something went wrong',
            type: 'error',
          }
          this.setState({ alertState: error })
        }
      }
    })
  }

  renderRecuranceInfo = (ticket) => {
    const { wMatrix } = this.props
    if (ticket && ticket.interval && ticket.interval.typeId && ticket.details.status !== 'completed') {
      return (
        <div style={{ ...styles.row, justifyContent: 'center', marginTop: -15 }}>
          <p style={{ ...styles.p, color: 'grey', fontSize: '0.9em' }}>{`${wMatrix('recursAfterCompletion')}`}</p>
        </div>
      )
    }
    return null
  }

  render() {
    const { wMatrix, form, existingTicket } = this.props
    const { alertState } = this.state
    const t = existingTicket
    /**
     * 3 initial dtCompleted cases:
     * - completed with no date (v2 records)
     * - completed today: use todays date
     * - not completed
     */
    let initialDateValue = null
    if (t && t.details && t.completion) {
      // ticket is currently set to completed
      if (t.details.status === 'completed') {
        initialDateValue = t.completion.date ? moment(t.completion.date) : undefined
      } else {
        initialDateValue = moment()
      }
    }

    return (
      <div style={styles.wrapper}>
        <Form
          name="technicianForm"
          style={{ width: '100%' }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

            <div style={styles.row}>
              <div style={styles.formLabel}>
                <h2 style={styles.h2}>{`${wMatrix('service')}: `}</h2>
              </div>
              <div style={styles.formItem}>
                {t.type.name}
              </div>
            </div>

            <div style={styles.row}>
              <div style={styles.formLabel}>
                <p style={styles.p}>{`${wMatrix('created')}: `}</p>
              </div>
              <div style={styles.formItem}>
                {t && t.creation && t.creation.date
                  ? moment(t.creation.date).format('YYYY-MM-DD')
                  : moment().format('YYYY-MM-DD')
                }
              </div>
            </div>

            <div style={styles.row}>
              <div style={styles.formLabel}>
                {`${wMatrix('estimatedCost')}: `}
              </div>
              <Form.Item style={styles.formItem}>
                {form.getFieldDecorator('estimatedCost', {
                  initialValue: t && t.cost && t.cost.estimated ? t.cost.estimated : 0,
                })(
                  <InputNumber
                    style={{ width: 400 }}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    onBlur={() => this.handleSubmit()}
                  />,
                )}
              </Form.Item>
            </div>

            <div style={styles.row}>
              <div style={styles.formLabel}>
                <p style={styles.p}>{`${wMatrix('cost')}: `}</p>
              </div>
              <Form.Item style={styles.formItem}>
                {form.getFieldDecorator('cost', {
                  initialValue: t && t.cost && t.cost.actual ? t.cost.actual : 0,
                })(
                  <InputNumber
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    onBlur={() => this.handleSubmit()}
                  />,
                )}
              </Form.Item>
            </div>

            <div style={styles.row}>
              <div style={styles.formLabel}>
                <p style={styles.p}>{`${wMatrix('Completed')}: `}</p>
              </div>
              <Form.Item style={{ ...styles.formItem }}>
                {form.getFieldDecorator('completed', {
                  initialValue: !!(t && t.details && t.details.status && t.details.status === 'completed'),
                  valuePropName: 'checked',
                })(
                  <Checkbox onBlur={() => this.handleSubmit()} />,
                )}
              </Form.Item>
              {form.getFieldValue('completed') ? (
                <Form.Item style={{ ...styles.formItem, margin: '0 0 0 7px' }}>
                  {form.getFieldDecorator('dtCompleted', {
                    initialValue: initialDateValue,
                  })(
                    <DatePicker
                      disabledDate={this.disableFutureDates}
                      showToday={false}
                      allowClear={false}
                      style={{ width: '100%' }}
                      size="small"
                      onChange={() => this.handleSubmit()}
                      autoFocus
                    />,
                  )}
                </Form.Item>
              ) : null}
            </div>

            {this.renderRecuranceInfo(t)}

            <div style={styles.row}>
              <div style={{ ...styles.formLabel, alignItems: 'flex-start' }}>
                <p style={styles.p}>{`${wMatrix('notes')}: `}</p>
              </div>
              <Form.Item style={styles.formItem}>
                {form.getFieldDecorator('notes', {
                  initialValue: t && t.details && t.details.notes ? t.details.notes : '',
                })(
                  <Input.TextArea
                    rows={5}
                    style={{ maxWidth: '250px' }}
                    onBlur={() => this.handleSubmit()}
                  />,
                )}
              </Form.Item>
            </div>

          </div>
        </Form>

        <div style={styles.alert}>
          <Alert
            style={{
              display: alertState.display,
              width: '275px',
              margin: '0px 10px',
            }}
            closable
            showIcon
            message={alertState.message}
            type={alertState.type}
          />
        </div>
      </div>
    )
  }
}

export default Form.create({ name: 'technicianForm' })(TechnicianForm)
