import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DeviceList from '@org/deviceList'
import { Input } from 'antd'
import TagFilter from '@atom/tagsFilterDropDown'
import helper from '@helper'
import LeftContainer from './left.container'

const { Search } = Input

const styles = {
  formContainer: {
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  search: {
    marginBottom: 10,
  },
}

/**
 * Composition of the LeftContainer
 */
class LeftDevicesContainer extends Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    selectModal: PropTypes.func.isRequired,
    mainNav: PropTypes.string,
    setNav: PropTypes.func.isRequired,
    deviceListMode: PropTypes.string,
    mapIconMode: PropTypes.string,
    mapIconSizeMode: PropTypes.string,
    saveContactMeta: PropTypes.func.isRequired,
    filterDevice: PropTypes.func.isRequired,
    existingTagList: PropTypes.array,
    filterDeviceByTag: PropTypes.func.isRequired,
    deviceList: PropTypes.array,
    selectedDevice: PropTypes.object,
    selectDevice: PropTypes.func.isRequired,
    filterStr: PropTypes.string,
    filterTags: PropTypes.array,
    loading: PropTypes.bool,
  }

  static defaultProps = {
    mainNav: 'map',
    deviceListMode: 'comfortable',
    mapIconMode: 'comfortable',
    mapIconSizeMode: 'large',
    existingTagList: [],
    deviceList: [],
    selectedDevice: null,
    filterStr: '',
    filterTags: [],
    loading: false,
  }

  /**
   * @private
   */
  filterDevice = (e) => {
    const { filterDevice } = this.props

    filterDevice(e.target.value)
  }

  renderHeaderDetails = () => {
    const {
      wMatrix, existingTagList, filterDeviceByTag, filterStr, filterTags,
    } = this.props

    return (
      <div style={styles.formContainer}>
        <Search
          style={styles.search}
          placeholder={wMatrix('Search for Device')}
          onChange={this.filterDevice}
          allowClear
          defaultValue={filterStr}
        />
        <TagFilter
          existingTagList={existingTagList}
          handleFilterChange={filterDeviceByTag}
          defaultValues={filterTags}
          valueKey="id"
          width="100%"
        />
      </div>
    )
  }

  render() {
    const {
      mainNav, setNav, mapIconMode, loading, deviceListMode, deviceList,
      selectedDevice, selectDevice, selectModal,
      saveContactMeta, mapIconSizeMode,
    } = this.props

    return (
      <LeftContainer
        deviceListMode={deviceListMode}
        mapIconMode={mapIconMode}
        mapIconSizeMode={mapIconSizeMode}
        mainNav={mainNav}
        setNav={setNav}
        saveContactMeta={saveContactMeta}
        renderHeaderDetails={this.renderHeaderDetails}
        selectModal={selectModal}
      >
        <DeviceList
          mainNav={mainNav}
          loading={loading}
          deviceList={deviceList}
          listMode={deviceListMode}
          selectedDevice={selectedDevice}
          selectDevice={selectDevice}
          deviceListMode={deviceListMode}
        />
      </LeftContainer>
    )
  }
}

export default helper()(LeftDevicesContainer)
