import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'react-apollo'
import Analytics from '@analytics'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import { loginQuery, loginToV3VerifyQuery } from '../../graphql/query'
import { generateForgotCode } from '../../graphql/mutation'
import consumerConnector from '../../graphql/consumerConnector'

const preferenceQueryWhenLogin = gql`
  query preferenceQuery {
    preference {
      language
      distanceMeasurement
      timeZoneId
      mapRefresh
    }
    rolesToCapability {
      capability
      ynCreate
      ynRead
      ynUpdate
      ynDelete
    }
    features{
      featureId
      nameId
      name
      isActive
    }
  }
`

const loginHOC = () => (WrappedComponent) => {
  class LoginHOC extends Component {
    static propTypes = {
      apolloClient: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired,
    }

    state = {
      username: '',
      password: '',
      rememberMe: false,
      failedLoginAttempt: false,
      numberOfLoginAttempts: 0,
      loginInfo: null,
      showReCaptcha: false,
    }

    /**
     * Wait until component has mounted to auto log in
     */
    componentDidMount() {
      this.loginUsingRememberMe()
    }

    componentWillUnmount() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
    }

    /**
     * If Remember Me is set (local storage contains a token) Then Route to /map page
     */
    loginUsingRememberMe = () => {
      const { history } = this.props
      const localToken = localStorage.getItem('rft-token')
      if (localToken) {
        /** @analytics Record successful Login */
        Analytics.record({
          feature: 'remember_me_login',
          page: 'login',
          event: 'login',
        })
        // set session token using local storage
        sessionStorage.setItem('rft-token', localStorage.getItem('rft-token'))
        history.push('/map')
      }
    }

    changeUsername = (e) => {
      this.setState({ username: e.target.value })
    }

    changePassword = (e) => {
      this.setState({ password: e.target.value })
    }

    checkRememberMe = (e) => {
      this.setState({ rememberMe: e.target.checked })
    }

    checkFailedLogin = (data = null) => {
      const { failedLoginAttempt, numberOfLoginAttempts } = this.state

      this.setState({ numberOfLoginAttempts: numberOfLoginAttempts + 1 })
      if (numberOfLoginAttempts > 1) {
        this.setState({ showReCaptcha: true })
      }

      if (failedLoginAttempt === false) {
        this.setState({
          failedLoginAttempt: true,
          loginInfo: data,
        })
        this.timer = setTimeout(() => {
          this.setState({ failedLoginAttempt: false })
        }, 10000)
      } else if (this.timer) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.setState({ failedLoginAttempt: false })
        }, 10000)
      }
    }

    forgot = (email) => {
      const { apolloClient } = this.props
      return new Promise((resolve, reject) => apolloClient.mutate({
        mutation: generateForgotCode,
        variables: {
          email,
        },
      }).then(() => {
        resolve()
      }).catch(() => {
        // console.log(err)
        reject()
      }))
    }

    login = () => new Promise((resolve, reject) => {
      const { apolloClient, history } = this.props
      const {
        username, password, rememberMe, showReCaptcha,
      } = this.state

      if (showReCaptcha) {
        return
      }
      apolloClient.query({
        query: loginQuery,
        fetchPolicy: 'network-only',
        variables: {
          username,
          password,
        },
      }).then(({ data }) => {
        if (data) {
          if (data.loginBcrypt.resultCode === 1) {
            if (rememberMe) {
              localStorage.setItem('rft-token', data.loginBcrypt.token)
            } else {
              sessionStorage.setItem('rft-token', data.loginBcrypt.token)
            }
            apolloClient.query({
              query: preferenceQueryWhenLogin,
            }).then((prefData) => {
              /* We always store preference data in localStorage */
              localStorage.setItem('rft-language', prefData.data.preference.language)
              localStorage.setItem('rft-measurement', prefData.data.preference.distanceMeasurement)
              localStorage.setItem('rft-timeZoneId', prefData.data.preference.timeZoneId)
              localStorage.setItem('rft-mapRefresh', prefData.data.preference.mapRefresh)
              localStorage.setItem('rft-roleToCap', JSON.stringify(prefData.data.rolesToCapability))
              localStorage.setItem('rft-features', JSON.stringify(prefData.data.features))
              this.setState({ numberOfLoginAttempts: 0 })
              history.replace('/map')
              /** @analytics Record successful Login */
              Analytics.record({
                feature: 'login',
                page: 'login',
                event: 'login',
              })
              resolve()
            }).catch(() => {
              // alert('Internel Error', error)
              history.push('/login')
              reject()
            })
          } else {
            this.checkFailedLogin(data)
            reject()
          }
        } else {
          // alert('No Data from apollo link')
          reject()
        }
      }).catch(() => {
        this.checkFailedLogin()
        reject()
      })
    })

    onReCaptchaSuccess = () => {
      this.setState({ showReCaptcha: false, numberOfLoginAttempts: 2 })
    }

    forceLogin = (contactId, randomToken) => new Promise((resolve, reject) => {
      const { apolloClient, history } = this.props
      if (!Number(contactId)) {
        reject()
      }
      apolloClient.query({
        query: loginToV3VerifyQuery,
        fetchPolicy: 'network-only',
        variables: {
          contactId: Number(contactId),
          randomToken,
        },
      }).then(({ data }) => {
        if (data) {
          if (data.loginToV3FromExternalVerify.resultCode === 1) {
            sessionStorage.setItem('rft-token', data.loginToV3FromExternalVerify.token)
            apolloClient.query({
              query: preferenceQueryWhenLogin,
            }).then((prefData) => {
              /* We always store preference data in localStorage */
              localStorage.setItem('rft-language', prefData.data.preference.language)
              localStorage.setItem('rft-measurement', prefData.data.preference.distanceMeasurement)
              localStorage.setItem('rft-timeZoneId', prefData.data.preference.timeZoneId)
              localStorage.setItem('rft-mapRefresh', prefData.data.preference.mapRefresh)
              localStorage.setItem('rft-roleToCap', JSON.stringify(prefData.data.rolesToCapability))
              localStorage.setItem('rft-features', JSON.stringify(prefData.data.features))
              history.push('/map')
              /** @analytics Record successful Login */
              Analytics.record({
                feature: 'admin_login',
                page: 'login',
                event: 'login',
              })
              resolve()
            }).catch(() => {
              // alert('Internel Error', error)
              history.push('/login')
              reject()
            })
          } else {
            this.checkFailedLogin(data)
            reject()
          }
        } else {
          // alert('No Data from apollo link')
          reject()
        }
      }).catch(() => {
        this.checkFailedLogin()
        reject()
      })
    })

    // rename of the componentWillMount since react 16.3
    UNSAFE_componentWillMount() {
      /**
       * Handle Force Log In
       */
      let queryString = window.location.search
      if (queryString) {
        try {
          queryString = queryString.substring(1)
          const queries = queryString.split('&')
          const params = {}
          for (let i = 0; i < queries.length; i += 1) {
            const split0 = queries[i].split('=')[0]
            const split1 = queries[i].split('=')[1]
            params[split0] = split1
          }
          if (params.n) {
            const contactId = params.n.split('-')[0]
            const randomToken = params.n.split('-')[1]
            // admin login
            if (contactId && randomToken) {
              this.forceLogin(contactId, randomToken)
            }
          }
        } catch (err) {
          // console.log(err, '\n failed to parse the query string')
        }
      }
    }

    render = () => {
      const {
        username, password, failedLoginAttempt, loginInfo, showReCaptcha,
      } = this.state

      return (
        <WrappedComponent
          login={this.login}
          forgot={this.forgot}
          changeUsername={this.changeUsername}
          changePassword={this.changePassword}
          checkRememberMe={this.checkRememberMe}
          username={username}
          password={password}
          failedLoginAttempt={failedLoginAttempt}
          verifyContactIsActive={this.verifyContactIsActive}
          loginInfo={loginInfo}
          showReCaptcha={showReCaptcha}
          onReCaptchaSuccess={this.onReCaptchaSuccess}
          {...this.props}
        />
      )
    }
  }

  return compose(consumerConnector(), withRouter)(LoginHOC)
}

export default (loginHOC)
