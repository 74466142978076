import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Slider, Switch } from 'antd'
import equal from 'deep-equal'

class TimeSlider extends Component {
    static propTypes = {
      min: PropTypes.number,
      max: PropTypes.number,
      militaryTime: PropTypes.bool,
      showSwitch: PropTypes.bool,
      width: PropTypes.number,
      splitID: PropTypes.number,
      defaultVal: PropTypes.array,
      handleDisableChange: PropTypes.func,
      viewOnly: PropTypes.bool,
      handleOnChange: PropTypes.func,
      disabled: PropTypes.bool,
    }


    static defaultProps = {
      militaryTime: false,
      min: 0,
      max: 1440,
      showSwitch: true,
      width: 300,
      splitID: 0,
      defaultVal: null,
      handleDisableChange: null,
      viewOnly: false,
      handleOnChange: null,
      disabled: false,
    }

    constructor(props) {
      super(props)
      const { min, max } = this.props
      this.state = {
        // disabled: disabled || false,
        rangeValue: [min, max],
      }
    }

    componentDidMount() {
      const { defaultVal } = this.props
      // if default values
      if (defaultVal && defaultVal[0] != null) {
        this.setState({ rangeValue: [defaultVal[0], defaultVal[1]] })
      }
    }

    componentDidUpdate(prevProps) {
      const { defaultVal } = this.props
      // if default values
      if (!equal(prevProps.defaultVal, defaultVal)) {
        /**
         * this component is dynamic, sometimes it doesn't have defaultVal.
         * We just keep a local state
         */
        const start = defaultVal && defaultVal[0] ? defaultVal[0] : 0
        const end = defaultVal && defaultVal[1] ? defaultVal[1] : 1440
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ rangeValue: [start, end] })
      }
    }

    handleDisableChange = (disabledVal) => {
      const { handleDisableChange } = this.props
      if (handleDisableChange) {
        handleDisableChange(!disabledVal)
      }
      // this.setState({ disabled: !disabledVal })
    }

    handleOnChange = (value) => {
      const { handleOnChange, splitID } = this.props
      this.setState({ rangeValue: value })
      // pass function to store/update value in parent
      if (handleOnChange) {
        handleOnChange(value, splitID)
      }
      // console.log(value)
    }

    convertToTimeString = (value) => {
      const { militaryTime } = this.props
      // do modulo to divide and find remainder
      // make value go from 0 to 1439
      let hours = Math.floor(value / 60)
      const minutes = value % 60
      let meridiem = 'am'
      // not military time
      if (!militaryTime) {
        if (hours > 11 && hours < 24) meridiem = 'pm'
        if (hours > 12) hours -= 12
        if (hours === 0) hours = 12
      } else meridiem = ''

      const timeObj = {
        h: hours,
        m: minutes,
        md: meridiem,
      }
      // return object if you want the object
      // return timeObj
      const paddedMin = minutes.toString().padStart(2, '0')
      return (`${timeObj.h}:${paddedMin} ${timeObj.md}`)
    }

    isDisabled = () => {
      const { viewOnly, disabled } = this.props
      if (viewOnly) {
        return true
      }
      if (disabled !== null && disabled !== undefined) {
        return disabled
      }
      return false
      /* eslint-disable react/destructuring-assignment */
      // return this.state.disabled
    }


    render() {
      // NOTE: use props disable value to govern switch check
      const {
        // disabled,
        rangeValue,
      } = this.state
      const {
        min, max, width, disabled, showSwitch, viewOnly,
      } = this.props
      const strt = this.convertToTimeString(rangeValue[0])
      const end = this.convertToTimeString(rangeValue[1])
      const disableVal = this.isDisabled()

      // width: '300px',
      return (
        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 5px 10px 5px' }}>
          <label style={{ whiteSpace: 'no-wrap', width: 65 }}>
            {` ${strt} `}
          </label>
          <Slider
            range
            min={min}
            max={max}
            step={15}
            value={rangeValue}
            disabled={disableVal}
            onChange={this.handleOnChange}
            tooltipVisible={false}
            style={{ width, margin: 'auto 20px auto 20px' }}
          />
          <label style={{ whiteSpace: 'no-wrap', width: 65 }}>
            {` ${end} `}
          </label>
          <Switch
            size="small"
            checked={!disabled}
            onChange={this.handleDisableChange}
            style={{ display: showSwitch ? 'block' : 'none', marginLeft: 20 }}
            disabled={viewOnly ? true : undefined}
          />
        </div>
      )
    }
}

export default TimeSlider
