import React, { Component } from 'react'
import { compose } from 'react-apollo'
import {
  Button, Dropdown, Badge, Switch,
} from 'antd'
import PropTypes from 'prop-types'
import notifIcon from '@assets/img/icons/nav/notifBell.svg'
import NotificationCard from '@mol/notificationCard'
import helper from '@helper'
import NotificationsHOC from '@hoc/socketNotifications.hoc'
import SectionScrollView from '@mol/sectionScrollView'

const styles = {
  tableOuterWrapper: {
    height: '100%',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  tableInnerWrapper: {
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
}

class NotificationMenu extends Component {
  static propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    buildAlertDetails: PropTypes.func.isRequired,
    toggleDropDownVisible: PropTypes.func.isRequired,
    updateDeliverQuietlyPref: PropTypes.func.isRequired,
    handleVisibleChange: PropTypes.func.isRequired,
    deliverQuietly: PropTypes.bool,
    dropDownVisible: PropTypes.bool,
    pushNotifs: PropTypes.array,
    highlightedNotif: PropTypes.string,
  }

  static defaultProps = {
    pushNotifs: [],
    deliverQuietly: false,
    dropDownVisible: false,
    highlightedNotif: null,
  }

  menuItems = () => {
    const {
      pushNotifs, clearAlerts, buildAlertDetails, highlightedNotif,
    } = this.props
    const pushNotifsClone = JSON.parse(JSON.stringify(pushNotifs))
    return (
      pushNotifsClone.map(notif => (
        <NotificationCard
          highlightOnMount={highlightedNotif === notif.id}
          key={notif.id}
          event={notif.raw.event.type}
          title={notif.raw.event.type}
          alias={notif.alert.alias}
          driver={notif.alert.driver}
          time={notif.alert.time}
          onClose={() => { clearAlerts(notif.id) }}
          details={buildAlertDetails(notif)}
        />
      ))
    )
  }

  renderMenuItem = (section, item) => {
    const {
      clearAlerts, buildAlertDetails, highlightedNotif,
    } = this.props
    return (
      <NotificationCard
        highlightOnMount={highlightedNotif === item.id}
        key={item.id}
        event={item.raw.event.type}
        title={item.raw.event.type}
        alias={item.alert.alias}
        driver={item.alert.driver}
        time={item.alert.time}
        onClose={() => { clearAlerts(item.id) }}
        details={buildAlertDetails(item)}
      />
    )
  }

  clearAllAlerts = () => {
    const { clearAlerts, toggleDropDownVisible } = this.props
    clearAlerts()
    toggleDropDownVisible()
  }

  dropDownMenu = () => {
    const {
      pushNotifs, deliverQuietly, updateDeliverQuietlyPref, highlightedNotif,
    } = this.props

    const notifsArray = [{ id: 'notifs', notifs: pushNotifs }]

    return (
      <div style={{
        width: 300,
        height: 400,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 4,
        boxShadow: '0 2px 8px rgb(0 0 0 / 15%)',
      }}
      >
        <div
          style={{
            margin: '0px 15px',
            padding: '15px 0px',
            borderBottom: '1px solid #e9e9e9',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexShrink: 0,
          }}
        >
          <div style={{ fontSize: 14, color: '#737373' }}>
            Deliver Push Notifications Quietly
          </div>
          <Switch size="small" checked={deliverQuietly} onChange={updateDeliverQuietlyPref} />
        </div>
        <div style={{ flexGrow: 1, overflow: 'auto' }}>
          <div style={styles.tableOuterWrapper}>
            <div style={styles.tableInnerWrapper}>
              <SectionScrollView
                sections={notifsArray}
                selectedId={`notifs-${highlightedNotif}`}
                renderItem={this.renderMenuItem}
                sectionIdKey="id"
                itemIdKey="id"
                itemArrayKey="notifs"
                noHeaders
                noFooters
                delayScrollToElement={500}
              />
            </div>
          </div>
          {/* {this.menuItems()} */}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: '1px solid #e9e9e9',
            alignItems: 'center',
            padding: '5px 20px',
          }}
        >
          {`${pushNotifs.length} Notifications`}
          <Button
            type="danger"
            ghost
            onClick={() => this.clearAllAlerts()}
          >
            Clear All
          </Button>
        </div>
      </div>
    )
  }

  render() {
    const { dropDownVisible, pushNotifs, handleVisibleChange } = this.props

    // this is to handle the ant design's badge overflow
    let rightMargin = 0
    if (pushNotifs.length > 0) {
      rightMargin = 6
    } if (pushNotifs.length > 9) {
      rightMargin = 16
    }

    return (
      <div
        style={{
          marginTop: 5,
          marginBottom: 5,
          marginLeft: 5,
          padding: '4px 0 4px 10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: rightMargin,
          transition: 'all 0.05s ease-out',
        }}
      >
        <Dropdown
          visible={dropDownVisible}
          overlay={this.dropDownMenu()}
          onVisibleChange={handleVisibleChange}
          trigger={['click']}
        >
          <Badge
            count={pushNotifs.length}
            style={{
              backgroundColor: 'red',
              boxShadow: 0,
            }}
            className="notificationBadge"
            offset={pushNotifs.length > 9 ? [6, 0] : [3, 0]}
          >
            <img src={notifIcon} alt="notifications" />
          </Badge>
        </Dropdown>
      </div>
    )
  }
}

export default compose(
  helper(),
  NotificationsHOC(),
)(NotificationMenu)
