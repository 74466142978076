import React from 'react'
import PropTypes from 'prop-types'
import helper from '@helper'
import DriverScorecardView from '@org/driverScorecardView'
import FullScreenContainer from './fullScreen.container'

class DriverScorecardContainer extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    appHeight: PropTypes.number.isRequired,
  }

  state = {
    dscNav: 'dashboard',
  }

  /**
   * @param {*string} navString
   * @description Sets the nav state given string.
   *  Either 'devices', 'lanmarks', or 'distance'
   */
  setDSCNav = (navString) => {
    if (['dashboard', 'exceptions', 'standings'].includes(navString)) {
      this.setState({ dscNav: navString })
    }
  }

  render() {
    const { wMatrix, appHeight } = this.props
    const { dscNav } = this.state
    return (
      <FullScreenContainer
        heading={wMatrix('driverScorecard')}
        filled
      >
        <DriverScorecardView
          dscNav={dscNav}
          setDSCNav={this.setDSCNav}
          appHeight={appHeight}
        />
      </FullScreenContainer>
    )
  }
}

export default helper()(DriverScorecardContainer)
