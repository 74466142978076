import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DirectionStep from '@atom/directionStep'
import helper from '@helper'

const styles = {
  blueHeader: {
    padding: '4px',
    display: 'flex',
    flexShrink: '0',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    alignItems: 'baseline',
    color: 'white',
    backgroundColor: '#102A96',
    fontSize: '13px',
  },
  p: {
    margin: 0,
    padding: '5px 0 5px 10px',
  },
  span: {
    display: 'inline-block',
    width: 40,
  },
  details: {
    fontSize: 20,
    padding: '5px 0 5px 20px',
    margin: 0,
  },
  directionFoorter: {
    height: 25,
    backgroundColor: '#102A96',
  },
}

class DirectionPanel extends Component {
  static propTypes = {
    directionLeg: PropTypes.object,
    selectedDevice: PropTypes.object,
    trim: PropTypes.func.isRequired,
  }

  static defaultProps = {
    directionLeg: null,
    selectedDevice: null,
  }

  state = {}

  renderDirectionHeader = () => {
    const { directionLeg, selectedDevice, trim } = this.props
    if (directionLeg) {
      return (
        <div style={styles.blueHeader}>
          <p style={styles.p} title={selectedDevice.locate.address.full}>
            <span style={styles.span}>From: </span>
            {trim(selectedDevice.locate.address.full, 35)}
          </p>
          <p style={styles.p} title={directionLeg.end_address}>
            <span style={styles.span}>To:</span>
            {trim(directionLeg.end_address, 35)}
          </p>
        </div>
      )
    }
    return null
  }

  renderDirectionDetails = () => {
    const { directionLeg } = this.props
    if (directionLeg) {
      return (
        <div>
          {
          // todo, use directionLeg.distance.value for kilometers/miles
        }
          <p style={styles.details}>
            {directionLeg.distance.text}
            . About
            {' '}
            {directionLeg.duration.text}
          </p>
        </div>
      )
    }
    return null
  }

  renderDirectionSteps() {
    const { directionLeg } = this.props
    if (directionLeg && directionLeg.steps) {
      return directionLeg.steps.map((step, i) => (
        <DirectionStep
          key={step.encoded_lat_lngs}
          step={step}
          stepNumber={i + 1}
          ifLastOne={(i === directionLeg.steps.length - 1)}
        />
      ))
    }
    return null
  }

  /**
   * @private
   * this is a placeholder for future features like "send to driver"
   */
  renderDirectionFooter = () => (
    <div style={styles.directionFoorter}> </div>
  )

  render() {
    return (
      <>
        {this.renderDirectionHeader()}
        {this.renderDirectionDetails()}
        <div
          style={{
            flex: '1 1 0%',
            position: 'relative',
            minHeight: 0,
            overflow: 'hidden auto',
          }}
        >
          {this.renderDirectionSteps()}
        </div>
        {this.renderDirectionFooter()}
      </>
    )
  }
}

export default helper()(DirectionPanel)
