import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DirectionPanel from '@mol/directionPanel'
import RightDrawer from './rightDrawer'

/**
 * @description A container of instance rightDrawer that holds the timeline.
 *
 * @Note
 *  - If basing your drawer component on this, note that you do not *NEED* to keep track of the
 * expanded state. This is a special case. This component uses it to dynamically render its contents
 */

export default class DirectionDrawer extends Component {
  static propTypes = {
    show: PropTypes.bool,
    directionLeg: PropTypes.object,
    selectedDevice: PropTypes.object,
  }

  static defaultProps = {
    show: true,
    directionLeg: null,
    selectedDevice: null,
  }

  state = {
    expanded: true,
  }

  expandGet = (expanded) => {
    this.setState({ expanded })
  }

  returnDirectionTab = () => {
    const { directionLeg, selectedDevice } = this.props
    const { expanded } = this.state
    return (
      expanded ? (
        <DirectionPanel
          directionLeg={directionLeg}
          selectedDevice={selectedDevice}
        />
      ) : null
    )
  }

  render() {
    const {
      show,
    } = this.props
    return (
      <RightDrawer
        show={show}
        tabs={[
          { name: 'Directions', id: '1' },
        ]}
        getExpansion={this.expandGet}
      >
        {this.returnDirectionTab()}
      </RightDrawer>
    )
  }
}
