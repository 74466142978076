import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EventIcon from '@atom/eventIcon'
import ButtonWrapper from '@atom/textButton'
import OneClickButton from '@page/main/atoms/oneClickButton'
import CustomIcon from '@atom/icon'
import helper from '@helper'
import moment from 'moment'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: '0',
  },
  details: {
    flexGrow: 1,
    // backgroundColor: '#225511',
  },
  time: {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 4px',
    minWidth: '67px',
  },
  timeDate: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 4px',
    minWidth: '67px',
  },
  textContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // margin top to align with event icon
    marginTop: '18px',
    marginRight: 5,
    width: '100%',
    // marginBottom: '15px',
  },
  eventSpeedDurationContainer: {
    fontSize: 12,
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
  },
  eventLineContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '38px',
    flexShrink: 0,
  },
  hourContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '38px',
    flexShrink: 0,
    backgroundColor: '#EBEBEB',
  },
  lineTop: {
    width: '4px',
    borderRadius: '0px 0px 4px 4px',
    minHeight: '10px',
    backgroundColor: '#D8D8D8',
  },
  lineBottom: {
    width: '4px',
    borderRadius: '4px 4px 0px 0px',
    minHeight: '5px',
    backgroundColor: '#D8D8D8',
    flexGrow: '1',
  },
  image: {
    marginTop: '5px',
    marginBottom: '5px',
  },
}

class TimelineCard extends Component {
  static propTypes = {
    /** @helper */
    wMatrix: PropTypes.func.isRequired,
    speedToString: PropTypes.func.isRequired,
    minutesToString: PropTypes.func.isRequired,
    /** @parent */
    eventToString: PropTypes.func.isRequired,
    event: PropTypes.string,
    address: PropTypes.object,
    landmarks: PropTypes.array,
    details: PropTypes.object,
    time: PropTypes.string,
    expanded: PropTypes.bool,
    focused: PropTypes.bool,
    onClickEvent: PropTypes.func,
    disabled: PropTypes.bool,
    date: PropTypes.string,
    heading: PropTypes.number,
    speed: PropTypes.number,
    requestCameraClipUpload: PropTypes.func,
    selectModal: PropTypes.func,
    videoClip: PropTypes.object,
    device: PropTypes.object,
    datetime: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    drivers: PropTypes.array,
  }

  static defaultProps = {
    event: 'Locate',
    address: { street: '' },
    landmarks: null,
    details: null,
    time: '',
    expanded: true,
    focused: false,
    onClickEvent: null,
    disabled: false,
    date: null,
    speed: null,
    heading: null,
    videoClip: null,
    device: {
      unit: {
        abbr: 'OBD',
      },
    },
    datetime: null,
    lat: null,
    lng: null,
    drivers: null,
    selectModal: null,
    requestCameraClipUpload: null,
  }

  /**
   * @private
   * Renders event line
   */
  returnEventLine = () => {
    const {
      event, time, expanded, heading, speed,
    } = this.props
    const hourEvent = event === 'hour'
    // if hour block, return hour block
    if (hourEvent) {
      if (!expanded) {
        return (
          <div style={styles.eventLineContainer}>
            <div style={styles.lineTop} />
            <h5 style={{ whiteSpace: 'nowrap' }}>{time}</h5>
            <div style={styles.lineBottom} />
          </div>
        )
      }
      return null
    }
    return (
      <div style={styles.eventLineContainer}>
        <div style={styles.lineTop} />
        <EventIcon event={event} heading={heading} speed={speed} imgStyle={styles.image} />
        <div style={styles.lineBottom} />
      </div>
    )
  }

  returnTime = () => {
    const { time, date, device } = this.props
    const formattedTime = moment(time, 'h:mm:ss A').format('h:mm a')
    if (device.timelineRange === 'thirty-history') {
      return (
        <div style={styles.timeDate}>
          <h5>
            {formattedTime}
          </h5>
          <h5>
            {date}
          </h5>
        </div>
      )
    }
    return (
      <h5 style={styles.time}>
        {formattedTime}
      </h5>
    )
  }

  /**
   * Returns location component. This can be a list of landmarks or an address.
   */
  returnLocation = () => {
    const { address, landmarks } = this.props
    if (landmarks && landmarks.length > 0) {
      let landmarksString = ''
      // create landmark string
      for (let i = 0; i < landmarks.length; i += 1) {
        landmarksString += `${landmarks[i].name}`
        if (i !== landmarks.length - 1) {
          landmarksString += ', '
        }
      }
      return (
        <div style={{ fontSize: 12 }}>{landmarksString}</div>
      )
    }
    return (
      <div style={{ fontSize: 12 }}>{`${address.street || ''} ${address.city || ''}${address.city ? ', ' : ''}${address.state || ''}`}</div>
    )
  }

  /**
   * Returns mosl details if applicable
   */
  returnSpeedingDetails = () => {
    const { details, wMatrix, speedToString } = this.props
    if (details && details.speed) {
      if (details.mosl) {
        return (
          <div style={{ fontSize: 12 }}>{`${speedToString(details.mosl)} ${wMatrix('overTheLimit')}`}</div>
        )
      } if (details.roadspeed) {
        const mosl = details.speed - details.roadspeed
        return (
          <div style={{ fontSize: 12 }}>{`${speedToString(mosl)} ${wMatrix('overTheLimit')}`}</div>
        )
      }
    } return null
  }

  /**
   * Returns View Clip Button If applicable
   */
  returnViewClipButton = () => {
    const {
      selectModal, event, videoClip,
      device, date, time, address, landmarks,
    } = this.props
    const { replayURL } = videoClip
    const camModalProps = {
      clipType: 'event',
      device,
      url: replayURL,
      details: {
        eventType: event,
        date,
        time,
        landmarks,
        address,
      },
    }
    return (
      <ButtonWrapper
        onClick={() => selectModal('camera', camModalProps)}
        useAsWrapper
        // disabled={!uploaded}
      >
        <CustomIcon
          type="tv-play"
          width={22}
        />
      </ButtonWrapper>
    )
  }

  /**
   * Returns Clip Upload Button
   * @param {object} videoClip optional loading state of clip
   */
  returnUploadButton = (videoClip) => {
    let loading = true
    if (videoClip) {
      if (videoClip.status) {
        const failedStatuses = ['no video', 'provider_failed']
        loading = !failedStatuses.includes(videoClip.status.toLowerCase())
      } else {
        loading = false
      }
    }
    const {
      requestCameraClipUpload, wMatrix,
      device, event, datetime, lat, lng,
      address, landmarks, details, drivers,
    } = this.props
    return (
      <OneClickButton
        loading={loading}
        title={wMatrix('clipUploadWarning')}
        onClick={() => {
          requestCameraClipUpload(
            device.id,
            datetime,
            event,
            lat,
            lng,
            address,
            landmarks,
            drivers,
            details,
          )
        }}
        okText={wMatrix('upload')}
        cancelText={wMatrix('Cancel')}
      />
    )
  }

  /**
   * Returns the appropriate camera button if applicable
   */
  returnCameraButton = () => {
    const { videoClip, device, event } = this.props
    if (!device.type.includes('-CAMERA') || event === 'locate') return null
    const canView = videoClip && videoClip.status && videoClip.status.toLowerCase() === 'uploaded'
    if (canView) {
      return (
        <div style={{ marginRight: 8 }}>
          {this.returnViewClipButton()}
        </div>
      )
    }
    return (
      <div style={{ marginRight: 8 }}>
        {this.returnUploadButton(videoClip)}
      </div>
    )
  }

  /**
   * @private
   * Returns formatted content of timeline card
   */
  returnContent = () => {
    const {
      event, speed, speedToString, eventToString, details, minutesToString,
    } = this.props
    return (
      <div style={styles.textContainer}>
        <div style={styles.eventSpeedDurationContainer}>
          <h4 style={{ fontSize: 12 }}>{eventToString(event)}</h4>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            {this.returnCameraButton()}
            {speed ? speedToString(speed) : null}
            {details && details.duration ? minutesToString(details.duration) : null}
            {details && details.dtc ? details.dtc : null}
          </div>
        </div>
        {this.returnLocation()}
        {this.returnSpeedingDetails()}
      </div>
    )
  }

  render() {
    const {
      expanded, focused, onClickEvent, event, disabled,
    } = this.props
    const eventHour = event === 'hour'
    return (
      <div
        style={{
          ...styles.container,
          justifyContent: expanded ? 'flex-start' : 'center',
          backgroundColor: focused ? '#102A961A' : 'transparent', // 1A = 10% opacity
        }}
        onClick={disabled ? undefined : onClickEvent}
        tabIndex="-1"
        role="button"
      >
        {expanded && !eventHour ? this.returnTime() : null}
        {this.returnEventLine()}
        {expanded && !eventHour ? this.returnContent() : null}
      </div>
    )
  }
}

export default helper()(TimelineCard)
