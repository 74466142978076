import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { compose } from 'react-apollo'
import mutationConnector from '@graphql/mutationConnector'
import { createReportSchedule, updateReportSchedule, deleteReportSchedule } from '@graphql/mutation'
import helper from '@helper'

const scheduledReportHOC = () => (WrappedComponent) => {
  class ScheduledReportHOC extends React.Component {
    static propTypes = {
      wMatrix: PropTypes.func.isRequired,
      scheduledInputs: PropTypes.object,
      createSchedule: PropTypes.func.isRequired,
      updateSchedule: PropTypes.func.isRequired,
      deleteSchedule: PropTypes.func.isRequired,
      refetchMetaAndSchedule: PropTypes.func.isRequired,
      showModal: PropTypes.bool.isRequired,
      handleShowModal: PropTypes.func.isRequired,
      generateFormItem: PropTypes.func.isRequired,
      reportType: PropTypes.string.isRequired,
      reportInputs: PropTypes.array.isRequired,
      freqOptions: PropTypes.array,
      dateRangeOptions: PropTypes.array,
      selectReport: PropTypes.func,
      selectedReport: PropTypes.object,
    }

    static defaultProps = {
      scheduledInputs: null,
      freqOptions: [],
      dateRangeOptions: [],
      selectReport: null,
      selectedReport: null,
    }

    formatEventTypesForQuery = (events) => {
      if (!events) {
        return undefined
      }
      const eventArray = []
      if (events) {
        for (let i = 0; i < events.length; i += 1) {
          if (events[i].includes(',')) {
            const newEvents = events[i].split(',')
            eventArray.push(newEvents[0])
            eventArray.push(newEvents[1])
          } else {
            eventArray.push(events[i])
          }
        }
      }
      return eventArray
    }

    handleSave = async (values) => {
      try {
        const {
          createSchedule, updateSchedule, reportType, scheduledInputs, refetchMetaAndSchedule,
        } = this.props
        let emails = ''
        for (let i = 0; i < values.recipients.length; i += 1) {
          emails += `${values.recipients[i]},`
        }

        const variables = {
          name: values.scheduledReportName,
          type: reportType,
          // general filters
          deviceIds: values.deviceIds || [],
          groupIds: values.groupIds || null,
          labelIds: values.labelIds || null,
          driverIds: values.driverIds || undefined,
          landmarkIds: values.landmarkIds || undefined,
          // report schedule related
          dateRange: values.dateWindow,
          frequency: values.freq,
          recipients: emails.slice(0, -1),
          deliveryTime: moment(values.deliveryTime).format('h:mm A'),
          deliveryStartDate: moment(values.deliveryStartDate).format('YYYY-MM-DD'),
          // filters for special report
          speedThreshold: values.speedThreshold || values.speedThreshhold,
          events: this.formatEventTypesForQuery(values.events),
          serviceTypes: values.serviceTypes,
          serviceStatus: values.serviceStatus,
        }

        if (values.startTime) Object.assign(variables, { startTime: moment(values.startTime).format('h:mm A') })
        if (values.endTime) Object.assign(variables, { endTime: moment(values.endTime).format('h:mm A') })

        if (scheduledInputs) {
          variables.id = scheduledInputs.id
          await updateSchedule({ variables })
        } else {
          await createSchedule({ variables })
        }
        await refetchMetaAndSchedule()
      } catch (e) {
        throw e
      }
    }

    handleDelete = async () => {
      const { deleteSchedule, refetchMetaAndSchedule, scheduledInputs } = this.props
      try {
        await deleteSchedule({ variables: { id: scheduledInputs.id } })
        refetchMetaAndSchedule(true)
      } catch (e) {
        throw e
      }
    }

    render() {
      const {
        wMatrix, showModal, handleShowModal, generateFormItem, reportInputs, freqOptions,
        dateRangeOptions, scheduledInputs, reportType, selectedReport, selectReport,
      } = this.props
      return (
        <WrappedComponent
          wMatrix={wMatrix}
          handleSave={this.handleSave}
          handleDelete={this.handleDelete}
          showModal={showModal}
          handleShowModal={handleShowModal}
          generateFormItem={generateFormItem}
          freqOptions={freqOptions}
          dateRangeOptions={dateRangeOptions}
          reportInputs={reportInputs}
          scheduledInputs={scheduledInputs}
          reportType={reportType}
          selectedReport={selectedReport}
          selectReport={selectReport}
        />
      )
    }
  }
  return compose(
    helper(),
    mutationConnector(createReportSchedule, 'createSchedule'),
    mutationConnector(updateReportSchedule, 'updateSchedule'),
    mutationConnector(deleteReportSchedule, 'deleteSchedule'),
  )(ScheduledReportHOC)
}

export default scheduledReportHOC
