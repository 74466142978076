import React from 'react'
import {
  Table, Popconfirm, Icon, Button,
} from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'
import EditableCell from '@atom/logistics/editableTaskCell'
import LogisticsStatus from '@atom/logistics/status'
import ButtonWrapper from '@atom/textButton'
import helper from '@helper'

const styles = {
  label: {
    marginLeft: 4,
    marginRight: 4,
    whiteSpace: 'nowrap',
    display: 'block',
    color: '#4482FF',
    cursor: 'pointer',
  },
}

const dateFormat = 'YYYY-MM-DD h:mm a'

export const EditableContext = React.createContext()

class EditableTaskTable extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    validateMultipleEmails: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    tasks: PropTypes.array.isRequired,
    updateTask: PropTypes.func.isRequired,
    deleteTask: PropTypes.func.isRequired,
    createTask: PropTypes.func.isRequired,
    destination: PropTypes.object.isRequired,
  }

  state = {
    editingKey: '',
    newItem: null,
  }

  isEditing = (record) => {
    const { editingKey } = this.state
    return record.id === editingKey
  }

  getInputType = (index) => {
    switch (index) {
      case 'completed':
        return 'checkbox'

      case 'type':
        return 'select'

      default:
        return 'text'
    }
  }


  /**
   * @description Conditionally create table columns
   */
  buildBaseColumns = () => {
    const { wMatrix, validateMultipleEmails } = this.props
    return [
      {
        title: ' ',
        dataIndex: 'operation',
        width: '5%',
        render: (text, record) => {
          if (this.isEditing(record)) {
            return (
              <div>
                <ButtonWrapper
                  onClick={() => this.save(record.id)}
                  useAsWrapper
                >
                  <div style={{ ...styles.label, fontSize: '12px' }}>
                    <Icon style={{ margin: '5px 5px 5px 0px' }} type="save" />
                    {wMatrix('Save')}
                  </div>
                </ButtonWrapper>
                <ButtonWrapper
                  onClick={this.cancel}
                  useAsWrapper
                >
                  <div style={{ ...styles.label, fontSize: '12px' }}>
                    {wMatrix('Cancel')}
                  </div>
                </ButtonWrapper>
              </div>
            )
          }
          return (
            <ButtonWrapper
              onClick={() => this.edit(record.id)}
              useAsWrapper
              disabled={record.status === 'completed'}
            >
              <Icon
                type="edit"
                theme="twoTone"
                twoToneColor={record.status === 'completed' ? '#d8d8d8' : '#4482ff'}
              />
            </ButtonWrapper>
          )
        },
      },
      {
        title: wMatrix('type'),
        dataIndex: 'type',
        editable: true,
        width: '10%',
        render: (text, record) => wMatrix(record.type),
      },
      {
        title: wMatrix('alias'),
        dataIndex: 'alias',
        editable: true,
        width: '10%',
        required: true,
        placeholder: wMatrix('alias'),
      },
      {
        title: wMatrix('notes'),
        dataIndex: 'notes',
        editable: true,
        width: '15%',
        placeholder: wMatrix('notes'),
      },
      {
        title: wMatrix('email'),
        dataIndex: 'email',
        editable: true,
        width: '15%',
        placeholder: wMatrix('email'),
        validator: {
          validator: async (rule, value) => {
            if (!value || value === '' || validateMultipleEmails(value)) {
              return Promise.resolve()
            }
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject(wMatrix('Invalid email'))
          },
        },
      },
      {
        title: wMatrix('status'),
        dataIndex: 'status',
        editable: false,
        width: '10%',
        render: (text, record) => <LogisticsStatus wMatrix={wMatrix} status={record.status} />,
      },
      {
        title: wMatrix('Completed'),
        key: 'completed',
        dataIndex: 'completed',
        editable: true,
        width: '15%',
        render: (text, record) => {
          if (record.completionDate) return moment(record.completionDate).format(dateFormat)
          return null
        },
      },
      {
        title: ' ',
        dataIndex: 'delete',
        width: '5%',
        render: (text, record) => (
          <span style={{ verticalAlign: 'middle' }}>
            <Popconfirm
              title={`${wMatrix('okToDelete')}?`}
              okText={wMatrix('Delete')}
              cancelText={wMatrix('Cancel')}
              onConfirm={() => this.delete(record.id)}
              disabled={record.status === 'completed' || record.id === 'newItem'}
            >
              <label style={styles.label}>
                <Icon
                  style={{ cursor: 'pointer' }}
                  theme="twoTone"
                  twoToneColor={record.status === 'completed' || record.id === 'newItem' ? '#d8d8d8' : '#f64744'}
                  type="delete"
                />
              </label>
            </Popconfirm>
          </span>
        ),
      },
    ]
  }

  cancel = () => {
    this.setState({
      editingKey: '',
      newItem: null,
    })
  }

  /**
   * Handles the saving of both existing items as well as new items to be created.
   * @param {String || Number} key Key of row to be saved. Normally a number, but if it is a
   * new item, the key will be 'newItem'
   */
  save = (key) => {
    const {
      updateTask, createTask, tasks, form,
    } = this.props
    const { newItem } = this.state
    // only edits one task at a time
    form.validateFields(async (error, row) => {
      if (error) return
      const newData = []
      // if a newItem exists, add it to the datasource to be checked
      const dataSource = [...tasks]
      if (newItem) {
        dataSource.push(newItem)
      }
      dataSource.forEach(task => newData.push(task))
      const index = newData.findIndex(item => key === item.id)
      if (index > -1) {
        const item = newData[index]
        const updatedData = { ...item, ...row }
        // If saving a new item, create the task
        if (key === 'newItem') {
          await createTask({
            alias: updatedData.alias,
            type: updatedData.type,
            notes: updatedData.notes,
            email: updatedData.email,
            destinationId: updatedData.destinationId,
          })
          this.setState({ newItem: null })
        } else {
          // otherwise, update the existing task
          await updateTask(updatedData)
        }
        this.setState({ editingKey: '' })
      } else {
        // newData.push(row)
        this.setState({ editingKey: '' })
      }
    })
  }

  delete = (key) => {
    const { deleteTask } = this.props
    deleteTask(key)
    this.setState({ editingKey: '' })
  }

  /**
   * gets/sets the row that is being edited by its row key. Also resets newItem to null
   * just in case user did not click cancel on an unsaved new item.
   * @param {Number} key Task Id key
   */
  edit = (key) => {
    const { form } = this.props
    form.resetFields()
    this.setState({
      editingKey: key,
      newItem: null,
    })
  }

  handleAddItem = () => {
    const { destination, form } = this.props
    form.resetFields()
    const tempEditingKey = 'newItem'
    // set state to add new temp item
    const tempItem = {
      id: tempEditingKey, // temp Id,
      alias: null, // !
      type: 'package', // !
      destinationId: destination.id, // !
      notes: null,
      email: null,
      status: destination.status,
    }

    this.setState({
      newItem: tempItem,
      editingKey: tempEditingKey,
    })
  }

  render() {
    const { tasks, form, wMatrix } = this.props
    const { editingKey, newItem } = this.state

    const components = {
      body: {
        cell: EditableCell,
      },
    }

    const baseColumns = this.buildBaseColumns()
    const columns = baseColumns.map((col) => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: this.getInputType(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
          required: col.required,
          placeholder: col.placeholder,
          validator: col.validator,
        }),
      }
    })

    let currentEditingKey = editingKey
    const dataSource = [...tasks]
    if (newItem) {
      dataSource.push(newItem)
      currentEditingKey = newItem.key
    }

    return (
      <div style={{ display: 'flex', marginTop: 10, flexDirection: 'column' }}>
        <EditableContext.Provider value={form}>
          <Table
            className="userTable listTableNoPagiArrow"
            components={components}
            dataSource={dataSource}
            columns={columns}
            rowKey="id"
            scroll={currentEditingKey !== '' ? { x: 1000 } : { x: false }}
            size="small"
            pagination={false}
          />
        </EditableContext.Provider>
        <Button
          onClick={this.handleAddItem}
          type="primary"
          style={{ margin: '10px 0 0 0', alignSelf: 'flex-start' }}
          icon="plus"
          disabled={!!newItem}
        >
          {wMatrix('addItem')}
        </Button>
      </div>
    )
  }
}

export default helper()(EditableTaskTable)
