import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PreferenceModal from '@org/modal/preferenceModal'
import AdminModal from '@org/modal/adminModal'
import DeviceManager from '@org/modal/deviceManagerModal'
import DensityModal from '@mol/densityModal'
import UserResources from '@org/modal/userResources'
import CameraClipModal from '@page/main/organisms/modal/cameraClipModal'
import LandmarkImportModal from '@org/modal/landmarkImportModal'

import helper from '@helper'

class ModalContainer extends Component {
  static propTypes = {
    modalUI: PropTypes.object.isRequired,
    wMatrix: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    refetchDevices: PropTypes.func,
    devices: PropTypes.array,
    filterDeviceByTag: PropTypes.func.isRequired,
    filterDevice: PropTypes.func.isRequired,
    getPermission: PropTypes.func.isRequired,
    /** @deviceList */
    refetchDeviceListDrivers: PropTypes.func.isRequired,
    selectedDevice: PropTypes.object,
    /** @Main */
    setNav: PropTypes.func.isRequired,
    updateClipSearch: PropTypes.func.isRequired,
    navPath: PropTypes.string.isRequired,
    NavEnum: PropTypes.object.isRequired,
    deviceListMode: PropTypes.string,
    mapIconMode: PropTypes.string,
    mapIconSizeMode: PropTypes.string,
    saveContactMeta: PropTypes.func.isRequired,
    /** @landmarksHOC */
    uploadLandmarks: PropTypes.func.isRequired,
    downloadLandmarkTemplate: PropTypes.func.isRequired,
    uploadLandmarksLoading: PropTypes.bool,
    uploadLandmarksAndDeleteLoading: PropTypes.bool,
    landmarkImportAlert: PropTypes.object.isRequired,
    landmarkImportAlertOnClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    devices: [],
    refetchDevices: null,
    selectedDevice: null,
    deviceListMode: 'comfortable',
    mapIconMode: 'comfortable',
    mapIconSizeMode: 'large',
    uploadLandmarksLoading: false,
    uploadLandmarksAndDeleteLoading: false,
  }

  showModal = (type, data) => {
    const {
      modalUI, closeModal, refetchDevices, devices,
      filterDeviceByTag, filterDevice,
      getPermission, wMatrix,
      refetchDeviceListDrivers,
      updateClipSearch, navPath, NavEnum, setNav,
      deviceListMode, mapIconMode, mapIconSizeMode,
      saveContactMeta, uploadLandmarks, downloadLandmarkTemplate,
      uploadLandmarksLoading, uploadLandmarksAndDeleteLoading,
      landmarkImportAlert, landmarkImportAlertOnClose,
    } = this.props

    switch (type) {
      case 'admin':
        return (
          <AdminModal
            refetchDevices={refetchDevices}
            refetchDeviceListDrivers={refetchDeviceListDrivers}
            devices={devices}
            wMatrix={wMatrix}
            closeModal={closeModal}
            filterDeviceByTag={filterDeviceByTag}
            filterDevice={filterDevice}
            modalUI={modalUI}
            getPermission={getPermission}
          />
        )
      case 'preference':
        return <PreferenceModal {...this.props} />
      case 'deviceManager':
        return (
          <DeviceManager
            {...this.props}
            data={data || undefined}
          />
        )
      case 'userResources':
        return (
          <UserResources
            ifShowModal={modalUI.ifShowModal}
            closeModal={closeModal}
            wMatrix={wMatrix}
          />
        )
      case 'camera': {
        const {
          url, clipType, device, details,
        } = data
        return (
          <CameraClipModal
            ifShowModal={modalUI.ifShowModal}
            closeModal={closeModal}
            device={device}
            url={url}
            type={clipType}
            details={details}
            updateClipSearch={updateClipSearch}
            setNav={setNav}
            navPath={navPath}
            NavEnum={NavEnum}
          />
        )
      }
      case 'density':
        return (
          <DensityModal
            ifShowModal={modalUI.ifShowModal}
            closeModal={closeModal}
            deviceListMode={deviceListMode}
            mapIconMode={mapIconMode}
            mapIconSizeMode={mapIconSizeMode}
            saveContactMeta={saveContactMeta}
            wMatrix={wMatrix}
          />
        )
      case 'landmarkImport':
        return (
          <LandmarkImportModal
            wMatrix={wMatrix}
            ifShowModal={modalUI.ifShowModal}
            closeModal={closeModal}
            uploadLandmarks={uploadLandmarks}
            downloadLandmarkTemplate={downloadLandmarkTemplate}
            uploadLandmarksLoading={uploadLandmarksLoading}
            uploadLandmarksAndDeleteLoading={uploadLandmarksAndDeleteLoading}
            landmarkImportAlert={landmarkImportAlert}
            landmarkImportAlertOnClose={landmarkImportAlertOnClose}
          />
        )
      default:
        return <div />
    }
  }

  render() {
    const { modalUI } = this.props
    return (
      this.showModal(modalUI.modalType, modalUI.modalProps)
    )
  }
}

export default helper()(ModalContainer)
