import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Input } from 'antd'
import wheel from '@assets/img/icons/rainbow_svg.svg'
import { CirclePicker } from 'react-color'
import helper from '@helper'

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'flex-start',
  },
}

class MakeGroup extends Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    toggleColorPicker: PropTypes.func.isRequired,
    name: PropTypes.string,
    changeGroupName: PropTypes.func.isRequired,
    changeColor: PropTypes.func.isRequired,
    show: PropTypes.bool,
  }

  static defaultProps = {
    name: null,
    show: true,
  }

  defaultcolorBalls = color => ({
    height: '28px',
    width: '28px',
    margin: '5px',
    borderRadius: '13px',
    backgroundColor: color,
  })

  showCreationBar = (showBar) => {
    const {
      wMatrix, toggleColorPicker, name, changeGroupName, changeColor,
    } = this.props
    if (showBar) {
      return (
        <div style={styles.mainContainer}>
          <label style={{ paddingRight: 15, fontWeight: 500 }}>{wMatrix('Name')}</label>
          <Input style={{ width: 300, margin: 10 }} className="groupNameInput" placeholder="e.g. Dallas" value={name} onChange={changeGroupName} />
          <CirclePicker colors={['#D0021B', '#F5A623', '#50E3C2', '#4A90E2', '#F8E71C']} circleSpacing={10} width={200} onChange={changeColor} />
          <div role="button" onClick={toggleColorPicker} tabIndex="0">
            <img src={wheel} style={{ height: 26, width: 26, marginLeft: 5 }} alt="question" />
          </div>
        </div>
      )
    }
    return <div />
  }

  render() {
    const { show } = this.props
    return (
      this.showCreationBar(show)
    )
  }
}

export default helper()(MakeGroup)
