/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable global-require */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DeviceIcon from '@atom/deviceIcon'
import landmarkFlag from '@assets/img/icons/landmarkFlag.svg'
import { Tooltip } from 'antd'
import helper from '@helper'

const styles = {
  /**
  * The whole view of the component
  */
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translate(-50%, -100%)', // puts bottom marker point on the exact location
    width: 60, // determines how big the whole marker will be
  },
  /**
   * The whole view of landmark marker component
   */
  landmarkContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translate(-50%, -100%)', // puts bottom marker point on the exact location
    width: 30, // determines how big the whole marker will be
  },
  /**
  * Header that holds the alias and driver name
  */
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    minHeight: 30,
    width: '150%',
    backgroundColor: 'rgba(0, 0, 0, .7)',
    borderRadius: 15,
    marginBottom: 1, /** @todo change when marker image is fixed to have no padding around it */
  },
  /**
  * Text style of alias
  */
  alias: {
    textAlign: 'center',
    color: 'white',
    // maxHeight: 10,
    maxWidth: '80%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    margin: 0,
  },
  /**
  * Text style of driver
  */
  driver: {
    textAlign: 'center',
    color: 'white',
    // maxHeight: 10,
    maxWidth: '80%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    margin: 0,
  },
  /**
  * Marker container for marker and icon
  */
  markerContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  /**
  * Marker
  */
  marker: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  /**
  * Arrow marker
  */
  arrow: {
    height: '50%',
    width: '50%',
    objectFit: 'contain',
    transformOrigin: 'center',
    // transformations done in the render function
  },
  /**
  * Vehicle icon
  */
  vehicle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -65%)',
    objectFit: 'contain',
    width: '75%',
  },
  vehicleLabel: {
    position: 'absolute',
    top: '43%',
    left: '50%',
    transform: 'translate(-50%, -65%)',
    objectFit: 'contain',
    width: '75%',
  },
  compass: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    objectFit: 'contain',
    width: '22%',
  },
}

class Marker extends Component {
  static propTypes = {
    alias: PropTypes.string,
    driver: PropTypes.string,
    state: PropTypes.string,
    view: PropTypes.string,
    vehicle: PropTypes.string,
    heading: PropTypes.number,
    icon: PropTypes.object,
    onClick: PropTypes.func,
    className: PropTypes.string,
    type: PropTypes.string, // ['device', 'landmark', etc]
    size: PropTypes.string, // ['small', 'medium', 'large']
  }

  static defaultProps = {
    alias: 'Alias 123',
    driver: 'James Harden',
    state: 'moving',
    view: 'comfortable',
    vehicle: 'delivery_truck',
    heading: 80,
    icon: null,
    className: null,
    type: 'device',
    size: 'large',
    onClick: () => { },
  }

  /**
   * @todo
   * aggregator
   */

  /**
   * Should the header be included in the marker
   */
  includeHeader = () => {
    const { view, alias, driver } = this.props
    if (view === 'comfortable' || view === 'cozy') {
      return (
        <div style={styles.header}>
          <p style={styles.alias}>{alias}</p>
          <p style={styles.driver}>{driver}</p>
        </div>
      )
    }

    return (<div />)
  }

  /**
   * Should the icon be included in the marker
   */
  includeVehicleIcon = () => {
    const { vehicle, view, icon } = this.props
    if (vehicle && (view === 'comfortable' || view === 'compact')) {
      return (
        <div style={
          icon.type === 'circle'
          || icon.type === 'verticalRectangle'
          || icon.type === 'horizontalRectangle'
            ? styles.vehicleLabel
            : styles.vehicle
          }
        >
          <DeviceIcon icon={icon} />
        </div>
      )
    }

    return (<div />)
  }

  /**
   * Used to place compass under moving icons with heading values
   */
  compass = () => {
    const { view, heading, state } = this.props
    if (view === 'comfortable' && heading && state === 'Moving') {
      const compassImg = require('@assets/img/icons/compass-white.svg')
      return (<img src={compassImg} style={Object.assign({ transform: `translate(-45%, 80%) rotate(${heading}deg)` }, styles.compass)} alt="" />)
    }
    return (<div />)
  }

  /**
   * @description Returns a landmark map marker
   * @returns {Component}
   */
  returnLandmarkMarker = () => {
    const { alias, className, onClick } = this.props
    return (
      <div style={styles.landmarkContainer} className={className} role="button" tabIndex="-1" onClick={() => { onClick() }}>
        <Tooltip title={`${alias}`} trigger={['hover', 'click']}>
          <div style={styles.markerContainer}>
            <img src={landmarkFlag} style={styles.marker} alt="" />
          </div>
        </Tooltip>
      </div>
    )
  }

  render({ state, view, heading, className, onClick, type, size } = this.props) {
    // If landmark icon, simply return static marker
    if (type === 'landmark') {
      return this.returnLandmarkMarker()
    }

    let marker = require('@assets/img/icons/marker-red.svg')
    const arrow = {}

    if (state === 'Stopped') {
      switch (view) {
        case 'cozy':
          marker = require('@assets/img/icons/location-dot-red.svg')
          break
        case 'compact':
          marker = require('@assets/img/icons/marker-red.svg')
          break
        // 'comfortable'
        default:
          marker = require('@assets/img/icons/marker-red.svg')
      }
    } else if (state === 'Parking') {
      switch (view) {
        case 'cozy':
          marker = require('@assets/img/icons/location-arrow-red.svg')
          break
        case 'compact':
          marker = require('@assets/img/icons/marker-red.svg')
          break
        // 'comfortable'
        default:
          marker = require('@assets/img/icons/marker-red.svg')
      }
    } else if (state === 'Moving') {
      switch (view) {
        case 'cozy':
          marker = require('@assets/img/icons/location-arrow-green.svg')
          break
        case 'compact':
          marker = require('@assets/img/icons/marker-green.svg')
          break
        // 'comfortable'
        default:
          marker = require('@assets/img/icons/marker-green.svg')
      }
    }

    Object.assign(arrow, styles.arrow)
    // transformations: rightmost first (ie: rotate about origin, then translate)
    Object.assign(arrow, { transform: `translate(0%, 45%) rotate(${heading}deg)` })

    const containerStyle = JSON.parse(JSON.stringify(styles.container))
    switch (size) {
      case 'small':
        containerStyle.width *= 0.25
        break
      case 'medium':
        containerStyle.width *= 0.5
        break
      default:
        containerStyle.width *= 1
    }

    return (
      <div style={containerStyle} className={className} role="button" tabIndex="-1" onClick={() => { onClick() }}>
        {this.includeHeader()}
        <div style={styles.markerContainer}>
          <img src={marker} style={view === 'cozy' ? arrow : styles.marker} alt="" />
          {this.includeVehicleIcon()}
          {this.compass()}
        </div>
      </div>
    )
  }
}

export default helper()(Marker)
