import React, { Component } from 'react'
import { Progress } from 'antd'
import PropTypes from 'prop-types'


export default class LabelledProgressBar extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    percent: PropTypes.number,
    status: PropTypes.string,
    style: PropTypes.object,
  }

  static defaultProps = {
    percent: 0,
    status: null,
    style: null,
  }

  render() {
    const {
      label,
      percent,
      status,
      style,
    } = this.props
    return (
      <div style={{ display: 'flex', width: '100%', ...style }}>
        <p style={{ marginRight: 30, marginBottom: 0 }}>{label}</p>
        <Progress
          percent={percent}
          status={status}
        />
      </div>
    )
  }
}
