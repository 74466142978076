import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReportsHOC from '@hoc/reports.hoc'
import { Breadcrumb } from 'antd'
import ReportMenu from '@mol/fullScreenMenu'
import ReportFilterContainer from '@mol/reports/reportFilterContainer'
import ReportTable from '@mol/reports/reportTable'
import ReportDownload from '@org/modal/reportDownloadModal'
import moment from 'moment'

const styles = {
  // this style is so that the menu can scroll independently of the content
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
  },
  rightWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    padding: '14px 20px',
    overflow: 'auto',
  },
  menu: {
    borderStyle: 'solid',
    borderWidth: '0px 1px 0px 0px',
    borderColor: '#97979740',
  },
}

class Reports extends Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    speedToString: PropTypes.func.isRequired,
    eventToString: PropTypes.func.isRequired,
    minutesToString: PropTypes.func.isRequired,
    filterMenu: PropTypes.func.isRequired,
    selectReport: PropTypes.func,
    selectedReport: PropTypes.object,
    reportMenu: PropTypes.array,
    reportLoading: PropTypes.bool,
    scheduledReports: PropTypes.array,
    freqOptions: PropTypes.array,
    dateRangeOptions: PropTypes.array,

    devices: PropTypes.array,
    landmarks: PropTypes.array,
    landmarksLoading: PropTypes.bool,

    drivers: PropTypes.array.isRequired,
    labels: PropTypes.array.isRequired,
    groups: PropTypes.array.isRequired,
    events: PropTypes.array.isRequired,
    serviceTypes: PropTypes.array.isRequired,
    serviceTicketStatus: PropTypes.array.isRequired,
    returnDevicesWithGroup: PropTypes.func.isRequired,
    returnDevicesWithLabel: PropTypes.func.isRequired,
    refetchMetaAndSchedule: PropTypes.func.isRequired,
    handleGenerateData: PropTypes.func.isRequired,
    generatedData: PropTypes.array.isRequired,
    reportDownloadMeta: PropTypes.object,
    setReportDownloadMeta: PropTypes.func.isRequired,
    ifShowReportDownloadModal: PropTypes.bool.isRequired,
    toggleReportDownloadModal: PropTypes.func.isRequired,
    createReportDownload: PropTypes.func.isRequired,
    showDownloadButton: PropTypes.bool,
    ifReportTooLarge: PropTypes.bool,
    /** @Main */
    mainNav: PropTypes.string,
  }

  static defaultProps = {
    reportMenu: [
      { title: 'Menu Item', id: 'g0', items: { title: 'Sub Item', id: 'id-1' } },
    ],
    selectReport: null,
    selectedReport: null,
    scheduledReports: [],
    devices: [],
    landmarks: [],
    landmarksLoading: false,
    reportLoading: false,
    freqOptions: [],
    dateRangeOptions: [],
    reportDownloadMeta: null,
    showDownloadButton: false,
    ifReportTooLarge: false,
    mainNav: 'reports',
  }

  findScheduleDateRange = () => {
    const {
      selectedReport, scheduledReports, dateRangeOptions,
    } = this.props
    if (selectedReport.category === 'scheduled') {
      let selectedSchedule = null
      for (let i = 0; i < scheduledReports.length; i += 1) {
        if (scheduledReports[i].reportId === selectedReport.id) {
          selectedSchedule = scheduledReports[i]
        }
      }

      let rangeTitle
      if (selectedSchedule) {
        rangeTitle = selectedSchedule.dateRange
      }

      let sampleReportStartDate = moment()
      let sampleReportEndDate = null
      /* check https://jsfiddle.net/pu5d7fx4/ for testing */
      switch (rangeTitle) {
        case 'current_day':
          sampleReportEndDate = moment(sampleReportStartDate, 'YYYY-MM-DD').add(1, 'day')
          break
        case 'yesterday':
          sampleReportEndDate = sampleReportStartDate
          sampleReportStartDate = moment(sampleReportStartDate, 'YYYY-MM-DD').subtract(1, 'day')
          break
        case 'current_week':
          sampleReportStartDate = moment(sampleReportStartDate, 'YYYY-MM-DD').weekday(0)
          sampleReportEndDate = moment(sampleReportStartDate, 'YYYY-MM-DD').weekday(7)

          break
        case 'last_week':
          sampleReportEndDate = moment(sampleReportStartDate, 'YYYY-MM-DD').weekday(0)
          sampleReportStartDate = moment(sampleReportStartDate, 'YYYY-MM-DD').weekday(-7)
          break
        case 'current_month':
          sampleReportStartDate = moment(sampleReportStartDate, 'YYYY-MM-01')
          sampleReportEndDate = moment(sampleReportStartDate, 'YYYY-MM-DD').add(1, 'month')
          break
        case 'last_month':
          sampleReportStartDate = moment(sampleReportStartDate, 'YYYY-MM-01').subtract(1, 'month')
          sampleReportEndDate = moment(sampleReportStartDate, 'YYYY-MM-DD').add(1, 'month')
          break
        case 'current_quarter':
          sampleReportStartDate = moment(sampleReportStartDate, 'YYYY-MM-DD').startOf('quarter')
          sampleReportEndDate = moment(sampleReportStartDate, 'YYYY-MM-DD').endOf('quarter')
          break
        case 'last_quarter':
          sampleReportStartDate = moment(sampleReportStartDate, 'YYYY-MM-DD').startOf('quarter').subtract(3, 'month')
          sampleReportEndDate = moment(sampleReportStartDate, 'YYYY-MM-DD').endOf('quarter')
          break
        case 'year_to_date':
          sampleReportEndDate = sampleReportStartDate
          sampleReportStartDate = moment(sampleReportStartDate, 'YYYY-MM-DD').startOf('year')
          break
        case 'all':
          sampleReportEndDate = sampleReportStartDate
          sampleReportStartDate = '2017-01-01'
          break
        default:
          // default just run the sample for today
          sampleReportEndDate = moment(sampleReportStartDate, 'YYYY-MM-DD').add(1, 'day')
      }

      for (const dateRange of dateRangeOptions) {
        if (dateRange.key === rangeTitle) {
          rangeTitle = dateRange.title
        }
      }
      return {
        rangeTitle,
        sampleReportStartDate,
        sampleReportEndDate,
      }
    }
    return null
  }

  render() {
    const {
      mainNav, reportMenu, selectReport, filterMenu, wMatrix, selectedReport, handleGenerateData,
      generatedData, devices, landmarks, landmarksLoading, drivers, groups, labels,
      returnDevicesWithGroup, returnDevicesWithLabel, reportLoading, events,
      speedToString, refetchMetaAndSchedule, serviceTypes, serviceTicketStatus,
      scheduledReports, freqOptions, dateRangeOptions,
      reportDownloadMeta, setReportDownloadMeta,
      toggleReportDownloadModal, ifShowReportDownloadModal, createReportDownload,
      showDownloadButton, eventToString, minutesToString, ifReportTooLarge,
    } = this.props

    let breadcrumbText = selectedReport ? selectedReport.title : ' '
    if (selectedReport && (selectedReport.category === 'standard' || selectedReport.category === 'professional')) {
      breadcrumbText = wMatrix(selectedReport.type)
    }

    return (
      <div style={styles.contentWrapper}>
        <div style={styles.menu}>
          <ReportMenu
            searchPlaceholder={wMatrix('reportSearch')}
            menuItems={reportMenu}
            selectItem={selectReport}
            onInputChange={filterMenu}
          />
        </div>
        <div style={styles.rightWrapper}>
          <div>
            <Breadcrumb>
              <Breadcrumb.Item>{wMatrix('reports')}</Breadcrumb.Item>
              <Breadcrumb.Item>{breadcrumbText}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div style={{
            flexGrow: '1', alignSelf: 'stretch', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',
          }}
          >
            {!selectedReport
              ? (
                <span style={{ alignSelf: 'center', marginTop: 100, color: '#797979' }}>
                  {wMatrix('selectReport')}
                </span>
              )
              : null }
            {!selectedReport ? null : (
              <div style={{ marginTop: 20, flexGrow: 1 }}>
                <ReportFilterContainer
                  mainNav={mainNav}
                  title={selectedReport.title}
                  devices={devices}
                  drivers={drivers}
                  events={events}
                  labels={labels}
                  groups={groups}
                  serviceTypes={serviceTypes}
                  serviceTicketStatus={serviceTicketStatus}
                  landmarks={landmarks}
                  landmarksLoading={landmarksLoading}
                  returnDevicesWithGroup={returnDevicesWithGroup}
                  returnDevicesWithLabel={returnDevicesWithLabel}
                  refetchMetaAndSchedule={refetchMetaAndSchedule}
                  handleGenerateData={handleGenerateData}
                  reportType={selectedReport.type}
                  reportId={selectedReport.id}
                  reportInputs={selectedReport.inputBlob}
                  reportCategory={selectedReport.category}
                  scheduledReports={scheduledReports}
                  freqOptions={freqOptions}
                  dateRangeOptions={dateRangeOptions}
                  scheduleDateRange={this.findScheduleDateRange()}
                  toggleReportDownloadModal={toggleReportDownloadModal}
                  setReportDownloadMeta={setReportDownloadMeta}
                  showDownloadButton={showDownloadButton}
                  ifReportTooLarge={ifReportTooLarge}
                />
                <ReportTable
                  wMatrix={wMatrix}
                  speedToString={speedToString}
                  eventToString={eventToString}
                  minutesToString={minutesToString}
                  selectedReport={selectedReport}
                  generatedData={generatedData}
                  reportLoading={reportLoading}
                />
                <ReportDownload
                  toggleReportDownloadModal={toggleReportDownloadModal}
                  ifShowReportDownloadModal={ifShowReportDownloadModal}
                  reportDownloadMeta={reportDownloadMeta}
                  selectedReport={selectedReport}
                  createReportDownload={createReportDownload}
                  ifReportTooLarge={ifReportTooLarge}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default ReportsHOC()(Reports)
