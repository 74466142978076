import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MapControl from '@mol/mapControl'
import MapStyle from '@atom/map.style'


/**
 * All maps need our custom map control and common functionality
 *
 * * This renders the map control with the provided custom map.
 * * Control when the map should change its settings.
 * * Smooth zoom functionality
 */
export default class Map extends Component {
  static propTypes = {
    settings: PropTypes.object.isRequired,
    updateSettings: PropTypes.func.isRequired,
    renderMap: PropTypes.func.isRequired,
    selectModal: PropTypes.func.isRequired,
    mapIconSizeMode: PropTypes.string,
    deviceListMode: PropTypes.string,
    mapIconMode: PropTypes.string,
    containerStyle: PropTypes.object,
    landmarkToggle: PropTypes.bool, // when true, show in map control
    animationToggle: PropTypes.bool,
    clusteringToggle: PropTypes.bool,
    hidePlaceSearch: PropTypes.bool,
  }

  static defaultProps = {
    containerStyle: null,
    deviceListMode: 'comfortable',
    mapIconMode: 'comfortable',
    mapIconSizeMode: 'large',
    landmarkToggle: false,
    animationToggle: false,
    clusteringToggle: false,
    hidePlaceSearch: false,
  }

  state = {
    map: null,
    maps: null,
    trafficLayer: null,
    searchMarker: null,
  }

  /**
   * @private
   *
   * Gets the zoom level of a bounds
   *
   * this function is sick, get the zoom level from bounds without
   * Google maps, got it from stackoverflow
   * https://stackoverflow.com/questions/6048975/google-maps-v3-how-to-calculate-the-zoom-level-for-a-given-bounds
  */
  getBoundsZoomLevel = (bounds) => {
    const { map } = this.state
    const WORLD_DIM = { height: 256, width: 256 }
    const ZOOM_MAX = 21

    function latRad(lat) {
      const sin = Math.sin(lat * Math.PI / 180)
      const radX2 = Math.log((1 + sin) / (1 - sin)) / 2
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
    }

    function zoom(mapPx, worldPx, fraction) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
    }

    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()

    const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI

    const lngDiff = ne.lng() - sw.lng()
    const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360

    const latZoom = zoom(map.getDiv().offsetHeight, WORLD_DIM.height, latFraction)
    const lngZoom = zoom(map.getDiv().offsetWidth, WORLD_DIM.width, lngFraction)

    return Math.min(latZoom, lngZoom, ZOOM_MAX)
  }

  /**
   * @private
   *
   * Gets the center of a bounds
   */
  getBoundsCenter = (bounds) => {
    const ne = {
      lat: bounds.getNorthEast().lat(),
      lng: bounds.getNorthEast().lng(),
    }
    const sw = {
      lat: bounds.getSouthWest().lat(),
      lng: bounds.getSouthWest().lng(),
    }

    if ((sw.lng - ne.lng > 180) || (ne.lng - sw.lng > 180)) {
      sw.lng += 360
      sw.lng %= 360
      ne.lng += 360
      ne.lng %= 360
    }

    return {
      lat: (sw.lat + ne.lat) / 2,
      lng: (sw.lng + ne.lng) / 2,
    }
  }

  /**
   * @private
   *
   * Timestep for a smooth zoom transition
   *
   * @param {number} current zoom level
   * @param {object} end zoom level
   * @param {number} type zoom 'in' or 'out'
   */
  smoothZoom = (current, end, type) => {
    const { map, maps } = this.state

    if (type === 'in' ? current <= end : current >= end) {
      const listener = maps.event.addListenerOnce(map, 'zoom_changed', () => {
        maps.event.removeListener(listener)
        this.smoothZoom(type === 'in' ? current + 1 : current - 1, end, type)
      })
      setTimeout(() => {
        map.setZoom(current)
      }, 80)
    }
  }


  /**
   * @public
   *
   * Given to the custom maps so that they can zoom and fit to the given bounds
   *
   * @param {object} bounds of the area we want to zoom to. Google Maps Bounds
   * @param {number} maxZoomIn optional. if we want stop the going 'in' zoom
   *                           level at a specific point
   */
  fitBounds = (bounds, maxZoomIn) => {
    const { settings } = this.props
    const { map } = this.state
    const boundsZoom = this.getBoundsZoomLevel(bounds)
    const current = map.getZoom()
    let end = boundsZoom

    if (boundsZoom > current) {
      if (maxZoomIn) {
        if (maxZoomIn < boundsZoom) {
          end = maxZoomIn
        }
      }
    }

    if (settings.animation) {
      this.smoothZoom(current, end, boundsZoom < current ? 'out' : 'in')
    } else {
      map.setZoom(end)
    }

    map.panTo(this.getBoundsCenter(bounds))
  }

  /**
   * Places a google marker at the specified coordinates
   * @param {Object} coordinates Coordinate object of form {lat, lng}
   */
  placeSearchMarker = (coordinates) => {
    const { map, maps, searchMarker } = this.state
    let newSearchMarker = null
    // remove previous search if applicable
    if (map && maps) {
      if (searchMarker) searchMarker.setMap(null)
      newSearchMarker = new maps.Marker({
        position: coordinates,
        map,
      })
    }
    this.setState({ searchMarker: newSearchMarker })
  }

  /**
   * Removes search marker from map and deletes/overwrites to null
   */
  removeSearchMarker = () => {
    const { searchMarker } = this.state
    if (searchMarker) searchMarker.setMap(null)
    this.setState({ searchMarker: null })
  }

  /**
   * @public
   *
   * Given to the custom maps so that they can run their custom init function
   * Saves map and other property references
   *
   * @param {object} map refrence
   * @param {object} maps reference. Google Maps Maps
   * @param {function} callback custom init function
   */
  initMap = (map, maps, callback) => {
    this.setState({
      map,
      maps,
      trafficLayer: new maps.TrafficLayer(),
    })
    callback(map, maps)
  }

  /**
   * @private
   *
   * Applies the map settings to the map provided
   */
  applyMapSettings = () => {
    const { settings } = this.props
    const { map, trafficLayer } = this.state

    if (map) {
      // Satellite
      if (settings.satellite) {
        map.setMapTypeId('hybrid')
      } else {
        map.setMapTypeId('roadmap')
      }

      // Traffic
      if (settings.traffic) {
        trafficLayer.setMap(map)
      } else {
        trafficLayer.setMap(null)
      }

      // Map style / night mode
      let styles = MapStyle.default
      if (settings.nightMode) {
        styles = MapStyle.nightMode
      }
      map.set('styles', styles)
    }
  }

  render() {
    const {
      settings, renderMap, updateSettings, deviceListMode, mapIconMode, mapIconSizeMode,
      containerStyle, landmarkToggle, selectModal, animationToggle, clusteringToggle,
      hidePlaceSearch,
    } = this.props
    const { map, maps } = this.state

    this.applyMapSettings()

    return (
      <div style={containerStyle || { flex: 1 }}>
        <div style={{ position: 'relative' }}>
          <MapControl
            map={map}
            maps={maps}
            settings={settings}
            updateSettings={updateSettings}
            deviceListMode={deviceListMode}
            mapIconMode={mapIconMode}
            mapIconSizeMode={mapIconSizeMode}
            landmarkToggle={landmarkToggle}
            animationToggle={animationToggle}
            clusteringToggle={clusteringToggle}
            selectModal={selectModal}
            fitBounds={this.fitBounds}
            placeSearchMarker={this.placeSearchMarker}
            removeSearchMarker={this.removeSearchMarker}
            hidePlaceSearch={hidePlaceSearch}
          />
        </div>
        {renderMap(this.initMap, this.fitBounds)}
      </div>
    )
  }
}
