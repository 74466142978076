import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  AutoSizer, CellMeasurer, CellMeasurerCache, List,
} from 'react-virtualized'
import DeviceListItem from '@mol/deviceListItem'

const cache = new CellMeasurerCache({
  defaultHeight: 90,
  fixedWidth: true,
})

// cubic ease in out function
// https://easings.net/
function easeInOutCubic(x) {
  return x < 0.5 ? 4 * x * x * x : 1 - ((-2 * x + 2) ** 3) / 2
}

export default class DeviceVirtualList extends Component {
  // eslint-disable-next-line new-cap
  listRef = new React.createRef()

  /**
   * variables used for scroll animation (noted here for clarity)
   */
  animationStartTime = undefined

  scrollTopInitial = undefined

  scrollTopFinal = undefined

  static propTypes = {
    mainNav: PropTypes.string,
    deviceList: PropTypes.array,
    selectedDevice: PropTypes.object,
    selectDevice: PropTypes.func,
    listMode: PropTypes.string,
    formatVehicleStatus: PropTypes.func,
    ifShowDistance: PropTypes.bool,
    hoverDevice: PropTypes.func,
    loading: PropTypes.bool,
    animationDuration: PropTypes.number,
    setOriginFlag: PropTypes.bool,
  }

  static defaultProps = {
    mainNav: 'map',
    deviceList: [],
    selectedDevice: null,
    selectDevice: () => {},
    listMode: 'comfortable',
    formatVehicleStatus: () => {},
    ifShowDistance: false,
    hoverDevice: null,
    loading: false,
    animationDuration: 500,
    setOriginFlag: null,
  }

  state = {
    scrollTop: null,
  }

  componentDidUpdate = (prevProps) => {
    const { selectedDevice, listMode } = this.props
    // on change of selected device, trigger the scrollTo
    if (selectedDevice && prevProps.selectedDevice?.id !== selectedDevice.id) {
      const index = this.getIndexFromDeviceId(selectedDevice.id)
      // this.setScrollIndex(index)
      this.initAnimation(index)
    }
    // clear cellMeasurer cache to remeasure list item height
    if (prevProps.listMode !== listMode) {
      /**
       * clearing the cellMeasurer cache procs the remeasure
       * Note that the listElements should also call Measure when this occurs. I.E. deviceListItem
       * should call "measure" function when listMode changes
       */
      cache.clearAll()
    }
  }

  /**
   * Scrolls to top of list by setting scrollTop value, then, to allow
   * the user to scroll freely again, we set the scrollTop value back to undefined
   */
  scrollToTop = () => {
    this.setState({ scrollTop: 0 })
    setTimeout(() => {
      this.setState({ scrollTop: undefined })
    }, 500)
  }

  /**
   * On scroll will clear scrollIndex as well as update the current scrollTop value
   * @param {Number} param0 Current scroll top returned by List component
   */
  onScroll = ({ scrollTop }) => {
    // If not part of scroll animation
    if (!this.animationStartTime) {
      this.scrollTopInitial = scrollTop
      this.setState({ scrollTop: undefined })
    }
  }

  /**
   * Returns devices index in deviceList array given the device id
   * @param {Number} deviceId device's id
   * @returns {Number} index in deviceList array
   */
  getIndexFromDeviceId = (deviceId) => {
    const { deviceList } = this.props
    const deviceListClone = JSON.parse(JSON.stringify(deviceList))
    const selectedIndex = deviceListClone.map(d => d.id).indexOf(deviceId)
    return selectedIndex
  }

  /**
   * Callback function to be used with list items to force rerender the list if contents
   * has changed
   */
  forceUpdateList = () => {
    if (this.listRef && this.listRef.current.forceUpdateGrid) {
      this.listRef.current.forceUpdateGrid()
    }
  }

  /**
   * Function used to render each row. Based on react-virtualized List
   */
  rowRenderer = ({
    index, key, parent, style,
  }) => {
    const {
      mainNav, deviceList, selectedDevice, selectDevice, listMode,
      formatVehicleStatus, ifShowDistance, hoverDevice, loading,
      setOriginFlag,
    } = this.props

    const d = deviceList[index]
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ measure, registerChild }) => (
          <div ref={registerChild} style={style}>
            <DeviceListItem
              measure={measure}
              key={d.id}
              device={d}
              index={index}
              selectedDevice={selectedDevice}
              selectDevice={selectDevice}
              hoverDevice={hoverDevice}
              deviceListMode={listMode}
              formatVehicleStatus={formatVehicleStatus}
              ifShowDistance={ifShowDistance}
              mainNav={mainNav}
              loading={loading}
              setOriginFlag={setOriginFlag}
              forceUpdate={this.forceUpdateList}
            />
          </div>
        )}
      </CellMeasurer>
    )
  }

  /**
   * Facilitates the animation scrolling.
   *
   * Note: This method is modified from Brian Vaughn's (bvaughn)
   * react-virtualized-animated-scroll-to example
   * @see https://codesandbox.io/s/v1vkm8mol0
   */
  animate() {
    requestAnimationFrame(() => {
      const { animationDuration } = this.props
      const now = performance.now()
      const ellapsed = now - this.animationStartTime
      const scrollDelta = this.scrollTopFinal - this.scrollTopInitial
      const easedTime = easeInOutCubic(Math.min(1, ellapsed / animationDuration))
      const scrollTop = this.scrollTopInitial + scrollDelta * easedTime

      this.setState({ scrollTop })

      if (ellapsed < animationDuration) {
        this.animate()
      } else {
        // end animation
        this.animationStartTime = undefined
        this.scrollTopInitial = this.scrollTopFinal
        // onAnimationComplete()
      }
    })
  }

  initAnimation(index) {
    this.animationStartTime = performance.now()

    // Ask list for offset in case since it is complex (using cellMeasurer)
    this.scrollTopFinal = this.listRef.current.getOffsetForRow({
      index,
    })

    this.animate()
  }

  render() {
    const {
      deviceList,
      selectedDevice,
      listMode,
      mainNav,
      setOriginFlag,
    } = this.props
    const { scrollTop } = this.state
    return (
      <AutoSizer>
        {({ height, width }) => (
          <List
            ref={this.listRef}
            height={height}
            width={width}
            rowCount={deviceList.length}
            deferredMeasurementCache={cache}
            rowHeight={cache.rowHeight}
            rowRenderer={this.rowRenderer}
            scrollToAlignment="start"
            onScroll={this.onScroll}
            scrollTop={scrollTop}
            selectedDevice={selectedDevice}
            listMode={listMode}
            mainNav={mainNav}
            setOriginFlag={setOriginFlag}
          />
        )}
      </AutoSizer>
    )
  }
}
