// import { Checkbox } from 'antd';

// const CheckboxGroup = Checkbox.Group;

// export default Checkbox;
// export { CheckboxGroup };
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'
import ButtonWrapper from '@atom/textButton'

export default class CheckBox extends Component {
  static propTypes = {
    type: PropTypes.string,
    checked: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    type: 'star',
    defaultChecked: false,
    onChange: null,
    checked: undefined,
    disabled: false,
  }

  constructor(props) {
    const { defaultChecked } = props
    super(props)
    this.state = {
      stateChecked: defaultChecked,
    }
  }

  handleToggle = (state) => {
    const { onChange } = this.props
    this.setState({ stateChecked: state })
    if (onChange) {
      onChange(state)
    }
  }

  returnCheckBoxIcon = (checked) => {
    const { type } = this.props
    if (type === 'star') {
      return (
        <Icon
          type="star"
          theme={checked ? 'filled' : 'outlined'}
          style={{ color: checked ? '#FFD700' : undefined }}
        />
      )
    }
    return null
  }

  render() {
    const { disabled, checked } = this.props
    const { stateChecked } = this.state
    return (
      <ButtonWrapper
        onClick={() => this.handleToggle(!stateChecked)}
        useAsWrapper
        disabled={disabled}
      >
        {this.returnCheckBoxIcon(checked !== undefined ? checked : stateChecked)}
      </ButtonWrapper>
    )
  }
}
