import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, Select, Input, Form, Tooltip, Alert, Spin, InputNumber,
} from 'antd'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import GoogleMapReact from 'google-map-react'
import Marker from '@atom/marker'
import ModalHeader from '@mol/modalHeader'
import DestinationRow from '@atom/logistics/destinationRow'
import AddressAutoComplete from '@atom/addressAutoComplete'
import ButtonWrapper from '@atom/textButton'

const styles = {
  mainWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  wrapper: {
    flexGrow: 1,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    overflow: 'scroll',
  },
  ul: {
    width: '100%',
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  cell: {
    display: 'flex',
    flexGrow: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    alignItems: 'center',
  },
  line: {
    width: '17px',
    height: '4px',
    borderRadius: '1px',
    background: '#d8d8d8',
    margin: '4px',
  },
  headerRow: {
    height: 46,
    display: 'flex',
    alignItems: 'center',
    background: '#fafafa',
    border: '1px solid #e8e8e8',
    borderBottom: 0,
    width: '100%',
    padding: '13px 10px',
    fontSize: '14px',
    fontWeight: 'bold',
    borderRadius: '4px 4px 0px 0px',
  },
  header: {
    display: 'flex',
    // marginLeft: 16,
    flexShrink: 0,
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
  },
  addButtons: {
    display: 'flex',
    // width: '80%',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  footerButtonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    margin: '10px 10px 0 0',
    flexShrink: 0,
  },
  destinationsTable: {
    wrapper: {
      minWidth: 1150,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'hidden',
      overflow: 'auto',
      position: 'relative',
    },
    contentWrapper: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      flexGrow: 1,
      marginTop: -1,
      padding: 10,
      overflowY: 'auto',
      overflowX: 'hidden',
      border: '1px solid #e4e4e4',
      position: 'relative',
      justifyContent: 'center', // used for spin loading
    },
    loadingOverlay: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(255,255,255,0.5)',
    },
  },
  addDestForm: {
    marginTop: '10px',
    padding: '24px',
    backgroundColor: '#f9f9f9',
    border: '1px solid #e8e8e8',
    borderRadius: '6px',
    margin: '0 0 10px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  mapContainer: {
    height: '500px',
    marginBottom: '24px',
  },
  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  },
  tailFormItemLayout: {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 8,
        offset: 18,
      },
    },
  },
}

const mapDefaults = {
  center: {
    lat: 30.116936,
    lng: -97.129397,
  },
  zoom: 1,
}

class DestinationsInfo extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    job: PropTypes.object,
    form: PropTypes.object.isRequired,
    navDashboard: PropTypes.func.isRequired,
    createDestination: PropTypes.func.isRequired,
    updateDestination: PropTypes.func.isRequired,
    deleteDestination: PropTypes.func.isRequired,
    createTask: PropTypes.func.isRequired,
    updateTask: PropTypes.func.isRequired,
    deleteTask: PropTypes.func.isRequired,
    reorderDestinations: PropTypes.func.isRequired,
    landmarks: PropTypes.array,
    newDestinationLoading: PropTypes.bool,
    destinationsTableLoading: PropTypes.bool,
  }

  static defaultProps = {
    job: null,
    landmarks: [],
    newDestinationLoading: false,
    destinationsTableLoading: false,
  }

  state = {
    destinations: [],
    expandedDestinations: [],
    collapseAllTasks: false,
    displayAdd: false,
    buttonAllExpanded: false,
    addDestinationAlert: {
      show: false,
      type: 'error',
      message: null,
    },
  }

  componentDidMount() {
    const { job } = this.props
    if (job && job.destinations) this.setDestinations(job.destinations)
  }

  componentDidUpdate(prevProps) {
    const { job } = this.props
    const prevJob = JSON.stringify(prevProps.job)
    const currJob = JSON.stringify(job)
    // reset expanded ids if new job
    if (job && prevProps.job && prevProps.job.id !== job.id) {
      this.resetExpandedIds()
    }
    if (prevJob !== currJob) {
      if (job && job.destinations) {
        this.setDestinations(job.destinations)
      } else this.setDestinations([])
    }
  }

  // simply resets expanded ids to all collapsed
  resetExpandedIds = () => {
    this.setDestinationExpandedState('collapse')
  }

  setDestinations = (destinations) => {
    const destinationsClone = JSON.parse(JSON.stringify(destinations))
    this.setState({ destinations: destinationsClone })
  }

  /**
   * This function handles any changes in the destination expansion states. This is used to
   * toggle a single destinations expansion or set all expanded or collapsed
   * @param {String} type String describing the desired functionality. Must be one of:
   * - 'expand'
   * - 'collapse'
   * @param {Number} id Destination identifier. If no identifier is passed, the action is applied
   * to all destinations
   */
  setDestinationExpandedState = (type, id) => {
    const { destinations, expandedDestinations } = this.state
    let updatedDestinations = []

    if (!id) {
      if (type === 'expand') {
        // if expand all, then push all dest ids to the array and update
        for (let i = 0; i < destinations.length; i += 1) {
          updatedDestinations.push(destinations[i].id)
        }
      }
    } else if (type === 'expand' && !expandedDestinations.includes(id)) {
      // if expand and the id is not yet expanded, add to array
      updatedDestinations = [...expandedDestinations, id]
    } else if (type === 'collapse') {
      // if collapse and id is in array, filter out and update
      const deleteIndex = expandedDestinations.indexOf(id)
      updatedDestinations = JSON.parse(JSON.stringify(expandedDestinations))
      updatedDestinations.splice(deleteIndex, 1)
    }

    this.setState({
      expandedDestinations: updatedDestinations,
      buttonAllExpanded: destinations.length === updatedDestinations.length
        && destinations.length > 0,
    })
  }

  addDestination = () => {
    this.setState({ displayAdd: true })
  }

  handleCancelAdd = () => {
    const { form } = this.props
    form.resetFields()
    this.setState({ displayAdd: false })
  }

  handleCancel = () => {
    const { form, navDashboard } = this.props
    form.resetFields()
    this.setState({ displayAdd: false })
    navDashboard()
  }

  /**
   * Handle alert close
   */
    alertOnClose = () => {
      // also close display add form if alert is closed manually
      this.setState({
        addDestinationAlert: {
          show: false,
          type: 'error',
          message: null,
        },
        displayAdd: false,
      })
    }

  /**
   * Sets alert state and sets timeout for closing
   * @param {String} type type of alert message ('success','error')
   * @param {String} message Optional alert message
   */
  setAddDestinationAlert = (type, message) => {
    const { form } = this.props
    this.setState({
      addDestinationAlert: { show: true, type, message: message || null },
    })
    this.alertTimeout = setTimeout(() => {
      this.alertOnClose()
      this.setState({ displayAdd: false })
      form.resetFields()
    }, 2500)
  }

  handleDisable = () => {
    const { form, newDestinationLoading } = this.props
    const fv = form.getFieldsValue()
    if (!fv.label) {
      return true
    }

    if (fv.locationType !== 'coordinates' && (!fv.address || newDestinationLoading)) {
      return true
    }

    if (fv.locationType === 'coordinates' && (!fv.latitude || !fv.longitude)) {
      return true
    }

    return false
  }

  handleSave = async () => {
    const { form, createDestination, job } = this.props
    const fv = form.getFieldsValue()
    const sequence = job.destinations.length + 1
    const resStatus = await createDestination({
      jobId: job.id,
      sequence,
      ...fv,
    })
    this.setAddDestinationAlert(resStatus.type, resStatus.message)
  }

  handleDragStart = () => {
    this.setDestinationExpandedState('collapse')
  }

  /**
   * Apply the reordering to the destinations array state as well as call the
   * reorder/refetch gql calls
   * @param {Object} result Draggable Context Object that contains the items
   * source index as well as its 'dragged-to' index
   */
  handleDragEnd = async (result) => {
    if (result && result.source && result.source.index !== null
      && result.destination && result.destination.index !== null
    ) {
      const { destinations } = this.state
      // clone destinations
      const reorderedDestinations = Array.from(destinations)
      // apply reorder to array
      const [reorderedItem] = reorderedDestinations.splice(result.source.index, 1)
      reorderedDestinations.splice(result.destination.index, 0, reorderedItem)
      // set state for dragable content to hold correct position until refetch
      this.setState({ destinations: reorderedDestinations })
      // mutate order changes and refetch destinations/destinations order
      await this.handleReorderSave(reorderedDestinations)
    }
  }

  /**
   * Tracks the order changes and calls the gql reorder mutation
   * @param {[Objects]} reorderedDestinations Array of destinations with different order than
   * that of the original array
   */
  handleReorderSave = async (reorderedDestinations) => {
    const { job, reorderDestinations } = this.props
    const changes = []
    for (let i = 0; i < reorderedDestinations.length; i += 1) {
      if (job.destinations[i].id !== reorderedDestinations[i].id) {
        changes.push({ id: reorderedDestinations[i].id, sequence: i + 1 })
      }
    }
    await reorderDestinations(changes)
  }

  handleReorderDisable = () => {
    const { job } = this.props
    const { destinations } = this.state
    if (!job || !destinations) return true
    const changes = []
    for (let i = 0; i < destinations.length; i += 1) {
      if (job.destinations[i] && destinations[i]) {
        if (job.destinations[i].id !== destinations[i].id) {
          changes.push({ id: destinations[i].id, sequence: i + 1 })
        }
      }
    }
    return changes.length === 0
  }

  /**
   * Handles onSelect of AddressAutoComplete as well as autofilling the label with either
   * an address or landmark name.
   * @param {Object} coordinates Callback for address coordinates (not used in this function)
   * @param {String} address Google verified address
   */
  onAddressSelect = (coordinates, address) => {
    const { form, landmarks } = this.props
    const locationType = form.getFieldValue('locationType')
    if (!address || address.length === 0) {
      form.setFieldsValue({ address: '' })
    } else {
      // set address value
      form.setFieldsValue({ address })
      // depending on locationType, autofill the label input
      if (locationType !== 'landmark') {
        form.setFieldsValue({ label: address })
      } else {
        /**
         * if locationType is landmark, make sure the addresses match
         * IE: If a user first selects a landmark, but then alters the address
         * value, we should not autofill the label
         */
        const landmarkValue = form.getFieldValue('landmark')
        const landmarksClone = JSON.parse(JSON.stringify(landmarks))
        const filteredLandmarks = landmarksClone.filter(landmark => landmark.id === landmarkValue
          && landmark.destinationAddress === address)
        if (filteredLandmarks.length > 0) {
          const landmarkName = filteredLandmarks[0].name
          form.setFieldsValue({ label: landmarkName })
        }
      }
    }
  }

  createMapOptions = maps => ({
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: maps.ControlPosition.LEFT_BOTTOM,
    },
    streetViewControl: true,
    streetViewControlOptions: {
      position: maps.ControlPosition.LEFT_BOTTOM,
    },
    zoomControlOptions: {
      position: maps.ControlPosition.LEFT_BOTTOM,
    },
    mapTypeControl: true,
  })

  updateMapPin = (params) => {
    if (this.droppedPin) {
      this.droppedPin.setMap(null)
    }

    this.droppedPin = new window.google.maps.Marker({
      position: {
        lat: params.lat,
        lng: params.lng,
      },
      map: this.mapInstance,
    })

    const { form } = this.props

    form.setFieldsValue({
      latitude: params.lat,
      longitude: params.lng,
    })

    this.mapInstance.setCenter(params)
    this.mapInstance.setZoom(17)
  }

  /**
   * Renders either Address Input or Landmarks Select box and Address input depending on which
   * locationType is selected.
   * @returns Element
   */
  renderLocationFormItem = () => {
    const {
      form, wMatrix, landmarks,
    } = this.props

    const locationType = form.getFieldValue('locationType')

    if (locationType === 'coordinates') {
      return (
        <>
          <AddressAutoComplete
            onSelect={this.updateMapPin}
            style={{ width: '100%' }}
            wMatrix={wMatrix}
            showPoweredByGoogle
          />
          <div style={styles.mapContainer}>
            <GoogleMapReact
              bootstrapURLKeys={{ client: 'gme-twmatters' }}
              onGoogleApiLoaded={({ map, maps }) => {
                this.mapInstance = map
              }}
              defaultCenter={mapDefaults.center}
              defaultZoom={mapDefaults.zoom}
              yesIWantToUseGoogleMapApiInternals
              options={this.createMapOptions}
              onClick={this.updateMapPin}
            />
          </div>
          <Form.Item
            label={wMatrix('Latitude')}
            {...styles.formItemLayout}
          >
            {form.getFieldDecorator('latitude', {
              initialValue: '',
              rules: [
                {
                  required: true,
                },
              ],
            })(
              <InputNumber
                style={{ minWidth: '100%' }}
                min={-90}
                max={90}
                allowClear
                onChange={(latStr) => {
                  const lat = parseFloat(latStr)
                  const lng = form.getFieldValue('longitude')
                  if (lat && lng) {
                    this.updateMapPin({
                      lat,
                      lng,
                    })
                  }
                }}
              />,
            )}
          </Form.Item>
          <Form.Item
            label={wMatrix('Longitude')}
            {...styles.formItemLayout}
          >
            {form.getFieldDecorator('longitude', {
              initialValue: '',
              rules: [
                {
                  required: true,
                },
              ],
            })(
              <InputNumber
                style={{ minWidth: '100%' }}
                min={-180}
                max={180}
                allowClear
                onChange={(lngStr) => {
                  const lat = form.getFieldValue('latitude')
                  const lng = parseFloat(lngStr)
                  if (lat && lng) {
                    this.updateMapPin({
                      lat,
                      lng,
                    })
                  }
                }}
              />,
            )}
          </Form.Item>
        </>
      )
    }

    let landmarksRow = null
    let landmarkAddress = null
    // if location type is landmark, create the necessary elements
    // this includes the select options, as well as the landmarks form row
    if (locationType === 'landmark') {
      const landmarksClone = JSON.parse(JSON.stringify(landmarks))
      const landmarksOptions = landmarksClone.map((landmark) => {
        const disabled = !landmark.destinationAddress || landmark.destinationAddress.length === 0
        return (
          <Select.Option
            key={`${landmark.id}`}
            value={landmark.id}
            disabled={disabled}
          >
            <Tooltip
              title={disabled ? wMatrix('noAssociatedAddress') : landmark.destinationAddress}
            >
              {landmark.name}
            </Tooltip>
          </Select.Option>
        )
      })
      const landmarkValue = form.getFieldValue('landmark')

      // get landmark address if landmark is selected
      if (landmarkValue !== undefined && landmarkValue !== null) {
        const landmarkAddressArray = JSON.parse(JSON.stringify(landmarks))
          .filter(landmark => landmark.id === landmarkValue)
        landmarkAddress = landmarkAddressArray[0].destinationAddress
      }
      // create the landmark form row element
      landmarksRow = (
        <Form.Item
          label={wMatrix('landmark')}
          {...styles.formItemLayout}
        >
          {form.getFieldDecorator('landmark')(
            <Select style={{ width: '100%' }}>
              {landmarksOptions}
            </Select>,
          )}
        </Form.Item>
      )
    }
    // returns the landmark row if necessary as well as the address row
    return (
      <>
        {landmarksRow}
        <Form.Item
          label={wMatrix('Address')}
          {...styles.formItemLayout}
        >
          {form.getFieldDecorator('address', {
            initialValue: landmarkAddress,
            rules: [
              {
                required: true,
              },
            ],
          })(
            <AddressAutoComplete
              onSelect={this.onAddressSelect}
              style={{ width: '100%' }}
              wMatrix={wMatrix}
              defaultAddress={landmarkAddress}
              showPoweredByGoogle
            />,
          )}
        </Form.Item>
      </>
    )
  }

  renderAddAlert = () => {
    const { addDestinationAlert } = this.state
    if (!addDestinationAlert.show) {
      return null
    }
    return (
      <Alert
        style={{
          width: '275px',
          margin: '0px 10px',
        }}
        closable
        showIcon
        type={addDestinationAlert.type}
        message={addDestinationAlert.message}
      />
    )
  }

  /**
   * Renders the add destination form.
   * @returns Element
   */
  renderAddForm = () => {
    const {
      form, wMatrix, job, newDestinationLoading,
    } = this.props
    const { displayAdd } = this.state
    if (displayAdd && job && job.id) {
      return (
        <Form
          name="addDestForm"
          style={styles.addDestForm}
        >
          <Form.Item
            label={wMatrix('locationType')}
            {...styles.formItemLayout}
          >
            {form.getFieldDecorator('locationType', {
              initialValue: 'address',
            })(
              <Select style={{ width: '100%' }}>
                <Select.Option value="address">{wMatrix('Address')}</Select.Option>
                <Select.Option value="landmark">{wMatrix('Landmark')}</Select.Option>
                <Select.Option value="coordinates">{wMatrix('Coordinates')}</Select.Option>
              </Select>,
            )}
          </Form.Item>
          {this.renderLocationFormItem()}
          <Form.Item
            label={wMatrix('label')}
            {...styles.formItemLayout}
          >
            {form.getFieldDecorator('label', {
              initialValue: '',
              rules: [
                {
                  required: true,
                },
              ],
            })(
              <Input
                style={{ width: '100%' }}
                allowClear
              />,
            )}
          </Form.Item>
          <div style={styles.addButtons}>
            {this.renderAddAlert()}
            <Button
              onClick={this.handleCancelAdd}
              type="info"
              style={{ margin: '0 10px 0 0' }}
            >
              {wMatrix('Cancel')}
            </Button>
            <Button
              onClick={this.handleSave}
              type="primary"
              disabled={this.handleDisable()}
              loading={newDestinationLoading}
            >
              {wMatrix('Save')}
            </Button>
          </div>
        </Form>
      )
    }
    return null
  }

  /**
   * Returns expand/collapse all button
   * @returns Elements
   */
    renderExpandButton = () => {
      const { buttonAllExpanded } = this.state
      return (
        <ButtonWrapper
          onClick={() => { this.setDestinationExpandedState(buttonAllExpanded ? 'collapse' : 'expand') }}
          useAsWrapper
          style={{ ...styles.cell }}
        >
          <div
            role="button"
            tabIndex="0"
            className={`ant-table-row-expand-icon ${buttonAllExpanded ? 'ant-table-row-expanded' : 'ant-table-row-collapsed'}`}
            aria-label={buttonAllExpanded ? 'Collapse row' : 'Expand row'}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          />
        </ButtonWrapper>
      )
    }

    /**
   * Renders expand all button with items header
   * @returns Element
   */
   renderItemHeader = () => {
     const { wMatrix } = this.props
     return (
       <div style={{ ...styles.header, flex: 1, paddingLeft: 13 }}>
         {this.renderExpandButton()}
         <div style={{ ...styles.header, marginLeft: 10 }}>
           {wMatrix('items')}
         </div>
       </div>
     )
   }

   /**
   * renders the Order Cell for the Destination row
   * @returns Element
   */
   renderOrderCell = (sequence, handleProps) => (
     <div
       style={{
         ...styles.cell,
         flex: 1,
         justifyContent: 'center',
         marginRight: 0,
         paddingRight: 0,
         flexShrink: 0,
         alignSelf: 'flex-start',
         marginTop: 14,
       }}
       {...handleProps}
     >
       <div>
         <div style={styles.line} />
         <div style={styles.line} />
       </div>
       <div style={{ width: '5px' }} />
       {sequence}
     </div>
   )

  renderTableHeader = () => {
    const { wMatrix } = this.props
    return (
      <div style={{ ...styles.headerRow }}>
        <div style={{ ...styles.header, width: 65 }}>
          {wMatrix('Order')}
        </div>
        {this.renderItemHeader()}
        <div style={{ ...styles.header, flex: 2 }}>
          {wMatrix('label')}
        </div>
        <div style={{ ...styles.header, flex: 3 }}>
          {wMatrix('Address')}
        </div>
        <div style={{ ...styles.header, flex: 2 }}>
          {wMatrix('Coordinates')}
        </div>
        <div style={{ ...styles.header, flex: 2 }}>
          {wMatrix('Status')}
        </div>
        <div style={{ ...styles.header, flex: 2 }}>
          {wMatrix('completionDate')}
        </div>
        {/* Blank placeholder for edit column */}
        <div style={{ ...styles.header, width: 100 }}>
          {wMatrix('actions')}
        </div>
      </div>
    )
  }

  /**
   * Returns dragable content of table.
   * @returns {Element} Draggable content wrapped in a relative/absolute container to define
   * height for the draggable context
   */
  renderTableContent = () => {
    const {
      wMatrix,
      createDestination, updateDestination, deleteDestination,
      createTask, updateTask, deleteTask, job,
    } = this.props
    const { destinations, collapseAllTasks, expandedDestinations } = this.state
    return (
      // {/* relative wrapper to account for parent's padding */}
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        {/* absolute wrapper to prevent draggableContext overflow */}
        <div style={{ position: 'absolute', height: '100%', width: '100%' }}>
          <DragDropContext onDragStart={this.handleDragStart} onDragEnd={this.handleDragEnd}>
            <Droppable droppableId="destinations">
              {provided => (
                <ul
                  className="destinations"
                  style={styles.ul}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {destinations.map((dest, index) => (
                    <Draggable
                      style={{ width: '100%' }}
                      key={dest.id.toString()}
                      draggableId={dest.id.toString()}
                      index={index}
                      isDragDisabled={job && job.status === 'completed'}
                    >
                      {dragProvided => (
                        <li
                          style={{
                            width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap',
                          }}
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          // {...dragProvided.dragHandleProps}
                        >
                          <DestinationRow
                            draggableHandleProps={dragProvided.dragHandleProps}
                            destination={dest}
                            wMatrix={wMatrix}
                            collapseAllTasks={collapseAllTasks}
                            setExpandedState={this.setDestinationExpandedState}
                            createDestination={createDestination}
                            updateDestination={updateDestination}
                            deleteDestination={deleteDestination}
                            createTask={createTask}
                            updateTask={updateTask}
                            deleteTask={deleteTask}
                            job={job}
                            expanded={expandedDestinations.includes(dest.id)}
                          />
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </div>

      </div>
    )
  }

  /**
   * Returns Destinations Table Header and content as well as loading overlay.
   * @returns {Element} Destinations table
   */
  renderDestinationsTable = () => {
    const { destinationsTableLoading } = this.props
    return (
      <>
        {/* Destinations Table Wrapper - This is what holds the table's width in px */}
        <div style={styles.destinationsTable.wrapper}>
          {this.renderTableHeader()}
          <div style={styles.destinationsTable.contentWrapper}>
            {this.renderTableContent()}
          </div>
          {/* loading overlay similary to ant design */}
          {destinationsTableLoading
            ? (
              <div style={styles.destinationsTable.loadingOverlay}>
                <Spin />
              </div>
            ) : null}
        </div>
      </>
    )
  }

  /**
   * Renders Header with Title and Add Destination Button
   * @returns Element
   */
  renderHeader = () => {
    const { wMatrix, job, newDestinationLoading } = this.props
    const { displayAdd } = this.state
    return (
      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end',
      }}
      >
        <div style={{ display: 'flex', flexGrow: 1 }}>
          <ModalHeader width="100%" headerName={wMatrix('Destinations')} description="" />
        </div>
        <div style={{
          display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginLeft: 10, marginBottom: 10,
        }}
        >
          <Button
            style={{ marginLeft: 10 }}
            onClick={this.addDestination}
            type="primary"
            disabled={displayAdd || (job && job.status === 'completed') || newDestinationLoading}
          >
            {wMatrix('addDestination')}
          </Button>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div style={styles.mainWrapper}>
        {this.renderHeader()}
        {this.renderAddForm()}
        <div style={styles.wrapper}>
          {this.renderDestinationsTable()}
        </div>
      </div>
    )
  }
}

export default Form.create({ name: 'addDestForm' })(DestinationsInfo)
