import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Form, Input, InputNumber, Select,
} from 'antd'
import { EditableContext } from '@mol/editableDriverTable'

const FormItem = Form.Item

class EditableCell extends Component {
  static propTypes = {
    inputType: PropTypes.string,
    editing: PropTypes.bool,
    dataIndex: PropTypes.string,
    title: PropTypes.string,
    record: PropTypes.object,
  }

  static defaultProps = {
    inputType: 'text',
    editing: false,
    dataIndex: null,
    title: null,
    record: null,
  }

  filterOption = (input, option) => {
    const { label } = option.props
    return label.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
  }

  handleWexChange = (form, e) => {
    const upper = e.target.value.toUpperCase().trim()
    const final = upper.substring(0, 3)
    form.setFieldsValue({ wexCard: final })
  }

  getInput = (recordValue, form, availableDeviceOptions) => {
    const { inputType } = this.props
    // text input
    if (inputType === 'text') {
      return <Input size="small" style={{ width: 'auto' }} />
    }
    // role input
    if (inputType === 'deviceDropdown') {
      return (
      // this needs to pull the actual roles
        <Select
          style={{ width: '100%' }}
          showSearch
          labelFilterProp="label"
          filterOption={(input, option) => this.filterOption(input, option)}
        >
          {availableDeviceOptions(recordValue ? recordValue.id : null)}
        </Select>
      )
    }
    if (inputType === 'wexCard') {
      return (
        <Input onChange={e => this.handleWexChange(form, e)} />
      )
    }
    return <InputNumber />
  }

  getInitialValue = (title, record, dataIndex) => {
    switch (dataIndex) {
      case 'device':
        if (record.device) return record.device.id
        return 0 // unassigned
      default:
        return record[dataIndex]
    }
  }

  render() {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      ...restProps
    } = this.props
    return (
      <EditableContext.Consumer>
        {(valueObj) => {
          const { getFieldDecorator } = valueObj.form
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules: [{
                      required: (!!((dataIndex === 'firstName') || (dataIndex === 'lastName'))),
                      message: `Please Input ${title}`,
                    }],
                    initialValue: this.getInitialValue(title, record, dataIndex),
                  })(this.getInput(record[dataIndex],
                    valueObj.form,
                    valueObj.availableDeviceOptions))}
                </FormItem>
              ) : restProps.children}
            </td>
          )
        }}
      </EditableContext.Consumer>
    )
  }
}

export default EditableCell
