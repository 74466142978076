import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  InputNumber, Alert, Spin,
} from 'antd'
import ModalHeader from '@mol/modalHeader'
import clientPreferencesHOC from '@hoc/clientPreferences.hoc'
import helper from '@helper'

class ClientPreferencesPanel extends Component {
    static propTypes = {
      wMatrix: PropTypes.func.isRequired,
      moslInfo: PropTypes.object.isRequired,
      updateMOSL: PropTypes.func.isRequired,
    }

    renderMOSL = () => {
      const { moslInfo, updateMOSL, wMatrix } = this.props

      if (moslInfo.loading) {
        return (
          <Spin />
        )
      }
      if (!moslInfo.data.mosl) {
        return (
          <Alert message={wMatrix('moslError')} type="error" />
        )
      }

      return (
        <InputNumber type="number" min={5} max={20} defaultValue={moslInfo.data.mosl} onChange={updateMOSL} />
      )
    }

    render() {
      const { wMatrix } = this.props
      return (
        <div>
          <div style={{ display: 'flex', width: '100%' }}>
            <ModalHeader
              headerName={`${wMatrix('mosl')} (MOSL)`}
              description={wMatrix('moslDescription')}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            {this.renderMOSL()}
          </div>
        </div>
      )
    }
}

export default clientPreferencesHOC(helper())(ClientPreferencesPanel)
