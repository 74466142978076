import React, { Component } from 'react'
import {
  Modal, Button, Icon, Upload, List, Popconfirm, Alert,
} from 'antd'
import ModalHeader from '@mol/modalHeader'
import ButtonWrapper from '@atom/textButton'
import CustomIcon from '@atom/icon'
import PropTypes from 'prop-types'
import LabelledProgressBar from '@page/main/molecules/labelledProgressBar'

const { Dragger } = Upload

const fileStateEnum = {
  NOFILE: 'noFile',
  VALIDATING: 'validating',
  VALID: 'valid',
  INVALID: 'invalid',
  LOADING: 'loading', // ?

}

const BLUE = '#5EA7F8'

const styles = {
  downloadIcon: {
    color: BLUE,
    marginLeft: 30,
    fontSize: 18,
    verticalAlign: '-0.25em',
  },
}

export default class LandmarkImportModal extends Component {
  static propTypes = {
    ifShowModal: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    /** @helper */
    wMatrix: PropTypes.func.isRequired,
    /** @landmarksHOC */
    uploadLandmarks: PropTypes.func.isRequired,
    downloadLandmarkTemplate: PropTypes.func.isRequired,
    uploadLandmarksLoading: PropTypes.bool,
    uploadLandmarksAndDeleteLoading: PropTypes.bool,
    landmarkImportAlert: PropTypes.object.isRequired,
    landmarkImportAlertOnClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    ifShowModal: false,
    uploadLandmarksLoading: false,
    uploadLandmarksAndDeleteLoading: false,
  }

  state = {
    file: null,
    validationPercentage: 0,
    fileState: fileStateEnum.NOFILE,
    errorList: [],
    fileLandmarkCount: 0,
  }

  /**
   * Updates progress states based on progress percentage as well as any errors found
   * @param {Number} progress Percentage between from 0 to 100
   * @param {[String]} errors Array of error strings to be displayed
   */
  updateProgress = (progress, errors) => {
    let fileState = fileStateEnum.VALIDATING
    if (errors.length > 0 && progress === 100) {
      fileState = fileStateEnum.INVALID
    } else if (progress === 100) {
      fileState = fileStateEnum.VALID
    }
    this.setState({
      validationPercentage: progress,
      fileState,
      errorList: progress === 100 ? errors : [],
    })
  }

  /**
   * Loops through each line of .csv file to validate each field. This loop is used, rather than
   * a form, to update and show progress of validation to the user.
   * @param {file} file file src to be validated
   */
  validateFile = (file) => {
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = (event) => {
      const rows = event.target.result.split(/\r?\n/).filter(row => row)
      const errors = []

      // set validation state and set landmark count
      this.setState({
        fileState: fileStateEnum.VALIDATING,
        fileLandmarkCount: rows.length - 1,
      })

      // first row should always be the column headers
      // name,lat,lng,radius,billable,notes
      if (rows[0] !== 'name,lat,lng,radiusMeters,billable,notes') {
        errors.push('row 0: Invalid column names or column order')
      }
      // start from first row after header
      for (let i = 1; i < rows.length; i += 1) {
        // row number including header row as 1 for error output
        const readableRowNumber = i + 1
        // check each row and validate
        // match the row with the regex (simple regex specifying 6 values)
        // @TODO update regex to be more precise?
        const values = rows[i].match(/(.*),(-?\d*\.?\d*),(-?\d*\.?\d*),(\d*\.?\d*),([A-Z]*),(.*)/)
        // remove first element (full string match)
        values.shift()
        if (values.length > 6 || values.length < 3) {
          errors.push(`row ${readableRowNumber}: Invalid number of columns. Make sure your .csv is in the correct format.`)
        }
        // name
        if (values[0] && values[0] === '') {
          errors.push(`row ${readableRowNumber}: Name is a required field.`)
        }
        // lat
        if (values[1] && values[1] === '') {
          errors.push(`row ${readableRowNumber}: Lat is a required field.`)
        }
        const lat = parseFloat(values[1])
        if (Number.isNaN(lat) || lat < -90 || lat > 90) {
          errors.push(`row ${readableRowNumber}: Invalid Latitude value. Value must be between -90 and 90.`)
        }
        // lng
        if (values[2] && values[2] === '') {
          errors.push(`row ${readableRowNumber}: Lng is a required field.`)
        }
        const lng = parseFloat(values[2])
        if (Number.isNaN(lng) || lng < -180 || lng > 180) {
          errors.push(`row ${readableRowNumber}: Invalid Longitude value. Value must be between -180 and 180.`)
        }
        // radius
        // if radius === '', then default to 402 m
        if (values[3] && values[3] !== '' && parseFloat(values[3]) < 0) {
          errors.push(`row ${readableRowNumber}: Invalid Radius value. Value must be greater than 0.`)
        }
        // billable
        // can only be true or false or empty
        if (values[4] && values[4] !== '' && !['TRUE', 'FALSE'].includes(values[4])) {
          errors.push(`row ${readableRowNumber}: Invalid Billable value. Value must be 'TRUE', 'FALSE', or have no value.`)
        }
        // notes
        // @TODO possibly add check for special characters?
        // if (values[5] )

        // calculate progress
        const progress = Math.round(((i + 1) / rows.length) * 100)
        /**
         * update progress value using timeout. This is so the renderlifecycle has time to
         * show some of the progress changes
         */
        setTimeout(() => {
          this.updateProgress(progress, errors)
        }, 100)
      }
    }
  }

  /**
   * Handles onChange of dragger component. Used to set file state.
   * @param {Object} info File info object passed by Dragger component
   */
  onDraggerChange = async (info) => {
    const { file } = this.state
    if (file?.uid !== info.file.uid && info.file.name) {
      this.setState({
        file: info.file,
        fileState: fileStateEnum.VALIDATING,
      })
    }
  }

  /**
   * Handles import onClicks and calls uploadLandmarks from landamarks.HOC
   * @param {Bool} deleteExisting Boolean describing whether to delete all existing
   * landmarks or not
   */
  handleUpload = (deleteExisting) => {
    const { uploadLandmarks } = this.props
    const { file } = this.state
    uploadLandmarks(file, !!deleteExisting)
  }

  /**
   * Handles removal of file and updates necessary states.
   */
  removeFile = () => {
    this.setState({
      file: null,
      fileState: fileStateEnum.NOFILE,
      errorList: [],
      fileLandmarkCount: 0,
    })
  }

  /**
   * Returns download button element
   * @returns {Element}
   */
  templateDownloadButton = () => {
    const { downloadLandmarkTemplate } = this.props
    return (
      <Button
        icon="file"
        onClick={downloadLandmarkTemplate}
        style={{ marginBottom: 20 }}
      >
        landmark_upload_template.csv
        <Icon
          type="cloud-download"
          style={styles.downloadIcon}
        />
      </Button>
    )
  }

  /**
   * Returns Dragger area heading text or filename if selected.
   * @returns {Element}
   */
  draggerHeading = () => {
    const { wMatrix } = this.props
    const { file, fileState } = this.state

    if (!file) {
      return (
        <h2 style={{ fontSize: '1.25em' }}>
          {wMatrix('uploadCSV')}
        </h2>
      )
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8.75px' }}>
        <Icon type="paper-clip" style={{ color: '#000000A6', fontSize: '1em' }} />
        <h2 style={{ margin: '0px 10px', fontSize: '1.25em', color: fileState === fileStateEnum.INVALID ? 'red' : '#000000D9' }}>
          {file.name}
        </h2>
        <ButtonWrapper
          useAsWrapper
          onClick={(e) => {
            e.stopPropagation()
            this.removeFile()
          }}
        >
          <Icon type="close-circle" theme="filled" style={{ color: '#000000A6', fontSize: '1em' }} />
        </ButtonWrapper>
      </div>
    )
  }

  /**
   * Returns dragger box details text based on whether a file has been selected or not.
   * @returns {Element}
   */
  draggerDetails = () => {
    const { wMatrix } = this.props
    const { file } = this.state

    if (!file) {
      return (
        <p>{wMatrix('landmarkImportDetails')}</p>
      )
    }
    return (
      <p>{wMatrix('landmarkImportDetailsReplace')}</p>
    )
  }

  /**
   * Returns file icon based on what the current fileState is.
   * @returns {Element}
   */
  draggerIcon = () => {
    const { fileState } = this.state

    let badgeType = null
    if (fileState === fileStateEnum.VALIDATING) {
      badgeType = 'badge-pending'
    }
    if (fileState === fileStateEnum.VALID) {
      badgeType = 'badge-success'
    }
    if (fileState === fileStateEnum.INVALID) {
      badgeType = 'badge-error'
    }

    return (
      <div style={{ position: 'relative' }}>
        {!badgeType ? null
          : <div style={{ position: 'absolute', top: 12, left: 36 }}><CustomIcon type={badgeType} /></div>
        }
        <Icon type="inbox" style={{ color: fileState !== fileStateEnum.NOFILE ? '#000000A6' : BLUE, fontSize: 54, marginBottom: 10 }} />
      </div>
    )
  }

  /**
   * Returns dragger area that allows file uploading as well as displays the file's
   * validation state.
   * @returns {Element}
   */
  draggerArea = () => {
    const { file } = this.state

    const draggerProps = {
      name: 'file',
      beforeUpload: (newFile) => {
        this.setState({
          file: newFile,
          fileState: fileStateEnum.VALIDATING,
        })
        this.validateFile(newFile)
        return false
      },
      customRequest() {
        return null
      },
      fileList: file ? [file] : [],
    }

    return (
      <Dragger
        {...draggerProps}
        // onChange={info => this.onDraggerChange(info)}
        showUploadList={false}
        accept=".csv"
      >
        <div style={{
          paddingLeft: 20, paddingRight: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px 0px',
        }}
        >
          {this.draggerIcon()}
          {this.draggerHeading()}
          {this.draggerDetails()}
        </div>
      </Dragger>
    )
  }

  /**
   * Returns list of validation errors.
   * @returns {Element}
   */
  errorList = () => {
    const { errorList, fileState } = this.state
    if (errorList.length === 0 || fileState === fileStateEnum.NOFILE) {
      return null
    }
    return (
      <div>
        <List
          header={null}
          footer={null}
          bordered
          dataSource={errorList}
          style={{ height: 150, margin: '10px 0px', overflow: 'auto' }}
          renderItem={item => (
            <List.Item style={{ color: 'red' }}>
              {item}
            </List.Item>
          )}
        />
      </div>
    )
  }

  /**
   * Returns p-tag element showing end status of validation.
   * @returns {Element}
   */
  validationText = () => {
    const { wMatrix } = this.props
    const { fileState, fileLandmarkCount } = this.state

    if (fileState === fileStateEnum.INVALID) {
      return (
        <p style={{ color: '#000000D9' }}>
          {wMatrix('fileWasInvalid')}
        </p>
      )
    } if (fileState === fileStateEnum.VALID) {
      // displays x number of valid landmarks text
      return (
        <p style={{ color: '#000000D9' }}>
          {`${fileLandmarkCount} ${wMatrix('xValidLandmarksClickBelow')}`}
        </p>
      )
    }
    return null
  }

  /**
   * Returns div containing the validation progress as well as the
   * validation message that displays after validation is complete.
   * @returns {Element}
   */
  validationSection = () => {
    const { wMatrix } = this.props
    const { fileState, validationPercentage } = this.state

    if (fileState === fileStateEnum.NOFILE) {
      return null
    }
    // file status is noFile or valid
    let progressBarStatus = null
    // invalid
    if (fileState === fileStateEnum.INVALID) {
      progressBarStatus = 'exception'
    // validating
    } if (fileState === fileStateEnum.VALIDATING) {
      progressBarStatus = 'active'
    }

    return (
      <div>
        <LabelledProgressBar
          label={wMatrix('validating')}
          percent={validationPercentage}
          status={progressBarStatus}
          style={{ color: '#000000D9', margin: '10px 0px' }}
        />
        {this.validationText()}
        {this.errorList()}
      </div>
    )
  }

  /**
   * Returns the import buttons used at the foot of the modal
   * @returns {Element} Button group containing import buttons
   */
  footer = () => {
    const {
      wMatrix, uploadLandmarksLoading, uploadLandmarksAndDeleteLoading,
      landmarkImportAlert, landmarkImportAlertOnClose,
    } = this.props

    const { fileState } = this.state

    return (
      <div style={{
        width: '100%', marginTop: 20, display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end',
      }}
      >
        {landmarkImportAlert.show
          ? (
            <Alert
              message={landmarkImportAlert.message}
              type={landmarkImportAlert.type}
              closable
              onClose={landmarkImportAlertOnClose}
              style={{ maxWidth: '50%' }}
            />
          )
          : null}
        <Button.Group style={{ flexShrink: 0, flexWrap: 'nowrap' }}>
          <Popconfirm
            title={wMatrix('deleteAndImportLandmarksConfirm')}
            icon={<Icon type="exclamation-circle" theme="filled" style={{ color: 'red' }} />}
            onConfirm={() => this.handleUpload(true)}
          >
            <Button
              type="primary"
              disabled={fileState !== fileStateEnum.VALID || uploadLandmarksLoading}
              loading={uploadLandmarksAndDeleteLoading}
            >
              {wMatrix('deleteAndImport')}
            </Button>
          </Popconfirm>
          <Button
            type="primary"
            disabled={fileState !== fileStateEnum.VALID || uploadLandmarksAndDeleteLoading}
            loading={uploadLandmarksLoading}
            onClick={() => this.handleUpload()}
          >
            {wMatrix('import')}
          </Button>
        </Button.Group>
      </div>
    )
  }

  render() {
    const {
      wMatrix, ifShowModal, closeModal,
    } = this.props

    return (
      <Modal
        destroyOnClose
        visible={ifShowModal}
        onCancel={closeModal}
        footer={null}
        width="70%"
        zIndex={10}
        maskClosable={false}
      >
        <h1>{wMatrix('importLandmarks')}</h1>
        <ModalHeader headerName={wMatrix('downloadTemplateCSV')} />
        {this.templateDownloadButton()}
        <ModalHeader headerName={wMatrix('uploadFile')} />
        <div style={{ display: 'flex', flexDirection: 'column', margin: '0px 50px 20px' }}>
          {this.draggerArea()}
          {this.validationSection()}
          {this.footer()}
        </div>
      </Modal>
    )
  }
}
