import React from 'react'
import PropTypes from 'prop-types'
import Service from '@page/main/organisms/serviceView'
import helper from '@helper'
import FullScreenContainer from './fullScreen.container'

class ServiceContainer extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    appHeight: PropTypes.number.isRequired,
    devices: PropTypes.array,
  }

  static defaultProps = {
    devices: null,
  }

  /**
   * Roughly:
   *  111px above content
   *
   *  39px below content
   */
  render() {
    const { wMatrix, devices, appHeight } = this.props
    return (
      <FullScreenContainer
        heading={wMatrix('service')}
        filled
        devices={devices}
      >
        <Service
          devices={devices}
          appHeight={appHeight}
        />
      </FullScreenContainer>
    )
  }
}

export default helper()(ServiceContainer)
