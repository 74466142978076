import React from 'react'
import PropTypes from 'prop-types'

const styles = {
  dot: {
    height: '10px',
    width: '10px',
    flexShrink: 0,
    borderRadius: '50%',
    margin: '0 0 0 0',
  },
}

const colors = {
  active: '#4482FF',
  overdue: '#FF5500',
  completed: '#87D068',
  pending: '#A7A5A2',
  sent_unconfirmed: '#FFBA44',
  driver_unconfirmed: '#A7A5A2',
  driver_confirmed: '#A7A5A2',
}

export default class LogisticsStatus extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired,
  }

  render() {
    const { wMatrix, status } = this.props
    return (
      <div style={{
        display: 'flex', alignItems: 'center', fontSize: '14px', width: '100%',
      }}
      >
        <div style={{ ...styles.dot, backgroundColor: colors[status] }} />
        <div style={{ width: '3px' }} />
        {wMatrix(status)}
      </div>
    )
  }
}
