import React, { Component } from 'react'

import { Table, Checkbox } from 'antd'
import PropTypes from 'prop-types'
import { InputSearch } from '@atom/input'
import equal from 'deep-equal'
import clone from 'clone'

export default class NotificationTable extends Component {
  state = {
    searchVal: '',
    tableColumns: [],
    landmarksWithDirections: [],
  }

  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    updateSelectedLandmarkRows: PropTypes.func,
    editMode: PropTypes.bool,
    landmarksData: PropTypes.object,
    landmarks: PropTypes.array,
    alertSettings: PropTypes.object,
    checkedDevices: PropTypes.array,
    updateLandmarkDetails: PropTypes.func.isRequired,
  }

  static defaultProps = {
    landmarksData: {},
    editMode: false,
    updateSelectedLandmarkRows: null,
    landmarks: [],
    alertSettings: [],
    checkedDevices: [],
  }

  componentDidUpdate(prevProps) {
    if (!equal(prevProps, this.props)) {
      this.adjustTableColumns()
    }
    const { landmarks, checkedDevices } = this.props
    // the landmark preselected is based on the checked devices
    if (!equal(prevProps.checkedDevices, checkedDevices)) {
      // make sure all of the landmarks have a default direction
      const landmarksClone = landmarks.map(l => ({
        direction: {
          enter: 0,
          exit: 0,
        },
        ...l,
      }))
      this.updateLandmarksDirections(landmarksClone)
    }
  }

  updateLandmarkAlertDetails = (e, direction, row) => {
    const { landmarksWithDirections } = this.state
    const { updateLandmarkDetails, checkedDevices } = this.props
    const landmarksClone = clone(landmarksWithDirections)
    // Find the current direction from the state for this landmark
    for (let i = 0; i < landmarksClone.length; i += 1) {
      if (landmarksClone[i].id === row.id) {
        landmarksClone[i].direction[direction] = e.target.checked ? checkedDevices.length : 0
        updateLandmarkDetails({
          id: row.id,
          enter: !!landmarksClone[i].direction.enter,
          exit: !!landmarksClone[i].direction.exit,
        })
      }
    }

    this.setState({
      landmarksWithDirections: landmarksClone,
    })
  }

  adjustTableColumns = () => {
    const { wMatrix, checkedDevices } = this.props
    const generalTableColumns = [
      {
        title: wMatrix('LandmarkName'),
        dataIndex: 'name',
      },
      {
        title: wMatrix('Type'),
        dataIndex: 'type',
      },
      {
        title: wMatrix('Trigger on Enter'),
        render: row => (
          <Checkbox
            onChange={(e) => { this.updateLandmarkAlertDetails(e, 'enter', row) }}
            indeterminate={row.direction?.enter > 0 && row.direction?.enter < checkedDevices.length}
            checked={row.direction?.enter === checkedDevices.length}
          />
        ),
      },
      {
        title: wMatrix('Trigger on Exit'),
        render: row => (
          <Checkbox
            onChange={(e) => { this.updateLandmarkAlertDetails(e, 'exit', row) }}
            indeterminate={row.direction?.exit > 0 && row.direction?.exit < checkedDevices.length}
            checked={row.direction?.exit === checkedDevices.length}
          />
        ),
      },
    ]
    this.setState({ tableColumns: generalTableColumns })
  }

  onSelectChange = (selectedRowKeys, selectedRows) => {
    const { updateSelectedLandmarkRows } = this.props
    updateSelectedLandmarkRows(selectedRows, selectedRowKeys)
  }

  updateSearch = (event) => {
    this.setState({ searchVal: event.target.value.substr(0, 20) })
  }

  updateLandmarksDirections = (landmarks) => {
    const { alertSettings, checkedDevices } = this.props
    // Need to figure out how to pre-selected the enter/exit for selected devices
    const landmarksClone = clone(landmarks)
    // First generate an array for checked device Ids for convenience
    const checkedDevicesIds = []
    for (const checkedDevice of checkedDevices) {
      checkedDevicesIds.push(checkedDevice.id)
    }

    if (landmarksClone && landmarksClone.length > 0 && alertSettings['6']) {
      for (let i = 0; i < landmarksClone.length; i += 1) {
        const landmark = landmarksClone[i]
        /**
         * default the direction to br 1, so that later on we can do "multiply equal"
         * to make sure if all checked devices have the same direction for the same landmark
         *  */
        const direction = {
          enter: 0,
          exit: 0,
        }
        for (const alertSetting of alertSettings['6']) {
          // If this alert is for one of the checked device
          if (checkedDevicesIds.indexOf(alertSetting.numVehicleDeviceId) > -1) {
            // eslint-disable-next-line react/prop-types
            for (const alertLandmark of alertSetting.landmarks) {
              // if the landmark is one of the selected landmarks for one of the checked device
              if (alertLandmark.id === landmark.id) {
                switch (alertLandmark.direction) {
                  case 'enter':
                    direction.enter += 1
                    break
                  case 'exit':
                    direction.exit += 1
                    break
                  case 'both':
                    direction.enter += 1
                    direction.exit += 1
                    break
                  default:
                }
              }
            }
          }
        }
        landmarksClone[i].direction = direction
      }
    }
    this.setState({ landmarksWithDirections: landmarksClone })
  }

  returnFilteredLandmarks = () => {
    const { searchVal, landmarksWithDirections } = this.state
    let filteredLandmarks = clone(landmarksWithDirections)
    if (searchVal) {
      filteredLandmarks = filteredLandmarks.filter(landmark => (
        (landmark.name.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1)
      ))
    }
    return filteredLandmarks
  }

  render() {
    const {
      wMatrix,
      editMode,
      landmarksData,
    } = this.props
    const { tableColumns } = this.state

    return (
      <div>
        <h3 style={{ marginTop: 12 }}>{wMatrix('Landmarks')}</h3>
        <div style={{ display: editMode ? 'flex' : 'none', marginTop: 12, flexDirection: 'column' }}>
          <div style={{
            display: 'flex', alignSelf: 'flex-end', marginBottom: '5px', flexDirection: 'column', alignItems: 'flex-end',
          }}
          >
            <InputSearch
              className="groupPanelSearch"
              onChange={this.updateSearch}
              placeholder={wMatrix('Landmarks')}
            />
          </div>
          <Table
            dataSource={this.returnFilteredLandmarks()}
            columns={tableColumns}
            rowKey="id"
            size="middle"
            loading={landmarksData.loading}
            pagination={{ pageSize: 5 }}
          />
        </div>
      </div>
    )
  }
}
