/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:6fb87962-3c6c-476d-bc20-ab304fe4d28b",
    "aws_cognito_region": "us-east-2",
    "oauth": {},
    "aws_mobile_analytics_app_id": "55ca6604faab4f34bf71487108ce486d",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
