import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MarkerMenu from '@mol/tripMarkerMenu'
import MenuItem from '@atom/markerMenuItem'
import EventIcon from '@atom/eventIcon'


const K_WIDTH = 30
const K_HEIGHT = 30

const styles = {
  eventMarker: {
    position: 'absolute',
    width: K_WIDTH,
    height: K_HEIGHT,
    left: -K_WIDTH / 2,
    top: -K_HEIGHT / 2,
    borderRadius: '50%',
    // background: 'rgba(255,255,255, 0.9)',
  },
}

export default class Marker extends Component {
  static propTypes = {
    ifShow: PropTypes.bool,
    type: PropTypes.string,
    heading: PropTypes.number,
    speed: PropTypes.number,
    id: PropTypes.number,
    selectEvent: PropTypes.func,
    tripID: PropTypes.number,
    selected: PropTypes.bool,
    lat: PropTypes.number,
    lng: PropTypes.number,
    createLandmark: PropTypes.func,
    onItemOpen: PropTypes.func,
    onItemClose: PropTypes.func,
  }

  static defaultProps = {
    ifShow: true,
    type: null,
    heading: 0,
    speed: 0,
    id: null,
    selectEvent: null,
    tripID: null,
    selected: false,
    lat: null,
    lng: null,
    createLandmark: null,
    onItemOpen: null,
    onItemClose: null,
  }

  /**
   * Returns marker style based on locate type, heading and speed
   */
  getMarkerStyle = (type, heading, speed) => {
    const arrow = K_HEIGHT - 6
    const dot = K_HEIGHT - 20
    if (type === 'Locate' && speed > 0) {
      return {
        position: 'absolute',
        width: arrow,
        height: arrow,
        left: -(arrow / 2),
        top: -(arrow / 2),
        margin: 0,
        // transform: `rotate(${heading - 90}deg)`,
        borderRadius: '50%',
      }
    } if (type === 'Locate' && speed === 0) {
      return {
        position: 'absolute',
        width: dot,
        height: dot,
        left: -(dot / 2),
        top: -dot / 2,
        margin: 0,
        borderRadius: '50%',
      }
    }
    return styles.eventMarker
  }

  /**
   * Handles marker selection
   */
  onMarkerClick = () => {
    const {
      selectEvent, id, tripID,
    } = this.props
    if (tripID) {
      selectEvent(id, tripID)
    } else {
      selectEvent(id)
    }
  }

  render() {
    const {
      ifShow, type, heading, speed, selected, createLandmark, lat, lng, onItemOpen, onItemClose, id,
    } = this.props
    // Marker z-Index
    // if selected, should be on top.
    let zIndex = 5
    if (selected) {
      zIndex = 500
    } else if (type !== 'locate' && parseInt(id, 10) >= 0) { // non locate and make sure id is valid int
      // Non-Locate markers will render zIndex based on id
      zIndex = 6 + parseInt(id, 10)
    }

    return (
      ifShow
        ? (
          <div style={{ position: 'relative', zIndex }}>
            <MarkerMenu
              open={selected}
            >
              <MenuItem
                type="quickLandmark"
                createLandmark={createLandmark}
                clickable
                lat={lat}
                lng={lng}
                onItemOpen={onItemOpen || undefined}
                onItemClose={onItemClose || undefined}
              />
            </MarkerMenu>
            <div
              tabIndex="-1"
              role="button"
              onClick={this.onMarkerClick}
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
              <EventIcon
                event={type}
                heading={heading}
                speed={speed}
                imgStyle={this.getMarkerStyle(type, heading, speed)}
              />
            </div>
          </div>
        )
        : <div />
    )
  }
}
