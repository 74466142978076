import React from 'react'
import PropTypes from 'prop-types'
import helper from '@helper'
import VideoClipsView from '@org/videoClipsView'
import FullScreenContainer from './fullScreen.container'

class VideoClipsContainer extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    appHeight: PropTypes.number.isRequired,
    devices: PropTypes.array.isRequired,
    selectModal: PropTypes.func.isRequired,
    updateClipSearch: PropTypes.func.isRequired,
    clipsSearchValue: PropTypes.string.isRequired,
  }

  render() {
    const {
      wMatrix, appHeight, devices, selectModal, clipsSearchValue, updateClipSearch,
    } = this.props
    return (
      <FullScreenContainer
        heading={wMatrix('videoClips')}
      >
        <VideoClipsView
          appHeight={appHeight}
          wMatrix={wMatrix}
          devices={devices}
          selectModal={selectModal}
          clipsSearchValue={clipsSearchValue}
          updateClipSearch={updateClipSearch}
        />
      </FullScreenContainer>
    )
  }
}

export default helper()(VideoClipsContainer)
