import React, { Component } from 'react'
import { Popconfirm, Icon } from 'antd'
import ButtonWrapper from '@atom/textButton'
import CustomIcon from '@atom/icon'
import PropTypes from 'prop-types'

/**
 * @description This is a simple component that disables after confirmed or
 * if passed a loading prop of true
 */
export default class OneClickButton extends Component {
  static propTypes = {
    /** @Parent */
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    okButtonProps: PropTypes.object,
    title: PropTypes.string,
    iconType: PropTypes.string,
  }

  static defaultProps = {
    loading: null,
    onClick: null,
    okText: 'Confirm',
    cancelText: 'Cancel',
    okButtonProps: undefined,
    title: 'Are you sure?',
    iconType: 'upload',
  }

  state = {
    disabled: false,
  }

  onClick = () => {
    const { onClick } = this.props
    this.setState({ disabled: true })
    onClick()
  }

  returnIcon = () => {
    const { disabled } = this.state
    const { iconType, loading } = this.props
    switch (iconType) {
      case 'delete':
        return (
          <Icon
            theme="twoTone"
            twoToneColor={disabled || loading ? '#A9A9A9' : '#f64744'}
            type="delete"
          />
        )
      default:
        return (
          <CustomIcon
            type={disabled || loading ? 'tv-loading' : 'tv-upload'}
            width={22}
          />
        )
    }
  }

  render() {
    const { disabled } = this.state
    const {
      loading, okButtonProps, okText, cancelText, title,
    } = this.props
    /**
     * @todo - make popConfirm optional for this component
     */
    return (
      <Popconfirm
        placement="leftTop"
        title={title}
        onConfirm={() => this.onClick()}
        okText={okText}
        cancelText={cancelText}
        okButtonProps={okButtonProps}
      >
        <ButtonWrapper
          onClick={() => {}}
          useAsWrapper
          disabled={disabled || loading}
        >
          {this.returnIcon()}
        </ButtonWrapper>
      </Popconfirm>
    )
  }
}
