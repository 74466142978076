import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ScrollElement from '@page/main/atoms/scrollElement'
import { Spin, Icon } from 'antd'
import TextButton from '../atoms/textButton'

const styles = {
  scrollContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    scrollBehavior: 'smooth',
    alignItems: 'stretch',
    height: '100%',
    width: '100%',
  },
  /** @NOTE header style is a flexbox column */
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: '0',
    backgroundColor: '#EBEBEB',
    padding: '5px',
    // minHeight: '50px',
    alignSelf: 'stretch',
    position: 'sticky',
    top: 0,
    borderWidth: '0px 0px 1px 0px',
    borderStyle: 'solid',
    borderColor: '#d9d9d9',
    zIndex: '2',
  },
  miniHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: '0',
    backgroundColor: '#EBEBEB',
    padding: '5px',
    // minHeight: '50px',
    alignSelf: 'stretch',
    position: 'sticky',
    top: 0,
    borderWidth: '0px 0px 1px 0px',
    borderStyle: 'solid',
    borderColor: '#d9d9d9',
    zIndex: '2',
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: '0',
    backgroundColor: '#fff',
    padding: '5px',
    // minHeight: '50px',
    alignSelf: 'stretch',
    position: 'sticky',
    top: 0,
    borderWidth: '0px 0px 1px 0px',
    borderStyle: 'solid',
    borderColor: '#d9d9d9',
  },
  animation: {
    transition: 'all 0.2s ease-out',
  },
}

/**
 * @todo
 * - Make sectionIdKey and itemIdKey optional and if no keys are made, make them
 * - Maybe remove zIndex values and make dynamic solution to prevent unwanted Stacking Context
 */

/**
  * @Note Headers Elements are placed on zIndex 2 and Item Elements are placed on zIndex 1.
  * This is to facilitate any transform updates (such as icon rotation in timeline) in child items
  */

export default class SectionScrollView extends Component {
  elements = {}

  scrollArea = React.createRef()

  static propTypes = {
    renderItem: PropTypes.func.isRequired,
    sectionIdKey: PropTypes.string.isRequired,
    itemIdKey: PropTypes.string.isRequired,
    renderSectionHeader: PropTypes.func,
    renderSectionFooter: PropTypes.func,
    sections: PropTypes.array,
    itemArrayKey: PropTypes.string,
    selectedId: PropTypes.string,
    collapsable: PropTypes.bool,
    noHeaders: PropTypes.bool,
    noFooters: PropTypes.bool,
    miniMode: PropTypes.bool, // used in case of mini right drawer
    delayScrollToElement: PropTypes.number, // ms of delay time
  }

  static defaultProps = {
    sections: [],
    renderSectionHeader: () => (
      <div style={styles.headerContainer} />
    ),
    renderSectionFooter: () => (
      <div style={styles.footerContainer} />
    ),
    itemArrayKey: null,
    selectedId: null,
    collapsable: false,
    noHeaders: false,
    noFooters: false,
    miniMode: false,
    delayScrollToElement: null,
  }

  state = {
    collapsed: {},
  }

  // if item is selected on mount, scroll to element
  componentDidMount = () => {
    const { selectedId } = this.props
    if (selectedId) {
      this.scrollToElement(selectedId)
    }
  }

  componentDidUpdate = (prevProps) => {
    const { selectedId, sections } = this.props
    // create starting collapse state of section headers
    if (prevProps.sections.length !== sections.length) {
      this.registerSectionHeaderCollapse()
    }
    // Check to see if an item was selected
    if (prevProps.selectedId !== selectedId && selectedId) {
      this.scrollToElement(selectedId)
    }
  }

  /**
   * If newly selected item is hidden, expand section. This is to prepare for the scrollTo in
   * componentDidUpdate
  */
  componentWillUpdate = (nextProps) => {
    const { selectedId, collapsable } = this.props
    if (collapsable && this.collapseState(nextProps.selectedId)
    && selectedId !== nextProps.selectedId) {
      this.collapseSectionToggle(nextProps.selectedId.split('-')[0], 'open')
    }
  }

  componentWillUnmount = () => {
    // delete all references
    for (const [key] of Object.entries(this.elements)) {
      this.unregister(key)
    }
  }

  /** Create the starting state for the section collapse bools */
  registerSectionHeaderCollapse = () => {
    const { sections, sectionIdKey } = this.props
    const collapsed = {}
    for (let i = 0; i < sections.length; i += 1) {
      const id = sections[i][sectionIdKey]
      // console.log(id)
      collapsed[`${id}`] = false
    }
    this.setState({ collapsed })
  }

  /**
   * @description Add element reference by name to elements object.
   * This is passed as a prop to ScrollElements
   */
  register = (name, ref) => {
    this.elements[name] = ref
  }

  /**
   * @description Delete element reference by name from elements object.
   * This is passed as a prop to ScrollElements
   */
  unregister = (name) => {
    delete this.elements[name]
  }

  /** Returns bool based on the collapsed state for a given section */
  showElement = (id) => {
    const { collapsed } = this.state
    const sectionId = id.split('-')[0]
    if (collapsed[sectionId]) {
      return false
    }
    return true
  }

  /**
   * Check collapse state of section given section id or item identifier
   */
  collapseState = (identifier) => {
    const { collapsed } = this.state
    const sectionId = identifier.split('-')[0]
    return collapsed[sectionId]
  }

  /**
   * Toggle indicated Collapse state given the section id
   * @param {string} id - string identifier for section id
   * @param {string} forceState - `Optional` force state 'open' or 'close'
   */
  collapseSectionToggle = (id, forceState) => {
    const { collapsed } = this.state
    const newCollapsed = collapsed
    // if forceState
    if (forceState) {
      newCollapsed[id] = forceState !== 'open'
    } else {
      newCollapsed[id] = !collapsed[id]
    }
    this.setState({ collapsed: newCollapsed })
  }

  /**
   * @private
   *
   * @description Takes nested object key where keys are separated by '.'
   * @param {string} keyString string of key. Can be nested, separated by '.'
   */
  parseKey = (keyString) => {
    const keysArray = keyString.split('.')
    return keysArray
  }

  /**
   * @description return array of items given their section and the correlating object key(s)
   * @example `'details.itemArray'` would be the `itemArrayKey`
   * object {
   *  details: {
   *    itemArray: [...]
   *  }
   * }
   * @param {object || array} section Data containing items array. If section is array,
   * array is returned. If `itemArrayKey` is given, we find the array within the `section` object.
   */
  sectionItemsArray = (section) => {
    const { itemArrayKey } = this.props
    let items = section
    if (itemArrayKey) {
      const keys = this.parseKey(itemArrayKey)
      for (let i = 0; i < keys.length; i += 1) {
        if (items[keys[i]]) {
          items = items[keys[i]]
        }
      }
    }
    // array and contains items
    if (items.length && items.length > 0) {
      return items
    }
    return []
  }

  /**
   * @description Create and return scrollId based on section and item ids
   */
  scrollId = (section, item) => {
    const { sectionIdKey, itemIdKey } = this.props
    const identifier = `${section[sectionIdKey]}-${item[itemIdKey]}`
    return identifier
  }

  /**
   * Scroll to element based on item identifier
   */
  scrollToElement = (id) => {
    const { delayScrollToElement } = this.props
    const node = this.elements[id]
    if (node) {
      const headerNode = this.elements[`${id.split('-')[0]}-header`]
      let headerOffset = 0
      // account for header if exists
      if (headerNode && headerNode.current && headerNode.current.clientHeight) {
        headerOffset = headerNode.current.clientHeight
      }
      const scrollViewNode = this.scrollArea
      const yOffset = node.current.offsetTop - scrollViewNode.current.offsetTop - headerOffset
      // if optional delay is set, scroll to element after deleay time (in ms)
      if (delayScrollToElement) {
        setTimeout(() => { scrollViewNode.current.scrollTop = yOffset }, delayScrollToElement)
      } else {
        scrollViewNode.current.scrollTop = yOffset
      }
    }
  }

  /** Renders collapse arrow based on whether a section is collapsed and if the scrolView
   * is in 'miniMode'. (meaning it has a small width)
   */
  renderCollapseButton = (sectionId) => {
    const { collapsable, miniMode } = this.props
    return (collapsable ? (
      <div style={{
        display: 'flex', justifyContent: 'center', alignSelf: 'center', marginRight: '10px',
      }}
      >
        <TextButton useAsWrapper onClick={() => { this.collapseSectionToggle(sectionId) }}>
          {!miniMode ? (
            <Icon
              type="right"
              style={{
                ...styles.animation,
                transform: this.showElement(sectionId) ? 'rotate(90deg)' : null,
              }}
            />
          )
            : (
              <Icon
                type="up"
                style={{
                  ...styles.animation,
                  transform: this.showElement(sectionId) ? 'rotate(180deg)' : null,
                }}
              />
            )
          }
        </TextButton>
      </div>
    ) : null)
  }

  renderSectionHeader = (section) => {
    const {
      noHeaders, renderSectionHeader, sectionIdKey, collapsable, miniMode,
    } = this.props
    let headerElementDiv = null
    if (!noHeaders && renderSectionHeader) {
      const headerElement = renderSectionHeader(section)
      const headerRef = React.createRef()
      this.register(`${section[sectionIdKey]}-header`, headerRef)

      headerElementDiv = (
        <div
          key={`${section[sectionIdKey]}-header`}
          ref={headerRef}
          style={miniMode ? styles.miniHeaderContainer : styles.headerContainer}
        >
          {!miniMode && collapsable ? this.renderCollapseButton(`${section[sectionIdKey]}`) : null}
          <div style={{ flexGrow: '1' }}>
            {headerElement}
          </div>
          {miniMode && collapsable ? this.renderCollapseButton(`${section[sectionIdKey]}`) : null}
        </div>
      )
    }
    return headerElementDiv
  }

  /** Render section footer from props and wrap with div like header */
  renderSectionFooter = (section) => {
    const { renderSectionFooter, noFooters, sectionIdKey } = this.props
    let footerElementDiv = null
    if (!noFooters && renderSectionFooter) {
      const footerElement = renderSectionFooter(section)

      footerElementDiv = (
        <div
          key={`${section[sectionIdKey]}-footer`}
          // ref={headerRef}
          style={styles.footerContainer}
        >
          <div style={{ flexGrow: '1', alignSelf: 'center', marginTop: '5px' }}>
            {footerElement}
          </div>
        </div>
      )
    }
    return footerElementDiv
  }

  /**
   * @description Creates section headers and items and places them in an array to be rendered.
   * (This is done to render both headers and items within the same div and not nested, which helps
   * with scrolling and sticky headers.)
   */
  returnSections = () => {
    const {
      sections, renderItem, sectionIdKey,
    } = this.props
    const reactElements = []
    sections.forEach((section) => {
      /** @NOTE section can have loading state and list will account for it */
      if (section.loading || section.Loading) {
        reactElements.push(
          <div key={`${section[sectionIdKey]}-spin`} style={{ alignSelf: 'center', margin: 10 }}>
            <Spin />
          </div>,
        )
      } else {
        const reactElementsItems = []
        /** Create header and reference and push to render elements array */
        const headerElementDiv = this.renderSectionHeader(section)

        // get to correct items array in section
        const items = this.sectionItemsArray(section)

        /** Create each item and push to render elements array */
        items.forEach((item) => {
          const itemElement = renderItem(section, item)
          const identifier = this.scrollId(section, item)
          reactElementsItems.push(
            <ScrollElement
              style={{ zIndex: '1' }}
              key={identifier}
              name={identifier}
              register={this.register}
              unregister={this.unregister}
              show={this.showElement(identifier)}
            >
              {itemElement}
            </ScrollElement>,
          )
        })
        const footerElementDiv = this.renderSectionFooter(section)
        reactElements.push(
          <div key={`${section[sectionIdKey]}`}>
            {headerElementDiv || null}
            {reactElementsItems}
            {footerElementDiv || null}
          </div>,
        )
      }
    })
    return reactElements
  }

  render() {
    return (
      <div style={styles.scrollContainer} ref={this.scrollArea} className="scrollView div">
        {this.returnSections()}
      </div>
    )
  }
}
