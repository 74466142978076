import Amplify, { Analytics, AWSKinesisProvider } from 'aws-amplify'
import awsExports from '../aws-exports'

Amplify.configure(awsExports)
Analytics.addPluggable(new AWSKinesisProvider())

// Configure the plugin after adding it to the Analytics module
Analytics.configure({
  AWSKinesis: {

    // OPTIONAL -  Amazon Kinesis service region
    region: 'us-east-2',

    // OPTIONAL - The buffer size for events in number of items.
    bufferSize: 1000,

    // OPTIONAL - The number of events to be deleted from the buffer when flushed.
    flushSize: 100,

    // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
    flushInterval: 5000, // 5s

    // OPTIONAL - The limit for failed recording retries.
    resendLimit: 5,
  },
})

/**
 * @typedef {Object} AnalyticsData
 *
 * @property {string} feature
 * @property {string} page
 * @property {string} event
 * @property {string} token
 * @property {string} datetime
 */

/**
 * The main function to run at any event
 *
 * @param {AnalyticsData} data leave token and datetime null
 */
const record = (data) => {
  const object = data
  object.token = localStorage.getItem('rft-token') || sessionStorage.getItem('rft-token')
  // If no token, do not record
  if (!object.token) {
    return
  }
  object.datetime = (new Date()).toISOString()
  object.app = 'web'
  Analytics.record({
    data: object,
    streamName: 'V3-Analytics',
  }, 'AWSKinesis')
}

export default {
  record,
}
