/* eslint-disable object-curly-newline */
import React from 'react'
import PropTypes from 'prop-types'
import YouTube from 'react-youtube'
import { Divider, Row, Col, Pagination } from 'antd'

export default class Guides extends React.Component {
  state = {
    videoGuides: ['IYd9o21mQ1I', 'eryNuIAZ_g4', 'KzM4FrD2r6U', 'MXMaXJYysl4', 'pV9kUAoJh68', '2JIVfXCwOBY', 'kbss5CI1QkQ'],
    videoGuidesPage: 1,
    manuals: ['NewInV3', 'Admin', 'DeviceManager', 'DistanceLandmarks', 'Driver_ScoreCard_Explainer', 'DriverScoreCard', 'GroupsVsLabel', 'Map', 'MapLegend', 'Preferences', 'Reports', 'Service', 'UserPermission'],
    manualGuidesPage: 1,
    currentPage: 1,
  }

  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
  }

  handleChangePage = (e) => {
    this.setState({ currentPage: e })
  }

  handleVideoGuidesPage = (e) => {
    this.setState({ videoGuidesPage: e })
  }

  handleManualGuidesPage = (e) => {
    this.setState({ manualGuidesPage: e })
  }

  renderVideoGuidesPage = (opts) => {
    const { videoGuidesPage, videoGuides } = this.state
    const columns = []
    let index = 0

    if (videoGuidesPage > 1) index = (videoGuidesPage - 1) * 3

    const finish = index + 3

    while (index < videoGuides.length && index < finish) {
      columns.push(
        <Col xs={8}>
          <YouTube
            videoId={videoGuides[index]}
            opts={opts}
          />
        </Col>,
      )

      index += 1
    }

    return columns
  }

  renderManualGuides = () => {
    const { manualGuidesPage, manuals } = this.state
    const columns = []
    let index = 0

    if (manualGuidesPage > 1) index = (manualGuidesPage - 1) * 3

    const finish = index + 3

    while (index < manuals.length && index < finish) {
      const link = `/userGuides/${manuals[index]}.pdf`
      const image = `/userGuides/${manuals[index]}.jpg`
      columns.push(
        <Col xs={6}>
          <a target="_blank" href={link} rel="noopener noreferrer">
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid black' }}
            >
              <img
                src={image}
                alt=""
                height="180px"
                width="240px"
              />
            </div>
          </a>
        </Col>,
      )

      index += 1
    }

    return columns
  }

  renderPage1 = opts => (
    <>
      <Col xs={8}>
        <YouTube
          videoId="gXabHn5GrtA"
          opts={opts}
        />
      </Col>
      <Col xs={8}>
        <YouTube
          videoId="wjuBZhPk5DA"
          opts={opts}
        />
      </Col>
      <Col xs={8}>
        <YouTube
          videoId="fITXFOp2P6Q"
          opts={opts}
        />
      </Col>
    </>
  )

  renderPage2 = opts => (
    <>
      <Col xs={8}>
        <YouTube
          videoId="_8CLraTK2vA"
          opts={opts}
        />
      </Col>
      <Col xs={8}>
        <YouTube
          videoId="uoITHBZ0ViA"
          opts={opts}
        />
      </Col>
    </>
  )

  render() {
    const { wMatrix } = this.props
    const { currentPage, videoGuides, manuals } = this.state
    const opts = {
      height: '200',
      width: '300',
    }

    return (
      <div>
        <h2>{wMatrix('v3VideoGuides')}</h2>
        <div style={{ width: '80%' }}>
          <Divider style={{ marginTop: 0 }} />
        </div>
        <Row type="flex" justify="start" align="middle">
          {this.renderVideoGuidesPage(opts)}
        </Row>
        <Pagination
          defaultCurrent={1}
          onChange={this.handleVideoGuidesPage}
          total={videoGuides.length}
          pageSize={3}
        />
        <h2 style={{ marginTop: 40 }}>{wMatrix('v3ManualGuides')}</h2>
        <div style={{ width: '80%' }}>
          <Divider style={{ marginTop: 0 }} />
        </div>
        <Row type="flex" justify="start" align="middle">
          {this.renderManualGuides()}
        </Row>
        <Pagination
          defaultCurrent={1}
          onChange={this.handleManualGuidesPage}
          total={manuals.length}
          pageSize={3}
        />
        <h2>{wMatrix('deviceInstallationVideos')}</h2>
        <div style={{ width: '80%' }}>
          <Divider style={{ marginTop: 0 }} />
        </div>
        <Row type="flex" justify="start" align="middle">
          {currentPage === 1 ? this.renderPage1(opts) : this.renderPage2(opts)}
        </Row>
        <Pagination defaultCurrent={1} onChange={this.handleChangePage} total={5} pageSize={3} />
        <h2 style={{ marginTop: 40 }}>{wMatrix('userGuides')}</h2>
        <div style={{ width: '80%' }}>
          <Divider style={{ marginTop: 0 }} />
        </div>
        <Row type="flex" justify="start" align="middle">
          <Col xs={6}>
            <a href="/userGuides/ELD_Driver_Cheat_Sheet.pdf" target="_blank">
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid black' }}
              >
                <img
                  src="/userGuides/ELD_Driver_Cheat_Sheet.jpg"
                  alt="ELD_Driver_Cheat_Sheet"
                  height="180px"
                  width="240px"
                />
                <p>ELD Cheat Sheet</p>
              </div>
            </a>
          </Col>
        </Row>
      </div>
    )
  }
}
