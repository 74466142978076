import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Map from '@mol/map'
import ClusterMap from '@mol/clusterMap'
import { DevicesTableV2 } from '@mol/devicesTable'
import DeviceMap from '@mol/deviceMap'
import DeviceDetails from '@mol/rightPanel/deviceDetails'
import TimelineView from '@org/timelineView'
import {
  Select, Button, Switch, Tooltip, Icon,
} from 'antd'
import helper from '@helper'
import CustomIcon from '@atom/icon'
import RightContainer from './right.container'

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
  },
  timelineContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#367',
  },
  aliasContainer: {
    display: 'flex',
    // marginLeft: '-20px',
    flexWrap: 'nowrap',
    flexShrink: '0',
    alignItems: 'flex-end',
    height: '50px',
  },
  alias: {
    // lineHeight: '50px',
    marginBottom: '0px',
    maxWidth: '50%',
    minWidth: 100,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // margin: '0px 20px 0px 0px',
  },
  headingContainer: {
    display: 'flex',
    marginTop: '10px',
    marginRight: '6px',
    flexWrap: 'nowrap',
    alignItems: 'center',
    width: '100%',
  },
  select: {
    marginLeft: 10,
    minWidth: 150,
    maxWidth: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    padding: 10,
  },
}

const { Option } = Select

class DevicesContainer extends PureComponent {
  static propTypes = {
    devices: PropTypes.array,
    landmarks: PropTypes.array,
    findLandmarkCenter: PropTypes.func.isRequired,
    selectedDevice: PropTypes.object,
    selectDevice: PropTypes.func,
    unselectDevice: PropTypes.func,
    mapSettings: PropTypes.object,
    updateMapSettings: PropTypes.func,
    setStarterInterrupt: PropTypes.func.isRequired,
    createLandmark: PropTypes.func.isRequired,
    locateNow: PropTypes.object.isRequired,
    driversForAssignment: PropTypes.array.isRequired,
    assignDriverToDevice: PropTypes.func.isRequired,
    selectedDeviceSocketEvent: PropTypes.object,
    /** @Helper */
    wMatrix: PropTypes.func.isRequired,
    getPermission: PropTypes.func.isRequired,
    /** @Main */
    mainNav: PropTypes.string.isRequired,
    mapIconSizeMode: PropTypes.string,
    deviceListMode: PropTypes.string,
    mapIconMode: PropTypes.string,
    selectModal: PropTypes.func.isRequired,
    noLocateNowTypeList: PropTypes.array.isRequired,
    openMessenger: PropTypes.func,
    minuteTZOffset: PropTypes.number.isRequired,
  }

  static defaultProps = {
    devices: [],
    landmarks: null,
    selectedDevice: null,
    mapSettings: {},
    selectedDeviceSocketEvent: null,
    deviceListMode: 'comfortable',
    mapIconMode: 'comfortable',
    mapIconSizeMode: 'large',
    updateMapSettings: () => { },
    selectDevice: () => { },
    unselectDevice: () => { },
    openMessenger: () => { },
  }

  /**
   * This state is kept to be able to update the default value of the select
   * box. The select component does not re-render on change of default value.
   */
  state = {
    selectedDriver: 0,
    // used to keep track of small tab on close of selected device
    smallTab: 's1',
    colorState: 'textGray',
  }

  componentDidUpdate = (prevProps) => {
    const { selectedDevice } = this.props
    if (!selectedDevice) return
    /** update when the selected device or selected device driver has changed */
    if (!prevProps.selectedDevice
      || (prevProps.selectedDevice.id !== selectedDevice.id
        || prevProps.selectedDevice.currentDriver !== selectedDevice.currentDriver)) {
      this.onDeviceUpdate()
    }
  }

  /**
   * @description Used to set state for selected driver on change of selectedDevice
   */
  onDeviceUpdate = () => {
    const { selectedDevice } = this.props
    this.setState({
      selectedDriver: selectedDevice.currentDriver
        ? selectedDevice.currentDriver.id : 0,
    })
  }

  showManager = () => {
    const { selectModal, selectedDevice } = this.props
    selectModal('deviceManager', selectedDevice)
  }

  renderLiveViewMap = (initMap, fitBounds) => {
    const {
      devices, mapIconMode, mapIconSizeMode, selectedDevice, selectDevice, mapSettings,
      landmarks, findLandmarkCenter, mainNav,
    } = this.props

    return (
      <ClusterMap
        initMap={initMap}
        fitBounds={fitBounds}
        devices={devices}
        mapIconMode={mapIconMode}
        mapIconSizeMode={mapIconSizeMode}
        selectDevice={selectDevice}
        selectedDevice={selectedDevice}
        settings={mapSettings}
        landmarks={landmarks}
        findLandmarkCenter={findLandmarkCenter}
        mainNav={mainNav}
      />
    )
  }

  renderLiveView = () => {
    const {
      mapSettings,
      updateMapSettings,
      deviceListMode,
      mapIconMode,
      mapIconSizeMode,
      selectModal,
    } = this.props

    return (
      <div key="s1" style={styles.contentContainer}>
        <Map
          settings={mapSettings}
          updateSettings={updateMapSettings}
          renderMap={this.renderLiveViewMap}
          deviceListMode={deviceListMode}
          mapIconMode={mapIconMode}
          mapIconSizeMode={mapIconSizeMode}
          landmarkToggle
          clusteringToggle
          selectModal={selectModal}
        />
      </div>
    )
  }

  renderDeviceListTab = () => {
    const { devices, selectDevice, wMatrix } = this.props

    return (
      <div key="s2" style={styles.listContainer}>
        <DevicesTableV2
          devices={devices}
          columnKeys={['alias', 'driver', 'groups', 'labels', 'productKey', 'serialNumber', 'location', 'datetime']}
          selectDevice={selectDevice}
          wMatrix={wMatrix}
        />
      </div>
    )
  }

  renderDeviceMap = (initMap, fitBounds) => {
    const {
      selectedDevice, mapIconMode, mapIconSizeMode, mapSettings, landmarks, findLandmarkCenter,
    } = this.props

    return (
      <DeviceMap
        initMap={initMap}
        fitBounds={fitBounds}
        device={selectedDevice}
        mapIconMode={mapIconMode}
        mapIconSizeMode={mapIconSizeMode}
        settings={mapSettings}
        landmarks={landmarks}
        findLandmarkCenter={findLandmarkCenter}
      />
    )
  }

  renderDeviceLiveView = () => {
    const {
      mapSettings,
      updateMapSettings,
      mapIconMode,
      mapIconSizeMode,
      selectModal,
    } = this.props

    return (
      <div key="l1" style={styles.contentContainer}>
        <Map
          settings={mapSettings}
          updateSettings={updateMapSettings}
          renderMap={this.renderDeviceMap}
          mapIconMode={mapIconMode}
          mapIconSizeMode={mapIconSizeMode}
          landmarkToggle
          selectModal={selectModal}
        />
      </div>
    )
  }

  renderTimelineView = () => {
    const {
      mapSettings,
      updateMapSettings,
      selectedDevice,
      createLandmark,
      selectModal,
      mainNav,
      landmarks,
      findLandmarkCenter,
      minuteTZOffset,
      socketEvents,
      selectedDeviceSocketEvent,
    } = this.props

    return (
      <div key="l2" style={styles.contentContainer}>
        <TimelineView
          mainNav={mainNav}
          createLandmark={createLandmark}
          mapSettings={mapSettings}
          updateMapSettings={updateMapSettings}
          selectedDevice={selectedDevice}
          selectModal={selectModal}
          landmarks={landmarks}
          findLandmarkCenter={findLandmarkCenter}
          minuteTZOffset={minuteTZOffset}
          socketEvents={socketEvents}
          selectedDeviceSocketEvent={selectedDeviceSocketEvent}
        />
      </div>
    )
  }

  /**
   * @private
   * @description Handles onChange event for driver selection
   */
  handleDriverSelection = (value) => {
    const { assignDriverToDevice, selectedDevice } = this.props
    this.setState({ selectedDriver: value })
    assignDriverToDevice(selectedDevice.id, value)
  }

  /**
   * @private
   * @description Returns the driver options available in a select box
   * @returns {Component}
   */
  driverQuickAddSelection = () => {
    const {
      driversForAssignment,
      wMatrix,
      selectedDevice,
    } = this.props
    if (selectedDevice.fobEnabled) return null
    const { selectedDriver } = this.state
    const drivers = JSON.parse(JSON.stringify(driversForAssignment))
    drivers.splice(0, 0, { id: 0, name: wMatrix('unassigned') })
    return (
      <Select
        value={selectedDriver}
        style={styles.select}
        onChange={this.handleDriverSelection}
        dropdownMatchSelectWidth={false}
        size="small"
      // disabled={!!selectedDevice.fobEnabled}
      >
        {drivers.map(driver => (
          <Option key={driver.id} value={driver.id} disabled={!!driver.device}>
            {driver.name}
            {driver.device && driver.device.id !== selectedDevice.id ? ` (${driver.device.alias})` : null}
          </Option>
        ))}
      </Select>
    )
  }

  /**
   * @private
   * Handles starter interrupt switch on change
   */
  handleStarterChange = (device, checked) => {
    const { setStarterInterrupt } = this.props
    setStarterInterrupt(device, checked)
  }

  /**
   * @private
   * Returns starter interrupt component
   */
  returnStarterInterrupt = (selectedDevice) => {
    const { wMatrix } = this.props
    if (selectedDevice.starterInterrupt) {
      const { isEnabled } = selectedDevice.starterInterrupt
      return (
        <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
          {`${wMatrix('disableStarter')}:`}
          <Switch
            style={{ marginLeft: 4, marginRight: 4 }}
            checked={isEnabled}
            onChange={(checked) => { this.handleStarterChange(selectedDevice, checked) }}
          />
          <Tooltip
            placement="bottom"
            title={wMatrix('starterInterruptToolTip')}
            arrowPointAtCenter
            trigger={['hover', 'click']}
          >
            <Icon type="question-circle" theme="outlined" />
          </Tooltip>
        </div>
      )
    }
    return null
  }

  /**
     * @description Returns camera status icon
     * @returns {Element}
     */
  returnCameraStatusIcon = () => {
    const { selectedDevice, getPermission } = this.props
    const { type, camera } = selectedDevice
    if (type.includes('IMD') && getPermission('Camera', 'ynRead')) {
      const color = camera.status === 'online' ? 'green' : 'red'
      return (
        <div style={{ marginRight: '10px' }}>
          <CustomIcon
            type="camera"
            width={16}
            color={color}
          />
        </div>
      )
    }
    return null
  }

  renderHeaderRightButton = () => {
    const {
      selectedDevice, wMatrix, locateNow, noLocateNowTypeList,
    } = this.props

    // Comment out old const: selectModal, getPermission,

    const { type } = selectedDevice

    if (selectedDevice.starterInterrupt) {
      return this.returnStarterInterrupt(selectedDevice)
    }

    /* Remove Live Video (saving the logic for later if we change our mind)
    if (type.includes('IMD') && getPermission('Camera', 'ynRead')) {
      const disabled = camera.status !== 'online' || !camera.liveStreamURL
      const camModalProps = {
        clipType: 'live',
        device: selectedDevice,
        url: camera.liveStreamURL,
      }
      return (
        <Button
          type="primary"
          onClick={() => { selectModal('camera', camModalProps) }}
          disabled={disabled}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div style={{ marginRight: 10 }}>
              <CustomIcon
                type="live-camera"
                width={20}
                color={disabled ? '#D8D8D8' : 'white'}
              />
            </div>
            {wMatrix('liveVideo')}
          </div>
        </Button>
      )
    }
    */

    if (!noLocateNowTypeList.includes(type)) {
      return (
        <Button
          type="primary"
          // size="small"
          onClick={() => { locateNow.run(selectedDevice.id) }}
          disabled={!locateNow.canRun}
          loading={locateNow.loading}
          style={{ margin: '0px 5px' }}
        >
          {locateNow.canRun ? wMatrix('locateNow') : wMatrix('pending')}
        </Button>
      )
    }
    return null
  }

  /**
   * Currently only garmin devices have messenger feature. This returns the messenger button if the
   * device has a garmin attached
   * @returns Element
   */
  messengerButton = () => {
    const { selectedDevice, openMessenger } = this.props
    const { colorState } = this.state
    if (!selectedDevice.garmin) {
      return null
    }
    return (
      <div style={{ marginLeft: 4, marginRight: 4 }}>
        <Button
          type="default"
          onClick={() => { openMessenger(selectedDevice) }}
          onMouseEnter={() => { this.setState({ colorState: 'highlightBlue' }) }}
          onMouseLeave={() => { this.setState({ colorState: 'textGray' }) }}
          style={{
            width: 36,
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0px 5px',
          }}
        >
          <CustomIcon
            type="chat-bubble"
            width={16}
            color={colorState}
          />
        </Button>
      </div>
    )
  }

  /**
   * @public
   * @description Custom Heading for right container. This returns a component with
   * the device alias and driver quickAdd
   * @param {Object} selectedDevice The selected device
   */
  renderHeading = (selectedDevice) => {
    const title = selectedDevice ? selectedDevice.alias : ''
    if (selectedDevice) {
      return (
        <div style={styles.headingContainer}>
          <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            {this.returnCameraStatusIcon()}
            <h2 style={styles.alias} title={title}>{title}</h2>
            {this.driverQuickAddSelection()}
            {this.messengerButton()}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {this.renderHeaderRightButton()}
          </div>
        </div>
      )
    }
    return null
  }

  /**
   * @public
   * @description callback given to rightcontainer to keep track of small tab
   */
  onTabChangeCallback = (id) => {
    if (id.includes('s')) {
      this.setState({ smallTab: id })
    }
  }

  render() {
    const {
      selectedDevice, unselectDevice, wMatrix, locateNow,
    } = this.props
    const { smallTab } = this.state
    const tabs = {
      small: [{
        id: 's1',
        name: wMatrix('Map View'),
      }, {
        id: 's2',
        name: wMatrix('listView'),
      }],
      large: [{
        id: 'l1',
        name: wMatrix('Map View'),
      }, {
        id: 'l2',
        name: wMatrix('Timeline'),
      }],
    }

    return (
      <div style={styles.container}>
        <RightContainer
          headerMode={selectedDevice ? 'large' : 'small'}
          title={selectedDevice ? selectedDevice.alias : ''}
          onClose={unselectDevice}
          defaultTabKey={selectedDevice ? 'l1' : smallTab}
          customHeading={this.renderHeading(selectedDevice)}
          renderHeaderDetails={() => (
            <DeviceDetails device={selectedDevice} locateNow={locateNow} />
          )}
          onSettingsClick={this.showManager}
          tabs={tabs}
          onTabChange={this.onTabChangeCallback}
        >
          {this.renderLiveView()}
          {this.renderDeviceListTab()}
          {this.renderDeviceLiveView()}
          {this.renderTimelineView()}
        </RightContainer>
      </div>
    )
  }
}

export default helper()(DevicesContainer)
