import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Menu, Icon, Dropdown,
} from 'antd'

const styles = {
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    flexDirection: 'row',
  },
  expandItem: {
    display: 'flex',
    alignItems: 'center',
  },
}
/**
 * @TODO maybe update the width percentage used (.4) to something more accurate
 */
/**
 * @description This component creates a horizontal menu that adjusts the viewable items
 * based on its width. All menu items are shown if the width permits. If the width is too small
 * to display all items, the overflow items are placed in a dropdown menu. The selected item (if
 * width permits) will always be in view and either (1.) keep its placement order or (2.) be placed
 * last in the displayed items before the dropdown menu (if the selcted item would have otherwise
 * been pushed into the overflow dropdown)
 *
 * @Props
 * onSelect: {function(key)} onClick for menu items given a key string
 * selectedKey: {string} key of selected item
 * menuItems: {[{key, title}]} Array of objects that contain item key and title
 * overrideKeys: {Object} Object containing keys that coinside with menuItem keys
 *  and values that equal the designated remap Key.
 *    ie: {landmarks: 'map', distance: 'map'} means 'map' is the selectedKey
 *      for both 'landmarks' and 'distance' keys
 */
export default class DynamicMenu extends Component {
  menuItems = {}

  container = React.createRef()

  resizeObserver = null

  static propTypes = {
    onSelect: PropTypes.func.isRequired,
    selectedKey: PropTypes.string.isRequired,
    menuItems: PropTypes.array.isRequired,
    overrideKeys: PropTypes.object,
  }

  static defaultProps = {
    overrideKeys: null,
  }

  state = {
    overFlowVisible: false,
    totalWidthNeeded: 300,
    availableWidth: 300,
    itemInfo: [{ key: 'map', width: 20 }],
  }

  // set up itemInfo
  constructor(props) {
    super(props)
    // console.log('constructor running')
    const { menuItems, overrideKeys } = props
    /**
     * Override Keys are used to designate any menu items to exclude from
     * view (nav items that are sub nav paths)
     */
    let filteredItems = JSON.parse(JSON.stringify(menuItems))
    if (overrideKeys) {
      const excludeKeys = Object.keys(overrideKeys)
      // exclude items
      filteredItems = filteredItems.filter(item => !excludeKeys.includes(item.key))
    }
    // Make itemInfo an array of objects with keyname and width
    filteredItems = filteredItems.map(item => ({
      key: item.key,
      title: item.title,
      width: 10,
    }))

    this.state = {
      totalWidthNeeded: 300,
      availableWidth: 300,
      itemInfo: filteredItems,
    }
  }

  /**
   * After Mounting the first time, all menu items have been created with their needed widths. Here,
   *  we take those values and place them in state so we don't have to read the references anymore.
   *  We also store the total width needed in state to quickly weed out cases.
   */
  componentDidMount = () => {
    const { itemInfo } = this.state
    let totalWidthNeeded = 0
    // This gives us the width necesessary to show all menu items
    const entries = Object.entries(this.menuItems)
    const itemInfoClone = JSON.parse(JSON.stringify(itemInfo))
    for (const [key, value] of entries) {
      totalWidthNeeded += value.current.clientWidth
      // update widths
      for (let i = 0; i < itemInfoClone.length; i += 1) {
        if (itemInfoClone[i].key === key) {
          itemInfoClone[i].width = value.current.clientWidth
        }
      }
    }
    this.setState({
      totalWidthNeeded,
      itemInfo: itemInfoClone,
    })
    // create resizeObserver for container
    this.resizeObserver = new ResizeObserver((observerArray) => {
      if (observerArray && observerArray.length > 0) {
        const newWidth = observerArray[0].contentRect.width
        this.setState({ availableWidth: newWidth })
      }
    })
    this.resizeObserver.observe(this.container.current)
  }

  // Clean resizeObserver
  componentWillUnmount = () => {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
      this.resizeObserver = null
    }
  }

  /**
   * Returns the overflow menu used in the dropdown given an array of items
   * @param {[Objects]} items Array of menu items of form {key, title}
   */
  returnOverflowMenu = (items) => {
    const { onSelect } = this.props
    const itemsClone = JSON.parse(JSON.stringify(items))
    return (
      <Menu>
        {itemsClone.map(item => (
          <Menu.Item
            key={item.key}
            onClick={() => {
              onSelect(item.key)
              this.setState({ overFlowVisible: false })
            }}
          >
            {item.title}
          </Menu.Item>
        ))}
      </Menu>
    )
  }

  /**
   * Returns the Menu Item that contains the overflowed items in a dropdown menu
   * @param {[Object]} overflowItems Array of objects in format {key, title}
   */
  returnExpandItem = (overflowItems) => {
    const { totalWidthNeeded, availableWidth, overFlowVisible } = this.state

    const menu = this.returnOverflowMenu(overflowItems)
    if (availableWidth < totalWidthNeeded) {
      return (
        <div
          className="custom-menu-expand-item"
          style={{
            ...styles.expandItem,
            borderTop: overFlowVisible ? '2px solid white' : null,
          }}
        >
          <Dropdown
            overlay={menu}
            trigger={['click']}
            placement="bottomLeft"
            onVisibleChange={(visible) => { this.setState({ overFlowVisible: visible }) }}
          >
            <Icon type="ellipsis" style={{ fontSize: 28, color: 'white', margin: 0 }} />
          </Dropdown>
        </div>
      )
    }
    return null
  }

  /**
   * Returns the Menu Items displayed in the top bar
   * @param {string} key key for the menu item
   * @param {string} title title to be displayed for the menu item
   */
  returnCustomMenuItem = (key, title) => {
    const {
      selectedKey, onSelect, overrideKeys,
    } = this.props

    let selectedMenuItem = selectedKey
    if (overrideKeys && Object.keys(overrideKeys).includes(selectedMenuItem)) {
      selectedMenuItem = overrideKeys[selectedMenuItem]
    }
    const itemRef = React.createRef()
    this.menuItems[key] = itemRef
    return (
      <div
        id={`custom-menu-item-${key}`}
        key={key}
        ref={itemRef}
        className={selectedMenuItem === key ? 'custom-menu-item-selected' : 'custom-menu-item'}
        role="button"
        tabIndex={-1}
        onClick={() => onSelect(key)}
      >
        {title}
      </div>
    )
  }

  returnMenuArrays = () => {
    const { selectedKey } = this.props
    const { availableWidth, itemInfo, totalWidthNeeded } = this.state
    let menuItemArray = []
    let overflowKeys = []
    // Only run the following algorithm if some items dont fit
    if (totalWidthNeeded > availableWidth) {
      const keyWidths = {} // to be able to quickly refer to widths
      for (let i = 0; i < itemInfo.length; i += 1) {
        keyWidths[itemInfo[i].key] = itemInfo[i].width
      }
      // IF no items fit
      // 64 is to take into account expand item width with some extra padding
      if (keyWidths[selectedKey] > (availableWidth - 64)) {
        // push everything to overflow
        menuItemArray = []
        overflowKeys = JSON.parse(JSON.stringify(itemInfo))
      } else {
        /**
         * IF selected item fits, see what else fits. Push selected item to end of displayed,and
         * the rest to overflow
         */
        let widthLeft = availableWidth - 64 - keyWidths[selectedKey]
        let selectedPushed = false
        let selectedKeyTitle = ''
        for (let i = 0; i < itemInfo.length; i += 1) {
          const { key, title } = itemInfo[i]
          if (key === selectedKey && !selectedPushed) {
            selectedPushed = true
            menuItemArray.push(this.returnCustomMenuItem(key, title))
          } else if (keyWidths[key] <= widthLeft) {
            menuItemArray.push(this.returnCustomMenuItem(key, title))
          } else {
            // keep title variable so we dont need to search for it when we push to menu below
            if (key === selectedKey) {
              selectedKeyTitle = title
            }
            // Push to overflow menu
            overflowKeys.push({ key, title })
          }
          widthLeft -= keyWidths[key]
        }
        if (!selectedPushed) {
          // lastly push selected to end of displayed item array
          menuItemArray.push(this.returnCustomMenuItem(selectedKey, selectedKeyTitle))
        }
      }
    } else {
      // no need to reorder
      for (let i = 0; i < itemInfo.length; i += 1) {
        menuItemArray.push(this.returnCustomMenuItem(itemInfo[i].key, itemInfo[i].title))
      }
    }

    return {
      menu: menuItemArray,
      expand: overflowKeys,
    }
  }

  render() {
    const { menu, expand } = this.returnMenuArrays()

    return (
      <div
        ref={this.container}
        className="custom-menu"
        style={styles.wrapper}
      >
        <div style={{ display: 'flex', minWidth: 0 }}>
          {menu}
        </div>
        {this.returnExpandItem(expand)}
      </div>
    )
  }
}
