/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react'
import {
  Button, Icon, Select, Input, Form, Col,
} from 'antd'
import PropTypes from 'prop-types'

/**
 * @todo
 * - remove default devices from parent
 * - add required astericks to name
 * - have role options queried rather than static
 * - correct role/group logic if roles change or we get more roles
 */
const FormItem = Form.Item
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 8,
      offset: 18,
    },
  },
}

class NewDriver extends Component {
  formStyle = {
    marginTop: '10px',
    padding: '24px',
    background: '#fbfbfb',
    border: '1px solid #d9d9d9',
    borderRadius: '6px',
  }

  static propTypes = {
    groups: PropTypes.array,
    form: PropTypes.object.isRequired,
    showToggle: PropTypes.func.isRequired,
    showFob: PropTypes.bool,
    showWexCard: PropTypes.bool,
    onCreate: PropTypes.func.isRequired,
    wMatrix: PropTypes.func.isRequired,
    availableDeviceOptions: PropTypes.func.isRequired,
  }

  static defaultProps = {
    groups: [],
    showFob: false,
    showWexCard: false,
  }

  handleCancel = () => {
    const { form, showToggle } = this.props
    form.resetFields()
    showToggle()
  }

  handleSave = () => {
    const { form, onCreate, showToggle } = this.props
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onCreate(
          values.firstname,
          values.lastname,
          values.dotNumber,
          values.fobId,
          values.device,
          values.wexCard,
        )
        form.resetFields()
        showToggle()
      }
    })
  }

  // /**
  //  * @description creates option map based on available devices
  //  */
  // availableDevices = () => {
  //     let {availableDevices} = this.props
  //     let devicesClone = []
  //     //**CALL REFETCH OR CALL QUERY to update what devices are available* */
  //     // create copy to not overwrite original data
  //     for(var obj in availableDevices){
  //         let device = availableDevices[obj]
  //         devicesClone.push(device)
  //     }
  //     return (devicesClone.map((device, index) => {
  //         // TODO: maybe add group dots to the option display
  //         return (<Option value={device.id} key={device.id} >{device.alias}</Option>)
  //     }))
  // }


  render() {
    const {
      form, wMatrix, showFob, availableDeviceOptions, showWexCard,
    } = this.props
    const { getFieldDecorator } = form

    return (
      <Form onSubmit={null} style={this.formStyle}>
        <FormItem
          {...formItemLayout}
          label={wMatrix('name')}
          style={{ marginBottom: 0 }}
        >
          <Col span={11}>
            <FormItem>
              {getFieldDecorator('firstname', {
                rules: [{ required: true, message: wMatrix('This field is required') }],
              })(
                <Input placeholder={wMatrix('firstName')} />,
              )}

            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem>
              {getFieldDecorator('lastname', {
                rules: [{ required: true, message: wMatrix('This field is required') }],
              })(
                <Input placeholder={wMatrix('lastName')} />,
              )}
            </FormItem>
          </Col>
        </FormItem>

        <FormItem
          {...formItemLayout}
          label={wMatrix('dotNumber')}
        >
          {getFieldDecorator('dotNumber', {
            rules: [{ required: false, message: '' }],
          })(
            <Input placeholder="1223334444" />,
          )}
        </FormItem>

        {showFob ? (
          <FormItem
            {...formItemLayout}
            label={wMatrix('fobId')}
          >
            {getFieldDecorator('fobId', {
              rules: [{ required: false, message: '' }],
            })(
              <Input placeholder="1223334444" />,
            )}
          </FormItem>
        ) : null}

        {showWexCard ? (
          <FormItem
            {...formItemLayout}
            label={wMatrix('wexCard')}
          >
            {getFieldDecorator('wexCard', {
              rules: [
                { required: false, message: '' },
                {
                  validator: async (rule, value) => {
                    if (value === undefined || value === '' || value.length === 4) {
                      return Promise.resolve()
                    }
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject(wMatrix('invalidWexCardLength'))
                  },
                },
              ],
              validateTrigger: 'onSubmit',
            })(
              <Input placeholder="0001" />,
            )}
          </FormItem>
        ) : null}

        <FormItem
          {...formItemLayout}
          label={wMatrix('assignToDevice')}
        >
          {getFieldDecorator('device', {
            rules: [{ required: false, message: '' }],
            initialValue: 0,
          })(
            <Select
              showSearch
              labelFilterProp="label"
              filterOption={
                // eslint-disable-next-line max-len
                (input, option) => option.props.label.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
              }
            >
              {availableDeviceOptions()}
            </Select>,
          )}
        </FormItem>
        <FormItem {...tailFormItemLayout} style={{ marginBottom: 0 }}>
          <label className="cancel" onClick={this.handleCancel}>{wMatrix('Cancel')}</label>
          <Button type="primary" onClick={this.handleSave} style={{ marginLeft: '10px' }}>
            <Icon type="plus" />
            {wMatrix('Save')}
          </Button>
        </FormItem>
      </Form>

    )
  }
}

const NewDriverForm = Form.create({})(NewDriver)

export default NewDriverForm


// CreateUserForm = Form.create({})(CreateUserForm);
// const EnhancedUserForm =  Form.create()(CreateUserForm);
// <EnhancedUserForm wrappedComponentRef={(form) => this.form = form} />
// this.form // => The instance of CustomizedForm
