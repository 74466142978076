import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import SkeletonComponent from '@atom/skeletonComponents'
import TripsReport from '@mol/reports/tripsReport'
import MileageReport from '@mol/reports/mileageReport'
import EngineHoursReport from '@mol/reports/enginehoursReport'
import moment from 'moment'
import EventIcon from '@atom/eventIcon'

/**
 * @description - a component that creates a table given an outputblob. It also handles loading.
 * Custom column renders are done below in formatColumns.
 */
export default class ReportTable extends React.Component {
  static propTypes = {
    /** @helper (passed from parent) */
    wMatrix: PropTypes.func.isRequired,
    eventToString: PropTypes.func.isRequired,
    speedToString: PropTypes.func.isRequired,
    minutesToString: PropTypes.func.isRequired,
    /** @parent */
    generatedData: PropTypes.array.isRequired,
    selectedReport: PropTypes.object,
    reportLoading: PropTypes.bool,
  }

  static defaultProps = {
    selectedReport: null,
    reportLoading: false,
  }

  /**
   * @private
   * @description - This formats the table columns given the metadata
   */
  formatColumns = (outputBlob) => {
    const {
      wMatrix, speedToString, minutesToString, selectedReport,
    } = this.props
    if (!outputBlob) {
      return null
    }
    return outputBlob.map((column) => {
      if (column.type === 'string') {
        // assign string sorter
        Object.assign(column, {
          sorter: (a, b) => {
            if (column.dataIndex.indexOf('address.') > -1) {
              // the subkey can be 'street', 'city', 'state', 'zip'
              const subKey = column.dataIndex.split('.')[1]
              if (a.address && b.address && a.address[subKey] < b.address[subKey]) return -1
              if (a.address && b.address && a.address[subKey] > b.address[subKey]) return 1
              if (!a.address[subKey] && b.address[subKey]) return -1
              if (a.address[subKey] && !b.address[subKey]) return 1
              return 0
            }
            if (a[column.dataIndex] < b[column.dataIndex]) return -1
            if (a[column.dataIndex] > b[column.dataIndex]) return 1
            if (!a[column.dataIndex] && b[column.dataIndex]) return -1
            if (a[column.dataIndex] && !b[column.dataIndex]) return 1
            return 0
          },
          render: (text) => {
            if (column.dataIndex === 'duration') {
              if (selectedReport.type === 'engineHours') {
                return minutesToString(text, 365, true, 'hours')
              }
              return minutesToString(text, 365, true, 'days')
            }
            if (column.dataIndex === 'status') {
              return wMatrix(text)
            }
            return text
          },
        })
        if (column.dataIndex === 'event') {
          Object.assign(column, {
            render: (item) => {
              // if data is not string, its an object
              if (item) {
                return this.renderEventCell(item)
              }
              return null
            },
          })
        }
      } else if (column.type === 'number') {
        Object.assign(column, {
          sorter: (a, b) => a[column.dataIndex] - b[column.dataIndex],
          render: (value) => {
            let output = value
            if (column.dataIndex === 'speed' || column.dataIndex === 'mosl' || column.dataIndex === 'roadSpeed') {
              output = speedToString(value)
            } else if (column.dataIndex === 'rank') {
              output = value === 0 ? 'N/A' : value
            }
            return output
          },
        })
      } else if (column.type === 'date' || column.type === 'time') {
        Object.assign(column, {
          sorter: (a, b) => {
            if (a.datetimeUnix) {
              return a.datetimeUnix - b.datetimeUnix
            }
            if (a.dtStart) {
              // @NOTE Using moment to sort for rows > ~1000 makes pagination slow
              return new Date(a.dtStart) - new Date(b.dtStart)
            }
            return new Date(a.datetime) - new Date(b.datetime)
          },
          defaultSortOrder: 'ascend',
        })
        // not sure if this needs to be here..
        if (column.type === 'time') {
          Object.assign(column, {
            render: time => moment(time, 'HH:mm:ss a').format('h:mm a'),
          })
        }
      } else if (column.type === 'datetime') {
        Object.assign(column, {
          sorter: (a, b) => a.datetimeUnix - b.datetimeUnix,
          defaultSortOrder: 'ascend',
        })
      } else if (column.type === 'array') {
        if (column.dataIndex === 'landmarks') {
          Object.assign(column, {
            render: (text) => {
              let list = []
              if (typeof text === 'string') {
                try {
                  list = JSON.parse(text)
                } catch (err) {
                  list = []
                }
              } else {
                list = text
              }

              let listString = ''
              if (list && list !== undefined && list.length > 0) {
                list = list.map(landmark => landmark.name)
                for (let i = 0; i < list.length; i += 1) {
                  listString = `${listString}${i === 0 ? '' : ','} ${list[i].replace('"', '')}`
                }
              }
              return <div>{listString}</div>
            },
          })
        }
        if (column.dataIndex === 'drivers') {
          Object.assign(column, {
            render: (text) => {
              const list = (text && text !== undefined) ? JSON.parse(text) : null
              if (list && list !== undefined && list.length > 0) {
                return (
                  <div>
                    {list.map(driver => <div key={`${driver.id}`}>{driver.name}</div>)}
                  </div>
                )
              }
              return null
            },
          })
        }
      } else if (column.type === 'object') {
        if (column.dataIndex === 'address') {
          Object.assign(column, {
            render: (item) => {
              if (item) {
                return (
                  <div>
                    <div>{`${item.street}`}</div>
                    <div>{`${item.city || ''}${item.city ? ', ' : ''}${item.state || ''}`}</div>
                  </div>
                )
              }
              return null
            },
          })
        }
      }
      return column
    })
  }

  /**
   * @description - renders event table cell using event (in snake case)
   * @param {String} event event type in snake_case
   */
  renderEventCell = (event) => {
    const { eventToString } = this.props
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <EventIcon event={event} />
        <div style={{ margin: 10 }}>{eventToString(event)}</div>
      </div>
    )
  }

  /**
   * @private
   * @description - Renders speed for details column
   */
  renderSpeed = (details) => {
    const { wMatrix, speedToString } = this.props
    // speed
    const speedLabel = wMatrix('speed')
    let speedString = ''
    if (details.speed) {
      speedString = `${speedLabel}: ${speedToString(details.speed)}`
      if (details.roadspeed && details.roadspeed < details.speed) {
        const diff = details.speed - details.roadspeed
        speedString += ` (${speedToString(diff)} ${wMatrix('overTheLimit')})`
      }
    }
    return speedString.length > 0 ? (
      <div>{speedString}</div>
    ) : null
  }

  /**
   * @returns {[Object]}
   * @description - Returns table columns from props. If no columns, returns empty array
   */
  formatTableColumns() {
    const { selectedReport, wMatrix } = this.props
    let columns = []
    if (selectedReport !== null) {
      const { outputBlob } = selectedReport
      for (const blob of outputBlob) {
        blob.title = wMatrix(blob.title)
      }
      columns = this.formatColumns(outputBlob)
    }
    return columns
  }

  render() {
    const {
      selectedReport, reportLoading, generatedData,
    } = this.props

    const columns = this.formatTableColumns()

    if (selectedReport.type === 'trips') {
      return (
        <TripsReport
          tripVehicles={generatedData}
          columns={columns}
          loading={reportLoading}
        />
      )
    } if (reportLoading) {
      return (<SkeletonComponent type="table" />)
    } if (selectedReport.type === 'mileage') {
      return (
        <MileageReport
          generatedData={generatedData}
          columns={columns}
          reportLoading={reportLoading}
        />
      )
    } if (selectedReport.type === 'engineHours') {
      return (
        <EngineHoursReport
          generatedData={generatedData}
          columns={columns}
          reportLoading={reportLoading}
        />
      )
    }
    return (
      <Table
        className="listTableNoPagiArrow"
        style={{ marginTop: 16 }}
        columns={columns}
        dataSource={generatedData}
        pagination={{
          defaultPageSize: 50, pageSizeOptions: ['50', '100', '250', '500'], showSizeChanger: true,
        }}
      />
    )
  }
}
