import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Timeline from '@mol/timeline'
import FilterPage from '@mol/timelineFilterPage'
import Analytics from '@analytics'
import RightDrawer from './rightDrawer'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    flex: 1,
  },
  blueHeader: {
    minHeight: '50px',
    padding: '4px',
    display: 'flex',
    flexShrink: '0',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'white',
    backgroundColor: '#102A96',
  },
  daySummary: {
    display: 'flex',
    flexShrink: '0',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#EBEBEB',
    paddingLeft: '5px',
    paddingRight: '5px',
    borderColor: '#B2B2B2',
    borderWidth: '0px 0px 1px 0px',
    borderStyle: 'solid',
    boxShadow: ' 0px -2px 8px',
  },
  HOOHour: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    marginRight: '5px',
    flexShrink: '0',
  },
}

/**
 * @description A container of instance rightDrawer that holds the timeline.
 *
 * @Note
 *  - If basing your drawer component on this, note that you do not *NEED* to keep track of the
 * expanded state. This is a special case. This component uses it to dynamically render its contents
 */

export default class TimelineDrawer extends Component {
  state = {
    expanded: true,
    showFilterPage: false,
    // activeKey: '1',
    // openDate: false,
  }

  static propTypes = {
    show: PropTypes.bool,
    wMatrix: PropTypes.func.isRequired,
    eventToString: PropTypes.func.isRequired,
    setSortOrder: PropTypes.func.isRequired,
    applyFilters: PropTypes.func.isRequired,
    toggleEvents: PropTypes.func.isRequired,
    toggleLocates: PropTypes.func.isRequired,
    availableEventFilters: PropTypes.array.isRequired,
    selectedEventTypes: PropTypes.array.isRequired,
    showLocates: PropTypes.bool.isRequired,
    showEvents: PropTypes.bool.isRequired,
    sortOrder: PropTypes.string.isRequired,
    selectedDevice: PropTypes.object,
    historyTrips: PropTypes.array,
    getTrips: PropTypes.func.isRequired,
    selectEvent: PropTypes.func.isRequired,
    selectedTrip: PropTypes.number,
    selectedEventID: PropTypes.number,
    date: PropTypes.string.isRequired,
    hoursOfOperation: PropTypes.object,
    redTripsLoading: PropTypes.bool,
    changeTripDate: PropTypes.func.isRequired,
    setSelectedTrip: PropTypes.func.isRequired,
    selectTab: PropTypes.func,
    requestCameraClipUpload: PropTypes.func.isRequired,
    selectModal: PropTypes.func.isRequired,
    /** @Main */
    mainNav: PropTypes.string,
  }

  static defaultProps = {
    show: true,
    historyTrips: null,
    hoursOfOperation: null,
    redTripsLoading: false,
    selectedDevice: null,
    selectedTrip: null,
    selectedEventID: null,
    selectTab: null,
    mainNav: 'map',
  }

  expandGet = (expanded) => {
    this.setState({ expanded })
  }

  onSaveFilter = (sortOrder, selectedEvents) => {
    const { setSortOrder, applyFilters } = this.props
    setSortOrder(sortOrder)
    applyFilters(selectedEvents)
    // handle save event
    this.setState({ showFilterPage: false })
  }

  onFilterClick = () => {
    const { mainNav } = this.props
    // Open Filter Page
    this.setState({ showFilterPage: true })
    /** @analytics Record timeline filter click */
    Analytics.record({
      feature: 'timeline',
      page: `${mainNav}`,
      event: 'opened_filters',
    })
  }

  returnFilterPage = () => {
    const { showFilterPage, expanded } = this.state
    const {
      wMatrix, eventToString,
      availableEventFilters, showLocates, showEvents, sortOrder,
      toggleLocates, toggleEvents, selectedEventTypes, date, changeTripDate,
      selectedDevice,
    } = this.props
    return (
      <FilterPage
        wMatrix={wMatrix}
        timelineRange={selectedDevice.timelineRange}
        availableEventFilters={availableEventFilters}
        showLocates={showLocates}
        showEvents={showEvents}
        sortOrder={sortOrder}
        toggleLocates={toggleLocates}
        toggleEvents={toggleEvents}
        selectedEventTypes={selectedEventTypes}
        date={date}
        expanded={expanded} /** @TODO add minimized state for filter page? */
        changeTripDate={changeTripDate}
        show={showFilterPage}
        onSave={this.onSaveFilter}
        eventToString={eventToString}
      />
    )
  }

  returnTripsTab = () => {
    const { expanded, showFilterPage } = this.state
    const {
      wMatrix, eventToString,
      selectedDevice, historyTrips, getTrips, date, hoursOfOperation,
      changeTripDate, setSelectedTrip, selectEvent,
      selectedTrip, selectedEventID, sortOrder, redTripsLoading, selectModal,
      requestCameraClipUpload,
    } = this.props
    return (
      <div key="2" style={styles.container}>
        {
          showFilterPage ? this.returnFilterPage() : (
            <Timeline
              wMatrix={wMatrix}
              eventToString={eventToString}
              redTripsLoading={redTripsLoading}
              sortOrder={sortOrder}
              expanded={expanded}
              selectedDevice={selectedDevice}
              historyTrips={historyTrips}
              getTrips={getTrips}
              date={date}
              hoursOfOperation={hoursOfOperation}
              changeTripDate={changeTripDate}
              setSelectedTrip={setSelectedTrip}
              selectedTrip={selectedTrip}
              selectedEventID={selectedEventID}
              selectEvent={selectEvent}
              toggleFilterPage={this.onFilterClick}
              selectModal={selectModal}
              requestCameraClipUpload={requestCameraClipUpload}
            />
          )
        }
      </div>
    )
  }

  /**
   * @NOTE
   * This should be replaced with a query that grabs all locates for the day,
   * instead of getting all trips and trip details
   * */
  returnAllDayTab = () => {
    const { expanded, showFilterPage } = this.state
    const {
      wMatrix, eventToString,
      selectedDevice, historyTrips, getTrips, date, hoursOfOperation,
      changeTripDate, setSelectedTrip, selectEvent,
      selectedTrip, selectedEventID, redTripsLoading,
      selectModal, requestCameraClipUpload,
    } = this.props
    return (
      <div key="1" style={styles.container}>
        {
          showFilterPage ? (this.returnFilterPage()) : (
            <Timeline
              redTripsLoading={redTripsLoading}
              expanded={expanded}
              selectedDevice={selectedDevice}
              wMatrix={wMatrix}
              tripsEnabled={false}
              historyTrips={historyTrips}
              getTrips={getTrips}
              date={date}
              hoursOfOperation={hoursOfOperation}
              changeTripDate={changeTripDate}
              setSelectedTrip={setSelectedTrip}
              selectedTrip={selectedTrip}
              selectedEventID={selectedEventID}
              selectEvent={selectEvent}
              toggleFilterPage={this.onFilterClick}
              eventToString={eventToString}
              selectModal={selectModal}
              requestCameraClipUpload={requestCameraClipUpload}
            />
          )
        }
      </div>
    )
  }

  /**
   * @NOTE
   * This should be replaced with a query that grabs all locates for the day,
   * instead of getting all trips and trip details
   * */
  returnLast30Tab = () => {
    const { expanded, showFilterPage } = this.state
    const {
      wMatrix, eventToString,
      selectedDevice, historyTrips, getTrips, date, hoursOfOperation,
      changeTripDate, setSelectedTrip, selectEvent,
      selectedTrip, selectedEventID, redTripsLoading,
    } = this.props
    return (
      <div key="3" style={styles.container}>
        {
          showFilterPage ? (this.returnFilterPage()) : (
            <Timeline
              redTripsLoading={redTripsLoading}
              expanded={expanded}
              selectedDevice={selectedDevice}
              wMatrix={wMatrix}
              tripsEnabled={false}
              historyTrips={historyTrips}
              getTrips={getTrips}
              date={date}
              hoursOfOperation={hoursOfOperation}
              changeTripDate={changeTripDate}
              setSelectedTrip={setSelectedTrip}
              selectedTrip={selectedTrip}
              selectedEventID={selectedEventID}
              selectEvent={selectEvent}
              toggleFilterPage={this.onFilterClick}
              eventToString={eventToString}
            />
          )
        }
      </div>
    )
  }

  render() {
    const { selectedDevice } = this.props
    let tabsArray = [
      { name: 'All Day', id: '1' },
      { name: 'Trips', id: '2' },
    ]

    if (selectedDevice && selectedDevice.timelineRange === 'thirty-history') {
      tabsArray = [{ name: 'Last 30 Days', id: '1' }]
    }

    const { show, selectTab } = this.props
    return (
      <RightDrawer
        show={show}
        tabs={tabsArray}
        getExpansion={this.expandGet}
        selectTab={selectTab}
      >
        { selectedDevice && selectedDevice.timelineRange === 'thirty-history'
          ? (this.returnLast30Tab())
          : ([this.returnAllDayTab(), this.returnTripsTab()])
        }
      </RightDrawer>
    )
  }
}

// changeTripDate={changeTripDate}
// changeSortOrder={changeSortOrder}
// tripLoading={tripLoading}
// tripDetailsLoading={tripDetailsLoading}
// tripPathLoading={tripPathLoading}
// ifCurrentTrip={ifCurrentTrip}
// hoursOfOperation={hoursOfOperation}
// availableEventFilters={availableEventFilters}
// sortOrder={sortOrder}
// selectedFilter={selectedFilter}
// changeFilter={changeFilter}
// eventCount={eventCount}
