import React, { Component } from 'react'
import {
  Button, Select, Input, Form, Col,
} from 'antd'
import PropTypes from 'prop-types'
import Tag from '@atom/tag'

/**
 * @todo
 * have role options queried rather than static
 * correct role/group logic if roles change or we get more roles
 */

const FormItem = Form.Item
const { Option } = Select

const styles = {
  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  },
  tailFormItemLayout: {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 8,
        offset: 18,
      },
    },
  },
  formStyle: {
    marginTop: '10px',
    padding: '24px',
    background: '#fbfbfb',
    border: '1px solid #d9d9d9',
    borderRadius: '6px',
  },
}

class NewUserForm extends Component {
  static propTypes = {
    groups: PropTypes.array,
    form: PropTypes.object.isRequired,
    showToggle: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    wMatrix: PropTypes.func.isRequired,
    getPermission: PropTypes.func.isRequired,
  }

  static defaultProps = {
    groups: [],
  }

  state = {
    disableGroups: false,
  }

  // Just clears the groups if Admin or Global
  handleRoleChange = (value) => {
    const groupIdStrings = []
    const { form } = this.props
    // NOTE: logic may need to change if more roles are created
    if (value === 1 || value === 2) {
      form.setFieldsValue({
        groups: groupIdStrings,
      })
      this.setState({ disableGroups: true })
    } else this.setState({ disableGroups: false })
  }

  handleCancel = () => {
    const { form, showToggle } = this.props
    form.resetFields()
    showToggle(false)
  }

  handleSave = () => {
    const { form, onCreate } = this.props
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onCreate(values.firstname,
          values.lastname,
          values.email,
          values.password,
          values.phone,
          values.permissions,
          values.groups).then((response) => {
          if (response) {
            form.resetFields()
          }
        })
      }
    })
  }

  render() {
    const {
      form, groups, wMatrix, getPermission,
    } = this.props
    const { disableGroups } = this.state
    const { getFieldDecorator } = form
    const children = []

    for (const group of groups) {
      children.push(
        <Option key={group.id}>
          <div style={{ display: 'flex' }}>
            <Tag
              id={group.id}
              name={group.name}
              color={group.color}
            />
          </div>
        </Option>,
      )
    }

    return (
      <Form onSubmit={null} style={styles.formStyle}>
        <FormItem
          {...styles.formItemLayout}
          label={wMatrix('email')}
        >
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email', message: wMatrix('Invalid email'),
              },
              {
                required: true, message: wMatrix('This field is required'),
              }],
          })(
            <Input type="email" />,
          )}
        </FormItem>
        <FormItem
          {...styles.formItemLayout}
          label={wMatrix('name')}
          style={{ marginBottom: 0 }}
        >
          <Col span={11}>
            <FormItem>
              {getFieldDecorator('firstname', {
                rules: [{ required: true, message: wMatrix('This field is required') }],
              })(
                <Input placeholder={wMatrix('firstName')} />,
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <FormItem>
              {getFieldDecorator('lastname', {
                rules: [{ required: true, message: wMatrix('This field is required') }],
              })(
                <Input placeholder={wMatrix('lastName')} />,
              )}
            </FormItem>
          </Col>
        </FormItem>
        <FormItem
          {...styles.formItemLayout}
          label={wMatrix('phone')}
        >
          {getFieldDecorator('phone', {
            rules: [{ required: false, message: '' }],
          })(
            <Input placeholder="1223334444" />,
          )}
        </FormItem>
        <FormItem
          {...styles.formItemLayout}
          label={wMatrix('Permissions/Role')}
        >
          {getFieldDecorator('permissions', {
            rules: [{ required: true, message: wMatrix('pleaseSelectPermissionLevel') }],
          })(
            <Select onChange={this.handleRoleChange}>
              {getPermission('Global', 'ynCreate') === true ? <Option value={1}>{wMatrix('global')}</Option> : null}
              <Option value={2}>{wMatrix('Admin')}</Option>
              <Option value={3}>{wMatrix('supervisor')}</Option>
              <Option value={6}>{wMatrix('serviceTech')}</Option>
              <Option value={4}>{wMatrix('readOnly')}</Option>
            </Select>,
          )}
        </FormItem>
        <FormItem
          {...styles.formItemLayout}
          label={wMatrix('Viewable Groups')}
        >
          {getFieldDecorator('groups', {
            rules: [{ required: false, message: '' }],
          })(
            <Select
              // if permissions is val 1 or 2:
              disabled={disableGroups}
              mode="multiple"
              style={{ width: '100%' }}
              placeholder={disableGroups ? wMatrix('globalAdminViewAll') : wMatrix('selectOptional')}
            >
              {children}
            </Select>,
          )}
        </FormItem>
        <FormItem
          {...styles.formItemLayout}
          label={wMatrix('Password')}
        >
          {getFieldDecorator('password', {
            rules: [{ required: true, message: wMatrix('This field is required') }],
          })(
            <Input type="password" placeholder="Password" />,
          )}
        </FormItem>

        <FormItem {...styles.tailFormItemLayout} style={{ marginBottom: 0 }}>
          <div tabIndex="-1" onClick={this.handleCancel} role="button">
            <label className="cancel">{wMatrix('Cancel')}</label>
          </div>
          <Button type="primary" onClick={this.handleSave} style={{ marginLeft: '10px' }}>
            {wMatrix('Save')}
          </Button>
        </FormItem>
      </Form>
    )
  }
}

const NewUserFormCreated = Form.create({})(NewUserForm)

export default NewUserFormCreated
