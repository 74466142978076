import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Select, Icon } from 'antd'
import Tag from '@atom/tag'
import helper from '@helper'

const { Option } = Select

class FilterDropDown extends Component {
    static propTypes = {
      handleFilterChange: PropTypes.func.isRequired,
      existingTagList: PropTypes.array,
      defaultValues: PropTypes.array,
      maxCount: PropTypes.number,
      wMatrix: PropTypes.func.isRequired,
      valueKey: PropTypes.string,
      placeholderColor: PropTypes.string,
      width: PropTypes.string,
    }

    static defaultProps = {
      existingTagList: [],
      defaultValues: [],
      maxCount: 1,
      valueKey: 'name',
      placeholderColor: '#BABABA',
      width: '220px',
    }

    /**
     * @private
     * Calls prop function handleFilterChange
     * @param {[number | string]} value - Array of tag ids or names, depending on what the valueKey
     * is set as in props
     */
    handleChange = (value) => {
      const { handleFilterChange } = this.props
      handleFilterChange(value)
    }

    /**
     * Formats the tag options and styles them as groups or labels accordingly
     * Note that the value used in the options is based on the valueKey prop. This is defaulted to
     * 'name' for tag.name as the option value
     */
    tagOptions = () => {
      const { existingTagList, valueKey } = this.props

      const allTagsClone = JSON.parse(JSON.stringify(existingTagList))

      return allTagsClone.map(tag => (
        <Option key={tag.id} value={tag[valueKey]} name={tag.name}>
          <div style={{ display: 'flex' }}>
            <Tag
              id={tag.id}
              name={tag.name}
              color={tag.color}
              personal={!tag.ynGroup}
            />
          </div>
        </Option>
      ))
    }

    render() {
      const {
        wMatrix, defaultValues, placeholderColor, width, maxCount,
      } = this.props
      const formattedTags = this.tagOptions()
      return (
        <Select
          mode="multiple"
          loading={formattedTags.length === 0 && defaultValues.length > 0}
          optionFilterProp="name"
          className="tag-filter-select"
          style={{ width }}
          dropdownMatchSelectWidth={false}
          placeholder={(
            <div style={{
              display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center',
            }}
            >
              <label style={{ color: placeholderColor, fontSize: '13px', padding: 0 }}>{wMatrix('filterGroupsAndLabels')}</label>
              <Icon type="filter" style={{ color: placeholderColor }} />
            </div>
          )}
          maxTagCount={maxCount}
          size="small"
          showSearch={false}
          onChange={this.handleChange}
          isRequired=""
          defaultValue={defaultValues}
        >
          {formattedTags}
        </Select>
      )
    }
}


export default helper()(FilterDropDown)
