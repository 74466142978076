import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Collapse, InputNumber, Button } from 'antd'
import equal from 'deep-equal'

const { Panel } = Collapse

/**
 * Deals with EventType 30 = Miles Over speed limit or "Excessive Speeding"
 * Pass props function
 * handleMilesOverSave = (value) => {
 *  this.setState({milesOverValue: value})
 * }
 */

export default class MilesOverDropDown extends Component {
  state = {
    MOSLInput: null,
  }

  static propTypes = {
    milesOverValue: PropTypes.number,
    alertSettings: PropTypes.object,
    eventTypeIds: PropTypes.array,
    updateUserMOSL: PropTypes.func,
    getPermission: PropTypes.func,
    editMode: PropTypes.bool,
    wMatrix: PropTypes.func.isRequired,
  }

  static defaultProps = {
    milesOverValue: 10,
    alertSettings: {},
    eventTypeIds: null,
    updateUserMOSL: null,
    getPermission: null,
    editMode: false,
  }

  state = {
    ifCollapse: true,
  }

  // rename of the componentWillMount since react 16.3
  UNSAFE_componentWillMount = () => {
    const { milesOverValue } = this.props
    this.setState({ MOSLInput: milesOverValue })
  }

  componentDidUpdate = (prevProps) => {
    const { alertSettings, milesOverValue } = this.props
    if (alertSettings[30]) {
      if (!equal(prevProps.milesOverValue, milesOverValue)) {
        this.setState({ MOSLInput: milesOverValue })
      }
    }
  }

  updateMOSLState = (value) => {
    this.setState({ MOSLInput: value })
  }

  saveMOSLValue = () => {
    const { milesOverValue, updateUserMOSL } = this.props
    const { MOSLInput } = this.state
    if (MOSLInput !== milesOverValue) { // make sure the value has changed
      updateUserMOSL(MOSLInput)
    }
    this.setState({ ifCollapse: true })
  }

  render() {
    const {
      milesOverValue, getPermission, eventTypeIds, editMode, wMatrix,
    } = this.props
    const { ifCollapse } = this.state
    const editable = getPermission('DeviceSettings', 'ynUpdate')
    // console.log(permissions)
    // console.log('caps', this.props.caps)
    // console.log('milesover: ', milesOverValue)
    // console.log('milesoverState: ', this.state.MOSLInput)
    // if(this.props.alertSettings[30]){
    //     console.log('milesOverFromAlertSettings[0]:', this.props.alertSettings[30][0].numMOSL)
    // }
    return (
      <div style={{ display: (eventTypeIds.includes(30) && editMode === false) ? 'block' : 'none', marginBottom: '50px' }}>
        <h3 style={{ marginTop: 12 }}>{wMatrix('milesOverPostedSpeed')}</h3>
        <Collapse
          className="collapse-custom-arrow"
          activeKey={ifCollapse ? '' : 'moslPanel'}
        >
          <Panel
            disabled={!editable}
            showArrow={false}
            key="moslPanel"
            header={(
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  whiteSpace: 'wrap',
                  alignItems: 'center',
                }}
              >
                <p style={{ marginBottom: 0, marginLeft: 15 }}>
                  {`${wMatrix('notifyMeMO1')} `}
                  {`${milesOverValue} `}
                  {wMatrix('notifyMeMO2')}
                </p>
                <div tabIndex="-1" onClick={() => this.setState({ ifCollapse: !ifCollapse })} role="button">
                  <label
                    style={{
                      margin: '0px 10px', color: '#0061DE', fontSize: '11pt', display: editable ? 'block' : 'none',
                    }}
                  >
                    {wMatrix('edit')}
                  </label>
                </div>
              </div>
            )}
          >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <span>
                {wMatrix('setYourNewValue')}
                :
                <InputNumber style={{ marginLeft: '10px' }} min={10} max={50} defaultValue={milesOverValue} onChange={this.updateMOSLState} />
              </span>
              <Button type="primary" onClick={this.saveMOSLValue}>{wMatrix('Save')}</Button>
            </div>
          </Panel>
        </Collapse>
      </div>
    )
  }
}
