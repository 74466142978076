import React, { Component } from 'react'
import PropTypes from 'prop-types'

const styles = {
  container: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
  },
}

export default class Banner extends Component {
    static propTypes = {
      view: PropTypes.object,
      backgroundColor: PropTypes.string,
    }

    static defaultProps = {
      view: null,
      backgroundColor: '#000000',
    }

    render() {
      const { view, backgroundColor } = this.props

      const style = {
        ...styles.container,
        backgroundColor,
      }

      return (
        <div style={style}>
          {view}
        </div>
      )
    }
}
