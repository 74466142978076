import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Tabs } from 'antd'
import GroupPanel from '@org/modal/groupPanel'
import UserPanel from '@org/modal/userPanel'
import DriverPanel from '@org/modal/driverPanel'
import PreferencePanel from '@org/modal/clientPreferencesPanel'

class AdminModal extends Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    modalUI: PropTypes.object,
    saveAdmin: PropTypes.func,
    closeModal: PropTypes.func.isRequired,
    deviceList: PropTypes.array,
    filterDeviceByTag: PropTypes.func.isRequired,
    filterDevice: PropTypes.func.isRequired,
    getPermission: PropTypes.func.isRequired,
    refetchDevices: PropTypes.func.isRequired,
    devices: PropTypes.array.isRequired,
    /** @DeviceListHOC */
    refetchDeviceListDrivers: PropTypes.func.isRequired,
  }

  static defaultProps = {
    modalUI: null,
    saveAdmin: null,
    deviceList: [],
  }

  state = {
    activeKey: '1',
  }

  switchAdminPanel = (activeKey) => {
    this.setState({ activeKey })
  }

  /**
   * @private
   *
   * Checks if user has access to groups and then renders
   */
  renderGroupsTab = () => {
    const { TabPane } = Tabs
    const {
      getPermission, refetchDevices, devices, wMatrix,
    } = this.props
    const { activeKey } = this.state

    if (getPermission('CompanyGroup', 'ynCreate')) {
      return (
        <TabPane tab={wMatrix('Groups')} key="1">
          <GroupPanel
            refetchDevices={refetchDevices}
            forceRender
            devices={devices}
            activeKey={activeKey}
            {...this.props}
          />
        </TabPane>
      )
    }
    return null
  }

  /**
   * @private
   *
   * Checks if user has access to groups and then renders
   */
  renderUsersTab = () => {
    const { TabPane } = Tabs
    const {
      getPermission, closeModal, wMatrix,
    } = this.props
    const { activeKey } = this.state

    if (getPermission('Contacts', 'ynRead')) {
      return (
        <TabPane tab={wMatrix('Users')} key="2">
          <UserPanel
            forceRender
            closeModal={closeModal}
            wMatrix={wMatrix}
            getPermission={getPermission}
            activeKey={activeKey}
          />
        </TabPane>
      )
    }
    return null
  }

  /**
   * @private
   *
   * Checks if user has access to groups and then renders
   */
  renderDriversTab = () => {
    const { TabPane } = Tabs
    const {
      getPermission, deviceList, wMatrix,
      refetchDeviceListDrivers, refetchDevices,
    } = this.props

    if (getPermission('DriverCreation', 'ynRead')) {
      return (
        <TabPane tab={wMatrix('Drivers')} key="3">
          <DriverPanel
            wMatrix={wMatrix}
            getPermission={getPermission}
            deviceList={deviceList}
            refetchDevices={refetchDevices}
            refetchDeviceListDrivers={refetchDeviceListDrivers}
          />
        </TabPane>
      )
    }
    return null
  }

  /**
   * @private
   *
   * Checks if user has access to groups and then renders
   */
  renderPreferencesTab = () => {
    const { TabPane } = Tabs
    const {
      getPermission, wMatrix,
    } = this.props

    if (getPermission('CompanyMOSL', 'ynRead')) {
      return (
        <TabPane tab={wMatrix('companyPreferences')} key="4">
          <PreferencePanel
            wMatrix={wMatrix}
            getPermission={getPermission}
          />
        </TabPane>
      )
    }
    return null
  }

  render() {
    const {
      wMatrix, modalUI, saveAdmin, closeModal,
    } = this.props

    return (
      <Modal
        visible={modalUI.ifShowModal}
        onOk={saveAdmin}
        onCancel={closeModal}
        footer={null}
        width="75%"
        zIndex={10}
        maskClosable={false}
      >
        <h1>{wMatrix('Admin')}</h1>
        <Tabs defaultActiveKey="1" onChange={this.switchAdminPanel}>
          {this.renderGroupsTab()}
          {this.renderUsersTab()}
          {this.renderDriversTab()}
          {this.renderPreferencesTab()}
        </Tabs>
      </Modal>
    )
  }
}

export default AdminModal
