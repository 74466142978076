import React, { Component } from 'react'
import { compose } from 'react-apollo'
import clone from 'clone'
import { getLandmarks } from '@graphql/query'
import queryConnector from '@graphql/queryConnector.js'
import consumerConnector from '@graphql/consumerConnector'
import mutationConnector from '@graphql/mutationConnector'
import {
  updateLandmark, createLandmark, deleteLandmark, uploadLandmarksMutation,
} from '@graphql/mutation'
import PropTypes from 'prop-types'

/**
 * @description This is the hoc used to fetch and manipulate landmark data. When modifying
 * this file, please be very descriptive by adding comments as to what you are adding.
 */
const landmarksHOC = () => (WrappedComponent) => {
  /**
   * @description What this should include
   * - all landmarks get
   * - edit landmark mutation
   * - new landmark post
   * - landmark delete
   */
  class LandmarksHOC extends Component {
    state = {
      // landmarks: [],
      selectedLandmark: null,
      filterStr: '',
      // filterTags: [],
      landmarkSortBy: 'name',
      ifNewLandmark: false,
      overlayType: 'CIRCLE',
      savingLandmark: false,
      deletingLandmark: false,
      landmarkCenter: null,
      uploadLandmarksLoading: false,
      uploadLandmarksAndDeleteLoading: false,
      landmarkImportAlert: {
        message: '',
        type: '',
        show: false,
      },
    }

    static propTypes = {
      landmarksQuery: PropTypes.object.isRequired,
      createLandmarkPost: PropTypes.func.isRequired,
      updateLandmarkPost: PropTypes.func.isRequired,
      deleteLandmarkPost: PropTypes.func.isRequired,
      wMatrix: PropTypes.func.isRequired,
      apolloClient: PropTypes.object.isRequired,
    }

    /**
   * Unmount and clear any timers
   */
    componentWillUnmount = () => {
      this.clearAlertTimer()
    }

    /**
     * Sets landmark upload alert and automatically closes after 5 seconds
     * @param {String} message Message to display
     * @param {String} type Alert type ['error', 'success', 'warning']
     */
    setLandmarkImportAlert = (message, type) => {
      this.setState({
        landmarkImportAlert: {
          message,
          type,
          show: true,
        },
      })
      this.alertTimer = setTimeout(() => {
        this.alertOnClose()
      }, 5000)
    }

    /**
     * Clears the alert timeout if it exists.
     */
    clearAlertTimer = () => {
      if (this.alertTimer) {
        clearTimeout(this.alertTimer)
        this.alertTimer = 0
      }
    }

    /**
     * Handle alert close. This gives the backend some time
     * to potentially import the landmarks, so we can then refetch to see if the changes were made.
     */
      alertOnClose = () => {
        const { landmarksQuery } = this.props
        // hide alert
        this.setState({ landmarkImportAlert: { show: false, type: 'success', message: '' } })
        // clear timer if it existed (to not run onclose twice)
        this.clearAlertTimer()
        // refetch landmarks
        landmarksQuery.refetch()
      }

    /**
     * @public
     *
     * set selectedLandmark state based on landmark ID
     */
    selectLandmark = (landmarkId) => {
      const { landmarksQuery } = this.props
      for (let i = 0; i < landmarksQuery.data.landmarks.length; i += 1) {
        if (landmarksQuery.data.landmarks[i].id === landmarkId) {
          const tempLandmark = clone(landmarksQuery.data.landmarks[i])
          if (tempLandmark.type === 'polygon') tempLandmark.points.pop()
          const landmarkCenter = this.findLandmarkCenter(landmarksQuery.data.landmarks[i])
          this.setState({
            selectedLandmark: tempLandmark,
            overlayType: landmarksQuery.data.landmarks[i].type.toUpperCase(),
            landmarkCenter,
          })
          return
        }
      }
      this.setState({ selectedLandmark: null })
    }

    /**
     * @public
     * unselectLandmark
     */
    unselectLandmark = () => {
      this.setState({ selectedLandmark: null })
    }

    /**
     * @public
     * Polygon Centroid formula for a non-self-intersecting closed polygon. Handles both
     * object points as well as google maps points (see below)
     * source: https://en.wikipedia.org/wiki/Centroid#Of_a_polygon
     * similar adaptation: https://stackoverflow.com/questions/9692448/how-can-you-find-the-centroid-of-a-concave-irregular-polygon-in-javascript
     * @param {[Objects]} points Array of points either in {lat, lng} format or in
     * google shape points format: {lat(), lng()}
     * @returns {Object} Coordinates object of form {lat, lng}
     *
     * Note - originally written inside the findLandmarkCenter function by
     * Brian Canlas (July 8th 2020), modified and separated by Gustavo Lopez (May 2021)
     */
    polygonCentroid = (polyPoints) => {
      const points = [...polyPoints]
      const first = points[0]
      const last = points[points.length - 1]
      // make sure first and last point are the same
      if (typeof first.lat === 'function') {
        if (first.lat() !== last.lat() || first.lng() !== last.lng()) {
          points.push(first)
        }
      } else if (first.lat !== last.lat || first.lng !== last.lng) {
        points.push(first)
      }
      // set up variables
      let x = 0
      let y = 0
      let f = 0
      let twicearea = 0
      let j = points.length - 1

      points.forEach((point1, i, arr) => {
        const point2 = arr[j]
        // if each point is a GoogleMaps API point, use the lat/lng functions
        if (typeof point1.lat === 'function') {
          f = point1.lat() * point2.lng() - point2.lat() * point1.lng()
          twicearea += f
          x += (point1.lat() + point2.lat()) * f
          y += (point1.lng() + point2.lng()) * f

          j = i
        } else {
          f = point1.lat * point2.lng - point2.lat * point1.lng
          twicearea += f
          x += (point1.lat + point2.lat) * f
          y += (point1.lng + point2.lng) * f

          j = i
        }
      })

      f = twicearea * 3

      return {
        lat: x / f,
        lng: y / f,
      }
    }

    /**
   * @public
   * @returns {Object} Object that contains lat and lng values
   * @description find the landmark center for any shape of the landmarks. (This is copied
   * from landmarksMap.js)
   * - NOTE: if this algorithm changes, make sure to update in
   *  appropriate places
   */
  // LINK - findLandmarkCenter() hoc
  findLandmarkCenter = (landmark) => {
    // console.log('finding landmark center: ', landmark)
    let center
    if (landmark.type === 'circle') {
      center = {
        lat: landmark.center[0].lat,
        lng: landmark.center[0].lng,
      }
    } else if (landmark.type === 'polygon') {
      center = this.polygonCentroid(landmark.points)
    } else {
      let sumLat = 0
      let sumLng = 0
      // temp
      let pointsLength = landmark.points.length
      // if last point is same as first, do not include it in calculation
      if (landmark.points[0].lat === landmark.points[pointsLength - 1].lat
        && landmark.points[0].lng === landmark.points[pointsLength - 1].lng) {
        pointsLength -= 1
      }
      for (let i = 0; i < pointsLength; i += 1) {
        sumLat += landmark.points[i].lat
        sumLng += landmark.points[i].lng
      }
      center = {
        lat: sumLat / (pointsLength),
        lng: sumLng / (pointsLength),
      }
      // for (let i = 0; i < landmark.points.length - 1; i += 1) {
      //   sumLat += landmark.points[i].lat
      //   sumLng += landmark.points[i].lng
      // }
      // center = {
      //   lat: sumLat / (landmark.points.length - 1),
      //   lng: sumLng / (landmark.points.length - 1),
      // }
    }
    return center
  }

    /**
     * @name createLandmark
     *
     * @param {String} type
     * @param {String} name
     * @param {Array[Object]} points - form [{"lat": 33.116937, "lng": -97.129439}]
     * @param {Number} radius
     * @param {Boolean} billable - default is false
     */
    createLandmark = (type, name, points, radius, billable, destinationAddress, notes) => {
      this.setState({ savingLandmark: true })
      const { createLandmarkPost, landmarksQuery } = this.props
      if (type === 'polygon') {
        points.push(points[0])
      }
      return createLandmarkPost({
        variables: {
          type,
          name,
          points: this.trimLatLng(points),
          radius: radius ? Math.round(radius * 1000) / 1000 : undefined,
          billable: billable || false,
          destinationAddress: destinationAddress || null,
          notes: notes || null,
        },
      }).then((response) => {
        // if response is success, refetch
        if (response.data && response.data.createLandmark.code === 1000) {
          landmarksQuery.refetch().then(() => {
            this.setState({ savingLandmark: false })
            this.setIfNewLandmark(false)
            this.unselectLandmark()
          })
          return 1000
        }
        this.setState({ savingLandmark: false })
        // console.log(response)
        return 4000
      }).catch(() => {
        this.setState({ savingLandmark: false })
        // console.log(err)
        return 4000
      })
    }

    /**
     * @name updateLandmark
     *
     * @param {Number} id
     * @param {String} type
     * @param {String} name
     * @param {Array[Object]} points - form [{"lat": 33.116937, "lng": -97.129439}]
     * @param {Number} radius
     * @param {Boolean} billable
     */
    updateLandmark = (id, type, name, points, radius, billable, destinationAddress, notes) => {
      this.setState({ savingLandmark: true })
      const { updateLandmarkPost, landmarksQuery } = this.props
      if (type === 'polygon') {
        // needs to be closed shape for db. Pass first point as last point as well.
        points.push(points[0])
      }
      updateLandmarkPost({
        variables: {
          id,
          type,
          name,
          points: this.trimLatLng(points),
          radius: radius ? Math.round(radius * 1000) / 1000 : undefined,
          billable,
          destinationAddress: destinationAddress || null,
          notes: notes || null,
        },
      }).then((response) => {
        // if response is success, refetch
        if (response.data && response.data.landmarkUpdate.code === 1000) {
          landmarksQuery.refetch().then(() => {
            this.setState({ savingLandmark: false })
            this.setIfNewLandmark(false)
            this.unselectLandmark()
          })
        }
      }).catch(() => {
        this.setState({ savingLandmark: false })
      })
    }

    /**
     * trim Latlng to 5 decimals
     * @private
     * @param {array} points point of lat and lng
     * @returns {array} trimmed points array
     */
    trimLatLng = (points) => {
      if (!points) {
        return points
      }
      const tempPoints = []
      for (let i = 0; i < points.length; i += 1) {
        tempPoints.push({
          lat: Math.round(points[i].lat * 100000) / 100000,
          lng: Math.round(points[i].lng * 100000) / 100000,
        })
      }
      return tempPoints
    }

    /**
     * @name deleteLandmark
     *
     * @param {Number} id
     */
    deleteLandmark = (landmarkId) => {
      this.setState({ deletingLandmark: true })
      const { deleteLandmarkPost, landmarksQuery } = this.props
      deleteLandmarkPost({ variables: { id: landmarkId } }).then((response) => {
        if (response.data && response.data.landmarkDelete.code === 1000) {
          landmarksQuery.refetch().then(() => {
            this.setState({ deletingLandmark: false })
            this.unselectLandmark()
          })
        } else {
          this.setState({ deletingLandmark: false })
        }
      }).catch(() => {
        this.setState({ deletingLandmark: false })
      })
    }

    /**
     * @public
     *
     * Sets the string filter
     *
     * @param {string} filterStr filter string
     */
    filterLandmark = (filterStr) => {
      this.setState({ filterStr })
    }

    /**
     * @public
     *
     * sort landmark
     *
     * @param {string} landmarkSortBy sort by
     */
    changeLandmarkSortBy = (landmarkSortBy) => {
      this.setState({ landmarkSortBy })
    }

    setIfNewLandmark = (ifNewLandmark) => {
      this.setState({ ifNewLandmark })
    }

    /**
     * @public
     * @param {String} overlayType, can be CIRCLE, POLYGON
     * map drawing functions
     */
    switchDrawingOverlay = (overlayType) => {
      this.setState({ overlayType })
    }

    /**
     * @private
     *
     * Applies the filter string and tags and returns
     * the specific landmarks
     *
     * @returns {array} landmarks
     */
    landmarks = () => {
      /**
       * @todo change landmarks to landmarks when GraphQL is updated
       */
      const { landmarksQuery } = this.props
      if (landmarksQuery && landmarksQuery.data && landmarksQuery.data) {
        const { data: { landmarks } } = landmarksQuery
        const { landmarkSortBy, filterStr } = this.state
        let returnLandmarks = []
        if (Array.isArray(landmarks)) {
          returnLandmarks = JSON.parse(JSON.stringify(landmarks))
          // For some reason, we remove the last set of points from polygons.
          /** @todo this should probably be done in gql level */
          for (let i = 0; i < returnLandmarks.length; i += 1) {
            if (returnLandmarks[i].type === 'polygon') {
              const tempLandmark = clone(returnLandmarks[i])
              tempLandmark.points.pop()
              returnLandmarks[i].points = tempLandmark.points
            }
          }
          // Filter
          if (filterStr && filterStr !== '') {
            returnLandmarks = returnLandmarks.filter((landmark) => {
              if (landmark.name.toLowerCase().indexOf(filterStr.toLowerCase()) >= 0
                || (landmark.destinationAddress && landmark.destinationAddress.toLowerCase()
                  .indexOf(filterStr.toLowerCase()) >= 0)
                || (landmark.notes && landmark.notes.toLowerCase()
                  .indexOf(filterStr.toLowerCase()) >= 0)) return true
              return false
            })
          }
          // Sort
          if (landmarkSortBy === 'type') {
            // sort by type
            const circles = []
            const rectangles = []
            const polys = []
            for (let i = 0; i < landmarks.length; i += 1) {
              switch (landmarks[i].type) {
                case 'circle':
                  circles.push(landmarks[i])
                  break
                case 'rectangle':
                  rectangles.push(landmarks[i])
                  break
                case 'polygon':
                  polys.push(landmarks[i])
                  break
                default:
                  polys.push(landmarks[i])
              }
            }
            returnLandmarks = circles.concat(rectangles).concat(polys)
          } else {
            // sort by name
            returnLandmarks = returnLandmarks.sort((a, b) => {
              if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
              if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
              return 0
            })
          }
        }
        return returnLandmarks
      }
      return []
    }

    updateSelectedLandmarkCenter = (coordinates) => {
      this.setState({ landmarkCenter: coordinates })
    }

    uploadLandmarks = async (file, deleteExisting) => {
      const { wMatrix } = this.props
      this.setState({
        uploadLandmarksLoading: !deleteExisting,
        uploadLandmarksAndDeleteLoading: deleteExisting,
      })
      try {
        const { apolloClient } = this.props

        const res = await apolloClient.mutate({
          mutation: uploadLandmarksMutation,
          variables: {
            file,
            deleteExisting: deleteExisting || false,
          },
        })
        // response may be an error code
        if (res.data?.uploadLandmarks?.code === 4000) {
          throw new Error(res.data?.uploadLandmarks?.description)
        }
        this.setLandmarkImportAlert(wMatrix('importSuccessfullyRequested'), 'success')
      } catch (err) {
        this.setLandmarkImportAlert(wMatrix('issueImportingLandmarks'), 'error')
      }
      this.setState({
        uploadLandmarksLoading: false,
        uploadLandmarksAndDeleteLoading: false,
      })
    }

    /**
     * Download landmark template from s3. This function should be attached to an onClick event.
     */
    downloadLandmarkTemplate = () => {
      window.location.href = 'https://landmark-bulk.s3.us-east-2.amazonaws.com/upload-template/landmark_upload_template.csv'
    }

    render() {
      const {
        selectedLandmark, landmarkSortBy, ifNewLandmark,
        overlayType, savingLandmark, deletingLandmark,
        filterStr, landmarkCenter, uploadLandmarksLoading, uploadLandmarksAndDeleteLoading,
        landmarkImportAlert,
      } = this.state
      const { landmarksQuery } = this.props
      return (
        <WrappedComponent
          landmarks={this.landmarks()}
          landmarksLoading={landmarksQuery.loading}
          landmarksRefetch={landmarksQuery.refetch}
          findLandmarkCenter={this.findLandmarkCenter}
          selectedLandmark={selectedLandmark}
          selectedLandmarkCenter={landmarkCenter}
          selectLandmark={this.selectLandmark}
          deleteLandmark={this.deleteLandmark}
          updateLandmark={this.updateLandmark}
          createLandmark={this.createLandmark}
          filterLandmark={this.filterLandmark}
          landmarkSortBy={landmarkSortBy}
          changeLandmarkSortBy={this.changeLandmarkSortBy}
          unselectLandmark={this.unselectLandmark}
          setIfNewLandmark={this.setIfNewLandmark}
          ifNewLandmark={ifNewLandmark}
          switchDrawingOverlay={this.switchDrawingOverlay}
          overlayType={overlayType}
          savingLandmark={savingLandmark}
          deletingLandmark={deletingLandmark}
          filterLandmarkStr={filterStr}
          updateSelectedLandmarkCenter={this.updateSelectedLandmarkCenter}
          polygonCentroid={this.polygonCentroid}
          uploadLandmarks={this.uploadLandmarks}
          downloadLandmarkTemplate={this.downloadLandmarkTemplate}
          uploadLandmarksLoading={uploadLandmarksLoading}
          uploadLandmarksAndDeleteLoading={uploadLandmarksAndDeleteLoading}
          landmarkImportAlert={landmarkImportAlert}
          landmarkImportAlertOnClose={this.alertOnClose}
          {...this.props}
        />
      )
    }
  }
  return compose(
    consumerConnector(),
    queryConnector(getLandmarks, {}, 'landmarksQuery'),
    mutationConnector(updateLandmark, 'updateLandmarkPost'),
    mutationConnector(createLandmark, 'createLandmarkPost'),
    mutationConnector(deleteLandmark, 'deleteLandmarkPost'),
  )(LandmarksHOC)
}

export default landmarksHOC
