import React, { Component } from 'react'
import { Icon } from 'antd'
import PropTypes from 'prop-types'

const styles = {
  animation: {
    transition: 'all 0.5s ease-out',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    flexWrap: 'no-wrap',
    margin: 2,
    flexShrink: '0',
    flexGrow: '0',
    height: 18, // adjust based on size?
    minWidth: 18,
    borderRadius: 9,

  },
  centerDot: {
    height: '10px',
    width: '10px',
    flexShrink: '0',
    borderRadius: '50%',
    backgroundColor: 'rgba(255,255,255,1)',
  },
  name: {
    flexShrink: '0',
    color: 'white',
    fontWeight: 'bold',
    margin: '0px 4px',
    maxWidth: '80px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}

/**
 * Tag that displays dot if group and title. Also displays edit icon if editable.
 * This component also includes an animation if you want to change the size. (small <-> large)
 */
export default class Tag extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    personal: PropTypes.bool,
    size: PropTypes.string, // small, large
    editable: PropTypes.bool,
    color: PropTypes.string,
    onClick: PropTypes.func,
    toggleable: PropTypes.bool,
    defaultSmall: PropTypes.bool,
  //   size: PropTypes.string,
  }

  static defaultProps = {
    size: 'large', //
    editable: false,
    color: 'blue',
    onClick: null,
    personal: false,
    toggleable: false,
    defaultSmall: false,
  }

  state = {
    stateSize: 'large',
  }

  componentDidMount = () => {
    const { defaultSmall } = this.props
    if (defaultSmall) {
      this.setState({ stateSize: 'small' })
    }
  }

  editClick = () => {
    const {
      onClick, id, name, color,
    } = this.props
    if (onClick) {
      onClick({
        id,
        name,
        color,
      })
    }
  }

  // Only called if not editable
  // toggleable disables onClick prop
  onClick = () => {
    const { stateSize } = this.state
    const {
      onClick, editable, toggleable,
    } = this.props
    if (toggleable) {
      if (stateSize === 'small') {
        this.setState({ stateSize: 'large' })
      } else {
        this.setState({ stateSize: 'small' })
      }
    } else if (onClick && !editable) {
      onClick()
    }
  }

  render() {
    const { stateSize } = this.state
    const {
      color, size, personal, name, editable, onClick, toggleable,
    } = this.props
    const sizeValue = toggleable ? stateSize : size
    return (
      <div
        style={{
          ...styles.container,
          ...styles.animation,
          justifyContent: sizeValue === 'small' ? 'center' : 'flex-start',
          backgroundColor: color,
          maxWidth: sizeValue === 'small' ? 18 : 150,
          padding: personal ? '0px 8px' : '0px 4px',
          cursor: (onClick && !editable) || size === 'toggleable' ? 'pointer' : 'default',
        }}
        onClick={editable ? null : () => this.onClick()}
        tabIndex="-1"
        role="button"
        title={name}
      >
        {!personal ? <div style={styles.centerDot}> </div> : null}
        {sizeValue !== 'small' ? <div style={styles.name}>{name}</div> : null}
        {editable && sizeValue !== 'small'
          ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: 'white',
                cursor: (editable) ? 'pointer' : 'default',
              }}
              onClick={editable ? () => this.editClick() : onClick}
              tabIndex="-1"
              role="button"
            >
              |
              <Icon type="edit" style={{ color: '#FFFFFF', flexShrink: '0', margin: '0px 4px' }} />
            </div>
          )
          : null}
      </div>
    )
  }
}
