import React, { Component } from 'react'
import PropTypes from 'prop-types'
import helper from '@helper'

const K_WIDTH = 30
const K_HEIGHT = 30

const styles = {
  animation: {
    transition: 'all 0.5s ease-out',
  },
  quickMenuBG: {
    width: K_WIDTH,
    borderRadius: (K_WIDTH / 2),
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    alignItems: 'stretch',
    left: -(K_WIDTH / 2),
  },
  menuItemsContainer: {
    position: 'absolute',
    left: -K_WIDTH,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}

class TripMarkerMenu extends Component {
  static propTypes = {
    open: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
  }

  static defaultProps = {
    open: false,
    children: null,
  }

  render() {
    const {
      open, children,
    } = this.props
    let itemNum = 2
    if (Array.isArray(children)) {
      itemNum = children.length + 1
    }
    // (icon height * items) + (margin * (items - 1))
    const menuHeight = open ? (K_HEIGHT * itemNum) + (3 * (itemNum - 1)) : K_HEIGHT
    const menuColor = open ? '#FFFFFFC0' : '#FFFFFF00'
    return (
      <div>
        <div style={{
          height: menuHeight,
          top: -(menuHeight - (K_HEIGHT / 2)),
          ...styles.animation,
          ...styles.menuContainer,
        }}
        >
          <div style={{
            backgroundColor: menuColor,
            boxShadow: open ? '0px 0px 5px grey' : undefined,
            ...styles.animation,
            ...styles.quickMenuBG,
          }}
          />
          <div style={{ position: 'relative' }}>
            <div style={{
              position: 'absolute', left: -K_WIDTH, paddingBottom: K_HEIGHT + 3, display: 'flex', flexDirection: 'column', justifyContent: 'center',
            }}
            >
              {open ? children : null}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default helper()(TripMarkerMenu)
