/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Alert, Button, Input, Checkbox, Modal, Timeline, Icon,
} from 'antd'
import ReCAPTCHA from 'react-google-recaptcha'
import Logo from '../../assets/img/logo.png'
import LoginHOC from './login.hoc'
import release from './releaseMsg'

class Login extends Component {
  state = {
    btnDisabled: false,
    inputClass: '',
    showForgotModal: false,
    forgotInput: '',
    forgotAlert: 'none',
  }

  static propTypes = {
    username: PropTypes.string.isRequired,
    changeUsername: PropTypes.func.isRequired,
    password: PropTypes.string.isRequired,
    changePassword: PropTypes.func.isRequired,
    checkRememberMe: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    forgot: PropTypes.func.isRequired,
    failedLoginAttempt: PropTypes.bool.isRequired,
    loginInfo: PropTypes.object,
    showReCaptcha: PropTypes.bool.isRequired,
    onReCaptchaSuccess: PropTypes.func.isRequired,
  }

  static defaultProps = {
    loginInfo: null,
  }

  showForgotModal = () => {
    const { showForgotModal } = this.state
    this.setState({ showForgotModal: !showForgotModal })
  }

  handleSubmit = (login) => {
    login().catch(() => {
      this.setState({ btnDisabled: true, inputClass: 'shake-input' })
      setTimeout(() => {
        this.setState({ btnDisabled: false, inputClass: '' })
      }, 500)
    })
  }

  handleForgotSubmit = () => {
    const { forgotInput } = this.state
    const { forgot } = this.props
    forgot(forgotInput).then(() => {
      this.setState({ forgotAlert: 'success', forgotInput: '' })
    }).catch(() => {
      this.setState({ forgotAlert: 'error' })
    })
  }

  renderFailedLogin = () => {
    const { failedLoginAttempt, loginInfo } = this.props
    if (failedLoginAttempt) {
      if (loginInfo
        && loginInfo.loginBcrypt
      ) {
        if (loginInfo.loginBcrypt.loginMessage && loginInfo.loginBcrypt.loginMessage.indexOf('Past Due') >= 0) {
          return (
            <Alert
              style={{ textAlign: 'center' }}
              type="error"
              message="Your account is past due, please contact us to avoid service interruption and reactivation fees"
            />
          )
        } if (loginInfo.loginBcrypt.description.indexOf('GPSI Account') >= 0 || loginInfo.loginBcrypt.resultCode === 5) {
          return (
            <Alert
              style={{ textAlign: 'center' }}
              type="error"
              message={(
                <p>
                  <div>You have been upgraded to GPS Insight Fleet Management.</div>

                  <span>
                    Please login to to your
                    <a href="https://portal.gpsinsight.com"> Fleet Management </a>
                    account.
                  </span>
                </p>
              )}
            />
          )
        }
      }
      return (
        <Alert
          style={{ textAlign: 'center' }}
          type="error"
          message="Incorrect username or password"
        />
      )
    }
    return null
  }

  renderForgotAlert() {
    const { forgotAlert } = this.state
    let alert = null
    if (forgotAlert === 'success') {
      alert = (
        <Alert
          style={{ textAlign: 'center' }}
          type="success"
          message="Check your inbox for further instructions"
        />
      )
    } else if (forgotAlert === 'error') {
      alert = (
        <Alert
          style={{ textAlign: 'center' }}
          type="error"
          message="Something went wrong, please contact our customer support team"
        />
      )
    }

    return alert
  }

  renderForgotModal() {
    const { showForgotModal, forgotInput } = this.state
    return (
      <Modal
        visible={showForgotModal}
        onCancel={this.showForgotModal}
        footer={null}
        width={500}
        zIndex={100}
        maskClosable={false}
      >
        <br />
        <h3>Forgot Password?</h3>
        <hr />
        <br />
        <p>
          {`
            Enter the email address associated with your Rhino account and you will receive an
            email with instructions on how to create a new password
          `}
        </p>
        <Input
          prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="email"
          placeholder="Email"
          onChange={e => this.setState({ forgotInput: e.target.value })}
          value={forgotInput}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '13px',
            marginBottom: '13px',
          }}
        >
          <Button
            disabled={forgotInput === ''}
            type="primary"
            htmlType="submit"
            className="login-form-button"
            onClick={this.handleForgotSubmit}
          >
            Submit
          </Button>
        </div>
        {this.renderForgotAlert()}
      </Modal>
    )
  }


  render() {
    const {
      username, changeUsername, password, changePassword,
      checkRememberMe, login, showReCaptcha, onReCaptchaSuccess,
    } = this.props

    const { btnDisabled, inputClass } = this.state

    return (
      <div className="isoSignInPage">
        {this.renderForgotModal()}
        <div className="isoLoginContent">
          <div className="isoLogoWrapper">
            <label><img src={Logo} className="logo" alt="logo" /></label>
          </div>
          <div style={{ paddingBottom: 10 }}>
            {this.renderFailedLogin()}
          </div>
          <div className="isoSignInForm">
            <div className="isoInputWrapper">
              <Input
                className={inputClass}
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Username"
                defaultValue={username}
                onChange={changeUsername}
                type="email"
              />
            </div>
            <div className="isoInputWrapper">
              <Input
                className={inputClass}
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Password"
                defaultValue={password}
                onChange={changePassword}
                onPressEnter={() => this.handleSubmit(login)}
              />
            </div>
            <div className="isoInputWrapper isoLeftRightComponent">
              <Checkbox onChange={checkRememberMe}>Remember Me</Checkbox>
              <p
                style={{ color: 'rgb(110, 163, 255)', cursor: 'pointer' }}
                onClick={this.showForgotModal}
              >
                Forgot Password
              </p>
            </div>

          </div>
          {
            showReCaptcha
              ? (
                <ReCAPTCHA
                  sitekey="6Ld7sd4ZAAAAAJBaf8tcpx4DcXQidUjVHUw7dnLH"
                  onChange={onReCaptchaSuccess}
                  style={{ margin: '10px auto' }}
                />
              )
              : (
                <div className="isoCenterComponent">
                  <Button
                    disabled={btnDisabled || showReCaptcha}
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    onClick={() => this.handleSubmit(login)}
                  >
                    Sign In
                  </Button>
                </div>
              )
          }
          <div className="features">
            <h3>
              Current version:
              {' '}
              {release.version}
            </h3>
            <h3 style={{ paddingBottom: '10px' }}>{'What\'s new?'}</h3>
            <Timeline>
              {release.msgs.map(msg => (
                <Timeline.Item color={msg.color} key={msg.msg + msg.date}>
                  {msg.date}
                  {' - '}
                  {msg.msg}
                </Timeline.Item>
              ))}
            </Timeline>
          </div>
        </div>
      </div>
    )
  }
}

export default LoginHOC()(Login)
