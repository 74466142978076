import React from 'react'
import PropTypes from 'prop-types'
import { Button, Select } from 'antd'
import Card from '@atom/card'
import Skeleton from '@atom/skeleton'
import DSCTable from '@atom/driver-scorecard/dscTable'
import helper from '@helper'

const { Option } = Select

class DriverStandingsDetails extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    getDSCData: PropTypes.func.isRequired,
    DSCDateRangeOptions: PropTypes.array.isRequired,
    multipleWeeks: PropTypes.bool.isRequired,
    setDSCNav: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    drivers: PropTypes.array,
  }

  static defaultProps = {
    loading: false,
    drivers: [],
  }

  /**
   * On Click for Dashboard Button
   */
  onDashClick = () => {
    const { setDSCNav, getDSCData } = this.props
    // set data back to one week
    getDSCData('current_week')
    // navigate back to dashboard
    setDSCNav('dashboard')
  }

  /**
   * renders dashboard button
   */
  renderPostTitle = () => {
    const { wMatrix } = this.props
    return (
      <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
        <Button
          size="small"
          style={{ marginRight: 8 }}
          type="primary"
          onClick={() => this.onDashClick()}
        >
          {wMatrix('dashboard')}
        </Button>
      </div>
    )
  }

  /**
   * Renders total drivers text
   */
  renderTotalDrivers = () => {
    const { wMatrix, drivers, loading } = this.props
    return (
      <div style={{
        marginLeft: 20, alignSelf: 'flex-start', display: 'flex', alignItems: 'center',
      }}
      >
        <h3 style={{ marginRight: '1ch', marginBottom: 0 }}>
          {`${wMatrix('totalDrivers')}: `}
        </h3>
        {!loading && drivers
          ? <h3 style={{ marginBottom: 0 }}>{drivers.length}</h3>
          : <Skeleton wrapperStyle={{ width: '4ch', height: '1em' }} />
        }
      </div>
    )
  }

  renderTimeSelection = () => {
    const {
      wMatrix, getDSCData, loading, DSCDateRangeOptions,
    } = this.props
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <h3 style={{ marginLeft: 20 }}>{`${wMatrix('timeRange')}:`}</h3>
        <Select
          defaultValue="current_week"
          style={{ marginLeft: 15, width: 106 }}
          onChange={getDSCData}
          disabled={loading}
        >
          {DSCDateRangeOptions ? DSCDateRangeOptions.map(option => (
            <Option value={option.key} key={option.key}>{`${wMatrix(option.title)}`}</Option>
          )) : null}
        </Select>
      </div>
    )
  }

  render() {
    const {
      wMatrix, drivers, loading, multipleWeeks,
    } = this.props
    return (
      <Card
        title={wMatrix('standings')}
        postTitle={this.renderPostTitle()}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
        >
          {this.renderTotalDrivers()}
          {this.renderTimeSelection()}
          <DSCTable
            wMatrix={wMatrix}
            drivers={drivers}
            multipleWeeks={multipleWeeks}
            loading={loading}
            view="details"
          />
        </div>
      </Card>
    )
  }
}

export default helper()(DriverStandingsDetails)
