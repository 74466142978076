import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from 'antd'
import Card from '@atom/card'

export default class FullScreenNav extends React.Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    nav: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    handleDashboard: PropTypes.func,
    handleShowCreate: PropTypes.func,
  }

  static defaultProps = {
    handleDashboard: null,
    handleShowCreate: null,
    loading: false,
  }

  render() {
    const {
      wMatrix, nav, handleDashboard, handleShowCreate, loading,
    } = this.props
    const displayQuickCreate = nav === 'details' ? 'block' : 'none'
    return (
      <Card title={null} contentWrapper={{ margin: 0 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {handleShowCreate ? (
              <Button
                style={{ display: displayQuickCreate }}
                type="primary"
                onClick={handleShowCreate}
                loading={loading}
              >
                {wMatrix('quickCreate')}
              </Button>
            ) : null}
          </div>
          <div>
            {handleDashboard ? (
              <Button type="primary" onClick={handleDashboard}>
                <Icon type="dashboard" />
                {wMatrix('dashboard')}
              </Button>
            ) : null}
          </div>
        </div>
      </Card>
    )
  }
}
