/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { Component } from 'react'
import {
  Button, Select, Form, DatePicker, TimePicker,
} from 'antd'
import PropTypes from 'prop-types'

const FormItem = Form.Item
const { Option } = Select

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 8,
      offset: 18,
    },
  },
}

class RequestClipForm extends Component {
  formStyle = {
    marginTop: '10px',
    padding: '24px',
    background: '#fbfbfb',
    border: '1px solid #d9d9d9',
    borderRadius: '6px',
  }

  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    minutesToStringV2: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    requestCustomCameraClipUpload: PropTypes.func.isRequired,
    cameraDevices: PropTypes.array.isRequired,
  }

  static defaultProps = {

  }

  state = {
    showForm: false,
  }


  /**
   * Handle cancel form
   */
  onCancel = () => {
    const { form } = this.props
    form.resetFields()
    this.setState({ showForm: false })
  }

  /**
   * Handle upload clip
   */
  onSave = () => {
    const {
      form, requestCustomCameraClipUpload,
    } = this.props
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const {
          deviceId, startDate, startTime, duration,
        } = values
        const startDatetime = `${startDate.format('YYYY-MM-DD')} ${startTime.format('HH:mm:ss.SSS')}`
        requestCustomCameraClipUpload(
          deviceId,
          startDatetime,
          duration,
        )
        form.resetFields()
        this.setState({ showForm: false })
      }
    })
  }

  /**
   * Returns array of select options for devices
   */
  deviceOptions = () => {
    const { cameraDevices } = this.props
    const devicesClone = JSON.parse(JSON.stringify(cameraDevices))
    return devicesClone.map(device => (
      <Option key={device.id} value={device.id}>
        {device.alias}
      </Option>
    ))
  }

  /**
   * Calculates time left based on clip duration and remaining camera data
   */

  timeRemaining = () => {
    const { form, cameraDevices } = this.props
    const { getFieldValue } = form

    const selectedDevice = getFieldValue('deviceId')
    const duration = getFieldValue('duration')
    let timeLeft = 0
    for (let i = 0; i < cameraDevices.length; i += 1) {
      if (cameraDevices[i].id === selectedDevice) {
        const { minutes } = cameraDevices[i].camera.data.remaining
        if (minutes) {
          if (duration) {
            timeLeft = minutes - (duration / 60)
          } else {
            timeLeft = minutes
          }
        }
        break
      }
    }
    return timeLeft
  }

  /**
   * Handles validation/ error checking for time/data left
   * @param {*} rule
   * @param {*} value
   * @param {Function} callback callback function for validator
   */
  validateTimeRemaining = (rule, value, callback) => {
    const minutesLeft = this.timeRemaining()

    // TODO: Handle minutesLeft for cameras that do not have data usage info (i.e. SureCam)
    try {
      if (minutesLeft < 0) {
        throw new Error('Not enough data. Choose a smaller duration.')
      }
      callback()
    } catch (err) {
      callback(err)
    }
  }

  /**
   * Renders Form component
   */
  renderForm = () => {
    const {
      form, wMatrix, minutesToStringV2,
    } = this.props
    const { getFieldDecorator } = form

    const minutesLeft = this.timeRemaining()
    return (
      <Form
        onSubmit={null}
      >
        <FormItem
          {...formItemLayout}
          label={wMatrix('timeRemaining')}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('timeRemaining', {
            rules: [{ validator: this.validateTimeRemaining }],
          })(
            <span style={{ color: minutesLeft < 0 ? '#f5222d' : undefined }}>
              {minutesToStringV2(minutesLeft)}
            </span>,
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label={wMatrix('Device')}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('deviceId', {
            rules: [{ required: true, message: wMatrix('This field is required') }],
          })(
            <Select
              showSearch
              labelFilterProp="label"
              filterOption={
                // eslint-disable-next-line max-len
                (input, option) => option.props.children.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
              }
            >
              {this.deviceOptions()}
            </Select>,
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label={wMatrix('clipStart')}
          style={{ marginBottom: 0 }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <FormItem style={{ marginBottom: 0 }}>
              {getFieldDecorator('startDate', {
                rules: [{ required: true, message: wMatrix('This field is required') }],
              })(
                <DatePicker
                  format="YYYY-MM-DD"
                />,
              )}
            </FormItem>
            <FormItem style={{ marginBottom: 0, marginLeft: 15 }}>
              {getFieldDecorator('startTime', {
                rules: [{ required: true, message: wMatrix('This field is required') }],
              })(
                <TimePicker
                  // defaultOpenValue={moment().format('h:mm:ss a')}
                  use12Hours
                  format="hh:mm:ss a"
                />,
              )}
            </FormItem>
          </div>
        </FormItem>
        <FormItem
          {...formItemLayout}
          label={wMatrix('Duration')}
          style={{ marginBottom: 0 }}
        >
          {getFieldDecorator('duration', {
            rules: [{ required: true, message: wMatrix('This field is required') }],
          })(
            <Select>
              <Option key="30" value={30}>
                30 sec
              </Option>
              <Option key="60" value={60}>
                1 min
              </Option>
              <Option key="90" value={90}>
                1 min 30 sec
              </Option>
              <Option key="120" value={120}>
                2 min
              </Option>
            </Select>,
          )}
        </FormItem>
        <FormItem {...tailFormItemLayout} style={{ marginBottom: 0 }}>
          <label role="button" tabIndex="-1" className="cancel" onClick={this.onCancel}>{wMatrix('Cancel')}</label>
          <Button type="primary" onClick={() => this.onSave(minutesLeft)} style={{ marginLeft: '10px' }}>
            {wMatrix('upload')}
          </Button>
        </FormItem>
      </Form>

    )
  }


  render() {
    const { wMatrix } = this.props
    const { showForm } = this.state
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          style={{
            alignSelf: 'flex-end',
          }}
          disabled={showForm}
          onClick={() => { this.setState({ showForm: true }) }}
        >
          {wMatrix('requestClip')}
        </Button>
        <div
          style={{
            width: '100%',
            // display: showForm ? 'block' : 'none',
            height: showForm ? 300 : 0,
            transition: 'all 0.5s ease-out',
            overflow: 'hidden',
            marginTop: '10px',
            padding: showForm ? '24px' : '0px',
            background: '#fbfbfb',
            border: showForm ? '1px solid #d9d9d9' : '0px solid #d9d9d9',
            borderRadius: '6px',
          }}
        >
          {this.renderForm()}
        </div>
      </div>
    )
  }
}

// eslint-disable-next-line no-class-assign
RequestClipForm = Form.create({})(RequestClipForm)

export default RequestClipForm
