import React, { Component } from 'react'
import {
  BrowserRouter as Router, Route, Switch,
} from 'react-router-dom'
import './style/styles.less'
import fontawesome from '@fortawesome/fontawesome'
import ffs from '@fortawesome/fontawesome-free-solid'
import Analytics from '@analytics'
import Main from './pages/main/main'
import Login from './pages/login/login'
import Forgot from './pages/forgot/forgot'

window.onbeforeunload = () => {
  Analytics.record({
    feature: 'app',
    page: 'app',
    event: 'closed_app',
  })
}

window.addEventListener('beforeunload', () => {
  Analytics.record({
    feature: 'app',
    page: 'app',
    event: 'closed_app',
  })
}, false)

const styles = {
  appWrapper: {
    transition: 'all 0.05s ease-out',
    display: 'flex',
    flexDirection: 'column',
  },
}

export default class App extends Component {
  state = {
    windowHeight: window.innerHeight,
    navPath: 'map',
  }

  /**
   * @description Creates event listener for window resizing
   */
  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize)
    // Record when app is opened (note a refresh counts as opening the app)
    Analytics.record({
      feature: 'app',
      page: 'app',
      event: 'opened_app',
    })
  }

  /**
   * @description Cleanly clears timer. Removes event listener for resizing
   */
  componentWillUnmount = () => {
    this.clearResizeTimer()
    window.removeEventListener('resize', this.handleResize)
  }

  /**
   * @private
   * @description handles height resizing and adds debounce to make sure to get final height
   * from a resize event
   */
  handleResize = () => {
    if (this.resizeTimer) {
      return
    }
    this.resizeTimer = setTimeout(() => {
      this.setState({ windowHeight: window.innerHeight })
      this.resizeTimer = 0
    }, 50)
  }

  /**
   * @private
   * @description Used to cleanly clear resize timer
   */
  clearResizeTimer = () => {
    // If timer is running, clear
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer)
      this.resizeTimer = 0
    }
  }

  setNavPath = (navPath) => {
    this.setState({ navPath })
  }

  routeGroups = () => {
    const { windowHeight, navPath } = this.state
    return (
      <Route
        path={[
          '/map', '/distance', '/landmarks', '/driver-scorecard', '/reports',
          '/service', '/video-clips', '/field-service',
        ]}
        render={props => (
          <Main
            {...props}
            navPath={navPath}
            setNavPath={this.setNavPath}
            appHeight={windowHeight}
          />
        )}
      />
    )
  }

  render() {
    const { windowHeight } = this.state
    fontawesome.library.add(ffs)
    return (
      <Router>
        <div
          className="App"
          style={{ ...styles.appWrapper, height: windowHeight }}
        >
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/login" component={Login} />
            {this.routeGroups()}
            <Route exact path="/forgot" component={Forgot} />
            <Route path="*" exact component={Login} />
          </Switch>
        </div>
      </Router>
    )
  }
}
