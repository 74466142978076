import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'react-apollo'
import consumerConnector from '@graphql/consumerConnector'
import queryConnector from '@graphql/queryConnector'
import { RelativeDSCQuery, reportScheduleDateRanges } from '@graphql/query'
import helper from '@helper'

const driverScorecardHOC = () => (WrappedComponent) => {
  class DriverScorecardHOC extends React.Component {
    state = {
      range: 'current_week',
    }

    static propTypes = {
      apolloClient: PropTypes.object.isRequired,
      /** @helper */
      wMatrix: PropTypes.func.isRequired,
      interpolateColors: PropTypes.func.isRequired,
      DSCData: PropTypes.object,
      dateRangeOptions: PropTypes.object,
    }

    static defaultProps = {
      DSCData: null,
      dateRangeOptions: null,
    }

    /**
     * Loops through driver data to get performance totals. Used in dashboard.
     */
    returnPerformanceData = () => {
      const { DSCData } = this.props
      const { relativeDriverScorecards } = DSCData.data
      if (!relativeDriverScorecards || relativeDriverScorecards === undefined) {
        return null
      }
      const drivers = JSON.parse(JSON.stringify(relativeDriverScorecards.drivers))
      let good = 0
      let fair = 0
      let poor = 0
      for (const values of drivers) {
        switch (values.performance) {
          case 'poor':
            poor += 1
            break
          case 'fair':
            fair += 1
            break
          case 'good':
            good += 1
            break
          default:
            return null
        }
      }
      const performanceTotals = [
        { name: 'good', value: good },
        { name: 'fair', value: fair },
        { name: 'poor', value: poor },
      ]
      return performanceTotals
    }

    /**
     * Array descending sort given object key.
     */
    sortMapExceptions = (arr, key) => {
      const sortedArray = arr.sort((a, b) => b[key] - a[key]).map(driver => ({
        name: driver.name,
        id: driver.id,
        count: driver[key],
      }))
      return sortedArray
    }

    /**
     * Returns the dscData organized for exception card and exception details. Orders the drivers in
     *  descending order for each exception type by exception count. Note that the top 5 is NOT
     *  weighted by the miles over that the driver sped
     */
    returnExceptionData = () => {
      const { DSCData } = this.props
      const { relativeDriverScorecards } = DSCData.data
      if (!relativeDriverScorecards || relativeDriverScorecards === undefined) {
        return null
      }
      // clone driver data to prevent modifying original with sort functions
      const drivers = JSON.parse(JSON.stringify(relativeDriverScorecards.drivers))
      // sort and map for each exception
      const topSpeeders = this.sortMapExceptions(drivers, 'speeding')
      const topBrakers = this.sortMapExceptions(drivers, 'hardBrake')
      const topFStarters = this.sortMapExceptions(drivers, 'fastStart')

      /**
       * @todo - remove the static max values below once gql is updated
       */
      const exceptionData = {
        speeding: {
          total: relativeDriverScorecards.speeding,
          max: relativeDriverScorecards.max.speeding,
          orderedDrivers: topSpeeders,
        },
        fastStart: {
          total: relativeDriverScorecards.fastStart,
          max: relativeDriverScorecards.max.fastStart,
          orderedDrivers: topFStarters,
        },
        hardBrake: {
          total: relativeDriverScorecards.hardBrake,
          max: relativeDriverScorecards.max.hardBrake,
          orderedDrivers: topBrakers,
        },
      }
      return exceptionData
    }

    /**
     * Refetch dsc data with new week count
     */
    getDSCData = (range) => {
      const { DSCData } = this.props
      // sets new variables and reruns query
      this.setState({ range })
      DSCData.setVariables({ range })
    }

    render() {
      const {
        wMatrix, DSCData, dateRangeOptions, interpolateColors,
      } = this.props
      const { range } = this.state
      const performanceData = this.returnPerformanceData()
      const exceptionData = this.returnExceptionData()
      let DSCDateRangeOptions = []
      if (dateRangeOptions && dateRangeOptions.data
        && dateRangeOptions.data.reportScheduleDateRanges) {
        DSCDateRangeOptions = dateRangeOptions.data.reportScheduleDateRanges.filter(option => !option.key.includes('day') && !option.key.includes('all'))
      }

      return (
        <WrappedComponent
          wMatrix={wMatrix}
          performanceData={performanceData}
          exceptionData={exceptionData}
          returnColor={interpolateColors}
          dscData={DSCData.data.relativeDriverScorecards || null}
          loading={DSCData.loading}
          getDSCData={this.getDSCData}
          range={range}
          multipleWeeks={!range.includes('week')}
          // weeks={weekCount}
          DSCDateRangeOptions={DSCDateRangeOptions}
          // this.props is used to pass whatever parent props need to be passed through the wrap
          {...this.props}
        />
      )
    }
  }

  return compose(
    helper(),
    consumerConnector(),
    queryConnector(RelativeDSCQuery, { range: 'current_week' }, 'DSCData'),
    queryConnector(reportScheduleDateRanges, {}, 'dateRangeOptions'),
  )(DriverScorecardHOC)
}

export default driverScorecardHOC
