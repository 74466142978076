import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Card } from 'antd'
import DynamicTimeSlider from '@mol/dynamicTimeSlider'

const styles = {
  label: {
    marginRight: '5px',
    width: '30px',
    fontWeight: 'bold',
  },
  sliderRow: {
    display: 'flex',
    alignItems: 'center',
  },
}

export default class WeekSliderCard extends Component {
  static propTypes = {
    wMatrix: PropTypes.func.isRequired,
    toggleDisableDay: PropTypes.func,
    onChangeHours: PropTypes.func,
    viewOnly: PropTypes.bool,
    mon: PropTypes.array,
    tue: PropTypes.array,
    wed: PropTypes.array,
    thu: PropTypes.array,
    fri: PropTypes.array,
    sat: PropTypes.array,
    sun: PropTypes.array,
  }

  static defaultProps = {
    toggleDisableDay: null,
    onChangeHours: null,
    viewOnly: false,
    mon: [{ start: 0, end: 1440 }, { start: null, end: null }],
    tue: [{ start: 0, end: 1440 }, { start: null, end: null }],
    wed: [{ start: 0, end: 1440 }, { start: null, end: null }],
    thu: [{ start: 0, end: 1440 }, { start: null, end: null }],
    fri: [{ start: 0, end: 1440 }, { start: null, end: null }],
    sat: [{ start: 0, end: 1440 }, { start: null, end: null }],
    sun: [{ start: 0, end: 1440 }, { start: null, end: null }],
  }

  /**
   * @private
   * @description Returns a day label and associated slider
   * @returns {Component}
   */
  renderSliderRow = (defaultVals, dayId) => {
    const {
      viewOnly, wMatrix, onChangeHours, toggleDisableDay,
    } = this.props
    return (
      <div style={styles.sliderRow}>
        <label style={styles.label}>{wMatrix(dayId)}</label>
        <DynamicTimeSlider
          defaultVals={defaultVals}
          viewOnly={viewOnly || false}
          dayId={dayId}
          toggleDisableDay={toggleDisableDay}
          onChangeHours={onChangeHours}
        />
      </div>
    )
  }

  render() {
    const {
      viewOnly, wMatrix, mon, tue, wed, thu, fri, sat, sun,
    } = this.props
    // console.log('mon', mon)
    return (
      <div style={{ display: 'flex' }}>
        <Card size="small" style={{ margin: '10px 0px 10px 0px' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', paddingLeft: '5px' }}>
            <label style={{ marginRight: '5px', display: viewOnly ? 'none' : 'block' }}>{wMatrix('Enable')}</label>
            <label style={{ marginLeft: '5px', display: viewOnly ? 'none' : 'block' }}>{wMatrix('Split')}</label>
          </div>
          <div>
            {this.renderSliderRow(mon, 'mon')}
            {this.renderSliderRow(tue, 'tue')}
            {this.renderSliderRow(wed, 'wed')}
            {this.renderSliderRow(thu, 'thu')}
            {this.renderSliderRow(fri, 'fri')}
            {this.renderSliderRow(sat, 'sat')}
            {this.renderSliderRow(sun, 'sun')}
          </div>
        </Card>
      </div>
    )
  }
}
